import { useEffect } from 'react';
import { useMedia } from 'react-use';

import { useAuth } from '../../hooks/useAuth';

export function useSetupLocalStorageVariables() {
  const {
    user: { roles },
    updateIsAssistant,
  } = useAuth();

  const isAdmin = roles && isUserAdmin(defaultRoles, roles);
  // media screens
  const isLarge = useMedia('(min-width: 1920px)');
  isLarge
    ? localStorage.setItem('isLarge', 'true')
    : localStorage.setItem('isLarge', 'false');
  const isMacLarge = useMedia('(min-width: 1790px) and (max-width: 1919px)');
  isMacLarge
    ? localStorage.setItem('isMacLarge', 'true')
    : localStorage.setItem('isMacLarge', 'false');
  const isMediumLarge = useMedia('(min-width: 1680px) and (max-width: 1789px)');

  isMediumLarge
    ? localStorage.setItem('isMediumLarge', 'true')
    : localStorage.setItem('isMediumLarge', 'false');
  const isMedium = useMedia('(min-width: 1441px) and (max-width: 1679px)');

  isMedium
    ? localStorage.setItem('isMedium', 'true')
    : localStorage.setItem('isMedium', 'false');
  //
  useEffect(() => {
    localStorage.setItem('isAdmin', JSON.stringify({ isAdmin }));
    updateIsAssistant(roles?.includes('ROLE_ASSISTANT'));
  }, [roles]);
}

const defaultRoles = [
  'ROLE_ADMIN',
  'ROLE_ADMIN_ASSISTANT',
  'ROLE_ADMIN_AGENT',
  'ROLE_ADMIN_OPERATOR',
];

const isUserAdmin = (defaultRoles: string[], roles: string[]) => {
  for (const item of roles) {
    if (defaultRoles.includes(item)) {
      return true;
    }
  }
  return false;
};
