import { ChangeEvent } from 'react';

import { http } from '../../../libs/axios';
import { IMedia } from '../../../types/api';
import { useNotification } from '../../../hooks/useNotification';
import { useTranslation } from 'react-i18next';

const ImageInput = ({
  value,
  onChange,
}: {
  value?: IMedia;
  onChange: (newValue: IMedia) => unknown;
}) => {
  const { addNotification } = useNotification();

  const { t } = useTranslation('brochureEditor');

  async function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0];
    if (file) {
      try {
        const { data } = await uploadFile(file);
        onChange(data);
      } catch (e) {
        addNotification(t('could_not_upload_file'), 'error');
        console.error(e);
      }
    }
  }

  return (
    <>
      <input type="file" accept="image/*" onChange={handleChange} />
      {value && <img src={value.url.mobile} alt="" />}
    </>
  );
};

export default ImageInput;

async function uploadFile(file: File) {
  const formData = new FormData();
  formData.append('files[]', file);
  return await http.post<IMedia>('/media/patterns', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}
