import { AxiosResponse } from 'axios';
import { PropsWithChildren } from 'react';
import { SWRConfig, SWRConfiguration } from 'swr';
import { toast } from 'react-toastify';

import { http } from '../../libs/axios';
import { router } from '../router';

export function SWRProvider({ children }: PropsWithChildren) {
  return <SWRConfig value={swrConfig}>{children}</SWRConfig>;
}

const swrConfig: Partial<SWRConfiguration> = {
  fetcher: (url: string, params: unknown) =>
    http
      .get(url, {
        params,
      })
      .then(({ data }: AxiosResponse) => data),
  onError: ({ response }) => {
    if (response.status === 401) {
      toast.warning(
        'you are not allowed to continue you will be redirect to login page'
      );
      return router.navigate('/signin');
    }
  },
};
