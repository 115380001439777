import { FC } from 'react';

import PageTitleAndPaper from '../../components/stepper/pageTitleAndPaper';
import PropertyStepper from './stepper';
import { useTranslation } from 'react-i18next';

const CreatePropertyV2: FC = () => {
  const { t } = useTranslation('propertiesPage');
  return (
    <PageTitleAndPaper title={t('Add_a_new_property')} goBackLink="/properties">
      <PropertyStepper />
    </PageTitleAndPaper>
  );
};

export default CreatePropertyV2;
