import { useTranslation } from 'react-i18next';
import { PromotionWidgetPreviewProps } from '../../../../../types/brochureEditor';
import TableWidget from '../../tableWidget';
import { useMemo } from 'react';

const PromotionDetailsWidget = ({
  promotion,
  widgetOptions,
}: PromotionWidgetPreviewProps) => {
  const { t } = useTranslation('promotionPage');

  const lines = useMemo(
    () => [
      {
        id: 'maxRooms',
        label: t('MaxRooms'),
        value: promotion.maxRooms || '-',
      },
      {
        id: 'minRooms',
        label: t('MinRooms'),
        value: promotion.minRooms || '-',
      },
      {
        id: 'maxSurface',
        label: t('MaxSurface'),
        value: promotion.maxSurface || '-',
      },
      {
        id: 'minSurface',
        label: t('MinSurface'),
        value: promotion.minSurface || '-',
      },
      {
        id: 'maxBathrooms',
        label: t('MaxBathrooms'),
        value: promotion.maxBathrooms || '-',
      },
      {
        id: 'minBathrooms',
        label: t('MinBathrooms'),
        value: promotion.minBathrooms || '-',
      },
    ],
    [t, promotion]
  );

  return (
    <TableWidget
      title={t('promotionDetails')}
      items={lines}
      widgetOptions={widgetOptions}
    />
  );
};

export default PromotionDetailsWidget;
