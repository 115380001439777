import { HTMLAttributes } from 'react';

interface Props extends Omit<HTMLAttributes<HTMLSpanElement>, 'defaultValue'> {
  fieldValue: string | null | undefined;
  defaultValue: string | JSX.Element;
}

export default function FieldValueToDisplay({
  fieldValue,
  defaultValue,
  ...rest
}: Props): JSX.Element {
  if (fieldValue)
    return (
      <span
        className="field"
        dangerouslySetInnerHTML={{ __html: fieldValue }}
        {...rest}
      />
    );

  return <span {...rest}>{defaultValue}</span>;
}
