import { useTranslation } from 'react-i18next';
import { IContactTask } from '../../../../types/contacts';
import TaskItem from './list-item';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';

interface Props {
  items: IContactTask[];
  contactId: string;
  onAddNewItemClick: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  mutate: any;
  onAfterSubmit: () => void;
}

const TasksList = (props: Props) => {
  const { items, onAddNewItemClick, contactId, mutate, onAfterSubmit } = props;

  // if (!items) {
  //   return null;
  // }
  const { t } = useTranslation('common');
  const upcomingItems = items
    ? items.filter((item) => item.status?.keyname.toLowerCase() === 'upcoming')
    : [];
  const otherItems = items
    ? items.filter((item) => item.status?.keyname.toLowerCase() !== 'upcoming')
    : [];

  const onAddNewTask = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onAddNewItemClick) {
      onAddNewItemClick(e);
    }
  };

  return (
    <div className={'pt-4'}>
      {upcomingItems.length ? (
        <div>
          <p className={'text-xs font-normal leading-none mb-4 opacity-50'}>
            {t('Upcoming_tasks')}
          </p>
          {upcomingItems.map((item) => (
            <TaskItem
              key={item.id}
              item={item}
              id={contactId}
              onAfterSubmit={onAfterSubmit}
              mutate={mutate}
              taskId={item.id}
            />
          ))}
        </div>
      ) : null}
      <div className={'py-8 mb-8 border-b'}>
        <button
          onClick={(event) => onAddNewTask(event)}
          className={
            'hover:opacity-80 transition-opacity duration-300 rounded flex items-center justify-center gap-2 bg-gray-100 w-full p-3 text-[14px] text-gray-900 new_task_btn'
          }
        >
          <AddIcon />
          <span> {t('Add_new_task')} </span>
        </button>
      </div>
      {otherItems.length ? (
        <div className={'py-16'}>
          <p className={'text-xs font-normal leading-none mb-4 opacity-50'}>
            {t('Archived_tasks')}
          </p>
          {otherItems.map((item) => (
            <TaskItem
              key={item.id}
              item={item}
              completed
              id={contactId}
              onAfterSubmit={onAfterSubmit}
              mutate={mutate}
              taskId={item.id}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default TasksList;
