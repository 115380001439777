import SwitcherIosFormField from '../../../../components/form/switcherIosFormField';
import { EditorControlProps } from '../../../../types/brochureEditor';
import EditorSwitcherLayout from '../editorSwitcherLayout';

const BooleanControl = ({
  label,
  value,
  onChange,
}: EditorControlProps<boolean>) => (
  <EditorSwitcherLayout title={label}>
    <SwitcherIosFormField
      checked={value}
      onChange={(e) => onChange?.(e.target.checked)}
    />
  </EditorSwitcherLayout>
);

export default BooleanControl;
