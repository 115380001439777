import { Box, Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import { GridColumns, GridValidRowModel } from '@mui/x-data-grid';
import {
  gridFilteredDescendantCountLookupSelector,
  GridRenderCellParams,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import classNames from 'classnames';
import { t } from 'i18next';

import PermissionIcon from '../../../features/icons/permissionIcon';
import LocationIcon from '../../../features/icons/locationIcon';
import HomeIcon from '../../../features/icons/homeIcon';
import SquareIcon from '../../../features/icons/squareIcon';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { EnsureShortLocale } from '../../common/ensureShortLocale';
import PromotionsListingActionMenu from '../../tableGridX/promotionsListingActionMenu';
import CreatedUpdated from '../../tableGridX/createdUpdated';
import CreatedUpdatedHeader from '../../tableGridX/createdUpdatedHeader';
import { IPromotionListItem } from '../../../types/promotion';

interface Props extends GridRenderCellParams {
  title?: string;
}

const isLarge = localStorage.getItem('isLarge');
const isMacLarge = localStorage.getItem('isMacLarge');
const isMediumLarge = localStorage.getItem('isMediumLarge');

export function CustomGridTreeDataGroupingCell(props: Props) {
  const { id, field, rowNode, title = 'properties' } = props;
  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector
  );
  const filteredDescendantCount =
    filteredDescendantCountLookup[rowNode.id] ?? 0;

  const handleClick: ButtonProps['onClick'] = (event) => {
    if (rowNode.type !== 'group') {
      return;
    }

    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  return (
    <Box>
      <div>
        {filteredDescendantCount > 0 ? (
          <Tooltip
            title={`${
              rowNode.type == 'group' && rowNode.childrenExpanded
                ? 'Hide'
                : 'Show'
            } ${title}`}
            arrow
            placement="top"
          >
            <button
              onClick={handleClick}
              className="w-[1.5rem] h-[1.5rem] 2xl:w-[2rem] 2xl:h-[2rem] rounded-md border border-blue text-blue aspect-square flex justify-center items-center cursor-pointer"
            >
              <KeyboardArrowDownIcon
                className={classNames('', {
                  'rotate-180':
                    rowNode.type == 'group' && rowNode.childrenExpanded,
                })}
                fontSize={'small'}
              />
            </button>
          </Tooltip>
        ) : (
          <span />
        )}
      </div>
    </Box>
  );
}

export const renderCreatedByField = (row: any) => {
  const { createdBy } = row;
  if (!createdBy) return <span></span>;
  const { firstname, lastname } = createdBy;

  return (
    <div className="pl-2">
      <Tooltip title={`${firstname} ${lastname}`} arrow placement="top">
        <Box
          className="flex flex-col w-8 h-9 mx-1
           p-1.5 rounded-xl bg-gray-100 cursor-pointer"
          // title={`${firstname} ${lastname}`}
        >
          <span className="text-blue leading-3 m-auto">
            {firstname.charAt(0).toUpperCase()}
            {lastname.charAt(0).toUpperCase()}
          </span>
        </Box>
      </Tooltip>
    </div>
  );
};

export const promotionsListingConfig: GridColumns<GridValidRowModel> = [
  {
    field: 'cover',
    width: 180,
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({ row }) => {
      const link = 'promotions';

      return (
        <Box className={'py-1 h-[100px] w-full'}>
          <Link
            className={classNames(
              'h-full w-full flex relative overflow-hidden bg-gray-100'
            )}
            to={`/${link}/${row.id}`}
          >
            {row.coverImage ? (
              <img
                style={{ maxWidth: 'none' }}
                className={
                  'w-full h-full absolute inset-0 mt-[5px] object-cover'
                }
                src={row.coverImage.url.thumbnail}
                alt="promotion-desc"
              />
            ) : (
              <img
                className={'w-full h-full  object-cover'}
                src={'/img/default_image.jpg'}
                alt="promotion-desc"
              />
            )}
          </Link>
        </Box>
      );
    },
  },
  {
    field: 'name',
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    filterable: false,
    renderHeader: () => {
      const [Name] = [t('common:Name')];
      return (
        <Box className="flex flex-col Inter text-sm pl-1 text-[#1D1D1F]">
          <span className="leading-5 font-medium">{Name}</span>
        </Box>
      );
    },
    renderCell: ({ row }) => {
      const link = 'promotions';
      const currentLanguage = localStorage.getItem('i18nextLng') || 'en';

      return (
        <Box
          className={classNames(
            'flex flex-col py-2 pl-1 overflow-hidden gap-1 2xl:gap-2'
          )}
        >
          <>
            <Link to={`/${link}/${row?.id}`}>
              <p
                className={classNames(
                  'leading-none overflow-ellipsis overflow-hidden pb-0.5 text-[.875rem] xl:text-[1rem]'
                )}
              >
                {row.translations &&
                row.translations[`${EnsureShortLocale(currentLanguage)}`]
                  ? row.translations[`${EnsureShortLocale(currentLanguage)}`]
                      .name
                  : ``}
              </p>
            </Link>
          </>
        </Box>
      );
    },
  },
  {
    field: 'reference',
    // minWidth: 140,
    // maxWidth: 180,
    width: 200,
    sortable: false,
    disableColumnMenu: true,
    filterable: false,
    renderHeader: () => {
      const [Reference, Status] = [t('common:Reference'), t('common:Status')];
      return (
        <Box className="flex flex-col Inter text-sm pl-1 text-[#1D1D1F]">
          <span className="leading-5 font-medium">{Reference}</span>
          <span className="leading-5 font-medium">{Status}</span>
        </Box>
      );
    },
    renderCell: ({ row }) => {
      const currentLanguage = localStorage.getItem('i18nextLng') || 'en';
      return (
        <Box className="flex flex-col py-2 pl-1 gap-2">
          <>
            {row.reference ? (
              <div
                className="flex py-1 px-1 rounded-md items-center min-w-[120px] max-w-[120px]"
                style={{ border: '1px solid #3446B9 ' }}
              >
                <PermissionIcon className="mr-2 col-span-1" color="#3446B9" />{' '}
                <span className="text-blue leading-5 text-[.75rem] 2xl:text-[1rem]">
                  {row.reference}
                </span>
              </div>
            ) : null}
            <span className="text-[#4CAF50] leading-none capitalize text-[.75rem] 2xl:text-[1rem]">
              {
                row?.status?.translations[
                  `${EnsureShortLocale(currentLanguage)}`
                ]?.value
              }
            </span>
          </>
        </Box>
      );
    },
  },
  {
    field: 'address',
    // minWidth: 280,
    // maxWidth: 300,
    // flex: 0.125,
    flex: 0.7,
    sortable: false,
    disableColumnMenu: true,
    filterable: false,
    renderHeader: () => {
      const [Location] = [t('common:Location')];
      return (
        <Box className="flex flex-col Inter pl-1 text-sm text-[#1D1D1F]">
          <span className="leading-5 font-medium">{Location}</span>
        </Box>
      );
    },
    renderCell: ({ row }) => {
      const currentLanguage = localStorage.getItem('i18nextLng') || 'en';

      return (
        <Box className="flex items-center py-2 gap-2 text-[.875rem] 2xl:text-[1rem] overflow-hidden w-full">
          <>
            {row.address ? (
              <>
                <LocationIcon
                  className="mr-2 col-span-1 shrink-0"
                  color="#6E6E72"
                />
                <span className="overflow-ellipsis overflow-hidden">
                  {
                    row?.address?.translations[
                      `${EnsureShortLocale(currentLanguage)}`
                    ]?.city
                  }{' '}
                  -{' '}
                  {
                    row?.address?.translations[
                      `${EnsureShortLocale(currentLanguage)}`
                    ]?.adresse
                  }
                </span>
              </>
            ) : null}
          </>
        </Box>
      );
    },
  },
  {
    field: 'max',
    // minWidth: 170,
    // maxWidth: 180,
    // flex: 0.125,
    flex: 0.5,
    sortable: false,
    disableColumnMenu: true,
    filterable: false,
    renderHeader: () => {
      const [Max_Min, Rooms, Surface] = [
        t('common:Max_Min'),
        t('common:Rooms'),
        t('common:Surface'),
      ];

      return (
        <Box className="flex flex-col Inter text-sm text-[#1D1D1F]">
          <span className="leading-5 font-medium">
            {Max_Min} {Rooms}:
          </span>
          <span className="leading-5 font-medium">
            {Max_Min} {Surface}:{' '}
          </span>
        </Box>
      );
    },
    renderCell: ({ row }) => {
      return (
        <Box className="flex flex-col w-full py-2 pl-1 gap-2 text-[14px] overflow-hidden">
          <>
            {row.maxRooms || row.minRooms ? (
              <Box className="flex" sx={{ lineHeight: '20px' }}>
                <HomeIcon className="mr-2 col-span-1" color="#6E6E72" />{' '}
                <span>
                  {row.maxRooms} / {row.minRooms}
                </span>
              </Box>
            ) : null}
            {row.maxSurface || row.minSurface ? (
              <Box className="flex" sx={{ lineHeight: '20px' }}>
                <SquareIcon className="mr-2 col-span-1" color="#6E6E72" />{' '}
                <span>
                  {row.maxSurface} / {row.minSurface}
                </span>
              </Box>
            ) : null}
          </>
        </Box>
      );
    },
  },
  {
    field: 'createdBy',
    headerName: 'Contact',
    // width:
    //   isLarge === 'true'
    //     ? 150
    //     : isMacLarge === 'true'
    //     ? 130
    //     : isMediumLarge === 'true'
    //     ? 110
    //     : 90,
    flex: 0.5,
    sortable: false,
    disableColumnMenu: true,
    headerClassName:
      'font-medium Inter text-sm !pl-4 text-[#1D1D1F] !important',
    filterable: false,
    renderCell: ({ row }: any) => {
      return renderCreatedByField(row);
    },
  },
  {
    field: 'createdAt',
    // minWidth: 150,
    // maxWidth: 210,
    width: 200,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    renderHeader: () => <CreatedUpdatedHeader />,
    renderCell: ({ row }) => (
      <CreatedUpdated created={row.createdAt} updated={row.updatedAt} />
    ),
  },
  {
    field: '',
    // minWidth: 120,
    // maxWidth: 160,
    // flex: 0.125,
    width: 100,
    disableColumnMenu: true,
    sortable: false,
    headerClassName: '!bg-white !border !border-x-0',
    renderHeader: () => {
      const [Actions] = [t('common:actions')];
      return (
        <Box className="flex items-end pl-1" sx={{ maxWidth: '50%' }}>
          <span className="leading-5 font-medium text-[#1D1D1F]">
            {Actions}
          </span>
        </Box>
      );
    },
    renderCell: ({ row }) => (
      <PromotionsListingActionMenu row={row as IPromotionListItem} />
    ),
  },
];

export const skeletonPromotionsListingConfig: GridColumns<GridValidRowModel> = [
  {
    field: 'id',
    minWidth: 60,
    maxWidth: 60,
    flex: 0.125,
    disableColumnMenu: true,
    sortable: false,
    renderCell: () => {
      return <Skeleton width={60} height={20} animation="wave" />;
    },
  },
  {
    field: 'cover',
    minWidth: 85,
    maxWidth: 85,
    flex: 0.125,
    sortable: false,
    disableColumnMenu: true,
    renderCell: () => {
      return (
        <Skeleton variant="text" width={100} height={20} animation="wave" />
      );
    },
  },
  {
    field: 'name',
    minWidth: 170,
    maxWidth: 240,
    flex: 0.125,
    sortable: false,
    disableColumnMenu: true,
    filterable: false,
    renderHeader: () => {
      const [Name, Description] = [t('common:Name'), t('common:Description')];
      return (
        <Box className="flex flex-col Inter font-medium text-sm pl-1 text-[#1D1D1F]">
          <span className="leading-5">{Name}</span>
          <span className="leading-5">{Description}</span>
        </Box>
      );
    },
    renderCell: () => {
      return (
        <Box>
          {' '}
          <Skeleton variant="text" width={280} height={20} animation="wave" />
          <Skeleton variant="text" width={280} height={20} animation="wave" />
        </Box>
      );
    },
  },
  {
    field: 'reference',
    minWidth: 130,
    maxWidth: 170,
    sortable: false,
    disableColumnMenu: true,
    flex: 0.125,
    renderHeader: () => {
      const [Reference, Status] = [t('common:Reference'), t('common:Status')];
      return (
        <span className="flex flex-col Inter font-medium text-sm pl-2 text-[#1D1D1F]">
          <span className="leading-5">{Reference}</span>
          <span className="leading-5">{Status}</span>
        </span>
      );
    },
    renderCell: () => {
      return (
        <Box>
          {' '}
          <Skeleton variant="text" width={150} height={20} animation="wave" />
          <Skeleton variant="text" width={150} height={20} animation="wave" />
        </Box>
      );
    },
  },
  {
    field: 'address',
    minWidth: 150,
    maxWidth: 230,
    sortable: false,
    disableColumnMenu: true,
    flex: 0.125,
    renderHeader: () => {
      const [Location] = [t('common:Location')];
      return (
        <Box className="flex flex-col pl-2">
          <span className="leading-5 Inter font-medium text-sm text-[#1D1D1F]">
            {Location}
          </span>
        </Box>
      );
    },
    renderCell: () => {
      return (
        <Skeleton variant="text" width={280} height={20} animation="wave" />
      );
    },
  },
  {
    field: 'max',
    minWidth: 215,
    maxWidth: 180,
    flex: 0.125,
    sortable: false,
    disableColumnMenu: true,
    renderHeader: () => {
      const [Max_Min, Rooms, Surface] = [
        t('common:Max_Min'),
        t('common:Rooms'),
        t('common:Surface'),
      ];
      return (
        <Box className="flex flex-col Inter text-sm text-[#1D1D1F]">
          <span className="leading-5 font-medium">
            {Max_Min} {Rooms}:
          </span>
          <span className="leading-5 font-medium">
            {Max_Min} {Surface}:{' '}
          </span>
        </Box>
      );
    },
    renderCell: () => {
      return (
        <Box>
          {' '}
          <Skeleton variant="text" width={170} height={20} animation="wave" />
          <Skeleton variant="text" width={170} height={20} animation="wave" />
        </Box>
      );
    },
  },
  {
    field: 'createdBy',
    headerName: 'Contact',
    width:
      isLarge === 'true'
        ? 160
        : isMacLarge === 'true'
        ? 140
        : isMediumLarge === 'true'
        ? 120
        : 110,

    sortable: false,
    headerClassName: '!bg-white !pl-4 Inter font-medium text-sm text-[#1D1D1F]',
    disableColumnMenu: true,
    filterable: false,
    renderCell: () => {
      return (
        <Skeleton
          variant="text"
          width={
            isLarge === 'true'
              ? 180
              : isMacLarge === 'true'
              ? 160
              : isMediumLarge === 'true'
              ? 140
              : 130
          }
          height={20}
          animation="wave"
        />
      );
    },
  },
  {
    field: 'createdAt',
    minWidth: 150,
    maxWidth: 210,
    sortable: false,
    disableColumnMenu: true,
    flex: 0.12,
    renderHeader: () => <CreatedUpdatedHeader />,
    renderCell: () => {
      return (
        <Box>
          {' '}
          <Skeleton variant="text" width={230} height={20} animation="wave" />
          <Skeleton variant="text" width={230} height={20} animation="wave" />
        </Box>
      );
    },
  },
  {
    field: 'action',
    headerName: 'actions',
    minWidth: 130,
    maxWidth: 160,
    disableColumnMenu: true,
    sortable: false,
    flex: 0.125,
    headerClassName: '!bg-white !pl-4 !border !border-x-0',
    renderCell: () => {
      return (
        <Skeleton variant="text" width={180} height={20} animation="wave" />
      );
    },
  },
];
