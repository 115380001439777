import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

export default function UserDragIcon({ color, className }: IconProps) {
  const { mode } = useThemeMode();

  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <div>
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.99988 4C3.99988 4.55228 4.44759 5 4.99988 5C5.55216 5 5.99988 4.55228 5.99988 4C5.99988 3.44772 5.55216 3 4.99988 3C4.44759 3 3.99988 3.44772 3.99988 4ZM9.99988 4C9.99988 4.55228 10.4476 5 10.9999 5C11.5522 5 11.9999 4.55228 11.9999 4C11.9999 3.44772 11.5522 3 10.9999 3C10.4476 3 9.99988 3.44772 9.99988 4ZM10.9999 9C10.4476 9 9.99988 8.55228 9.99988 8C9.99988 7.44772 10.4476 7 10.9999 7C11.5522 7 11.9999 7.44772 11.9999 8C11.9999 8.55228 11.5522 9 10.9999 9ZM3.99988 8C3.99988 8.55228 4.44759 9 4.99988 9C5.55216 9 5.99988 8.55228 5.99988 8C5.99988 7.44772 5.55216 7 4.99988 7C4.44759 7 3.99988 7.44772 3.99988 8ZM10.9999 13C10.4476 13 9.99988 12.5523 9.99988 12C9.99988 11.4477 10.4476 11 10.9999 11C11.5522 11 11.9999 11.4477 11.9999 12C11.9999 12.5523 11.5522 13 10.9999 13ZM3.99988 12C3.99988 12.5523 4.44759 13 4.99988 13C5.55216 13 5.99988 12.5523 5.99988 12C5.99988 11.4477 5.55216 11 4.99988 11C4.44759 11 3.99988 11.4477 3.99988 12Z"
          fill={color}
        />
      </svg>
    </div>
  );
}
