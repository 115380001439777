import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

export default function UserIcon({
  color,
  className = 'w-4 h-4',
  strokeWidth,
}: IconProps & { strokeWidth?: string }) {
  const { mode } = useThemeMode();

  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
    >
      <circle
        cx="8"
        cy="8"
        r="7"
        stroke={color}
        strokeWidth={strokeWidth}
      ></circle>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 13.75c0-4.792-8-4.54-8 0"
        strokeWidth={strokeWidth}
      ></path>
      <circle
        cx="8.25"
        cy="5.75"
        r="2.25"
        stroke={color}
        strokeWidth={strokeWidth}
      ></circle>
    </svg>
  );
}
