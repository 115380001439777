import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';
function AiSearch({ color, className }: IconProps) {
  const { mode } = useThemeMode();

  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <defs>
        <linearGradient
          id="ai_icon_lg0"
          x1="21"
          x2="17.5"
          y1="12"
          y2="12"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            color="var(--ds-icon-accent-blue, #2684FF)"
            offset="0.271"
            stopColor="var(--ds-icon-accent-blue, #2684FF)"
          ></stop>
          <stop
            color="var(--ds-text-accent-blue, #0052CC)"
            offset="1"
            stopColor="var(--ds-text-accent-blue, #0052CC)"
          ></stop>
        </linearGradient>
        <linearGradient
          id="ai_icon_lg1"
          x1="12.5"
          x2="12.5"
          y1="3"
          y2="6.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            color="var(--ds-icon-accent-blue, #2684FF)"
            offset="0.188"
            stopColor="var(--ds-icon-accent-blue, #2684FF)"
          ></stop>
          <stop
            color="var(--ds-text-accent-blue, #0052CC)"
            offset="1"
            stopColor="var(--ds-text-accent-blue, #0052CC)"
          ></stop>
        </linearGradient>
        <linearGradient
          id="ai_icon_lg2"
          x1="6.83"
          x2="3.33"
          y1="12"
          y2="12"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            color="var(--ds-text-accent-blue, #0052CC)"
            stopColor="var(--ds-text-accent-blue, #0052CC)"
          ></stop>
          <stop
            color="var(--ds-icon-accent-blue, #2684FF)"
            offset="0.792"
            stopColor="var(--ds-icon-accent-blue, #2684FF)"
          ></stop>
        </linearGradient>
        <linearGradient
          id="ai_icon_lg3"
          x1="12.5"
          x2="12.5"
          y1="17"
          y2="21"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            color="var(--ds-text-accent-blue, #0052CC)"
            stopColor="var(--ds-text-accent-blue, #0052CC)"
          ></stop>
          <stop
            color="var(--ds-icon-accent-blue, #2684FF)"
            offset="0.802"
            stopColor="var(--ds-icon-accent-blue, #2684FF)"
          ></stop>
        </linearGradient>
      </defs>
      <g fillRule="evenodd" clipRule="evenodd">
        <path
          //   fill="url(#ai_icon_lg0)"
          stroke={color}
          d="M17.17 12a1 1 0 011-1H20a1 1 0 110 2h-1.83a1 1 0 01-1-1z"
        ></path>
        <path
          //   fill="url(#ai_icon_lg1)"
          stroke={color}
          d="M12 3a1 1 0 011 1v1.83a1 1 0 11-2 0V4a1 1 0 011-1z"
        ></path>
        <path
          //   fill="url(#ai_icon_lg2)"
          stroke={color}
          d="M3 12a1 1 0 011-1h1.83a1 1 0 110 2H4a1 1 0 01-1-1z"
        ></path>
        <path
          //   fill="url(#ai_icon_lg3)"
          stroke={color}
          d="M12 17.17a1 1 0 011 1V20a1 1 0 11-2 0v-1.83a1 1 0 011-1z"
        ></path>
        <path
          //   fill="var(--ds-icon-accent-blue, #2684FF)"
          stroke={color}
          d="M14.293 14.293a1 1 0 011.414 0l4 4a1 1 0 11-1.414 1.414l-4-4a1 1 0 010-1.414zM19.707 4.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0zM4.293 4.293a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414zM9.707 14.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0z"
        ></path>
      </g>
    </svg>
  );
}

export default AiSearch;
