import PipelinesStep from '../../../../components/reusableSteps/pipelines';
import { IPropertyApi, PropertyStepperStep } from '../../../../types/property';

function PropertyPipelines({ serverData }: PropertyStepperStep) {
  const id = serverData?.id as number;

  return (
    <PipelinesStep
      pipelineValuesEndpoint={`/v1/properties/${id}/pipeline_values`}
      createPipelineValuesEndpoint={`/property/pipeline_values`}
      availablePipelinesEndpoint={`/v1/properties/${id}/available_pipelines`}
      leadsEndpoint={`/v1/properties/${id}/prospects?itemsPerPage=400`}
      entity={{ property: serverData as IPropertyApi }}
    />
  );
}

export default PropertyPipelines;
