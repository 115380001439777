import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import {
  PipelineStep,
  PipelineStepTypes,
} from '../../../../../types/pipelines';
import { randomColor } from './colors';
import { t } from 'i18next';

// utils

export function generateRandomSteps(minAmount = 1, maxAmount = 11) {
  const amount =
    Math.floor(Math.random() * (maxAmount - minAmount)) + minAmount;
  const steps = [];
  for (let i = 0; i < amount; i++) {
    steps.push(generateRandomStep());
  }
  return steps;
}

let nextId = 100000;
function generateRandomStep() {
  nextId += 1;
  return {
    id: nextId,
    name: `Step ${nextId}`,
    sort: 1,
    color: randomColor(),
  };
}

function findOfferStep(columns: PipelineColumns) {
  for (const [columnName, column] of Object.entries(columns)) {
    for (const step of column) {
      if (step.isOfferStep) {
        return { offerStepColumn: columnName, offerStepId: step.id };
      }
    }
  }
  return { offerStepColumn: null, offerStepId: null };
}

// initial state

const initialState: PipelineStepsState = {
  columns: {
    [PipelineStepTypes.ENTRANCE]: [
      {
        serverId: null,
        id: nextId++,
        name: t('common:entrance_step'),
        color: randomColor(),
        isOfferStep: false,
      },
    ],
    [PipelineStepTypes.REGULAR]: [],
    [PipelineStepTypes.CLOSING]: [],
  },
  offerStepColumn: null,
  offerStepId: null,
};

// slice

export const useStepsState = create<Slice>()(
  immer((set, get) => ({
    ...initialState,
    setColumns(columns) {
      set(() => {
        const { offerStepId, offerStepColumn } = findOfferStep(columns);
        return { columns, offerStepId, offerStepColumn };
      });
    },
    addStep(column, data) {
      set((state) => {
        state.columns[column].push({ ...data, serverId: null, id: nextId++ });
      });
    },
    setOfferStep(column, id, isOfferStep) {
      const state = get();
      if (state.offerStepColumn && state.offerStepId) {
        state.updateStep(state.offerStepColumn, state.offerStepId, {
          isOfferStep: false,
        });
      }
      set((state) => {
        if (isOfferStep) {
          state.offerStepColumn = column;
          state.offerStepId = id;
        } else {
          state.offerStepColumn = null;
          state.offerStepId = null;
        }
      });
      state.updateStep(column, id, { isOfferStep });
    },
    updateStep(column, id, data) {
      set((state) => {
        const steps = state.columns[column];
        const step = steps.find((step) => step.id === id);
        if (step) {
          const index = steps.indexOf(step);
          if (index !== -1) {
            state.columns[column][index] = { ...step, ...data };
          }
        }
      });
    },
    deleteStep(column, id) {
      set((state) => {
        state.columns[column] = state.columns[column].filter(
          (step) => step.id !== id
        );
      });
    },
    moveStep(step, fromColumn, toColumn, index) {
      get().deleteStep(fromColumn, step.id);
      set((state) => {
        state.columns[toColumn].splice(index, 0, step);
      });
    },
    reset() {
      set(initialState);
    },
  }))
);

// hooks

export function useStepsHash(): Record<string, PipelineStepsStateItem> {
  const stepsArray = useStepsArray();
  return stepsArray.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {});
}

export function useStepsArray() {
  const columns = useStepsState((state) => state.columns);
  const stepsArray = [];
  for (const column of Object.values(columns)) {
    stepsArray.push(...column);
  }
  return stepsArray;
}

// types

export type PipelineStepsStateItem = Pick<
  PipelineStep,
  'name' | 'color' | 'isOfferStep'
> & {
  id: number;
  serverId: number | null;
};

export type PipelineColumns = Record<
  PipelineStepTypes,
  PipelineStepsStateItem[]
>;

export interface PipelineStepsState {
  columns: PipelineColumns;
  offerStepColumn: PipelineStepTypes | null;
  offerStepId: number | null;
}

interface Slice extends PipelineStepsState {
  setColumns: (columns: PipelineColumns) => void;
  addStep: (
    column: PipelineStepTypes,
    data: Omit<PipelineStepsStateItem, 'id' | 'serverId'>
  ) => void;
  setOfferStep: (
    column: PipelineStepTypes,
    id: number,
    isOfferStep: boolean
  ) => void;
  updateStep: (
    column: PipelineStepTypes,
    id: number,
    data: Partial<PipelineStepsStateItem>
  ) => void;
  deleteStep: (column: PipelineStepTypes, id: number) => void;
  moveStep: (
    step: PipelineStepsStateItem,
    fromColumn: PipelineStepTypes,
    toColumn: PipelineStepTypes,
    index: number
  ) => void;
  reset: () => void;
}
