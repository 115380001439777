import { deDE, enUS, frFR, GridValidRowModel } from '@mui/x-data-grid';

import {
  deDE as coredeDE,
  enUS as coreenEN,
  frFR as corefrFR,
} from '@mui/material/locale';
import { Box, createTheme, ThemeProvider } from '@mui/material';
import useLocale from '../../hooks/useLocale';
import { useThemeMode } from '../../hooks/useThemeMode';
import { appTheme } from '../material-ui/theme';
import { useMemo } from 'react';
import {
  DataGridPro,
  GridColDef,
  DataGridProProps,
} from '@mui/x-data-grid-pro';
import { TableGridXStyling } from '../tableGridX/tableGridXStyling';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

interface tableGridPropsT extends DataGridProProps {
  rows: Array<GridValidRowModel>;
  columns: GridColDef[];
  checkboxSelection?: boolean;
  disableColumnMenu?: boolean;
  density?: 'standard' | 'compact' | 'comfortable';
  rowsPerPageOptions?: Array<number>;
  loading?: boolean;
  showCellRightBorder?: boolean;
  page: number;
  pageSize: number;
  onPageChange: (page: number) => void;
  customColumnsTitle?: string;
}

export function CustomActionsIcon() {
  return <MoreHorizIcon sx={{ fontSize: 25 }} />;
}

function TableGridXPro({ rows, columns, ...rest }: tableGridPropsT) {
  const { locale } = useLocale();
  const [lang, coreLang] =
    locale == 'fr'
      ? [frFR, corefrFR]
      : locale == 'de'
      ? [deDE, coredeDE]
      : [enUS, coreenEN];

  const { mode } = useThemeMode();
  const theme = useMemo(() => createTheme(appTheme(mode)), [mode]);

  const tableGridTheme = createTheme(theme, lang, coreLang);

  return (
    <ThemeProvider theme={tableGridTheme}>
      <Box sx={TableGridXStyling}>
        <DataGridPro
          slots={{
            moreActionsIcon: CustomActionsIcon,
          }}
          slotProps={{
            panel: {
              placement: 'bottom-end',
            },
          }}
          rows={rows}
          columns={columns}
          {...rest}
        />
      </Box>
    </ThemeProvider>
  );
}

export default TableGridXPro;
