import UserInfoCard from '../../properties/singleProperty/propertyContact/userInfoCard';
import PromotionLocation from './promotionLocation';

type Props = {
  data: any;
};

function PromotionContact({ data }: Props) {
  const { mainBroker, address } = data;
  return (
    <div className="w-full flex flex-col gap-8">
      {address ? <PromotionLocation data={data} /> : null}
      {mainBroker && <UserInfoCard mainBroker={mainBroker} />}
    </div>
  );
}

export default PromotionContact;
