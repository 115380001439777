import React, { useEffect, useState } from 'react';
import RotateImage from './rotateImage';
import SpinnerLoading from '../../../../../features/spinner/spinnerLoading';
import { useValidateFile } from '../../../../../hooks/useValidateFile';
import { http } from '../../../../../libs/axios';
import { IMedia } from '../../../../../types/api';
import { useParams } from 'react-router-dom';

interface PopupImageProps {
  image: IMedia;
  maxSizeMb: number;
  mutate?: any;
  disableRotation?: boolean;
  activeSlide?: number | null;
}

const PopupImage: React.FC<PopupImageProps> = ({
  image,
  maxSizeMb,
  mutate,
  disableRotation,
  activeSlide,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [rotation, setRotation] = useState(0);
  const [withWatermark, toggleWithWatermark] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    setRotation(0);
    toggleWithWatermark(false);
  }, [activeSlide]);

  const validateFile = useValidateFile({ maxSizeMb });

  const handleSaveImage = async (newImage: File) => {
    setIsLoading(true);

    const formData = new FormData();

    if (newImage && validateFile(newImage)) {
      formData.append('file', newImage);

      try {
        await http.post(
          `/v1/properties/${id}/media/${image.id}${
            withWatermark ? '?watermark=true' : ''
          }`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        if (mutate) {
          mutate();
        }
      } catch (error) {
        console.error('Failed to upload the new image:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <RotateImage
        image={image}
        onSave={handleSaveImage}
        rotation={rotation}
        setRotation={setRotation}
        withWatermark={withWatermark}
        toggleWithWatermark={toggleWithWatermark}
        withDownloadButton
        disableRotation={disableRotation}
        isLoading={isLoading}
      />
      {isLoading ? (
        <div className="absolute z-20 inset-0 bg-white bg-opacity-50">
          <SpinnerLoading />
        </div>
      ) : null}
    </>
  );
};

export default PopupImage;
