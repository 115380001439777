import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd';
import classNames from 'classnames';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PipelineStepTypes } from '../../../../../types/pipelines';
import { PipelineStepsStateItem, useStepsState } from './useStepsState';
import StepItem from './stepItem';
import InputLayout from '../../../../../components/form/components/inputLayout';
import { randomColor } from './colors';

function EditStepsColumn({
  title,
  column,
  steps = [],
  maxSteps,
  minSteps,
  allowOfferStep,
}: {
  title?: string;
  column: PipelineStepTypes;
  steps?: PipelineStepsStateItem[];
  maxSteps?: number;
  minSteps?: number;
  allowOfferStep?: boolean;
}) {
  const { addStep, deleteStep, updateStep, setOfferStep } = useStepsState();
  const { t } = useTranslation('common');

  return (
    <div className="px-8">
      <InputLayout label={title}>
        <div>
          <div className="border-t border-b border-gray-200">
            <Droppable droppableId={column} direction="vertical">
              {(
                dropProvided: DroppableProvided,
                dropSnapshot: DroppableStateSnapshot
              ) => (
                <div
                  ref={dropProvided.innerRef}
                  className="select-none"
                  {...dropProvided.droppableProps}
                >
                  <ul
                    className={classNames('py-8 px-4 -mx-4 grid gap-2', {
                      'bg-blue-100': dropSnapshot.isDraggingOver,
                    })}
                    id={column}
                  >
                    {steps.map((step, index) => (
                      <li key={step.id}>
                        <Draggable draggableId={String(step.id)} index={index}>
                          {(
                            dragProvided: DraggableProvided,
                            dragSnapshot: DraggableStateSnapshot
                          ) => (
                            <div
                              ref={dragProvided.innerRef}
                              {...dragProvided.draggableProps}
                              {...dragProvided.dragHandleProps}
                            >
                              <StepItem
                                isHighlighted={dragSnapshot.isDragging}
                                step={step}
                                canDelete={
                                  typeof minSteps !== 'number' ||
                                  minSteps < steps.length
                                }
                                handleDelete={() => deleteStep(column, step.id)}
                                handleNameChange={(e) =>
                                  updateStep(column, step.id, {
                                    name: e.target.value,
                                  })
                                }
                                handleColorChange={(color) =>
                                  updateStep(column, step.id, {
                                    color,
                                  })
                                }
                                isOfferStep={step.isOfferStep || false}
                                handleOfferStepChange={(isOfferStep) => {
                                  setOfferStep(column, step.id, isOfferStep);
                                }}
                                allowOfferStep={allowOfferStep}
                              />
                            </div>
                          )}
                        </Draggable>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </Droppable>
          </div>
          {(typeof maxSteps !== 'number' || maxSteps > steps.length) && (
            <div className="pt-4">
              <Button
                type="button"
                sx={buttonStyles}
                classes={buttonClasses}
                variant="contained"
                disableElevation
                onClick={() => {
                  addStep(column, {
                    color: randomColor(),
                    name: `${title} #${steps.length + 1}`,
                    isOfferStep: false,
                  });
                  setTimeout(() => focusOnLastInput(column), 0);
                }}
              >
                {t('add_step')}
              </Button>
            </div>
          )}
        </div>
      </InputLayout>
    </div>
  );
}

export default EditStepsColumn;

const buttonStyles = {
  bgcolor: 'background.default',
  color: 'dark',
  '&:hover': { bgcolor: 'background.default' },
};

const buttonClasses = {
  root: 'w-full flex justify-center items-center',
};

function focusOnLastInput(column: string) {
  const input = document.querySelector(
    `#${column} li:last-child input`
  ) as HTMLInputElement | null;
  input?.focus();
}
