import classNames from 'classnames';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useTranslation } from 'react-i18next';

import { getElapsedTimeFromDate } from '../../../helpers/utils';
import { ILeadItem } from '../../../types/contacts';
import useLocale from '../../../hooks/useLocale';

function LeadDateAndStatus({
  lead,
  withStatus = true,
  hideIconOnMobile,
}: {
  lead?: ILeadItem | null;
  withStatus?: boolean;
  hideIconOnMobile?: boolean;
}) {
  const { locale } = useLocale();
  const { t } = useTranslation('propertiesPage');
  return (
    <div className={'@container flex flex-col items-start gap-2 w-full'}>
      <div className="flex-col justify-center items-start gap-2 flex">
        <div
          className={classNames(
            'self-stretch justify-start items-center gap-1 inline-flex',
            {
              'text-[#3446B9]': lead?.status.keyname === 'processed',
              'text-orange-600': lead?.status.keyname === 'in progress',
            }
          )}
        >
          <AccessTimeIcon
            className={classNames({
              '!hidden @[6rem]:!inline-block': hideIconOnMobile,
            })}
            fontSize={'small'}
            color={'inherit'}
          />
          <div className="text-[11px] font-medium leading-none">
            {lead && getElapsedTimeFromDate(lead?.date, locale === 'fr')}
          </div>
        </div>
      </div>
      {withStatus && lead?.status.keyname === 'in progress' ? (
        <div className="px-2 py-1 rounded border border-orange-600 justify-start items-center gap-1 inline-flex">
          <div className="text-orange-600 text-sm font-normal leading-none">
            {t('New_lead')}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default LeadDateAndStatus;
