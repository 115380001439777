import '@sweetalert2/themes/material-ui/material-ui.css';

import {
  Box,
  Checkbox,
  Fade,
  FormControlLabel,
  InputLabel,
  Modal,
  Typography,
} from '@mui/material';
import React, {
  useState,
  ReactNode,
  useEffect,
  Dispatch,
  SetStateAction,
  ChangeEvent,
  FC,
} from 'react';
import {
  Droppable,
  Draggable,
  DragDropContext,
  DroppableProvided,
  DroppableStateSnapshot,
  DraggableProvided,
  DraggableStateSnapshot,
  DropResult,
} from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

import { http } from '../../../libs/axios';
import { useStrictMode } from '../../../hooks/useStrictMode';
import { useValidateFile } from '../../../hooks/useValidateFile';

import { IMedia } from '../../../types/api';
import SpinnerLoading from '../../../features/spinner/spinnerLoading';
import GalleryFieldThumb from './thumb';
import GalleryFieldThumbLoading from './thumbLoading';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CloseIcon from '@mui/icons-material/Close';
import MediaGallery from '../../../pages/properties/stepper/steps/media/mediaGallery';

type Props = {
  media: (IMedia & { isVideo?: boolean })[];
  fileUploadUrl: string;
  setMedia?: Dispatch<SetStateAction<IMedia[]>>;
  isMultiple?: boolean;
  isStep?: boolean;
  isSubmitting?: boolean;
  name: string;
  accept?: string;
  mutate?: () => Promise<unknown>;
  singleDeleteUrl?: string;
  sortMediaUrl?: string;
  maxSizeMb?: number;
  TogglesComponent?: FC<{
    image: IMedia;
    mutate?: () => Promise<unknown>;
    isPublished?: boolean;
  }>;
  allowRemovalOfSharedImages?: boolean;
  isPublished?: boolean;
  isWatermarkAvailable?: boolean;
  disableRotation?: boolean;
};

const GalleryField = ({
  media,
  fileUploadUrl,
  isMultiple = false,
  name,
  isSubmitting,
  accept = '.jpg,.png,.jpeg',
  setMedia,
  mutate,
  singleDeleteUrl,
  sortMediaUrl,
  maxSizeMb = 10,
  TogglesComponent,
  allowRemovalOfSharedImages = false,
  isPublished,
  isWatermarkAvailable = true,
  disableRotation,
}: Props) => {
  const [spinnerCount, addSpinners] = useState(0);
  const [disabledImagesIds, setDisabledImagesIds] = useState<number[]>([]);
  const [isDragDisabled, setIsDragDisabled] = useState(false);
  const [selectedGalleryItem, setSelectedGalleryItem] = useState(0);
  const [openModal, toggleOpenModal] = useState(false);
  const [withWatermark, toggleWithWatermark] = useState(isWatermarkAvailable);

  const onPopupOpen = (index: number) => {
    setSelectedGalleryItem(index);
    toggleOpenModal(true);
  };

  const onClose = () => {
    toggleOpenModal(false);
  };

  const { t } = useTranslation('propertiesPage');

  const validateFile = useValidateFile({ maxSizeMb });

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (!files) return;

    addSpinners(files.length);

    const formData = new FormData();

    if (isMultiple) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file && validateFile(file)) {
          formData.append('files[]', file);
        }
      }
    } else if (files[0] && validateFile(files[0])) {
      formData.append('file', files[0]);
    }

    await http.post(
      `${fileUploadUrl}${withWatermark ? '?watermark=true' : ''}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    if (mutate) {
      await mutate();
    }

    addSpinners(0);
  };

  const removeImage = async (id: number) => {
    setDisabledImagesIds([...disabledImagesIds, id]);

    if (!isMultiple && singleDeleteUrl) {
      await http.patch(singleDeleteUrl);
    } else {
      await http.delete(`/media/${id}`);
    }

    if (mutate) {
      await mutate();
    }

    const index = disabledImagesIds.indexOf(id);
    if (index >= 0) {
      setDisabledImagesIds([
        ...disabledImagesIds.slice(0, index - 1),
        ...disabledImagesIds.slice(index),
      ]);
    }
  };

  const handleTrashIconClick = async (id: number) => {
    const { isConfirmed } = await Swal.fire({
      text: t(
        'Please_confirm_removal_of_the_image._This_action_cannot_be_reverte'
      ),
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: t('Remove_image'),
      showClass: {
        popup: 'block',
      },
      hideClass: {
        popup: 'hidden',
      },
    });
    if (isConfirmed) {
      removeImage(id);
    }
  };

  const handleDragEnd = async (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    setIsDragDisabled(true);

    const resortedMedia = swapMedia(
      media,
      result.source.index,
      result.destination.index
    );
    setMedia?.(resortedMedia);
    setIsDragDisabled(false);
  };

  useEffect(() => {
    if (isSubmitting && sortMediaUrl) {
      const medias = media.map((file) => file.id);
      if (medias.length) {
        http
          .patch(sortMediaUrl, { mediaIds: media.map((file) => file.id) })
          .catch(() => {
            toast.error(`${t('Server_error_when_re_ordering_images')}`);
          });
      }
    }
  }, [isSubmitting]);

  const spinners: ReactNode[] = [];
  for (let i = 0; i < spinnerCount; i++) {
    spinners.push(<GalleryFieldThumbLoading key={i * -1} />);
  }

  // https://github.com/atlassian/react-beautiful-dnd/issues/2396
  const [enableDropable] = useStrictMode(!media);

  return (
    <Box className="relative">
      {isDragDisabled && (
        <div className="absolute z-20 inset-0 bg-white bg-opacity-50">
          <SpinnerLoading />
        </div>
      )}
      <input
        style={{ display: 'none' }}
        id={name}
        multiple={isMultiple}
        type="file"
        accept={accept}
        onChange={(event) => handleFileUpload(event)}
      />
      {isWatermarkAvailable ? (
        <div className={'pb-1'}>
          <FormControlLabel
            control={
              <Checkbox
                className=""
                checked={withWatermark}
                onChange={() => toggleWithWatermark(!withWatermark)}
              />
            }
            label={t('upload_with_watermark')}
          />
        </div>
      ) : null}
      <InputLabel htmlFor={name} sx={{ display: 'flex', marginBottom: '16px' }}>
        <Typography
          sx={{
            flex: 2,
            padding: '16px',
            border: '1px solid #E6E8EC',
            borderRight: '0px',
            cursor: 'pointer',
            color: '#6E6E7250',
            background: '#F5F5F7;',
            height: '48px',
          }}
        >
          {/* This is always empty. We cannot have names of files here, because file names are not saved on the server,
            so they cannot be restored when user uploads images, leaves the page and comes back */}
        </Typography>

        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '16px',
            border: '1px solid #E6E8EC',
            cursor: 'pointer',
            height: '48px',
          }}
        >
          {t('Choose_file')}
        </Typography>
      </InputLabel>

      <DragDropContext onDragEnd={handleDragEnd}>
        {enableDropable && (
          <Droppable droppableId={name} direction="horizontal">
            {(
              dropProvided: DroppableProvided,
              dropSnapshot: DroppableStateSnapshot
            ) => (
              <div
                style={{
                  userSelect: 'none',
                  backgroundColor: dropSnapshot.isDraggingOver
                    ? '#eee'
                    : 'transparent',
                  overflow: 'hidden',
                }}
                {...dropProvided.droppableProps}
              >
                <div style={{ overflow: 'auto' }}>
                  <div
                    style={{
                      display: 'inline-flex',
                      flexGrow: '1',
                      width: 0,
                    }}
                  >
                    <div ref={dropProvided.innerRef}>
                      <Box sx={{ display: 'flex', gap: 3 }}>
                        {media.map((image: any, index) => (
                          <Draggable
                            key={String(image.id)}
                            draggableId={String(image.id)}
                            index={index}
                            isDragDisabled={isDragDisabled}
                          >
                            {(
                              dragProvided: DraggableProvided,
                              dragSnapshot: DraggableStateSnapshot
                            ) => {
                              return (
                                <div
                                  className="relative w-[185px] h-[135px]"
                                  ref={dragProvided.innerRef}
                                  {...dragProvided.draggableProps}
                                  {...dragProvided.dragHandleProps}
                                >
                                  {TogglesComponent && (
                                    <TogglesComponent
                                      mutate={mutate}
                                      image={image}
                                      isPublished={isPublished}
                                    />
                                  )}
                                  {name == 'logo' ? (
                                    <GalleryFieldThumb
                                      isVideoThumb={image?.type?.includes(
                                        'video'
                                      )}
                                      src={image?.x96}
                                      link={image?.x96}
                                      isDragging={dragSnapshot.isDragging}
                                      faded={disabledImagesIds.includes(
                                        image.id
                                      )}
                                      ribbon={image.isMain}
                                      trashIcon={
                                        !image.shared ||
                                        allowRemovalOfSharedImages
                                      }
                                      onTrashIconClick={() => {
                                        handleTrashIconClick(image.id);
                                      }}
                                      onItemClick={() => onPopupOpen(index)}
                                    />
                                  ) : (
                                    <GalleryFieldThumb
                                      isVideoThumb={image?.type?.includes(
                                        'video'
                                      )}
                                      src={image?.url?.thumbnail}
                                      link={image?.url?.large}
                                      isDragging={dragSnapshot.isDragging}
                                      faded={disabledImagesIds.includes(
                                        image.id
                                      )}
                                      ribbon={image.isMain}
                                      trashIcon={
                                        !image.shared ||
                                        allowRemovalOfSharedImages
                                      }
                                      onTrashIconClick={() => {
                                        handleTrashIconClick(image.id);
                                      }}
                                      onItemClick={() => onPopupOpen(index)}
                                    />
                                  )}
                                </div>
                              );
                            }}
                          </Draggable>
                        ))}
                        {spinners}
                      </Box>
                      {dropProvided.placeholder}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Droppable>
        )}
      </DragDropContext>

      <Modal
        open={openModal}
        onClose={onClose}
        classes={{ root: 'grid place-items-center' }}
      >
        <ClickAwayListener onClickAway={onClose}>
          <Fade in={openModal}>
            <Box
              sx={{
                maxHeight: '95vh',
                overflowY: 'auto',
                overflowX: 'hidden',
                width: {
                  xs: '85%',
                },
                borderRadius: 2,
                bgcolor: 'background.paper',
                boxShadow: 24,
              }}
            >
              <div
                className={
                  'p-4 flex justify-center items-center max-h-full relative'
                }
              >
                <button
                  onClick={onClose}
                  className={
                    'z-10 absolute right-4 top-4 w-[2rem] h-[2rem] rounded flex items-center justify-center bg-white transition-colors duration-500 hover:text-gray-700 text-black'
                  }
                >
                  <CloseIcon />
                </button>
                <MediaGallery
                  items={media}
                  initialSlide={selectedGalleryItem}
                  isLogo={name == 'logo'}
                  fileUploadUrl={fileUploadUrl}
                  mutate={mutate}
                  disableRotation={disableRotation}
                />
              </div>
            </Box>
          </Fade>
        </ClickAwayListener>
      </Modal>
    </Box>
  );
};
export default GalleryField;

function swapMedia(arr: any[], sourceIndex: number, targetIndex: number) {
  if (
    sourceIndex < 0 ||
    sourceIndex >= arr.length ||
    targetIndex < 0 ||
    targetIndex >= arr.length
  ) {
    return [...arr];
  }
  const newArray = [...arr];
  const temp = newArray[sourceIndex] as number;
  newArray[sourceIndex] = newArray[targetIndex] as number;
  newArray[targetIndex] = temp;
  return newArray;
}
