import React, { useEffect, useMemo, useState } from 'react';
import useListingCollection from '../../../../../hooks/useListingCollection';
import { INetworkListItem } from '../../../../../types/networks';
import useLocale from '../../../../../hooks/useLocale';
import classNames from 'classnames';
import { Skeleton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ActionData } from '../../../../../types/actionData';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useAnchorElement } from '../../../../../hooks/useAnchorElement';
import ActionMenu from '../../../../../components/common/actionMenu';
import { UseFormSetValue } from 'react-hook-form';
import { IProperty } from '../../../../../types/property';
import InputLabel from '@mui/material/InputLabel';

interface Props {
  setValue: UseFormSetValue<IProperty>;
  defaultValue: number[];
}

const mockUpItems = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const NetworkItemActionMenu = ({
  item,
  action,
}: {
  item: INetworkListItem;
  isList?: boolean;
  action?: string;
  mutate?: () => void;
}) => {
  const menuActionData: ActionData[] = [
    {
      label: 'view',
      link: `/networks/${item?.id}`,
      icon: VisibilityOutlinedIcon,
      hide: action,
    },
  ];

  const anchor = useAnchorElement();

  return (
    <>
      <ActionMenu
        {...anchor}
        menuAction={menuActionData}
        transPage={'networks'}
        action={action}
        propertiesCards={true}
      />
    </>
  );
};

const NetworksSelection: React.FC<Props> = (props) => {
  const { setValue, defaultValue } = props;
  const { itemsToDisplay, loadingItemsToDisplay } =
    useListingCollection<INetworkListItem>({
      basedUrl: `/networks`,
      isActiveProperties: true,
      currentPage: 'networks-all',
    });
  const { locale } = useLocale();
  const { t } = useTranslation();
  const [selectedIds, setSelectedIds] = useState<number[]>(defaultValue);

  const rows: INetworkListItem[] | null = useMemo(() => {
    if (itemsToDisplay) {
      return itemsToDisplay['hydra:member'];
    }
    return null;
  }, [itemsToDisplay]);

  const toggleSelect = (id: number) => {
    setSelectedIds((prevSelectedIds) =>
      prevSelectedIds.includes(id)
        ? prevSelectedIds.filter((selectedId) => selectedId !== id)
        : [...prevSelectedIds, id]
    );
  };

  useEffect(() => {
    setValue('networks', selectedIds);
  }, [selectedIds]);

  return (
    <div className={'pt-4'}>
      <p className={'mb-4 text-[12px] opacity-80'}></p>
      <InputLabel className={classNames('!text-xs')}>
        {t('propertiesPage:Share_With')}
        <span className="text-red-500">*</span>
      </InputLabel>
      <div className="grid grid-cols-4 gap-4 pt-4">
        {loadingItemsToDisplay ? (
          <>
            {mockUpItems.map((el) => (
              <Skeleton key={el} variant="rounded" height={90} />
            ))}
          </>
        ) : (
          <>
            {rows &&
              rows.map((item) => (
                <div
                  key={item.id}
                  onClick={() => toggleSelect(item.id)}
                  className={`py-2 px-4 border cursor-pointer rounded ${
                    selectedIds.includes(item.id)
                      ? 'border-blue-500'
                      : 'hover:border-gray-300'
                  }`}
                >
                  <div className={'flex gap-2 items-center mb-2'}>
                    <Tooltip
                      title={t('networks:Enabled')}
                      arrow
                      placement="top"
                    >
                      <div
                        className={classNames(
                          'shrink-0 w-[8px] h-[8px] rounded-full',
                          {
                            'bg-green-500': item.enabled,
                            'bg-gray-500': !item.enabled,
                          }
                        )}
                      />
                    </Tooltip>
                    <p
                      title={item?.translations?.[locale]?.name}
                      className={
                        'select-none overflow-hidden overflow-ellipsis whitespace-nowrap text-[18px] font-medium'
                      }
                    >
                      {item?.translations?.[locale]?.name}
                    </p>
                  </div>
                  <div className={'flex justify-between'}>
                    <div className="flex-col justify-center items-start inline-flex whitespace-nowrap overflow-hidden overflow-ellipsis">
                      <div className="text-zinc-900 text-sm font-medium leading-none overflow-ellipsis mb-1">
                        {item.creator.name}
                      </div>
                      <div className="text-neutral-500 text-sm font-normal leading-none capitalize">
                        {item.creator.city}
                      </div>
                    </div>
                    <div className={'w-[28px] h-[28px]'}>
                      <NetworkItemActionMenu item={item} />
                    </div>
                  </div>
                </div>
              ))}
          </>
        )}
      </div>
    </div>
  );
};

export default NetworksSelection;
