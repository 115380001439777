import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { UseFormGetValues, UseFormRegister } from 'react-hook-form';
import useSWR from 'swr';

import SelectFormField from '../../../components/form/selectFormField';
import useLocale from '../../../hooks/useLocale';
import { PipelineForm } from '../../../types/pipelines';
import { IService } from '../../../types/service';

function ServicesSelect({
  register,
  getValues,
}: {
  register: UseFormRegister<PipelineForm>;
  getValues: UseFormGetValues<PipelineForm>;
}) {
  const { t } = useTranslation('common');
  const { data: services } = useSWR<IService[]>('/services');
  const { locale } = useLocale();
  const options = useMemo(() => {
    return services
      ? services.map((service) => ({
          id: String(service.id),
          value: service.translations[locale]?.name || '',
        }))
      : [];
  }, [services, locale]);

  return (
    <SelectFormField
      {...register('serviceType')}
      defaultValue={String(getValues('serviceType'))}
      label={t('service_type')}
      options={options}
      isRequired
    />
  );
}

export default ServicesSelect;
