import { IDataFields } from '../../../../../types/settings';
import {
  SingleLocaleTranslation,
  Translations,
} from '../../../../../types/translations';

export function copyLanguage(
  source: string,
  destination: string,
  dataFields: IDataFields[]
) {
  for (const field of dataFields) {
    copyLanguageOfField(source, destination, field);
  }
}

export function copyLanguageOfField(
  source: string,
  destination: string,
  field: IDataFields
) {
  copyTranslation(source, destination, field.translations);
  for (const option of field.fieldSelect?.options || []) {
    copyTranslation(source, destination, option.translations);
  }
}

function copyTranslation(
  destination: string,
  source: string,
  translationsObject: Translations
) {
  const src = translationsObject[source];
  if (!src) {
    throw new Error(`Attempt to copy from non-existing locale: ${source}`);
  }

  translationsObject[destination];
  if (!translationsObject[destination]) {
    translationsObject[destination] = {
      locale: destination,
    } as SingleLocaleTranslation;
  }
  const dst = translationsObject[destination] as SingleLocaleTranslation;

  const keysToCopy = ['name', 'label', 'value'];
  for (const key of keysToCopy) {
    const typedKey = key as keyof SingleLocaleTranslation &
      ('name' | 'label' | 'value');
    const srcValue = src[typedKey];
    if (!dst[typedKey] && srcValue) {
      dst[typedKey] = srcValue;
    }
  }
}
