import { Box, Skeleton, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { GridColumns, GridValidRowModel } from '@mui/x-data-grid';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import LanguageIcon from '@mui/icons-material/Language';
import CheckIcon from '@mui/icons-material/Check';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { formatDate } from '../../datePicker/datePicker';
import { getContactName } from '../../../helpers/utils/contacts';
import { IContact } from '../../../types/contacts';
import CreatedUpdatedHeader from '../../tableGridX/createdUpdatedHeader';
import CreatedUpdated from '../../tableGridX/createdUpdated';
import { t } from 'i18next';
import { EnsureShortLocale } from '../../common/ensureShortLocale';
import { sanitizeUrl } from '../../../helpers/utils';
import ContactTypes from '../../tableGridX/leads/contactTypes';

const isLarge = localStorage.getItem('isLarge');
const currentLanguage = localStorage.getItem('i18nextLng') || 'en';
const lang = `${EnsureShortLocale(currentLanguage)}`;

export const contactsMatchingListingConfig: GridColumns<GridValidRowModel> = [
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 210,
    maxWidth: 215,
    flex: 1,
    sortable: false,
    resizable: false,
    hideable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: ({ row }) => {
      return (
        <div>
          <div
            className={classNames(
              'flex py-0.5 px-2 rounded-md items-center border border-[#4CAF50] text-[#4CAF50]',
              {
                'border-[#F50057] text-[#F50057]':
                  row.archive ||
                  (row.status && row.status.keyname === 'rejected'),
                'border-[#ED6C02] text-[#ED6C02]':
                  row.status && row.status.keyname === 'waiting for approval',
                'border-[#4CAF50] text-[#4CAF50]': !row.archive || !row.status,
              }
            )}
          >
            <span className="text-[12px] 2xl:text-[14px] leading-5 first-letter:uppercase">
              {row.status ? (
                <>{row.status.translations[lang]?.value}</>
              ) : row.archive ? (
                'Archived'
              ) : (
                ''
              )}
            </span>
          </div>
        </div>
      );
    },
  },
  {
    field: 'name',
    headerName: 'Name',
    minWidth: 220,
    maxWidth: 240,
    flex: 1,
    sortable: false,
    resizable: false,
    hideable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      const { row } = params;

      return (
        <div className={'flex w-full justify-between items-center'}>
          <div className="flex flex-col gap-1">
            <Link
              className={
                'text-[12px] 2xl:text-[16px] overflow-hidden overflow-ellipsis'
              }
              to={`/contacts/${row.id}/contact-information`}
            >
              {getContactName(row as IContact)}
            </Link>
          </div>
        </div>
      );
    },
  },
  {
    field: 'email',
    headerName: 'Email_status',
    minWidth: 210,
    maxWidth: 200,
    flex: 1,
    sortable: false,
    resizable: false,
    hideable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      const { row } = params;
      return (
        <div className={'flex items-center'}>
          {row.sentToContact ? (
            <div
              className={
                'flex flex-col justify-start items-start gap-2 h-full py-4'
              }
            >
              <div className="inner-tag h-6 px-2 py-1 bg-neutral-100 rounded justify-start items-center gap-1 inline-flex">
                <div className="text-zinc-900 text-sm font-normal font-['Inter'] leading-none">
                  {formatDate(row.sentToContactAt.date)}
                </div>
              </div>
              <div className="flex gap-1">
                <Tooltip
                  title={`${t('propertiesPage:Sent_to_the_contact')}`}
                  arrow
                  placement="top"
                >
                  <div className="h-6 px-2 py-1 bg-neutral-100 rounded justify-start items-center gap-1 inline-flex">
                    <CheckIcon
                      sx={{
                        width: 16,
                        height: 16,
                        color: '#4CAF50',
                      }}
                      fontSize={'small'}
                    />
                  </div>
                </Tooltip>
                {row.deliveredToContact ? (
                  <Tooltip
                    title={`${t('propertiesPage:Delivered_to_the_contact')}`}
                    arrow
                    placement="top"
                  >
                    <div className="h-6 px-2 py-1 bg-neutral-100 rounded justify-start items-center gap-1 inline-flex">
                      <CheckIcon
                        sx={{
                          width: 16,
                          height: 16,
                          color: '#3446B9',
                        }}
                        fontSize={'small'}
                      />
                    </div>
                  </Tooltip>
                ) : null}
                {row.openedByContact ? (
                  <Tooltip
                    title={`${t('propertiesPage:Opened_by_the_contact')}`}
                    arrow
                    placement="top"
                  >
                    <div className="h-6 px-2 py-1 bg-neutral-100 rounded justify-start items-center gap-1 inline-flex">
                      <VisibilityOutlinedIcon
                        sx={{
                          width: 16,
                          height: 16,
                        }}
                        fontSize={'small'}
                      />
                    </div>
                  </Tooltip>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      );
    },
  },
  {
    field: 'agent',
    headerName: 'Related_agent',
    minWidth: 155,
    maxWidth: 240,
    flex: 1,
    sortable: false,
    resizable: false,
    hideable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      const { row } = params;
      return (
        <div className={'flex items-center gap-2'}>
          {row.agentInCharge ? (
            <div
              className={
                'w-[24px] h-[24px] rounded-full bg-gray-100 overflow-hidden flex items-center justify-center'
              }
            >
              {row.agentInCharge.avatar ? (
                <img
                  src={row.agentInCharge.avatar}
                  alt={`${row.agentInCharge.firstname} ${row.agentInCharge.lastname}`}
                />
              ) : null}
            </div>
          ) : null}
          <span className={'!text-[12px] 2xl:text-[14px]'}>
            {row.agentInCharge
              ? `${row.agentInCharge.firstname} ${row.agentInCharge.lastname}`
              : ''}
          </span>
        </div>
      );
    },
  },
  {
    field: 'createdBy',
    headerName: 'Contact_source',
    cellClassName: '!overflow-x-auto',
    minWidth: 150,
    maxWidth: 240,
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    filterable: false,
    renderCell: (params) => {
      const { row } = params;
      const [Website, Promotion_Landing_Page, Property_Landing_Page, Direct] = [
        t('common:Website'),
        t('common:Promotion_Landing_Page'),
        t('common:Property_Landing_Page'),
        t('common:Direct'),
      ];
      return (
        <>
          {row.lead ? (
            <>
              {row.sourceWebsite ||
              row.sourcePromotionLP ||
              row.sourcePropertyLP ||
              row.sourceDirect ||
              (row.sourcePortals && row.sourcePortals.length) ? (
                <LanguageIcon fontSize={'small'} className={'mr-2'} />
              ) : null}
              {row.sourceWebsite ? (
                Website
              ) : row.sourcePortals && row.sourcePortals.length ? (
                <div
                  className={classNames('flex gap-1 py-1 pb-2', {
                    'overflow-x-scroll':
                      row.sourcePortals.length > (isLarge ? 2 : 1),
                  })}
                >
                  {row.sourcePortals.map((item: { name: string }) => (
                    <a
                      target={'_blank'}
                      rel={'noreferrer'}
                      href={sanitizeUrl(item.name)}
                      key={item.name}
                      className={`transition-color duration-300 hover:text-blue after:content-[','] hover:after:text-[#1D1D1F] last:after:hidden`}
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              ) : row.sourcePromotionLP ? (
                Promotion_Landing_Page
              ) : row.sourcePropertyLP ? (
                Property_Landing_Page
              ) : row.sourceDirect ? (
                Direct
              ) : null}
            </>
          ) : row.createdBy ? (
            <div className={'flex items-start gap-1'}>
              <PermIdentityIcon fontSize={'small'} />
              <span>
                {row.createdBy?.firstname} {row.createdBy?.lastname}
              </span>
            </div>
          ) : null}
        </>
      );
    },
  },
  {
    field: 'type',
    headerName: 'Contact_types',
    minWidth: 230,
    maxWidth: 240,
    flex: 1,
    sortable: false,
    resizable: false,
    hideable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: ({ row }) => {
      return <ContactTypes contact={row as IContact} />;
    },
  },
  {
    field: 'createdAt',
    headerName: 'date',
    minWidth: 185,
    maxWidth: 190,
    flex: 1,
    sortable: false,
    resizable: false,
    hideable: false,
    filterable: false,
    disableColumnMenu: true,
    renderHeader: () => <CreatedUpdatedHeader />,
    renderCell: ({ row }) => (
      <CreatedUpdated created={row.createdAt} updated={row.updatedAt} />
    ),
  },
];

export const skeletonContactsMatchingListingConfig: GridColumns<GridValidRowModel> =
  [
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 170,
      maxWidth: 195,
      flex: 1,
      sortable: false,
      resizable: false,
      hideable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: () => {
        return (
          <Skeleton variant="text" width={128} height={20} animation="wave" />
        );
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 250,
      maxWidth: 270,
      flex: 1,
      sortable: false,
      resizable: false,
      hideable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: () => {
        return (
          <Skeleton variant="text" width={317} height={20} animation="wave" />
        );
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 210,
      maxWidth: 200,
      flex: 1,
      sortable: false,
      resizable: false,
      hideable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: () => {
        return (
          <Skeleton variant="text" width={256} height={20} animation="wave" />
        );
      },
    },
    {
      field: 'agent',
      headerName: 'Related_agent',
      minWidth: 155,
      flex: 1,
      sortable: false,
      resizable: false,
      hideable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: () => {
        return (
          <Skeleton variant="text" width={256} height={20} animation="wave" />
        );
      },
    },
    {
      field: 'createdBy',
      headerName: 'Contact_source',
      minWidth: 150,
      maxWidth: 256,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      renderCell: () => {
        return (
          <Skeleton variant="text" width={256} height={20} animation="wave" />
        );
      },
    },
    {
      field: 'type',
      headerName: 'Contact_types',
      minWidth: 230,
      maxWidth: 256,
      flex: 1,
      sortable: false,
      resizable: false,
      hideable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: () => {
        return (
          <Skeleton variant="text" width={283} height={20} animation="wave" />
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'date',
      minWidth: 170,
      maxWidth: 176,
      flex: 1,
      sortable: false,
      resizable: false,
      hideable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: () => <CreatedUpdatedHeader />,
      renderCell: () => {
        return (
          <Box>
            {' '}
            <Skeleton variant="text" width={230} height={20} animation="wave" />
            <Skeleton variant="text" width={230} height={20} animation="wave" />
          </Box>
        );
      },
    },
  ];
