import { CSSProperties } from 'react';

import { WidgetOptions } from '../../../../types/brochureEditor';
import { IAddress } from '../../../../types/address';

export function useAlignmentStyles(
  widgetOptions?: WidgetOptions
): CSSProperties {
  const verticalAlign = (widgetOptions?.verticalAlign?.value ||
    'center') as string;
  const horizontalAlign = (widgetOptions?.horizontalAlign?.value ||
    'center') as string;

  return {
    alignContent: verticalAlign,
    justifyItems: horizontalAlign,
  };
}

export function useGoogleMapsLink({ address }: { address?: IAddress | null }) {
  if (!address?.latitude || !address?.longitude) {
    return null;
  }
  return `https://www.google.com/maps/search/?api=1&query=${address.latitude},${address.longitude}`;
}
