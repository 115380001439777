import { useTranslation } from 'react-i18next';
import { Box, Button, Fade, Modal } from '@mui/material';
import React, { useRef, useState } from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Document } from '../../types/property';
import PopupFormSuccess from '../properties/propertiesListing/propertiesMatching/steps/success';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { GridSelectionModel } from '@mui/x-data-grid';
import SendEmailForm from './sendEmail/form';
import ContactsListing from './sendEmail/contactsListing';
import useListingCollection from '../../hooks/useListingCollection';
import PopupStepper, { Step } from '../../components/popupStepper';
import useLocale from '../../hooks/useLocale';

interface Props {
  id?: string;
  rowSelection: (Document | undefined)[];
  onRowsSelectionHandler: (value: []) => void;
}

enum Steps {
  USERS,
  PREVIEW,
}

interface NestedObject {
  [key: string]: string;
}

function filterEmptyValues(obj: NestedObject): NestedObject {
  return Object.fromEntries(
    Object.entries(obj).filter(([, value]) => {
      if (typeof value === 'object' && !Array.isArray(value)) {
        return Object.keys(value).length > 0;
      }
      return value !== '' && value !== null && value !== undefined;
    })
  );
}

const SendBrochureButton = (props: Props) => {
  const { rowSelection, id } = props;
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [rowSelectionUsers, setRowSelectionUser] = useState<GridSelectionModel>(
    []
  );
  const formRef = useRef<HTMLFormElement>();
  const {
    itemsToDisplay,
    page,
    setPage,
    getFiltredProperties,
    loadingItemsToDisplay,
  } = useListingCollection({
    basedUrl: `/contacts`,
    isActiveProperties: true,
    status: null,
    currentPage: 'contacts',
    initialItemsPerPage: 5,
  });
  const { locale } = useLocale();

  function onClose() {
    setOpenModal(false);
    setRowSelectionUser([]);
    setSuccessMessage('');
  }

  const shouldDisableNext = (step: Step | undefined) => {
    switch (step?.id) {
      case Steps.USERS:
        return !rowSelectionUsers.length;
        break;
      case Steps.PREVIEW:
        return false;
        break;
      default:
        return false;
    }
  };

  function handleEmailSent(messageHtml?: string) {
    setSuccessMessage(
      messageHtml || t('propertiesPage:Your_files_was_successfully_sent')
    );
  }

  const submitSearch = async (data: any) => {
    try {
      const newdataTosend: any = filterEmptyValues(data);
      newdataTosend.archive = false;
      newdataTosend.lang = locale;

      await getFiltredProperties(newdataTosend);
    } catch (error) {
      console.log('Error occurred:', error);
    }
  };

  const content = successMessage ? (
    <PopupFormSuccess onClose={onClose}>
      <div dangerouslySetInnerHTML={{ __html: successMessage }}></div>
    </PopupFormSuccess>
  ) : (
    <PopupStepper
      onClose={onClose}
      disableNext={shouldDisableNext}
      steps={[
        {
          id: Steps.USERS,
          Icon: PeopleOutlineIcon,
          title: `${t('usersPage:users')}`,
          body: (
            <ContactsListing
              items={rowSelection}
              submitSearch={submitSearch}
              onRowsSelectionHandler={setRowSelectionUser}
              rowSelectionUsers={rowSelectionUsers}
              contacts={itemsToDisplay}
              onPageChange={setPage}
              page={page}
              loadingItemsToDisplay={loadingItemsToDisplay}
            />
          ),
        },
        {
          id: Steps.PREVIEW,
          Icon: MailOutlineIcon,
          title: `${t('propertiesPage:Email_preview')}`,
          body: (
            <SendEmailForm
              rows={[]}
              rowSelectionUsers={rowSelectionUsers}
              // @ts-ignore
              items={rowSelection}
              onEmailSent={handleEmailSent}
              formRef={formRef}
              contacts={itemsToDisplay}
              id={id}
            />
          ),
          nextButton: () => {
            const onClick = () => {
              if (formRef && formRef.current) {
                formRef.current.requestSubmit();
              }
            };

            return (
              <div
                onClick={onClick}
                className="h-[40px] rounded flex items-center justify-center p-4 gap-y-2 bg-blue cursor-pointer gap-6 text-white text-[14px] transition-colors duration-100 drop-shadow hover:bg-[rgb(36,49,129)]"
              >
                <span>{t('propertiesPage:Send_email')}</span>
                <ArrowForwardIosIcon
                  sx={{
                    width: '16px',
                    height: '16px',
                  }}
                  fontSize={'small'}
                />
              </div>
            );
          },
        },
      ]}
    />
  );

  return (
    <>
      <Button
        disabled={!(rowSelection && rowSelection.length)}
        variant="outlined"
        className="flex justify-center items-center"
        sx={{
          height: '42px',
        }}
        onClick={() => setOpenModal(true)}
      >
        <Box className="flex justify-center items-center" gap="16px">
          <MailOutlineIcon fontSize={'small'} />
          <span className="font-medium">
            {t('brochureEditor:Send_selected_brochures_via_email')}
          </span>
        </Box>
      </Button>

      <Modal
        open={openModal}
        onClose={onClose}
        classes={{ root: 'grid place-items-center' }}
      >
        <Fade in={openModal}>
          <Box
            sx={{
              maxHeight: '90vh',
              overflowY: 'auto',
              overflowX: 'hidden',
              width: {
                xs: successMessage ? '40%' : '90%',
              },
              borderRadius: 2,
              bgcolor: 'background.paper',
              boxShadow: 24,
            }}
          >
            {content}
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default SendBrochureButton;
