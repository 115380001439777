import { useJsApiLoader } from '@react-google-maps/api';

type Libraries = (
  | 'places'
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'visualization'
)[];

// important: libraries array must be declared outside of render method

const libraries = ['places'] as Libraries;

export const useGoogleApis = () => {
  return useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
    //@ts-ignore
    libraries,

    // The line below gives errors if user is trying to switch language. It is a known issue: https://github.com/googlemaps/js-api-loader/issues/210
    // language: locale,
  });
};
