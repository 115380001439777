import { ReactNode } from 'react';
import Container from '@mui/material/Container';
import GoBackTag from '../../features/tags/goBackTag';

function PageTitleAndPaper({
  title,
  goBackLink,
  onGoBackClick,
  extraTitle,
  children,
}: {
  title: string;
  goBackLink: string;
  extraTitle?: ReactNode;
  onGoBackClick?: () => unknown;
  children: ReactNode;
}) {
  return (
    <>
      <Container component="div" maxWidth="xl">
        <GoBackTag
          className="text-sm leading-4 font-medium"
          link={goBackLink}
          onClick={onGoBackClick}
        />

        <div className="flex flex-wrap gap-8 items-center">
          <h1 className="text-[32px] leading-none font-normal mt-6 mb-10">
            {title}
          </h1>
          <div className="flex-grow">{extraTitle}</div>
        </div>

        {children}
      </Container>
    </>
  );
}

export default PageTitleAndPaper;
