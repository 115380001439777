import { useTranslation } from 'react-i18next';
import SwitcherIosFormField from '../../../../components/form/switcherIosFormField';
import InputLayout from '../../../../components/form/components/inputLayout';
import {
  usePagesEditor,
  usePagesEditorComputables,
} from '../../lib/hooks/usePagesEditor';
import EditorSwitcherLayout from '../editorSwitcherLayout';
import EditorSidebarSection from '../sidebarSection';
import ImageInput from '../imageInput';
import { PageTypes } from '../../../../types/brochureEditor';

const PageOptions = () => {
  const setPageProperty = usePagesEditor((state) => state.setPageProperty);
  const { editedPage, editedPageIsRepeatable } = usePagesEditorComputables();

  const { t } = useTranslation('brochureEditor');

  return (
    <>
      <EditorSidebarSection>
        <EditorSwitcherLayout title={t('repeatable_page')}>
          <SwitcherIosFormField
            checked={editedPageIsRepeatable}
            onChange={(e, checked) => {
              editedPage &&
                setPageProperty(
                  editedPage.id,
                  'type',
                  checked ? PageTypes.REPEATABLE : PageTypes.DEFAULT
                );
            }}
          />
        </EditorSwitcherLayout>
        <InputLayout label={t('header') + ' ' + t('background_image')}>
          <ImageInput
            value={editedPage?.headerBg || undefined}
            onChange={(value) =>
              editedPage && setPageProperty(editedPage.id, 'headerBg', value)
            }
          />
        </InputLayout>
        <InputLayout label={t('footer') + ' ' + t('background_image')}>
          <ImageInput
            value={editedPage?.footerBg || undefined}
            onChange={(value) =>
              editedPage && setPageProperty(editedPage.id, 'footerBg', value)
            }
          />
        </InputLayout>
      </EditorSidebarSection>
    </>
  );
};
export default PageOptions;
