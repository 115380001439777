import { IconProps } from './locationIcon';

export default function PipelinesIcon({ color = 'currentcolor' }: IconProps) {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="5.5" y="4" width="5" height="21" stroke={color} />
      <rect x="13.5" y="6" width="5" height="21" stroke={color} />
      <rect x="21.5" y="6" width="5" height="21" stroke={color} />
    </svg>
  );
}
