import classNames from 'classnames';

function StackOfPages({ highlighted = false }: { highlighted?: boolean }) {
  return (
    <>
      <div
        className={classNames(
          'absolute -z-10 w-full h-full top-0 left-0 border mt-1 ml-1',
          {
            'border-gray-300': !highlighted,
            'border-blue': highlighted,
          }
        )}
      ></div>
      <div
        className={classNames(
          'absolute -z-10 w-full h-full top-0 left-0 border mt-2 ml-2',
          {
            'border-gray-300': !highlighted,
            'border-blue': highlighted,
          }
        )}
      ></div>
    </>
  );
}

export default StackOfPages;
