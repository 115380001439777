export const COLORS = [
  '#E15241',
  '#DA4F7A',
  '#9F4DB6',
  '#7859BC',
  '#5EA3EF',
  '#60C3D7',
  '#52A49A',
  '#7BB972',
  '#D6E16D',
  '#F7CC4F',
  '#F2AB47',
  '#EE7950',
  '#BDBDBD',
];

export function randomColor() {
  return COLORS[Math.floor(Math.random() * COLORS.length)] as string;
}
