import { Dispatch, SetStateAction, useMemo } from 'react';
import { ApiListing } from '../../../types/api';
import { IContactListItem } from '../../../types/contacts';
import TableGridXWithTreeData from '../../../components/tableGridX/withTreeData';
import useTranslationForDataGreedTree from '../../../hooks/useTranslationForDataGreedTree';
import { contactsListingConfig } from '../../../components/material-ui/tablesConfig/contactsListingConfig';
import { isCompanyContact } from '../../../helpers/utils/contacts';

function addHierarchyToList(items: IContactListItem[]) {
  return items.map((item) => {
    const isCompany = isCompanyContact(item);

    const idToUse = isCompany ? item?.company?.id : item?.id;
    const suffix = isCompany ? 'comp' : 'ind';

    const id = `${idToUse}-${suffix}`;

    let hierarchy = [`${id}`];

    if (item.individual && item.individual.companyInformation) {
      const parentItem = items.find(
        (el) =>
          el.company &&
          el.company.id ===
            parseInt(item?.individual?.companyInformation?.id as string)
      );

      if (parentItem) {
        hierarchy = [`${item.individual.companyInformation.id}-comp`, id];
      }
    }

    return {
      ...item,
      id: id,
      realId: item.id,
      hierarchy,
    };
  });
}

type Props = {
  itemsPerPage: number;
  data: ApiListing<IContactListItem>;
  handlePage: Dispatch<SetStateAction<number>>;
  page: number;
  rowsPerPage: number;
  setItemsPerPage: (value: number) => void;
};
const ContactsListingTable = ({
  itemsPerPage,
  data,
  handlePage,
  page,
  rowsPerPage,
  setItemsPerPage,
}: Props) => {
  const contactsListingColumnsTree = useTranslationForDataGreedTree(
    contactsListingConfig,
    'common'
  );

  const rows = useMemo(() => {
    if (data) {
      const rows = data['hydra:member'];
      return addHierarchyToList(rows);
    }
  }, [data]);

  if (!rows) return null;

  return (
    <TableGridXWithTreeData
      columns={contactsListingColumnsTree}
      rows={rows}
      setItemsPerPage={setItemsPerPage}
      rowsPerPage={rowsPerPage}
      autoPageSize
      pageSize={itemsPerPage}
      page={page - 1}
      showCellRightBorder={true}
      paginationMode="server"
      density={'standard'}
      customColumnsTitle={'contacts'}
      rowCount={data['hydra:totalItems']}
      onPageChange={(page: number) => {
        handlePage(page + 1);
      }}
    />
  );
};
export default ContactsListingTable;
