import { FC } from 'react';

import GooglePlacesWithMap from './googlePlacesWithMap';
import useLocale from '../../../hooks/useLocale';
import { IAddress } from '../../../types/address';

const AddressInput: FC<{
  value?: IAddress;
  onChange: (address: IAddress) => unknown;
  withoutMaps?: boolean;
  replaceClassNames?: string;
}> = ({ value, onChange, withoutMaps = false, replaceClassNames }) => {
  const { locale } = useLocale();
  const separator = ', ';
  const defaultAddress = value?.translations?.[locale]?.adresse || '';
  const [defaultRoute, defaultStreetNumber] = defaultAddress.split(separator);

  const onPlaceChange = (
    placeResult: google.maps.places.PlaceResult,
    placesByLanguage: any
  ) => {
    const location = placeResult.geometry?.location;

    const address: IAddress = {
      latitude: null,
      longitude: null,
      zipCode: '',
      locale,
      translations: {},
    };
    if (location) {
      const { lat, lng } = location.toJSON();
      address.latitude = lat;
      address.longitude = lng;
    }

    // }
    const { address_components } = placeResult;
    if (address_components) {
      let route: string | undefined;
      let streetNumber: string | undefined;

      for (const languageKey of Object.keys(placesByLanguage)) {
        const components = placesByLanguage[languageKey];
        const translationObject = (address.translations[languageKey] = {
          locale: languageKey,
          country: '',
          countryCode: '',
          city: '',
          zone: '',
          adresse: '',
          canton: '',
          district: '',
        });
        for (const component of components) {
          const {
            long_name,
            short_name,
            types: [type],
          } = component;

          switch (type) {
            case 'postal_code':
              address.zipCode = long_name;
              break;
            case 'country':
              translationObject.country = short_name;
              break;
            case 'locality':
            case 'postal_town':
              translationObject.city = long_name;
              break;
            case 'sublocality_level_1':
              translationObject.zone = long_name;
              break;
            case 'route':
              route = long_name;
              break;
            case 'street_number':
              streetNumber = long_name;
              break;
            case 'administrative_area_level_1':
              translationObject.canton = long_name;
              break;
            case 'administrative_area_level_2':
              translationObject.district = long_name;
              break;
            default:
            // Do nothing.
          }
        }

        // Combine route and street number, if available
        const routeAndStreetNumber = [
          route || defaultRoute,
          streetNumber || defaultStreetNumber,
        ]
          .filter(Boolean)
          .join(separator);

        // Set the 'adresse' property in the translation object
        translationObject.adresse = routeAndStreetNumber;
      }
    }

    onChange(address);
  };

  const mapCenter = {
    lat: value?.latitude || null,
    lng: value?.longitude || null,
  };
  const hasCoordinates = mapCenter.lat && mapCenter.lng;
  const zoom = hasCoordinates ? 15 : 12;

  return (
    <GooglePlacesWithMap
      withoutMaps={withoutMaps}
      center={mapCenter}
      onPlaceChange={onPlaceChange}
      zoom={zoom}
      replaceClassNames={replaceClassNames}
    />
  );
};

export default AddressInput;
