import TableItem from './item';
import { IContactCompany } from '../../../../types/contacts';
import useLocale from '../../../../hooks/useLocale';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ReactNode } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import IndividualInformationTable from './individual';
import { useTranslation } from 'react-i18next';

export const AccordionView = (props: {
  title: string;
  subTitle: ReactNode;
  children: ReactNode;
}) => {
  const { title, subTitle, children } = props;
  return (
    <Accordion
      key={title}
      sx={{
        boxShadow: 'none',
        margin: '0',
        '&:last-of-type': {
          borderBottom: '1px solid #E6E8EC',
        },
        '&::before': {
          display: 'none',
        },
        '&.Mui-expanded': {
          margin: '0 0 -1px 0',
          borderBottom: 'none',
        },
        '&.Mui-disabled': {
          backgroundColor: 'inherit',
        },
      }}
    >
      <AccordionSummary
        expandIcon={<KeyboardArrowDownIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          border: '1px solid #E6E8EC',
          borderBottom: 'none',
          padding: '0',
          fontSize: '1rem',
          '&.Mui-expanded': {
            minHeight: '51px',
          },
          '& .MuiAccordionSummary-content': {
            margin: '0',
          },
          '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '0',
            backgroundColor: '#FBFBFD',
          },
          '& .MuiAccordionSummary-content.Mui-expanded .test': {
            opacity: 0,
          },
          '& .MuiAccordionSummary-expandIconWrapper': {
            position: 'absolute',
            right: '8px',
          },
        }}
      >
        <div className={'grid grid-cols-12 divide-x w-full'}>
          <div className={'col-start-1 col-end-4 p-4 text-blue'}>{title}</div>
          <div className={'col-start-4 col-end-12 p-4 test'}>
            <p className={'test transition-opacity duration-300'}>{subTitle}</p>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails
        style={{
          boxShadow: 'none',
          padding: '0',
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

const CompanyInformationTable = (props: {
  data: IContactCompany;
  agent?: string;
  archived?: boolean;
  agencies?: {
    categoryAgence?: string;
    idAgence?: number;
    nameAgence?: string;
  }[];
}) => {
  const { data, archived, agencies } = props;
  const { locale } = useLocale();

  const { t } = useTranslation('common');
  const agenciesList =
    Array.isArray(agencies) && agencies.map((elem) => elem.nameAgence);
  return data ? (
    <>
      <p className={'text-[24px] font-normal leading-7 mb-8'}>
        {t('Company_information')}
      </p>
      <div className={'divide-y border'}>
        <TableItem title={t('Legal_name')} value={data.legalName || null} />
        <TableItem
          title={t('Status')}
          color={archived ? '#F50057' : '#4CAF50'}
          value={archived ? 'Archived' : 'Active'}
        />
        <TableItem
          title={t('Business')}
          value={data.business ? data.business.keyname : null}
        />
        <TableItem
          title={t('Description')}
          value={
            data.translations && data.translations[locale]
              ? data.translations[locale]?.description
              : null
          }
        />
        <TableItem title={t('Phone')} link={'phone'} value={data.phone} />
        <TableItem title={t('Email')} link={'email'} value={data.email} />
        <TableItem title={t('Website')} link={'web'} value={data.website} />
        <TableItem
          title={t('Country')}
          value={
            data.address && data.address.translations[locale]
              ? data.address?.translations?.[locale]?.country
              : null
          }
        />
        <TableItem
          title={t('City')}
          value={
            data.address && data.address.translations[locale]
              ? data.address?.translations?.[locale]?.city
              : null
          }
        />
        <TableItem
          title={t('Address')}
          value={
            data.address && data.address.translations[locale]
              ? data.address?.translations?.[locale]?.adresse
              : null
          }
        />
        <TableItem title={t('Postal_code')} value={data.address?.zipCode} />
        <TableItem
          title={t('Main_contact_person')}
          value={data.mainContactPerson}
        />
        <TableItem
          title={t('Email')}
          link={'email'}
          value={data.emailContactPerson}
        />
        <TableItem
          title={t('Phones')}
          link={'phone'}
          multiple
          value={
            data.phonesContactPerson.length
              ? data.phonesContactPerson.map((item) => item.telephone)
              : null
          }
        />
        <TableItem
          title={t('usersPage:secondary_agencies')}
          value={agenciesList.toString().replaceAll(',', ', ')}
        />
      </div>
      {data.individuals && data.individuals.length ? (
        <div className={'pb-4'}>
          <p className={'text-[24px] font-normal leading-7 mb-8 mt-16'}>
            {t('Contact_information')}
          </p>
          <>
            {data.individuals.map((item) => (
              <AccordionView
                key={item.firstname}
                title={`${
                  item.translations &&
                  item.translations[locale] &&
                  item.translations[locale]?.title
                    ? `${item.translations[locale]?.title}.`
                    : ''
                } ${item.firstname} ${item.lastname}`}
                subTitle={
                  <>
                    {item.phone && (
                      <span
                        className={`mr-2 after:content-[','] last:after:hidden`}
                      >
                        {item.phone}
                      </span>
                    )}
                    {item.email && (
                      <span className={`after:content-[','] last:after:hidden`}>
                        {item.email}
                      </span>
                    )}
                  </>
                }
              >
                <IndividualInformationTable data={item} />
              </AccordionView>
            ))}
          </>
        </div>
      ) : null}
    </>
  ) : null;
};

export default CompanyInformationTable;
