import { Box } from '@mui/material';
import BackLink from '../../components/common/backLink';

type Props = {
  className?: string;
  onClick?: () => void;
  link: string;
};

function GoBackTag({ link, className, onClick }: Props) {
  return (
    <Box onClick={onClick} className={'flex mb-4 cursor-pointer ' + className}>
      <BackLink url={link} label="usersPage:Back_to_list" />
    </Box>
  );
}

export default GoBackTag;
