import { WidgetPreviewProps } from '../../../../../types/brochureEditor';
import ImageOrIcon from '../../imageOrIcon';

const ImageBlock = ({ previewSubject, widgetOptions }: WidgetPreviewProps) => {
  let imageSrc: string | null = null;

  const imageId = widgetOptions?.image?.value;
  if (imageId) {
    const media = previewSubject.images.find(
      ({ id }) => String(id) === imageId
    );
    if (media) {
      imageSrc = media.url.large;
    }
  }

  const objectFit =
    (widgetOptions?.objectFit?.value as string | undefined) || 'cover';

  return <ImageOrIcon imageSrc={imageSrc} objectFit={objectFit} />;
};

export default ImageBlock;
