import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

function ParkingIcon({ color, className }: IconProps) {
  const { mode } = useThemeMode();
  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path stroke={color} d="M2.4 14H1V2h14v12h-1.4M9 14V1.5M7.5 14h3"></path>
      <path
        stroke={color}
        d="M3.5 12V9m0 0V5H5a2 2 0 012 2v0a2 2 0 01-2 2H3.5z"
      ></path>
    </svg>
  );
}

export default ParkingIcon;
