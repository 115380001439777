import { ReactNode } from 'react';
import { Control, useFieldArray } from 'react-hook-form';
import PlusIcon from '../../features/icons/plusIcon';
import { Button, Typography } from '@mui/material';
import classNames from 'classnames';

interface Props {
  control: Control<any, any>;
  name: string;
  maxItems?: number;
  renderField: (
    field: Record<'id', string>,
    index: number,
    remove: (index?: number | number[]) => unknown
  ) => ReactNode;
  appendObj: unknown;
  addLabel?: string;
}

const RepeatableBlock = ({
  control,
  maxItems,
  name,
  renderField,
  addLabel,
  appendObj,
}: Props) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const isAddButtonEnabled =
    typeof maxItems === 'undefined' || fields.length < maxItems;

  return (
    <div className="grid gap-4">
      {fields.map((field, index) => renderField(field, index, remove))}
      <div
        className={classNames('mt-10', {
          hidden: !isAddButtonEnabled,
        })}
      >
        <Button
          variant="outlined"
          type="button"
          onClick={() => {
            append(appendObj);
          }}
          style={{ width: '100%', background: '#F5F5F7' }}
          className="w-full relative"
        >
          <PlusIcon color="#6E6E72" className="absolute left-4" />

          <Typography
            sx={{
              fontSize: '14px',
              color: '#6E6E72',
              fontWeight: 500,
            }}
          >
            {addLabel}
          </Typography>
        </Button>
      </div>
    </div>
  );
};

export default RepeatableBlock;
