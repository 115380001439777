import React from 'react';
import { GridSelectionModel } from '@mui/x-data-grid';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import ContactsListingTable from '../../properties/propertiesListing/propertiesMatching/steps/users/listing';
import { ApiListing } from '../../../types/api';
import { IContact } from '../../../types/contacts';
import { Document } from '../../../types/property';
import { Link } from 'react-router-dom';
import { parseFileName } from '../../../helpers/utils/downloadFile';
import LazyPdfPreview from '../../../components/common/lazyPdfPreview';
import { useTranslation } from 'react-i18next';
import InputFormField from '../../../components/form/inputFormField';
import { useForm } from 'react-hook-form';
import { Button } from '@mui/material';
import SpinnerLoading from '../../../features/spinner/spinnerLoading';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { getFileName } from '../useColumns';

const initialData = {
  contactName: '',
  contactEmail: '',
};

type Props = {
  rowSelectionUsers: GridSelectionModel;
  onRowsSelectionHandler: (rowSelectionModel: GridRowSelectionModel) => void;
  contacts: ApiListing<any> | null;
  items: (Document | undefined)[];
  onPageChange: (page: number) => void;
  submitSearch: any;
  loadingItemsToDisplay: boolean;
  page: number;
};

const ContactsListing = ({
  rowSelectionUsers,
  onRowsSelectionHandler,
  contacts,
  items,
  onPageChange,
  submitSearch,
  loadingItemsToDisplay,
  page,
}: Props) => {
  const { t } = useTranslation();
  const { register, handleSubmit, reset } = useForm();
  const brochures = items.filter((el) => el?.isBrochure);
  const files = items.filter((el) => el?.isDocument);

  const onSubmit = (data: any) => {
    submitSearch(data);
  };

  const resetFilters = () => {
    reset();
    submitSearch(initialData);
  };

  return (
    <div>
      {brochures && brochures.length ? (
        <>
          <p className={'font-medium'}>
            {t('brochureEditor:selected_brochures')}
          </p>
          <div className={'grid grid-cols-10 gap-2 py-4 mb-2'}>
            {brochures.map((el) => {
              if (el) {
                return (
                  <div className={'bg-[#F5F5F7] p-2 rounded'} key={el.id}>
                    <Link
                      className={'flex flex-col justify-between h-full'}
                      to={el.name}
                      target="_blank"
                      title={parseFileName(el.name)}
                      aria-label="Download file"
                    >
                      <LazyPdfPreview
                        documentProps={{ file: el.name }}
                        thumbnailProps={{
                          width: 100,
                          height: 100,
                          className: 'pointer-events-none',
                        }}
                        skeletonHeight={100}
                      />
                    </Link>
                  </div>
                );
              }
            })}
          </div>
        </>
      ) : null}
      {files && files.length ? (
        <>
          <p className={'font-medium'}>{t('brochureEditor:selected_files')}</p>
          <div className={'grid grid-cols-12 gap-2 py-4 mb-10'}>
            {files.map((el) => {
              if (el) {
                return (
                  <div key={el.id} className={'overflow-hidden'}>
                    <div
                      className={
                        "bg-[#F5F5F7] p-2 pb-4 rounded flex flex-col items-center justify-end gap-2 relative w-full h-[9rem] before:content-[''] before:absolute before:top-0 before:right-0 before:w-8 before:h-8 before:bg-white before:rotate-45 before:transform before:translate-x-4 before:-translate-y-4"
                      }
                    >
                      <PictureAsPdfOutlinedIcon
                        sx={{
                          height: '3rem',
                          width: '100%',
                          fill: '#9898a1',
                        }}
                      />
                      <p
                        title={getFileName(el)}
                        className={
                          'w-full shrink-0 whitespace-nowrap text-ellipsis overflow-hidden font-medium text-center'
                        }
                      >
                        {getFileName(el)}
                      </p>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </>
      ) : null}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={'flex gap-4 pb-8 items-end justify-between'}
      >
        <div className={'w-1/2 flex gap-4'}>
          <InputFormField
            type="text"
            label="Contact name"
            {...register('contactName')}
          />
          <InputFormField
            type="text"
            label="Contact email"
            {...register('contactEmail')}
          />
        </div>
        <div className={'flex gap-2'}>
          <Button
            variant="outlined"
            onClick={resetFilters}
            disabled={loadingItemsToDisplay}
            sx={{
              height: '56px',
              width: '100%',
              minWidth: '100px',
            }}
          >
            <span>{t('propertiesPage:clear')}</span>
          </Button>
          <Button
            variant="contained"
            type={'submit'}
            disabled={loadingItemsToDisplay}
            sx={{
              height: '56px',
              width: '100%',
              minWidth: '100px',
            }}
          >
            <span>{t('propertiesPage:Search')}</span>
          </Button>
        </div>
      </form>
      <div className={'min-h-[400px] flex flex-col gap-8 relative'}>
        {loadingItemsToDisplay ? (
          <div
            className={
              'z-20 w-full h-full absolute inset-0 bg-black bg-opacity-10 flex items-center justify-center'
            }
          >
            <SpinnerLoading />
          </div>
        ) : null}
        <ContactsListingTable
          contacts={contacts as ApiListing<IContact>}
          rowSelectionModel={rowSelectionUsers}
          onRowsSelectionHandler={onRowsSelectionHandler}
          singleSelectableContact
          pageSize={5}
          countByPageSize
          onPageChange={onPageChange}
          page={page}
          setPage={onPageChange}
        />
      </div>
    </div>
  );
};

export default ContactsListing;
