import { useState } from 'react';
import classNames from 'classnames';
import { IContact } from '../../../../../../../types/contacts';
import { getContactName } from '../../../../../../../helpers/utils/contacts';
import Modal from '../../../../../../../components/modal';
import ContactPopupHeader from './header';
import ContactPopupInfo from './info';

const ContactPopup = ({
  option,
  isMain,
  visit,
}: {
  option?: IContact | any;
  isMain: boolean;
  visit?: boolean;
}) => {
  const title = visit ? option?.fullname : getContactName(option);

  const [isOpen, toggle] = useState(false);
  return (
    <Modal
      isOpen={isOpen}
      closeModal={() => toggle(false)}
      openModal={() => toggle(true)}
      buttonProps={{
        title,
        isMain,
        className: classNames(`pr-6 text-sm leading-4 text-gray-900`, {
          '!text-blue': isMain,
        }),
      }}
      closeIconSize="small"
      title={
        <ContactPopupHeader
          className="mt-4 mb-8"
          src={'../'}
          title={title}
          isMain={isMain}
        />
      }
      withTitleDivider={false}
      width={{
        md: 420,
      }}
    >
      <ContactPopupInfo id={option?.id} />
    </Modal>
  );
};

export default ContactPopup;
