import { FC } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import AutocompleteSelect from '../../../../components/form/autoCompleteSelect';
import {
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
  useController,
} from 'react-hook-form';
import InputLayout from '../../../../components/form/components/inputLayout';
import useLocale from '../../../../hooks/useLocale';
import DateRangeField from '../../../../components/form/dateRangeField';
import AddressInput from '../../../../components/form/addressInput';
import { findCountryAlpha2 } from '../../../properties/utils';
import InputFormField from '../../../../components/form/inputFormField';
import ControlledSelectedForm from '../../../../components/form/controlledSelectedForm';
import { useFiltersProperties } from '../../../../hooks/useFiltersProperties';
import FilterAgencyMultiselect from '../../../../components/form/filterAgencyMultiselect';
import { useAuth } from '../../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';

type Props = {
  mainFilterState: any;
  getValues?: any;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  getInputValue: any;
  watch: UseFormWatch<any>;
  control: any;
};

const PromotionsFilter: FC<Props> = ({
  register,
  watch,
  control,
  getInputValue,
  mainFilterState,
  setValue,
}) => {
  const { isAssistant } = useAuth();
  const { agencyHierarchy: hierarchy, assistantAgencyHierarchy } =
    useFiltersProperties(); // address
  const { field: addressField } = useController({ control, name: 'address' });
  const { t } = useTranslation('propertiesPage');
  const { locale } = useLocale();

  const country = findCountryAlpha2(locale, watch('country'));
  const isSwitzerland = country === 'CH';
  return (
    <form>
      <Box>
        <Typography fontSize="1.3rem" marginBottom="20px">
          {' '}
          {t('common:Promotion')}
        </Typography>
        <Box className="grid grid-cols-3 gap-8 mb-4">
          <AutocompleteSelect
            value={watch('reference')}
            register={register}
            className="w-full"
            setValue={setValue}
            isMultiple
            onInputChange={(value: string, label: string) =>
              getInputValue(value, label)
            }
            options={
              mainFilterState['promotion']?.find(
                (elm: any) => elm.label === 'reference'
              )?.options || []
            }
            label={t('Reference')}
            name={'reference'}
          />
          <AutocompleteSelect
            value={watch('title')}
            register={register}
            className="w-full"
            setValue={setValue}
            onInputChange={(value: string, label: string) =>
              getInputValue(value, label)
            }
            options={
              mainFilterState['promotion']?.find(
                (elm: any) => elm.label === 'title'
              )?.options || []
            }
            label={t('Title')}
            name={'title'}
          />
          <FilterAgencyMultiselect
            label={t('Agencies')}
            node={isAssistant ? assistantAgencyHierarchy : hierarchy}
            name="departments"
            value={watch('departments')}
            setValue={setValue}
            className="w-full !h-14"
            isDisabled={isAssistant && !watch('agents')}
          />
          <ControlledSelectedForm
            options={
              mainFilterState['promotion']?.find(
                (elm: any) => elm.label === 'service'
              )?.options || []
            }
            setValue={setValue}
            value={watch('service')}
            label={t('Service')}
            name={'service'}
            register={register}
          />
          <ControlledSelectedForm
            options={
              mainFilterState['promotion']?.find(
                (elm: any) => elm.label === 'status'
              )?.options || []
            }
            //isDisable={disableField}
            value={watch('status')}
            label={t('Status')}
            name={'status'}
            register={register}
          />
          <ControlledSelectedForm
            label={t('Agents')}
            name={'agents'}
            options={
              mainFilterState['promotion']?.find(
                (elm: any) => elm.label === 'agents'
              )?.options || []
            }
            value={watch('agents')}
            register={register}
          />
        </Box>

        <Divider sx={{ margin: '20px 0', border: '1px solid #E6E8EC' }} />
        <Box>
          <Typography fontSize="1.3rem" marginBottom="20px">
            {' '}
            {t('Availability')}
          </Typography>
          <Box className="grid grid-cols-3 gap-8 mb-4">
            <DateRangeField
              nameFrom="availableFrom"
              nameTo="availableTo"
              labelFrom={t('From')}
              labelTo={t('To')}
              control={control}
            />
          </Box>
        </Box>
        <Divider sx={{ margin: '20px 0', border: '1px solid #E6E8EC' }} />
        <Typography fontSize="1.3rem" marginBottom="20px">
          {' '}
          {t('Location')}
        </Typography>
        <Box className="grid grid-cols-3 gap-8 mb-4">
          <InputLayout label={t('Location')}>
            <AddressInput
              withoutMaps
              value={addressField.value}
              onChange={addressField.onChange}
            />
          </InputLayout>
          <AutocompleteSelect
            label={t('Country')}
            register={register}
            name={'country'}
            setValue={setValue}
            className="w-full"
            onInputChange={(value: string, label: string) =>
              getInputValue(value, label)
            }
            options={
              mainFilterState['location']?.find(
                (elm: any) => elm.label === 'country'
              )?.options || []
            }
            value={watch('country')}
          />
          <AutocompleteSelect
            value={watch('city')}
            register={register}
            className="w-full"
            setValue={setValue}
            onInputChange={(value: string, label: string) =>
              getInputValue(value, label)
            }
            options={
              mainFilterState['location']?.find(
                (elm: any) => elm.label === 'city'
              )?.options || []
            }
            label={t('City')}
            name={'city'}
          />
          <AutocompleteSelect
            value={watch('zipCode')}
            register={register}
            className="w-full"
            setValue={setValue}
            onInputChange={(value: string, label: string) =>
              getInputValue(value, label)
            }
            options={
              mainFilterState['location']?.find(
                (elm: any) => elm.label === 'zipCode'
              )?.options || []
            }
            label={t('Zip_code')}
            name="zipCode"
          />
          <AutocompleteSelect
            value={watch('adresse')}
            register={register}
            className="w-full"
            setValue={setValue}
            onInputChange={(value: string, label: string) =>
              getInputValue(value, label)
            }
            options={
              mainFilterState['location']?.find(
                (elm: any) => elm.label === 'adresse'
              )?.options || []
            }
            label={t('Address')}
            name="adresse"
          />
          {isSwitzerland && (
            <InputFormField
              type="text"
              label={t('Canton')}
              {...register('canton')}
            />
          )}
          {isSwitzerland && (
            <InputFormField
              type="text"
              label={t('District')}
              {...register('district')}
            />
          )}
        </Box>
      </Box>
    </form>
  );
};

export default PromotionsFilter;
