import { Button, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import CalendarIcon from '../../../features/icons/calendarIcon';
import EditIcon from '../../../features/icons/editIcon';
import PermissionIcon from '../../../features/icons/permissionIcon';
import TabGridTag from '../../../features/tags/tabGridTag';
import SelectPdfTemplate from '../../../components/common/selectPdfTemplate';
import { PreviewSubjectTypes } from '../../../types/brochureEditor';

interface Props {
  id: number;
  reference: string | null;
  createdAt: string;
}

function SinglePromotionHeader(props: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onEditButtonClick = () => {
    navigate(`/promotions/${props.id}/edit`);
  };
  const { id } = useParams();

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        color: 'text.primary',
        bgColor: 'background.default',
      }}
    >
      <section className="flex flex-col gap-2">
        <Typography variant="h3">
          {t('promotionPage:promotionDetails')}
        </Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <TabGridTag
            icon={<PermissionIcon color="#3446B9" />}
            iconPlacement="start"
            textContent={`ID: ${props.reference}`}
            color="#3446B9"
            className="flex gap-1 items-center py-0 px-2 !max-w-full"
          />

          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <CalendarIcon />
            <p>{props.createdAt}</p>
          </Box>
        </Box>
      </section>
      <section className="flex gap-4 justify-center items-start">
        <SelectPdfTemplate
          subjectId={id}
          subjectType={PreviewSubjectTypes.PROMOTION}
        />
        <Button
          onClick={onEditButtonClick}
          variant="contained"
          disableElevation
          startIcon={<EditIcon color={'#fff'} />}
        >
          {t('common:editInfos')}
        </Button>
      </section>
    </Box>
  );
}

export default SinglePromotionHeader;
