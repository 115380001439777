import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { IProperty } from '../../../../../types/property';
import StepsFieldset from '../../../../../components/stepper/stepsFieldset';
import InputFormField from '../../../../../components/form/inputFormField';
import { numberInputProps } from './const';

function Rooms() {
  const { register } = useFormContext<IProperty>();
  const { t } = useTranslation('propertiesPage');

  return (
    <StepsFieldset className="grid grid-cols-4" title={t('Rooms')}>
      <InputFormField
        type="text"
        isRequired
        label={t('rooms')}
        inputProps={numberInputProps}
        {...register('rooms', {
          valueAsNumber: true,
        })}
      />
      <InputFormField
        type="number"
        isRequired
        label={t('Bedrooms')}
        inputProps={numberInputProps}
        {...register('bedrooms', {
          valueAsNumber: true,
        })}
      />
      <InputFormField
        type="number"
        isRequired
        label={t('Bathrooms')}
        inputProps={numberInputProps}
        {...register('bathrooms', {
          valueAsNumber: true,
        })}
      />
      <InputFormField
        type="number"
        label={'WC'}
        inputProps={numberInputProps}
        {...register('wc', {
          valueAsNumber: true,
        })}
      />
    </StepsFieldset>
  );
}

export default Rooms;
