import CheckIcon from '@mui/icons-material/Check';
import { Button, IconButton, InputAdornment, InputLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { http } from '../../libs/axios';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import InputFormField from '../../components/form/inputFormField';
import CloseIcon from '../../features/icons/closeIcon';
import FormControl from '@mui/material/FormControl';
import { useForm } from 'react-hook-form';

interface Data {
  [key: string]: boolean;
}

interface CompletionSettings {
  id: string;
  fields: string;
  keyName: string;
  customSettings?: string;
}

export default function AiSettings() {
  const { t } = useTranslation('propertiesPage');

  const [titleData, setTitleData] = useState<Data>({});
  const [descriptionData, setDescriptionData] = useState<Data>({});
  const [titleId, setTitleId] = useState<string | null>('');
  const [descriptionId, setDescriptionId] = useState<string | null>('');
  const [customPrompt, setCustomPrompt] = useState<string>('');
  const [customTo, setCustomTo] = useState<string | null>('');
  const [titleIsChanged, setTitleIsChanged] = useState(false);
  const [descriptionIsChanged, setDescriptionIsChanged] = useState(false);
  const [titleObject, setTitleObject] = useState<CompletionSettings | null>(
    null
  );
  const [descriptionObject, setDescriptionObject] =
    useState<CompletionSettings | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await http.get('open_a_i_completion_user_settings');
        const data = res.data?.['hydra:member'];
        const titleObject = data.find(
          (item: CompletionSettings) =>
            item.keyName === 'PROPERTY_TITLE_COMPLETION'
        );

        const descriptionObject = data.find(
          (item: CompletionSettings) =>
            item.keyName === 'PROPERTY_DESCRIPTION_COMPLETION'
        );

        if (titleObject) {
          setTitleData(JSON.parse(titleObject.fields));
          setTitleId(titleObject.id);
          setTitleObject(titleObject);
        }

        if (descriptionObject) {
          setDescriptionData(JSON.parse(descriptionObject.fields));
          setDescriptionId(descriptionObject.id);
          setDescriptionObject(descriptionObject);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [descriptionIsChanged, titleIsChanged]);

  const { register } = useForm();
  const handleSave = async (data: Data, id: string | null, message: string) => {
    let updatedData;

    if (id == customTo) {
      updatedData = {
        fields: JSON.stringify(data),
        customSettings: customPrompt,
      };
    } else {
      updatedData = {
        fields: JSON.stringify(data),
      };
    }
    try {
      await http
        .patch(`open_a_i_completion_user_settings/${id}`, updatedData)
        .then((res) => {
          if (res.status === 200) {
            toast.success(`${t(message)}`, {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 4000,
              style: { width: '450px', letterSpacing: '1px' },
            });
          }
        });
      setDescriptionIsChanged(false);
      setTitleIsChanged(false);
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  const updatedDescriptionList = (name: string, e: any) => {
    setDescriptionIsChanged(true);
    setDescriptionData({
      ...descriptionData,
      [name]: !e.target.selected,
    });
  };
  const updatedTitleList = (name: string, e: any) => {
    setTitleIsChanged(true);
    setTitleData({
      ...titleData,
      [name]: !e.target.selected,
    });
  };

  const defaultCustom = (text: string, select: boolean, id?: number) => {
    !select
      ? setCustomPrompt(text)
      : setCustomPrompt(
          id === 0
            ? `${descriptionObject?.customSettings}`
            : `${titleObject?.customSettings}`
        );
  };
  return (
    <div>
      <div className="flex flex-row-reverse mr-4"></div>
      <div className="flex justify-between mb-16 w-[98%] mx-auto">
        <span className="w-[50%]">
          <InputFormField
            {...register('customSettings')}
            placeholder="Add Custom Prompt Title or Description"
            onChange={(e) => {
              defaultCustom(e.target.value, false);
            }}
            id="customPrompt"
            value={customPrompt}
            inputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setCustomPrompt('');
                    }}
                    aria-label={t('clear')}
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            disabled={!titleIsChanged && !descriptionIsChanged}
          />
        </span>
        <div>
          <FormControl fullWidth>
            <InputLabel className="mt-[-4px]" id="demo-simple-select-label">
              {t('choose')}
            </InputLabel>
            <Select
              className="w-[180px]"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Create text for"
            >
              <MenuItem
                value={1}
                onClick={() => {
                  defaultCustom(customPrompt, true, 1);
                  setCustomTo(titleId);
                  setTitleIsChanged(true);
                  setDescriptionIsChanged(false);
                }}
              >
                {t('Title')}
              </MenuItem>
              <MenuItem
                value={0}
                onClick={() => {
                  defaultCustom(customPrompt, true, 0);
                  setCustomTo(descriptionId);
                  setDescriptionIsChanged(true);
                  setTitleIsChanged(false);
                }}
              >
                {t('Description')}
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      <div className="flex justify-between items-center mb-16 w-[98%] mx-auto">
        <div className="w-[50%]">
          <InputLabel
            className="mb-2 font-['Inter'] text-base !font-semibold"
            id="demo-multiple-title-label"
          >
            {t('AI_Title')} :
          </InputLabel>
          <Select
            className="w-full h-[55px]"
            labelId="demo-multiple-title-label"
            id="demo-multiple-title"
            multiple
            value={Object.keys(titleData).filter(
              (item) => titleData[item] && item
            )}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: '300px',
                  marginTop: '8px',
                },
              },
            }}
          >
            {Object.keys(titleData).map((name, i) => (
              <MenuItem
                key={i}
                value={name}
                disabled={
                  Object.keys(titleData).filter((item) => titleData[item])
                    .length > 4 &&
                  !Object.keys(titleData)
                    .filter((item) => titleData[item])
                    .includes(name)
                }
                onClick={(e) => updatedTitleList(name, e)}
              >
                {t(`${name}`)}
              </MenuItem>
            ))}
          </Select>
        </div>
        <Button
          className="w-[120px] !h-[44px]"
          disabled={!titleIsChanged}
          onClick={() =>
            handleSave(
              titleData,
              titleId,
              'Ai_settings_title_updated_successfully'
            )
          }
          variant="contained"
          disableElevation
          startIcon={<CheckIcon fontSize="small" />}
        >
          {t('usersPage:save')}
        </Button>
      </div>

      <div className="flex justify-between items-center w-[98%] mx-auto">
        <div className="w-[50%]">
          <InputLabel
            className="mb-2 font-['Inter'] text-base !font-semibold"
            id="demo-multiple-descriptions-label"
          >
            {t('AI_Descriptions')} :
          </InputLabel>
          <Select
            className="w-full h-[55px]"
            type="checkbox"
            labelId="demo-multiple-descriptions-label"
            id="demo-multiple-title"
            multiple
            value={Object.keys(descriptionData).filter(
              (item) => descriptionData[item] && item
            )}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: '300px',
                  marginTop: '8px',
                },
              },
            }}
          >
            {Object.keys(descriptionData).map((name, i) => (
              <MenuItem
                key={i}
                value={name}
                disabled={
                  Object.keys(descriptionData).filter(
                    (item) => descriptionData[item]
                  ).length > 9 &&
                  !Object.keys(descriptionData)
                    .filter((item) => descriptionData[item])
                    .includes(name)
                }
                onClick={(e) => updatedDescriptionList(name, e)}
              >
                {t(`${name}`)}
              </MenuItem>
            ))}
          </Select>
        </div>
        <Button
          className="w-[120px] h-[44px]"
          disabled={!descriptionIsChanged}
          onClick={() =>
            handleSave(
              descriptionData,
              descriptionId,
              'Ai_settings_description_updated_successfully'
            )
          }
          variant="contained"
          disableElevation
          startIcon={<CheckIcon fontSize="small" />}
        >
          {t('usersPage:save')}
        </Button>
      </div>

      <div className="flex flex-row-reverse mr-4 absolute right-[5rem] bottom-6 z-50"></div>
    </div>
  );
}
