import { fullName } from '.';
import { http } from '../../libs/axios';
import { IContact, IContactListItem } from '../../types/contacts';

export async function updateHistoric(id?: string | number, message?: string) {
  if (id) {
    const payload = {
      historicAction: message,
    };

    return http.post(`/contact/${id}/historics`, payload);
  }
}

enum CONTACT_TYPES {
  COMPANY = 1,
  INDIVIDUAL = 2,
}

export function isCompanyContact(contact: IContact | IContactListItem) {
  const isCompanyByBusinessType =
    contact.businessType && contact.businessType.id === CONTACT_TYPES.COMPANY;
  return Boolean(contact.company) || isCompanyByBusinessType;
}

export function getContactName(contact?: IContact) {
  if (contact) {
    const individualName = contact.individual
      ? fullName(contact.individual.firstname, contact.individual.lastname)
      : '';
    const companyName = contact.company?.legalName || '';

    return isCompanyContact(contact)
      ? companyName
      : contact.individual
      ? individualName
      : // @ts-ignore
      contact.firstname
      ? // @ts-ignore
        fullName(contact.firstname, contact.lastname)
      : '';
  }

  return '';
}

export function getContactGreeting(contact?: IContact) {
  if (contact) {
    return isCompanyContact(contact) ? null : contact.individual.greeting;
  }

  return null;
}
