import { GridColumns } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Skeleton } from '@mui/material';
import {
  IMercureMatching,
  IMercureMatchingTime,
} from '../../../../types/matching';
import MatchingStatus from './matchingStatus';
import PropertyFirstImage from '../../../../components/tableGridX/propertyFirstImage';
import PermissionIcon from '../../../../features/icons/permissionIcon';
import CreatedUpdatedHeader from '../../../../components/tableGridX/createdUpdatedHeader';
import CreatedUpdated from '../../../../components/tableGridX/createdUpdated';
import useLocale from '../../../../hooks/useLocale';
import ContactsMatched from './contactsMatched';

export default function useColumns({
  isLoading,
  remainingTime,
  mercureNotifications,
}: {
  isLoading: boolean;
  remainingTime: IMercureMatchingTime | null;
  mercureNotifications: IMercureMatching | null;
}) {
  const { t } = useTranslation('propertiesPage');
  const { locale } = useLocale();

  const columns: GridColumns = [
    {
      field: 'status',
      minWidth: 100,
      maxWidth: 176,
      flex: 1,
      headerName: `${t('Status')}`,
      renderCell: ({ row }) => {
        const { id, status, spentTime } = row;

        return (
          <MatchingStatus
            status={status}
            remainingTime={remainingTime}
            mercureNotifications={mercureNotifications}
            spentTime={spentTime}
            id={id}
          />
        );
      },
    },
    {
      field: 'property',
      minWidth: 300,
      maxWidth: 590,
      flex: 1,
      headerName: `${t('Property')}`,
      renderCell: ({ row }) => {
        const { property } = row;
        return (
          <div className={'flex items-start gap-x-6 w-full'}>
            <div className={'py-2 w-2/6 shrink-0 max-w-[88px]'}>
              <PropertyFirstImage row={property} />
            </div>
            <div className={'flex flex-col py-4 overflow-hidden'}>
              {property.translations &&
              property.translations[locale] &&
              property.translations[locale].title ? (
                <Link
                  className={'text-base inline-flex'}
                  to={`/properties/${property.id}`}
                  id={`img_property_${property.id}`}
                >
                  <p
                    title={property.translations[locale].title}
                    className={'font-medium overflow-ellipsis overflow-hidden'}
                  >
                    {property.translations[locale].title}
                  </p>
                </Link>
              ) : null}
              <Box className="w-3/5 flex items-center py-2 gap-2">
                <div
                  className="flex py-1 px-2 rounded-md items-center"
                  style={{ border: '1px solid #3446B9 ' }}
                >
                  <PermissionIcon className="mr-2 col-span-1" color="#3446B9" />{' '}
                  <span className="text-blue leading-5">
                    {property.reference}
                  </span>
                </div>
              </Box>
            </div>
          </div>
        );
      },
    },
    {
      field: 'generated',
      minWidth: 200,
      maxWidth: 256,
      flex: 1,
      headerName: `${t('Generated_by')}`,
      renderCell: ({ row }) => {
        const { generatedBy } = row;
        return (
          <div className={'h-full flex items-start pt-4'}>
            <div className={'flex items-center gap-2'}>
              {generatedBy ? (
                <div
                  className={
                    'w-[24px] h-[24px] rounded-full bg-gray-100 overflow-hidden flex items-center justify-center'
                  }
                >
                  {generatedBy.avatar ? (
                    <img
                      src={generatedBy.avatar}
                      alt={`${generatedBy.firstname} ${generatedBy.lastname}`}
                    />
                  ) : null}
                </div>
              ) : null}
              <span
                className={
                  'text-[12px] 2xl:text-[16px] font-medium leading-none'
                }
              >
                {generatedBy
                  ? `${generatedBy.firstname} ${generatedBy.lastname}`
                  : ''}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      field: 'users',
      minWidth: 200,
      maxWidth: 256,
      flex: 1,
      headerName: `${t('Users_matched')}`,
      renderCell: ({ row }) => {
        const { id, contactsMatched } = row;

        return (
          <ContactsMatched
            id={id}
            remainingTime={remainingTime}
            count={contactsMatched}
          />
        );
      },
    },
    {
      field: 'createdAt',
      minWidth: 200,
      flex: 1,
      renderHeader: () => <CreatedUpdatedHeader />,
      renderCell: ({ row }) => (
        <CreatedUpdated created={row.createdAt} updated={row.updatedAt} />
      ),
    },
  ];

  const commonSettings = {
    sortable: false,
    disableColumnMenu: true,
  };

  return columns.map((column) => ({
    ...commonSettings,
    ...column,
    ...(isLoading
      ? {
          renderCell: () => (
            <Skeleton
              variant="text"
              animation="wave"
              width="100%"
              height={20}
            />
          ),
        }
      : {}),
  }));
}
