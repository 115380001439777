import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

export default function PDFIcon({ color, className }: IconProps) {
  const { mode } = useThemeMode();

  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15 12.5V2H2.5v10.5H15z"
      ></path>
      <path
        fill={color}
        d="M4.316 9V5.364H5.75c.275 0 .51.052.704.158.195.104.343.249.444.435.103.184.155.397.155.639 0 .241-.052.454-.156.64a1.077 1.077 0 01-.453.43 1.521 1.521 0 01-.714.155h-.914v-.616h.79c.148 0 .27-.026.366-.076a.506.506 0 00.216-.215.673.673 0 00.073-.318.656.656 0 00-.073-.316.486.486 0 00-.216-.211.791.791 0 00-.37-.077h-.518V9h-.77zM8.842 9H7.553V5.364h1.3c.366 0 .68.072.945.218.264.144.466.352.609.623.143.271.214.596.214.973 0 .38-.071.705-.214.977a1.477 1.477 0 01-.613.627c-.265.145-.582.218-.952.218zm-.52-.659h.488c.228 0 .419-.04.574-.12a.777.777 0 00.351-.379c.08-.171.12-.393.12-.664 0-.268-.04-.488-.12-.658a.77.77 0 00-.35-.377 1.235 1.235 0 00-.573-.12h-.49V8.34zM11.19 9V5.364H13.6v.634h-1.64v.866h1.48v.634h-1.48V9h-.768z"
      ></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12.5 14.5H.5v-11"
      ></path>
    </svg>
  );
}
