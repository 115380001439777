import React from 'react';

type Props = {
  index: number;
  name: string;
  active: boolean;
  count: number;
};

export default function ItemList({ name, active, index, count }: Props) {
  return (
    <li>
      <a
        href="#"
        className={`${
          active === true ? 'bg-[#3446B9] text-white' : 'text-[#1D1D1F]'
        }  flex justify-center items-center px-[4px] py-[8px] h-[24px] border border-#E6E8EC rounded-[4px] `}
        aria-current="page"
      >
        <span className="Inter text-[11px] font-medium leading-4">
          {name} {index === 2 && `(${count})`}
        </span>
      </a>
    </li>
  );
}
