import { createElement } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import { Paper } from '@mui/material';
import slugify from 'slugify';
import Tabs from '../../../../components/common/tabs';
import TitleHeader from '../../../../components/common/titleHeader';
import { IContact } from '../../../../types/contacts';
import { checkType } from '../../components/forms/contact-type-form';
import useSWR from 'swr';
import StatusChanger from '../../components/status-changer';
import {
  getContactName,
  isCompanyContact,
} from '../../../../helpers/utils/contacts';
import { useTranslation } from 'react-i18next';
import { useQuery } from '../../../../hooks/useQuery';

export interface ContactTabProps {
  data: IContact;
  mapTabNamesToComponents: any;
  mutate: any;
  id?: string;
}

const filterTabs = (
  stepsData: any[],
  isCompany: boolean,
  clientType: boolean
) => {
  let test = stepsData;
  if (!clientType || !isCompany) {
    test = stepsData.filter(
      (item) =>
        item.title !== (!clientType ? 'Search criteria' : '') &&
        item.title !== (!isCompany ? 'Contacts' : '')
    );
  }
  return test;
};

const TabbedContactInformation = (props: ContactTabProps) => {
  const { id, data, mapTabNamesToComponents, mutate } = props;
  const isCompany = isCompanyContact(data);
  const { data: types } = useSWR(`/contact_types`);
  const clientType = checkType(types, data, 'client');
  const query = useQuery();
  const isEditMode = query.get('mode') === 'edit';
  const { t } = useTranslation('common');
  const stepNames = Object.keys(mapTabNamesToComponents);
  const stepsData = stepNames.map((name) => {
    return {
      slug: slugify(name.toLowerCase()),
      title: name,
      component: mapTabNamesToComponents[name],
    };
  });

  return (
    <div>
      <TitleHeader
        title={getContactName(data)}
        goBackLink="/contacts"
        rightElement={
          <div className="flex gap-8">
            {data.lead ? (
              <StatusChanger
                mutate={mutate}
                id={data.id}
                status={data.status ? data.status.id : null}
              />
            ) : null}
            <Link
              className={'py-3 px-4 rounded border text-left group edit_btn'}
              to={`/contacts/${id}/contact-information${
                isEditMode ? '' : '?mode=edit'
              }`}
            >
              <span
                className={
                  'text-[14px] font-medium transition-opacity duration-300 group-hover:opacity-80'
                }
              >
                {isEditMode ? t('Back_to_preview') : t('Edit_contact')}
              </span>
            </Link>
          </div>
        }
      >
        <>
          <Tabs items={filterTabs(stepsData, isCompany, clientType)} />
          <Paper square elevation={0}>
            <div className="">
              <div className="pt-12 pb-12 px-12">
                <Routes>
                  {filterTabs(stepsData, isCompany, clientType).map(
                    ({ slug, component }) => (
                      <Route
                        key={slug}
                        path={slug}
                        element={
                          component &&
                          createElement(component, {
                            data: data,
                            id: id,
                            mutate: mutate,
                          })
                        }
                      />
                    )
                  )}
                </Routes>
              </div>
            </div>
          </Paper>
        </>
      </TitleHeader>
    </div>
  );
};

export default TabbedContactInformation;
