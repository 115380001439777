import useSWR from 'swr';
import { Control, Controller } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';

import { AutocompleteOption } from '../../../components/form/autoCompleteField';
import { useAuth } from '../../../hooks/useAuth';
import InputLayout from '../../../components/form/components/inputLayout';
import { useTranslation } from 'react-i18next';

function IndividualAgent({ control }: { control: Control<any> }) {
  const options = useAgentsList();
  const { t } = useTranslation('common');
  return (
    <InputLayout label={t('Agent_in_charge')} isRequired>
      <Controller
        control={control}
        name="agentInCharge"
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            onChange={(event, item) => {
              onChange(item);
            }}
            value={value}
            options={options}
            renderInput={(params) => (
              <TextField
                className="agent_in_Charge_contact"
                variant="outlined"
                required
                {...params}
              />
            )}
          />
        )}
      />
    </InputLayout>
  );
}

export default IndividualAgent;

export function useAgentsList() {
  const { isAssistant, user } = useAuth();
  const { data: users } = useSWR(
    !isAssistant && `/users/assigned?itemsPerPage=100`
  );

  let options: AutocompleteOption[];
  if (isAssistant) {
    const { users }: any = user;
    options =
      users.length > 0
        ? users.map(({ id, firstname, lastname }: any) => {
            return { id: String(id), label: `${firstname} ${lastname}` };
          })
        : [];
  } else {
    options =
      users && users['hydra:member']
        ? users['hydra:member'].map(
            (item: { id: any; firstname: string; lastname: string }) => ({
              id: item.id.toString(),
              label: `${item.firstname} ${item.lastname}`,
            })
          )
        : [];
  }

  return options;
}
