import { useParams } from 'react-router-dom';
import SinglePropertyHeader from './singlePropertyHeader';
import { Box } from '@mui/material';
import useSWR from 'swr';
import useLocale from '../../../hooks/useLocale';
import { useEffect, useState } from 'react';
import PropertyInfos from './propertyInfos';
import PropertyContact from './propertyContact';
import { IPropertyApi } from '../../../types/property';

function SingleProperty() {
  const { propertyId } = useParams();
  const [callApi, setCallApi] = useState(false);
  const { data: property } = useSWR<IPropertyApi>(
    callApi && `/v1/properties/${propertyId}`
  );
  const { locale } = useLocale();
  useEffect(() => {
    setCallApi(true);
  }, [locale]);

  if (!property || !propertyId) return null;
  return (
    <Box component="section" className="w-full ">
      <SinglePropertyHeader property={property} />
      <Box className="w-full grid grid-cols-3 gap-4">
        <Box className="col-span-2">
          <PropertyInfos property={property} />
        </Box>
        <Box className="col-span-1">
          <PropertyContact property={property} />
        </Box>
      </Box>
    </Box>
  );
}

export default SingleProperty;
