import { FC } from 'react';
import { IProperty, PropertyStepperStep } from '../../../../../types/property';
import { useIsRent } from '../../../../../hooks/useIsRent';
import { useForm } from 'react-hook-form';
import { stepperFormId } from '../../../../../const';
import TransactionDetailsFields from './fields';

function transformData(data: any) {
  const transformedData = { ...data };
  if (data.propertyCommissionRepartitionIntermediate) {
    transformedData.propertyCommissionRepartition = [
      ...data.propertyCommissionRepartition,
      ...data.propertyCommissionRepartitionIntermediate,
    ];
  }

  return transformedData;
}

const TransactionDetailsStep: FC<PropertyStepperStep> = ({
  defaultValues,
  onSubmit,
  serverData,
}) => {
  const { handleSubmit, register, getValues, resetField, watch, control } =
    useForm<IProperty>({ defaultValues });
  const isRent = useIsRent(serverData);
  const name = isRent ? 'propertyRent' : 'propertyBuy';

  const localOnSubmit = (data: any) => {
    const body = { ...data };

    body[name] = transformData(data[name]);
    onSubmit(body);
  };

  return (
    <form id={stepperFormId} onSubmit={handleSubmit(localOnSubmit)}>
      <div className="grid gap-16">
        <TransactionDetailsFields
          name={name}
          currency={serverData?.pricingBuy?.currency?.id}
          watch={watch}
          resetField={resetField}
          register={register}
          getValues={getValues}
          control={control}
        />
      </div>
    </form>
  );
};

export default TransactionDetailsStep;
