import { FormControl, MenuItem, Select } from '@mui/material';
import { useContext } from 'react';
import { UseFormRegister } from 'react-hook-form';

import { StepperContext } from '../../app/providers/stepper';
import InputLayout from './components/inputLayout';

export type SelectOption = {
  id?: number;
  value: string;
  locale?: string;
};

type SelectForm = {
  name: string;
  isRequired?: boolean;
  className?: string;
  label: string;
  error?: any;
  sleep?: (duration: number) => Promise<void>;

  setSelectedIdsToSend?: any;
  register: UseFormRegister<any>;
  options: SelectOption[];
  isDisable?: boolean;
  isMultiple?: boolean;
  value: string | any;
  setValue?: any;
  updateInfo?: (action: { name: string; value: any }) => void;
};
const defaultOptions = [{ id: 0, value: 'empty' }];

const ControlledSelectedForm = ({
  label,
  name,
  className,
  register,
  sleep,
  isRequired = false,
  error,
  value,
  setSelectedIdsToSend,
  updateInfo,
  // setValue,
  isDisable = false,
  isMultiple = false,
  options = defaultOptions,
}: SelectForm) => {
  const { handleChange } = useContext(StepperContext);

  return (
    <InputLayout label={label} isRequired={isRequired}>
      <FormControl error={error} fullWidth>
        <Select
          className={'w-full h-12' + ' ' + className}
          defaultValue={''}
          disabled={isDisable}
          multiple={isMultiple}
          value={isMultiple ? value.join(',') : value}
          displayEmpty
          MenuProps={{
            PaperProps: {
              onScroll: async () => {
                sleep && (await sleep(1e3));
              },
              style: {
                maxHeight: 300,
              },
            },
          }}
          {...register(name, {
            onChange: (e) => {
              handleChange(e);
              isMultiple &&
                updateInfo &&
                updateInfo({
                  name,
                  value: String(e.target.value),
                });
            },
          })}
        >
          {options.map((item: SelectOption, index: number) => (
            <MenuItem
              onClick={(event) => {
                const { myValue } = event.currentTarget.dataset as any;
                setSelectedIdsToSend &&
                  isMultiple &&
                  setSelectedIdsToSend((prev: any) => [...prev, myValue]);
                setSelectedIdsToSend &&
                  !isMultiple &&
                  setSelectedIdsToSend(myValue);
              }}
              key={index}
              value={item?.id}
              data-my-value={item?.id}
            >
              {item.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </InputLayout>
  );
};
export default ControlledSelectedForm;
