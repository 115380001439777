import classNames from 'classnames';
import ControlLayout from '../controlLayout';
import { EditorControlProps } from '../../../../types/brochureEditor';
import { Tooltip } from '@mui/material';

const options = [
  {
    value: 'start',
  },
  {
    value: 'center',
  },
  {
    value: 'end',
  },
  {
    value: 'stretch',
  },
];

export const AlignmentControl = ({
  value,
  onChange,
  label,
  direction,
}: EditorControlProps<string> & { direction: 'horizontal' | 'vertical' }) => {
  const alignmentProp =
    direction === 'vertical' ? 'alignItems' : 'justifyItems';
  return (
    <ControlLayout label={label}>
      <div className="flex gap-2">
        {options.map((o) => (
          <Tooltip key={o.value} title={o.value} arrow placement="top">
            <button
              className={classNames('w-12 h-12 p-2 bg-white grid border', {
                'border-gray-200': o.value !== value,
                'text-blue border-blue': o.value === value,
              })}
              onClick={() => onChange?.(o.value)}
              aria-label={o.value}
              style={{ [alignmentProp]: o.value }}
            >
              <div
                className={classNames('bg-current inline-flex', {
                  'w-4 min-h-[16px]': direction === 'vertical',
                  'min-w-[16px] h-4': direction === 'horizontal',
                })}
              ></div>
            </button>
          </Tooltip>
        ))}
      </div>
    </ControlLayout>
  );
};

export const VerticalAlignmentControl = (props: EditorControlProps<string>) => (
  <AlignmentControl direction="vertical" {...props} />
);

export const HorizontalAlignmentControl = (
  props: EditorControlProps<string>
) => <AlignmentControl direction="horizontal" {...props} />;

export default AlignmentControl;
