import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

export default function BrochuresIcon({
  color,
  width = '32',
  height = '32',
}: IconProps) {
  const { mode } = useThemeMode();

  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 32 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Notebook">
          <path
            id="Vector"
            d="M14 14.5H23"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M14 18.5H23"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_3"
            d="M27.8182 3.5H4.18182C3.52912 3.5 3 4.02912 3 4.68182V28.3182C3 28.9709 3.52912 29.5 4.18182 29.5H27.8182C28.4709 29.5 29 28.9709 29 28.3182V4.68182C29 4.02912 28.4709 3.5 27.8182 3.5Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_4"
            d="M9 3.5V29.5"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </>
  );
}
