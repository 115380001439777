import { Button } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  usePagesEditorComputables,
  usePagesEditor,
} from '../../lib/hooks/usePagesEditor';

import EditorSidebarSection from '../sidebarSection';
import Sheet from '../sheet';
import BlockWithIndex from '../blockWithIndex';
import Mockup from '../mockup';

import CloseIcon from '../../../../features/icons/closeIcon';
import PlusIcon from '../../../../features/icons/plusIcon';
import { PageTypes } from '../../../../types/brochureEditor';
import StackOfPages from './stackOfPages';

const ThumbnailsList = ({ addNew = true }: { addNew?: boolean }) => {
  const { addPage, editedPageId, setEditedPage, removePage } = usePagesEditor();
  const { pages } = usePagesEditorComputables();
  const { t } = useTranslation('brochureEditor');

  return (
    <div className="flex flex-col w-full">
      {addNew && (
        <EditorSidebarSection isLarge>
          <Button
            variant="outlined"
            classes={{
              root: 'w-full !rounded-none',
            }}
            onClick={() => addPage()}
          >
            <span className="!text-[11px] !text-black !font-medium">
              {t('add_page')}
            </span>
          </Button>
        </EditorSidebarSection>
      )}

      <EditorSidebarSection isLarge className="flex-grow overflow-y-auto">
        <div className="grid gap-4">
          {pages.map((page, index) => (
            <BlockWithIndex
              key={page.id}
              index={index}
              rightSlot={
                page.id === editedPageId && (
                  <button
                    onClick={() => removePage(page.id)}
                    title={t('remove')}
                  >
                    <CloseIcon color="#F50057" width="12" height="12" />
                  </button>
                )
              }
            >
              <button
                className={classNames('relative z-0 text-gray-100 border', {
                  'border-gray-200 hover:border-gray-300 cursor-pointer':
                    page.id !== editedPageId,
                  'border-blue': page.id === editedPageId,
                })}
                onClick={() => setEditedPage(page.id)}
              >
                <Sheet>
                  {page.mockup && (
                    <Mockup
                      page={page}
                      gridTemplateAreas={page.mockup}
                      renderCell={() => <div className="grid bg-current"></div>}
                    />
                  )}
                </Sheet>

                {page.type === PageTypes.REPEATABLE && (
                  <StackOfPages highlighted={page.id === editedPageId} />
                )}
              </button>
            </BlockWithIndex>
          ))}

          {addNew && (
            <button
              className="p-2"
              onClick={() => addPage()}
              title={t('add_page')}
            >
              <div className="aspect-[210/297] bg-white grid place-items-center border border-gray-200 hover:border-gray-300">
                <PlusIcon />
              </div>
            </button>
          )}
        </div>
      </EditorSidebarSection>
    </div>
  );
};

export default ThumbnailsList;
