import { useQuery } from '../../../../../hooks/useQuery';
import PortalForm from '../../form';

interface Props {
  data: any;
  id: string;
  mutate?: any;
  register: any;
  handleSubmit: any;
  reset: any;
  control: any;
  setValue: any;
  onSubmit: any;
  isLoading: any;
  onCancelClick?: () => void;
  onDeleteClick?: () => void;
}

const PortalInformationTab = (props: Props) => {
  const {
    data,
    mutate,
    handleSubmit,
    control,
    register,
    setValue,
    onSubmit,
    isLoading,
    onCancelClick,
    onDeleteClick,
  } = props;
  const query = useQuery();
  const isEditMode = query.get('mode') === 'edit';

  return (
    <>
      <div>
        <PortalForm
          mutate={mutate}
          isWatchMode={!isEditMode}
          isEditMode={isEditMode}
          data={data}
          handleSubmit={handleSubmit}
          control={control}
          register={register}
          setValue={setValue}
          onSubmit={onSubmit}
          isLoading={isLoading}
          onCancelClick={onCancelClick}
          onDeleteClick={onDeleteClick}
        />
      </div>
    </>
  );
};

export default PortalInformationTab;
