import { usePagesEditorComputables } from '../../../lib/hooks/usePagesEditor';

const PageIndexBlock = () => {
  const { editedPageIndex } = usePagesEditorComputables();
  return (
    <div>{typeof editedPageIndex === 'number' ? editedPageIndex + 1 : ''}</div>
  );
};

export default PageIndexBlock;
