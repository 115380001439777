import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Fade, Modal } from '@mui/material';
import { GridRowId, GridSelectionModel } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

// hooks
import useMercure from '../../../../hooks/useMercure';

// lib
import { http } from '../../../../libs/axios';

// types
import {
  IMatching,
  IMercureMatching,
  IMercureMatchingTime,
} from '../../../../types/matching';

// icons
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import CheckIcon from '@mui/icons-material/Check';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MatchingIcon from '../../../../features/icons/matchingIcon';

// steps
import PopupStepper, { Step } from '../../../../components/popupStepper';
import MatchingStep from './steps/matching';
import UsersStep from './steps/users/index';
import SendEmailForm from './steps/email';
import PopupFormSuccess from './steps/success';
import useColumns from './useColumns';
import useSWR from 'swr';
import { useAuth } from '../../../../hooks/useAuth';

type Props = {
  isModalOpen: boolean;
  handleCloseModal: () => void;
  onButtonClick: () => void;
};

enum Steps {
  MATCHING,
  USERS,
  EMAIL,
}

export const matchingStatuses = {
  PENDING: 1,
  RUNNING: 2,
  READY: 3,
  EMPTY: 4,
  ERROR: 5,
};

const eventTypes: string[] = ['message'];

const MatchingButton = (props: {
  onClick: () => void;
  newMatchings: boolean;
  newMatchingIndicator: boolean;
  totalItemsCount: number;
  clickDisabled?: boolean;
  isModalOpen: boolean;
  status: string;
  remainingTime: IMercureMatchingTime | null;
}) => {
  const { t } = useTranslation('propertiesPage');
  const {
    onClick,
    newMatchings,
    newMatchingIndicator,
    totalItemsCount,
    clickDisabled,
    isModalOpen,
    status,
    remainingTime,
  } = props;

  const getButtonText = (status: string) => {
    let textKey = `${t('Matchings')} ${
      totalItemsCount ? `(${totalItemsCount})` : ''
    }`;

    switch (status) {
      case 'started':
        textKey = `${
          remainingTime && remainingTime.remaining_time
            ? `${t('Matching_in_progress')}`
            : `${t('New_matching_started')} `
        }`;
        break;
      case 'completed':
        textKey = `${t('New_matching_completed')} ${
          remainingTime && !remainingTime.remaining_time && remainingTime.result
            ? `(${remainingTime.result} ${t('contacts_found')})`
            : ' '
        }`;
        break;
    }

    return textKey;
  };

  const getButtonIcon = (status: string) => {
    let button = null;

    switch (status) {
      case 'started':
        button = (
          <div className="flex gap-0.5 justify-center items-center">
            <div className="h-1 w-1 bg-blue rounded-full animate-bounce [animation-delay:-0.3s]"></div>
            <div className="h-1 w-1 bg-blue rounded-full animate-bounce [animation-delay:-0.15s]"></div>
            <div className="h-1 w-1 bg-blue rounded-full animate-bounce"></div>
          </div>
        );
        break;
      case 'completed':
        button = (
          <CheckIcon
            sx={{
              width: '16px',
              height: '16px',
              color: '#4CAF50',
            }}
          />
        );
        break;
    }

    return button;
  };

  return (
    <button
      onClick={onClick}
      className={classNames(
        'relative cursor-pointer h-12 px-4 py-2 pl-5 pr-5 bg-white rounded-[100px] shadow justify-center items-center gap-2 inline-flex',
        {
          'pointer-events-none': clickDisabled,
        }
      )}
    >
      <div
        className={classNames(
          'transition-transform duration-300 origin-center w-2 h-2 text-[12px] bg-rose-600 rounded-full absolute right-0 top-0 flex items-center justify-center text-white',
          {
            'scale-1': newMatchingIndicator,
            'scale-0': !newMatchingIndicator,
          }
        )}
      />
      <div className="flex items-center text-zinc-900 text-base font-medium leading-tight">
        <div className={'mr-2'}>{getButtonIcon(status)}</div>
        {getButtonText(newMatchings ? status : 'viewed')}
      </div>
      <div
        className={classNames(
          'w-4 h-4 relative flex items-center justify-center',
          {
            'rotate-180': isModalOpen,
            hidden: clickDisabled,
          }
        )}
      >
        <KeyboardArrowDownIcon fontSize={'small'} />
      </div>
    </button>
  );
};

const PropertiesMatchingModal = ({
  isModalOpen,
  handleCloseModal,
  onButtonClick,
}: Props) => {
  const [selectedMatching, setSelectedMatching] = useState<IMatching | null>(
    null
  );
  const [successMessage, setSuccessMessage] = useState('');
  const [newMatchings, setNewMatchings] = useState(false);
  const [downloadAllContacts, setDownloadAllContacts] = useState(false);
  const formRef = useRef<HTMLFormElement>();
  const { t } = useTranslation('propertiesPage');
  const itemsPerPage = 10;
  const [page, setPage] = useState<number>(1);
  const { data: itemsToDisplay, mutate } = useSWR(
    `/matchings?itemsPerPage=${itemsPerPage}?page=${page}`
  );
  const loadingItemsToDisplay = !itemsToDisplay;
  const {
    user: { id: userId },
  } = useAuth();

  const [contactsFilter, setContactsFilter] = useState<null | number>(null);
  const [contactsPage, setContactsPage] = useState<number>(1);

  const { data: contacts, mutate: mutateContacts } = useSWR(
    selectedMatching
      ? `/matchings/${
          selectedMatching.id
        }/contacts?page=${contactsPage}&itemsPerPage=${10}${
          contactsFilter !== null ? `&sent=${contactsFilter}` : ''
        }`
      : null
  );

  const { data } = useMercure<IMercureMatching>({
    subscribeUrl: 'matching',
    eventTypes,
  });
  const { data: time } = useMercure<IMercureMatchingTime>({
    subscribeUrl: 'matching-remaining-time',
    eventTypes,
  });

  const [rowSelectionProperty, setRowSelectionProperty] =
    useState<GridSelectionModel>([]);
  const [rowSelectionUsers, setRowSelectionUser] = useState<GridSelectionModel>(
    []
  );
  const [viewedMatchings, setViewedMatchings] = useState<GridSelectionModel>(
    []
  );

  const rows = useMemo(() => {
    let returnedValue: IMatching[] = [];
    if (itemsToDisplay) {
      returnedValue = itemsToDisplay['hydra:member'];
    }
    return returnedValue;
  }, [itemsToDisplay]);

  useEffect(() => {
    if (time && time.user === userId && time.remaining_time == 0) {
      mutate();
    }
  }, [time]);

  useEffect(() => {
    if (data && data.user === userId) {
      mutate();
      setNewMatchings(true);
    }
  }, [data]);

  const usersListingColumns = useColumns({
    isLoading: loadingItemsToDisplay,
    remainingTime: time,
    mercureNotifications: data,
  });

  const onRowsPropertySelectionHandler = (ids: GridSelectionModel) => {
    setRowSelectionProperty(ids);

    if (ids && ids.length && rows) {
      const item = rows.find((el: any) => el.id === ids[0]);

      if (item) {
        setSelectedMatching(item);
      }
    }
  };

  const onRowsUsersSelectionHandler = (ids: GridSelectionModel) => {
    setRowSelectionUser(ids);
    setDownloadAllContacts(
      selectedMatching
        ? ids.length === 10 && selectedMatching?.contactsMatched > 10
        : false
    );
  };

  const shouldDisableNext = (step: Step | undefined) => {
    switch (step?.id) {
      case Steps.MATCHING:
        return !rowSelectionProperty.length;
        break;
      case Steps.USERS:
        return !rowSelectionUsers.length;
        break;
      default:
        return false;
    }
  };

  function handleEmailSent(messageHtml?: string) {
    setSuccessMessage(messageHtml || 'Success');
  }

  function onClose() {
    setRowSelectionUser([]);
    setRowSelectionProperty([]);
    setSuccessMessage('');
    setPage(1);
    setSelectedMatching(null);
    handleCloseModal();
    setContactsFilter(null);

    if (viewedMatchings.length) {
      viewedMatchings.forEach((el) => handleMatchingIsViewed(el));
      mutate();
    }
  }

  const handleMatchingIsViewed = async (id: GridRowId) => {
    try {
      await http.patch(`/matchings/${id}`, {
        isViewed: true,
      });

      mutate();
    } catch (event: any) {
      console.log('error', event);
    }
  };

  const onRegenerateMatching = (id?: number, remove?: boolean) => {
    if (id) {
      if (remove) {
        setViewedMatchings([...viewedMatchings, id]);
      } else {
        setViewedMatchings(viewedMatchings.filter((el) => el !== id));
      }
    }
  };

  const onClick = () => {
    onButtonClick();
    if (data && data.user === userId && data.status === 'completed') {
      setNewMatchings(false);
    }
  };

  const content = successMessage ? (
    <PopupFormSuccess onClose={onClose}>
      <div dangerouslySetInnerHTML={{ __html: successMessage }}></div>
    </PopupFormSuccess>
  ) : (
    <PopupStepper
      onClose={onClose}
      disableNext={shouldDisableNext}
      onBack={(step) => {
        if (step?.id === Steps.USERS) {
          setRowSelectionUser([]);
          setContactsFilter(null);
        }
      }}
      onNext={(step) => {
        if (step?.id === Steps.USERS) {
          if (rowSelectionProperty[0]) {
            setViewedMatchings([...viewedMatchings, rowSelectionProperty[0]]);
          }
        }
      }}
      steps={[
        {
          id: Steps.MATCHING,
          Icon: MatchingIcon,
          title: 'Matching',
          body: (
            <MatchingStep
              rows={rows}
              onRowsSelectionHandler={onRowsPropertySelectionHandler}
              rowSelectionModel={rowSelectionProperty}
              usersListingColumns={usersListingColumns}
              matchings={itemsToDisplay}
              page={page}
              setPage={setPage}
              pageSize={itemsPerPage}
            />
          ),
        },
        {
          id: Steps.USERS,
          Icon: PeopleOutlineIcon,
          title: `${t('usersPage:users')}`,
          body: (
            <UsersStep
              onRegenerateMatching={onRegenerateMatching}
              onRowsSelectionHandler={onRowsUsersSelectionHandler}
              rowSelectionUsers={rowSelectionUsers}
              matching={selectedMatching}
              contacts={contacts}
              page={contactsPage}
              setPage={setContactsPage}
              filter={contactsFilter}
              setFilter={setContactsFilter}
              singleMatching
              mutate={mutateContacts}
            />
          ),
        },
        {
          id: Steps.EMAIL,
          Icon: MailOutlineIcon,
          title: `${t('Email_preview')}`,
          body: (
            <SendEmailForm
              rows={rows}
              rowSelectionUsers={rowSelectionUsers}
              rowSelectionProperty={rowSelectionProperty}
              onEmailSent={handleEmailSent}
              formRef={formRef}
              downloadAllContacts={downloadAllContacts}
              contactsMatched={contacts ? contacts['hydra:totalItems'] : 10}
              contactsFilter={contactsFilter}
            />
          ),
          nextButton: () => {
            const onClick = () => {
              if (formRef && formRef.current) {
                formRef.current.requestSubmit();
              }
            };

            return (
              <div
                onClick={onClick}
                className="h-[40px] rounded flex items-center justify-center p-4 gap-y-2 bg-blue cursor-pointer gap-6 text-white text-[14px] transition-colors duration-100 drop-shadow hover:bg-[rgb(36,49,129)]"
              >
                <span>{t('Send_email')}</span>
                <ArrowForwardIosIcon
                  sx={{
                    width: '16px',
                    height: '16px',
                  }}
                  fontSize={'small'}
                />
              </div>
            );
          },
        },
      ]}
    />
  );

  return (
    <>
      {(data && data.user === userId) ||
      (itemsToDisplay && itemsToDisplay['hydra:totalItems']) ? (
        <MatchingButton
          isModalOpen={isModalOpen}
          newMatchings={newMatchings}
          newMatchingIndicator={newMatchings}
          onClick={onClick}
          remainingTime={time}
          status={data && data.user === userId ? data.status : ''}
          totalItemsCount={
            itemsToDisplay ? itemsToDisplay['hydra:totalItems'] : 0
          }
        />
      ) : null}

      <Modal
        open={isModalOpen}
        onClose={onClose}
        classes={{ root: 'grid place-items-center' }}
      >
        <Fade in={isModalOpen}>
          <Box
            sx={{
              maxHeight: '90vh',
              overflowY: 'auto',
              overflowX: 'hidden',
              width: {
                xs: successMessage ? '40%' : '90%',
              },
              borderRadius: 2,
              bgcolor: 'background.paper',
              boxShadow: 24,
            }}
          >
            {content}
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default PropertiesMatchingModal;
