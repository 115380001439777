import { Button, Typography } from '@mui/material';
import React, { PropsWithChildren, ReactNode } from 'react';
import { UseTranslationResponse, useTranslation } from 'react-i18next';

interface Props {
  children?: ReactNode;
  translation: UseTranslationResponse<'common'>;
}

interface State {
  didCatch: boolean;
}

class RealsErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { didCatch: false };
  }

  componentDidCatch(error: Error) {
    console.error(error);
    this.setState({ didCatch: true });
  }
  render() {
    const tryAgain = () => {
      this.setState({ didCatch: false });
    };
    const { t } = this.props.translation;
    return this.state.didCatch ? (
      <div className="flex flex-col justify-center items-center flex-1">
        <Typography variant="h3">{t('oops')}</Typography>
        <p className="mt-2 mb-4">{t('somethingWentWrong')}</p>
        <Button onClick={tryAgain} variant="contained" disableElevation>
          {t('tryAgain')}
        </Button>
      </div>
    ) : (
      this.props.children
    );
  }
}

export default withTranslations(RealsErrorBoundary);

function withTranslations(Component: typeof React.Component<Props, State>) {
  return function ComponentWithTranslations(props: PropsWithChildren) {
    const translation = useTranslation('common');
    return <Component translation={translation} {...props} />;
  };
}
