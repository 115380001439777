import { useState } from 'react';

import { useNotification } from './useNotification';
import { downloadFile } from '../helpers/utils/downloadFile';

const pdfServiceUrl =
  process.env.REACT_APP_PDF_SERVICE_URL || 'http://localhost:4000';

export function usePrint() {
  const [isPrinting, setIsPrinting] = useState(false);
  const { addNotification } = useNotification();

  async function print({ url, fileName }: { url: string; fileName: string }) {
    setIsPrinting(true);
    try {
      await downloadFile({
        url: `${pdfServiceUrl}${url}`,
        fileName: `${fileName}.pdf`,
      });
    } catch (e) {
      console.error(e);
      addNotification(
        'PDF generation failed. Please try again in a few minutes.',
        'error'
      );
    }
    setIsPrinting(false);
  }

  return { isPrinting, print };
}
