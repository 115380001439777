import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

export default function TableIcon({
  color,
  width = '32',
  height = '32',
}: IconProps) {
  const { mode } = useThemeMode();

  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Table">
          <path
            d="M2 3.5H14V12C14 12.1326 13.9473 12.2598 13.8535 12.3536C13.7598 12.4473 13.6326 12.5 13.5 12.5H2.5C2.36739 12.5 2.24021 12.4473 2.14645 12.3536C2.05268 12.2598 2 12.1326 2 12V3.5Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2 6.5H14"
            stroke="#6E6E72"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2 9.5H14"
            stroke="#6E6E72"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.5 3.5V12.5"
            stroke="#6E6E72"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </>
  );
}
