import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

export default function SearchIcon({ color, className }: IconProps) {
  const { mode } = useThemeMode();

  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        d="M11.21 11.383L14.5 14.5m-1.602-7.056a5.446 5.446 0 01-5.449 5.443A5.446 5.446 0 012 7.444 5.446 5.446 0 017.45 2c3.009 0 5.448 2.437 5.448 5.444z"
      ></path>
    </svg>
  );
}
