import React from 'react';
import StepsFieldset from '../../../../../../../components/stepper/stepsFieldset';
import SwitcherFormField from '../../../../../../../components/form/switcherFormField';
import { UseFormGetValues, UseFormRegister } from 'react-hook-form';
import { IProperty } from '../../../../../../../types/property';
import { useTranslation } from 'react-i18next';

export interface IPublicationListItem {
  name: keyof IProperty | string;
  label: string;
}

type Props = {
  list: IPublicationListItem[];
  getValues: UseFormGetValues<IProperty>;
  register: UseFormRegister<IProperty>;
};

function PricingPublication({ list, getValues, register }: Props) {
  const { t } = useTranslation('propertiesPage');
  return (
    <StepsFieldset className="grid grid-cols-2" title={t('Publication')}>
      {list.map(({ label, name }: IPublicationListItem, index: number) => (
        <SwitcherFormField
          key={index + name}
          label={t(label.replaceAll(' ', '_'))}
          name={name}
          checked={Boolean(getValues(name as keyof IProperty))}
          register={register}
        />
      ))}
    </StepsFieldset>
  );
}

export default PricingPublication;
