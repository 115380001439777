import Footer from './footer';
import Logo from '../components/common/logo';
import { Box } from '@mui/material';
import { useThemeMode } from '../hooks/useThemeMode';

export default function AuthLayout({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) {
  const { mode } = useThemeMode();

  return (
    <Box
      sx={{
        backgroundColor: mode === 'dark' ? 'background.paper' : '#e5e7eb70',
        color: 'text.primary',
      }}
      className="flex flex-col justify-between h-screen w-full px-8"
    >
      <Box
        component="main"
        className="lg:w-1/3 md:w-3/4 mt-10 xl:mt-24 w-full mx-auto flex flex-col gap-y-12 justify-center items-center"
      >
        <Box>
          <Logo />
        </Box>

        <Box className="w-full">{children}</Box>
      </Box>

      <Footer />
    </Box>
  );
}
