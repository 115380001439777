import { ReactNode, useState } from 'react';
import { IAgentInCharge, IContactIndividual } from '../../../../types/contacts';
import useLocale from '../../../../hooks/useLocale';
import { useNotification } from '../../../../hooks/useNotification';
import { greetingsOptions } from '../../creator/individual-form-fields';
import { AxiosResponse } from 'axios';
import { http } from '../../../../libs/axios';
import { countries, Icountry } from '../../../../const/countries';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { updateHistoric } from '../../../../helpers/utils/contacts';
import { useTranslation } from 'react-i18next';

export interface ExtendedIcountry extends Icountry {
  [index: string]: any;
}

export const transformDataForFormUsage = (
  locale: string,
  data?: IContactIndividual,
  agentInCharge?: IAgentInCharge
) => {
  let initialValue;

  if (data) {
    const countryItem: ExtendedIcountry | null | undefined =
      data.address &&
      data.address.translations &&
      data.address.translations[locale]
        ? countries.find(
            (el: ExtendedIcountry) =>
              el[locale] === data.address?.translations?.[locale]?.country
          )
        : null;
    const nationalityItem: ExtendedIcountry | null | undefined =
      data.nationality
        ? countries.find(
            (el: ExtendedIcountry) => el[locale] === data.nationality
          )
        : null;

    initialValue = {
      age: data.age,
      birthdate: data.birthdate ? dayjs(data.birthdate) : null,
      birthplace: data.birthplace,
      email: data.email,
      firstname: data.firstname,
      language: data.language
        ? { id: data.language.id, label: data.language.name }
        : null,
      lastname: data.lastname,
      nationality: nationalityItem
        ? {
            id: nationalityItem.alpha2.toString(),
            label: nationalityItem[locale],
          }
        : null,
      phone: data.phone,
      city: data.address?.translations?.[locale]?.city || null,
      adresse: data.address?.translations?.[locale]?.addresse || null,
      zipCode:
        data.address && data.address.zipCode ? data.address.zipCode : null,
      title: data.translations?.[locale]?.title || null,
      capacity: data.translations?.[locale]?.capacity || null,
      greeting: data.greeting ? data.greeting.id : null,
      country: countryItem
        ? { id: String(countryItem.alpha2), label: countryItem[locale] }
        : null,
      agentInCharge: agentInCharge
        ? {
            id: agentInCharge.id,
            label: `${agentInCharge.firstname} ${agentInCharge.lastname}`,
          }
        : null,
    };
  }

  return initialValue;
};

export const withFormHandler = (
  Component: (props: {
    isEditMode: boolean;
    id?: string;
    data?: IContactIndividual;
    companyInformation?: number;
    inner?: boolean;
    additionalButton?: ReactNode;
    mutate?: any;
  }) => JSX.Element
) => {
  return function InnerComponent(props: {
    isEditMode: boolean;
    id?: string;
    data?: IContactIndividual;
    companyInformation?: number;
    inner?: boolean;
    additionalButton?: ReactNode;
    mutate?: any;
    agentInCharge?: IAgentInCharge;
    archived?: boolean;
  }) {
    const { isEditMode, id, companyInformation, mutate, inner } = props;
    const [isLoading, setIsLoading] = useState(false);
    const { addNotification } = useNotification();
    const { locale } = useLocale();
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const onSubmit = (data: any) => {
      const greetingItem = greetingsOptions.find(
        (el) => el.id === data.greeting
      );
      const body = {
        agentInCharge: data.agentInCharge
          ? parseInt(data.agentInCharge.id)
          : null,
        individual: {
          companyInformation,
          age: data.age,
          birthdate: data.birthdate,
          birthplace: data.birthplace,
          email: data.email,
          firstname: data.firstname,
          language: data.language ? data.language.id : null,
          lastname: data.lastname,
          greeting: data.greeting,
          nationality: data.nationality ? data.nationality.label : null,
          phone: data.phone,
          translations: {
            [`${locale}`]: {
              locale: locale,
              capacity: data.capacity,
              title: data.title,
              greeting: greetingItem ? greetingItem.value : null,
            },
          },
          address: {
            zipCode: data.zipCode,
            translations: {
              [`${locale}`]: {
                locale: locale,
                country: data.country ? data.country.label : null,
                city: data.city,
                adresse: data.adresse,
              },
            },
          },
        },
      };

      submitDataToServer(body);
    };

    const submitDataToServer = async (body: any) => {
      let promise: Promise<AxiosResponse>;
      if (isEditMode) {
        promise = http.patch(`/contacts/${id}`, body);
      } else {
        promise = http.post('/contacts/individual', body);
      }

      setIsLoading(true);
      promise
        .finally(() => {
          setIsLoading(false);
        })
        .then(({ data }) => {
          if (isEditMode) {
            if (mutate) {
              mutate(data);
            }
            addNotification(
              `Your contact has been successfully updated.`,
              'success'
            );
            updateHistoric(id, 'Contact information has been updated');
          } else {
            addNotification(
              `Contact has been successfully created. Keep going and fill the rest of information`
            );
            if (mutate) {
              mutate(data);
            }
            if (!inner) {
              navigate(`/contacts/${data.id}/contact-information`, {
                state: {
                  forceHideConfirmationPopup: true,
                },
              });
            }
          }
        })
        .catch((error) => {
          let message;
          if (error.response) {
            if (error.response.status === 474) {
              message = t('Email_Already_used');
            } else message = 'Server error';
          } else if (error.request) {
            message = 'Failed to connect to server';
          } else {
            message = 'Unknown error';
          }
          addNotification(message, 'error');
        });
    };

    // @ts-ignore
    return <Component {...props} onSubmit={onSubmit} isLoading={isLoading} />;
  };
};
