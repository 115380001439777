import classNames from 'classnames';
import { useEffect } from 'react';

import ControlLayout from '../controlLayout';
import { EditorControlProps } from '../../../../types/brochureEditor';
import { IMedia } from '../../../../types/api';

const SelectImage = ({
  value,
  onChange,
  label,
  previewSubject,
}: EditorControlProps<string>) => {
  const medias = previewSubject?.images || [];
  usePreselectFirstImage({ medias, value, onChange });
  return (
    <ControlLayout label={label}>
      <div className="flex flex-wrap gap-2">
        {medias.length === 0 && <p>No images available.</p>}
        {medias.map((media) => {
          const isSelected = String(media.id) === value;
          return (
            <button
              key={media.id}
              className={classNames('border p-2 bg-white w-16 h-16', {
                'border-gray-200': !isSelected,
                'border-blue pointer-events-none': isSelected,
              })}
              onClick={() => onChange?.(String(media.id))}
            >
              <img
                src={media.url.thumbnail}
                alt={label}
                className={classNames('w-12 h-12 object-cover', {
                  'opacity-70': !isSelected,
                })}
              />
            </button>
          );
        })}
      </div>
    </ControlLayout>
  );
};

export default SelectImage;

function usePreselectFirstImage({
  medias,
  value,
  onChange,
}: {
  medias: IMedia[];
  value?: string;
  onChange?: (value: string) => unknown;
}) {
  useEffect(() => {
    if (medias[0] && !value) {
      onChange?.(String(medias[0].id));
    }
  }, [medias, value, onChange]);
}
