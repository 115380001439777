import { Box } from '@mui/material';
import { uniqBy } from 'lodash';

import { countries } from '../../const/countries';
import LocationIcon from '../../features/icons/locationIcon';
import { getCountry } from '../../pages/agencies/tree/utils';
import User from '../../types/user';
import { Department } from '../../types/hierarchy';
import { Locale } from '../../hooks/useLocale';
import { IAddress } from '../../types/address';
import { EnsureShortLocale } from '../../components/common/ensureShortLocale';
import { ISettingEmails } from '../../types/settings';
import { IAddressPopup } from '../../types/popupSingleProperty';

export interface IFilterProperties {
  type: string;
  value: string;
}

export const renderPropertyAdress = (
  address: IAddress | IAddressPopup | null,
  withTruncate: boolean
) => {
  const fullPropertyAdress = renderFullPropertyAdress({ address });
  return (
    <Box className="flex items-center leading-5 w-full">
      {fullPropertyAdress.length > 0 && (
        <span className="flex gap-2 items-center capitalize h-6 px-2 w-full">
          <LocationIcon className="col-span-1 mr-1" color="#6E6E72" />
          <span className={`w-full  ${withTruncate && 'truncate'}`}>
            {fullPropertyAdress}
          </span>
        </span>
      )}
    </Box>
  );
};

export const renderFullPropertyAdress = ({
  address,
  withAddress = true,
  withZip = true,
}: {
  address: IAddress | IAddressPopup | null;
  withAddress?: boolean;
  withZip?: boolean;
}) => {
  const locale = ((localStorage.getItem('i18nextLng') as Locale) ||
    'en') as Locale;
  const localAdress = address?.translations?.[`${EnsureShortLocale(locale)}`];

  if (!localAdress) return '';

  const { zipCode } = address;
  const { country, adresse, city } = localAdress;

  const fullCountryName = getFullCountryName(country, locale);

  // Quai Gustave-Ador 62, 1207 Geneva, Switzerland
  const parts = [];

  if (withAddress && adresse) {
    parts.push(adresse);
  }

  if (withZip) {
    parts.push([zipCode, city].filter((value) => !!value).join(' '));
  } else {
    parts.push(city);
  }

  if (fullCountryName) {
    parts.push(fullCountryName);
  }

  return parts.filter((value) => !!value).join(', ');
};

function getFullCountryName(country: string | undefined, locale: Locale) {
  const countryOption = country ? getCountry(countries, country) : null;
  return countryOption?.[locale] || null;
}

export const debounce = (
  callback: (input: IFilterProperties) => void,
  wait: number
) => {
  let timeoutId: any = null;
  return (args: any) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback(args);
    }, wait);
  };
};

export const extratagentsFromDept = (departments: Department[]) => {
  let agents: User[] = [];
  agents = agents.concat(...departments.map(({ users }: any) => users));
  const nexTab = agents.map(({ id, firstname, lastname }) => {
    return { id, value: `${firstname} ${lastname}` };
  });
  const agentOption = uniqBy(nexTab, 'id');
  return agentOption;
};

export const ucfirst = (str: string): string =>
  str[0]?.toUpperCase() + str.slice(1);

export const normalizeById = (arr: { id: number }[]) =>
  arr.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {});

export function searchCountries(lang: string, str: string) {
  const filteredCountries = countries.filter((country: any) => {
    const countryName: any = country[`${lang}`];
    return countryName.toLowerCase().includes(str.toLowerCase());
  });

  const countryNames = filteredCountries.map((country: any) => country[lang]);
  return countryNames;
}

export function formatPrice(number?: number | null) {
  if (!number) {
    return '-';
  }
  return new Intl.NumberFormat('de-CH', {
    style: 'currency',
    currency: 'CHF',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(number);
}

export const minTwoDigits = (n: number) => {
  return ('0' + n).slice(-2);
};

export const formatTime = (date: string) => {
  const formattedDate = new Date(date);

  return `${minTwoDigits(formattedDate.getHours())}:${minTwoDigits(
    formattedDate.getMinutes()
  )}`;
};

export const formatDateAndTime = (date: string) => {
  const formattedDate = new Date(date);

  return `${minTwoDigits(formattedDate.getDate())}-${minTwoDigits(
    formattedDate.getMonth() + 1
  )}-${formattedDate.getFullYear()}, ${formatTime(date)}`;
};

export const formatDate = (date: string) => {
  const formattedDate = new Date(date);

  const month = formattedDate.toLocaleString('default', {
    month: 'short',
  });
  return `${minTwoDigits(
    formattedDate.getDate()
  )} ${month} ${formattedDate.getFullYear()}`;
};

export const getMatchingSettingByType = (
  type: string,
  data?: ISettingEmails[]
) => {
  return data?.find((el) => el.type === type);
};

export function abbreviate(strings: (string | undefined | null)[]) {
  return strings
    ?.filter(Boolean)
    .map((str) => str?.charAt(0)?.toUpperCase())
    .join('');
}

export function fullName(
  firstname?: string | null,
  lastname?: string | null,
  legalName?: string | null
) {
  return [firstname, lastname, legalName].join(' ');
}

export function getElapsedTimeFromDate(
  inputDate: string,
  isFrenchLocale?: boolean
) {
  const inputDateTime = new Date(inputDate);
  const currentDateTime = new Date();

  const timeDifference = currentDateTime.getTime() - inputDateTime.getTime();

  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return isFrenchLocale ? `Il y a ${days} jours` : `${days} days ago`;
  } else if (hours > 0) {
    return isFrenchLocale ? `Il y a ${hours} heures` : `${hours} hours ago`;
  } else if (minutes > 0) {
    return isFrenchLocale
      ? `Il y a ${minutes} minutes`
      : `${minutes} minutes ago`;
  } else {
    return isFrenchLocale
      ? `Il y a ${seconds} secondes`
      : `${seconds} seconds ago`;
  }
}

export function sanitizeUrl(url: string) {
  const res = !/^https?:\/\//i.test(url);
  if (res) {
    return `https://${url}`;
  } else {
    return url;
  }
}
