import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

export default function DashboardIcon({ color, className }: IconProps) {
  const { mode } = useThemeMode();

  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        d="M9 2a1 1 0 011-1h4a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 01-1-1V2zM1 2a1 1 0 011-1h4a1 1 0 011 1v12a1 1 0 01-1 1H2a1 1 0 01-1-1V2z"
      ></path>
      <rect width="6" height="6" x="9" y="9" stroke={color} rx="1"></rect>
    </svg>
  );
}
