import EditIcon from '../../../features/icons/editIcon';
import { Button, Tooltip } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

import { Typography } from '@mui/material';
import TitleHeader from '../../../components/common/titleHeader';
import { Link } from 'react-router-dom';

import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';

import { http } from '../../../libs/axios';
import { useTranslation } from 'react-i18next';

export interface Translation {
  id: number;
  locale: string;
  name: string;
}

export interface DataItem {
  id: number;
  defaultTagLocale: string;
  translations: {
    en?: Translation;
    fr?: Translation;
    de?: Translation;
  };
}

export default function CustomAttTagsList() {
  const { t } = useTranslation('propertiesPage');
  const [rows, setRows] = useState<DataItem[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await http.get('data_field_tags', { params: { limit: 0 } });
        console.log(res.data);
        setRows(res.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);
  const columns: GridColDef[] = [
    {
      field: 'en',
      headerName: 'EN',
      headerClassName: '!pl-5',
      cellClassName: '!pl-6',
      width: 250,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        return params.row.translations['en']
          ? params.row.translations['en'].name
          : '-';
      },
    },
    {
      field: 'fr',
      headerName: 'FR',
      width: 250,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        return params.row.translations['fr']
          ? params.row.translations['fr'].name
          : '-';
      },
    },
    {
      field: 'de',
      headerName: 'DE',
      width: 250,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        return params.row.translations['de']
          ? params.row.translations['de'].name
          : '-';
      },
    },
    {
      field: 'edit',
      headerName: t('Edit'),
      width: 250,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <Tooltip title={t('Edit')} arrow placement="top">
            <Link to={'/settings/customAttrTags/edit/' + params?.row?.id}>
              <EditIcon color="#FFA500" />
            </Link>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <div className="w-[95%] mx-auto">
      <TitleHeader
        title={''}
        titleElement={
          <div>
            <Typography className="!text-3xl">
              {t('Custom_attributes_tags')}
            </Typography>
          </div>
        }
        goBackLink="/settings"
        rightElement={
          <div>
            <Link to={'/settings/customAttrTags/create'}>
              <Button
                variant="contained"
                disableElevation
                startIcon={<CheckIcon fontSize="small" />}
              >
                {t('Create_Tag')}
              </Button>
            </Link>
          </div>
        }
      >
        <div>
          <DataGrid
            sx={{
              '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                display: 'none',
              },
            }}
            rows={rows}
            columns={columns}
            autoHeight
            loading={!rows}
            disableColumnMenu
            disableSelectionOnClick
            pageSize={5}
            rowsPerPageOptions={[5]}
          />
        </div>
      </TitleHeader>
    </div>
  );
}
