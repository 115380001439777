import React from 'react';
import { Link } from 'react-router-dom';
import NoDataWidget from '../components/noData';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import classNames from 'classnames';
interface WidgetWrapperProps {
  title: string;
  children: React.ReactNode;
  linkText: string;
  link: string | null;
  emptyData?: boolean;
  onButtonClick?: () => void;
  wide?: boolean;
}

const WidgetWrapper: React.FC<WidgetWrapperProps> = ({
  children,
  title,
  linkText,
  link,
  emptyData,
  onButtonClick,
  wide,
}) => {
  const { t } = useTranslation('common');

  const isLowerThen420 = useMediaQuery('(max-width:420px)');
  return (
    <div
      className={classNames('w-full', {
        'md:col-span-2 2xl:col-span-3': wide,
      })}
    >
      <div
        className={`sm:p-8 p-2 bg-white rounded flex-col justify-center items-start gap-8 flex ${title}`}
      >
        <div className="self-stretch pb-4 border-b border-gray-200 justify-start items-center gap-4 inline-flex">
          <div
            className={classNames(
              'grow shrink basis-0 text-zinc-900 font-medium leading-none',
              {
                'text-sm': !wide,
              }
            )}
          >
            {t(title)}
          </div>
        </div>
        <div
          className={`self-stretch flex-col justify-center items-start gap-8 flex ${
            isLowerThen420 ? 'w-[84vw]' : 'w-full'
          } `}
        >
          {emptyData ? <NoDataWidget /> : children}
          {link && !emptyData ? (
            <Link
              to={link}
              className="px-2 py-1 rounded border border-blue-800 justify-start items-center gap-1 inline-flex"
            >
              <span className="text-blue-800 text-sm font-normal leading-none">
                {t(linkText)}
              </span>
            </Link>
          ) : null}
          {onButtonClick && !emptyData ? (
            <button
              onClick={onButtonClick}
              className="px-2 py-1 rounded border border-blue-800 justify-start items-center gap-1 inline-flex"
            >
              <span className="text-blue-800 text-sm font-normal leading-none">
                {t(linkText)}
              </span>
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default WidgetWrapper;
