import { useTranslation } from 'react-i18next';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';

function useTranslationForDataGreedTree(columnsData: GridColDef[], ns: string) {
  const { t } = useTranslation(ns);
  // console.log(columnsData)
  // console.log('t', t)
  const [data, resetData] = useState<GridColDef[] | []>([]);

  useEffect(() => {
    resetData(columnsData);
  }, [localStorage.getItem('isLarge'), columnsData]);

  return data.map((item) => {
    return {
      ...item,
      headerName: t(item?.headerName || ''),
    };
  });
}

export default useTranslationForDataGreedTree;
