import useSWR from 'swr';
import Select from '../../../components/popupStepper/controls/select';
import { ApiListing } from '../../../types/api';
import { BrokerItem } from '../landing';

function PromotionLandingSelectBroker({
  value,
  onChange,
  promotionId,
}: {
  value?: string;
  onChange: (newVal: string) => unknown;
  promotionId: number;
}) {
  const options = [
    {
      label: 'All',
      value: '',
    },
  ];
  const { data: brokersList } = useSWR<ApiListing<BrokerItem>>(
    `/v1/promotions/${promotionId}/brokers`
  );
  if (brokersList) {
    options.push(
      ...brokersList['hydra:member'].map((b) => ({
        label: `${b.firstname} ${b.lastname}`,
        value: `${b.id}`,
        img: b.avatar,
      }))
    );
  }
  return <Select value={value} onChange={onChange} options={options} />;
}

export default PromotionLandingSelectBroker;
