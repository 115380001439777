import { Box, Tooltip } from '@mui/material';
import { abbreviate, fullName } from '../../helpers/utils';

export interface CircleWithShortNameProps {
  avatar?: string | null;
  firstname?: string | null;
  lastname?: string | null;
  legalName?: string | null;
  role?: string;
}

const CircleWithShortName = ({
  firstname,
  lastname,
  legalName,
  role,
}: CircleWithShortNameProps) => {
  return (
    <Tooltip
      title={fullName(firstname, lastname, legalName)}
      arrow
      placement="top"
    >
      <Box className="flex flex-col w-8 h-9 mx-1 p-1.5 rounded-xl bg-gray-100 cursor-pointer">
        <span className="text-blue leading-3 m-auto capitalize">
          {abbreviate([firstname, lastname, legalName])}
        </span>
        <span className="text-[#1D1D1F] leading-5 text-[10px] m-auto">
          {role?.slice(0, 3)}
        </span>
      </Box>
    </Tooltip>
  );
};

export default CircleWithShortName;
