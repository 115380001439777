import PropertyDistanceItem from './item';
import StepsFieldset from '../../../../../components/stepper/stepsFieldset';
import { useTranslation } from 'react-i18next';

interface Props {
  serverData: any;
  mutate: any;
}

export interface DistanceArrayItem {
  label: string;
  name: string;
  googleType: string;
  keyname: string;
  radius: number;
}

const distances: DistanceArrayItem[] = [
  {
    label: 'Train_station',
    name: 'train_station',
    googleType: 'train_station',
    keyname: 'train_station',
    radius: 1000,
  },
  {
    label: 'Public_transport',
    name: 'public_transport',
    googleType: 'transit_station',
    keyname: 'public_transport',
    radius: 500,
  },
  {
    label: 'Highway',
    name: 'highway',
    googleType: 'highway',
    keyname: 'highway',
    radius: 500,
  },
  {
    label: 'Kindergarten',
    name: 'kindergarten',
    googleType: 'school',
    keyname: 'kindergarten',
    radius: 500,
  },
  {
    label: 'Primary_school',
    name: 'primary_school',
    googleType: 'school',
    keyname: 'primary_school',
    radius: 500,
  },
  {
    label: 'Secondary_school',
    name: 'secondary_school',
    googleType: 'school',
    keyname: 'secondary_school',
    radius: 500,
  },
  {
    label: 'High_school',
    name: 'high_school',
    googleType: 'school',
    keyname: 'high_school',
    radius: 500,
  },
  {
    label: 'Higher_education',
    name: 'higher_education',
    googleType: 'university',
    keyname: 'higher_education',
    radius: 500,
  },
  {
    label: 'Shops',
    name: 'shops',
    googleType: 'store',
    keyname: 'shops',
    radius: 500,
  },
  {
    label: 'Cable_car_ski_lift',
    name: 'ski_lift',
    googleType: 'travel_agency',
    keyname: 'ski_lift',
    radius: 500,
  },
  {
    label: 'Airport',
    name: 'airport',
    googleType: 'airport',
    keyname: 'airport',
    radius: 5000,
  },
  {
    label: 'Post_office',
    name: 'post_office',
    googleType: 'post_office',
    keyname: 'post_office',
    radius: 500,
  },
  {
    label: 'Bank',
    name: 'bank',
    googleType: 'bank',
    keyname: 'bank',
    radius: 500,
  },
  {
    label: 'Hospital',
    name: 'hospital',
    googleType: 'hospital',
    keyname: 'hospital',
    radius: 500,
  },
  {
    label: 'Restaurants',
    name: 'restaurants',
    googleType: 'restaurant',
    keyname: 'restaurants',
    radius: 500,
  },
  {
    label: 'Park_green_space',
    name: 'park',
    googleType: 'park',
    keyname: 'park',
    radius: 500,
  },
];
const PropertyDistances = (props: Props) => {
  const { t } = useTranslation('propertiesPage');
  const { serverData, mutate } = props;

  return (
    <div>
      <StepsFieldset title={t('Distances')}>
        <div className={'flex flex-col gap-4 divide-y w-full col-span-2'}>
          {distances.map((item) => (
            <PropertyDistanceItem
              key={item.name}
              item={item}
              latitude={serverData.address.latitude}
              longitude={serverData.address.longitude}
              serverData={serverData}
              mutate={mutate}
            />
          ))}
        </div>
      </StepsFieldset>
    </div>
  );
};

export default PropertyDistances;
