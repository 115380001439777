import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';

import Nav from '../../aside/nav';

import CloseIcon from '@mui/icons-material/Close';
import Logo from '../../../components/common/logo';
export default function TemporaryDrawer() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button sx={{ paddingLeft: 0 }} onClick={() => setIsOpen(!isOpen)}>
        <MenuIcon />
      </Button>
      <Drawer anchor={'left'} open={isOpen} onClose={() => setIsOpen(false)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '250px',
            height: '100%',
            padding: '64px 16px 0px ',
            justifyContent: 'space-between',
          }}
          role="presentation"
          // onClick={() => setIsOpen(false)}
          // onKeyDown={() => setIsOpen(false)}
        >
          <Logo width="100px" className="absolute left-4 top-5" />
          <button
            onClick={() => setIsOpen(false)}
            className={
              'z-10 absolute right-4 top-4 w-[2rem] h-[2rem] rounded flex items-center justify-center bg-white transition-colors duration-500 hover:text-gray-700 text-black'
            }
          >
            <CloseIcon
              sx={{
                cursor: 'pointer',
              }}
            />
          </button>

          <Nav setIsOpen={setIsOpen} />
        </Box>
      </Drawer>
    </>
  );
}
