import { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import RightArrowIcon from '../../../../features/icons/rightArrowIcon';
import { Box, Stack } from '@mui/material';

type Props = {
  propertyGalleries: string[];
};

function PropertyGallery({ propertyGalleries }: Props) {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const navigationStyle =
    'cursor-pointer absolute bottom-1/2 translate-y-1/2  flex justify-center items-center  w-10  rounded  aspect-square text-sm bg-white z-50 border border-gray-200 ';
  return (
    <Stack className="bg-[#FFFFFF] w-full max-w-full select-none rounded border p-8 flex flex-col relative">
      <Swiper
        slidesPerView={4}
        spaceBetween={8}
        className="mySwiper"
        pagination={{
          type: 'fraction',
        }}
        navigation={{
          prevEl: navigationPrevRef?.current,
          nextEl: navigationNextRef?.current,
        }}
        onSwiper={(swiper: any) => {
          setTimeout(() => {
            if (swiper && swiper.params) {
              if (
                swiper.params.navigation &&
                typeof swiper.params.navigation !== 'boolean'
              ) {
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;
              }

              swiper.navigation.destroy();
              swiper.navigation.init();
              swiper.navigation.update();
            }
          });
        }}
        modules={[Navigation]}
      >
        {propertyGalleries.map((url: string, index: number) => (
          <SwiperSlide key={index}>
            <img
              className="aspect-square bg-white border border-gray-200 w-full"
              alt=""
              src={url}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      <Box ref={navigationPrevRef} className={navigationStyle + ' left-4'}>
        <RightArrowIcon className="rotate-180" />
      </Box>
      <Box ref={navigationNextRef} className={navigationStyle + '  right-4 '}>
        <RightArrowIcon />
      </Box>
    </Stack>
  );
}

export default PropertyGallery;
