import React from 'react';
import { IContactHistoryItem } from '../../../types/contacts';
import TableItem from './table/item';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../helpers/utils';

const ContactHistoryList = (props: {
  items: IContactHistoryItem[];
  count: number;
}) => {
  const { items, count } = props;
  const { t } = useTranslation('common');
  return (
    <div className="min-h-[500px]">
      <p className={'mb-8 text-2xl'}>
        {t('Contact_history')}
        {items && items.length ? <span> ({count})</span> : null}
      </p>
      {items && items.length ? (
        <div className={'divide-y border'}>
          {items.map((item) => (
            <TableItem
              key={item.id}
              title={formatDate(item.date) || ''}
              value={item.action}
            />
          ))}
        </div>
      ) : (
        <div>{t('There_is_no_history_for_this_contact_yet')}.</div>
      )}
    </div>
  );
};

export default ContactHistoryList;
