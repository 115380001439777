import SpinnerLoading from '../../../../features/spinner/spinnerLoading';
import * as React from 'react';
import useSWR from 'swr';
import { ContactTapInnerProps } from './search-criteria-tab';
import ContactHistoryList from '../../components/history';
import Pagination from '@mui/material/Pagination';

const HistoryTab = (props: ContactTapInnerProps) => {
  const [page, setPage] = React.useState<number>(1);
  const itemPerPage = 7;

  const { data, id } = props;
  const { data: history } = useSWR(
    `/contact/${id}/historics?page=${page}&itemsPerPage=${itemPerPage}`
  );
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return data && history ? (
    <div className="min-h-[400px]">
      <ContactHistoryList
        items={history && history['hydra:member']}
        count={history['hydra:totalItems']}
      />
      <Pagination
        className="float-right"
        count={
          history['hydra:totalItems'] % itemPerPage == 0
            ? Math.floor(history['hydra:totalItems'] / itemPerPage)
            : Math.floor(history['hydra:totalItems'] / itemPerPage) + 1
        }
        page={page}
        onChange={handleChange}
      />
    </div>
  ) : (
    <div className={'col-start-1 col-end-4 min-h-[500px]'}>
      <SpinnerLoading />
    </div>
  );
};

export default HistoryTab;
