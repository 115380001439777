import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  // TextField,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { http } from '../../../libs/axios';
import PhoneInput from 'react-phone-input-2';
import { toast } from 'react-toastify';
import { captureException } from '@sentry/react';
import { UserDetail } from '../../../types/user';
import { UserValidation } from '../../../libs/yup/userFormValidation';
import { useParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import SelectFormField from '../../../components/form/selectFormField';
import { useFiltersProperties } from '../../../hooks/useFiltersProperties';
import { mutate } from 'swr';
import { extratagentsFromDept } from '../../../helpers/utils';
import InputFormField from '../../../components/form/inputFormField';
import InputLayout from '../../../components/form/components/inputLayout';

type UserDetailDataT = Pick<
  UserDetail,
  'firstname' | 'lastname' | 'email' | 'phone' | 'users'
>;

type Props = {
  closeModal: () => void;
  type: 'add' | 'edit';
  dataToEdit?: UserDetailDataT;
  apiKey: string;
  itemsPerPage?: number;
  role?: string;
};

export default function UserForm({
  closeModal,
  type,
  dataToEdit,
  itemsPerPage,
  apiKey,
  role,
}: Props) {
  const location = useLocation();
  const { pathname } = location;

  const { agentsPerDept, setAgentOptionsApi } = useFiltersProperties();

  const { t } = useTranslation('usersPage');
  const { userId } = useParams();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    control,
    getValues,
  } = useForm<any>({
    resolver: yupResolver(UserValidation),
    shouldFocusError: false,
  });

  useEffect(() => {
    agentsPerDept.length === 0 && setAgentOptionsApi();
  }, [setAgentOptionsApi]);

  useEffect(() => {
    if (type === 'edit' && dataToEdit) {
      const { firstname, lastname, email, phone, users } = dataToEdit;

      if (role === 'assistant' && users) {
        const usersOptions: any = users[`hydra:member`]
          ? users['hydra:member'].map(({ id }: any) => id)
          : [];
        setValue('users', usersOptions);
      }
      setValue('firstname', firstname);
      setValue('lastname', lastname);
      setValue('email', email);
      setValue('phone', phone !== null ? phone.replace('+', '') : null);
    }
  }, [type]);

  const agentsOptions = useMemo(() => {
    if (agentsPerDept) {
      return extratagentsFromDept(agentsPerDept);
    }
    return [];
  }, [agentsPerDept]);

  const onSubmit = async (data: UserDetailDataT) => {
    data.phone = watch('phone') ? `+${data.phone}` : null;
    try {
      if (type === 'add') {
        await http.post(apiKey, { ...data });
        mutate(
          `/users?page=${1}&itemsPerPage=${
            pathname.includes('users') ? itemsPerPage : 6
          }`
        );
        toast.success(t('alerts:user_added') as string);
      } else {
        await http.patch(apiKey, { ...data });
        mutate(`/users/${userId}`);
        toast.success(t('alerts:user_updated') as string);
      }
    } catch (event: any) {
      captureException(event);
      if (event.response.status === 452 || event.response.status === 409) {
        toast.warning(event.response.data.message as string);
      } else {
        if (type === 'add') {
          toast.error(t('alerts:user_not_added') as string);
        } else {
          toast.error(t('alerts:user_not_updated') as string);
        }
      }
    }
    closeModal();
  };

  const errorKeys = Object.keys(errors) as (keyof typeof errors)[];

  return (
    <Box
      sx={{
        color: 'text.primary',
      }}
      component="form"
    >
      <Box className="flex flex-col md:grid gap-6">
        <InputFormField
          className="col-span-1 last_name"
          label={t('lastname')}
          {...register('lastname')}
          error={Object.keys(errors).includes('lastname')}
          helperText={
            errorKeys.includes('lastname') &&
            t('validation:' + errors['lastname']?.message)
          }
        />

        <InputFormField
          className="col-span-1 first_name"
          label={t('firstname')}
          {...register('firstname')}
          error={Object.keys(errors).includes('firstname')}
          helperText={
            errorKeys.includes('firstname') &&
            t('validation:' + errors['firstname']?.message)
          }
        />
        <InputFormField
          className="col-span-1 email"
          label={t('email')}
          {...register('email')}
          error={errorKeys.includes('email')}
          helperText={
            errorKeys.includes('email') &&
            t('validation:' + errors.email?.message)
          }
        />
        <InputLayout label={t('usersPage:phone')}>
          <FormControl className="col-span-1 phone">
            <Controller
              render={({ field }) => (
                <PhoneInput
                  {...field}
                  inputStyle={{
                    height: '56px',
                    width: '100%',
                    border: errors['phone'] ? ' 1px solid #D63E3E ' : '',
                    color: '#1D1D1F',
                  }}
                  country={'ch'}
                  {...(errors['phone'] && {
                    buttonStyle: {
                      border: '1px solid #D63E3E',
                      borderRight: 'none',
                    },
                  })}
                />
              )}
              control={control}
              name="phone"
            />

            {errors['phone'] && (
              <FormHelperText className="!text-[#D63E3E]">
                {t('validation:' + errors.phone?.message)}
              </FormHelperText>
            )}
          </FormControl>
        </InputLayout>
        {role === 'assistant' && (
          <Box sx={{ maxWidth: '29rem' }}>
            <SelectFormField
              className="users"
              label={t('usersPage:users')}
              // placeholder=
              options={agentsOptions}
              multiple
              defaultValue={getValues('users')}
              value={watch('users') || []}
              {...register('users')}
            />
          </Box>
        )}
      </Box>
      <Box className="w-full flex  justify-center px-16 pt-3">
        <Button
          className="save_btn"
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          disableElevation
          startIcon={<CheckIcon fontSize="small" />}
        >
          {t('save')}
        </Button>
      </Box>
    </Box>
  );
}
