import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

function CustomAttrTags({
  color,
  width = '32',
  height = '32',
  className,
}: IconProps) {
  const { mode } = useThemeMode();
  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <svg
      className={className}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 48 48"
    >
      <g>
        <g>
          <path
            fill={color}
            fillRule="evenodd"
            d="M10.988 18.127c0-1.316.84-2.435 2.014-2.85V11.1H7a1 1 0 00-1 1v24a1 1 0 001 1h12.966a1 1 0 110 2H7a3 3 0 01-3-3v-24a3 3 0 013-3h6.002V7.122a1 1 0 112 0v1.977h9a1 1 0 011 1v17.119a3.025 3.025 0 010 5.713V37.1H41a1 1 0 001-1v-24a1 1 0 00-1-1H28.002a1 1 0 110-2h5v-1.96a1 1 0 112 0V9.1H41a3 3 0 013 3v24a3 3 0 01-3 3H25.002V41a1 1 0 11-2 0v-8.075a3.025 3.025 0 010-5.701V11.099h-8v4.172a3.025 3.025 0 010 5.713v13.068a1 1 0 11-2 0V20.979a3.026 3.026 0 01-2.014-2.852zm3.053 1.024a1.006 1.006 0 00-.07 0 1.023 1.023 0 11.07 0zm18.961 7.828a3.026 3.026 0 010-5.702V14.15a1 1 0 112 0v7.121a3.025 3.025 0 010 5.713v7.068a1 1 0 11-2 0v-7.073zm2.032-2.852a1.022 1.022 0 10-2.044 0 1.022 1.022 0 002.044 0zm-10 5.948a1.024 1.024 0 10-2.047 0 1.024 1.024 0 002.047 0z"
            clipRule="evenodd"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default CustomAttrTags;
