import { useState } from 'react';

import TitleHeader from '../../../components/common/titleHeader';
import { Typography } from '@mui/material';
import { Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import LangSwitcher from '../../../layout/footer/langSwitcher';
import { Locale } from '../../../hooks/useLocale';
import InputFormField from '../../../components/form/inputFormField';
import { http } from '../../../libs/axios';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function CustomAttributesTags() {
  const { t } = useTranslation('propertiesPage');
  const [locale, setLocale] = useState<Locale>('en');
  const navigate = useNavigate();

  const { register, setValue } = useForm<any>();
  const [value, setValueState] = useState<string>('');

  const handleInputChange = (newValue: string) => {
    setValueState(newValue);
    setValue(`translations.${locale}.name`, newValue);
  };

  const onSubmit = async () => {
    try {
      await http.post('data_field_tags', {
        defaultTagLocale: locale,
        translations: {
          [locale]: { name: value, locale: locale },
        },
      });

      toast.success(' custom attribute tag added successfully', {
        position: 'bottom-center',
        autoClose: 3000,
        style: { width: '500px' },
      });
      navigate('/settings/customAttrTags');
    } catch (error: any) {
      if (error.response.status === 400) {
        toast.error('custom attribute already exist !', {
          position: 'bottom-center',
          autoClose: 3000,
          style: { width: '500px' },
        });
      } else {
        toast.error('Error posting value', {
          position: 'bottom-center',
          autoClose: 3000,
          style: { width: '500px' },
        });
      }
    }
  };

  return (
    <div>
      <TitleHeader
        title={''}
        titleElement={
          <div className="!mb-[10%]">
            <Typography className="!text-3xl">
              {t('Add_Custom_Attributes_Tags')}
            </Typography>
          </div>
        }
        goBackLink="/settings/customAttrTags"
        rightElement={
          <Button
            className="!mt-[7%] !mr-5"
            variant="contained"
            disableElevation
            onClick={onSubmit}
            startIcon={<CheckIcon fontSize="small" />}
          >
            {t('usersPage:save')}
          </Button>
        }
      >
        <div className="!w-[90%] mx-auto flex justify-between gap-8">
          <div className="w-[50%] mt-2">
            <LangSwitcher
              currentLocale={locale}
              setLanguage={setLocale}
              changeAppLocale={false}
              className="!w-[40%] !mx-0 !h-16"
            />
          </div>
          <div className="w-[50%]">
            <InputFormField
              label={t('Add_Custom_Attribute_Tag')}
              type="text"
              value={value}
              {...register(`translations.${locale}.name`, {
                onChange: (e) => handleInputChange(e.target.value),
              })}
            />
          </div>
        </div>
      </TitleHeader>
    </div>
  );
}
