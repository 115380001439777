import useSWR from 'swr';

import { WidgetPreviewProps } from '../../../../../types/brochureEditor';
import { FileNode } from '../../../../../types/hierarchy/legacy';

const AgencyLogoBlock = ({ previewSubject }: WidgetPreviewProps) => {
  const { data: department } = useSWR<FileNode>(
    previewSubject.department
      ? `/departments/${previewSubject.department?.id}`
      : null
  );

  const agencyId = department?.agency?.id;
  const agencyUrl = agencyId ? `/agencies/${agencyId}` : undefined;

  const { data: agency } = useSWR<FileNode>(agencyUrl);

  return agency?.logo?.x96 ? (
    <img
      className="block w-full h-[88px] object-contain"
      src={agency.logo.x96}
      alt=""
    />
  ) : null;
};

export default AgencyLogoBlock;
