import FieldValueToDisplay from '../../components/common/fieldValueToDisplay';
import useLocale from '../../hooks/useLocale';
import moment from 'moment';
import { INotificationApi } from '../../types/notification';

type Props = {
  notificationList: INotificationApi;
};

export default function NotificationDetails({ notificationList }: Props) {
  const { locale } = useLocale();

  return (
    <>
      {notificationList && (
        <div className="w-[88%] m-auto mt-6 grid gap-8 text-[#1D1D1F]">
          <div className="grid gap-2">
            <p className="Inter font-normal text-xs leading-4">
              {moment(notificationList.notification?.publishDate).format(
                'DD MMM, YYYY'
              )}
            </p>
            <h1 className="Inter leading-10 text-[32px] font-medium">
              {notificationList.notification?.translations[locale]?.title || ''}
            </h1>
            <p className="Inter text-sm leading-4 font-medium">
              <FieldValueToDisplay
                fieldValue={
                  notificationList.notification?.translations[locale]
                    ?.description || ''
                }
                defaultValue={''}
              />
            </p>
          </div>

          {notificationList.notification?.mediaType != null &&
            (notificationList.notification?.mediaType?.includes('image') ? (
              <img
                className="w-full h-auto m-auto"
                src={
                  notificationList.notification?.media.url.large ||
                  notificationList.notification?.media.url.mobile ||
                  notificationList.notification?.media.url.thumbnail ||
                  notificationList.notification?.media.url.original
                }
                alt="notification image"
              />
            ) : (
              <video loop muted controls className="w-full h-auto m-auto">
                <source
                  src={
                    notificationList.notification?.media.url.large ||
                    notificationList.notification?.media.url.mobile ||
                    notificationList.notification?.media.url.thumbnail ||
                    notificationList.notification?.media.url.original
                  }
                  type="video/mp4"
                />
              </video>
            ))}

          <p className="Inter font-normal text-base	leading-[19.2px]">
            <FieldValueToDisplay
              fieldValue={
                notificationList.notification?.translations[locale]?.content ||
                ''
              }
              defaultValue={''}
            />
          </p>
        </div>
      )}
    </>
  );
}
