import { Box } from '@mui/material';
import PromotionStatsTable from './statsTab';
import { IPromotionApi } from '../../../../../types/promotion';
import { useTranslation } from 'react-i18next';

interface Props {
  data: IPromotionApi;
}

function PromotionStats({ data }: Props) {
  const { t } = useTranslation('promotionPage');

  const stats = [
    { label: `${t('MaxRooms')}:`, value: data.maxRooms },
    { label: `${t('MinRooms')}:`, value: data.minRooms },
    { label: `${t('MaxSurface')}:`, value: data.maxSurface },
    { label: `${t('MinSurface')}:`, value: data.minSurface },
    { label: `${t('MaxBathrooms')}:`, value: data.maxBathrooms },
    { label: `${t('MinBathrooms')}:`, value: data.minBathrooms },
  ];

  const isAnyStats = stats.some(({ value }) => value);
  if (!isAnyStats) return null;

  return (
    <Box
      sx={{
        backgroundColor: 'background.white',
        color: 'text.primary',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        gap: '12px',
        borderBottom: '2px solid #F5F5F7',
        paddingBottom: '24px',
      }}
    >
      <PromotionStatsTable stats={stats} />
    </Box>
  );
}

export default PromotionStats;
