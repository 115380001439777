import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { UseFormRegister, UseFormWatch } from 'react-hook-form';

import { DataFieldTag, IDataFields } from '../../../../types/settings';
import {
  DatafieldsGroupedByTagId,
  FieldValuePayload,
} from '../../../../types/property';

import useLocale from '../../../../hooks/useLocale';

import StepsFieldset from '../../../../components/stepper/stepsFieldset';
import CustomAttributeField from './field';

function CustomAttributesDisplay({
  groupedDataFields,
  fieldValues = [],
  register,
  watch,
}: {
  groupedDataFields: DatafieldsGroupedByTagId;
  fieldValues?: FieldValuePayload[];
  register: UseFormRegister<any>;
  watch: UseFormWatch<any>;
}) {
  const { dataFieldsWithTags, takenIndexes } = useDataFieldsWithTags(
    groupedDataFields,
    fieldValues
  );

  let lastTakenIndex = fieldValues.length;

  const { t } = useTranslation('common');

  return (
    <>
      {dataFieldsWithTags.map(({ tagName, dataFields }, index) => (
        <StepsFieldset
          key={index}
          className="grid grid-cols-2 gap-12 items-end"
          title={tagName || t('Autres')}
        >
          {dataFields.map((field) => {
            return (
              <div key={field.id}>
                <CustomAttributeField
                  dataField={field}
                  indexInFieldValues={
                    typeof takenIndexes[field.id] === 'number'
                      ? (takenIndexes[field.id] as number)
                      : lastTakenIndex++
                  }
                  register={register}
                  watch={watch}
                />
              </div>
            );
          })}
        </StepsFieldset>
      ))}
    </>
  );
}

export default CustomAttributesDisplay;

function useDataFieldsWithTags(
  groupedDataFields: DatafieldsGroupedByTagId,
  fieldValues: FieldValuePayload[]
) {
  const { locale } = useLocale();
  return useMemo(() => {
    const dataFieldsWithTags = [];
    const takenIndexes: Record<string, number> = {};
    for (const [tagId, dataFields] of Object.entries(groupedDataFields)) {
      let tagName;
      if (tagId === 'undefined_tag') {
        tagName = null;
      } else {
        const tag = extractTag(dataFields);
        tagName = getTagName(tag, locale);
      }
      for (const field of dataFields) {
        const index = findFieldIndex(fieldValues, field.id);
        if (index !== null) {
          takenIndexes[field.id] = index;
        }
      }
      dataFieldsWithTags.push({ tagName, dataFields });
    }
    return { dataFieldsWithTags, takenIndexes };
  }, [groupedDataFields, locale, fieldValues]);
}

function getTagName(tag: DataFieldTag | null, locale: string) {
  return (
    tag?.translations?.[locale]?.name ||
    tag?.translations?.[tag.defaultTagLocale]?.name ||
    null
  );
}

function extractTag(dataFields: IDataFields[]) {
  const firstOneWithTag = dataFields.find((field) => field.tag);
  return firstOneWithTag?.tag || null;
}

function findFieldIndex(fieldValues: FieldValuePayload[], dataFieldId: number) {
  const field = fieldValues.find(
    (value) => value.dataField === String(dataFieldId)
  );
  if (field) {
    return fieldValues.indexOf(field);
  }
  return null;
}
