import React from 'react';
import { useTranslation } from 'react-i18next';
import useLocale from '../../../hooks/useLocale';
interface Props {
  item: any;
}

export default function Status({ item }: Props) {
  const { t } = useTranslation('propertiesPage');
  const { locale } = useLocale();
  const statusPromotions = [
    {
      name: 'inProgress',
      style: 'bg-[#02d5ed]',
    },
    {
      name: 'sold',
      style: 'bg-[#3446B9]',
    },
    {
      name: 'prospecting',
      style: 'bg-[#ed0202]',
    },
    {
      name: 'active',
      style: 'bg-[#4CAF50]',
    },
    {
      name: 'rent',
      style: 'bg-[#ed02e3]',
    },
    {
      name: 'reserved',
      style: 'bg-[#ED6C02]',
    },
    {
      name: 'suspended',
      style: 'bg-red-500',
    },
  ];
  const status = (key: string, style: string) => {
    if (
      item?.statusOfProperties[key] &&
      item.statusOfProperties[key]?.translations?.[locale]?.value != null
    ) {
      return (
        <div
          className={`p-[6px] py-2 rounded flex gap-1 text-white text-xs font-normal leading-none ${style}`}
        >
          <p className="first-letter:uppercase">
            {item.statusOfProperties[key]?.translations?.[locale]?.value} :{' '}
            {item.statusOfProperties[key].count}
          </p>
        </div>
      );
    }
  };

  return (
    <div className="w-full pb-2 flex justify-start flex-wrap  gap-1">
      {'numberOfProperties' in item && (
        <div className="p-[6px] py-2 bg-neutral-100  text-zinc-900 rounded flex gap-1  text-xs font-normal leading-none">
          {item.numberOfProperties + ' '}
          {item.numberOfProperties === 1
            ? t('Property')
            : t('common:Properties')}
        </div>
      )}
      {statusPromotions.map((elem: { name: string; style: string }) =>
        status(elem.name, elem.style)
      )}
    </div>
  );
}
