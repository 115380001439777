import { useTranslation } from 'react-i18next';
import useLocale from '../../../../../hooks/useLocale';
import { PropertyWidgetPreviewProps } from '../../../../../types/brochureEditor';
import TableWidget from '../../tableWidget';

const PropertyGeneralDataWidget = ({
  property,
  widgetOptions,
}: PropertyWidgetPreviewProps) => {
  const { t } = useTranslation('propertiesPage');

  const withCategory = widgetOptions?.withCategory?.value;
  const withPropertyType = widgetOptions?.withPropertyType?.value;
  const withAvailability = widgetOptions?.withAvailability?.value;

  const items = [];

  const { locale } = useLocale();
  if (withCategory) {
    items.push({
      id: '101',
      label: t('Category'),
      value: property.category?.translations?.[locale]?.value || '-',
    });
  }

  if (withPropertyType) {
    items.push({
      id: '102',
      label: t('sub-category'),
      value: property.type?.translations?.[locale]?.value || '-',
    });
  }

  if (withAvailability) {
    items.push({
      id: '103',
      label: t('Availability'),
      value: property.availability?.translations?.[locale]?.value || '-',
    });
  }

  return (
    <TableWidget
      title={t('common:general-information')}
      items={items}
      widgetOptions={widgetOptions}
    />
  );
};

export default PropertyGeneralDataWidget;
