import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

export default function MLSNetworkIcon({
  width = '16',
  height = '16',
  color,
  className,
}: IconProps) {
  const { mode } = useThemeMode();

  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        d="M16 27.5C17.933 27.5 19.5 25.933 19.5 24C19.5 22.067 17.933 20.5 16 20.5C14.067 20.5 12.5 22.067 12.5 24C12.5 25.933 14.067 27.5 16 27.5Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.5 12.5C24.8807 12.5 26 11.3807 26 10C26 8.61929 24.8807 7.5 23.5 7.5C22.1193 7.5 21 8.61929 21 10C21 11.3807 22.1193 12.5 23.5 12.5Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 9.5C9.88071 9.5 11 8.38071 11 7C11 5.61929 9.88071 4.5 8.5 4.5C7.11929 4.5 6 5.61929 6 7C6 8.38071 7.11929 9.5 8.5 9.5Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 9.99951V13.4998C8.5 13.8937 8.5776 14.2838 8.72837 14.6478C8.87913 15.0118 9.10012 15.3425 9.3787 15.6211C9.65728 15.8997 9.98801 16.1207 10.352 16.2714C10.716 16.4222 11.1061 16.4998 11.5001 16.4998L20.5001 16.4996C21.2957 16.4996 22.0587 16.1835 22.6213 15.6209C23.1839 15.0583 23.5 14.2952 23.5 13.4996V12.4995"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0003 16.5L16 20.5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
