import {
  Box,
  Button,
  Fade,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
} from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CheckIcon from '@mui/icons-material/Check';
import Modal from '../../../components/modal';
import { useTranslation } from 'react-i18next';

import { http } from '../../../libs/axios';
import { useRef, useState } from 'react';

type Props = {
  openModal: boolean;
  id: number | undefined;
  weighting: string;
  isSetted: boolean | undefined;
  isHabitable?: boolean;
  setOpenModal: (value: boolean) => void;
  setIsHabitable: (value: boolean) => void;
  setLoading: (value: boolean) => void;
  setWeighting: (value: string) => void;
  fetchData: () => Promise<void>;
};

const SpaceTypeModal = ({
  openModal,
  isSetted,
  id,
  weighting,
  isHabitable,
  setOpenModal,
  setIsHabitable,
  setWeighting,
  setLoading,
  fetchData,
}: Props) => {
  const { t } = useTranslation('common');
  const selectRef = useRef(null);

  const [isSelectOpen, setSelectOpen] = useState(false);

  const onClickAway = () => {
    if (isSelectOpen) return;
    setOpenModal(false);
  };

  const handleSelectOpen = () => setSelectOpen(true);
  const handleSelectClose = () => setSelectOpen(false);

  const renderList = () => {
    const items = [];
    items.push(<MenuItem value="0"> {t('No_select')} </MenuItem>);
    for (let i = 1; i <= 10; i++) {
      items.push(<MenuItem value={i / 10}> {i / 10} </MenuItem>);
    }
    return items;
  };

  const onSubmit = async () => {
    try {
      if (isSetted) {
        await http.patch(`space_types_settings/${id}`, {
          weighting: parseFloat(weighting),
          isHabitable: isHabitable,
          spaceType: id,
        });
      } else {
        await http.post('space_types_settings', {
          weighting: parseFloat(weighting),
          isHabitable: isHabitable,
          spaceType: id,
        });
      }
      setLoading(true);
    } catch (error) {
      console.error('Error updating settings:', error);
    } finally {
      fetchData();
    }

    setOpenModal(false);
  };

  return (
    <Modal
      withButton={false}
      isOpen={openModal}
      closeModal={() => setOpenModal(false)}
      openModal={() => setOpenModal(true)}
      sx={{
        maxHeight: '100vh',
      }}
      width={{
        md: '45%',
      }}
      boxClassName="border rounded-0 !py-0"
      title={
        <span className="text-lg font-medium Inter !pb-2">
          {isSetted ? t('Edit_space_type') : t('Add_space_type')}
        </span>
      }
      buttonProps={{
        title: 'Choose role',
      }}
    >
      <ClickAwayListener onClickAway={onClickAway}>
        <Fade in={openModal}>
          <Box>
            <div className="flex flex-wrap justify-between w-full">
              <div className="flex justify-between items-center w-[40%]">
                <span>{t('Habitable')} :</span>
                <div>
                  <Switch
                    defaultChecked={isHabitable}
                    onChange={(e: any) => {
                      setIsHabitable(e.target.checked);
                    }}
                  />
                </div>
              </div>

              <div className="w-[55%] gap-4 flex justify-between items-center">
                <span>{t('weighting')} :</span>

                <FormControl
                  variant="outlined"
                  style={{ width: 200 }}
                  margin="normal"
                  ref={selectRef}
                >
                  <InputLabel className="mt-[-5px]">
                    {t('weighting')}
                  </InputLabel>
                  <Select
                    defaultValue={weighting}
                    onChange={(e: SelectChangeEvent) =>
                      setWeighting(e.target.value)
                    }
                    label="weighting"
                    onOpen={handleSelectOpen}
                    onClose={handleSelectClose}
                  >
                    {renderList()}
                  </Select>
                </FormControl>
              </div>
            </div>

            <Box className="w-full flex justify-around px-16 py-6">
              <Button
                onClick={() => {
                  setLoading(false);
                  onSubmit();
                  setOpenModal(false);
                }}
                variant="contained"
                disableElevation
                startIcon={<CheckIcon fontSize="small" />}
              >
                {t('Save')}
              </Button>
            </Box>
          </Box>
        </Fade>
      </ClickAwayListener>
    </Modal>
  );
};

export default SpaceTypeModal;
