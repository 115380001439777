import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

import { IndependentWidgetProps } from '../../../../../types/brochureEditor';
import FontSizeCalibrator from '../../fontSizeCalibrator';

const TextBlock = ({ widgetOptions }: IndependentWidgetProps) => {
  const textAlign = widgetOptions?.textAlign?.value;
  const color = widgetOptions?.color?.value;

  const { t } = useTranslation('brochureEditor');

  return (
    <div
      className="text-2xl leading-[calc(28/24)] whitespace-pre-wrap absolute inset-0"
      style={{ textAlign, color } as CSSProperties}
    >
      <FontSizeCalibrator
        html={
          (widgetOptions?.text?.value as string) ||
          t('enter_text_in_widget_settings')
        }
      />
    </div>
  );
};

export default TextBlock;
