import useSWR from 'swr';

import { ICustomAttributesApi } from '../../../../../types/settings';
import RepeatableCustomAttributesForm from './form/repeatableCustomAttributesForm';
import CustomAttributesList from './customAttributesList';
import SpinnerLoading from '../../../../../features/spinner/spinnerLoading';

const CustomAttributesSettings = () => {
  const { data: fieldsList } = useSWR<ICustomAttributesApi[]>('/data_fields');

  return (
    <div className="flex flex-col gap-8">
      <RepeatableCustomAttributesForm />

      {fieldsList ? (
        <CustomAttributesList fieldsList={fieldsList} />
      ) : (
        <SpinnerLoading />
      )}
    </div>
  );
};

export default CustomAttributesSettings;
