import { IconProps } from './locationIcon';

export default function MatchingIcon({
  className,
  width = '24',
  height = '25',
}: IconProps) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon / Matching" clipPath="url(#clip0_6530_6872)">
        <rect
          id="Rectangle 16"
          x="6.5"
          y="1"
          width="17"
          height="17"
          stroke="currentColor"
        />
        <rect
          id="Rectangle 17"
          x="0.5"
          y="7"
          width="17"
          height="17"
          stroke="currentColor"
        />
        <path id="Vector 61" d="M6.75 12.5L12 7.25" stroke="currentColor" />
        <path id="Vector 63" d="M12 17.75L17.25 12.5" stroke="currentColor" />
        <path id="Vector 62" d="M6.75 17.75L17.25 7.25" stroke="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_6530_6872">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
