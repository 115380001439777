import React, { useEffect, useState } from 'react';
import { IContact, IContactSearch } from '../../../../types/contacts';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import InputLayout from '../../../../components/form/components/inputLayout';
import { Button, MenuItem, Select } from '@mui/material';
import classNames from 'classnames';
import SearchIcon from '../../../../features/icons/searchIcon';
import useSWR from 'swr';
import { checkType } from './contact-type-form';
// import useLocale from '../../../../hooks/useLocale';
import SearchCriteriaItem from '../matching/item';
import { useTranslation } from 'react-i18next';
import { useQuery } from '../../../../hooks/useQuery';

export interface IExtendedContactSearch extends IContactSearch {
  checked?: boolean;
  realId?: string;
}

interface FormValues {
  type: number | null;
  types: number | null;
  search: IExtendedContactSearch[] | null;
}

interface Props {
  data: IContact;
  searchCriteria: IContactSearch[];
  contactId: string | number;
  onSubmit: (data: any) => void;
}

const getSearchItems = (
  items: IContactSearch[],
  type: number,
  defaultValues?: string[]
) => {
  let typesIds: number[] = [];
  let returnedValue: any = [];

  switch (type) {
    case 4: //rent
      typesIds = [2, 4];
      break;
    case 3: //buy
      typesIds = [1, 3];
      break;
    default:
      typesIds = [];
  }

  if (items) {
    const returnedArr = items.filter((el) => {
      if (el.serviceType) {
        return typesIds.includes(el.serviceType.id);
      }
    });
    returnedValue = returnedArr.map((item) => ({
      ...item,
      realId: item.id,
      checked:
        defaultValues && item.id && defaultValues.includes(item.id.toString()),
    }));
  }

  return returnedValue;
};

const MatchingSearchForm = (props: Props) => {
  const { searchCriteria, data, contactId, onSubmit } = props;
  // const [searchPopupIsOpen, setSearchPopupIsOpen] = useState(false);
  const [innerTypes, setInnerTypes] = useState([]);
  // const [fieldsWithTranslations, setFieldsWithTranslations] = useState<IContactSearch[]>([]);
  // const [currentItem, setCurrentItem] = useState<IContactSearch | null>(null);
  const query = useQuery();
  const { t } = useTranslation('common');
  const {
    handleSubmit,
    watch,
    control,
    setValue,
    resetField,
    formState: { dirtyFields },
  } = useForm<FormValues>({
    defaultValues: {
      type: query.get('type') ? parseInt(query.get('type') || '') : null,
      types: query.get('types') ? parseInt(query.get('types') || '') : null,
      search: query.get('search')
        ? getSearchItems(
            searchCriteria,
            parseInt(query.get('types') || ''),
            query.get('search')?.split(',')
          )
        : null,
    },
  });

  const { fields, replace } = useFieldArray({
    control,
    name: 'search',
  });

  const watchType = watch('type');
  const watchTypes = watch('types');
  const { data: types } = useSWR(`/contact_types`);

  const clientType = checkType(types, data, 'client');
  const ownerType = checkType(types, data, 'owner');
  const externalType = checkType(types, data, 'external');

  const typesOptions =
    types &&
    types.filter((el: { keyname: string }) => {
      const arr = [];

      if (clientType) {
        arr.push('client');
      }
      if (ownerType) {
        arr.push('owner');
      }
      if (externalType) {
        arr.push('external');
      }
      return arr.includes(el.keyname);
    });

  useEffect(() => {
    if (
      searchCriteria &&
      searchCriteria.length &&
      query.get('types') &&
      query.get('search')
    ) {
      const arr = getSearchItems(
        searchCriteria,
        parseInt(query.get('types') || ''),
        query.get('search')?.split(',')
      );
      replace(arr);
      // setFieldsWithTranslations(arr);
    }
  }, [searchCriteria]);

  useEffect(() => {
    if (watchType && types) {
      const arr = types.find((el: { id: number }) => el.id === watchType);
      const keynames = data.types.map(
        (item: { keyname: string }) => item.keyname
      );

      if (arr && arr.choices) {
        setInnerTypes(
          arr.choices.filter((item: { keyname: string }) =>
            keynames.includes(item.keyname)
          )
        );
      }
    }

    if (dirtyFields.type) {
      resetField('types', {
        defaultValue: null,
      });
      resetField('search', {
        defaultValue: [],
      });
      replace([]);
    }
  }, [watchType, types]);

  useEffect(() => {
    if (watchTypes) {
      const arr = getSearchItems(searchCriteria, watchTypes);
      replace(arr);
      // setFieldsWithTranslations(arr)
    }
  }, [watchTypes]);

  // const onViewClose = () => {
  //   setSearchPopupIsOpen(false);
  //
  //   setTimeout(() => {
  //     setCurrentItem(null);
  //   }, 1000);
  // };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={'grid grid-cols-3 gap-8 2xl:gap-x-16 pt-4 relative'}
    >
      <InputLayout label={t('Type')}>
        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <Select
              sx={{
                '& .MuiSelect-select': {
                  textTransform: 'capitalize',
                },
              }}
              className={'h-[56px] matching_type'}
              labelId="level-label"
              {...field}
            >
              {typesOptions &&
                typesOptions.length &&
                typesOptions.map(
                  (item: { id: number; keyname: string }, index: number) => (
                    <MenuItem
                      key={index}
                      value={item?.id}
                      className={`capitalize matching_type_${item.id}`}
                    >
                      {item.keyname}
                    </MenuItem>
                  )
                )}
            </Select>
          )}
        />
      </InputLayout>
      <InputLayout
        label={t('types')}
        className={classNames('', {
          'opacity-50 pointer-events-none': !watchType,
        })}
      >
        <Controller
          name="types"
          control={control}
          render={({ field }) => (
            <Select
              sx={{
                '& .MuiSelect-select': {
                  textTransform: 'capitalize',
                },
              }}
              disabled={!watchType}
              className={'h-[56px] matching_types'}
              labelId="level-label"
              {...field}
            >
              {innerTypes &&
                innerTypes.map(
                  (item: { id: number; keyname: string }, index: number) => (
                    <MenuItem
                      key={index}
                      value={item?.id}
                      className={`capitalize matching_types_${item.id}`}
                    >
                      {item.keyname}
                    </MenuItem>
                  )
                )}
            </Select>
          )}
        />
      </InputLayout>
      {watchType === 2 && fields && fields.length ? (
        <div
          className={classNames('', {
            'opacity-50 pointer-events-none': !watchType || !watchTypes,
          })}
        >
          <div className={'flex justify-between items-center border-b pb-4'}>
            <p className={'text-[16px]'}>
              {t('Search_criteria')} {fields ? `(${fields.length})` : ''}
            </p>
          </div>
          <div>
            {fields && fields.length
              ? fields.map((item, index) => (
                  <SearchCriteriaItem
                    key={item.id}
                    item={item}
                    index={index}
                    control={control}
                    setValue={setValue}
                    contactId={contactId}
                  />
                ))
              : null}
          </div>
        </div>
      ) : null}
      <div className={'col-start-1 col-end-4 border-t pt-8'}>
        <Button
          className="!grid !grid-cols-3 h-9 col-span-1 w-full cursor-pointer matching_search_btn"
          variant="outlined"
          disabled={
            watchType === 2
              ? !(
                  watchType &&
                  watchTypes &&
                  !!watch('search')?.filter((el) => el.checked).length
                )
              : !(watchType && watchTypes)
          }
          sx={{
            width: '100%',
            height: '50px',
            border: '1px solid #3446B9 !important',
          }}
          type={'submit'}
        >
          <SearchIcon color="#3446B9" className="" />
          <span> {t('Find')} </span>
        </Button>
      </div>
    </form>
  );
};

export default MatchingSearchForm;
