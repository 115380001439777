import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tooltip } from '@mui/material';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import useLocale from '../../../../hooks/useLocale';
import PermissionIcon from '../../../../features/icons/permissionIcon';
import WarningIcon from '../../../../features/icons/warningIcon';
import { PropertyListingItem } from '../../../../types/property';

import PropertiesInfoModal from './modal';

const PropertiesReferenceModal = ({
  row: listingItem,
}: {
  row: PropertyListingItem;
}) => {
  const { t } = useTranslation('propertiesPage');

  const { locale } = useLocale();

  const [openModal, setOpenModal] = useState(false);

  const warningTranslations = Array.isArray(
    listingItem?.warningField?.translations
  )
    ? {}
    : listingItem?.warningField?.translations;
  const warning = warningTranslations?.[locale]?.value;
  const title = listingItem?.translations?.[locale]?.title;
  const PublishWebsite = listingItem?.publishWebsite?.publishWebsite;
  const PublishGateways = listingItem?.publishGateways;
  const type = listingItem?.type?.translations?.[locale]?.value;
  const rooms = listingItem?.rooms;
  const city = listingItem?.address?.translations?.[locale]?.city;

  return (
    <>
      <Box
        onClick={() => setOpenModal(true)}
        className=" grid gap-2 pl-3 py-1 w-full h-full cursor-pointer"
      >
        <div className="flex justify-between">
          <Tooltip title={t('common:Property_title')} arrow placement="top">
            <div className="w-[220px]">
              <p className=" inter font-medium text-base leading-5 truncate">
                {title ? (
                  <span>{title}</span>
                ) : (
                  <span className="leading-6">
                    {type && type}
                    {rooms && rooms >= 2
                      ? ' ' + rooms + ` ${t('rooms')}`
                      : ' ' + rooms + ` ${t('room')}`}
                    {city && ', ' + city}
                  </span>
                )}
              </p>
            </div>
          </Tooltip>

          {(PublishWebsite || PublishGateways.length > 0) && (
            <Tooltip
              title={
                PublishWebsite && PublishGateways.length > 0
                  ? t('common:Published_on_websites_and_Published_on_gateways')
                  : PublishWebsite
                  ? t('common:Publish_on_websites')
                  : t('common:Publish_on_gateways')
              }
              arrow
              placement="top"
            >
              <div className="w-[15px] h-[15px] rounded-full bg-green-500"></div>
            </Tooltip>
          )}
        </div>

        <div className="flex items-stretch gap-2 relative">
          <div>
            <div
              className="flex gap-1 py-1 px-1 mb-[6px] rounded-md items-center min-w-[135px] max-w-[135px]"
              style={{ border: '1px solid #3446B9 ' }}
            >
              <PermissionIcon className=" col-span-1" color="#3446B9" />{' '}
              <span className="text-blue inter leading-4 font-normal text-sm">
                {listingItem?.reference}
              </span>
            </div>
            {listingItem?.agencyReference && (
              <Tooltip
                title={`${t('Reference_Agency')}: ${
                  listingItem.agencyReference
                }`}
                arrow
                placement="top"
              >
                <div
                  className="flex gap-1 py-1 px-1 rounded-md items-center min-w-[135px] max-w-[135px]"
                  style={{ border: '1px solid #00bfff' }}
                >
                  <PermissionIcon
                    className="w-[25px] col-span-1"
                    color="#00bfff"
                  />
                  <span className="text-[#00bfff] block truncate inter leading-4 font-normal text-sm">
                    {listingItem.agencyReference}
                  </span>
                </div>
              </Tooltip>
            )}
          </div>

          <span
            style={{ border: '1px solid #4CAF50' }}
            className="py-1 px-2 h-max rounded-md text-[#4CAF50] leading-4 font-normal text-sm inter capitalize min-w-[58px]"
          >
            {listingItem?.status?.translations[locale]?.value}
          </span>
          <div className="absolute bottom-0 right-0">
            {warning && (
              <CustomWidthTooltip
                title={<span>{warning}</span>}
                arrow
                placement="top"
              >
                <span>
                  <WarningIcon color="#FFA500" />
                </span>
              </CustomWidthTooltip>
            )}
          </div>
        </div>
      </Box>
      {listingItem ? (
        <PropertiesInfoModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          listingItem={listingItem}
        />
      ) : null}
    </>
  );
};

export default PropertiesReferenceModal;

export function trimIf(isExpanded: boolean, text = '', maxLength = 85) {
  return isExpanded
    ? text
    : text.slice(0, maxLength) + (text.length > maxLength ? '...' : '');
}

export function textIsLongerThen(text?: string, length = 100) {
  return text ? text.length > length : false;
}

export const CustomWidthTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 250,
  },
});
