import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { WidgetPreviewProps } from '../../../../../types/brochureEditor';
import QrCode from '../../qrCode';
import { useGoogleMapsLink } from '../../../lib/hooks';

const MapBlock = ({ previewSubject, widgetOptions }: WidgetPreviewProps) => {
  const { t } = useTranslation('brochureEditor');

  const googleMapsLink = useGoogleMapsLink(previewSubject);

  const withQr = widgetOptions?.withQr?.value;
  const zoom = widgetOptions?.zoom?.value;

  const lat = previewSubject.address?.latitude;
  const lng = previewSubject.address?.longitude;

  let contents: ReactNode = null;
  if (lat && lng) {
    const params = new URLSearchParams({
      size: '640x640',
      scale: '1',
      format: 'webp',
      maptype: 'roadmap',
      markers: `${lat},${lng}`,
      key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
    });
    if (zoom) {
      params.append('zoom', zoom.toString());
    }
    contents = (
      <>
        <img
          src={`https://maps.googleapis.com/maps/api/staticmap?${params.toString()}`}
          alt="map"
          className="absolute top-0 left-0 w-full h-full object-cover"
        />

        {withQr && googleMapsLink && (
          <div className="absolute bottom-0 right-0 z-10">
            <QrCode value={googleMapsLink}></QrCode>
          </div>
        )}
      </>
    );
  } else {
    contents = <div className="grid place-items-center">{t('no_address')}</div>;
  }

  return <div className="relative grid">{contents}</div>;
};

export default MapBlock;
