import SpinnerLoading from '../../../../features/spinner/spinnerLoading';
import * as React from 'react';
import { ContactTapInnerProps } from './search-criteria-tab';
import Matching from '../../components/matching';
import useSWR from 'swr';

const MatchingTab = (props: ContactTapInnerProps) => {
  const { data, id } = props;
  const { data: search } = useSWR(`/search_contacts/contacts/${id}`);

  return data ? (
    <Matching id={id} data={data} search={search} />
  ) : (
    <div className={'col-start-1 col-end-4 min-h-[500px]'}>
      <SpinnerLoading />
    </div>
  );
};

export default MatchingTab;
