import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

function GardenSurfIcon({ color, className }: IconProps) {
  const { mode } = useThemeMode();
  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        d="M4 .5H.5V4M15.5 4V.5H12M12 15.5h3.5V12M.5 12v3.5H4M8 11.5H4L6.5 7H5l3-5 3 5H9.5l2.5 4.5H8zm0 0V14"
      ></path>
    </svg>
  );
}

export default GardenSurfIcon;
