import {
  DesktopDatePicker,
  DesktopDatePickerProps,
} from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import InputLayout from '../form/components/inputLayout';
import { Control, Controller, UseFormWatch } from 'react-hook-form';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

type Props = {
  name: string;
  label: string;
  control: Control<any, any>;
  watch: UseFormWatch<any>;
} & DesktopDatePickerProps<Dayjs>;

function ReusableDatePicker({ name, label, control, watch, ...rest }: Props) {
  return (
    <InputLayout label={label} className="custom-datepicker">
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange } }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              disablePast
              format="DD/MM/YYYY"
              defaultValue={watch(name) ? dayjs.utc(watch(name)) : null}
              onChange={(value: any) =>
                onChange(dayjs.utc(value).add(1, 'hours'))
              }
              {...rest}
            />
          </LocalizationProvider>
        )}
      />
    </InputLayout>
  );
}

export default ReusableDatePicker;
