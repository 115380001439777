import React, { CSSProperties, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, EffectFade, Navigation } from 'swiper/modules';
import classNames from 'classnames';
import LeftArrowIcon from '../../../../../features/icons/leftArrowIcon';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import PopupImage from './popupImage';

const MediaGallery = (props: {
  items: any;
  slideClassName?: string;
  initialSlide?: number;
  isLogo?: boolean;
  disableRotation?: boolean;
  fileUploadUrl?: string;
  mutate?: any;
}) => {
  const [activeSlide, setActiveSlide] = useState(null);
  return (
    <Swiper
      // @ts-ignore
      preloadImages={false}
      lazy={true}
      centeredSlides={true}
      loop={false}
      speed={1000}
      slidesPerView={1}
      effect={'fade'}
      navigation={{
        nextEl: '.next',
        prevEl: '.prev',
      }}
      initialSlide={props.initialSlide}
      modules={[Pagination, EffectFade, Navigation]}
      style={swiperStyle as CSSProperties}
      pagination={{
        type: 'bullets',
      }}
      onSlideChange={(swiper: any) => {
        setActiveSlide(swiper.activeIndex);
      }}
      className="with-bullets-scale mySwiper image-gallery-slider"
    >
      <button className="border bg-white transition-all duration-500  hover:bg-gray-200 text-black md:flex items-center prev absolute z-20 left-2 md:left-4 inset-y-0 m-auto w-[40px] h-[40px] rounded-md flex justify-center disabled:opacity-50 disabled:pointer-events-none">
        <span className={'flex rotate-180'}>
          <LeftArrowIcon />
        </span>
      </button>
      <button className="border bg-white transition-all duration-500  hover:bg-gray-200 text-black md:flex items-center next absolute z-20 right-2 md:right-4 inset-y-0 m-auto w-[40px] h-[40px] rounded-md flex justify-center disabled:opacity-50 disabled:pointer-events-none">
        <LeftArrowIcon />
      </button>
      {props.items.map((item: any) => {
        return (
          <>
            {props.isLogo ? (
              <>
                {item?.x96 ? (
                  <SwiperSlide
                    key={item.id}
                    className={classNames(
                      'rounded-md h-[90vh] flex justify-center items-center max-h-full relative',
                      props.slideClassName
                    )}
                  >
                    <img
                      src={item?.x96}
                      className={'!object-contain h-full w-auto'}
                    />
                  </SwiperSlide>
                ) : null}
              </>
            ) : (
              <>
                {item?.url?.large ? (
                  <SwiperSlide
                    key={item.id}
                    className={classNames(
                      'rounded-md h-[90vh] flex justify-center items-center max-h-full relative',
                      props.slideClassName
                    )}
                  >
                    <PopupImage
                      image={item}
                      maxSizeMb={50}
                      mutate={props.mutate}
                      disableRotation={props.disableRotation}
                      activeSlide={activeSlide}
                    />
                  </SwiperSlide>
                ) : null}
              </>
            )}
          </>
        );
      })}
    </Swiper>
  );
};

export default MediaGallery;

const swiperStyle = {
  '--swiper-pagination-color': '#fff',
  '--swiper-pagination-bullet-inactive-color': '#999999',
  '--swiper-pagination-bullet-inactive-opacity': '0.6',
  '--swiper-pagination-bullet-size': '6px',
  '--swiper-pagination-bullet-active-size': '8px',
  '--swiper-pagination-bullet-horizontal-gap': '4px',
};
