import { DateRangePicker, DateRangePickerProps } from '@mui/x-date-pickers-pro';
import { Control, Controller } from 'react-hook-form';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
type Props = {
  control: Control<any, any>;
  nameFrom: string;
  nameTo: string;
  labelFrom?: string;
  labelTo?: string;
  required?: boolean;
} & DateRangePickerProps<Dayjs>;

function DateRangeField({
  control,
  nameFrom,
  nameTo,
  labelFrom,
  labelTo,
  required = false,
  ...rest
}: Props) {
  return (
    <Controller
      rules={{ required }}
      control={control}
      name={nameFrom}
      render={({ field: fieldFrom }) => (
        <Controller
          rules={{ required }}
          control={control}
          name={nameTo}
          render={({ field: fieldTo }) => {
            return (
              <DateRangePicker
                localeText={{ start: labelFrom, end: labelTo }}
                value={[
                  prepareValue(fieldFrom.value),
                  prepareValue(fieldTo.value),
                ]}
                onChange={([newFrom, newTo]) => {
                  fieldFrom.onChange(newFrom);
                  fieldTo.onChange(newTo);
                }}
                format="DD/MM/YYYY"
                {...rest}
              />
            );
          }}
        />
      )}
    />
  );
}

export default DateRangeField;

function prepareValue(value: Date | string | null): Dayjs | null {
  return value ? dayjs.utc(value) : null;
}
