import ControlLayout from '../controlLayout';
import { EditorControlProps } from '../../../../types/brochureEditor';
import { TextField } from '@mui/material';

const TextControl = ({
  value,
  onChange,
  label,
}: EditorControlProps<string>) => {
  return (
    <ControlLayout label={label}>
      <TextField
        className="w-full bg-white"
        value={value}
        onChange={(e) => onChange?.(e.target.value)}
        InputProps={{
          multiline: true,
          minRows: 3,
        }}
      />
    </ControlLayout>
  );
};

export default TextControl;
