import classNames from 'classnames';

function Hamburger({ isOpened = false }: { isOpened?: boolean }) {
  return (
    <div className="grid gap-1.5 w-5">
      <div
        className={classNames('h-px bg-current transition-all origin-left', {
          'rotate-[45deg] translate-x-0.5': isOpened,
        })}
      ></div>
      <div
        className={classNames('h-px bg-current transition-all origin-left', {
          'scale-x-0': isOpened,
        })}
      ></div>
      <div
        className={classNames('h-px bg-current transition-all origin-left', {
          '-rotate-[45deg] translate-x-0.5': isOpened,
        })}
      ></div>
    </div>
  );
}

export default Hamburger;
