import useSWR from 'swr';
import { useEffect, useState } from 'react';
import { ApiListing } from '../../../../types/api';
import { IPersonalNotification } from '../../../../types/notification';
import NotificationsList from '../../notificationsList';
const StarredNotifications = () => {
  const [page, setPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState(false);
  const [starredData, setStarredData] = useState<IPersonalNotification[]>([]);
  const { data: starredNotificationsList } = useSWR<
    ApiListing<IPersonalNotification>
  >(`/personal_notifications?isStarred=true&page=${page}&itemsPerPage=${10}`);
  useEffect(() => {
    if (starredNotificationsList) {
      setStarredData(starredNotificationsList['hydra:member']);
    }
  }, [starredNotificationsList]);

  if (
    !starredNotificationsList ||
    !starredNotificationsList['hydra:member'].length
  )
    return null;

  return (
    <div>
      <NotificationsList
        as="starred"
        page={page}
        setIsLoading={setIsLoading}
        handlePage={setPage}
        data={starredData}
        itemsPerPage={10}
        autoHeight={true}
        loadingItemsToDisplay={isLoading}
        notificationsList={starredNotificationsList}
      />
    </div>
  );
};

export default StarredNotifications;
