import { FormControl, TextField } from '@mui/material';
import classNames from 'classnames';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import { Control, useController } from 'react-hook-form';
import InputLayout from './components/inputLayout';

export interface AutocompleteOption {
  label: string;
  id: string;
  value?: string;
}

type Props = Omit<
  AutocompleteProps<AutocompleteOption, false, false, false>,
  'renderInput'
> & {
  label: string;
  isRequired?: boolean;
  className?: string;
  error?: string;
  name: string;
  control: Control<any>;
};

const AutoCompleteField = ({
  label,
  className,
  error,
  isRequired,
  name,
  control,
  options,
  ...rest
}: Props) => {
  const { field } = useController({ name, control });
  return (
    <InputLayout isRequired={isRequired} label={label}>
      <FormControl error={!!error} fullWidth>
        <Autocomplete
          disablePortal
          renderInput={(params) => (
            <TextField variant="outlined" {...params} required={isRequired} />
          )}
          value={options.find((o) => o.id === field.value)}
          onChange={(e, option) => field.onChange(option?.id || null)}
          ref={field.ref}
          onBlur={field.onBlur}
          className={classNames('w-full h-12', className)}
          options={options}
          {...rest}
        />
      </FormControl>
    </InputLayout>
  );
};

export default AutoCompleteField;
