import { Avatar, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LocationIcon from '../../../../features/icons/locationIcon';
import { IPromotionApi } from '../../../../types/promotion';
import useLocale from '../../../../hooks/useLocale';
import { useMemo } from 'react';
import { formatPrice } from '../../../../helpers/utils';
// import {getCountry} from "../../../agencies/tree/utils";
// import {countries} from "../../../../const/countries";

interface Props {
  promotionTitle: string;
  data: IPromotionApi;
}

function DescriptionHeader({ data, promotionTitle }: Props) {
  const { t } = useTranslation('promotionPage');
  const { locale } = useLocale();
  const promotionAddress = useMemo(() => {
    if (data && data.address && data.address.translations) {
      const { translations } = data.address;
      const addressTranslation = translations[locale];

      if (addressTranslation) {
        // const propertyCountry = addressTranslation?.country || null;
        // let fullNameCountry = '';
        //
        // if (propertyCountry) {
        //     fullNameCountry = getCountry(countries, propertyCountry)[locale];
        // }
        return {
          // fullNameCountry,
          ...addressTranslation,
        };
      } else {
        return null;
      }
    } else {
      return null;
    }
  }, [locale, data]);

  return (
    <>
      <div>
        <Box
          sx={{
            display: 'flex',
            minHeight: '200px',
            backgroundColor: 'background.default',
          }}
        >
          {data.coverImage ? (
            <img
              className={'w-full'}
              src={data.coverImage.url.large}
              alt="promotion-desc"
            />
          ) : null}
        </Box>
        <div className={'flex'}>
          <div>
            <Avatar
              sx={{
                bgcolor: 'white',
                width: '128px',
                height: '128px',
                border: '1px solid #E6E8EC',
                marginTop: '-64px',
                marginLeft: '16px',
                borderRadius: '4px',
              }}
              variant="square"
            >
              {data.logo ? (
                <img src={data?.logo?.url?.thumbnail} alt="avatar" />
              ) : null}
            </Avatar>
          </div>
          <div className={'pl-4 pt-4 grow'}>
            {promotionAddress ? (
              <Box className="flex items-center gap-2 opacity-50 pt-2">
                <LocationIcon />
                <p>
                  {promotionAddress.city}
                  {promotionAddress.adresse
                    ? `, ${promotionAddress.adresse}`
                    : ''}
                  {promotionAddress.country
                    ? `, ${promotionAddress.country}`
                    : ''}
                </p>
              </Box>
            ) : null}
            {promotionTitle ? (
              <p className={'text-[2rem] leading-none'}>{promotionTitle}</p>
            ) : null}
          </div>
          {data.propertiesMinPrice ? (
            <div className="border border-blue rounded p-[10px] h-full mt-4">
              <p className="text-[#3446B980] text-[.875rem] 2xl:text-[1rem] leading-none mb-2">
                {t('priceFrom')}:
              </p>
              <p className="text-blue text-[1.2rem] 2xl:text-[1.5rem] leading-none">
                {formatPrice(data.propertiesMinPrice)}
              </p>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default DescriptionHeader;
