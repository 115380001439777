import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

export default function EraseIcon({ color, className }: IconProps) {
  const { mode } = useThemeMode();

  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.059 11.323L14 5.971 9.412 1 4.47 6.353m4.588 4.97L6.589 14 2 9.03l2.47-2.677m4.589 4.97L4.47 6.353"
      ></path>
      <path stroke={color} strokeLinecap="round" d="M3 14h10"></path>
    </svg>
  );
}
