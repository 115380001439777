import { ReactElement } from 'react';
import { Box, Divider, Tab, Tabs, Typography } from '@mui/material';
// import { makeStyles } from '@mui/styles';
import EditIcon from '../../features/icons/editIcon';
import CalendarIcon from '../../features/icons/calendarIcon';
import PermissionIcon from '../../features/icons/permissionIcon';

interface Props {
  items: Item[];
  activeItemId: number;
  onItemClick: (itemId: number) => unknown;
}

interface Item {
  id: number;
  title: string;
  icon?: string;
  content: ReactElement;
}

const componentMap: any = {
  EditIcon: EditIcon,
  CalendarIcon: CalendarIcon,
  PermissionIcon: PermissionIcon,
  // Add more component mappings here
};

const Pills = ({ activeItemId, items, onItemClick }: Props) => {
  const handleChange = (event: any, newValue: number) => {
    onItemClick(newValue);
  };

  return (
    <Box>
      <Tabs
        value={activeItemId}
        onChange={handleChange}
        TabIndicatorProps={{ sx: { border: '1px solid #E6E8EC' } }}
        sx={{
          height: '50px',
          '& button': {
            borderRadius: '7px',
            height: '100%',
            paddingBottom: '30px',
            borderBottom: '1px solid #F5F5F7',
            color: 'black',
          },
          '& span.MuiTabs-indicator': {
            border: '1px solid #F5F5F7',
          },
          '& button.Mui-selected': {
            backgroundColor: '#F5F5F7',
          },
          '& button:hover': { backgroundColor: '#f4f7fe' },
        }}
      >
        {' '}
        {items.length &&
          items.map(
            (
              { /* id, <= actually is not unique! */ title, icon }: Item,
              index: number
            ) => {
              const IconComponent: any = icon ? componentMap[icon] : null;
              return (
                <Tab
                  className={`${title}_Btn_${index}`}
                  key={index}
                  icon={
                    icon ? (
                      <IconComponent
                        color={index === activeItemId ? '#192eb6' : '#1D1D1F'}
                      />
                    ) : (
                      ''
                    )
                  }
                  iconPosition="start"
                  label={
                    <Typography
                      className="!text-[1.1rem] capitalize !font-medium"
                      component="span"
                    >
                      {title}
                    </Typography>
                  }
                />
              );
            }
          )}
      </Tabs>
      <Divider sx={{ margin: '20px 0', border: '1px solid #E6E8EC' }} />
      <Box className="p-1">{items.length && items[activeItemId]?.content}</Box>
    </Box>
  );
};

export default Pills;
