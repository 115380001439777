import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from 'react-beautiful-dnd';
import { useEffect } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import './surfacesCalculator.css';

import {
  Dimension,
  DimensionApi,
  IProperty,
  IPropertyApi,
} from '../../../../../../types/property';
import { useColumns } from './useColumns';
import { http } from '../../../../../../libs/axios';

function SurfacesCalculator({
  property,
}: {
  property?: Partial<IPropertyApi>;
}) {
  const { control } = useFormContext<IProperty>();

  const fieldArray = useFieldArray({
    control,
    name: 'dimensions',
  });

  const columns = useColumns({ fieldArray, property });

  const handleDragEnd: OnDragEndResponder = ({ draggableId, destination }) => {
    const fromIndex = fieldArray.fields.findIndex(
      (field) => field.id === draggableId
    );
    const toIndex = destination?.index;
    if (fromIndex !== -1 && toIndex !== undefined && fromIndex !== toIndex) {
      fieldArray.move(fromIndex, toIndex);
    }
  };

  const getServerDimensions = async () => {
    const { data: serverDimensions } = await http.get<DimensionApi[]>(
      `/v1/properties/${property?.id}/dimensions`
    );
    const dimensions: Dimension[] = serverDimensions?.map((el) => ({
      ...el,
      building: el.building ? String(el.building.id) : null,
      spaceType: el.spaceType ? String(el.spaceType.id) : null,
      orientation: el.orientation ? String(el.orientation.id) : null,
      flooring: el.flooring ? String(el.flooring.id) : null,
    }));
    if (dimensions) {
      fieldArray.remove();
      fieldArray.append(dimensions);
    }
  };
  useEffect(() => {
    getServerDimensions();
  }, []);

  const { t } = useTranslation('propertiesPage');

  return (
    <div className="py-12 flex flex-col gap-4">
      <div className="text-[1.5rem] leading-[1.2]">{t('areas')}</div>

      <div className="overflow-y-auto">
        <div className="w-0">
          <table className="table-fixed w-[1400px]">
            <colgroup>
              {columns.map((col) => (
                <col key={col.field} style={{ width: col.width }} />
              ))}
            </colgroup>
            <thead>
              <tr>
                {columns.map((col) => {
                  return (
                    <th className="custom-cell" key={col.field}>
                      {/* @ts-ignore */}
                      {col.renderHeader?.() || col.headerName}
                    </th>
                  );
                })}
              </tr>
              <tr>
                <th className="h-4"></th>
              </tr>
            </thead>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="droppable">
                {(drop, { isDraggingOver }) => (
                  <tbody {...drop.droppableProps} ref={drop.innerRef}>
                    {fieldArray.fields.map((field, index) => (
                      <Draggable
                        key={field.id}
                        draggableId={field.id}
                        index={index}
                      >
                        {(drag, { isDragging }) => (
                          <tr
                            ref={drag.innerRef}
                            {...drag.draggableProps}
                            className={classNames({
                              'bg-attention bg-opacity-5 is-dragged':
                                isDragging,
                            })}
                          >
                            {columns.map((col) => (
                              <td className="custom-cell" key={col.field}>
                                {col.renderCell?.({
                                  row: field,
                                  dragProvided: drag,
                                })}
                              </td>
                            ))}
                          </tr>
                        )}
                      </Draggable>
                    ))}

                    {/* extra space for drag animation */}
                    {isDraggingOver && (
                      <tr>
                        {columns.map((column) => (
                          <td className="custom-cell" key={column.field}></td>
                        ))}
                      </tr>
                    )}

                    {drop.placeholder}
                  </tbody>
                )}
              </Droppable>
            </DragDropContext>
          </table>
        </div>
      </div>
    </div>
  );
}

export default SurfacesCalculator;
