import { IconProps } from './locationIcon';
import { useThemeMode } from '../../hooks/useThemeMode';

function RedDotIcon({ color, className }: IconProps) {
  const { mode } = useThemeMode();

  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
    >
      <circle cx="6" cy="6" r="6" fill={color}></circle>
    </svg>
  );
}

export default RedDotIcon;
