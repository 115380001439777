import { IMercureMatchingTime } from '../../../../types/matching';
import { useEffect, useState } from 'react';

const ContactsMatched = ({
  remainingTime,
  count,
  id,
}: {
  id: number;
  count: number;
  remainingTime: IMercureMatchingTime | null;
}) => {
  const [results, setResults] = useState<number>(count);

  useEffect(() => {
    if (remainingTime && remainingTime.matching === id) {
      setResults(remainingTime.result);
    }
  }, [remainingTime]);

  return (
    <div className={'h-full flex items-start pt-4'}>
      {results ? <>{results}</> : <>{count}</>}
    </div>
  );
};

export default ContactsMatched;
