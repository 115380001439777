import { FC } from 'react';
import {
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';

import { stepperFormId } from '../../../../const';
import { Box, Divider, Typography } from '@mui/material';
import DateRangeField from '../../../../components/form/dateRangeField';
import InputFormField from '../../../../components/form/inputFormField';
import { useTranslation } from 'react-i18next';

type Props = {
  mainFilterState: any;
  getValues?: any;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  watch: UseFormWatch<any>;
  control: any;
  handleSubmit: any;
};

const PropertyAdvanceFilter: FC<Props> = ({ register, control }) => {
  const { t } = useTranslation('propertiesPage');
  return (
    <form id={stepperFormId}>
      <Box className="grid grid-cols-3 gap-8 mb-4">
        <Box>
          <Typography fontSize="1.3rem" marginBottom="20px">
            {' '}
            {t('price')}{' '}
          </Typography>
          <Box className="grid grid-cols-2 gap-8 mb-4">
            <InputFormField
              type="number"
              label={t('Min')}
              {...register('priceMin', {
                valueAsNumber: true,
              })}
            />
            <InputFormField
              type="number"
              label={t('Max')}
              {...register('priceMax', {
                valueAsNumber: true,
              })}
            />
          </Box>
        </Box>
        <Box>
          <Typography fontSize="1.3rem" marginBottom="20px">
            {' '}
            {t('Availability')}{' '}
          </Typography>
          <div className="mt-10">
            <DateRangeField
              nameFrom="availableFrom"
              nameTo="availableTo"
              labelFrom={t('From')}
              labelTo={t('To')}
              control={control}
            />
          </div>
        </Box>
      </Box>
      <Divider sx={{ margin: '20px 0', border: '1px solid #E6E8EC' }} />

      <Box className="grid grid-cols-3 gap-8 mb-4">
        <Box>
          <Typography fontSize="1.3rem" marginBottom="20px">
            {' '}
            {t('rooms')}{' '}
          </Typography>
          <Box className="grid grid-cols-2 gap-4 mb-4">
            <InputFormField
              type="number"
              label={t('Min')}
              {...register('minRooms', {
                valueAsNumber: true,
              })}
            />
            <InputFormField
              type="number"
              label={t('Max')}
              {...register('maxRooms', {
                valueAsNumber: true,
              })}
            />
          </Box>
        </Box>
        <Box>
          <Typography fontSize="1.3rem" marginBottom="20px">
            {' '}
            {t('Bedrooms')}{' '}
          </Typography>
          <Box className="grid grid-cols-2 gap-4 mb-4">
            <InputFormField
              type="number"
              label={t('Min')}
              {...register('minBedrooms', {
                valueAsNumber: true,
              })}
            />
            <InputFormField
              type="number"
              label={t('Max')}
              {...register('maxBedrooms', {
                valueAsNumber: true,
              })}
            />
          </Box>
        </Box>
        <Box>
          <Typography fontSize="1.3rem" marginBottom="20px">
            {' '}
            {t('Bathrooms')}{' '}
          </Typography>
          <Box className="grid grid-cols-2 gap-4 mb-4">
            <InputFormField
              type="number"
              label={t('Min')}
              {...register('minBathrooms', {
                valueAsNumber: true,
              })}
            />
            <InputFormField
              type="number"
              label={t('Max')}
              {...register('maxBathrooms', {
                valueAsNumber: true,
              })}
            />
          </Box>
        </Box>
      </Box>
      <Divider sx={{ margin: '20px 0', border: '1px solid #E6E8EC' }} />
      <Box className="grid grid-cols-3 gap-8 mb-4">
        <Box>
          <Typography fontSize="1.3rem" marginBottom="20px">
            {' '}
            {t('Land_surface')}{' '}
          </Typography>
          <Box className="grid grid-cols-2 gap-4 mb-4">
            <InputFormField
              type="number"
              label={t('Min')}
              {...register('minLandSurface', {
                valueAsNumber: true,
              })}
            />
            <InputFormField
              type="number"
              label={t('Max')}
              {...register('maxLandSurface', {
                valueAsNumber: true,
              })}
            />
          </Box>
        </Box>
        <Box>
          <Typography fontSize="1.3rem" marginBottom="20px">
            {' '}
            {t('Living_surface')}{' '}
          </Typography>
          <Box className="grid grid-cols-2 gap-4 mb-4">
            <InputFormField
              type="number"
              label={t('Min')}
              {...register('minLivingSurface', {
                valueAsNumber: true,
              })}
            />
            <InputFormField
              type="number"
              label={t('Max')}
              {...register('maxLivingSurface', {
                valueAsNumber: true,
              })}
            />
          </Box>
        </Box>
        <Box>
          <Typography fontSize="1.3rem" marginBottom="20px">
            {' '}
            {t('Parking')}{' '}
          </Typography>
          <Box className="grid grid-cols-2 gap-4 mb-4">
            <InputFormField
              type="number"
              label="parkings"
              {...register('parkings', {
                valueAsNumber: true,
              })}
            />
          </Box>
        </Box>
      </Box>
    </form>
  );
};

export default PropertyAdvanceFilter;
