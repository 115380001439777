import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useFieldArray } from 'react-hook-form';
import { Box, Button } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddressForm from '../../forms/address-form';
import useLocale from '../../../../../hooks/useLocale';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const AddressesList = (props: {
  register: any;
  control: any;
  watch: any;
  setValue: any;
  defaultValues: any;
  setFocus: any;
}) => {
  const { control, register, watch, setValue, defaultValues, setFocus } = props;
  const { locale } = useLocale();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'addresses',
  });
  const { t } = useTranslation('common');
  const addFields = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    append({
      location: null,
      latitude: null,
      longitude: null,
      zipCode: '',
      locale,
      polygon: [],
      translations: {
        [`${locale}`]: {
          locale,
          country: '',
          countryCode: '',
          city: '',
          zone: '',
          adresse: '',
          canton: '',
          district: '',
        },
      },
    });
  };

  useEffect(() => {
    if (fields.length) {
      setFocus(`addresses.${fields.length - 1}.translations.${locale}.adresse`);
    }
  }, [fields]);

  return (
    <>
      {fields.length ? (
        <>
          {fields.map((field, index) => (
            <div key={field.id} className={'pt-8 first:pt-0'}>
              <div className={'flex items-center justify-between'}>
                <p>
                  {t('Address')} #{index + 1}
                </p>
                <Button
                  variant="outlined"
                  className="flex justify-center items-center"
                  sx={{
                    height: '42px',
                    color: '#F50057',
                  }}
                  onClick={() => remove(index)}
                >
                  <Box className="flex justify-center items-center" gap="16px">
                    <DeleteOutlineIcon fontSize="small" />
                    <span className="font-medium"> {t('Remove_address')} </span>
                  </Box>
                </Button>
              </div>
              <AddressForm
                index={index}
                register={register}
                watch={watch}
                control={control}
                setValue={setValue}
                defaultValues={defaultValues}
              />
              {index + 1 === fields.length ? (
                <div className={'flex items-center justify-between pt-8'}>
                  <p>
                    {t('Address')} #{index + 2}
                  </p>
                  <Button
                    variant="outlined"
                    className="flex justify-center items-center"
                    sx={{
                      height: '42px',
                    }}
                    onClick={(e) => addFields(e)}
                  >
                    <Box
                      className="flex justify-center items-center"
                      gap="16px"
                    >
                      <AddIcon fontSize="small" />
                      <span className="font-medium">{t('Add_address')}</span>
                    </Box>
                  </Button>
                </div>
              ) : null}
            </div>
          ))}
        </>
      ) : (
        <div className={'flex items-center justify-between'}>
          <p> {t('Address')} </p>
          <Button
            variant="outlined"
            className="flex justify-center items-center"
            sx={{
              height: '42px',
            }}
            onClick={(e) => addFields(e)}
          >
            <Box className="flex justify-center items-center" gap="16px">
              <AddIcon fontSize="small" />
              <span className="font-medium">{t('Add_address')}</span>
            </Box>
          </Button>
        </div>
      )}
    </>
  );
};

export default AddressesList;
