import { useTranslation } from 'react-i18next';

const CreatedUpdatedHeader = () => {
  const { t } = useTranslation('common');

  return (
    <div className="flex flex-col font-medium text-xs pl-2">
      <span className="leading-5">{t('date_created')}</span>
      <span className="leading-5">{t('date_updated')}</span>
    </div>
  );
};

export default CreatedUpdatedHeader;
