import React, { FC } from 'react';
import { PropertyStepperStep } from '../../../../types/property';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';
import SpinnerLoading from '../../../../features/spinner/spinnerLoading';
import Comments from '../../../contacts/components/comments';

const PropertyCommentsStep: FC<PropertyStepperStep> = () => {
  const { id } = useParams();
  const { data: comments, mutate } = useSWR(`/v1/property/${id}/comments`);

  return comments && comments['hydra:member'] ? (
    <Comments
      api={`/v1/property/${id}/comment`}
      items={comments && comments['hydra:member']}
      id={id || '6'}
      mutate={mutate}
      property
    />
  ) : (
    <div className={'col-start-1 col-end-4 min-h-[500px]'}>
      <SpinnerLoading />
    </div>
  );
};

export default PropertyCommentsStep;
