import TagManager from 'react-gtm-module';

if (process.env.REACT_APP_GTM_ID) {
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GTM_ID,
  });
}

export function useGtm({
  type,
  reference,
}: {
  type: 'property' | 'promotion';
  reference?: string;
}) {
  if (process.env.REACT_APP_GTM_ID) {
    TagManager.dataLayer({
      dataLayer: {
        type,
        reference,
      },
      dataLayerName: `${type}-${reference}`,
    });
  }
}
