import { ActionData } from '../../../../../types/actionData';
import AttachEmailOutlinedIcon from '@mui/icons-material/AttachEmailOutlined';
import { useAnchorElement } from '../../../../../hooks/useAnchorElement';
import ActionMenu from '../../../../../components/common/actionMenu';
import { http } from '../../../../../libs/axios';
import { toast } from 'react-toastify';
import { mutate } from 'swr';
import LoopSharpIcon from '@mui/icons-material/LoopSharp';
import CheckIcon from '@mui/icons-material/Check';
import { useState } from 'react';
import SendFilesPopup from './sendFilesPopup';
import { ILeadItemExtended } from './index';

const LeadsListingActionMenu = ({ row }: { row: ILeadItemExtended }) => {
  const [filesPopupOpen, setFilesPopupOpen] = useState(false);
  const changeLeadStatus = () => {
    http
      .patch(`/prospects/${row.id}`, { status: '2' })
      .then(() => {
        toast.success('Lead status successfully changed.');
        mutate(`/v1/properties/${row.parentId}/prospects`);
      })
      .catch(() => {
        toast.error('There is been an server error. Lead status not changed.');
      });
  };

  const generateSearchCriteria = () => {
    if (row.contact && row.contact.id) {
      http
        .post(`/contacts/${row.contact.id}/search_criteria`, {
          property: row.parentId,
        })
        .then(() => {
          toast.success('Search criteria was successfully generated.');
        })
        .catch(() => {
          toast.error(
            'There is been an server error. Search criteria was not generated.'
          );
        });
    }
  };

  const menuActionData: ActionData[] = [
    {
      label: 'propertiesPage:Generate_Search_Criteria',
      icon: LoopSharpIcon,
      onClick: generateSearchCriteria,
    },
    {
      label: 'propertiesPage:Send_brochure',
      icon: AttachEmailOutlinedIcon,
      onClick: () => setFilesPopupOpen(true),
    },
  ];

  if (row.status.keyname === 'in progress') {
    menuActionData.push({
      label: 'propertiesPage:Mark_as_processed',
      icon: CheckIcon,
      onClick: changeLeadStatus,
    });
  }

  const anchor = useAnchorElement();

  return (
    <>
      <ActionMenu
        {...anchor}
        menuAction={menuActionData}
        transPage={'brochureEditor'}
      />
      {filesPopupOpen ? (
        <SendFilesPopup
          id={row.parentId}
          contact={row.contact}
          openModal={filesPopupOpen}
          setOpenModal={setFilesPopupOpen}
        />
      ) : null}
    </>
  );
};

export default LeadsListingActionMenu;
