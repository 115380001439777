// NotificationContext.tsx
import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
} from 'react';

interface NotificationContextProps {
  totalNotifications: number;
  setNewTotalNotifications: (newTotal: number) => void;
}

const NotificationContext = createContext<NotificationContextProps | undefined>(
  undefined
);

interface NotificationProviderProps {
  children: ReactNode;
}

export const NotificationProvider: React.FC<NotificationProviderProps> = ({
  children,
}) => {
  const [totalNotifications, setTotalNotifications] = useState(0);

  const setNewTotalNotifications = useCallback((newTotal: number) => {
    setTotalNotifications(newTotal);
  }, []);

  const contextValue: NotificationContextProps = {
    totalNotifications,
    setNewTotalNotifications,
  };

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useTotalNotification = (): NotificationContextProps => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      'useNotification must be used within a NotificationProvider'
    );
  }
  return context;
};
