import { GridValidRowModel } from '@mui/x-data-grid';
import SpinnerLoading from '../../../features/spinner/spinnerLoading';
import { Box } from '@mui/material';
import InfoCard from './infoCard';

type PropsT = { data: GridValidRowModel };

function UserInfos({ data }: PropsT) {
  const { roles } = data;

  const handledisplayRightData = (roles: string) => {
    if (roles.length === 0) {
      return ['Role'];
    }
    if (roles.includes('ROLE_ASSISTANT')) {
      return ['Role', 'Related users'];
    } else {
      return ['Role', 'Belong to', 'Level', 'Related assistant'];
    }
  };

  const leftDataToDisplay = ['firstname', 'lastname', 'email', 'phone'];

  const rightDataToDisplay = handledisplayRightData(roles);

  if (!data) return <SpinnerLoading />;

  return (
    <Box
      component="section"
      className="w-full grid grid-cols-1 gap-6 md:grid-cols-2 "
    >
      <InfoCard cardKeys={leftDataToDisplay} data={data} />

      <InfoCard cardKeys={rightDataToDisplay} data={data} />
    </Box>
  );
}

export default UserInfos;
