import { useThemeMode } from '../../hooks/useThemeMode';

export type IconProps = {
  color?: string;
  className?: string;
  width?: string;
  height?: string;
};
export default function LocationIcon({ color, className }: IconProps) {
  const { mode } = useThemeMode();

  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <circle cx="8" cy="7" r="2.5" stroke={color}></circle>
      <path
        stroke={color}
        d="M3.394 10.95c-2.525-2.39-2.525-6.255 0-8.646 2.54-2.405 6.671-2.405 9.212 0 2.525 2.391 2.525 6.256 0 8.647L8 15.31l-4.606-4.36z"
      ></path>
    </svg>
  );
}
