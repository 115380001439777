import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { cloneElement } from 'react';
import { Box } from '@mui/material';

export default function SocialMedias() {
  const socials = [
    <FacebookIcon key={1} />,
    <InstagramIcon key={2} />,
    <LinkedInIcon key={3} />,
    <YouTubeIcon key={4} />,
    <TwitterIcon key={5} />,
  ];

  return (
    <Box component="ul" className="flex gap-x-4">
      {socials.map((social) => (
        <Box component="li" key={social.key}>
          {cloneElement(social, { fontSize: 'medium' })}
        </Box>
      ))}
    </Box>
  );
}
