import { PropertyListingItem } from '../../types/property';
import PropertyAvatar from './propertyAvatar';

const PropertyMainImage = ({
  row,
  fullScalePicture = false,
  width,
  height,
}: {
  row: PropertyListingItem;
  fullScalePicture?: boolean;
  width?: string;
  height?: string;
}) => {
  return (
    <PropertyAvatar
      row={row}
      defaultImage={row?.mainImage?.thumbnail}
      fullScalePicture={fullScalePicture}
      width={width}
      height={height}
    />
  );
};

export default PropertyMainImage;
