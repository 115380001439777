import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import useLocale, { Locale } from '../../hooks/useLocale';
import { useState } from 'react';

type Props = {
  currentLocale?: Locale;
  setLanguage?: (key: Locale) => void;
  queryLang?: string | null;
  setPrevLanguage?: (key: Locale) => void;
  changeAppLocale?: boolean;
  className?: string;
  inputDefaultValue?: (text: string, select: boolean) => void;
  portalLanguage?: boolean;
};

const LangSwitcher = ({
  setLanguage,
  queryLang,
  setPrevLanguage,
  currentLocale = 'en',
  changeAppLocale = true,
  className = '',
  inputDefaultValue,
  portalLanguage,
}: Props) => {
  const { locale, update, langues } = useLocale();
  const [editLanguage, setEditLanguage] = useState(queryLang);
  const { t } = useTranslation('common');

  const handleChange = async (e: SelectChangeEvent<Locale>) => {
    const newLocale = e.target.value as Locale;

    if (changeAppLocale) {
      update(newLocale);
    } else {
      setLanguage?.(newLocale);
    }

    setPrevLanguage?.(currentLocale);
    if (typeof inputDefaultValue === 'function') {
      inputDefaultValue('', true);
    }
  };

  return (
    <Box>
      <FormControl fullWidth className={className} sx={{ m: 1, minWidth: 120 }}>
        {portalLanguage ? (
          <>
            {queryLang == null && (
              <InputLabel className="mt-[-7px]" id="languageSwitcher">
                {t('propertiesPage:choose')}
              </InputLabel>
            )}

            <Select
              label={queryLang == null ? 'languageSwitcher' : ''}
              value={editLanguage != null ? editLanguage : undefined}
              onChange={(e: any) => {
                handleChange(e);
                setEditLanguage(e.target.value);
              }}
              className="!text-xs !text-[#1D1D1F] !m-0 !p-0 "
            >
              {langues.map((langue, i) => (
                <MenuItem
                  value={Object.keys(langue).join()}
                  key={i}
                  onClick={() => inputDefaultValue?.('', true)}
                >
                  {t(Object.values(langue).join())}
                </MenuItem>
              ))}
            </Select>
          </>
        ) : (
          <Select
            value={changeAppLocale === true ? locale : currentLocale}
            onChange={handleChange}
            className="!text-xs !text-[#1D1D1F] !m-0 !p-0 "
          >
            {langues.map((langue, i) => (
              <MenuItem
                value={Object.keys(langue).join()}
                key={i}
                onClick={() => inputDefaultValue?.('', true)}
              >
                {t(Object.values(langue).join())}
              </MenuItem>
            ))}
          </Select>
        )}
      </FormControl>
    </Box>
  );
};
export default LangSwitcher;
