import { forwardRef, useState, useEffect } from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Select,
  SelectProps,
} from '@mui/material';
import { optionType } from '../../const/propertiesOptions';
import InputLayout from './components/inputLayout';
import { UseFormSetValue } from 'react-hook-form';
import { uniq } from 'lodash';
import { FileNode } from '../../types/hierarchy/legacy';

export type FilterAgencyMultiselectProps = Omit<SelectProps, 'variant'> & {
  selectedServiceOption?: optionType;
  node: FileNode;
  isRequired?: boolean;
  label: string;
  isMultiple?: boolean;
  name: string;
  defaultValue?: any;
  setValue: UseFormSetValue<any>;
  className?: string;
  value: string[] | [];
  isDisabled?: boolean;
  variant?: SelectProps;
};

const extractedIdsfromArray = (checkedNodes: string[]) => {
  return uniq(
    checkedNodes.map((item) => {
      const match = /-(\d+)$/.exec(item);
      return match ? match[1] : null;
    })
  );
};

const FilterAgencyMultiselect = forwardRef<
  HTMLSelectElement,
  FilterAgencyMultiselectProps
>(
  (
    {
      isRequired = false,
      className,
      setValue,
      defaultValue = [],
      value,
      name = 'departments',
      isMultiple = true,
      node,
      label,
      isDisabled = false,
      sx,
    },
    ref
  ) => {
    const mapIdsToDepartmentNames: Record<string, string> = {};
    const [checkedNodes, setCheckedNodes] = useState<string[]>([]);
    useEffect(() => {
      if (defaultValue.length) {
        setValue(name, defaultValue);
      }
    }, []);
    const handleNodeToggle = (togglednode: any) => {
      const { id: nodeId, name, categoryEntity } = togglednode;
      const isSelected = checkedNodes.includes(
        `${categoryEntity}${name}-${nodeId}`
      );
      let checkedItems: string[] = [];

      if (isSelected) {
        const deselectItems = deselectDescendants(togglednode);
        // find all parent node of the checked element and remove them from checknode state
        const getAllParents = findParentNodes(
          `${categoryEntity}${name}-${nodeId}`,
          node
        ).map(
          ({ id, name, categoryEntity }: any) =>
            `${categoryEntity}${name}-${id}`
        );

        checkedItems = deselectItems.filter(
          (item) => !getAllParents.includes(item)
        );
      } else {
        checkedItems = selectDescendants(togglednode);
        // newCheckedNodes.push(String(nodeId));
      }

      // update value of react hook form
      checkedNodesToDepatValues(checkedItems);
      setCheckedNodes([...checkedItems]);
    };

    const findNodesByDepartmentIds = (departmentIds: string[], data: any) => {
      const result: FileNode[] = [];

      const findNode = (id: unknown, node: FileNode) => {
        if (node?.categoryEntity === 'Department' && node?.id == id) {
          result.push(node);
        } else if (node.children) {
          for (const childNode of node.children) {
            findNode(id, childNode);
          }
        }
      };

      for (const id of departmentIds) {
        findNode(id, data);
      }

      return result;
    };

    useEffect(() => {
      if (value && value.length === 0) {
        setCheckedNodes([]);
      } else {
        const result = findNodesByDepartmentIds(value, node);
        const updatecheckedNodeToStateFormat = result.map(
          ({ id, name, categoryEntity }: any) =>
            `${categoryEntity}${name}-${id}`
        );
        setCheckedNodes(
          uniq([...checkedNodes, ...updatecheckedNodeToStateFormat])
        );
      }
    }, [value]);

    const checkedNodesToDepatValues = (itemTocheck: string[]) => {
      if (mapIdsToDepartmentNames) {
        // extract only ids from itemTocheck CATEGORYname-id
        const extractedIds = extractedIdsfromArray(itemTocheck);

        // extract only the ids of departement from all ids selected from the extractedIds
        const matchIdsWithDepartement = extractedIds.filter((id) => {
          const nameInMap = mapIdsToDepartmentNames[id!];
          const matchingTabItem = itemTocheck
            .filter((elm) => elm.includes('Department'))
            .find((tabItem) => tabItem.includes(`-${id}`));

          const nameInTab = matchingTabItem
            ? matchingTabItem.split('-').slice(0, -1).join('-')
            : null;

          return (
            id !== null && nameInMap && nameInTab && nameInMap === nameInTab
          );
        });
        setValue(name, matchIdsWithDepartement);
      }
    };

    const selectDescendants = (parentNode: any) => {
      const { id: nodeId, name, categoryEntity } = parentNode;
      const descendants = [
        `${categoryEntity}${name}-${nodeId}`,
        ...getDescendantIds(parentNode),
      ];
      return [...checkedNodes, ...descendants];
    };

    const deselectDescendants = (parentNode: any) => {
      const { id: nodeId, name, categoryEntity } = parentNode;
      const descendants = [
        `${categoryEntity}${name}-${nodeId}`,
        ...getDescendantIds(parentNode),
      ];
      return checkedNodes.filter((elm) => !descendants.includes(elm));
    };

    const getDescendantIds = (node: any): string[] => {
      let descendants: string[] = [];
      if (node?.children) {
        for (const child of node.children) {
          descendants.push(`${child.categoryEntity}${child.name}-${child.id}`);
          descendants = [...descendants, ...getDescendantIds(child)];
        }
      }
      return descendants;
    };

    //find node
    const findParentNodes = (
      basedNode: string,
      data: FileNode,
      parentNodes = [] as FileNode[]
    ) => {
      // i should test if the id is descendant of the data
      const { categoryEntity, name, id } = data;
      if (!data || typeof data !== 'object') {
        return parentNodes;
      }

      if (`${categoryEntity}${name}-${id}` === basedNode) {
        return parentNodes;
      }

      if (data.children && data.children.length > 0) {
        for (const child of data.children) {
          const updatedParentNodes = [...parentNodes, data];
          const result: FileNode[] = findParentNodes(
            basedNode,
            child,
            updatedParentNodes
          );
          if (result.length > 0) {
            return result;
          }
        }
      }

      return [];
    };

    const renderTree = (node: any) => {
      if (!node) return <></>;
      const { categoryEntity, name, id, children } = node;
      if (
        children &&
        children.every((subArray: unknown[]) => subArray.length === 0)
      ) {
        return null;
      }
      if (categoryEntity === 'Department') {
        mapIdsToDepartmentNames[id] = `${categoryEntity}${name}`;
      }

      return (
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                className="!ml-3"
                checked={checkedNodes.includes(
                  `${categoryEntity}${name}-${id}`
                )}
                onChange={() => handleNodeToggle(node)}
              />
            }
            label={node?.name}
          />
          {children?.map((child: any) => (
            <div key={child.id} style={{ marginLeft: 20 }}>
              {Array.isArray(child) && child.length === 0 ? (
                <></>
              ) : (
                renderTree(child)
              )}
            </div>
          ))}
        </FormGroup>
      );
    };

    // if (!node) return null;
    return (
      <InputLayout label={label} className="relative">
        <Select
          required={isRequired}
          multiple={isMultiple}
          fullWidth
          ref={ref}
          MenuProps={{
            elevation: 0,
            classes: {
              root: 'max-h-[calc(100vh-400px)]',
              paper: 'border border-gray-200',
            },
          }}
          className={className}
          sx={sx}
          value={value}
          disabled={isDisabled}
          renderValue={(selected) => {
            return Array.isArray(selected)
              ? selected
                  .map((id) =>
                    mapIdsToDepartmentNames[id!]?.replace('Department', '')
                  )
                  .filter(Boolean)
                  .join(', ')
              : '';
          }}
        >
          {renderTree(node)}
        </Select>
        {isDisabled && (
          <FormHelperText className="!text-[rgb(79,102,235)] absolute -bottom-6">
            * PLease choose broker first
          </FormHelperText>
        )}
      </InputLayout>
    );
  }
);

FilterAgencyMultiselect.displayName = 'AgencyMultiSelect';

export default FilterAgencyMultiselect;
