import SpinnerLoading from '../../../../features/spinner/spinnerLoading';
import { ContactTapInnerProps } from './search-criteria-tab';
import Comments from '../../components/comments';
import useSWR from 'swr';

const CommentsTab = (props: ContactTapInnerProps) => {
  const { data, id } = props;
  const { data: comments, mutate } = useSWR(`/contact/${id}/comments`);

  return data && comments && comments['hydra:member'] ? (
    <Comments
      items={comments && comments['hydra:member']}
      id={id}
      mutate={mutate}
    />
  ) : (
    <div className={'col-start-1 col-end-4 min-h-[500px]'}>
      <SpinnerLoading />
    </div>
  );
};

export default CommentsTab;
