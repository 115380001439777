export const HistoryTableGridXStyling = {
  color: 'text.primary',
  width: '100%',
  height: '100%',
  '& .MuiDataGrid-main  ': {
    position: 'relative',
    border: ' 1px solid #E6E8EC !important',
  },
  '& .MuiDataGrid-root': {
    position: 'relative',
    border: 'none !important',
  },
  '& .MuiDataGrid-footerContainer:first-child': {
    marginTop: '29px',
    display: 'flex',
    flexDirection: 'row-reverse',
    border: 'none',
  },
  '& .MuiTablePagination-actions': {
    padding: '0px !important',
  },
  '& .MuiTablePagination-toolbar': {
    // display: 'none !important',
    display: 'flex',
    flexDirection: 'row !important',
    padding: '0px !important',
  },
  '& .MuiDataGrid-columnHeader': {
    maxWidth: '100% !important',
    paddingLeft: '18px',
    height: '48px !important',
  },
  '& .MuiDataGrid-columnHeader  .MuiDataGrid-menuIcon ': {
    width: 'auto ',
    visibility: 'visible !important',
  },

  //   '& .MuiDataGrid-virtualScroller': {
  //     backgroundColor: 'background.paper',
  //     marginTop: '48px !important',
  //     marginLeft: '1px',
  //   },

  '& .MuiDataGrid-columnHeader ': {
    backgroundColor: 'background.paper',
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  //   '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell:first-of-type': {
  //     borderRight: `1px solid #E6E8EC`,
  //   },
  //   '& .MuiDataGrid-renderingZone': {
  //     maxHeight: 'none !important',
  //     maxWidth: 'none !important',
  //   },
  //   '& .MuiDataGrid-cell': {
  //     lineHeight: 'unset !important',
  //     maxHeight: 'none !important',
  //     whiteSpace: 'normal',
  //   },
  //   '& .MuiDataGrid-row': {
  //     maxHeight: 'none !important',
  //   },
  //   '& .MuiDataGrid-row.super-app-theme--inner': {
  //     backgroundColor: 'background.default',
  //     maxHeight: '45px !important',
  //   },
  //   '& .MuiDataGrid-toolbarContainer': {
  //     marginBottom: '10px',
  //     display: 'flex',
  //     justifyContent: 'flex-end',
  //   },
  //   '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
  //     color: 'text.primary',
  //   },
  // '& .MuiDataGrid-root.only-vertical-scroll .MuiDataGrid-virtualScroller': {
  //   overflowY: 'auto',
  //   overflowX: 'hidden',
  // }
};
