import { CSSProperties } from 'react';

import { formatPrice } from '../../../../../helpers/utils';
import { useIsRent } from '../../../../../hooks/useIsRent';
import { PropertyWidgetPreviewProps } from '../../../../../types/brochureEditor';
import { useAlignmentStyles } from '../../../lib/hooks';
import ImageOrIcon from '../../imageOrIcon';

const ImageWithPriceWidget = ({
  property,
  widgetOptions,
}: PropertyWidgetPreviewProps) => {
  const isRent = useIsRent(property);

  const textAlign = String(widgetOptions?.textAlign?.value);
  const color = String(widgetOptions?.color?.value);
  const textStyle = { textAlign, color } as CSSProperties;

  const gridStyle = useAlignmentStyles(widgetOptions);

  let imageSrc: string | null = null;
  const imageId = widgetOptions?.image?.value;
  if (imageId) {
    const mediaObj = Array.isArray(property.media)
      ? { images: [] }
      : property.media;
    const media = mediaObj.images.find(({ id }) => String(id) === imageId);
    if (media) {
      imageSrc = media.url.large;
    }
  }
  const objectFit =
    (widgetOptions?.objectFit?.value as string | undefined) || 'cover';

  const image = <ImageOrIcon imageSrc={imageSrc} objectFit={objectFit} />;
  const source =
    isRent === null
      ? null
      : isRent
      ? property.pricingRent
      : property.pricingBuy;

  return (
    <div className="flex flex-col gap-4">
      <div className="relative grow">{image}</div>
      {source && (
        <div className="grid" style={textStyle}>
          <div className="grid gap-4" style={gridStyle}>
            <div className="text-4xl font-bold">
              {formatPrice(source?.displayedPrice) + '.-'}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageWithPriceWidget;
