import { GridColumns } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { Box, Rating, Tooltip, Skeleton } from '@mui/material';
import useLocale from '../../../../hooks/useLocale';
import PropertyMainImage from '../../../../components/tableGridX/propertyMainImage';
import PropertiesReferenceModal from '../propertiesReferenceModal';
import {
  renderFullPropertyAdress,
  renderPropertyAdress,
} from '../../../../helpers/utils';
import PropertyPrice from '../../../../components/tableGridX/propertyPrice';
import HomeIcon from '../../../../features/icons/homeIcon';
import BedroomIcon from '../../../../features/icons/bedroomIcon';
import SquareIcon from '../../../../features/icons/squareIcon';
import MountainIcon from '../../../../features/icons/mountainIcon';
import PropertyContactOptimized from '../../../../components/tableGridX/propertyContactOptimized';
import Star2Icon from '../../../../features/icons/star2Icon';
import CreatedUpdatedHeader from '../../../../components/tableGridX/createdUpdatedHeader';
import CreatedUpdated from '../../../../components/tableGridX/createdUpdated';
import PropertiesListingActionMenu from '../../../../components/tableGridX/propertiesListingActionMenu';
import { IMercureMatchingTime } from '../../../../types/matching';
import { MatchingIndicator } from '../../../../components/cardsTablesList/cards/elements/matchingIndicator';

export default function usePropertiesColumns({
  isLoading,
  isActiveProperties,
  remainingTime,
}: {
  isLoading: boolean;
  isActiveProperties?: boolean;
  remainingTime: IMercureMatchingTime | null;
}) {
  const { t } = useTranslation('propertiesPage');
  const { locale } = useLocale();

  const columns: GridColumns = [
    {
      field: 'media',
      width: 150,
      renderCell: ({ row }) => (
        <div className="relative">
          <PropertyMainImage width={'120px'} height={'83px'} row={row} />
          <MatchingIndicator
            matchingRemainingTime={remainingTime as IMercureMatchingTime | null}
            item={row}
            small
          />
        </div>
      ),
    },
    {
      field: 'reference',
      minWidth: 300,
      flex: 1,
      renderHeader: () => (
        <Box className="leading-5 flex flex-col Inter font-medium text-sm pl-2 py-5 opacity-80 text-[#030814]">
          <span>{t('common:Property_title')}</span>
          <div>
            <span>{t('common:Reference')}, </span>
            <span>{t('common:Status')}</span>
          </div>
        </Box>
      ),
      renderCell: ({ row }) => <PropertiesReferenceModal row={row} />,
    },
    {
      field: 'category',
      minWidth: 200,
      flex: 1,
      renderHeader: () => (
        <span className="leading-5 flex flex-col Inter font-medium pl-3 text-sm opacity-80 text-[#030814]">
          <span>{t('common:Category')}</span>
          <span>{t('common:Location')}</span>
        </span>
      ),
      renderCell: ({ row }) => (
        <Box className="flex flex-col gap-2 w-full pl-3">
          <div>
            {row.category && (
              <span className="leading-5 capitalize bg-gray-100 rounded py-1 px-2">
                {`${row.category?.translations[locale]?.value} - ${row.type?.translations[locale]?.value}`}
              </span>
            )}
          </div>
          <Tooltip title={renderFullPropertyAdress(row)} arrow placement="top">
            {renderPropertyAdress(row.address, true)}
          </Tooltip>
        </Box>
      ),
    },
    {
      field: 'displayedPrice',
      headerName: t('common:Price'),
      width: 130,
      headerClassName: '!pl-4',
      renderCell: ({ row }) => <PropertyPrice row={row} />,
    },
    {
      field: 'rooms',
      headerName: t('common:Dimensions'),
      width: 130,
      headerClassName: '!pl-4',
      renderCell: ({ row }) => (
        <Box className="flex justify-between px-4 gap-2 w-full">
          <Box className="flex flex-col gap-2">
            <Tooltip title={t('common:Rooms')} arrow placement="top">
              <Box className="flex" sx={{ lineHeight: '20px' }}>
                <HomeIcon className="mr-2 col-span-1" color="#6E6E72" />{' '}
                <span>{row.rooms}</span>
              </Box>
            </Tooltip>
            <Tooltip title={t('common:Bedrooms')} arrow placement="top">
              <Box className="flex" sx={{ lineHeight: '20px' }}>
                <BedroomIcon className="mr-2 col-span-1" color="#6E6E72" />{' '}
                <span>{row.bedrooms}</span>
              </Box>
            </Tooltip>
          </Box>
          <Box className="flex flex-col gap-2">
            <Tooltip title={t('common:Living_area')} arrow placement="top">
              <Box className="flex" sx={{ lineHeight: '20px' }}>
                <SquareIcon className="mr-2 col-span-1" color="#6E6E72" />{' '}
                <span>{row.livingArea}</span>
              </Box>
            </Tooltip>
            <Tooltip title={t('common:Land_surface')} arrow placement="top">
              <Box className="flex" sx={{ lineHeight: '20px' }}>
                <MountainIcon className="mr-2 col-span-1" color="#6E6E72" />{' '}
                <span>{row.landSurface}</span>
              </Box>
            </Tooltip>
          </Box>
        </Box>
      ),
    },
    {
      field: 'mainBroker',
      headerName: t('common:Contacts'),
      width: 130,
      renderCell: ({ row }) => <PropertyContactOptimized row={row} />,
    },
    {
      field: 'rating',
      headerName: t('common:Ranking'),
      width: 130,
      renderCell: ({ row }) => (
        <div className="flex gap-2 pl-3">
          <Rating
            name="rating"
            value={row.rating}
            max={3}
            readOnly
            icon={<Star2Icon className="mr-1" color="#FFC839" />}
            emptyIcon={<Star2Icon className="mr-1" color="#E6E8EC" />}
          />
        </div>
      ),
    },
    {
      field: 'createdAt',
      headerName: t('common:Date'),
      width: 200,
      renderHeader: () => <CreatedUpdatedHeader />,
      renderCell: ({ row }) => (
        <CreatedUpdated created={row.createdAt} updated={row.updatedAt} />
      ),
    },
    {
      field: '',
      headerName: t('common:Actions'),
      width: 100,
      headerClassName: '!bg-white !border !border-x-0',
      renderCell: ({ row }) => (
        <PropertiesListingActionMenu
          isActiveProperties={isActiveProperties}
          row={row}
        />
      ),
    },
  ];

  const commonSettings = {
    sortable: false,
    disableColumnMenu: true,
  };

  return columns.map((column) => ({
    ...commonSettings,
    ...column,
    ...(isLoading
      ? {
          renderCell: () => (
            <Skeleton
              variant="text"
              animation="wave"
              width="100%"
              height={20}
            />
          ),
        }
      : {}),
  }));
}
