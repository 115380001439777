type ErrorType = 'size' | 'type';

class InvalidImageException extends Error {
  type: ErrorType;

  constructor(type: ErrorType) {
    super();

    this.type = type;
  }
}

export default InvalidImageException;
