import useSWR from 'swr';
import { useNavigate, useParams } from 'react-router-dom';
import SpinnerLoading from '../../../../features/spinner/spinnerLoading';
import { HttpError } from '../../../../libs/axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import PortalInformationTab from './tabs/portal-information';
import { IPortalSetting } from '../../../../types/portals';
import IDXFileTab from './tabs/idx-file';
import HistoryTab from './tabs/history-tab';
import TabbedPortalInformation, { PortalTabProps } from './tabs';

const mapTabNamesToComponents = {
  'Portal Information': PortalInformationTab,
  'IDX file': IDXFileTab,
  History: HistoryTab,
} as Record<string, (props: PortalTabProps) => JSX.Element>;

function SingleContactPage() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation('alerts');
  const { data, error, mutate } = useSWR<IPortalSetting, HttpError>(
    `/setting_portals/${userId}`
  );

  useEffect(() => {
    if (error) {
      toast.error(t('user_not_found') as string);
      navigate('/contacts');
    }
  }, [error, navigate, t]);

  if (!data) return <SpinnerLoading />;

  return (
    <TabbedPortalInformation
      id={userId}
      data={data}
      mapTabNamesToComponents={mapTabNamesToComponents}
      mutate={mutate}
    />
  );
}

export default SingleContactPage;
