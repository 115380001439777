import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import { useFieldArray } from 'react-hook-form';
import InputLayout from '../../../components/form/components/inputLayout';

const MultiplePhoneNumbers = (props: {
  name: string;
  register: any;
  control: any;
}) => {
  const { name, register, control } = props;
  const { fields, append, remove } = useFieldArray({
    control,
    name: name,
    // rules: {
    //
    // }
  });

  const addField = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    append({ name: 'phone' });
  };

  return (
    <div className={'flex flex-col gap-4'}>
      <InputLayout label={'Phone'}>
        {fields.map((field, index) => (
          <div key={field.id} className={'relative w-full group'}>
            <TextField
              className={'w-full'}
              variant="outlined"
              key={field.id}
              {...register(`${name}.${index}.value`)}
            />
            {index !== 0 ? (
              <button
                onClick={() => remove(index)}
                className={
                  'absolute right-2 inset-y-0 opacity-0 transition-opacity duration-300 group-hover:opacity-60 hover:opacity-70'
                }
              >
                <CloseIcon />
              </button>
            ) : null}
          </div>
        ))}
      </InputLayout>
      <button
        onClick={(e) => addField(e)}
        className={
          'hover:opacity-90 transition-opacity duration-300 rounded bg-gray-100 flex items-center gap-4 justify-center p-[12px] text-gray-900'
        }
      >
        <AddIcon />
        <span className={'text-[14px]'}>Add phone number</span>
      </button>
    </div>
  );
};

export default MultiplePhoneNumbers;
