import { useCallback, useState } from 'react';
import useDebounce from 'react-use/lib/useDebounce';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { NameableProperty } from '../types/property';
import { ApiListing } from '../types/api';
import useLocale from './useLocale';
import usePropertyName from './usePropertyName';
import { http } from '../libs/axios';

interface Option {
  label: string;
  value: string;
  id: string;
}

function usePropertiesAutocomplete({
  urlTemplate = '/v1/properties/elastic/magic_field?page=1&itemsPerPage=100&searchQuery={searchQuery}&lang={locale}',
  selectedProperty,
  preventEmptyQuery = true,
}: {
  urlTemplate?: string;
  selectedProperty?: NameableProperty | null;
  preventEmptyQuery?: boolean;
}) {
  const { locale } = useLocale();
  const [searchQuery, setSearchQuery] = useState('');
  const { getPropertyName } = usePropertyName();

  let defaultValue: Option | undefined;
  if (selectedProperty) {
    defaultValue = {
      label: getPropertyName(selectedProperty),
      value: `${selectedProperty.id}`,
      id: `${selectedProperty.id}`,
    };
  }

  const [options, setOptions] = useState<Option[] | null>(
    defaultValue ? [defaultValue] : null
  );
  const { t } = useTranslation('alerts');

  const searchProperty = useCallback(async () => {
    const query = encodeURIComponent(searchQuery);

    if (preventEmptyQuery && !query) return;

    const url = urlTemplate
      .replace(`{searchQuery}`, query)
      .replace(`{locale}`, locale);

    setOptions(null);
    try {
      const { data } = await http.get<ApiListing<NameableProperty>>(url);
      const properties = data['hydra:member'];
      const newOptions = properties.map((property) => ({
        label: getPropertyName(property),
        value: `${property.id}`,
        id: `${property.id}`,
      }));
      const optionsWithUniqueLabels = addNumbersToRepeatedLabels(newOptions);
      setOptions(optionsWithUniqueLabels);
    } catch (error) {
      toast.error(t('unknown_error') as string);
    }
  }, [searchQuery, locale, getPropertyName, urlTemplate, preventEmptyQuery]);

  useDebounce(searchProperty, 200, [searchProperty]);

  return {
    options,
    searchQuery,
    setSearchQuery,
    defaultValue,
  };
}

export default usePropertiesAutocomplete;

function addNumbersToRepeatedLabels(options: Option[]) {
  const uniqueLabels: string[] = [];
  for (const option of options) {
    if (uniqueLabels.includes(option.label)) {
      option.label += ` (#${option.value})`;
    } else {
      uniqueLabels.push(option.label);
    }
  }
  return options;
}
