import SpinnerLoading from '../../features/spinner/spinnerLoading';
import Input from '@mui/material/Input';
import { TextareaAutosize } from '@mui/base';
import { MenuItem, Select, Tooltip } from '@mui/material';
import React, { ReactElement, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Controller } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { ISettingEmails } from '../../types/settings';
import useLocale from '../../hooks/useLocale';
import InputLayout from '../form/components/inputLayout';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  children: ReactElement;
  onSubmit: (data: any) => void;
  formId: string;
  formRef: any;
  isLoading: boolean;
  control: any;
  register: any;
  usersList: string;
  allNames?: string;
  setValue: any;
}

const languages: {
  [key: string]: string;
} = {
  en: 'English',
  fr: 'French',
  de: 'German',
};

const TemplateLanguageSelect = (props: {
  selectedTemplate: number | null;
  templates?: ISettingEmails[];
  selectedTemplateLang: string | null;
  setSelectedTemplateLang: (value: string | null) => void;
}) => {
  const {
    selectedTemplate,
    templates,
    selectedTemplateLang,
    setSelectedTemplateLang,
  } = props;
  const { t } = useTranslation('propertiesPage');

  if (!selectedTemplate || !templates) {
    return null;
  }

  const template = templates.find((el) => el.id === selectedTemplate);
  const options = template
    ? Object.keys(template.translations).map(
        (key) => template.translations[key]
      )
    : [];

  return (
    <InputLayout label={t('Select_template_language')}>
      <Select
        className={classNames('h-12 w-[150px]')}
        value={selectedTemplateLang}
        onChange={(event) => setSelectedTemplateLang(event.target.value)}
      >
        {options.map((item, index: number) => (
          <MenuItem
            id={`${item?.locale}`}
            key={index}
            value={item?.locale}
            className="capitalize"
          >
            {item ? t(`common:${languages[item.locale]}`) : ''}
          </MenuItem>
        ))}
      </Select>
    </InputLayout>
  );
};

const SendEmailReusableForm = (props: Props) => {
  const {
    children,
    onSubmit,
    formId,
    formRef,
    isLoading,
    control,
    register,
    usersList,
    allNames,
    setValue,
  } = props;
  const [selectedTemplate, setSelectedTemplate] = useState<number | null>(null);
  const [selectedTemplateLang, setSelectedTemplateLang] = useState<
    string | null
  >(null);
  const { data: templates } = useSWR<ISettingEmails[]>(
    '/email_templates?isActive=true'
  );
  const { t } = useTranslation('propertiesPage');
  const { locale } = useLocale();

  useEffect(() => {
    setSelectedTemplateLang(null);
  }, [selectedTemplate]);

  useEffect(() => {
    if (selectedTemplate && selectedTemplateLang) {
      const template = templates?.find((el) => el.id === selectedTemplate);

      if (template) {
        setValue(
          'subject',
          template.translations?.[selectedTemplateLang]?.subject || ''
        );
        setValue(
          'text',
          template.translations?.[selectedTemplateLang]?.text1 || ''
        );
        setValue(
          'secondText',
          template.translations?.[selectedTemplateLang]?.text2 || ''
        );
      }
    } else {
      setValue('subject', '');
      setValue('text', '');
      setValue('secondText', '');
    }
  }, [selectedTemplate, selectedTemplateLang]);

  return (
    <form
      ref={formRef}
      className={classNames('relative flex-grow flex flex-col')}
      onSubmit={onSubmit}
      id={formId}
    >
      {isLoading && (
        <div className={'absolute inset-0 z-10'}>
          <SpinnerLoading />
        </div>
      )}

      <div className={'flex flex-col'}>
        <div
          className={classNames(
            'self-stretch px-8 py-2 border-b border-gray-200 justify-start items-start flex-col gap-2 inline-flex'
          )}
        >
          {templates && templates.length ? (
            <div className={'flex gap-4 items-end w-full'}>
              <div className={'w-1/5 min-w-[10rem]'}>
                <InputLayout label={t('Select_email_template')}>
                  <Select
                    IconComponent={
                      selectedTemplate
                        ? () => (
                            <button
                              className={'mr-[12px]'}
                              onClick={() => setSelectedTemplate(null)}
                            >
                              <CloseIcon
                                sx={{
                                  width: '14px',
                                  height: '14px',
                                }}
                              />
                            </button>
                          )
                        : undefined
                    }
                    className={classNames('h-12 w-full')}
                    value={selectedTemplate}
                    onChange={(event) =>
                      setSelectedTemplate(event.target.value as number)
                    }
                  >
                    {templates?.map((item, index: number) => (
                      <MenuItem
                        id={`${item.id}`}
                        key={index}
                        value={item?.id}
                        className="capitalize"
                      >
                        {item.translations?.[locale]?.title}
                      </MenuItem>
                    ))}
                  </Select>
                </InputLayout>
              </div>
              <TemplateLanguageSelect
                selectedTemplate={selectedTemplate}
                templates={templates}
                selectedTemplateLang={selectedTemplateLang}
                setSelectedTemplateLang={setSelectedTemplateLang}
              />
            </div>
          ) : null}
          <Controller
            control={control}
            name={'greeting'}
            render={({ field }) => (
              <Tooltip
                className={'text-center'}
                title={t(
                  'When_activated_each_of_your_emails_text_will_start_with_greeting_saved_for_each_contact'
                )}
                arrow
                placement="top-start"
              >
                <label
                  className={classNames(
                    'flex items-center ml-[-16px] cursor-pointer'
                  )}
                >
                  <Checkbox
                    className="private_checkbox"
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    defaultChecked={!!field.value}
                  />
                  <span className={'text-[14px]'}>
                    {t('Activate_Greetings')}
                  </span>
                </label>
              </Tooltip>
            )}
          />
        </div>
        <div className="self-stretch p-8 border-b border-gray-200 justify-start items-start gap-2 inline-flex">
          <div className="w-40 text-zinc-900 text-base font-normal font-['Inter'] leading-tight">
            {t('To')}
          </div>
          <div className="grow shrink basis-0 text-zinc-900 text-base font-normal font-['Inter'] leading-tight">
            <div>
              {usersList ? (
                <p className={''} title={allNames}>
                  {usersList}
                </p>
              ) : (
                <div className="flex gap-0.5 justify-start items-center">
                  <div className="h-1 w-1 bg-blue rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                  <div className="h-1 w-1 bg-blue rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                  <div className="h-1 w-1 bg-blue rounded-full animate-bounce"></div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="self-stretch p-8 border-b border-gray-200 justify-start items-start gap-2 inline-flex">
          <div className="w-40 text-zinc-900 text-base font-normal font-['Inter'] leading-tight">
            {t('Subject')}
          </div>
          <div className="grow shrink basis-0 text-zinc-900 text-base font-normal font-['Inter'] leading-tight">
            <Input
              {...register('subject')}
              sx={{
                '&': {
                  width: '100%',
                },
                '&:after, &:before': {
                  display: 'none',
                },
              }}
              placeholder={t('Click_to_add_subject')}
            />
          </div>
        </div>
        <div className="self-stretch p-8 border-b border-gray-200 justify-start items-start gap-2 inline-flex">
          <div className="w-40 text-zinc-900 text-base font-normal font-['Inter'] leading-tight">
            {t('Text')}
          </div>
          <div className="grow shrink basis-0 text-zinc-900 text-base font-normal font-['Inter'] leading-tight">
            <TextareaAutosize
              {...register('text')}
              placeholder={t('Click_to_add_text')}
              minRows={4}
              maxRows={6}
              className={'outline-0 w-full'}
            />
          </div>
        </div>
        <div className={'px-8 flex flex-col divide-y'}>{children}</div>
        <div className="self-stretch p-8 border-b border-gray-200 justify-start items-start gap-2 inline-flex">
          <div className="w-40 text-zinc-900 text-base font-normal font-['Inter'] leading-tight">
            {t('Another_text')}
          </div>
          <div className="grow shrink basis-0 text-zinc-900 text-base font-normal font-['Inter'] leading-tight">
            <TextareaAutosize
              {...register('secondText')}
              placeholder={t('Click_to_add_text')}
              minRows={4}
              maxRows={6}
              className={'outline-0 w-full'}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default SendEmailReusableForm;
