import { Dispatch, SetStateAction, useEffect } from 'react';
import { IPromotionListItem } from '../../../../types/promotion';
import { promotionsListingConfig } from '../../../../components/material-ui/tablesConfig/promotionsListingConfig';
import TableGridX from '../../../../components/tableGridX';
import useTranslationForTableGridXConfig from '../../../../hooks/useTranslationForTableGridX';

type Props = {
  itemsPerPage: number;
  data: any;
  handlePage: Dispatch<SetStateAction<number>>;
  page: number;
  setSelectedRows: any;
  rows: IPromotionListItem[];
};

const PromotionsListingTable = ({
  itemsPerPage,
  data,
  rows,
  handlePage,
  page,
}: // setSelectedRows,
Props) => {
  const propertiesListingColumns = useTranslationForTableGridXConfig([
    promotionsListingConfig,
    'common',
  ]);

  useEffect(() => {
    !localStorage.getItem('promotionlistInfos') &&
      localStorage.setItem(
        'promotionlistInfos',
        JSON.stringify({ page: 1, itemsPerPage })
      );
  }, []);
  if (!data) return null;

  return (
    <TableGridX
      columns={propertiesListingColumns}
      rows={rows}
      autoPageSize
      pageSize={itemsPerPage}
      page={page - 1}
      // autoHeight
      paginationMode="server"
      density={'comfortable'}
      rowCount={data['hydra:totalItems']}
      onPageChange={(page: number) => {
        //save page number and itemPer page to use it on archive/restore promotion
        localStorage.setItem(
          'promotionlistInfos',
          JSON.stringify({ page: page + 1, itemsPerPage })
        );
        handlePage(page + 1);
      }}
    />
  );
};
export default PromotionsListingTable;
