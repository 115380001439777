import ReactDOM from 'react-dom/client';

import App from './app/App';

import './i18n';
import './app/init/sentry';
import './app/init/muiLicense';
import './app/init/preventScrollOnInputs';

import './assets/scss/app.scss';
import { SentryErrorBoundary } from './app/components/sentryErrorBoundary';
import Providers from './app/providers';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <SentryErrorBoundary>
    <Providers>
      <App />
    </Providers>
  </SentryErrorBoundary>
);
