import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { IPromotionApi } from '../../../../types/promotion';
import useLocale from '../../../../hooks/useLocale';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropertiesListingActionMenu from '../../../../components/tableGridX/propertiesListingActionMenu';

function PromotionProperties({ data }: { data: IPromotionApi }) {
  const { locale } = useLocale();
  const { t } = useTranslation('promotionPage');

  // we need only data.properties
  // we don't need properties from blocks, because after changes in DB structure all properties which are assigned to blocks are also assigned to a promotion
  return data.properties?.length > 0 ? (
    <Stack
      className="bg-white border border-gray-200 rounded"
      sx={wrapperStyle}
    >
      <Typography variant="h5">{t('Properties')}</Typography>
      <Stack sx={listStyle}>
        {data.properties?.map(
          ({ id, reference, translations, media }, index) => {
            const firstImage = media?.[0];
            return (
              <Box sx={itemStyle} key={index}>
                <Link to={`/properties/${id}`}>
                  <Box
                    className="bg-gray-200"
                    sx={{ width: '100%', height: 'auto' }}
                  >
                    {firstImage && (
                      <img
                        src={
                          firstImage?.url.large ||
                          firstImage?.url.mobile ||
                          firstImage?.url.thumbnail ||
                          firstImage?.url.original
                        }
                        style={{
                          width: '100%',
                          height: 'auto',
                        }}
                      />
                    )}
                  </Box>
                </Link>
                <Box className="flex items-center" sx={{ width: '80%' }}>
                  <Link to={`/properties/${id}`}>
                    <Tooltip
                      title={translations[locale]?.title}
                      arrow
                      placement="top"
                    >
                      <p className={'truncate w-[36vw]'}>
                        {translations[locale]?.title}
                      </p>
                    </Tooltip>
                  </Link>
                </Box>
                <PropertiesListingActionMenu
                  row={{ id, translations, reference }}
                  isList={false}
                />
              </Box>
            );
          }
        )}
      </Stack>
    </Stack>
  ) : null;
}

export default PromotionProperties;

const wrapperStyle = {
  backgroundColor: 'background.white',
  color: 'text.primary',
  padding: '32px',
  display: 'flex',
  gap: '16px',
};

const listStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '12px',
};

const itemStyle = {
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '10% 80% 10%',
  gap: '16px',
  backgroundColor: 'background.default',
  borderBottomWidth: '1px',
  '&:last-child': {
    borderBottom: 'none',
  },
  padding: '8px',
};
