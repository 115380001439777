import 'react-quill/dist/quill.snow.css';

import { Link, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IAddress } from '../../../types/address';
import { Translations } from '../../../types/translations';
import { IMedia, Nullable } from '../../../types/api';
import { IConvenienceChoiceApi } from '../../../types/conveniences';

import usePropertyName from '../../../hooks/usePropertyName';
import {
  guaranteeArray,
  guaranteeObject,
} from '../../../helpers/utils/formatters';
import { abbreviate, formatPrice } from '../../../helpers/utils';

import { use404 } from '../../../hooks/use404';
import { useQuery } from '../../../hooks/useQuery';

import LandingHeader from '../../../components/landing/header';
import LandingTitle from '../../../components/landing/title';
import LandingHeroSlider from '../../../components/landing/heroSlider';
import LandingH2 from '../../../components/landing/h2';
import LandingCta from '../../../components/landing/cta';
import LandingFeaturesList from '../../../components/landing/featuresList';
import LandingContainer from '../../../components/landing/container';
import LandingH3 from '../../../components/landing/h3';
import LandingUserEditableHtml from '../../../components/landing/userEditableHtml';

import GoogleMapContainer from '../../../components/map/googleMapContainer';
import ContactForm from '../../properties/landing/contact-form';

import { LandingTemplates } from '../landingPopup';
import { useLandingNavLinks } from '../../../components/landing/useLandingNavLinks';
import { useGtm } from '../../../components/landing/useGtm';

export interface BrokerItem {
  id: number;
  avatar: string;
  firstname: string;
  lastname: string;
  phone: string;
  email: string;
  isSuperAdmin: boolean;
  roles: string[];
}

interface PublishedProperty {
  livingArea: number | null;
  pricing: number | null;
  reference: string | null;
  referenceAgency: unknown | null;
  status: { keyname: string; translations: Translations } | null;
  createdAt: string;
  updatedAt: string;
  translations: Translations;
}

interface PromotionLandingResponse {
  publishedProperties: PublishedProperty[] | Record<string, PublishedProperty>;
  address: IAddress;
  translations: Translations;
  coverImage: Pick<IMedia, 'url'>;
  conveniences: IConvenienceChoiceApi[];
  emailMainContact: string | null;
  phoneMainContact: string | null;
  video: string | null;
  publishedImages: IMedia[];
  logo: Pick<IMedia, 'url'>;
  agency: {
    logo?: {
      x32?: string | null;
      x64?: string | null;
      x96?: string | null;
      x368?: string | null;
    } | null;
    brand: string;
  } | null;
  mainBrokers?: Nullable<BrokerItem>[];
  broker?: Nullable<BrokerItem> | null;
  brochure: string | null;
  createdAt: string;
  updatedAt: string;
}

const PromotionLandingPage = () => {
  const query = useQuery();
  const template = query.get('template');
  const locale = query.get('lang') || 'en';

  const { reference } = useParams();
  const { data, error } = useSWR<PromotionLandingResponse>(
    `${
      process.env.REACT_APP_API_HOST
    }/promotions/${reference}?${query.toString()}`
  );

  useGtm({
    type: 'promotion',
    reference,
  });

  use404(error?.response?.status >= 400);

  const translations = data?.translations[locale];
  const title = translations?.title;
  const userHtml = translations?.description;
  const userHtml2 = translations?.secondDescription;

  const finalBrokers = useMemo(
    () => (data?.broker ? [data.broker] : data?.mainBrokers || []),
    [data?.broker, data?.mainBrokers]
  );

  const slider = data?.publishedImages && data.publishedImages.length > 0 && (
    <LandingHeroSlider images={data?.publishedImages} />
  );

  const { t } = useTranslation('propertiesPage');

  const [selectedPropertyReference, setSelectedPropertyReference] =
    useState<string>();

  const navLinks = useLandingNavLinks();
  const linksArray = Object.values(navLinks).map((el) => ({
    ...el,
    element: el.ref.current,
  }));

  function contact(propertyReference?: string | null) {
    navLinks.contact.ref.current?.scrollIntoView({
      behavior: 'smooth',
    });
    if (propertyReference) {
      setSelectedPropertyReference(propertyReference);
    }
  }

  const propertiesArray = useMemo(
    () => guaranteeArray(data?.publishedProperties),
    [data?.publishedProperties]
  );

  const { getPropertyName: _getPropertyName } = usePropertyName();
  const getPropertyName = useCallback(
    (property: PublishedProperty) => {
      return _getPropertyName({
        ...property,
        id: Number(property.reference) || 0,
      });
    },
    [_getPropertyName]
  );

  const propertyOptions = useMemo(() => {
    const options = [
      {
        id: '',
        label: t('promotionPage:contact_request'),
        value: t('promotionPage:contact_request', { lng: locale }),
      },
    ];

    for (const property of propertiesArray) {
      options.push({
        id: property.reference || '',
        label: getPropertyName(property),
        value: getPropertyName(property),
      });
    }

    return options;
  }, [propertiesArray, getPropertyName, t]);

  return (
    <>
      <LandingHeader
        locale={locale}
        logo={data?.agency?.logo}
        email={data?.emailMainContact}
        phone={data?.phoneMainContact}
        cta={true}
        onCtaClick={() => contact()}
        brochureLink={data?.brochure}
        navLinks={linksArray}
      />

      <div className="grid grid-cols-1 gap-16 xl:gap-32 pb-16">
        {template === LandingTemplates.BACKGROUND ? (
          <div className="h-[calc(100vh-109px)] relative bg-gradient-to-t from-black via-90% flex flex-col justify-end py-16">
            <div className="absolute z-10 inset-0">
              {data?.video && (
                <video
                  src={data?.video}
                  autoPlay
                  muted
                  playsInline
                  loop
                  className="w-full object-cover h-[calc(100vh-109px)]"
                />
              )}
            </div>
            <div className="relative z-20 text-white">
              <LandingTitle title={title} />
            </div>
          </div>
        ) : (
          <>
            <div></div>
            <div>
              <LandingTitle title={title} />
              {slider}
            </div>
          </>
        )}

        {userHtml && (
          <section>
            <div className="px-4 lg:px-0 max-w-[880px] w-full m-auto">
              <LandingUserEditableHtml
                className="text-center"
                html={userHtml}
              ></LandingUserEditableHtml>
            </div>
          </section>
        )}

        {template === LandingTemplates.BACKGROUND ? (
          slider
        ) : data?.video ? (
          <video
            src={data?.video}
            autoPlay
            muted
            playsInline
            loop
            className="w-full object-cover"
          />
        ) : null}

        <section id="project" ref={navLinks.project.ref}>
          <LandingContainer>
            <div className="grid gap-8 sm:grid-cols-2 xl:grid-cols-12">
              <div className="xl:col-span-4">
                <div className="grid gap-16">
                  {userHtml2 && (
                    <LandingUserEditableHtml
                      html={userHtml2}
                    ></LandingUserEditableHtml>
                  )}
                  {data?.conveniences && (
                    <LandingFeaturesList
                      features={data?.conveniences.map((c) =>
                        getConvenienceName(c, locale)
                      )}
                    />
                  )}
                  <div>
                    <LandingCta onClickPrevented={() => contact()}>
                      {t('Contact_us', { lng: locale })}
                    </LandingCta>
                  </div>
                </div>
              </div>
              <div className="xl:col-start-7 xl:col-end-13 flex justify-end items-start">
                {data?.coverImage?.url.large && (
                  <img
                    loading="lazy"
                    className="max-w-full h-auto"
                    src={data?.coverImage?.url.large}
                    alt=""
                  />
                )}
              </div>
            </div>
          </LandingContainer>
        </section>

        <section className="py-16 lg:py-32 bg-gray-100">
          <LandingContainer>
            <div className="grid gap-16">
              <div className="text-center">
                <LandingH2>{t('common:Properties', { lng: locale })}</LandingH2>
              </div>
              <div className="grid gap-2">
                {propertiesArray.map((property, index) => (
                  <div
                    className="bg-white p-4 flex flex-col items-center gap-4 lg:flex-row"
                    key={index}
                  >
                    <div className="flex-grow">
                      <Link
                        to={`/property/${property.reference}`}
                        className="hover:underline"
                      >
                        <LandingH3>{getPropertyName(property)}</LandingH3>
                      </Link>
                    </div>
                    <div className="xl:w-1/6 xl:shrink-0 text-[14px] leading-[16px] font-medium capitalize">
                      {property.status?.translations[locale]?.value ||
                        property.status?.keyname}
                    </div>
                    <div className="xl:w-1/6 xl:shrink-0 text-[14px] leading-[16px] font-medium">
                      {property.livingArea} m2
                    </div>
                    <div className="xl:w-1/6 xl:shrink-0 text-[14px] leading-[16px] font-medium">
                      {formatPrice(property.pricing)}
                    </div>
                    <div className="xl:w-1/6 xl:shrink-0">
                      <LandingCta
                        onClickPrevented={() => contact(property.reference)}
                      >
                        {t('promotionPage:receive_plans', { lng: locale })}
                      </LandingCta>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </LandingContainer>
        </section>

        {data?.address?.latitude && data?.address?.longitude && (
          <section id="location" ref={navLinks.location.ref}>
            <LandingContainer>
              <div className="grid gap-16">
                <div className="text-center">
                  <LandingH2>{t('Location', { lng: locale })}</LandingH2>
                </div>
                <GoogleMapContainer
                  coordinates={{
                    lat: data.address.latitude,
                    lng: data.address.longitude,
                  }}
                  containerStyle={googleMapStyle}
                />
              </div>
            </LandingContainer>
          </section>
        )}

        <section id="contact" ref={navLinks.contact.ref}>
          <LandingContainer>
            <div className="grid gap-8 sm:grid-cols-2 xl:grid-cols-12">
              <div className="xl:col-start-1 xl:col-end-6">
                <div className="grid gap-4">
                  {finalBrokers.map((broker, index) => (
                    <div className="bg-white rounded-2xl p-4" key={index}>
                      <div className="flex gap-8">
                        {broker.avatar ? (
                          <img
                            loading="lazy"
                            src={broker.avatar}
                            alt=""
                            className="w-[88px] h-[88px] rounded-full object-cover"
                          />
                        ) : (
                          <div className="w-[88px] h-[88px] rounded-full grid place-items-center bg-gray-200 text-white">
                            <span className="text-[32px]">
                              {abbreviate([broker.firstname, broker.lastname])}
                            </span>
                          </div>
                        )}

                        <div className="grid gap-4">
                          <LandingH3>
                            {broker.firstname} {broker.lastname}
                          </LandingH3>
                          <div className="grid gap-1 text-[16px] leading-[1.2] font-semibold">
                            <div>
                              <a
                                className="hover:underline"
                                href={`tel:${broker.phone}`}
                              >
                                {broker.phone}
                              </a>
                            </div>
                            <div>
                              <a
                                className="hover:underline"
                                href={`mailto:${broker.email}`}
                              >
                                {broker.email}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="bg-white rounded-2xl p-8">
                    {reference && (
                      <ContactForm
                        reference={selectedPropertyReference}
                        promotionReference={reference}
                        properties={propertyOptions}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="xl:col-start-6 xl:col-end-13">
                {data?.coverImage?.url.large && (
                  <img
                    loading="lazy"
                    className="max-w-full h-auto"
                    src={data?.coverImage?.url.large}
                    alt=""
                  />
                )}
              </div>
            </div>
          </LandingContainer>
        </section>
      </div>
    </>
  );
};

export default PromotionLandingPage;

const googleMapStyle = {
  width: '100%',
  height: 'calc(100vh - 200px)',
};

function getConvenienceName(c: IConvenienceChoiceApi, locale: string) {
  const translations = guaranteeObject(c.translations);
  return translations[locale]?.name || c?.name;
}
