import { IMedia, IVideoLink, Nullable } from './api';
import { IAddress } from './address';
import { Translations } from './translations';
import { IConvenienceChoiceApi } from './conveniences';
import { StepComponentProps } from '../components/stepper';
import { optionType } from '../const/propertiesOptions';
import { IContact } from './contacts';
import { IDataFields } from './settings';
import { Department } from './hierarchy';
import { HistoryT } from '../pages/properties/propertiesListing/propertiesHistory';

export interface IVisibility {
  visibility: number;
  restrictedShare: boolean;
  commissionRate: number;
  isCommissionRateHt: boolean;
  commissionRateShared: number;
  isCommissionRateSharedHt: boolean;
  networks: number[];
}

export interface DistanceInfo {
  car: number;
  distance: number;
  foots: number;
  id: number;
  keyname: string;
  publicTransport: number;
}

export interface IWebsitePublication {
  publishWebsite: boolean;
  featured: boolean;
  publishHomepage: boolean;
  startDate: string;
  endDate: string;
}

export interface IBuildingBlock {
  id: number | string;
  name: string;
  orientation: string;
  surface: number;
}

export interface IBuildingBlockApi {
  id: number | string;
  name: string;
  orientation: optionType;
  surface: number;
}

export interface IRoomBlock {
  name: string;
  type: string;
  surface: number;
  buildingBlock: string;
}

export interface IRoomBlockApi {
  id: number | string;
  name: string;
  type: optionType;
  surface: number;
  buildingBlock: IBuildingBlockApi;
}

export interface IContactOption {
  contact: string;
  mainContact: boolean;
}
export interface IContactVisitApi {
  id: string;
  email: string;
  firstname: string;
  lastname: string;
  phone: string;
  agentInCharge?: boolean;
  avatar?: string;
  isSuperAdmin?: boolean;
  roles: string[];
  company?: string | null;
  createdAt: string;
  updatedAt: string;
  individual?: {
    firstname: string;
    lastname: string;
  };
}
export interface IPropertyCommissionRepartitionApi {
  id: string;
  mainBroker: string;
  intermediateBroker: string;
  amount: number;
  percentage: string;
}

export interface IPropertyBuyOrRent {
  sellType: string;
  forwardSale: string;
  salePriceSigned: number;
  salePriceCurrency: string;
  signatureDate: string;
  globalCommissionAmount: number;
  globalCommissionCurrency: string;
  globalCommissionPourcentage: string;
  agencyCommissionAmount: number;
  agencyCommissionCurrency: string;
  agencyCommissionPourcentage: string;
  lastDepositDate: string;
  depositAmount: number;
  saleDate: string;
  handoverOfKeys: string;
  invoiceNumber: string;
  notes: string;
  propertyCommissionRepartition: IPropertyCommissionRepartitionApi[];
  propertyCommissionRepartitionIntermediate?: IPropertyCommissionRepartitionApi[];
}

export type DatafieldsGroupedByTagId = { [tagId: string]: IDataFields[] };

export type DatafieldsGroupedByTagIdAndStepName = {
  [stepName: string]: DatafieldsGroupedByTagId;
};

export interface IProperty {
  // step 1
  status: string;
  warningField: any;
  category: string;
  department: string;
  type: string;
  promotion: string | null;
  mainBroker: string;
  rateMainBroker: number;
  mainBrokerHT: boolean;
  mainBrokerTTC: boolean;
  intermediateBroker: string | null;
  rateIntermediateBroker: number | null;
  intermediateBrokerHT: boolean;
  intermediateBrokerTTC: boolean;
  availability: string;
  availableFrom: string;
  availableTo: string;
  landRegister: string;
  address: IAddress | null;
  replacementAddress: IAddress | null;
  translations: Translations;
  conveniences: string[];
  // publish location
  publishedLocation: PublishedLocation;
  newBuilding: boolean;
  // step 2
  owners: IContactOption[];
  externals: IContactOption[];
  visitContact?: IContactVisitApi | string | null;
  // step 3
  orientations: string[] | [];
  position: number;
  constructionYear: string;
  renovationYear: string;
  state: number;
  sunlight: number;
  heatingType: number;
  energy: number;
  soundLevel: number;
  views: number[];

  dimensions: Dimension[];

  buildingBlocks: IBuildingBlock[];
  roomBlocks: IRoomBlock[];
  floor: number;
  floors: number;
  balcony: number;
  terrace: number;
  lift: boolean;
  pool: boolean;
  garden: boolean;
  rooms: number;
  bedrooms: number;
  bathrooms: number;
  livingArea: number;
  usefulArea: number;
  landSurface: number;
  terraceSurface: number;
  gardenSurface: number;
  addFromToLivingArea: boolean;
  addFromToSurfaceHabitableTotale: boolean;
  addFromToLandSurface: boolean;
  addFromToUsefulArea: boolean;
  useTildeForSurface: boolean;
  surfaceBalcon: number;
  surfaceVerandaJardinHiver: number;
  surfaceUtileSecondaire: number;
  surfacePPE: number;
  surfaceSousSol: number;
  surfaceNetteDePlancher: number;
  surfaceLoggia: number;
  surfaceNette: number;
  surfacePonderee: number;
  hauteurDuPlafond: number;
  surfaceBruteDePlancher: number;
  surfaceLocative: number;
  surfaceHabitableTotale: number;
  surfaceExterne: number;
  surfaceTotale: number;
  volume: number;
  surfaceCave: number;
  surfaceGaletas: number;
  garage: boolean;
  nbGarage: number;
  parkingAvailable: boolean;
  parkingNumbers: number;
  parkingBoxesAvailable: boolean;
  parkingBoxesNumbers: number;
  parkingPrice: number;
  priceWithParking: number;
  charges: number;
  originalPrice: number;
  displayedPrice: number;
  pricem2: number;
  // mandate
  mandateType: string | null;
  mandateStartDate: string;
  mandateEndDate: string;
  mandateRenewalDate: string;
  // step 5 pricing
  pricingBuy: {
    originalPrice?: number;
    displayedPrice?: number;
    pricem2?: number;
    estimatedPrice?: number;
    recommendedPrice?: number;
    // parking
    parkingPrice?: number;
    parkingIncluded?: string | null;
    parkingMandatory?: string | null;
    // charges
    annualCharges?: number;
    chargesIncluded?: string | null;
    monthlyHeatingCharges?: number;
    heatingAnnualChargesIncluded?: string | null;
    monthlyPPECharges?: number;
    currency?: string | null;
    chargesm2Year?: number;
    // general infos
    income?: number;
    propertyLiableToVAT?: boolean;
    parkingLiableToVAT?: boolean;
    regime?: string | null;
    businessAsset?: number;
    revenue?: number;
    taxes?: number;
    incomeTaxRates?: number;
    renovationFund?: number;
    // publication
    hidePriceOnWebsite?: boolean;
    hidePriceOnPortal?: boolean;
    hidePriceOnBrochure?: boolean;
  } | null;
  pricingRent: {
    originalPrice?: number;
    displayedPrice?: number;
    pricem2?: number;
    estimatedPrice?: number;
    recommendedPrice?: number;
    priceReduction?: boolean;
    frequency?: string | null;
    moderatedPrice?: boolean;
    rentalGuaranteeDeposit?: number;
    //parking
    parkingPrice?: number;
    parkingIncluded?: string | null;
    parkingMandatory?: string | null;
    // charges
    annualCharges?: number;
    chargesIncluded?: string | null;
    monthlyHeatingCharges?: number;
    individualChargesIncluded?: string | null;
    maintenanceContract?: string | null;
    accessoryExpenses?: number;
    monthlyCharges?: number;
    chargesm2Year?: number;
    // general infos
    income?: number;
    currency?: string | null;
    propertyLiableToVAT?: boolean;
    parkingLiableToVAT?: boolean;
    serafe?: number;
    waterElectricity?: number;
    telenetwork?: number;
    //publication
    hidePriceOnWebsite?: boolean;
    hidePriceOnPortal?: boolean;
    hidePriceOnBrochure?: boolean;
  } | null;

  // step 6 media
  videoLinks: IVideoLink[];
  links3d: IVideoLink[];
  // step 7 publication;

  publishWebsite: IWebsitePublication;
  publishGateways: Gateway[];
  publishLanding: boolean | null;
  publishLandingPromotion: boolean | null;

  //transaction details
  propertyBuy?: IPropertyBuyOrRent;
  propertyRent?: IPropertyBuyOrRent;

  // not used in any step of stepper but exists in API
  reference: string;
  referenceAgency: string | null;
  rating: number | null;

  dataFields: DatafieldsGroupedByTagIdAndStepName;
  fieldValues: FieldValuePayload[];
  wc: number;
  visibility: number;
  restrictedShare: boolean;
  commissionRate: number;
  isCommissionRateHt: boolean;
  commissionRateShared: number;
  isCommissionRateSharedHt: boolean;
  isCommissionRateTTC?: boolean;
  isCommissionRateSharedTTC?: boolean;
  networks: number[];
}

export interface IContactOptionApi {
  contact: IContact;
  mainContact: boolean;
}

export interface FieldValue {
  value: string[];
  dataField: IDataFields;
}

export interface FieldValuePayload {
  value: string[];
  dataField: string;
}

export interface IMatchingProperty {
  createdAt: string;
  id: number;
  status: number;
  updatedAt: string;
}

export interface IPropertyApi {
  id: number;
  status: {
    id: number;
    translations: Translations;
  } | null;
  warningField: {
    translations: Translations | [];
  };
  availability: {
    id: number;
    translations: Translations;
  } | null;
  conveniences: Record<string, IConvenienceChoiceApi[]> | null;
  category: optionType;
  type: optionType;
  promotion: {
    id: number;
    translations: Translations;
    createdAt: string;
    updatedAt: string;
  };
  mainBroker: Broker | null;
  mainBrokerHT: boolean;
  mainBrokerTTC: boolean;
  intermediateBroker: Broker | null;
  matching?: IMatchingProperty | null;

  intermediateBrokerHT: boolean;
  intermediateBrokerTTC: boolean;
  // step 2
  owners: IContactOptionApi[];
  externals: IContactOptionApi[];
  visitContact?: string | null;
  // step 3
  floor: number;
  floors: number;
  balcony: number;
  terrace: number;
  orientations: optionType[] | [];
  position: number;
  constructionYear: string;
  renovationYear: string;
  state: optionType;
  sunlight: optionType;
  heatingType: optionType;
  energy: optionType;
  soundLevel: optionType;
  views: optionType[];

  lift: boolean;
  pool: boolean;
  garden: boolean;
  buildingBlocks: IBuildingBlockApi[];
  roomBlocks: IRoomBlockApi[];
  rooms: number;
  bedrooms: number;
  bathrooms: number;
  livingArea: number;
  usefulArea: number;
  landSurface: number;
  terraceSurface: number;
  gardenSurface: number;
  addFromToLivingArea: boolean;
  addFromToSurfaceHabitableTotale: boolean;
  addFromToLandSurface: boolean;
  addFromToUsefulArea: boolean;
  useTildeForSurface: boolean;
  surfaceBalcon: number;
  surfaceVerandaJardinHiver: number;
  surfaceUtileSecondaire: number;
  surfacePPE: number;
  surfaceSousSol: number;
  surfaceNetteDePlancher: number;
  surfaceLoggia: number;
  surfaceNette: number;
  surfacePonderee: number;
  hauteurDuPlafond: number;
  surfaceBruteDePlancher: number;
  surfaceLocative: number;
  surfaceHabitableTotale: number;
  surfaceExterne: number;
  surfaceTotale: number;
  volume: number;
  surfaceCave: number;
  surfaceGaletas: number;
  garage: boolean;
  nbGarage: number;
  parkingAvailable: boolean;
  parkingNumbers: number;
  parkingBoxesAvailable: boolean;
  parkingBoxesNumbers: number;
  originalPrice: number;
  displayedPrice: number;
  pricem2: number;
  priceWithParking: number;
  parkingPrice: number;
  // mandate
  mandateType: {
    id: number;
    translations: Translations;
  } | null;
  mandateStartDate: string;
  mandateEndDate: string;
  mandateRenewalDate: string;
  // pricing
  pricingBuy: PricingBuy;
  pricingRent: PricingRent;
  charges: number;
  dataFields: DatafieldsGroupedByTagIdAndStepName;
  publishedLocation: PublishedLocation;
  newBuilding: boolean;
  // step 5 media
  media:
    | {
        images: IMedia[];
        pdf: IMedia[];
      }
    | [];
  videoLinks: IVideoLink[];
  links3d: IVideoLink[];
  documents: IMedia[];
  // step 6 publication

  publishWebsite: IWebsitePublication;
  publishGateways: GatewayApi[] | Record<string, GatewayApi>;
  publishLanding: boolean | null;
  publishLandingPromotion: boolean | null;

  createdAt: string;
  updatedAt: string;

  archive: boolean | null;
  reference: string;
  referenceAgency: string | null;
  rating: number | null;
  availableFrom: string | null;
  availableTo: string | null;
  landRegister: string;
  rateMainBroker: number;
  rateIntermediateBroker: number;
  owner: PropertyOwner;
  features: {
    '@context': string;
    '@id': string;
    '@type': string;
    'hydra:member': [];
    'hydra:totalItems': number;
    'hydra:search': {
      '@type': string;
      'hydra:template': string;
      'hydra:variableRepresentation': string;
      'hydra:mapping': [
        {
          '@type': string;
          variable: string;
          property: string;
          required: boolean;
        },
        {
          '@type': string;
          variable: string;
          property: string;
          required: boolean;
        },
        {
          '@type': string;
          variable: string;
          property: string;
          required: boolean;
        }
      ];
    };
  };
  block: string | null;
  prospections: {
    '@context': string;
    '@id': string;
    '@type': string;
    'hydra:member': [];
    'hydra:totalItems': number;
    'hydra:search': {
      '@type': string;
      'hydra:template': string;
      'hydra:variableRepresentation': string;
      'hydra:mapping': [
        {
          '@type': string;
          variable: string;
          property: string;
          required: boolean;
        },
        {
          '@type': string;
          variable: string;
          property: string;
          required: boolean;
        },
        {
          '@type': string;
          variable: string;
          property: string;
          required: boolean;
        }
      ];
    };
  };
  displayOrder: string | null;
  address: IAddress | null;
  replacementAddress: IAddress | null;

  longitude: string | null;
  latitude: string | null;

  link3d: string | null;
  linkVideo: string | null;
  translations: Translations;
  department: Department;
  startDate?: string;
  endDate?: string;
  blockName?: string;
  fieldValues:
    | (FieldValue | undefined)[]
    | Record<string, FieldValue | undefined>;
  propertyBuy: IPropertyBuyOrRent;
  propertyRent: IPropertyBuyOrRent;
  brochure: string | null;
  wc: number;
  visibility: number;
  restrictedShare: boolean;
  commissionRate: number;
  isCommissionRateHt: boolean;
  commissionRateShared: number;
  isCommissionRateSharedHt: boolean;
  networks: {
    id: number;
  }[];
  historics: HistoryT[];
  isNetworkProperty: boolean;
  distances: DistanceInfo[];
}

export interface PricingBuy {
  originalPrice: number;
  displayedPrice: number;
  pricem2: number;
  estimatedPrice: number;
  recommendedPrice: number;
  parkingPrice: number;
  parkingIncluded: {
    id: number;
    translations: Translations;
  } | null;
  parkingMandatory: {
    id: number;
    translations: Translations;
  } | null;
  annualCharges: number;
  chargesIncluded: {
    id: number;
    translations: Translations;
  } | null;
  monthlyHeatingCharges: number;
  heatingAnnualChargesIncluded: {
    id: number;
    translations: Translations;
  } | null;
  monthlyPPECharges: number;
  chargesm2Year: number;
  currency: {
    id: number;
    translations: Translations;
    value?: string;
  } | null;
  propertyLiableToVAT?: boolean;
  parkingLiableToVAT?: boolean;
  income?: number;
  regime: {
    id: number;
    translations: Translations;
  } | null;
  businessAsset?: number;
  revenue?: number;
  taxes?: number;
  incomeTaxRates?: number;
  renovationFund?: number;
  hidePriceOnWebsite?: boolean;
  hidePriceOnPortal?: boolean;
  hidePriceOnBrochure?: boolean;
}

export interface PricingRent {
  displayedPrice?: number;
  originalPrice?: number;
  pricem2?: number;
  estimatedPrice?: number;
  recommendedPrice?: number;
  priceReduction?: boolean;
  frequency: {
    id: number;
    translations: Translations;
  } | null;
  moderatedPrice?: boolean;
  rentalGuaranteeDeposit?: number;
  //parking
  parkingPrice: number;
  parkingIncluded: {
    id: number;
    translations: Translations;
  } | null;
  parkingMandatory: {
    id: number;
    translations: Translations;
  } | null;
  //charges
  annualCharges?: number;
  chargesIncluded?: {
    id: number;
    translations: Translations;
  } | null;
  monthlyHeatingCharges?: number;
  individualChargesIncluded?: {
    id: number;
    translations: Translations;
  } | null;
  maintenanceContract?: {
    id: number;
    translations: Translations;
  } | null;
  accessoryExpenses?: number;
  monthlyCharges?: number;
  chargesm2Year?: number;
  // general infos
  currency?: {
    id: number;
    translations: Translations;
    value?: string;
  } | null;
  propertyLiableToVAT?: boolean;
  parkingLiableToVAT?: boolean;
  income?: number;
  serafe?: number;
  waterElectricity?: number;
  telenetwork?: number;
  // publication
  hidePriceOnWebsite?: boolean;
  hidePriceOnPortal?: boolean;
  hidePriceOnBrochure?: boolean;
}

interface PropertyOwner {
  '@context': string;
  '@id': string;
  '@type': string;
  id: number;
  gender: string;
  firstname: string;
  lastname: string;
  email: string;
  company: string;
  phone: string;
  mobile: string;
  address: {
    '@type': string;
    id: number;
    longitude: string | null;
    latitude: string | null;
    zipCode: string | null;
    translations: any;
  };
  createdAt: string | null;
  updatedAt: string | null;
}

export interface PublishedLocation {
  realWebsite: boolean;
  alternativeWebsite: boolean;
  realBrochure: boolean;
  alternativeBrochure: boolean;
  realPortals: boolean;
  alternativePortals: boolean;
}

export type PropertyStepperStep = StepComponentProps<IProperty, IPropertyApi>;

export interface Broker {
  id: number;
  email: string;
  roles: Array<
    | 'ROLE_ADMIN'
    | 'ROLE_USER'
    | 'ROLE_ADMIN_ASSISTANT'
    | 'ROLE_ADMIN_AGENT'
    | 'ROLE_ADMIN_OPERATOR'
  >;
  firstname: string;
  lastname: string;
  phone: string;
  avatar: string | null;
  isSuperAdmin?: boolean;
}

export interface GatewayApi {
  startDate: string | null;
  endDate: string | null;
  isPublished: boolean;
  gateway: {
    id: number;
    name: string;
    language: string | null;
    isActive?: boolean | null;
  };
  settingPortal: {
    id: number;
  } | null;
}

export interface Gateway {
  startDate: string | null;
  endDate: string | null;
  isPublished: boolean;
  gateway: string | number;
  settingPortal: number | null;
}

export interface GeneratedFile {
  id: number;
  name: string;
  isMain: boolean | null;
  createdAt: string;
  createdBy: GeneratedFileAuthor | null;
}

export interface DocumentDate {
  date: string;
  timezone_type: number;
  timezone: string;
}

export interface Document {
  createdAt: DocumentDate | string;
  updatedAt: DocumentDate | string;
  createdBy: GeneratedFileAuthor | null;
  id: number;
  isDocument?: boolean;
  isBrochure?: boolean;
  isMain?: boolean;
  name: string;
  originalName?: string | null;
  customName?: string | null;
  friendlyName: string | null;
  type?: string;
  pattern: number | null;
  url?: {
    large: string | null;
    mobile: string | null;
    original: string;
    thumbnail: string | null;
  };
}

export interface GeneratedFileAuthor {
  avatar: string | null;
}

export interface PropertyListingItem {
  address: IAddress | null;
  category: optionType | null;
  type: optionType | null;
  displayedPrice: number | null;
  matching?: {
    createdAt: string;
    id: number;
    updatedAt: string;
    status: number;
  };
  id: number;
  mainBroker: Broker | null;
  mainImage: {
    large: string;
    original: string;
    mobile: string;
    thumbnail: string;
  } | null;
  owners: {
    mainContact: boolean | null;
    contact?: {
      company?: {
        legalName?: string | null;
      } | null;
      individual?: {
        firstname?: string | null;
        lastname?: string | null;
      } | null;
    };
    company?: {
      companyName: string | null;
    };
  }[];
  rating: number | null;
  reference: string | null;
  referenceAgency: string | null;

  status: {
    id: number;
    keyname: string;
    translations: Translations;
  } | null;

  translations: Translations;

  createdAt: string;
  updatedAt: string;

  warningField: {
    translations: Translations | [];
  } | null;

  bedrooms: number | null;
  landSurface: number | null;
  livingArea: number | null;
  publishGateways: {
    endDate: string | null;
    gateway: {
      name: string | null;
    };
    isPublished: boolean | null;
    startDate: string | null;
  }[];
  publishWebsite: {
    publishWebsite: boolean | null;
  } | null;
  rooms: number | null;
  department?: {
    logo: {
      x32?: string | null;
      x64?: string | null;
      x96?: string | null;
      x368?: string | null;
    } | null;
    name: string | null;
    service: optionType;
  };
  pricingBuy: {
    displayedPrice?: number | null;
    pricem2?: number | null;
    currency?: {
      value?: string;
    } | null;
  } | null;
  pricingRent: {
    displayedPrice?: number | null;
    pricem2?: number | null;
    currency?: {
      value?: string;
    } | null;
  } | null;
  media: {
    images: IMedia[];
  } | null;
  restrictedShare?: boolean;
  commissionRate?: number;
  isCommissionRateHt?: boolean;
  commissionRateShared?: number;
  isCommissionRateSharedHt?: boolean;
  pricing?: number;
  currency?: string | null;
  invitations?: InvitationItem[];
}

export interface NameableProperty {
  id: number;
  reference: string | null;
  translations: Translations;
  matching?: {
    createdAt: string;
    id: number;
    status: number;
    updatedAt: string;
  } | null;
  hasPromotion?: boolean;
}

export interface PromotionChildPropertyItem
  extends Omit<IPropertyApi, 'block'> {
  index: number;
  block: {
    name: string;
  } | null;
}

export type PropertyElasticItem = Omit<IPropertyApi, 'department'> & {
  department: Omit<Department, 'id'>;
  hasPromotion?: boolean;
};

export interface SharedPropertyListingItem {
  id: number;
  restrictedShare?: boolean;
  commissionRate?: number;
  isCommissionRateHt?: boolean;
  commissionRateShared?: number;
  isCommissionRateSharedHt?: boolean;
  invitations?: InvitationItem[];
}

export interface InvitationItem {
  id: number;
  status: number;
  agency: {
    id: number;
    name: string;
    logo: string;
  };
  network: {
    id: number;
    translations: {
      [key: string]: {
        locale: string;
        name: string;
      };
    };
    userAuthorizedToEdit: boolean;
    membersCount: number;
  };
}

export enum PropertyStatuses {
  SOLD = '3',
  RENT = '4',
}

export type Dimension = {
  quantity: number;
} & Nullable<{
  spaceType: string;
  isHabitable: boolean;
  habitable: number;
  weighting: number;
  usable: number;
  building: string;
  floor: number;
  orientation: string;
  flooring: string;
  celling: number;
  sort: number;
}>;

export type DimensionApi = Omit<
  Dimension,
  'spaceType' | 'building' | 'orientation' | 'flooring'
> & {
  id: number;
} & Nullable<{
    spaceType: optionType;
    building: optionType;
    orientation: optionType;
    flooring: optionType;
  }>;

export type SpaceType = {
  id: number;
} & Nullable<{
  weighting: number;
  isHabitable: boolean;
  spaceType: {
    id: number;
    keyname: string;
    translations: Translations;
  };
}>;
