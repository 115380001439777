import { Button, Menu } from '@mui/material';
import {
  DigitalClock,
  DigitalClockProps,
  TimeField as MuiTimeField,
} from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { useState } from 'react';
import { Control, Controller } from 'react-hook-form';

type Props = {
  control: Control<any, any>;
  name: string;
  required?: boolean;
  digitalClockProps?: Omit<DigitalClockProps<Dayjs>, 'ampm'>;
};

/**
 * Inspired by https://calendar.google.com/
 */
function TimeField({
  control,
  name,
  required = false,
  digitalClockProps = {},
}: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Controller
      rules={{ required }}
      control={control}
      name={name}
      render={({ field: { ref, ...rest } }) => (
        <>
          <Button
            id={`${name}-button`}
            aria-controls={open ? `${name}-menu` : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            disableFocusRipple
          >
            <MuiTimeField
              ref={ref}
              {...rest}
              classes={{ root: '!w-full' }}
              ampm={false}
            />
          </Button>

          <Menu
            id={`${name}-menu`}
            aria-labelledby={`${name}-button`}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <DigitalClock
              {...digitalClockProps}
              {...rest}
              onChange={(e) => {
                setAnchorEl(null);
                rest.onChange(e);
              }}
              ampm={false}
            />
          </Menu>
        </>
      )}
    />
  );
}

export default TimeField;
