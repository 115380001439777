import { FC, useEffect, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import InputFormField from '../inputFormField';
import AddressInput from './';
import AutoCompleteField, { AutocompleteOption } from '../autoCompleteField';

import useLocale from '../../../hooks/useLocale';
import { countries } from '../../../const/countries';
import { useTranslation } from 'react-i18next';

const AddressInputWithTextfields: FC<{
  name: string;
  isRequired?: boolean;
  setDisabledRadio?: (value: boolean) => void;
  disabledRadio?: boolean;
}> = ({ name, isRequired, setDisabledRadio, disabledRadio }) => {
  const { register, watch, control, setValue } = useFormContext();
  const { locale } = useLocale();
  const [required, setRequired] = useState(isRequired);
  const { t } = useTranslation();
  const { field } = useController({ name, control });

  const countryOptions: AutocompleteOption[] = countries.map((country) => ({
    id: String(country.alpha2),
    label: country[locale],
  }));

  const country = watch(`${name}.translations.${locale}.country`);
  const isSwitzerland = country === 'CH';

  useEffect(() => {
    setValue(`${name}.translations.${locale}.locale`, locale);
  }, [name, locale, setValue]);

  useEffect(() => {
    if (setDisabledRadio) {
      country ? setDisabledRadio(false) : setDisabledRadio(true);
    }
    if (disabledRadio != undefined && country) {
      setRequired(true);
    }
  }, [country]);

  return (
    <div className="grid lg:grid-cols-12 gap-12">
      <div className="col-span-7 pt-6 h-full">
        <AddressInput
          value={field.value}
          onChange={field.onChange}
          replaceClassNames={'flex flex-col gap-8 h-full'}
        />
      </div>
      <div className="col-span-5 grid grid-cols-2 lg:grid-cols-1 gap-8">
        <AutoCompleteField
          label={t('common:Country')}
          className="bg-slate-50 country_promotion"
          isRequired={required}
          control={control}
          name={`${name}.translations.${locale}.country`}
          options={countryOptions}
          // force re-rendering if country value is updated
          // otherwise react-hook-form doesnt update the country when it is selected in <AddressInput />
          key={country}
        />
        <InputFormField
          label={t('common:City')}
          className="bg-slate-50 city_promotion"
          isRequired={required}
          {...register(`${name}.translations.${locale}.city`)}
          // force updating value from <AddressInput />
          value={watch(`${name}.translations.${locale}.city`)}
        />
        <InputFormField
          label={t('common:Zip_Code')}
          className="bg-slate-50 zip_code_promotion"
          isRequired={required}
          {...register(`${name}.zipCode`)}
          // force updating value from <AddressInput />
          value={watch(`${name}.zipCode`)}
        />
        <InputFormField
          label={t('common:Adresse')}
          className="bg-slate-50 address_promotion"
          // isRequired={required}
          {...register(`${name}.translations.${locale}.adresse`)}
          // force updating value from <AddressInput />
          value={watch(`${name}.translations.${locale}.adresse`)}
        />
        {isSwitzerland && (
          <InputFormField
            className="bg-slate-50 canton_promotion"
            label={t('common:Canton')}
            {...register(`${name}.translations.${locale}.canton`)}
            // force updating value from <AddressInput />
            value={watch(`${name}.translations.${locale}.canton`)}
          />
        )}
        {isSwitzerland && (
          <InputFormField
            disabled
            className="bg-slate-50 district_promotion"
            label={t('common:District')}
            {...register(`${name}.translations.${locale}.district`)}
            // force updating value from <AddressInput />
            value={watch(`${name}.translations.${locale}.district`)}
          />
        )}
        <InputFormField
          className="construction_zone_promotion"
          label={t('common:Construction_zone')}
          {...register(`${name}.translations.${locale}.zone`)}
        />
      </div>
    </div>
  );
};

export default AddressInputWithTextfields;
