import { useTranslation } from 'react-i18next';
import BedroomIcon from '../../../features/icons/bedroomIcon';
import BathroomIcon from '../../../features/icons/bathroomIcon';
import SquareIcon from '../../../features/icons/squareIcon';

const PropertyKeySpecs = ({
  locale,
  bedrooms,
  bathrooms,
  livingArea,
}: {
  locale: string;
  bedrooms?: number | null;
  bathrooms?: number | null;
  livingArea?: number | null;
}) => {
  const { t } = useTranslation('propertiesPage');
  return (
    <div className="flex-col xl:flex-row justify-start items-start gap-2 inline-flex">
      {bedrooms ? (
        <div className="px-4 py-3 bg-neutral-100 rounded-lg justify-start items-start gap-4 flex">
          <BedroomIcon className="mr-2 col-span-1" />
          <div className="text-zinc-900 text-base font-normal font-['Inter'] leading-tight">
            {bedrooms} {t('Bedrooms', { lng: locale })}
          </div>
        </div>
      ) : null}
      {bathrooms ? (
        <div className="px-4 py-3 bg-neutral-100 rounded-lg justify-start items-start gap-4 flex">
          <BathroomIcon className="mr-2 col-span-1" />
          <div className="text-zinc-900 text-base font-normal font-['Inter'] leading-tight">
            {bathrooms} {t('Bathrooms', { lng: locale })}
          </div>
        </div>
      ) : null}
      {livingArea ? (
        <div className="px-4 py-3 bg-neutral-100 rounded-lg justify-start items-start gap-4 flex">
          <SquareIcon className="mr-2 col-span-1" />
          <div className="text-zinc-900 text-base font-normal font-['Inter'] leading-tight">
            {livingArea} m²
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default PropertyKeySpecs;
