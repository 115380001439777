import { Skeleton } from '@mui/material';

const MockCard = () => {
  return (
    <div className="p-2 bg-white">
      <Skeleton variant="rectangular" height={256} />
      <div className="pt-4">
        <Skeleton variant="rectangular" height={40} />
      </div>

      <div className={'pt-4 flex flex-col'}>
        <div className="flex flex-col gap-4 pb-4">
          <Skeleton variant="rectangular" height={28} />
        </div>
        <div className={'divide-y flex flex-col border-b border-t'}>
          <div className="w-full text-neutral-500 text-sm py-1">
            <Skeleton variant="rectangular" height={20} />
          </div>
          <div className="py-2">
            <Skeleton variant="rectangular" height={16} />
          </div>
        </div>
        <div className={'py-4 border-b grid grid-cols-2 gap-2 mb-4'}>
          <Skeleton
            variant="rectangular"
            height={44}
            sx={{
              borderRadius: '118px',
            }}
          />
          <Skeleton
            variant="rectangular"
            height={48}
            sx={{
              borderRadius: '118px',
            }}
          />
        </div>
        <Skeleton variant="rectangular" height={48} />
      </div>
    </div>
  );
};

export default MockCard;
