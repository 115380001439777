import { FC, useEffect, useState } from 'react';

import {
  Box,
  Divider,
  MenuItem,
  Select,
  Switch,
  Typography,
} from '@mui/material';
import {
  Controller,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useFiltersProperties } from '../../../hooks/useFiltersProperties';
import ControlledSelectedForm from '../../../components/form/controlledSelectedForm';
import useSWR from 'swr';
import { switchStyles } from '../components/forms/contact-type-form';
import { IContactTypeChoice } from '../../../types/contacts';
import InputLayout from '../../../components/form/components/inputLayout';
import classNames from 'classnames';
import { selectOptionStringT } from '../../../components/form/selectFormField';
import useLocale from '../../../hooks/useLocale';
import InputFormField from '../../../components/form/inputFormField';
import FilterAgencyMultiselect from '../../../components/form/filterAgencyMultiselect';
import { useAuth } from '../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';

type Props = {
  mainFilterState: any;
  getValues?: any;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  getInputValue: any;
  watch: UseFormWatch<any>;
  control: any;
  sleep: (duration: number) => Promise<void>;
};

const choicesToOptions = (choices: IContactTypeChoice[], locale: string) => {
  return choices.map((item) => ({
    id: item.id,
    value: item.translations?.[locale]?.name || '',
  }));
};

const ContactFilter: FC<Props> = ({
  register,
  watch,
  control,
  mainFilterState,
  sleep,
  setValue,
}) => {
  const { isAssistant } = useAuth();
  const [data, setData] = useState(
    mainFilterState?.contact?.find((elm: any) => elm.label === 'agents')
      ?.options || []
  );

  const { agencyHierarchy: hierarchy, assistantAgencyHierarchy } =
    useFiltersProperties();
  const { data: types } = useSWR(`/contact_types`);
  const { data: constructorChoices } = useSWR(
    watch('external-types') && watch('external-types') === 9
      ? `/contact_type_choices/9`
      : null
  );
  const { locale } = useLocale();
  const { t } = useTranslation('propertiesPage');
  useEffect(() => {
    console.log('updated', mainFilterState.contact);
    setData([
      ...data,
      ...(mainFilterState?.contact?.find((elm: any) => elm.label === 'agents')
        ?.options || []),
    ]);
  }, [mainFilterState]);
  return (
    <form>
      <Box>
        <Typography fontSize="1.3rem" marginBottom="20px">
          {' '}
          {t('Contact')}
        </Typography>
        <Box className="grid grid-cols-3 gap-8 mb-8">
          <InputFormField
            type="text"
            label={t('Contact_name')}
            {...register('contactName')}
          />
          <FilterAgencyMultiselect
            label={t('Agencies')}
            node={isAssistant ? assistantAgencyHierarchy : hierarchy}
            name="departments"
            value={watch('departments')}
            setValue={setValue}
            className="w-full !h-14"
            isDisabled={isAssistant && !watch('agents')}
          />
          <ControlledSelectedForm
            label={t('Agents')}
            sleep={sleep}
            name={'agents'}
            options={data}
            value={watch('agents')}
            register={register}
            className="h-14"
          />
        </Box>
        <Divider sx={{ margin: '20px 0', border: '1px solid #E6E8EC' }} />
        <Box className="grid grid-cols-3 gap-8 mb-4">
          {types?.map((item: any) => (
            <div key={item.keyname}>
              <div className="flex items-center justify-between border-b py-4 mb-4">
                <p className={'capitalize'}>
                  {item.translations[locale]?.name}
                </p>
                <Controller
                  name={item.keyname}
                  key={item.keyname}
                  control={control}
                  render={({ field }) => {
                    return (
                      <Switch
                        sx={switchStyles}
                        checked={field.value}
                        {...register(item.keyname)}
                        defaultChecked={!!field.value}
                        onChange={(event, val) => field.onChange(val)}
                      />
                    );
                  }}
                />
              </div>
              <InputLayout
                label={`${item.translations[locale]?.name} types`}
                className={classNames('', {
                  'opacity-50 pointer-events-none': !watch(item.keyname),
                })}
              >
                <Controller
                  name={`${item.keyname}-types`}
                  control={control}
                  render={({ field }) => (
                    <Select
                      disabled={!watch(item.keyname)}
                      sx={{
                        '& .MuiSelect-select.MuiSelect-multiple': {
                          textTransform: 'capitalize',
                        },
                      }}
                      //multiple
                      className={'h-[56px]'}
                      labelId="level-label"
                      {...field}
                    >
                      {choicesToOptions(item.choices, locale).map(
                        (item: selectOptionStringT, index: number) => (
                          <MenuItem
                            key={index}
                            value={item?.id}
                            className="capitalize"
                          >
                            {item.value}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  )}
                />
              </InputLayout>
              {item.keyname === 'external' &&
              watch('external-types') &&
              watch('external-types') === 9 ? (
                <InputLayout
                  label={t('Building_constructor_types')}
                  className={classNames('pt-4')}
                >
                  <Controller
                    name={'constructor-types'}
                    control={control}
                    render={({ field }) => (
                      <Select
                        sx={{
                          '& .MuiSelect-select': {
                            textTransform: 'capitalize',
                          },
                        }}
                        className={'h-[56px]'}
                        labelId="level-label"
                        {...field}
                      >
                        {constructorChoices && constructorChoices.constructor
                          ? choicesToOptions(
                              constructorChoices?.constructor,
                              locale
                            ).map(
                              (item: selectOptionStringT, index: number) => (
                                <MenuItem
                                  key={index}
                                  value={item?.id}
                                  className="capitalize"
                                >
                                  {item?.value}
                                </MenuItem>
                              )
                            )
                          : null}
                      </Select>
                    )}
                  />
                </InputLayout>
              ) : null}
            </div>
          ))}
        </Box>
      </Box>
    </form>
  );
};

export default ContactFilter;
