import { ReactNode } from 'react';

const ControlLayout = ({
  label,
  children,
}: {
  label?: string;
  children?: ReactNode;
}) => {
  return (
    <div className="grid gap-4">
      {label && <div className="text-sm leading-none">{label}</div>}
      {children}
    </div>
  );
};

export default ControlLayout;
