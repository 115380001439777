import { categoryOptionType, optionType } from '../const/propertiesOptions';
import { Translation } from '../pages/settingsPage/customAttrTags';
import { Translations } from './translations';

export const visibilityOption: optionType[] = [
  {
    id: '1',
    keyname: 'visible',
    translations: {
      fr: { name: 'visible', value: 'visible', locale: 'fr' },
      en: { name: 'visible', value: 'visible', locale: 'en' },
      de: { name: 'sichtbar', value: 'sichtbar', locale: 'de' },
    },
  },
  {
    id: '0',
    keyname: 'hidden',
    translations: {
      fr: { name: 'cachée', value: 'cachée', locale: 'fr' },
      en: { name: 'hidden', value: 'hidden', locale: 'en' },
      de: { name: 'versteckt', value: 'versteckt', locale: 'de' },
    },
  },
];

export const fieldTypes: optionType[] = [
  {
    id: '0',
    keyname: 'text',
    translations: {
      fr: { name: 'texte', value: 'texte', locale: 'fr' },
      en: { name: 'text', value: 'text', locale: 'en' },
      de: { name: 'Wortlaut', value: 'Wortlaut', locale: 'de' },
    },
  },
  {
    id: '1',
    keyname: 'list-with-single-choice',
    translations: {
      fr: {
        name: 'liste (à choix unique)',
        value: 'liste (à choix unique)',
        locale: 'fr',
      },
      en: {
        name: 'list (with single choice)',
        value: 'list (with single choice)',
        locale: 'en',
      },
      de: {
        name: 'Liste (mit Einzelauswahl)',
        value: 'Liste (mit Einzelauswahl)',
        locale: 'de',
      },
    },
  },
  {
    id: '2',
    keyname: 'list-with-multiple-choices',
    translations: {
      fr: {
        name: 'liste (à choix multiples)',
        value: 'liste (à choix multiples)',
        locale: 'fr',
      },
      en: {
        name: 'list (with multiple choices)',
        value: 'list (with multiple choices)',
        locale: 'en',
      },
      de: {
        name: 'Liste (mit mehreren Auswahlmöglichkeiten)',
        value: 'Liste (mit mehreren Auswahlmöglichkeiten)',
        locale: 'de',
      },
    },
  },
];
export const stepTypes: optionType[] = [
  {
    id: '0',
    keyname: 'General information',
    translations: {
      fr: {
        name: 'information générale',
        value: 'information générale',
        locale: 'fr',
      },
      en: {
        name: 'general information',
        value: 'general information',
        locale: 'en',
      },
      de: {
        name: 'allgemeine Informationen',
        value: 'allgemeine Informationen',
        locale: 'de',
      },
    },
  },
  {
    id: '1',
    keyname: 'property details',
    translations: {
      fr: {
        name: 'Détails de la propriété',
        value: 'Détails de la propriété',
        locale: 'fr',
      },
      en: {
        name: 'property details',
        value: 'property details',
        locale: 'en',
      },
      de: {
        name: 'Details der Immobilie',
        value: 'Details der Immobilie',
        locale: 'de',
      },
    },
  },
  {
    id: '2',
    keyname: 'conveniences',
    translations: {
      fr: {
        name: 'commodités',
        value: 'commodités',
        locale: 'fr',
      },
      en: {
        name: 'conveniences',
        value: 'conveniences',
        locale: 'en',
      },
      de: {
        name: 'Annehmlichkeiten',
        value: 'Annehmlichkeiten',
        locale: 'de',
      },
    },
  },
  {
    id: '3',
    keyname: 'description',
    translations: {
      fr: {
        name: 'description',
        value: 'description',
        locale: 'fr',
      },
      en: {
        name: 'description',
        value: 'description',
        locale: 'en',
      },
      de: {
        name: 'Beschreibung',
        value: 'Beschreibung',
        locale: 'de',
      },
    },
  },
];

export interface DataFieldTag {
  id: number;
  defaultTagLocale: string;
  translations: Translations;
}

export interface IDataFields {
  id: number;
  translations: Translations;
  isVisible: string | boolean;
  type: string | number;
  departments: string[];
  // propertyType: string;
  propertyTypes: string[] | null;
  tag: DataFieldTag | null;
  step: string | number;
  category: string;
  publishedOnWebsite: boolean | null;
  isRequired: boolean;
  fieldSelect: {
    options: {
      id?: number;
      translations: Translations;
    }[];
  };
  fieldText: {
    translations: Translations;
  };
}

export interface ICustomAttributesApi {
  id: number;
  translations: Translations;
  publishedOnWebsite: boolean | null;
  isVisible: boolean;
  isRequired: boolean;
  type: number;
  departments: {
    name: string;
    id: number;
  }[];
  propertyTypes: {
    id: number;
  }[];
  step: number;
  fieldSelect: {
    options: {
      id: number;
      translations: Translations;
    }[];
  };
  fieldText: {
    translations: Translations;
  };
  tag?: {
    id: number;
    keyname: string;
    translations: Translation;
  } | null;
  propertyType?: {
    id: number;
    keyname: string;
    translations: Translations;
  };
  category: categoryOptionType;
}

export interface IDataFieldsToSend {
  id?: string;
  translations: Translations;
  isVisible: boolean;
  type: string | number;
  departments: string[];
  propertyTypes: string[];
  // propertyType: string | number | string[];
  step: string | number;
  category: string;
  fieldSelect: {
    options: {
      id?: number;
      translations: Translations;
    }[];
  };
  fieldText: {
    translations: Translations;
  };
}

export interface ISettings {
  dataFields?: IDataFields[];
}

export interface ISettingEmails {
  id: string | number;
  type: string;
  isActive: boolean;
  translations: {
    [key: string]: {
      locale: string;
      title: string;
      subject: string;
      text1: string;
      text2: string;
    };
  };
}
