import { Skeleton } from '@mui/material';
import EditIcon from '../../features/icons/editIcon';
import PlusIcon from '../../features/icons/plusIcon';
import { formatDateAndTime } from '../../helpers/utils';
import { useTranslation } from 'react-i18next';

const CreatedUpdated = ({
  created,
  updated,
  dateCard,
}: {
  created?: string | null;
  updated?: string | null;
  dateCard?: boolean;
}) => {
  const { t } = useTranslation('common');
  const skeletonEl = (
    <Skeleton width={100} height={20} animation="wave" variant="text" />
  );
  return (
    <div
      className={`${dateCard ? 'pl-0' : 'pl-3'}  flex flex-col gap-2 leading-5`}
    >
      {created !== null && (
        <div className="flex items-center gap-2">
          <PlusIcon color="#6E6E72" />{' '}
          {dateCard ? (
            <div className="flex gap-2 items-center">
              <span className="font-medium leading-4 text-xs font-[Inter] text-[#6E6E72]">
                {t('date_created')}:
              </span>
              <span className="font-medium leading-4 text-xs font-[Inter] text-[#1D1D1F]">
                {created ? formatDateAndTime(created) : skeletonEl}
              </span>
            </div>
          ) : (
            <span>{created ? formatDateAndTime(created) : skeletonEl}</span>
          )}
        </div>
      )}
      {updated !== null && (
        <div className="flex items-center gap-2">
          <EditIcon color="#6E6E72" />{' '}
          {dateCard ? (
            <div className="flex gap-2 items-center">
              <span className="font-medium leading-4 text-xs font-[Inter] text-[#6E6E72]">
                {t('date_updated')}:
              </span>
              <span className="font-medium leading-4 text-xs font-[Inter] text-[#1D1D1F]">
                {updated ? formatDateAndTime(updated) : skeletonEl}
              </span>
            </div>
          ) : (
            <span>{updated ? formatDateAndTime(updated) : skeletonEl}</span>
          )}
        </div>
      )}
    </div>
  );
};

export default CreatedUpdated;
