import { http } from '../../../../libs/axios';
import { toast } from 'react-toastify';
import i18n from '../../../../i18n';

const addToNetwork = (
  networkId: string,
  agencyId: string,
  mutate?: () => void
) => {
  const { t } = i18n;
  http
    .post(`/networks/${networkId}/network_members`, {
      agency_id: agencyId,
    })
    .then(() => {
      toast.success(`${t('alerts:networks:yourInvitationIsSend')}`, {
        position: 'top-right',
        autoClose: 3000,
      });

      if (mutate) {
        mutate();
      }
    })
    .catch(() => {
      toast.error(`${t('alerts:server_error')}`);
    });
};

export default addToNetwork;
