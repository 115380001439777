import { MouseEventHandler } from 'react';
import { Tooltip } from '@mui/material';

import RemoveIcon from '../../../features/icons/removeIcon';
import { t } from 'i18next';

const RemoveButton = ({
  tooltip,
  onClick,
  className,
}: {
  tooltip: string;
  className?: string;
  onClick?: MouseEventHandler;
}) => (
  <Tooltip
    title={`${t(`common:${tooltip.replaceAll(' ', '_')}`)}`}
    arrow
    placement="top"
  >
    <button
      type="button"
      className={
        'absolute right-4 z-10 w-6 h-6 bg-white rounded grid place-items-center border border-gray-200 hover:border-black ' +
        className
      }
      onClick={onClick}
      aria-label={tooltip}
    >
      <RemoveIcon color="#F50057" />
    </button>
  </Tooltip>
);

export default RemoveButton;
