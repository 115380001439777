import { PropertyListingItem } from '../../types/property';
import CircleWithShortName, {
  CircleWithShortNameProps,
} from './circleWithShortName';
import PropertyContactItem from '../cardsTablesList/cards/elements/contactItem';

const PropertyContactOptimized = ({
  row,
  isGrid,
}: {
  row: PropertyListingItem;
  isGrid?: boolean;
}) => {
  const items: CircleWithShortNameProps[] = [];

  items.push({
    avatar: row.mainBroker?.avatar,
    firstname: row.mainBroker?.firstname,
    lastname: row.mainBroker?.lastname,
    role: 'broker',
  });

  const mainOwner =
    row.owners &&
    row.owners.length &&
    row.owners.filter((owner) => owner.mainContact === true)[0];
  if (mainOwner) {
    items.push({
      firstname: mainOwner?.contact?.individual?.firstname,
      lastname: mainOwner?.contact?.individual?.lastname,
      legalName: mainOwner?.contact?.company?.legalName,
      role: 'owner',
    });
  }

  return (
    <>
      {items.map((item, index) => (
        <>
          {isGrid ? (
            <PropertyContactItem key={index} {...item} />
          ) : (
            <CircleWithShortName key={index} {...item} />
          )}
        </>
      ))}
    </>
  );
};

export default PropertyContactOptimized;
