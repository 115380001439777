import useSWR from 'swr';
import { useNavigate, useParams } from 'react-router-dom';
import SpinnerLoading from '../../../features/spinner/spinnerLoading';
import { HttpError } from '../../../libs/axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { IContact } from '../../../types/contacts';
import ContactInformationTab from './tabs/contact-information-tab';
import IndividualContactTab from './tabs/individual-contacts-tab';
import ContactTypeTab from './tabs/contact-type-tab';
import AdditionalInformationTab from './tabs/additional-information-tab';
import TabbedContactInformation, { ContactTabProps } from './tabs';
import SearchCriteriaTab from './tabs/search-criteria-tab';
import HistoryTab from './tabs/history-tab';
import MatchingTab from './tabs/matching-tab';
import TasksTab from './tabs/tasks-tab';
import CommentsTab from './tabs/comments-tab';
import PropertiesTab from './tabs/properties-tab';
import Communication from './tabs/communication';

const mapTabNamesToComponents = {
  'Contact information': ContactInformationTab,
  Contacts: IndividualContactTab,
  'Contact type': ContactTypeTab,
  'Additional information': AdditionalInformationTab,
  'Search criteria': SearchCriteriaTab,
  Matching: MatchingTab,
  Tasks: TasksTab,
  'Contact history': HistoryTab,
  Comments: CommentsTab,
  Leads: PropertiesTab,
  communication: Communication,
} as Record<string, (props: ContactTabProps) => JSX.Element>;

function SingleContactPage() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation('alerts');
  const { data, error, mutate } = useSWR<IContact, HttpError>(
    `/contacts/${userId}`
  );

  useEffect(() => {
    if (error) {
      toast.error(t('user_not_found') as string);
      navigate('/contacts');
    }
  }, [error, navigate, t]);

  if (!data) return <SpinnerLoading />;

  return (
    <TabbedContactInformation
      id={userId}
      data={data}
      mapTabNamesToComponents={mapTabNamesToComponents}
      mutate={mutate}
    />
  );
}

export default SingleContactPage;
