import { Box, Button, ButtonProps } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { FC, ReactNode } from 'react';

interface Props extends ButtonProps {
  children?: ReactNode;
  icon?: boolean;
}

const PreviousButton: FC<Props> = ({
  children,
  type = 'submit',
  icon = true,
  ...rest
}) => {
  return (
    <Button
      type={type}
      variant="contained"
      className="flex justify-center items-center"
      disableElevation
      sx={{
        background: '#FFFFFF',
        color: 'blue',
        border: '1px solid',
        padding: '.5rem 1rem',
        ':hover': { background: 'transparent' },
      }}
      {...rest}
    >
      <Box className="flex justify-center items-center" gap="16px">
        {icon && <KeyboardBackspaceIcon className="w-4 h-4" fontSize="small" />}
        <p className="capitalize">{children || 'Prev'}</p>
      </Box>
    </Button>
  );
};

export default PreviousButton;
