import { FieldValues, UseFormRegister, UseFormWatch } from 'react-hook-form';
import { useMemo } from 'react';

import InputFormField from '../../../../components/form/inputFormField';
import SelectFormField from '../../../../components/form/selectFormField';
import useLocale from '../../../../hooks/useLocale';
import { IDataFields } from '../../../../types/settings';
import { FieldValue } from '../../../../types/property';

export interface FormWithCustomAttributes extends FieldValues {
  fieldValues: FieldValue[];
}

const CustomAttributeField = ({
  dataField,
  indexInFieldValues,
  register,
  watch,
}: {
  dataField: IDataFields;
  indexInFieldValues: number;
  register: UseFormRegister<FormWithCustomAttributes>;
  watch: UseFormWatch<FormWithCustomAttributes>;
}) => {
  const { locale } = useLocale();
  const label = dataField?.translations?.[locale]?.label as string;

  const options = useMemo(
    () =>
      dataField?.fieldSelect?.options?.map((option) => ({
        label: option?.translations[locale]?.value as string,
        value: option?.translations[locale]?.value as string,
        id: option?.id as number,
      })) || [],
    [dataField, locale]
  );

  const isNormalInput = dataField.type === 0;
  const isSingleSelect = dataField.type === 1;

  if (isNormalInput) {
    return (
      <div>
        <input
          type="text"
          className="hidden"
          value={dataField.id}
          {...register(`fieldValues.${indexInFieldValues}.dataField`, {
            required: dataField.isRequired,
          })}
        />
        <InputFormField
          label={label}
          className="bg-slate-50"
          isRequired={dataField.isRequired}
          defaultValue={watch(`fieldValues.${indexInFieldValues}.value.0`)}
          {...register(`fieldValues.${indexInFieldValues}.value.0`)}
        />
      </div>
    );
  } else {
    const src = watch(`fieldValues.${indexInFieldValues}.value`) || [];
    return (
      <div>
        <input
          type="text"
          className="hidden"
          value={dataField.id}
          {...register(`fieldValues.${indexInFieldValues}.dataField`, {
            required: dataField.isRequired,
          })}
        />

        <SelectFormField
          className="Status_property"
          isRequired={dataField.isRequired}
          multiple={!isSingleSelect}
          label={label}
          options={options}
          defaultValue={isSingleSelect ? src[0] : src}
          {...register(
            isSingleSelect
              ? `fieldValues.${indexInFieldValues}.value.0`
              : `fieldValues.${indexInFieldValues}.value`,
            { required: dataField.isRequired }
          )}
        />
      </div>
    );
  }
};

export default CustomAttributeField;
