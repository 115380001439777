import { useTranslation } from 'react-i18next';

import { IndependentWidgetProps } from '../../../../../types/brochureEditor';
import FontSizeCalibrator from '../../fontSizeCalibrator';

const RichTextBlock = ({ widgetOptions }: IndependentWidgetProps) => {
  const { t } = useTranslation('brochureEditor');

  return (
    <div className="prose prose-neutral prose-headings:font-normal absolute inset-0">
      <FontSizeCalibrator
        html={
          (widgetOptions?.content?.value as string) ||
          `<p>${t('enter_text_in_widget_settings')}</p>`
        }
      />
    </div>
  );
};

export default RichTextBlock;
