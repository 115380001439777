import { ChangeEvent, useState } from 'react';
import { http } from '../libs/axios';
import { IMedia } from '../types/api';

export function useImageState({
  image,
  mutate,
  defaultEntries = [],
}: {
  image: IMedia;
  mutate?: () => Promise<unknown>;
  defaultEntries: ImageStateEntry[];
}) {
  const [entries, setEntries] = useState<ImageStateEntry[]>(defaultEntries);

  const handleSwitchChange =
    (index: number) => async (event: ChangeEvent<any>) => {
      const updatedPortals = entries.map((portal, i) =>
        i === index ? { ...portal, value: event.target.checked } : portal
      );

      setEntries(updatedPortals);

      const dataToSend: Record<string, boolean> = {};

      for (const data of updatedPortals) {
        dataToSend[data.name] = data.value;
      }

      await http.patch(`media/${image.id}`, dataToSend);

      if (mutate) {
        await mutate();
      }
    };

  return { stateEntries: entries, handleSwitchChange };
}

interface ImageStateEntry {
  label: string;
  name: string;
  value: boolean;
}
