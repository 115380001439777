import dayjs from 'dayjs';

import { IContactTask } from '../../../../types/contacts';
import { Box } from '@mui/material';
import Pills from '../../../../components/common/pills';
import React, { useState } from 'react';
import TasksList from './list';
import Calendar from './calendar';
import CreateTaskModal from './modal';
import { useTranslation } from 'react-i18next';

interface Props {
  items: IContactTask[];
  id: string;
  mutate: any;
}

const initialData: IContactTask = {
  id: 0,
  time: '',
  title: '',
  type: null,
  status: null,
  topic: '',
  note: '',
  startDate: '',
  endDate: '',
  reminderTime: null,
};

const Tasks = (props: Props) => {
  const { items, id, mutate } = props;
  const [activePill, setActivePill] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isEditMode, toggleIsEditMode] = useState(false);
  const [data, setData] = useState<IContactTask>(initialData);
  const { t } = useTranslation('common');
  const addTask = (date?: string) => {
    const updatedData = initialData;

    if (date) {
      updatedData.startDate = dayjs(date).set('hour', 9).toString();
      updatedData.endDate = dayjs(date).set('hour', 10).toString();
    } else {
      const now = dayjs(new Date());
      updatedData.startDate = now.add(1, 'hour').set('minute', 0).toString();
      updatedData.endDate = now.add(2, 'hour').set('minute', 0).toString();
    }

    toggleIsEditMode(false);
    setData(updatedData);
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
    setData(initialData);
  };

  const onOpenTask = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    updatedData: IContactTask
  ) => {
    e.preventDefault();

    toggleIsEditMode(true);
    setData(updatedData);
    setTimeout(() => {
      setIsOpen(true);
    }, 300);
  };

  return (
    <>
      <Pills
        activeItemId={activePill}
        onItemClick={(itemId) => setActivePill(itemId)}
        items={[
          {
            id: 0,
            title: `${t('List_view')}`,
            icon: 'PermissionIcon',
            content: (
              <Box>
                <TasksList
                  items={items}
                  onAddNewItemClick={() => addTask()}
                  contactId={id}
                  onAfterSubmit={() => onClose()}
                  mutate={mutate}
                />
              </Box>
            ),
          },
          {
            id: 0,
            title: `${t('Calendar_view')}`,
            icon: 'CalendarIcon',
            content: (
              <Box>
                <Calendar
                  events={items}
                  onAddNewItemClick={(date) => addTask(date)}
                  onOpenTask={onOpenTask}
                />
              </Box>
            ),
          },
        ]}
      />
      <CreateTaskModal
        data={data}
        isOpen={isOpen}
        onClose={onClose}
        contactId={id}
        onAfterSubmit={() => onClose()}
        mutate={mutate}
        isEditMode={isEditMode}
      />
    </>
  );
};

export default Tasks;
