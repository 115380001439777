import { useTranslation } from 'react-i18next';
import { IconButton, InputAdornment } from '@mui/material';
import InputFormField from '../../components/form/inputFormField';
import SearchIcon from '../../features/icons/searchIcon';
import CloseIcon from '../../features/icons/closeIcon';

function ContactMagicSearch({
  contactSearch,
  setContactSearch,
}: {
  contactSearch: string;
  setContactSearch: (newVal: string) => unknown;
}) {
  const { t } = useTranslation('common');

  return (
    <InputFormField
      className="bg-white"
      placeholder={`${t('Name')} , ${t('Email')} , ${t('Phone')}`}
      isSmall
      inputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              size="small"
              onClick={() => setContactSearch('')}
              aria-label={t('clear')}
            >
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      value={contactSearch}
      onChange={(e) => setContactSearch(e.target.value)}
    />
  );
}

export default ContactMagicSearch;
