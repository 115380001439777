import { useTranslation } from 'react-i18next';
import { ChangeEvent } from 'react';
import { http } from '../../libs/axios';
import { IMedia } from '../../types/api';
import InputLabel from '@mui/material/InputLabel';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import { toast } from 'react-toastify';

interface Props {
  mutate: () => void;
  propertyId?: string;
  promotionId?: string;
}

const DownloadBrochureButton = (props: Props) => {
  const { propertyId, promotionId, mutate } = props;
  const { t } = useTranslation();

  async function uploadFile(file: File) {
    const formData = new FormData();
    formData.append('files[]', file);
    return await http.post<IMedia>(
      `/brochures/${propertyId ? 'property' : 'promotion'}/${
        propertyId || promotionId
      }`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  }

  async function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0];
    if (file) {
      try {
        const { data } = await uploadFile(file);
        if (data) {
          toast.success(t(`alerts:download_brochure`) as string);

          if (mutate) {
            mutate();
          }
        }
      } catch (e) {
        toast.error(e as string);
      }
    }
  }

  return (
    <>
      <input
        type="file"
        accept="application/pdf"
        onChange={handleChange}
        style={{ display: 'none' }}
        id={'brochure'}
      />
      <InputLabel htmlFor={'brochure'} sx={{ display: 'flex' }}>
        <div
          className={
            'p-4 text-[14px] cursor-pointer h-[40px] rounded bg-blue text-white flex items-center justify-center gap-2'
          }
        >
          <ArrowCircleUpIcon fontSize={'small'} />
          {t('propertiesPage:upload_brochure')}
        </div>
      </InputLabel>
    </>
  );
};

export default DownloadBrochureButton;
