import isEqual from 'lodash/isEqual';
import { useLocation } from 'react-router-dom';

export function useCurrentTab<T extends { slug: string }>(
  tabs: T[]
): T | undefined {
  const { pathname } = useLocation();
  const currentTab = tabs.find((tab) => pathname.includes(tab.slug));
  return currentTab;
}

export function sanitizePayload<ClientType>(body: Partial<ClientType>) {
  const entries = Object.entries(body);
  for (const [key, value] of entries) {
    if (value === '') {
      // @ts-ignore
      body[key] = null;
    }
  }
  return body;
}

export function calculateDiff<T>(a: T, b: T) {
  const diff: Partial<T> = {};
  for (const key in a) {
    if (!isEqual(a[key], b[key])) {
      diff[key] = b[key];
    }
  }
  return diff;
}

export class SilentAbortion extends Error {}
