import { FC, useRef } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import InputFormField from '../../../../../components/form/inputFormField';
import useLocale from '../../../../../hooks/useLocale';

interface Props {
  onPlaceChange: (
    placeResult: google.maps.places.PlaceResult,
    placesByLanguage: any
  ) => unknown;
  isLoaded: boolean;
  register: any;
  watch: any;
  index: number;
  name: string;
}

const GooglePlacesWithMap: FC<Props> = ({
  onPlaceChange,
  isLoaded,
  register,
  name,
  index,
  watch,
}) => {
  const { langues } = useLocale();
  const laguageArray = langues.map((langue) => Object.keys(langue).join());
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const onLoadAutocomplete = (
    autocomplete: google.maps.places.Autocomplete
  ) => {
    autocompleteRef.current = autocomplete;
  };
  const onUnmountAutocomplete = () => {
    autocompleteRef.current = null;
  };
  const onPlaceChanged = async () => {
    if (autocompleteRef.current) {
      const placeResult = autocompleteRef.current.getPlace();
      if (placeResult) {
        const placesByLanguage: any = {};

        const fetchPlaceForLanguage = async (language: string) => {
          const request: any = {
            placeId: placeResult.place_id, // Use the place_id from the autocomplete result
            fields: ['address_components'],
            language, // Set the desired language code, e.g., 'fr' for French
          };

          const service = new google.maps.places.PlacesService(
            document.createElement('div')
          );

          return new Promise((resolve: any) => {
            service.getDetails(request, (placeDetails, status) => {
              if (status === google.maps.places.PlacesServiceStatus.OK) {
                // Extract address components from placeDetails
                const addressComponents = placeDetails?.address_components;

                // Store address components in placesByLanguage
                placesByLanguage[language] = addressComponents;
              } else {
                placesByLanguage[language] = null;
              }
              resolve();
            });
          });
        };

        const fetchPlacePromises = laguageArray?.map(fetchPlaceForLanguage);
        await Promise.all(fetchPlacePromises);
        // Process and save the placesByLanguage object in the database
        onPlaceChange(placeResult, placesByLanguage);
      }
    }
  };

  if (!isLoaded) {
    return null;
  }

  return (
    <div className="grid gap-8">
      <Autocomplete
        onLoad={onLoadAutocomplete}
        onUnmount={onUnmountAutocomplete}
        onPlaceChanged={onPlaceChanged}
      >
        <InputFormField
          {...register(`${name}.${index}.location`)}
          value={watch(`${name}.${index}.location`)}
          label=""
        />
      </Autocomplete>
    </div>
  );
};

export default GooglePlacesWithMap;
