import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

export default function NotificationIcon({ color, className }: IconProps) {
  const { mode } = useThemeMode();

  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8 1.5c-4.225 0-4.875 3.473-4.875 6.446 0 1.73-.625 3.554-1.625 3.554H8M8 1.5c4.225 0 4.875 3.473 4.875 6.446 0 1.73.625 3.554 1.625 3.554H8M11 11.5a3 3 0 11-6 0"
      ></path>
    </svg>
  );
}
