import { GridColumns, GridValidRowModel } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function useTranslationForTableGridXConfig([columnsData, inns]: [
  GridColumns<GridValidRowModel>,
  string
]): GridColumns<GridValidRowModel> {
  const { t } = useTranslation(inns);
  const [data, resetData] = useState<GridColumns<GridValidRowModel>>([]);

  useEffect(() => {
    resetData(columnsData);
  }, [localStorage.getItem('isLarge'), columnsData]);

  return data.map((item) => {
    return {
      ...item,
      headerName: t(item?.headerName || ''),
    };
  });
}

export default useTranslationForTableGridXConfig;
