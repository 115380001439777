import {
  Alert,
  Box,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import AuthLayout from '../../layout/authLayout';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { http, isHttpError } from '../../libs/axios';
import { Tokens, useAuth, UserPayload } from '../../hooks/useAuth';
import MountTransition from '../../layout/mountTransition';
import InputPassword from '../../components/form/inputPassword';
import { useForm } from 'react-hook-form';
import { captureException } from '@sentry/react';
import { useThemeMode } from '../../hooks/useThemeMode';
import { LoadingButton } from '@mui/lab';

type Form = {
  email: string;
  password: string;
};

export type Auth = {
  user?: UserPayload;
  token?: Tokens['token'];
  refresh_token?: Tokens['refresh_token'];
  two_factor?: boolean;
  two_factor_token?: string;
};

class TfError extends Error {
  token: string;

  constructor(token: string) {
    super();
    this.token = token;
  }
}

const isTfError = (e: unknown): e is TfError => e instanceof TfError;

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().required(),
});

export default function SignIn() {
  const { t } = useTranslation('auth');

  const { login } = useAuth();

  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    setError,
    formState: { isSubmitting, errors },
  } = useForm<Form>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async ({ email, password }: Form) => {
    try {
      const { status, data } = await http.post<Auth>('/login', {
        email,
        password,
      });

      if (status === 206) {
        throw new TfError(data.two_factor_token as string);
      }

      const { token, refresh_token, user } = data;

      login({ token, refresh_token } as Tokens, user as UserPayload);

      navigate('/');
    } catch (e: any) {
      if (e.response?.status == 463) {
        setError('email', {
          message: t('This_account_has_been_deactivated'),
          type: 'credentials',
        });
      } else if (e.response?.status == 453) {
        setError('email', {
          message: t('Subscription_disabled_or_expired'),
          type: 'credentials',
        });
      } else if (isTfError(e)) {
        navigate(`/2fa/${e.token}`);
      } else if (isHttpError(e)) {
        setError('email', {
          message: t('invalid_credentials'),
          type: 'credentials',
        });
      } else {
        captureException(e, {
          level: 'fatal',
        });
      }
    }
  };

  const { mode } = useThemeMode();

  return (
    <AuthLayout>
      <MountTransition
        mountDelay={100}
        className="transition-transform duration-700"
        transitionStyles={{
          exited: { transform: 'translateX(-100%)' },
          entered: { transform: 'translateX(0%)' },
        }}
      >
        <Box
          sx={{
            backgroundColor: mode === 'dark' ? 'background.default' : '#fff',
            color: 'text.primary',
          }}
          className=" px-8 py-10 shadow-sm"
        >
          <Box className="text-center mb-8">
            <Typography className="!mb-2" variant="h5">
              {t('welcome_back')}
            </Typography>
            <Typography variant="body1">
              {t('Please_sign_in_to_your_account_below')}
            </Typography>
          </Box>

          {errors.email && errors.email.type === 'credentials' && (
            <Alert sx={{ mb: 3, justifyContent: 'center' }} severity="error">
              {errors.email.message}
            </Alert>
          )}

          <Box
            component="form"
            className="flex flex-col gap-y-6 w-full"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <TextField
              type="email"
              id="email"
              label="Email"
              variant="outlined"
              error={
                Boolean(errors.email) && errors?.email?.type !== 'credentials'
              }
              {...register('email')}
            />

            <InputPassword
              label={t('password')}
              error={Boolean(errors.password)}
              {...register('password')}
            />

            <Box className="flex justify-between items-center">
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label={t('remember_me')}
                />
              </FormGroup>

              <Box>
                <Link className="text-primary" to="/forget-password">
                  {t('forget_your_password')}
                </Link>
              </Box>
            </Box>

            <LoadingButton
              type="submit"
              loading={isSubmitting}
              loadingPosition="center"
              variant="contained"
              disableElevation
              startIcon={<TrendingFlatIcon />}
            >
              {t('login_to_dashboard')}
            </LoadingButton>
          </Box>
        </Box>
      </MountTransition>
    </AuthLayout>
  );
}
