import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

export default function PropertiesIcon({ color, className }: IconProps) {
  const { mode } = useThemeMode();

  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        d="M1 10.833a1 1 0 01.37-.776l3.75-3.045a1 1 0 011.26 0l3.75 3.045a1 1 0 01.37.776V14a1 1 0 01-1 1H2a1 1 0 01-1-1v-3.167z"
      ></path>
      <path
        stroke={color}
        d="M8.5 8.781v-6.67a1 1 0 011.444-.896l4.5 2.23A1 1 0 0115 4.34V14a1 1 0 01-1 1h-4"
      ></path>
      <path
        fill={color}
        d="M6.75 11.25H8.25V13.25H6.75z"
        transform="rotate(90 6.75 11.25)"
      ></path>
      <path fill={color} d="M11 5.5H12.5V7.5H11z"></path>
    </svg>
  );
}
