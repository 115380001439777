import { Box, Typography } from '@mui/material';
import { FC, Suspense, lazy, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { stepperFormId } from '../../../const';
import useLocale, { Locale } from '../../../hooks/useLocale';
import { StepComponentProps } from '../../stepper';

import InputFormField from '../../form/inputFormField';
import LangSwitcher from '../../../layout/footer/langSwitcher';
import { Translations } from '../../../types/translations';
import GenerateWithAiButton from './generateWithAiButton';
import { useProtectedForm } from '../../../hooks/useProtectedForm';
import CustomAttributesDisplay from '../../../pages/properties/stepper/customAttributesDisplay';
import {
  DatafieldsGroupedByTagIdAndStepName,
  FieldValue,
  FieldValuePayload,
} from '../../../types/property';

const WysiwygEditor = lazy(() => import('../../form/wysiwygEditor'));

type Props = StepComponentProps<
  {
    translations: Translations;
    dataFields: DatafieldsGroupedByTagIdAndStepName;
    fieldValues: FieldValuePayload[];
  },
  {
    id: number;
    translations: Translations;
    dataFields: DatafieldsGroupedByTagIdAndStepName;
    fieldValues: FieldValue[];
  }
>;

export function createDescriptionStep({
  withAi,
  withSecondSetOfFields,
}: {
  withAi?: boolean;
  withSecondSetOfFields?: boolean;
}) {
  const DescriptionReusableStep: FC<Props> = ({
    defaultValues,
    onSubmit,
    serverData,
  }) => {
    const { register, control, handleSubmit, watch, setValue } =
      useProtectedForm<{
        translations: Translations;
        dataFields: DatafieldsGroupedByTagIdAndStepName;
        fieldValues: FieldValuePayload[];
      }>(
        {
          defaultValues,
        },
        serverData
      );
    const { locale } = useLocale();
    const [lang, setLang] = useState<Locale>(locale);
    const { t } = useTranslation('propertiesPage');

    return (
      <form id={stepperFormId} onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ display: 'flex', gap: '80px' }}>
          <div>
            <Typography className="text-[#6E6E72] !text-xs">
              {t('Language')}
            </Typography>

            <LangSwitcher
              currentLocale={lang}
              setLanguage={setLang}
              changeAppLocale={false}
              className="w-full !mx-0 !h-16"
            />
          </div>
          {/* key={lang} forces all child nodes to unmount and mount again */}
          <div className="col-span-2 grid gap-10 w-full" key={lang}>
            <div className="flex items-start gap-4">
              <InputFormField
                {...register(`translations.${lang}.title`)}
                label={t('Title')}
                variant="outlined"
                fullWidth
                width="w-full"
              />
              {withAi && (
                <div className="pt-8">
                  <GenerateWithAiButton
                    url={
                      serverData?.id
                        ? `/v1/properties/${serverData?.id}/generate_title?lang=${lang}`
                        : undefined
                    }
                    onGenerated={(data: { title: string }) => {
                      const result = data;
                      if (result) {
                        // for (const locale in result.title) {
                        const value = result?.title;
                        if (value) {
                          setValue(`translations.${lang}.title`, value, {
                            shouldDirty: true,
                            shouldTouch: true,
                            shouldValidate: true,
                          });
                        }
                        // }
                      }
                    }}
                  />
                </div>
              )}
            </div>
            <div className="flex items-start gap-4">
              <div className="w-full mb-16 flex gap-20">
                <div className="flex flex-col w-full gap-2">
                  <span className="text-xs text-zinc-500">
                    {t('Description')}
                  </span>
                  <Controller
                    control={control}
                    name={`translations.${lang}.description`}
                    render={({ field: { onChange, ref, value } }) => (
                      <Suspense fallback={null}>
                        <WysiwygEditor
                          value={value}
                          ref={ref}
                          onChange={onChange}
                        />
                      </Suspense>
                    )}
                  />
                </div>
              </div>
              {withAi && (
                <div className="pt-8">
                  <GenerateWithAiButton
                    url={
                      serverData?.id
                        ? `/v1/properties/${serverData?.id}/generate_description?lang=${lang}`
                        : undefined
                    }
                    onGenerated={(data: { description: string }) => {
                      const result = data;
                      if (result) {
                        // for (const locale in result.description) {
                        const value = result?.description;
                        if (value) {
                          setValue(`translations.${lang}.description`, value, {
                            shouldDirty: true,
                            shouldTouch: true,
                            shouldValidate: true,
                          });
                        }
                        // }
                      }
                    }}
                  />
                </div>
              )}
            </div>
            {withSecondSetOfFields && (
              <div className="w-full mb-16 flex gap-20">
                <div className="flex flex-col w-full gap-2">
                  <span className="text-xs text-zinc-500">
                    {t('promotionPage:second_description')}
                  </span>
                  <Controller
                    control={control}
                    name={`translations.${lang}.secondDescription`}
                    render={({ field: { onChange, ref, value } }) => (
                      <Suspense fallback={null}>
                        <WysiwygEditor
                          value={value}
                          ref={ref}
                          onChange={onChange}
                        />
                      </Suspense>
                    )}
                  />
                </div>
              </div>
            )}
            <input
              type="hidden"
              {...register(`translations.${lang}.locale`)}
              value={lang}
            />
          </div>{' '}
        </Box>

        {defaultValues?.dataFields?.description && (
          <CustomAttributesDisplay
            groupedDataFields={defaultValues?.dataFields?.description}
            fieldValues={defaultValues.fieldValues}
            register={register}
            watch={watch}
          />
        )}
      </form>
    );
  };

  return DescriptionReusableStep;
}

export default createDescriptionStep({
  withAi: false,
  withSecondSetOfFields: false,
});
