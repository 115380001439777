import { CSSProperties } from 'react';
import { LayoutPart } from '../../../../types/brochureEditor';

export function useLayoutPartStyles(options: LayoutPart) {
  const style: CSSProperties = {};
  if (options.bgColor) {
    style.backgroundColor = options.bgColor;
  }
  if (options.borderColor) {
    style.border = `1px solid ${options.borderColor}`;
  }
  if (options.textColor) {
    style.color = options.textColor;
  }
  return style;
}
