import { IContactType } from '../../../../types/contacts';

export interface minMaxOption {
  value: number;
  label: string;
}

export const buyBudgetOptions: minMaxOption[] = [
  {
    value: 10000,
    label: "CHF 10'000",
  },
  {
    value: 20000,
    label: "CHF 20'000",
  },
  {
    value: 30000,
    label: "CHF 30'000",
  },
  {
    value: 40000,
    label: "CHF 40'000",
  },
  {
    value: 50000,
    label: "CHF 50'000",
  },
  {
    value: 60000,
    label: "CHF 60'000",
  },
  {
    value: 70000,
    label: "CHF 70'000",
  },
  {
    value: 80000,
    label: "CHF 80'000",
  },
  {
    value: 90000,
    label: "CHF 90'000",
  },
  {
    value: 100000,
    label: "CHF 100'00",
  },
  {
    value: 150000,
    label: "CHF 150'00",
  },
  {
    value: 200000,
    label: "CHF 200'00",
  },
  {
    value: 250000,
    label: "CHF 250'00",
  },
  {
    value: 300000,
    label: "CHF 300'00",
  },
  {
    value: 350000,
    label: "CHF 350'00",
  },
  {
    value: 400000,
    label: "CHF 400'00",
  },
  {
    value: 450000,
    label: "CHF 450'00",
  },
  {
    value: 500000,
    label: "CHF 500'00",
  },
  { value: 600000, label: "CHF 600'00" },
  { value: 700000, label: "CHF 700'00" },
  { value: 800000, label: "CHF 800'00" },
  { value: 900000, label: "CHF 900'00" },
  { value: 1000000, label: "CHF 1'000'000" },
  { value: 1250000, label: "CHF 1'250'000" },
  { value: 1500000, label: "CHF 1'500'000" },
  { value: 1750000, label: "CHF 1'750'000" },
  { value: 2000000, label: "CHF 2'000'000" },
  { value: 2500000, label: "CHF 2'500'000" },
  { value: 3000000, label: "CHF 3'000'000" },
  { value: 3500000, label: "CHF 3'500'000" },
  { value: 4000000, label: "CHF 4'000'000" },
  { value: 4500000, label: "CHF 4'500'000" },
  { value: 5000000, label: "CHF 5'000'000" },
  { value: 5500000, label: "CHF 5'500'000" },
  { value: 6000000, label: "CHF 6'000'000" },
  { value: 6500000, label: "CHF 6'500'000" },
  { value: 7000000, label: "CHF 7'000'000" },
  { value: 7500000, label: "CHF 7'500'000" },
  { value: 8000000, label: "CHF 8'000'000" },
  { value: 8500000, label: "CHF 8'500'000" },
  { value: 9000000, label: "CHF 9'000'000" },
  { value: 9500000, label: "CHF 9'500'000" },
  { value: 10000000, label: "CHF 10'000'000" },
  { value: 15000000, label: "CHF 15'000'000" },
  { value: 20000000, label: "CHF 20'000'000" },
];

export const rentBudgetOptions: minMaxOption[] = [
  { value: 200, label: 'CHF 200' },
  { value: 400, label: 'CHF 400' },
  { value: 600, label: 'CHF 600' },
  { value: 800, label: 'CHF 800' },
  { value: 1000, label: "CHF 1'000" },
  { value: 1200, label: "CHF 1'200" },
  { value: 1400, label: "CHF 1'400" },
  { value: 1600, label: "CHF 1'600" },
  { value: 1800, label: "CHF 1'800" },
  { value: 2000, label: "CHF 2'000" },
  { value: 2000, label: "CHF 2'200" },
  { value: 2400, label: "CHF 2'400" },
  { value: 2600, label: "CHF 2'600" },
  { value: 2800, label: "CHF 2'800" },
  { value: 3000, label: "CHF 3'000" },
  { value: 3200, label: "CHF 3'200" },
  { value: 3400, label: "CHF 3'400" },
  { value: 3600, label: "CHF 3'600" },
  { value: 3800, label: "CHF 3'800" },
  { value: 4000, label: "CHF 4'000" },
  { value: 4500, label: "CHF 4'500" },
  { value: 5000, label: "CHF 5'000" },
  { value: 5500, label: "CHF 5'500" },
  { value: 6000, label: "CHF 6'000" },
  { value: 6500, label: "CHF 6'500" },
  { value: 7000, label: "CHF 7'000" },
  { value: 7500, label: "CHF 7'500" },
  { value: 8000, label: "CHF 8'000" },
  { value: 8500, label: "CHF 8'500" },
  { value: 9000, label: "CHF 9'000" },
  { value: 9500, label: "CHF 9'500" },
  { value: 10000, label: "CHF 10'000" },
  { value: 12500, label: "CHF 12'500" },
  { value: 15000, label: "CHF 15'000" },
  { value: 17500, label: "CHF 17'500" },
  { value: 20000, label: "CHF 20'000" },
  { value: 22500, label: "CHF 22'500" },
  { value: 25000, label: "CHF 25'000" },
  { value: 27500, label: "CHF 27'500" },
  { value: 30000, label: "CHF 30'000" },
  { value: 40000, label: "CHF 40'000" },
  { value: 50000, label: "CHF 50'000" },
];

export const surfaceOptions: minMaxOption[] = [
  {
    value: 10,
    label: '10 m²',
  },
  {
    value: 20,
    label: '20 m²',
  },
  {
    value: 30,
    label: '30 m²',
  },
  {
    value: 40,
    label: '40 m²',
  },
  {
    value: 50,
    label: '50 m²',
  },
  {
    value: 100,
    label: '100 m²',
  },
  {
    value: 150,
    label: '150 m²',
  },
  {
    value: 200,
    label: '200 m²',
  },
  {
    value: 250,
    label: '250 m²',
  },
  {
    value: 300,
    label: '300 m²',
  },
  {
    value: 350,
    label: '350 m²',
  },
  {
    value: 400,
    label: '400 m²',
  },
  {
    value: 450,
    label: '450 m²',
  },
  {
    value: 500,
    label: '500 m²',
  },
  {
    value: 600,
    label: '600 m²',
  },
  {
    value: 700,
    label: '700 m²',
  },
  {
    value: 800,
    label: '800 m²',
  },
  {
    value: 900,
    label: '900 m²',
  },
  {
    value: 1000,
    label: '1000 m²',
  },
  {
    value: 1500,
    label: '1500 m²',
  },
  {
    value: 2000,
    label: '2000 m²',
  },
  {
    value: 2500,
    label: '2500 m²',
  },
  {
    value: 3000,
    label: '3000 m²',
  },
];

export const roomsOptions: minMaxOption[] = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 7,
    label: '7',
  },
  {
    value: 8,
    label: '8',
  },
];

export const bedroomsOptions: minMaxOption[] = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
];

export const bathroomsOptions: minMaxOption[] = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
];

export const floorsOptions: minMaxOption[] = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 7,
    label: '7',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 9,
    label: '9',
  },
  {
    value: 11,
    label: '11',
  },
  {
    value: 12,
    label: '12',
  },
  {
    value: 13,
    label: '13',
  },
  {
    value: 14,
    label: '14',
  },
  {
    value: 15,
    label: '15',
  },
  {
    value: 16,
    label: '16',
  },
  {
    value: 17,
    label: '17',
  },
  {
    value: 18,
    label: '18',
  },
  {
    value: 19,
    label: '19',
  },
  {
    value: 20,
    label: '20',
  },
];

export const preferences: { name: string; label: string }[] = [
  {
    name: 'mountainView',
    label: 'Mountain view',
  },
  {
    name: 'seaView',
    label: 'Sea view',
  },
  {
    name: 'balcony',
    label: 'Balcony',
  },
  {
    name: 'lakeView',
    label: 'Lake view',
  },
  {
    name: 'terrace',
    label: 'Terrace',
  },
  {
    name: 'lift',
    label: 'Lift',
  },
  {
    name: 'parking',
    label: 'Parking',
  },
  {
    name: 'garage',
    label: 'Garage',
  },
  {
    name: 'pool',
    label: 'Pool',
  },
];

export const types: IContactType[] = [
  {
    id: 1,
    keyname: 'owner',
    translations: {
      en: { name: 'owner', locale: 'en' },
    },
    choices: [
      {
        id: 1,
        keyname: 'seller',
        translations: {
          en: { name: 'broker', locale: 'en' },
        },
      },
      {
        id: 2,
        keyname: 'renter',
        translations: {
          en: { name: 'renter', locale: 'en' },
        },
      },
    ],
  },
  {
    id: 2,
    keyname: 'client',
    translations: {
      en: { name: 'client', locale: 'en' },
    },
    choices: [
      {
        id: 3,
        keyname: 'buyer',
        translations: {
          en: { name: 'buyer', locale: 'en' },
        },
      },
      {
        id: 4,
        keyname: 'tenant',
        translations: {
          en: { name: 'tenant', locale: 'en' },
        },
      },
    ],
  },
  {
    id: 1,
    keyname: 'external',
    translations: {
      en: { name: 'external', locale: 'en' },
    },
    choices: [
      {
        id: 5,
        keyname: 'broker',
        translations: {
          en: { name: 'broker', locale: 'en' },
        },
      },
      {
        id: 6,
        keyname: 'agent',
        translations: {
          en: { name: 'agent', locale: 'en' },
        },
      },
      {
        id: 7,
        keyname: 'notary',
        translations: {
          en: { name: 'notary', locale: 'en' },
        },
      },
      {
        id: 8,
        keyname: 'lawyer',
        translations: {
          en: { name: 'lawyer', locale: 'en' },
        },
      },
      {
        id: 9,
        keyname: 'constructor',
        translations: {
          en: { name: 'constructor', locale: 'en' },
        },
      },
    ],
  },
];
