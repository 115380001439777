import axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from 'axios';
import { initialState, Tokens, useAuth } from '../../hooks/useAuth';
import process from 'process';
import { captureException } from '@sentry/react';
import { isDevEnv } from '../../const';

const baseURL = process.env.REACT_APP_API_ROOT;

export const isHttpError = (error: unknown): error is AxiosError =>
  axios.isAxiosError(error);

export type HttpError = AxiosError;

export const http: AxiosInstance = axios.create();

let tokens = useAuth.getState().tokens;
const logout = useAuth.getState().logout;

// fires synchronously on every change

useAuth.subscribe((state) => {
  tokens = state.tokens;
});

http.interceptors.request.use((config) => {
  config.headers = {
    Accept: 'application/json, application/ld+json',
    'Content-Type': 'application/json',
  };

  config.baseURL = baseURL;

  if (tokens?.token && config.url !== '/login') {
    config.headers.Authorization = `Bearer ${tokens.token}`;
  }

  return config;
});

// refresh token

interface Error extends AxiosError<{ [key: string]: string }> {
  config: AxiosRequestConfig & {
    _retry?: boolean;
  };
}

const refreshTokens = () =>
  axios.post<Tokens>(`${baseURL}/token/refresh`, {
    refresh_token: tokens?.refresh_token,
  });

http.interceptors.response.use(
  (response) => response,

  async (error: Error) => {
    if (isDevEnv) {
      const res = error?.response?.data;
      if (res) {
        console.error(res);
      }
    }

    // Capture only interval server error
    if (error.response?.status === 500) {
      captureException(error);
    }

    const originalRequest = error.config;
    const tokenIsExpired = error?.response?.status === 401;
    if (!originalRequest._retry && tokenIsExpired) {
      // Avoid Infinite loop
      originalRequest._retry = true;
      try {
        const { data: newTokens } = await refreshTokens();
        useAuth.setState({ tokens: newTokens });
        return http(originalRequest);
      } catch (e: unknown) {
        // Refresh token should be expired
        useAuth.setState(initialState);
        return Promise.reject(e);
      }
    }

    if (error.response?.status === 463) {
      logout();
    }

    return Promise.reject(error);
  }
);
