import { ValidationSchema } from '../app/providers/stepper';

export const initialValues: ValidationSchema = {
  status: {
    value: '',
  },
  agency: {
    value: '',
  },
  availability: { value: '' },
  category: { value: '' },
  subCategory: { value: '' },
  Ref: { value: '' },
  broker: { value: '' },
  responsableRate: { value: '' },
  intermediaireRate: { value: '' },
  brokerRate: { value: '' },
  intermediateBrokerRate: { value: '' },
};
