import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from 'react-beautiful-dnd';
import DragItem from './item';
import { SectionItem } from '../popup';
import { useStrictMode } from '../../../../../hooks/useStrictMode';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  itemsActive: SectionItem[];
  itemsHidden: SectionItem[];
  id: string;
  onDragEnd: (
    listId: string,
    items: {
      active_widgets: SectionItem[];
      hidden_widgets: SectionItem[];
    }
  ) => void;
}

const reorder = (list: SectionItem[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  if (removed) {
    result.splice(endIndex, 0, removed);
  }
  return result;
};

const move = (
  source: Iterable<unknown> | ArrayLike<unknown>,
  destination: Iterable<unknown> | ArrayLike<unknown>,
  droppableSource: { index: number; droppableId: string | number },
  droppableDestination: { index: number; droppableId: string | number }
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result: {
    [key: string]: any;
  } = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const DraggableList = (props: Props) => {
  const { t } = useTranslation('common');
  const [items, setItems] = useState({
    active_widgets: props.itemsActive,
    hidden_widgets: props.itemsHidden,
  });

  const onWidthChange = (name: string, value: boolean) => {
    const returned = {
      active_widgets: [...items.active_widgets], // Create a shallow copy to avoid direct mutation
      hidden_widgets: [...items.hidden_widgets],
    };

    const itemIndex = items.active_widgets.findIndex((el) => el.name === name);

    if (itemIndex >= 0 && items.active_widgets[itemIndex]) {
      // @ts-ignore
      returned.active_widgets[itemIndex] = {
        ...returned.active_widgets[itemIndex], // Ensure you're not mutating the object
        wideWidget: value,
      };
    }

    setItems(returned);

    if (props.onDragEnd) {
      props.onDragEnd(props.id, returned);
    }
  };

  const onDragEnd: OnDragEndResponder = (result) => {
    const { source, destination } = result;
    let returned = {
      active_widgets: items.active_widgets,
      hidden_widgets: items.hidden_widgets,
    };

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const reorderedItems = reorder(
        items[source.droppableId as 'active_widgets' | 'hidden_widgets'],
        source.index,
        destination.index
      );

      returned[destination.droppableId as 'active_widgets' | 'hidden_widgets'] =
        reorderedItems;
    } else {
      // @ts-ignore
      returned = move(
        items[source.droppableId as 'active_widgets' | 'hidden_widgets'],
        items[destination.droppableId as 'active_widgets' | 'hidden_widgets'],
        source,
        destination
      );
    }

    setItems(returned);

    if (props.onDragEnd) {
      props.onDragEnd(props.id, returned);
    }
  };

  // https://github.com/atlassian/react-beautiful-dnd/issues/2396
  const [enableDropable] = useStrictMode(false);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {enableDropable ? (
        <>
          <div className="text-neutral-500 text-sm font-normal mb-6 leading-none">
            {t('ActiveWidgets')}
          </div>
          <Droppable droppableId={'active_widgets'}>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {items.active_widgets.map((item, index) => (
                  <Draggable
                    key={item.name}
                    draggableId={item.name}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                        }}
                      >
                        <DragItem
                          isDragging={snapshot.isDragging}
                          item={item}
                          onWidthChange={onWidthChange}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <div className="text-neutral-500 text-sm font-normal mt-8 mb-6 leading-none">
            {t('HiddenWidgets')}
          </div>
          <Droppable droppableId={'hidden_widgets'}>
            {(provided) => (
              <div
                style={{
                  minHeight: '57px',
                  backgroundColor: items.hidden_widgets.length
                    ? 'transparent'
                    : '#F5F5F7',
                  borderRadius: items.hidden_widgets.length ? '0' : '8px',
                }}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {items.hidden_widgets.map((item, index) => (
                  <Draggable
                    key={item.name}
                    draggableId={item.name}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                        }}
                      >
                        <DragItem
                          isHiddenWidget
                          isDragging={snapshot.isDragging}
                          item={item}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </>
      ) : null}
    </DragDropContext>
  );
};

export default DraggableList;
