import { useCallback, useEffect } from 'react';
import { FieldValues, UseFormProps, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useBeforeUnload, useBlocker } from 'react-router-dom';
import Swal from 'sweetalert2';

/**
 *  Adds confirmation popup when trying to leave form after making some changes. Based on `react-hook-form`
 *
 * @param props - Same props you normally pass to `useForm`
 * @param successfulSaveIndicator -  It is necessary to stop showing confirmation after successful save.
 *  You will have to pass there any value that changes when form is successfully sent to server.
 *  For example in stepper forms it's `serverData` prop, for contact forms it's `data`
 * @returns methods same methods you would get from `useForm`
 */
export function useProtectedForm<T extends FieldValues>(
  props?: UseFormProps<T>,
  successfulSaveIndicator?: unknown,
  options?: { ignoreFields?: string[] }
) {
  const methods = useForm<T>(props);

  const blocker = useBlocker(({ nextLocation }) => {
    const forceHideBlocker = Boolean(
      nextLocation.state?.forceHideConfirmationPopup
    );
    const dirtyFields = Object.keys(methods.formState.dirtyFields);
    const filteredDirtyFields = dirtyFields.filter(
      (field) => !options?.ignoreFields?.includes(field)
    );
    const isDirty = filteredDirtyFields.length > 0;
    return !forceHideBlocker && isDirty;
  });

  const { t } = useTranslation('alerts');
  const confirmLeave = useCallback(async () => {
    if (blocker.state === 'blocked') {
      const { isConfirmed } = await Swal.fire({
        text: t('are_you_sure_you_want_to_leave'),
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: 'OK',
        cancelButtonText: t('common:Cancel'),
        showClass: {
          popup: 'block',
        },
        hideClass: {
          popup: 'hidden',
        },
      });
      if (isConfirmed) {
        blocker.proceed();
      } else {
        blocker.reset();
      }
    }
  }, [blocker, t]);

  useEffect(() => {
    confirmLeave();
  }, [confirmLeave]);

  useBeforeUnload(confirmLeave);

  const { reset } = methods;
  useEffect(
    () => reset({} as T, { keepValues: true }),
    [successfulSaveIndicator, reset]
  );

  return methods;
}
