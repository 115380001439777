import PhoneInput from 'react-phone-input-2';

import './formattedPhone.css';

function FormattedPhone({
  phone,
  horizontalAlignment = 'start',
}: {
  phone?: string;
  horizontalAlignment?: 'start' | 'center' | 'end';
}) {
  let textAlign: 'left' | 'center' | 'right' = 'left';
  if (horizontalAlignment === 'center') {
    textAlign = 'center';
  } else if (horizontalAlignment === 'end') {
    textAlign = 'right';
  }
  return phone ? (
    <div className={`formatted-phone formatted-phone--${textAlign}`}>
      <PhoneInput value={phone} />
    </div>
  ) : null;
}

export default FormattedPhone;
