import ChoiceButton from './choiceButton';

function Select({
  options,
  value,
  onChange,
}: {
  options: { label: string; value: string; img?: string }[];
  value?: string;
  onChange: (value: string) => void;
}) {
  return (
    <div className="flex gap-4">
      {options.map((option) => {
        const isSelected = value === option.value;
        const handleClick = () => {
          if (!isSelected) {
            onChange(option.value);
          }
        };
        return (
          <ChoiceButton
            key={option.value}
            img={
              option.img ? (
                <img
                  src={option.img}
                  alt=""
                  loading="lazy"
                  className="absolute -top-2 -left-2 w-8 h-8 rounded-full max-w-none object-cover"
                />
              ) : null
            }
            isActive={isSelected}
            onClick={handleClick}
          >
            {option.label}
          </ChoiceButton>
        );
      })}
    </div>
  );
}

export default Select;
