import { PipelineColumns, useStepsHash, useStepsState } from './useStepsState';
import { useEffect } from 'react';
import { DragDropContext, OnDragEndResponder } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import {
  PipelineStepTypes,
  PipelineSteps,
} from '../../../../../types/pipelines';
import EditStepsColumn from './column';
import { useStrictMode } from '../../../../../hooks/useStrictMode';

function EditSteps({ defaultSteps }: { defaultSteps?: PipelineSteps | null }) {
  const { reset, columns, setColumns, moveStep } = useStepsState();
  useEffect(() => {
    reset();
    if (defaultSteps) {
      setColumns(prepareSteps(defaultSteps));
    }
  }, [defaultSteps, reset, setColumns]);

  const stepsHash = useStepsHash();
  const handleDragEnd: OnDragEndResponder = async ({
    destination,
    source,
    draggableId,
  }) => {
    const step = stepsHash[draggableId];
    if (step && source && destination) {
      moveStep(
        step,
        source.droppableId as PipelineStepTypes,
        destination.droppableId as PipelineStepTypes,
        destination.index
      );
    }
  };

  const [enableDropable] = useStrictMode(false);
  const { t } = useTranslation('common');

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      {enableDropable && (
        <div className="grid grid-cols-3 divide-x divide-gray-200 -mx-8">
          <EditStepsColumn
            title={t('entrance_step')}
            steps={columns[PipelineStepTypes.ENTRANCE]}
            column={PipelineStepTypes.ENTRANCE}
            minSteps={1}
            maxSteps={1}
          />
          <EditStepsColumn
            title={t('steps')}
            steps={columns[PipelineStepTypes.REGULAR]}
            column={PipelineStepTypes.REGULAR}
            allowOfferStep
          />
          <EditStepsColumn
            title={t('close_steps')}
            steps={columns[PipelineStepTypes.CLOSING]}
            column={PipelineStepTypes.CLOSING}
            allowOfferStep
            minSteps={1}
          />
        </div>
      )}
    </DragDropContext>
  );
}

export default EditSteps;

function prepareSteps(steps: PipelineSteps) {
  const result: PipelineColumns = {
    [PipelineStepTypes.ENTRANCE]: [],
    [PipelineStepTypes.REGULAR]: [],
    [PipelineStepTypes.CLOSING]: [],
  };
  for (const [column, columnSteps] of Object.entries(steps)) {
    for (const step of columnSteps) {
      result[column as PipelineStepTypes].push({
        id: step.id as number,
        serverId: step.id as number,
        name: step.name,
        color: step.color,
        isOfferStep: step.isOfferStep,
      });
    }
  }
  return result;
}
