import { useMemo } from 'react';
import { DataGrid, DataGridProps, deDE, enUS, frFR } from '@mui/x-data-grid';
import {
  deDE as coredeDE,
  enUS as coreenEN,
  frFR as corefrFR,
} from '@mui/material/locale';
import { Box, createTheme, ThemeProvider } from '@mui/material';

import useLocale from '../../hooks/useLocale';
import { TableGridXStyling } from './tableGridXStyling';
import { useThemeMode } from '../../hooks/useThemeMode';
import { appTheme } from '../material-ui/theme';

function TableGridX({
  customTableStyle,
  ...rest
}: DataGridProps & {
  customTableStyle?: { [key: string]: any };
}) {
  const { locale } = useLocale();
  const [lang, coreLang] =
    locale == 'fr'
      ? [frFR, corefrFR]
      : locale == 'de'
      ? [deDE, coredeDE]
      : [enUS, coreenEN];

  const { mode } = useThemeMode();
  const theme = useMemo(() => createTheme(appTheme(mode)), [mode]);

  const tableGridTheme = createTheme(theme, lang, coreLang);
  return (
    <ThemeProvider theme={tableGridTheme}>
      <Box sx={customTableStyle ? customTableStyle : TableGridXStyling}>
        <DataGrid
          componentsProps={componentProps}
          disableSelectionOnClick
          disableVirtualization
          pageSize={10}
          {...rest}
        />
      </Box>
    </ThemeProvider>
  );
}

export default TableGridX;

const componentProps = {
  panel: {
    placement: 'bottom-end',
  },
};
