import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import EditorSidebarSection from '../sidebarSection';
import Sheet from '../sheet';
import {
  usePagesEditorComputables,
  usePagesEditor,
} from '../../lib/hooks/usePagesEditor';
import Mockup from '../mockup';

export const gridTemplateAreasOptions = [
  `'a a' 'a a' 'a a' 'a a'`,
  `'a a' 'a a' 'b b' 'b b'`,
  `'a a' 'a a' 'b c' 'b c'`,
  `'a b' 'a b' 'c c' 'c c'`,
  `'a b' 'a b' 'c d' 'c d'`,
  `'a a' 'b b' 'b b' 'c c'`,
  `'a a' 'b c' 'b c' 'd d'`,
  `'a b' 'c d' 'e f' 'g h'`,
  `'a b' 'a c' 'd d' 'd d'`,
  `'a b' 'c b' 'd d' 'd d'`,
  `'a a' 'b b' 'b b' 'b b'`,
  `'a b' 'a c' 'a d' 'a e'`,
  `'a b' 'a b' 'a b' 'a b'`,
  `'a b' 'c b' 'd b' 'e b'`,
  `'a a' 'b b' 'c c' 'd d'`,
  `'a b' 'c b' 'c d' 'e e'`,
  `'a b' 'c c' 'd e' 'f f'`,
  `'a a' 'b c' 'd e' 'f f'`,
  `'a a' 'b c' 'd d' 'e f'`,
  `'a b' 'c d' 'e e' 'f f'`,
];

const MockupSelection = () => {
  const { editedAreaId, setPageMockup } = usePagesEditor();
  const { editedPage } = usePagesEditorComputables();

  const { t } = useTranslation('brochureEditor');

  return (
    <>
      {editedPage && !editedAreaId && (
        <EditorSidebarSection>
          <div className="pt-4 grid gap-4">
            <div className="text-[14px] leading-[16px] font-medium">
              {t('mockup')}
            </div>
            <div className="grid grid-cols-5 gap-4">
              {gridTemplateAreasOptions.map((option, index) => (
                <button
                  key={option}
                  className={classNames('border cursor-pointer', {
                    'border-gray-200 text-[rgba(255,0,0,0.1)] hover:border-gray-300':
                      option !== editedPage.mockup,
                    'border-blue text-blue': option === editedPage.mockup,
                  })}
                  onClick={() => setPageMockup(editedPage.id, option)}
                  title={t('option_no', { option: index + 1 })}
                >
                  <Sheet>
                    <Mockup
                      page={editedPage}
                      gridTemplateAreas={option}
                      renderCell={() => <div className="grid bg-current"></div>}
                    ></Mockup>
                  </Sheet>
                </button>
              ))}
            </div>
          </div>
        </EditorSidebarSection>
      )}
    </>
  );
};
export default MockupSelection;
