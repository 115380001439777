import React, { useState } from 'react';
import WidgetWrapper from './wrapper';
import { WidgetProps } from '../index';
import { Link } from 'react-router-dom';
import PriceIcon from '../../../features/icons/priceIcon';
import { IDashboardOfferItemList } from '../../../types/user';
import { useTranslation } from 'react-i18next';
import OffersPopup from '../components/offersPopup';
import { formatPriceWithCurrency } from '../utils/useOffersListColumns';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Tooltip } from '@mui/material';
export function getInitials(name: string): string {
  const words = name.split(' ');
  const initials = words.map((word) => word.charAt(0).toUpperCase());
  return initials.join('');
}

const Item = (props: { item: IDashboardOfferItemList }) => {
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:900px)');
  const handleTouchStart = () => {
    if (isMobile) {
      setTimeout(() => setOpen(true), 10);
    }
  };

  const handleTouchEnd = () => {
    if (isMobile) {
      setTimeout(() => setOpen(false), 100);
    }
  };
  const { item } = props;

  return (
    <div className="self-stretch pb-2 border-b border-gray-200 justify-start items-center gap-2 md:gap-4 inline-flex">
      <Link
        className={
          'flex w-16 md:w-24 text-blue-800 text-sm font-medium leading-none'
        }
        to={`/properties/${item.id}`}
      >
        <span>{item.reference}</span>
      </Link>
      <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex overflow-y-hidden">
        <div className="h-6 p-1 bg-neutral-100 rounded-[100px] justify-center items-center gap-2 flex">
          <div className="text-zinc-900 text-xs font-normal font-['Inter'] leading-none">
            {getInitials(item.name)}
          </div>
        </div>
        <Tooltip
          title={item.name}
          arrow
          placement="top"
          onClick={handleTouchStart}
          open={open}
          onOpen={handleTouchStart}
          onClose={handleTouchEnd}
        >
          <div className="grow shrink basis-0 text-zinc-900 text-sm font-medium font-['Inter'] pb-0.5 leading-none whitespace-nowrap overflow-hidden overflow-ellipsis">
            {item.name}
          </div>
        </Tooltip>
      </div>
      <div className="grow shrink basis-0 h-4 justify-start items-start gap-2 flex">
        <div className="hidden md:flex w-4 h-4 relative opacity-30">
          <PriceIcon />
        </div>
        <div className="grow shrink basis-0 text-zinc-900 text-sm font-normal leading-none">
          {formatPriceWithCurrency(item.price, item.currency)}
        </div>
      </div>
    </div>
  );
};

const OffersWidget: React.FC<WidgetProps> = (props) => {
  const { data } = props;
  const { t } = useTranslation('common');
  const [openModal, setOpenModal] = useState(false);

  return (
    <WidgetWrapper
      emptyData={!data['recent-offers']?.length}
      title={'MyOffers'}
      linkText={'ViewAll'}
      link={null}
      onButtonClick={() => setOpenModal(true)}
    >
      <div className="w-full flex-col justify-center items-start gap-2 inline-flex">
        <div className="self-stretch pb-2 border-b border-gray-200 justify-start items-start md:gap-4 inline-flex">
          <div className="w-24 text-zinc-900 text-sm font-medium font-['Inter'] leading-none">
            {t('Reference')}
          </div>
          <div className="grow shrink basis-0 text-zinc-900 text-sm font-medium font-['Inter'] leading-none">
            {t('Name')}
          </div>
          <div className="grow shrink basis-0 h-4 justify-start items-start gap-2 flex">
            <div className="grow shrink basis-0 text-zinc-900 text-sm font-medium font-['Inter'] leading-none">
              {t('Price')}
            </div>
          </div>
        </div>
        {data['recent-offers']?.map((item, index) => (
          <Item key={index} item={item} />
        ))}
      </div>

      <OffersPopup
        title={'Offers'}
        openModal={openModal}
        onClose={() => setOpenModal(false)}
      />
    </WidgetWrapper>
  );
};

export default OffersWidget;
