import classNames from 'classnames';
import React, { useState } from 'react';
import { SectionItem } from '../popup';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useTranslation } from 'react-i18next';
import { Switch } from '@mui/material';
import { switchStylesBlue } from '../../../../properties/stepper/steps/media/rotateImage';

export const tabNames = (name: string) => {
  const names: Record<string, string> = {
    profile: 'MyProfile',
    agency: 'MyAgency',
    'recent-leads': 'MyRecentLeads',
    properties: 'MyProperties',
    'mls-requests': 'MLSRequests',
    'lead-source': 'Lead_source',
    sales: 'MySales',
    offers: 'Offers',
    'properties-sold': 'PropertiesSold',
    'agency-recent-leads': 'MyAgencyRecentLeads',
    'agency-lead-source': 'Agency_Lead_source',
    'pipelines-stats': 'leads_progression',
  };
  return names[name];
};

const DragItem = (props: {
  isDisabled?: boolean;
  isHiddenWidget?: boolean;
  onWidthChange?: (name: string, value: boolean) => void;
  isDragging: boolean;
  item: SectionItem;
}) => {
  const { item, isDragging, isDisabled, isHiddenWidget, onWidthChange } = props;
  const { t } = useTranslation('common');
  const [wide, setWide] = useState(item.wideWidget);

  const onSetWide = () => {
    setWide(!wide);

    if (onWidthChange) {
      onWidthChange(item.name, !wide);
    }
  };

  return (
    <div
      className={classNames(
        'group bg-white border-b border-lightgray px-2 flex items-center justify-between',
        {
          'border-blue drop-shadow-lg': isDragging,
          'border-lightgray active:drop-shadow': !isDragging,
          'opacity-50 user-select-none': isDisabled,
          'py-2': item.name === 'agency-recent-leads',
          'py-4': item.name !== 'agency-recent-leads',
        }
      )}
    >
      <div className={'flex items-center'}>
        <div
          className={classNames(
            'mr-4 group-hover:opacity-50 group-active:opacity-90',
            {
              'text-blue': isDragging,
            }
          )}
        >
          <DragIndicatorIcon fontSize={'small'} />
        </div>
        <p className={'font-medium'}>{t(`${tabNames(item.name)}`)}</p>
      </div>
      {item.name === 'agency-recent-leads' || item.name === 'recent-leads' ? (
        <div
          className={classNames(
            'relative flex gap-2 items-center bg-gray-400 rounded py-2 px-4 bg-opacity-10',
            {
              'opacity-50 pointer-event-none': isHiddenWidget,
            }
          )}
        >
          <p className={'font-medium opacity-70 text-xs'}>
            {t(wide ? 'Large' : 'Small')}
          </p>
          <Switch sx={switchStylesBlue} checked={wide} onChange={onSetWide} />
        </div>
      ) : null}
    </div>
  );
};

export default DragItem;
