import { FC, ReactElement } from 'react';

const TableItem: FC<{
  title: string;
  value?: string | null | Array<string>;
  link?: string;
  multiple?: boolean;
  color?: string;
  titleTag?: ReactElement | null;
}> = ({ title, value, link, color, titleTag }) => {
  let linkType = '';

  switch (link) {
    case 'email':
      linkType = 'mailto:';
      break;
    case 'phone':
      linkType = 'tel:';
      break;
  }

  return value ? (
    <div className={'grid grid-cols-12 divide-x'}>
      <div className={'col-start-1 col-end-4 p-4'}>
        <p>{title}</p>
        {titleTag}
      </div>
      <div className={'col-start-4 col-end-12 p-4'}>
        {Array.isArray(value) ? (
          <>
            {value.map((item) =>
              link ? (
                <a
                  key={item}
                  className={`transition-color duration-300 hover:text-blue mr-2 after:content-[','] hover:after:text-[#1D1D1F] last:after:hidden capitalize`}
                  href={`${linkType}${item}`}
                >
                  {item}
                </a>
              ) : (
                <div
                  key={item}
                  className={`mr-2 after:content-[','] last:after:hidden capitalize`}
                  dangerouslySetInnerHTML={{
                    __html: item,
                  }}
                />
              )
            )}
          </>
        ) : link ? (
          <a
            className={'transition-color duration-300 hover:text-blue'}
            href={`${linkType}${value}`}
          >
            {value}
          </a>
        ) : (
          <div
            style={{ color: color }}
            dangerouslySetInnerHTML={{
              __html: value,
            }}
          />
        )}
      </div>
    </div>
  ) : null;
};

export default TableItem;
