import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

function TerraceSurfIcon({ color, className }: IconProps) {
  const { mode } = useThemeMode();
  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        d="M4 .5H.5V4M15.5 4V.5H12M12 15.5h3.5V12M8 13V4.2M4.786 8.6h6.428M9.786 13v-1.2a1 1 0 011-1H13m0 0V13m0-2.2V6.4m-10 0v4.4M3 13v-2.2m0 0h2.214a1 1 0 011 1V13m-2.5-8.8l1.233-1.266a1 1 0 01.468-.27l2.336-.6a1 1 0 01.497 0l2.337.6a1 1 0 01.468.27L12.285 4.2h-8.57zM.5 12v3.5H4"
      ></path>
    </svg>
  );
}

export default TerraceSurfIcon;
