import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { IContact } from '../../../../../../../types/contacts';
import { HttpError } from '../../../../../../../libs/axios';
import PhoneIcon from '../../../../../../../features/icons/phoneIcon';
import EmailIcon from '../../../../../../../features/icons/emailIcon';
import { renderFullPropertyAdress } from '../../../../../../../helpers/utils';
import LocationIcon from '../../../../../../../features/icons/locationIcon';
import { Link } from 'react-router-dom';
import { Box, Button, Skeleton } from '@mui/material';
import ContactIcon from '../../../../../../../features/icons/contactIcon';

const ContactPopupInfo = ({ id }: { id?: number }) => {
  const { t } = useTranslation('propertiesPage');
  const { data } = useSWR<IContact, HttpError>(`/contacts/${id}`);

  return (
    <div>
      {data ? (
        <div>
          {data?.individual?.phone || data?.company?.phone ? (
            <div className="flex justify-start items-center gap-4 py-4 border-gray-200 border-t border-b">
              <PhoneIcon color="#1D1D1F" />
              <p>{data?.individual?.phone || data?.company?.phone}</p>
            </div>
          ) : null}
          {data?.individual?.email || data?.company?.email ? (
            <div className="flex justify-start items-center gap-4 py-4">
              <EmailIcon />
              <p>{data?.individual?.email || data?.company?.email}</p>
            </div>
          ) : null}
          {renderFullPropertyAdress(
            data?.individual || data?.company || { address: null }
          ) ? (
            <div className="flex justify-start items-center gap-4 py-4 border-gray-200 border-t border-b">
              <LocationIcon />
              <p>
                {renderFullPropertyAdress(
                  data?.individual || data?.company || { address: null }
                )}
              </p>
            </div>
          ) : null}
        </div>
      ) : (
        <div className={'flex flex-col'}>
          {[1, 2, 3].map((el) => (
            <div
              key={el}
              className="flex justify-start items-center gap-4 py-4 border-gray-200 border-t border-b"
            >
              <Skeleton width={'16px'} height={'27px'} />
              <Skeleton width={'100%'} height={'27px'} />
            </div>
          ))}
        </div>
      )}
      <Link to={`/contacts/${data?.id}/contact-information`}>
        <Button
          variant="outlined"
          className="flex justify-center items-center"
          disableElevation
          sx={{
            padding: '12px 16px',
            marginTop: '32px',
          }}
        >
          <Box className="flex justify-center items-center" gap="16px">
            <ContactIcon color="#3446B9" />

            <p className="text-blue text-sm leading-4"> {t('View_contact')} </p>
          </Box>
        </Button>
      </Link>
    </div>
  );
};

export default ContactPopupInfo;
