import { Tooltip } from '@mui/material';
import { abbreviate, fullName } from '../../../helpers/utils';
import { IContact } from '../../../types/contacts';

function RelatedAgent({
  contact,
  styleCard,
}: {
  contact?: IContact | null;
  styleCard?: boolean;
}) {
  return (
    <div className={'flex items-center gap-1 w-full'}>
      {contact?.agentInCharge ? (
        // todo use contactCircle
        <div
          className={` ${
            styleCard ? ' bg-blue-700 text-white' : 'bg-gray-100'
          } w-[24px] h-[24px] rounded-full overflow-hidden flex items-center justify-center`}
        >
          {contact?.agentInCharge.avatar ? (
            <img
              src={contact.agentInCharge.avatar}
              alt={`${contact.agentInCharge.firstname} ${contact.agentInCharge.lastname}`}
            />
          ) : (
            <>
              {abbreviate([
                contact?.agentInCharge.firstname,
                contact?.agentInCharge.lastname,
              ])}
            </>
          )}
        </div>
      ) : null}
      <Tooltip
        title={
          contact?.agentInCharge &&
          fullName(
            contact.agentInCharge.firstname,
            contact.agentInCharge.lastname
          )
        }
        arrow
        placement="top"
      >
        <span
          className={`${
            styleCard ? 'text-sm leading-4' : '!text-[12px]'
          }  2xl:text-[16px] font-medium font-[Inter] block truncate w-[74%]`}
        >
          {contact?.agentInCharge &&
            fullName(
              contact.agentInCharge.firstname,
              contact.agentInCharge.lastname
            )}
        </span>
      </Tooltip>
    </div>
  );
}

export default RelatedAgent;
