import { useState } from 'react';
import classNames from 'classnames';
import IndividualView from './individual-view';
import CompanyView from './company-view';
import { useTranslation } from 'react-i18next';

const TabButton = (props: {
  active: boolean;
  title: string;
  onClick: () => void;
}) => {
  const { active, title, onClick } = props;
  return (
    <button
      className={classNames(
        'py-3 px-4 rounded border min-w-[200px] text-left group',
        {
          'border-blue bg-blue text-white pointer-events-none': active,
          'border-gray-900': !active,
        }
      )}
      onClick={onClick}
    >
      <span
        className={classNames(
          'flex group-hover:opacity-80 group-hover:translate-x-0.5',
          {
            'opacity-60 transition-all duration-300': !active,
          }
        )}
      >
        {title}
      </span>
    </button>
  );
};

const ContactCreator = () => {
  const [individualType, toggleIndividualType] = useState(true);
  const { t } = useTranslation('common');
  return (
    <>
      <div className={'bg-white p-16'}>
        <p className={'text-[12px] opacity-50 mb-4'}> {t('Business_type')} </p>
        <div className={'flex items-center gap-4 pb-8 border-b mb-16'}>
          <TabButton
            title={t('Individual')}
            active={individualType}
            onClick={() => toggleIndividualType(true)}
          />
          <TabButton
            title={t('Company')}
            active={!individualType}
            onClick={() => toggleIndividualType(false)}
          />
        </div>
        {individualType ? (
          <IndividualView isEditMode={false} />
        ) : (
          <CompanyView isEditMode={false} />
        )}
      </div>
    </>
  );
};

export default ContactCreator;
