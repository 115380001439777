import SelectFormField from '../../../../../components/form/selectFormField';
import { useTranslation } from 'react-i18next';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Button, Fade, SelectChangeEvent } from '@mui/material';
import Modal from '../../../../../components/modal';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIsRent } from '../../../../../hooks/useIsRent';
import SpinnerLoading from '../../../../../features/spinner/spinnerLoading';
import classNames from 'classnames';
import CheckedIcon from '../../../../../features/icons/checkedIcon';
import { PropertyStatuses } from '../../../../../types/property';

interface Props {
  statusOptions: any;
  getValues: any;
  setValue: any;
  onSubmit: any;
  id?: number;
  serverData?: any;
  isLoading?: boolean;
}

const StatusSelect = (props: Props) => {
  const {
    statusOptions,
    getValues,
    setValue,
    id,
    onSubmit,
    serverData,
    isLoading,
  } = props;
  const [openModal, setOpenModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<string>('');
  const [status, setStatus] = useState(getValues('status'));
  const { t } = useTranslation('propertiesPage');
  const navigate = useNavigate();
  const isRent = useIsRent(serverData);

  const onLocalChange = (e: SelectChangeEvent<unknown>) => {
    setSelectedStatus(e.target.value as string);
    // if default value is sold/rent > changing to anything else
    // if default value is whatever > changing to sold/rent
    if (
      e.target.value === PropertyStatuses.SOLD ||
      e.target.value === PropertyStatuses.RENT ||
      getValues('status') === PropertyStatuses.SOLD ||
      getValues('status') === PropertyStatuses.RENT
    ) {
      setOpenModal(true);
    } else {
      setValue('status', e.target.value as string);
      setStatus(e.target.value as string);
    }
  };

  const onSave = () => {
    setValue('status', selectedStatus);

    if (
      selectedStatus === PropertyStatuses.SOLD ||
      selectedStatus === PropertyStatuses.RENT
    ) {
      setValue(
        `${
          selectedStatus === PropertyStatuses.SOLD
            ? 'propertyBuy'
            : 'propertyRent'
        }.salePriceSigned`,
        getValues(`${isRent ? 'pricingRent' : 'pricingBuy'}.displayedPrice`)
      );
    }

    setStatus(selectedStatus);
    onSubmit().then(() => {
      if (
        selectedStatus === PropertyStatuses.SOLD ||
        selectedStatus === PropertyStatuses.RENT
      ) {
        navigate(`/properties/${id}/edit/transaction-details`, {
          state: {
            forceHideConfirmationPopup: true,
          },
        });
      } else {
        setOpenModal(false);
      }
    });
  };

  const onClose = () => {
    if (!isLoading) {
      setSelectedStatus('');
      setOpenModal(false);
    }
  };

  return (
    <>
      <SelectFormField
        className="Status_property"
        isRequired
        label={t('common:Status')}
        options={statusOptions}
        defaultValue={getValues('status')}
        name={'status'}
        value={status}
        onChange={(e) => onLocalChange(e)}
      />

      <Modal
        sx={{
          transform: 'translate(5%, -5%)',
        }}
        boxClassName="pb-5 border rounded-0"
        CloseIconStyle={{
          cursor: 'pointer',
        }}
        isOpen={openModal}
        closeModal={onClose}
        openModal={() => setOpenModal(true)}
        buttonProps={{
          title: ' ',
          className: 'pr-6 text-sm leading-4 text-gray-900 hidden',
        }}
        withButton={false}
        closeIconSize="medium"
        title={
          <div>
            <p className="text-2xl font-normal inter leading-7 ml-10 mt-8">
              {t('Change_of_status')}
            </p>
          </div>
        }
        withTitleDivider={false}
        width={{
          md: '40%',
        }}
      >
        <ClickAwayListener onClickAway={onClose}>
          <Fade in={openModal}>
            <div
              className={classNames('px-10 relative', {
                '!opacity-50 pointer-events-none': isLoading,
              })}
            >
              <p className={'text-xl mb-2'}>
                {t('Are_you_sure_you_want_to_change_the_status')}
              </p>
              {selectedStatus === PropertyStatuses.SOLD ||
              selectedStatus === PropertyStatuses.RENT ? (
                <p className={'opacity-80'}>
                  {t(
                    'When_you_press_save_you_will_be_redirected_to_complete_Transaction_Details'
                  )}
                </p>
              ) : null}
              <div className="flex gap-4 justify-between items-start pt-8">
                <Button
                  sx={{ color: 'text.primary' }}
                  variant="outlined"
                  onClick={onClose}
                  classes={{
                    root: 'h-[47px]',
                  }}
                >
                  {t('common:cancel')}
                </Button>
                <Button
                  variant="contained"
                  disableElevation
                  onClick={onSave}
                  classes={{
                    root: 'flex justify-center items-center gap-4 bg-blue py-8 px-8 h-[47px]',
                  }}
                >
                  {isLoading ? <SpinnerLoading /> : t('usersPage:save')}
                  {!isLoading && <CheckedIcon color="currentcolor" />}
                </Button>
              </div>
            </div>
          </Fade>
        </ClickAwayListener>
      </Modal>
    </>
  );
};

export default StatusSelect;
