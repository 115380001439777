import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

function Star2Icon({
  color,
  className,
  width = '23',
  height = '23',
}: IconProps) {
  const { mode } = useThemeMode();

  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24"
    >
      <rect width="23" height="23" x="0.5" y="0.5" fill={color} rx="4"></rect>
      <path
        fill="#fff"
        d="M12 3.5l2.248 5.406 5.836.467-4.446 3.809 1.358 5.695L12 15.825l-4.996 3.052 1.358-5.695-4.446-3.809 5.836-.467L12 3.5z"
      ></path>
    </svg>
  );
}

export default Star2Icon;
