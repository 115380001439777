import { FilterPropertiesT } from './propertiesFilter';

export const initialContactMainFilter: FilterPropertiesT = {
  contact: [
    {
      id: 10,
      label: 'contactName',
      title: 'Contact name',
      type: 'listSearch',
      disableField: false,
      options: [],
    },
    {
      id: 12,
      label: 'departments',
      title: 'Department',
      type: 'list-hierarchy',
      disableField: false,
    },
    {
      id: 14,
      label: 'agents',
      title: 'Agent',
      type: 'list',
      disableField: false,
      options: [],
    },
  ],
  owner: [
    {
      id: 181,
      label: 'owner-types',
      title: 'Owner Types',
      type: 'list',
      disableField: false,
      options: [],
    },
  ],
  client: [
    {
      id: 183,
      label: 'client-types',
      title: 'Client types',
      type: 'list',
      options: [],
      disableField: false,
    },
  ],
  external: [
    {
      id: 121,
      label: 'external-types',
      title: 'external types',
      type: 'list',
      options: [],
      disableField: false,
    },
    {
      id: 122,
      label: 'constructor-types',
      title: 'Building constructor types',
      type: 'list',
      disableField: false,
      options: [],
    },
  ],
};

export const textFieldStyle = {
  '& .MuiInputBase-root , .MuiSelect-select': {
    borderRadius: '1px',
  },
};
