import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';

import { Pipeline } from '../../../../types/pipelines';
import { use404 } from '../../../../hooks/use404';
import TitleHeader from '../../../../components/common/titleHeader';
import EditPipelineForm from './form';
import { ucfirst } from '../../../../helpers/utils';

function EditPipeline() {
  const { id } = useParams();
  const {
    data: pipeline,
    error,
    mutate,
  } = useSWR<Pipeline>(id ? `/pipelines/${id}` : null);
  use404(error?.response?.status === 404);
  const { t } = useTranslation('common');
  const pageTitle = `${t(pipeline ? 'propertiesPage:edit' : 'createNew')} ${t(
    'pipeline'
  )}`;
  return (
    <TitleHeader title={ucfirst(pageTitle)} goBackLink="/settings/pipelines">
      {id && pipeline && (
        <EditPipelineForm pipeline={pipeline} mutate={mutate} />
      )}
      {!id && <EditPipelineForm pipeline={null} />}
    </TitleHeader>
  );
}

export default EditPipeline;
