import { create } from 'zustand';
import { http } from '../libs/axios';
import {
  categoryOptionType,
  getOptionsByLanguage,
} from '../const/propertiesOptions';
import { selectOptionStringT } from '../components/form/selectFormField';
import { Department } from '../types/hierarchy';

interface FiltersPropertiesState {
  agentsPerDept: Department[];
  actionOptions: selectOptionStringT[] | [];
  departmentService: selectOptionStringT[] | [];
  statusOptions: selectOptionStringT[] | [];
  categoryOptions: selectOptionStringT[] | [];
  availabilityOptions: selectOptionStringT[] | [];
  categoriesOptionsApi: categoryOptionType[];
  agencyHierarchy: any;
  assistantAgencyHierarchy: any;
  setAgencyHierarchy: (serviceId?: number) => void;
  setStatusOptions: () => void;
  setCategoryOptions: (categoriesOptionsApi: any) => void;
  setCategoriesOptionsApi: () => Promise<any>;
  setAvailabilityOptions: () => void;
  setAgentOptionsApi: () => void;
  setDepartmentService: () => void;
  setActionOptions: () => void;
  setAssistantAgencyHierarchy: (
    brokerId?: unknown | null,
    serviceId?: unknown | null
  ) => void;
}

// const initialState = { users: [], unAssignedUsers: [] };
export const useFiltersProperties = create<FiltersPropertiesState>((set) => ({
  agentsPerDept: [],
  actionOptions: [],
  statusOptions: [],
  categoryOptions: [],
  availabilityOptions: [],
  categoriesOptionsApi: [],
  departmentService: [],
  agencyHierarchy: null,
  assistantAgencyHierarchy: null,
  setActionOptions: async () => {
    const response = await http.get(`/actions`);
    const { data } = response;
    const actionOptions = getOptionsByLanguage(data);
    set({ actionOptions });
  },
  setStatusOptions: async () => {
    const response = await http.get(`/statuses`);
    const { data } = response;

    const statusOptions = getOptionsByLanguage(data);
    set({ statusOptions });
  },
  setCategoriesOptionsApi: async () => {
    const response = await http.get(`/categories`);
    const { data: categoriesOptionsApi } = response;
    set({ categoriesOptionsApi });
    return categoriesOptionsApi;
  },
  setAvailabilityOptions: async () => {
    const response = await http.get(`/availabilities`);
    const { data } = response;
    const availabilityOptions = getOptionsByLanguage(data);
    set({ availabilityOptions });
  },
  setCategoryOptions: async (categoriesOptionsApi) => {
    const categoryOptions = getOptionsByLanguage(categoriesOptionsApi);
    set({ categoryOptions });
  },
  setAgentOptionsApi: async () => {
    const response = await http.get(`/users/lateral`);
    const { data: agentsPerDept } = response;
    set({ agentsPerDept });
  },
  setDepartmentService: async () => {
    const response = await http.get(`/services`);
    const { data } = response;
    const departmentService = getOptionsByLanguage(data);
    set({ departmentService: departmentService });
  },
  setAgencyHierarchy: async (serviceId?: number) => {
    let response;
    if (serviceId) {
      response = await http.get(`/users/hierarchy?service=${serviceId}`);
    } else {
      response = await http.get(`/users/hierarchy`);
    }
    const { data: agencyHierarchy } = response;
    set({ agencyHierarchy });
  },
  setAssistantAgencyHierarchy: async (brokerId = null, serviceId = 0) => {
    const response = await http.get(
      `/users/hierarchy?broker=${brokerId}${
        serviceId === 0 ? '' : `&service=${serviceId}`
      }`
    );
    const { data: assistantAgencyHierarchy } = response;
    set({ assistantAgencyHierarchy });
  },
}));
