import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

export default function PromoIcon({ color, className }: IconProps) {
  const { mode } = useThemeMode();

  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <circle cx="8" cy="8" r="7" stroke={color}></circle>
      <path
        stroke={color}
        strokeLinejoin="round"
        d="M5.487 6.333C4.395 7.423 3.522 10.474 6.797 12c-.073-.581-.088-1.874.436-2.397 0 1.394 1.31 2.18 1.965 2.397.582-.218 1.834-1.003 2.183-2.397.437-1.744-.437-2.834-.873-3.27-.219.436-.655.872-1.528 1.308.654-2.18-.437-3.705-1.529-4.141 0 1.09-.873 1.744-1.964 2.833z"
      ></path>
    </svg>
  );
}
