// fix API serialization bugs when sometimes it returns array and sometimes object
export const guaranteeArray = <T>(
  arg: Record<string, T> | T[] | null | undefined
): T[] => {
  if (Array.isArray(arg)) {
    return arg;
  }
  if (typeof arg === 'object' && arg !== null) {
    return Object.values(arg);
  }
  return [];
};

export const guaranteeObject = <T>(
  arg: Record<string, T> | T[] | null | undefined
): Record<string, T> => {
  if (typeof arg === 'object' && arg !== null && !Array.isArray(arg)) {
    return arg as Record<string, T>;
  }
  return {};
};

export function extractId(object: { id: number } | null) {
  if (object) {
    return String(object.id);
  }
  return null;
}

export function guaranteeString(str?: string | number | null): string {
  if (!str) return '';
  return String(str);
}

export function guaranteeStringOrNulable(
  str?: string | number | null
): string | null {
  if (!str) return null;
  return String(str);
}
