import { createElement, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Box, Button, Paper, Tooltip } from '@mui/material';
import slugify from 'slugify';
import TitleHeader from '../../../../../components/common/titleHeader';
import Tabs from '../../../../../components/common/tabs';
import { IPortalSetting } from '../../../../../types/portals';
import * as React from 'react';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import { transformDataFromServer } from '../../form';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '../../../../../components/modal';
import { AxiosResponse } from 'axios';
import { http } from '../../../../../libs/axios';
import { useNotification } from '../../../../../hooks/useNotification';
import SpinnerLoading from '../../../../../features/spinner/spinnerLoading';
import classNames from 'classnames';
import { useQuery } from '../../../../../hooks/useQuery';
import { useProtectedForm } from '../../../../../hooks/useProtectedForm';
import { useTranslation } from 'react-i18next';

export interface PortalTabProps {
  data: IPortalSetting;
  mapTabNamesToComponents: any;
  mutate: any;
  id?: string;
  register?: any;
  handleSubmit?: any;
  reset?: any;
  control?: any;
  setValue?: any;
  onSubmit?: any;
  isLoading?: boolean;
  onCancelClick?: () => void;
}

const TabbedPortalInformationNew = (props: PortalTabProps) => {
  const { id, data, mapTabNamesToComponents, mutate } = props;
  const query = useQuery();
  const isEditMode = query.get('mode') === 'edit';
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isIDXFileLoading, setIsIDXFileLoading] = useState(false);
  const [isFTPConnectionLoading, setIsFTPConnectionLoading] = useState(false);
  const [isFTPConnectionChecked, setIsFTPConnectionChecked] = useState(false);
  const [isFTPConnectionSuccessful, setIsFTPConnectionSuccessful] =
    useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation('propertiesPage');
  const stepNames = Object.keys(mapTabNamesToComponents);
  const stepsData = stepNames.map((name) => {
    return {
      slug: slugify(name.toLowerCase()),
      title: name,
      component: mapTabNamesToComponents[name],
    };
  });

  const [isLoading, setIsLoading] = useState(false);
  const { addNotification } = useNotification();

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { isDirty, isSubmitted },
    setValue,
  } = useProtectedForm({
    defaultValues: transformDataFromServer(data),
  });

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const onCancelButtonClick = () => {
    setIsModalOpen(false);
  };

  const onClosePopup = () => {
    reset(transformDataFromServer(data));
    navigate(`/settings/portals/${id}/portal-information`);
    setIsModalOpen(false);
  };

  const onEditModeSwitch = () => {
    if (!isDirty && !isSubmitted) {
      navigate(
        `/settings/portals/${id}/portal-information${
          isEditMode
            ? `?lang=${data.language}`
            : `?mode=edit&lang=${data.language}`
        }`
      );
    } else {
      setIsModalOpen(true);
    }
  };

  const onCancelClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (!isDirty && !isSubmitted) {
      navigate(`/settings/portals/${id}/portal-information`);
    } else {
      setIsModalOpen(true);
    }
  };

  const onSubmit = (data: any) => {
    const body = {
      portal: data.portal,
      ftpHost: data.ftpHost,
      ftpLogin: data.ftpLogin,
      ftpPassword: data.ftpPassword,
      ftpPort: parseInt(data.ftpPort),
      isSSL: !!data.isSSL,
      isActive: !!data.status,
      idxFile: data.idxFile,
      dataPath: data.dataPath,
      imagesPath: data.imagesPath,
      videosPath: data.videosPath,
      documentsPath: data.documentsPath,
      departments: data.departments,
      agencyId: data.agencyId,
      agencyEmail: data.agencyEmail,
      contact: data.contact,
      emailRent: data.emailRent,
      telRent: data.telRent,
      emailBuy: data.emailBuy,
      telBuy: data.telBuy,
      language: data.language,
      notifyBroker: data.notifyBroker,
    };

    submitDataToServer(body);
  };

  const submitDataToServer = async (body: any) => {
    let promise: Promise<AxiosResponse>;
    if (isEditMode) {
      promise = http.patch(`/setting_portals/${data?.id}`, body);
    } else {
      promise = http.post('/setting_portals', body);
    }

    setIsLoading(true);
    promise
      .finally(() => {
        setIsLoading(false);
      })
      .then(({ data }) => {
        if (isEditMode) {
          if (mutate) {
            mutate(data);
          }
          reset(transformDataFromServer(data));
          addNotification(
            `Your portal has been successfully updated.`,
            'success'
          );
          navigate(`/settings/portals`);
          if (isModalOpen) {
            setIsModalOpen(false);
            navigate(`/settings/portals/${data.id}/portal-information`);
          }
        } else {
          addNotification(
            `Portal has been successfully created. Keep going and fill the rest of information`
          );
          if (mutate) {
            mutate(data);
          }
          navigate(`/settings/portals/${data.id}/portal-information`);
        }
      })
      .catch((error) => {
        let message;
        if (error.response) {
          message =
            error.response.data && error.response.data.message
              ? error.response.data.message
              : 'Server error';
        } else if (error.request) {
          message = 'Failed to connect to server';
        } else {
          message = 'Unknown error';
        }
        addNotification(message, 'error');
      });
  };

  const publishIdxFile = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    const promise: Promise<AxiosResponse> = http.get(
      `/setting_portals/${data.id}/publish_idx`
    );

    setIsIDXFileLoading(true);
    promise
      .finally(() => {
        setIsIDXFileLoading(false);
      })
      .then(() => {
        addNotification(`IDX file was generated successfully.`);
      })
      .catch((error) => {
        let message;
        if (error.response) {
          message = 'Server error';
          if (error.response.data && error.response.data.detail) {
            message = error.response.data.detail;
          }
          if (error.response.data && error.response.data.message) {
            message = error.response.data.message;
          }
        } else if (error.request) {
          message = 'Failed to connect to server';
        } else if (error.detail) {
          message = error.detail;
        } else {
          message = 'Unknown error';
        }
        addNotification(message, 'error');
      });
  };

  const checkFTPConnection = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    const promise: Promise<AxiosResponse> = http.get(
      `/setting_portals/${data.id}/test_connection`
    );

    setIsFTPConnectionLoading(true);
    promise
      .finally(() => {
        setIsFTPConnectionLoading(false);
        setIsFTPConnectionChecked(true);
      })
      .then(() => {
        addNotification('FTP connection successfully checked.');
        setIsFTPConnectionSuccessful(true);
      })
      .catch((error) => {
        let message;
        if (error.response) {
          setIsFTPConnectionSuccessful(false);
          message = 'FTP connection failed';
        } else if (error.request) {
          message = 'Failed to connect to server';
        } else {
          message = 'Unknown error';
        }
        addNotification(message, 'error');
      });
  };

  const onDeleteClick = () => {
    http
      .delete(`/setting_portals/${data.id}`)
      .then(() => {
        addNotification(`Portal has been successfully deleted.`, 'success');
        if (mutate) {
          mutate();
        }
        navigate(`/settings/portals`);
      })
      .catch((error) => {
        let message;
        if (error.response) {
          message =
            error.response.data && error.response.data.message
              ? error.response.data.message
              : 'Server error';
        } else if (error.request) {
          message = 'Failed to connect to server';
        } else {
          message = 'Unknown error';
        }
        addNotification(message, 'error');
      });
  };

  return (
    <div>
      <TitleHeader
        titleElement={
          <div>
            <p className={'text-[2rem]'}>{data.portal.name}</p>
            {isFTPConnectionChecked ? (
              <div
                className={classNames('flex items-center gap-2 text-[14px]', {
                  'text-[#4CAF50]': isFTPConnectionSuccessful,
                  'text-[#F50057]': !isFTPConnectionSuccessful,
                })}
              >
                {isFTPConnectionSuccessful ? <CheckIcon /> : <CloseIcon />}
                <span>
                  {isFTPConnectionSuccessful
                    ? 'FTP connection: Successful'
                    : 'FTP connection: Failed'}
                </span>
              </div>
            ) : null}
          </div>
        }
        title={''}
        goBackLink="/settings/portals"
        rightElement={
          <div className="flex justify-end mb-4 gap-4">
            <Button
              onClick={checkFTPConnection}
              type="submit"
              variant="outlined"
              className={classNames('flex justify-center items-center', {
                'pointer-events-none': isFTPConnectionLoading,
              })}
              sx={{
                boxShadow: 'none',
                height: '49px',
                minWidth: '193px',
              }}
            >
              <Box className="flex justify-center items-center" gap="16px">
                {isFTPConnectionLoading ? (
                  <SpinnerLoading />
                ) : (
                  <>
                    <div className="font-medium">
                      {t('Check_FTP_connection')}
                    </div>
                    <NetworkCheckIcon
                      fontSize="small"
                      sx={{
                        width: '18px',
                        height: '18px',
                      }}
                    />
                  </>
                )}
              </Box>
            </Button>
            <Tooltip
              placement={'bottom-end'}
              title={t(
                'Generates_IDX_files_and_send_them_by_FTP_to_remote_server'
              )}
            >
              <Button
                onClick={publishIdxFile}
                type="submit"
                variant="outlined"
                className={classNames('flex justify-center items-center', {
                  'pointer-events-none': isIDXFileLoading,
                })}
                sx={{
                  boxShadow: 'none',
                  height: '49px',
                  minWidth: '193px',
                }}
              >
                <Box className="flex justify-center items-center" gap="16px">
                  {isIDXFileLoading ? (
                    <SpinnerLoading />
                  ) : (
                    <>
                      <div className="font-medium">
                        {t('Regenerate_IDX_file')}
                      </div>
                      <AutorenewIcon
                        fontSize="small"
                        sx={{
                          width: '18px',
                          height: '18px',
                        }}
                      />
                    </>
                  )}
                </Box>
              </Button>
            </Tooltip>
            <Button
              onClick={onEditModeSwitch}
              type="submit"
              variant="outlined"
              className="flex justify-center items-center"
              sx={{
                height: '49px',
                color: '#000',
              }}
            >
              <Box className="flex justify-center items-center" gap="16px">
                <span className={'text-[14px] font-medium'}>
                  {isEditMode ? t('Back_to_preview') : t('Edit_portal')}
                </span>
              </Box>
            </Button>
          </div>
        }
      >
        <>
          <Tabs items={stepsData} />
          <Paper square elevation={0}>
            <div className="">
              <div className="pt-16 pb-12 px-16">
                <Routes>
                  {stepsData.map(({ slug, component }) => (
                    <Route
                      key={slug}
                      path={slug}
                      element={
                        component &&
                        createElement(component, {
                          data: data,
                          id: id,
                          mutate: mutate,
                          register,
                          handleSubmit,
                          reset,
                          control,
                          setValue,
                          isLoading,
                          onSubmit,
                          onCancelClick,
                          onDeleteClick,
                        })
                      }
                    />
                  ))}
                </Routes>
              </div>
              <Modal
                boxClassName="px-14 py-10"
                CloseIconStyle={{
                  cursor: 'pointer',
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  display: 'none',
                }}
                isOpen={isModalOpen}
                closeModal={handleClose}
                openModal={handleOpen}
                buttonProps={{
                  title: ' ',
                  className: 'hidden',
                }}
                withButton={false}
                closeIconSize="medium"
                title={
                  <p className={'text-[20px] 2xl:text-[26px]'}>
                    {t('Do_you_want_to_save')}
                  </p>
                }
                withTitleDivider={false}
                width={{
                  md: '50%',
                  lg: '40%',
                }}
              >
                <div>
                  <p className={'text-[14px] 2xl:text-base opacity-70'}>
                    {t('This_tab_has_unsaved_changes_Do_you_want_to_save_them')}
                  </p>
                  <p className={'text-[14px] 2xl:text-base opacity-50'}>
                    {t(
                      'Your_changes_will_be_lost_if_you_close_this_tab_without_saving'
                    )}
                  </p>
                </div>
                <div className={'flex gap-16 w-full pt-16 justify-between'}>
                  <Button
                    onClick={onClosePopup}
                    variant="outlined"
                    className="flex justify-center items-center"
                    sx={{
                      height: '40px',
                    }}
                  >
                    <Box
                      className="flex justify-center items-center"
                      gap="16px"
                    >
                      <div className="font-medium">Don&apos;t Save</div>
                    </Box>
                  </Button>
                  <div className={'flex gap-4'}>
                    <Button
                      onClick={onCancelButtonClick}
                      variant="text"
                      className="flex justify-center items-center"
                      sx={{
                        borderColor: 'transparent',
                        height: '40px',
                      }}
                    >
                      <Box
                        className="flex justify-center items-center"
                        gap="16px"
                      >
                        <div className="font-medium">{t('Cancel')}</div>
                      </Box>
                    </Button>
                    <Button
                      onClick={handleSubmit((d) => onSubmit(d))}
                      variant="contained"
                      disableElevation
                      className="flex justify-center items-center"
                      sx={{
                        background: '#3446B9',
                        height: '40px',
                      }}
                    >
                      <Box
                        className="flex justify-center items-center"
                        gap="16px"
                      >
                        <div className="font-medium">{t('Save')}</div>
                        <CheckIcon
                          fontSize="small"
                          sx={{
                            width: '16px',
                            height: '14px',
                          }}
                        />
                      </Box>
                    </Button>
                  </div>
                </div>
              </Modal>
            </div>
          </Paper>
        </>
      </TitleHeader>
    </div>
  );
};

export default TabbedPortalInformationNew;
