import {
  initialPromtionFilterValuesI,
  initialStepperFilterValuesI,
} from '../types/promotion';
import { FilterPropertiesT } from './propertiesFilter';

export const initialStepperPromotionMainFilter: FilterPropertiesT = {
  property: [
    {
      id: 10,
      label: 'agents',
      title: 'Agents',
      type: 'listSearch',
      disableField: false,
      options: [],
    },
    {
      id: 11,
      label: 'reference',
      title: 'Reference',
      type: 'listSearch',
      disableField: false,
      options: [],
    },
    {
      id: 15,
      label: 'status',
      title: 'Status',
      type: 'list',
      disableField: false,
      options: [],
    },
  ],
  creationDate: [
    {
      id: 119,
      label: 'createdFrom',
      title: 'From',
      type: 'date',
      disableField: false,
    },
    {
      id: 120,
      label: 'createdTo',
      title: 'To',
      type: 'date',
      disableField: false,
    },
  ],
  location: [
    {
      id: 181,
      label: 'country',
      title: 'Country',
      type: 'listSearch',
      disableField: false,
      options: [],
    },
    {
      id: 18,
      label: 'city',
      title: 'City',
      type: 'listSearch',
      disableField: false,
      options: [],
    },
    {
      id: 19,
      label: 'zipCode',
      title: 'Zip code',
      type: 'listSearch',
      disableField: false,
      options: [],
    },
    {
      id: 20,
      label: 'adresse',
      title: 'Addresse',
      type: 'listSearch',
      disableField: false,
      options: [],
    },
    {
      id: 203,
      label: 'canton',
      title: 'Canton',
      type: 'listSearch',
      disableField: false,
    },
    {
      id: 182,
      label: 'district',
      title: 'District',
      type: 'text',
      disableField: false,
    },
  ],
};

export const initialPromotionMainFilter: FilterPropertiesT = {
  promotion: [
    {
      id: 10,
      label: 'reference',
      title: 'Reference',
      type: 'listSearch',
      disableField: false,
      options: [],
    },
    {
      id: 11,
      label: 'title',
      title: 'Title',
      type: 'listSearch',
      disableField: false,
      options: [],
    },
    {
      id: 12,
      label: 'departments',
      title: 'Department',
      type: 'list-hierarchy',
      disableField: false,
    },
    {
      id: 15,
      label: 'status',
      title: 'Status',
      type: 'list',
      disableField: false,
      options: [],
    },
    {
      id: 13,
      label: 'service',
      title: 'Service',
      type: 'list',
      disableField: false,
      options: [],
    },
    {
      id: 14,
      label: 'agents',
      title: 'Agent',
      type: 'list',
      disableField: false,
      options: [],
    },
  ],
  availability: [
    {
      id: 119,
      label: 'availableFrom',
      title: 'From',
      type: 'date',
      disableField: false,
    },
    {
      id: 120,
      label: 'availableTo',
      title: 'To',
      type: 'date',
      disableField: false,
    },
  ],
  location: [
    {
      id: 181,
      label: 'country',
      title: 'Country',
      type: 'listSearch',
      disableField: false,
      options: [],
    },
    {
      id: 18,
      label: 'city',
      title: 'City',
      type: 'listSearch',
      disableField: false,
      options: [],
    },
    {
      id: 19,
      label: 'zipCode',
      title: 'Zip code',
      type: 'listSearch',
      disableField: false,
      options: [],
    },
    {
      id: 20,
      label: 'adresse',
      title: 'Addresse',
      type: 'listSearch',
      disableField: false,
      options: [],
    },
    {
      id: 203,
      label: 'canton',
      title: 'Canton',
      type: 'listSearch',
      disableField: false,
    },
    {
      id: 182,
      label: 'district',
      title: 'District',
      type: 'text',
      disableField: false,
    },
  ],
};

export const initialStepeprFilterValues: initialStepperFilterValuesI = {
  reference: [],
  agents: [],
  city: '',
  zipCode: '',
  adresse: '',
  status: '',
  createdFrom: '',
  createdTo: '',
  address: null,
  country: '',
  canton: '',
  district: '',
};

export const initialPromotionFilterValues: initialPromtionFilterValuesI = {
  reference: [],
  title: '',
  city: '',
  zipCode: '',
  adresse: '',
  agents: null,
  status: '',
  availableFrom: '',
  availableTo: '',
  departments: [],
  service: '',
  address: null,
  country: '',
  canton: '',
  district: '',
};

export const textFieldStyle = {
  '& .MuiInputBase-root , .MuiSelect-select': {
    borderRadius: '1px',
  },
};
