import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

export function useLandingNavLinks() {
  const projectSectionRef = useRef<HTMLDivElement>(null);
  const locationSectionRef = useRef<HTMLDivElement>(null);
  const contactSectionRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('propertiesPage');

  return {
    project: {
      ref: projectSectionRef,
      title: t('Project'),
      href: '#project',
    },
    location: {
      ref: locationSectionRef,
      title: t('agencyPage:location'),
      href: '#location',
    },
    contact: {
      ref: contactSectionRef,
      title: t('common:Contact'),
      href: '#contact',
    },
  };
}
