import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { Stack } from '@mui/material';

import useLocale from '../../../hooks/useLocale';
import LocationIcon from '../../../features/icons/locationIcon';
import GoogleMapContainer from '../../../components/map/googleMapContainer';

type Props = {
  data: any;
};

function PromotionLocation({ data }: Props) {
  const { t } = useTranslation('propertiesPage');
  const {
    address: { latitude, longitude, zipCode },
  } = data;

  const { locale } = useLocale();
  const propertyAddress = useMemo(() => {
    if (data && data.address && data.address.translations) {
      const { translations } = data.address;
      const adressTranslation = translations[locale];
      return {
        ...adressTranslation,
      };
    }
  }, [locale, data]);

  const city = propertyAddress?.city;
  const adresse = propertyAddress?.adresse;
  const fullNameCountry = propertyAddress.fullNameCountry;

  const addressComponents = [
    adresse,
    city,
    city ? zipCode : undefined,
    fullNameCountry,
  ];
  const addressString = addressComponents.filter(Boolean).join(', ');

  return (
    <Stack
      sx={{ color: 'text.primary' }}
      className="w-full rounded border bg-[#FFFFFF] p-8 flex flex-col"
    >
      {latitude && longitude && (
        <GoogleMapContainer coordinates={{ lat: latitude, lng: longitude }} />
      )}

      <h2 className=" my-4 font-medium text-lg">{t('property_location')}</h2>
      {city && (
        <div className="flex items-center">
          <LocationIcon className="mr-2 col-span-1" color="#6E6E72" />
          <h3 className="col-span-7 text-sm">{addressString}</h3>
        </div>
      )}
    </Stack>
  );
}

export default PromotionLocation;
