import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

export default function GlobalNotificationIcon({
  color,
  className,
}: IconProps) {
  const { mode } = useThemeMode();

  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="17"
      fill="none"
      viewBox="0 0 10 17"
    >
      <path
        fill={color}
        d="M3.219 11.719v-.094c.01-.995.114-1.786.312-2.375s.48-1.065.844-1.43c.365-.364.802-.7 1.313-1.008.307-.187.583-.408.828-.664.244-.26.437-.56.578-.898.146-.339.218-.714.218-1.125 0-.51-.12-.953-.359-1.328a2.413 2.413 0 00-.96-.867 2.914 2.914 0 00-1.337-.305c-.427 0-.838.089-1.234.266a2.39 2.39 0 00-.992.836c-.266.38-.42.877-.461 1.492H0c.042-.886.27-1.643.688-2.274A4.14 4.14 0 012.351.5C3.044.167 3.812 0 4.656 0c.917 0 1.714.182 2.39.547a3.916 3.916 0 011.579 1.5c.375.635.563 1.36.563 2.172 0 .573-.089 1.09-.266 1.554a3.83 3.83 0 01-.75 1.243A5.145 5.145 0 017 7.984c-.458.287-.826.589-1.102.907a2.862 2.862 0 00-.601 1.117c-.125.432-.193.971-.203 1.617v.094H3.219zm1 4.625c-.386 0-.716-.138-.992-.414a1.354 1.354 0 01-.414-.992c0-.386.138-.717.414-.993.276-.276.606-.414.992-.414.385 0 .716.138.992.414.276.276.414.607.414.992 0 .256-.065.49-.195.704a1.458 1.458 0 01-.508.515 1.34 1.34 0 01-.703.188z"
      ></path>
    </svg>
  );
}
