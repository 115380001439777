import { ReactNode } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const PopupFormSuccess = ({
  children,
  onClose,
}: {
  children?: ReactNode;
  onClose?: () => void;
}) => (
  <div className="relative flex-grow w-full px-8 py-16 flex-col justify-center items-center gap-8 inline-flex">
    <div className="text-[#4CAF50] p-4 rounded-[200px] border-2 border-green-500 justify-start items-center gap-2 inline-flex">
      <CheckIcon
        sx={{
          width: '32px',
          height: '32px',
        }}
      />
    </div>
    <div className="self-stretch text-center text-zinc-900 text-2xl font-normal font-['Inter'] leading-7">
      {children}
    </div>
    {onClose ? (
      <button
        className={
          'absolute right-4 top-4 p-2 opacity-80 transition-opacity duration-300 hover:opacity-50'
        }
        onClick={onClose}
      >
        <CloseIcon
          sx={{
            width: '22px',
            height: '22px',
          }}
          fontSize={'small'}
        />
      </button>
    ) : null}
  </div>
);

export default PopupFormSuccess;
