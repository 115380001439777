// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formatted-phone .form-control {
  padding: 0;
  border: none;
  height: auto;
  line-height: 1.5em;
  background: none;
  pointer-events: none;
}

.formatted-phone .flag-dropdown {
  display: none;
}

.formatted-phone--left .form-control {
  text-align: left;
}

.formatted-phone--center .form-control {
  text-align: center;
}

.formatted-phone--right .form-control {
  text-align: right;
}
`, "",{"version":3,"sources":["webpack://./src/pages/brochureTemplates/components/formattedPhone/formattedPhone.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".formatted-phone .form-control {\n  padding: 0;\n  border: none;\n  height: auto;\n  line-height: 1.5em;\n  background: none;\n  pointer-events: none;\n}\n\n.formatted-phone .flag-dropdown {\n  display: none;\n}\n\n.formatted-phone--left .form-control {\n  text-align: left;\n}\n\n.formatted-phone--center .form-control {\n  text-align: center;\n}\n\n.formatted-phone--right .form-control {\n  text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
