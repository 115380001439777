import { PieChart as MinimalPieChart } from 'react-minimal-pie-chart';
import ChartLegend from './chartLegend';
import { useTranslation } from 'react-i18next';

export const COLORS = [
  '#FE9700',
  '#FFC006',
  '#3446B9',
  '#2095F3',
  '#6639B6',
  '#4CAF50',
  '#B1B424',
];

export interface PieChartProps {
  data: Record<string, number>;
  total: number;
}

export function calculatePercent(value: number, total: number) {
  const share = value / total;
  return Math.round(share * 100);
}

const PieChart = ({ data, total }: PieChartProps) => {
  const series = Object.entries(data)
    .map(([title, value], index) => ({
      value,
      color: COLORS[index % COLORS.length] as string,
      title: `${title} (${calculatePercent(value, total)}%)`,
    }))
    .sort((a, b) => Math.sign(b.value - a.value));

  const { t } = useTranslation('propertiesPage');

  return (
    <div className="real_col">
      <h3>{t('lead_sources')}</h3>

      <div className="w-[262px] aspect-square mx-auto mb-5">
        <MinimalPieChart data={series} lineWidth={60} startAngle={-90} />
      </div>

      <ChartLegend legend={series} />
    </div>
  );
};

export default PieChart;
