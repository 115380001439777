import useSWR from 'swr';
import { forwardRef } from 'react';

import SpinnerLoading from '../../features/spinner/spinnerLoading';
import FilterAgencyMultiselect, {
  FilterAgencyMultiselectProps,
} from './filterAgencyMultiselect';
import { ICompany } from '../../types/hierarchy/legacy';

const FilterAgencyMultiselectWithLoadedOptions = forwardRef<
  HTMLSelectElement,
  Omit<FilterAgencyMultiselectProps, 'node'>
>((props, ref) => {
  const { data: hierarchy } = useSWR<ICompany>('/users/hierarchy');

  if (!hierarchy) {
    return <SpinnerLoading />;
  }

  return <FilterAgencyMultiselect {...props} node={hierarchy} ref={ref} />;
});

FilterAgencyMultiselectWithLoadedOptions.displayName =
  'FilterAgencyMultiselectWithLoadedOptions';

export default FilterAgencyMultiselectWithLoadedOptions;
