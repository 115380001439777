import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

function LandSurfIcon({ color, className }: IconProps) {
  const { mode } = useThemeMode();
  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="17"
      fill="none"
      viewBox="0 0 18 17"
    >
      <path
        stroke={color}
        d="M6.5 5H5l-3 7h14l-1-7h-1.5M1.5 14.5h15m-15 0L3 13m-1.5 1.5L3 16m13.5-1.5L15 13m1.5 1.5L15 16"
      ></path>
      <path stroke={color} d="M6.5 3.5V9h7V3.5L10 1 6.5 3.5z"></path>
      <path stroke={color} d="M8.5 7V5h3v2h-3z"></path>
    </svg>
  );
}

export default LandSurfIcon;
