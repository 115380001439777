import { Alert, SxProps } from '@mui/material';

export default function ErrorBoundaryAlert() {
  const style: SxProps = {
    width: '50%',
    mx: 'auto',
    mt: '200px',
    fontSize: 20,
    fontWeight: 'bold',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <Alert sx={style} severity="error">
      An error has occurred
    </Alert>
  );
}
