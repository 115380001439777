import { Box, Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import * as React from 'react';
import { ReactNode } from 'react';
import FormWrapper from '../form-wrapper';
import { useTranslation } from 'react-i18next';

const NoEditModeFormWrapper = (props: {
  title?: string;
  onSubmit: () => void;
  children: ReactNode;
  isLoading: boolean;
  buttonDisabled?: boolean;
  additionalButton?: ReactNode;
}) => {
  const {
    title,
    children,
    onSubmit,
    isLoading,
    buttonDisabled = false,
    additionalButton,
  } = props;
  const { t } = useTranslation('common');
  const buttonsJsx = (
    <>
      {additionalButton}
      <Button
        disabled={buttonDisabled}
        type="submit"
        variant="contained"
        disableElevation
        className="flex justify-center items-center w-fit save_contact"
        sx={{
          background: '#3446B9',
          height: '40px',
        }}
      >
        <Box className="flex justify-center items-center" gap="12px">
          <div className="font-medium"> {t('Save')} </div>
          <CheckIcon
            fontSize="small"
            sx={{
              width: '16px',
              height: '14px',
            }}
          />
        </Box>
      </Button>
    </>
  );

  return (
    <FormWrapper
      title={title}
      onSubmit={onSubmit}
      buttons={buttonsJsx}
      isLoading={isLoading}
    >
      {children}
    </FormWrapper>
  );
};

export default NoEditModeFormWrapper;
