import classNames from 'classnames';
import React from 'react';
import { Tooltip } from '@mui/material';

interface AgencyProps {
  agencies: {
    nameAgence?: string;
  }[];
}
export const ContactAgencyCard = ({ agencies }: AgencyProps) => {
  if (!agencies || !agencies.length) {
    return null;
  }

  const allAgencies = agencies.map((elem) => elem.nameAgence).join(', ');

  return (
    <div className={classNames('flex py-2')}>
      <Tooltip title={allAgencies} arrow placement="top">
        <span className="w-full block truncate font-medium font-[Inter] text-sm leading-4">
          {allAgencies}
        </span>
      </Tooltip>
    </div>
  );
};
