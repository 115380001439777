import { Box, Tooltip } from '@mui/material';
import React, { CSSProperties, MouseEventHandler } from 'react';
import RemoveButton from '../components/removeButton';
import { useTranslation } from 'react-i18next';

type Props = {
  src: string;
  link: string;
  isDragging?: boolean;
  ribbon?: boolean | null;
  faded?: boolean;
  trashIcon?: boolean;
  onTrashIconClick?: MouseEventHandler<HTMLButtonElement>;
  isVideoThumb?: boolean;
  onItemClick?: () => void;
};

const GalleryFieldThumb = ({
  src,
  link,
  isDragging = false,
  ribbon = false,
  faded = false,
  trashIcon = true,
  onTrashIconClick,
  isVideoThumb = false,
  onItemClick,
}: Props) => {
  const style = {
    border: '1px solid',
    borderColor: isDragging ? '#3446B9' : '#E6E8EC',
    padding: '4px',
    width: '185px',
    height: '135px',
    objectFit: 'contain',
  };
  const { t } = useTranslation('common');
  return (
    <div
      style={{
        opacity: faded ? 0.5 : 1,
        cursor: isDragging ? 'grabbing' : 'grab',
      }}
    >
      {ribbon && (
        <Tooltip
          title={t(
            'This_image_will_be_used_as_cover_image_to_represent_this_property_everywhere_on_the_site'
          )}
        >
          <Box
            sx={{
              position: 'absolute',
              borderStyle: 'soild',
              borderWidth: '1rem 1rem 0 0',
              borderColor: '#3446B9 transparent transparent transparent',
              top: 0,
              left: 0,
              zIndex: 1,
            }}
          ></Box>
        </Tooltip>
      )}
      <div onClick={onItemClick} className="block bg-gray-100">
        {isVideoThumb ? (
          <video
            src={link}
            style={style as CSSProperties}
            width="100%"
            height="auto"
          />
        ) : (
          <img
            src={src}
            style={style as CSSProperties}
            width="100%"
            height="auto"
            alt=""
          />
        )}
      </div>
      {trashIcon && (
        <RemoveButton
          className="bottom-3 right-[12px]"
          tooltip="Remove image"
          onClick={onTrashIconClick}
        />
      )}
    </div>
  );
};
export default GalleryFieldThumb;
