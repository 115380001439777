import React, { useState } from 'react';
import WidgetWrapper from './wrapper';
import { WidgetProps } from '../index';
import { useTranslation } from 'react-i18next';
import LeadsPopup from '../components/leadsPopup';
import useMediaQuery from '@mui/material/useMediaQuery';
import classNames from 'classnames';
import {
  desktopTitles,
  FullLeadItem,
  MobileItem,
  mobileTitles,
} from './agencyLeads';

const LeadsWidget: React.FC<WidgetProps> = (props) => {
  const { data } = props;
  const { t } = useTranslation('common');
  const [openModal, setOpenModal] = useState(false);
  const isMobile = useMediaQuery('(max-width:1280px)');

  const titles = isMobile || !props.wide ? mobileTitles : desktopTitles;

  return (
    <WidgetWrapper
      wide={props.wide}
      title={'MyRecentLeads'}
      linkText={'ViewAll'}
      link={null}
      onButtonClick={() => setOpenModal(true)}
      emptyData={
        data && data['own-recent-leads'] && !data['own-recent-leads'].length
      }
    >
      <div className="w-full flex-col justify-center items-start gap-8 inline-flex">
        <div className="self-stretch flex-col justify-center items-start gap-2 flex">
          <div
            className={classNames(
              'grid self-stretch pb-2 border-b border-gray-200 justify-start items-start inline-flex',
              {
                'grid-cols-12 gap-4': !isMobile || props.wide,
                '!grid-cols-3 !gap-1': isMobile || !props.wide,
              }
            )}
          >
            {titles.map((el, index) => (
              <div
                key={index}
                className={classNames(
                  'grow shrink basis-0 text-zinc-900 text-sm font-medium leading-none overflow-hidden',
                  el.className
                )}
              >
                <p
                  className={'whitespace-nowrap text-ellipsis overflow-hidden'}
                  title={t(el.name)}
                >
                  {t(el.name)}
                </p>
              </div>
            ))}
          </div>
          <div
            className={classNames('flex flex-col w-full', {
              'divide-y divide-gray-200': !isMobile || props.wide,
            })}
          >
            {data['agency-recent-leads'] &&
              data['agency-recent-leads'].map((item, index) => {
                if (isMobile || !props.wide) {
                  return <MobileItem key={index} item={item} />;
                } else {
                  return <FullLeadItem key={index} item={item} />;
                }
              })}
          </div>
        </div>

        <LeadsPopup
          title={'MyRecentLeads'}
          openModal={openModal}
          onClose={() => setOpenModal(false)}
          api={'/prospects/my-leads'}
        />
      </div>
    </WidgetWrapper>
  );
};

export default LeadsWidget;
