import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

export default function RedirectIcon({ color, className }: IconProps) {
  const { mode } = useThemeMode();

  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.75 3.5H1V15h11.5V9.25M4 12L14.5 1.5m0 0H9m5.5 0V7"
      ></path>
    </svg>
  );
}
