import TabGridTag from '../../../../features/tags/tabGridTag';
import HomeIcon from '../../../../features/icons/homeIcon';
import BedroomIcon from '../../../../features/icons/bedroomIcon';
import BathroomIcon from '../../../../features/icons/bathroomIcon';
import SquareIcon from '../../../../features/icons/squareIcon';
import BalconyIcon from '../../../../features/icons/balconyIcon';
import FloorIcon from '../../../../features/icons/floorIcon';
import GardenSurfIcon from '../../../../features/icons/gardenSurfIcon';
import LandSurfIcon from '../../../../features/icons/landSurfIcon';
import ParkingIcon from '../../../../features/icons/parkingIcon';
import TerraceIcon from '../../../../features/icons/terraceIcon';
import UsefulAreaIcon from '../../../../features/icons/usefulAreaIcon';
import TerraceSurfIcon from '../../../../features/icons/terraceSurfIcon';
import { Box } from '@mui/material';
import { IPropertyApi } from '../../../../types/property';
import { useTranslation } from 'react-i18next';
import { useQuery } from '../../../../hooks/useQuery';

function PropertyDetailsItems({ property }: { property: IPropertyApi }) {
  const {
    rooms,
    bedrooms,
    bathrooms,
    balcony,
    parkingNumbers,
    livingArea,
    floor,
    gardenSurface,
    landSurface,
    parkingAvailable,
    parkingBoxesAvailable,
    parkingBoxesNumbers,
    terrace,
    terraceSurface,
    usefulArea,
  } = property;
  const query = useQuery();
  const locale = query.get('lang') || 'en';

  const { t } = useTranslation('propertiesPage');
  return (
    <Box className="w-full flex flex-wrap gap-2 mt-8">
      {rooms > 0 && (
        <TabGridTag
          textContent={`${rooms ? rooms : '-'} ${t('rooms', { lng: locale })}`}
          className=" bg-gray-100 border-0  px-2 py-1"
          color="black"
          fontSize="14px"
          minWidth="max-content"
          iconPlacement="start"
          icon={<HomeIcon className="mr-2 col-span-1" />}
        />
      )}
      {bedrooms > 0 && (
        <TabGridTag
          textContent={`${bedrooms ? bedrooms : '-'} ${t('Bedrooms', {
            lng: locale,
          })}`}
          className=" bg-gray-100 border-0  px-2 py-1"
          color="black"
          fontSize="14px"
          minWidth="max-content"
          iconPlacement="start"
          icon={<BedroomIcon className="mr-2 col-span-1" />}
        />
      )}
      {bathrooms > 0 && (
        <TabGridTag
          textContent={`${bathrooms ? bathrooms : '-'} ${t('Bathrooms', {
            lng: locale,
          })}`}
          className="  bg-gray-100 border-0 px-2 py-1"
          color="black"
          fontSize="14px"
          minWidth="max-content"
          iconPlacement="start"
          icon={<BathroomIcon className="mr-2 col-span-1" />}
        />
      )}
      {livingArea > 0 && (
        <TabGridTag
          textContent={`${livingArea ? livingArea : '-'} m2`}
          className="  bg-gray-100 border-0 px-2 py-1"
          color="black"
          fontSize="14px"
          minWidth="max-content"
          iconPlacement="start"
          icon={<SquareIcon className="mr-2 col-span-1" />}
        />
      )}
      {balcony > 0 && (
        <TabGridTag
          textContent={`${balcony ? balcony : '-'} ${t('balconies', {
            lng: locale,
          })}`}
          className=" bg-gray-100 border-0  px-2 py-1"
          color="black"
          fontSize="14px"
          minWidth="max-content"
          iconPlacement="start"
          icon={<BalconyIcon className="mr-2 col-span-1" />}
        />
      )}
      {floor > 0 && (
        <TabGridTag
          textContent={`${floor ? floor : '-'} ${t('floors', { lng: locale })}`}
          className=" bg-gray-100 border-0  px-2 py-1"
          color="black"
          fontSize="14px"
          minWidth="max-content"
          iconPlacement="start"
          icon={<FloorIcon className="mr-2 col-span-1" />}
        />
      )}
      {gardenSurface > 0 && (
        <TabGridTag
          textContent={`${gardenSurface ? gardenSurface : '-'} ${t(
            'garden_surf',
            { lng: locale }
          )}`}
          className="  bg-gray-100 border-0 px-2 py-1"
          color="black"
          fontSize="14px"
          minWidth="max-content"
          iconPlacement="start"
          icon={<GardenSurfIcon className="mr-2 col-span-1" />}
        />
      )}
      {landSurface > 0 && (
        <TabGridTag
          textContent={`${landSurface ? landSurface : '-'} ${t('land_surf', {
            lng: locale,
          })}`}
          className="  bg-gray-100 border-0 px-2 py-1"
          color="black"
          fontSize="14px"
          minWidth="max-content"
          iconPlacement="start"
          icon={<LandSurfIcon className="mr-2 col-span-1" />}
        />
      )}
      {parkingAvailable && parkingNumbers > 0 && (
        <TabGridTag
          textContent={`${parkingNumbers ? parkingNumbers : '-'} ${t(
            'parkings',
            { lng: locale }
          )}`}
          className="  bg-gray-100 border-0 px-2 py-1"
          color="black"
          fontSize="14px"
          minWidth="max-content"
          iconPlacement="start"
          icon={<ParkingIcon className="mr-2 col-span-1" />}
        />
      )}
      {parkingBoxesAvailable && parkingBoxesNumbers > 0 && (
        <TabGridTag
          textContent={`${parkingBoxesNumbers ? parkingBoxesNumbers : '-'} ${t(
            'parking_boxes',
            { lng: locale }
          )}`}
          className="  bg-gray-100 border-0 px-2 py-1"
          color="black"
          fontSize="14px"
          minWidth="max-content"
          iconPlacement="start"
          icon={<ParkingIcon className="mr-2 col-span-1" />}
        />
      )}
      {terrace > 0 && (
        <TabGridTag
          textContent={`${terrace ? terrace : '-'} ${t('terraces', {
            lng: locale,
          })}`}
          className="  bg-gray-100 border-0 px-2 py-1"
          color="black"
          fontSize="14px"
          iconPlacement="start"
          icon={<TerraceIcon className="mr-2 col-span-1" />}
        />
      )}
      {usefulArea > 0 && (
        <TabGridTag
          textContent={`${usefulArea ? usefulArea : '-'} ${t('useful_area', {
            lng: locale,
          })}`}
          className="  bg-gray-100 border-0 px-2 py-1"
          color="black"
          fontSize="14px"
          minWidth="max-content"
          iconPlacement="start"
          icon={<UsefulAreaIcon className="mr-2 col-span-1" />}
        />
      )}
      {terraceSurface > 0 && (
        <TabGridTag
          textContent={`${terraceSurface ? terraceSurface : '-'} ${t(
            'terrace_surf',
            { lng: locale }
          )}`}
          className="  bg-gray-100 border-0 px-2 py-1"
          color="black"
          fontSize="14px"
          iconPlacement="start"
          minWidth="max-content"
          icon={<TerraceSurfIcon className="mr-2 col-span-1" />}
        />
      )}
    </Box>
  );
}

export default PropertyDetailsItems;
