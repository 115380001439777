import InputLayout from '../../../../../components/form/components/inputLayout';
import AddressInput from './adress-input';
import InputFormField from '../../../../../components/form/inputFormField';
import AutoCompleteField, {
  AutocompleteOption,
} from '../../../../../components/form/autoCompleteField';
import * as React from 'react';
import useLocale from '../../../../../hooks/useLocale';
import { countries } from '../../../../../const/countries';
import {
  Control,
  UseFormRegister,
  UseFormWatch,
  useController,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface Props {
  register: UseFormRegister<any>;
  control: Control<any>;
  watch: UseFormWatch<any>;
  index: number;
  name: string;
  isLoaded: boolean;
}

const AddressFields = ({
  register,
  control,
  watch,
  name,
  index,
  isLoaded,
}: Props) => {
  const { locale } = useLocale();
  const { field } = useController({ name: `${name}.${index}`, control });
  const country = watch(`${name}.${index}.translations.${locale}.country`);
  const { t } = useTranslation('common');
  const countryOptions: AutocompleteOption[] = countries.map((country) => ({
    id: String(country.alpha2),
    label: country[locale],
  }));

  return (
    <>
      <InputLayout label={t('Location')}>
        <AddressInput
          watch={watch}
          register={register}
          name={name}
          index={index}
          isLoaded={isLoaded}
          value={field.value}
          onChange={field.onChange}
        />
      </InputLayout>
      <InputFormField
        disabled
        type="text"
        label={t('Address')}
        className="bg-slate-50"
        {...register(`${name}.${index}.translations.${locale}.adresse`)}
        value={watch(`${name}.${index}.translations.${locale}.adresse`)}
      />
      <AutoCompleteField
        disabled
        label={t('Country')}
        isRequired={false}
        control={control}
        className="bg-slate-50"
        name={`${name}.${index}.translations.${locale}.country`}
        options={countryOptions}
        // force re-rendering if country value is updated
        // otherwise react-hook-form doesnt update the country when it is selected in <AddressInput />
        key={country}
      />
      <InputFormField
        disabled
        type="text"
        label={t('Canton')}
        className="bg-slate-50"
        {...register(`${name}.${index}.translations.${locale}.canton`)}
        value={watch(`${name}.${index}.translations.${locale}.canton`)}
      />
      <InputFormField
        disabled
        type="text"
        label={t('District')}
        className="bg-slate-50"
        {...register(`${name}.${index}.translations.${locale}.district`)}
        value={watch(`${name}.${index}.translations.${locale}.district`)}
      />
      <InputFormField
        disabled
        type="text"
        label={t('City')}
        className="bg-slate-50"
        {...register(`${name}.${index}.translations.${locale}.city`)}
        value={watch(`${name}.${index}.translations.${locale}.city`)}
      />
      <InputFormField
        disabled
        type="number"
        label={t('Zip_Code')}
        className="bg-slate-50"
        {...register(`${name}.${index}.zipCode`)}
        value={watch(`${name}.${index}.zipCode`)}
      />
    </>
  );
};

export default AddressFields;
