import { PropsWithChildren, useMemo } from 'react';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { appTheme } from '../../components/material-ui/theme';
import { useThemeMode } from '../../hooks/useThemeMode';

export function ThemeProvider({ children }: PropsWithChildren) {
  const { mode } = useThemeMode();

  const theme = useMemo(() => createTheme(appTheme(mode)), [mode]);

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}
