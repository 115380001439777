import { useTranslation } from 'react-i18next';
import { PropertyWidgetPreviewProps } from '../../../../../types/brochureEditor';
import TableWidget from '../../tableWidget';

const PropertyParkingWidget = ({
  property,
  widgetOptions,
}: PropertyWidgetPreviewProps) => {
  const { t } = useTranslation('propertiesPage');

  return (
    <TableWidget
      title={t('Parking')}
      items={[
        {
          id: '15',
          label: t('parkingNumbers'),
          value: String(property.parkingNumbers),
        },
        {
          id: '16',
          label: t('parkingBoxesNumbers'),
          value: String(property.parkingBoxesNumbers),
        },
      ]}
      widgetOptions={widgetOptions}
    />
  );
};

export default PropertyParkingWidget;
