import { Autocomplete, TextField } from '@mui/material';
import { Button, Fade, Box } from '@mui/material';
import Modal from '../../../../../components/modal';
import CheckIcon from '@mui/icons-material/Check';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { FormControl } from '@mui/material';
import { TextareaAutosize } from '@mui/base';
import { useTranslation } from 'react-i18next';
import InputLayout from '../../../../../components/form/components/inputLayout';
import { Controller, useForm } from 'react-hook-form';

import { useEffect, useState } from 'react';

import { http } from '../../../../../libs/axios';

import { toast } from 'react-toastify';
import SpinnerLoading from '../../../../../features/spinner/spinnerLoading';

type Props = {
  id?: string | undefined;
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  setUpdated: (value: boolean) => void;
  updated: boolean;
};
interface Contact {
  id: string;
  name: string;
}

interface FormData {
  message: string;
}

export default function ManualLead({
  id,
  openModal,
  setOpenModal,
  setUpdated,
  updated,
}: Props) {
  const [page, setPage] = useState<number>(1);
  const [fullData, setFullData] = useState<Contact[]>([]);
  const [itemsPerPage] = useState<number>(10);

  const [contactId, setContactId] = useState<string | null>(null);
  const [contactList, setContactList] = useState<any>([]);

  const { t } = useTranslation();
  const { control, register, handleSubmit, setValue } = useForm<FormData>();

  const onClickAway = () => {
    setOpenModal(false);
  };

  const [open, setOpen] = useState<boolean>(false);

  function sleep(duration: number): Promise<void> {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        if (contactList.length > 0) {
          setPage(page + 1);
          setFullData([...fullData, ...contactList]);
        }

        resolve();
      }, duration);
    });
  }

  useEffect(() => {
    fullData.length == 0 && setFullData(contactList);
  }, [contactList]);

  const fetchData = async (contactSearch?: string) => {
    try {
      const res = await http.get(
        `/contacts_leads?page=${page}&itemsPerPage=${itemsPerPage}${
          contactSearch ? `&contactName=${contactSearch.trim()}` : ''
        }`
      );
      setContactList(
        res?.data?.map((elem: any) => {
          const key = Object.keys(elem)[0];
          return { name: elem[key as any], id: key };
        })
      );
      if (contactSearch) {
        setFullData(
          res?.data?.map((elem: any) => {
            const key = Object.keys(elem)[0];
            return { name: elem[key as any], id: key };
          })
        );
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [openModal, open, page]);

  const onSubmit = (data: FormData) => {
    setValue('message', '');
    setOpenModal(false);
    const body = {
      property: id,
      contact: contactId,
      message: data.message,
    };

    submitDataToServer(body);
  };

  const submitDataToServer = async (body: {
    property: string | undefined;
    contact: string | null;
    message: string;
  }) => {
    try {
      await http.post(`/prospects/manual`, body);
      toast.success(
        `${t('propertiesPage:The_new_lead_is_added_successfully')}`,
        {
          position: 'bottom-center',
          autoClose: 5000,
          style: { width: '600px' },
        }
      );
      setUpdated(!updated);
    } catch {
      toast.error(
        `${t(
          'propertiesPage:A_lead_has_already_been_created_with_this_contact_for_the_property'
        )}`,
        {
          position: 'bottom-center',
          autoClose: 5000,
          style: { width: '600px' },
        }
      );
    }
  };
  return (
    <Modal
      withButton={false}
      isOpen={openModal}
      closeModal={() => setOpenModal(false)}
      openModal={() => setOpenModal(true)}
      sx={{
        maxHeight: '100vh',
        paddingTop: '150px !important',
      }}
      width={{
        md: '50%',
      }}
      boxClassName="pt-3 border rounded-0"
      title={t('propertiesPage:Manual_lead')}
      buttonProps={{
        title: 'Choose role',
      }}
    >
      <ClickAwayListener onClickAway={onClickAway}>
        <Fade in={openModal}>
          <Box>
            <Autocomplete
              sx={{ width: 300 }}
              open={open}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
                setPage(1);
                setFullData([]);
              }}
              getOptionLabel={(fullData: Contact) => fullData.name}
              options={fullData}
              onChange={(e, fullData) => setContactId(fullData?.id || null)}
              ListboxProps={{
                onScroll: async () => {
                  await sleep(1e3);
                },
              }}
              loading={!fullData}
              loadingText={!fullData && <SpinnerLoading />}
              noOptionsText={'No results found'}
              renderInput={(params) => (
                <TextField
                  onChange={(e) => {
                    setFullData([]);
                    fetchData(e.target.value);
                  }}
                  {...params}
                  label="Choose Contact"
                />
              )}
            />

            <InputLayout className="mt-4" label={t('usersPage:Message')}>
              <Controller
                name={'message'}
                control={control}
                defaultValue={''}
                render={({ field: { value, ...restField } }) => (
                  <FormControl
                    sx={{
                      border: '1px solid #c4c4c4',
                      borderRadius: '4px',
                      '&:hover': {
                        borderColor: '#1D1D1F',
                      },
                    }}
                  >
                    <TextareaAutosize
                      {...register('message')}
                      minRows={4}
                      maxRows={6}
                      value={value}
                      placeholder={t('usersPage:Enter_your_message_here')}
                      className={
                        'MuiInputBase-input MuiOutlinedInput-input rounded p-4 comment_area'
                      }
                      {...restField}
                    />
                  </FormControl>
                )}
              />
            </InputLayout>
            <Box className="w-full flex  justify-center px-16 py-8">
              <Button
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                disableElevation
                startIcon={<CheckIcon fontSize="small" />}
              >
                {t('usersPage:save')}
              </Button>
            </Box>
          </Box>
        </Fade>
      </ClickAwayListener>
    </Modal>
  );
}
