import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { isProdEnv, env } from '../../const';

export const SentryDsn = process.env.REACT_APP_SENTRY_DSN;

Sentry.init({
  environment: env,
  dsn: SentryDsn,
  integrations: [
    new BrowserTracing({
      tracingOrigins: ['*'],
    }),
    new Integrations.Breadcrumbs({
      console: false,
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  tracesSampler: () => (isProdEnv ? 1 : 0.2),
});
