import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

export default function EmailIcon({
  color,
  className,
  width = '16',
  height = '16',
}: IconProps) {
  const { mode } = useThemeMode();

  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <>
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2 3.5h12V12a.5.5 0 01-.5.5h-11A.5.5 0 012 12V3.5z"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M14 3.5L8 9 2 3.5"
        ></path>
      </svg>
    </>
  );
}
