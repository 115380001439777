export type selectFilterOptionT = {
  id: string | number;
  value?: string;
  firstname?: string;
  lastname?: string;
  label?: string;
  locale?: string;
};

export type FilterOptionT = {
  id: number;
  label: string;
  type: string;
  title: string;
  disableField: boolean;
  options?: selectFilterOptionT[];
};

export type FilterPropertiesT = {
  [key: string]: FilterOptionT[];
};

export const initialMainFilter: FilterPropertiesT = {
  property: [
    {
      id: 10,
      label: 'reference',
      title: 'Reference',
      type: 'listSearch',
      disableField: false,
      options: [],
    },
    {
      id: 11,
      label: 'title',
      title: 'Title',
      type: 'listSearch',
      disableField: false,
      options: [],
    },
    {
      id: 12,
      label: 'departments',
      title: 'Department',
      type: 'list-hierarchy',
      disableField: false,
    },
    {
      id: 13,
      label: 'service',
      title: 'Service',
      type: 'list',
      disableField: false,
      options: [],
    },
    {
      id: 14,
      label: 'agents',
      title: 'Agent',
      type: 'list',
      disableField: false,
      options: [],
    },
    {
      id: 15,
      label: 'status',
      title: 'Status',
      type: 'list',
      disableField: false,
      options: [],
    },
    {
      id: 16,
      label: 'category',
      title: 'Category',
      type: 'list',
      disableField: false,
      options: [],
    },
    {
      id: 17,
      label: 'type',
      title: 'Property type',
      type: 'list',
      disableField: true,
      options: [],
    },
  ],
  location: [
    {
      id: 181,
      label: 'country',
      title: 'Country',
      type: 'listSearch',
      disableField: false,
      options: [],
    },
    {
      id: 18,
      label: 'city',
      title: 'City',
      type: 'listSearch',
      disableField: false,
      options: [],
    },
    {
      id: 19,
      label: 'zipCode',
      title: 'Zip code',
      type: 'listSearch',
      disableField: false,
      options: [],
    },
    {
      id: 20,
      label: 'adresse',
      title: 'Addresse',
      type: 'listSearch',
      disableField: false,
      options: [],
    },
    {
      id: 203,
      label: 'canton',
      title: 'Canton',
      type: 'listSearch',
      disableField: false,
    },
    {
      id: 182,
      label: 'district',
      title: 'District',
      type: 'text',
      disableField: false,
    },
  ],
  price: [
    {
      id: 183,
      label: 'priceMin',
      title: 'Min',
      type: 'list',
      options: [],
      disableField: false,
    },
    {
      id: 118,
      label: 'priceMax',
      title: 'Min',
      type: 'list',
      options: [],
      disableField: false,
    },
  ],
  availability: [
    {
      id: 119,
      label: 'availableFrom',
      title: 'From',
      type: 'date',
      disableField: false,
    },
    {
      id: 120,
      label: 'availableTo',
      title: 'To',
      type: 'date',
      disableField: false,
    },
  ],
  rooms: [
    {
      id: 121,
      label: 'minRooms',
      title: 'Min',
      type: 'number',
      disableField: false,
    },
    {
      id: 122,
      label: 'maxRooms',
      title: 'Max',
      type: 'number',
      disableField: false,
      options: [],
    },
  ],
  bedrooms: [
    {
      id: 123,
      label: 'minBedrooms',
      title: 'Min',
      type: 'number',
      disableField: false,
    },
    {
      id: 124,
      label: 'maxBedrooms',
      title: 'Max',
      type: 'number',
      disableField: false,
    },
  ],
  bathrooms: [
    {
      id: 125,
      label: 'minBathrooms',
      title: 'Min',
      type: 'number',
      disableField: false,
    },
    {
      id: 126,
      label: 'maxBathrooms',
      title: 'Max',
      type: 'number',
      disableField: false,
    },
  ],
  Living_surface: [
    {
      id: 127,
      label: '"minLivingSurface',
      title: 'Min',
      type: 'number',
      disableField: false,
    },
    {
      id: 128,
      label: 'maxLivingSurface',
      title: 'Max',
      type: 'number',
      disableField: false,
    },
  ],
  Land_surface: [
    {
      id: 129,
      label: 'minLandSurface',
      title: 'Min',
      type: 'number',
      disableField: false,
    },
    {
      id: 130,
      label: 'maxLandSurface',
      title: 'Max',
      type: 'number',
      disableField: false,
      options: [],
    },
  ],
  parking: [
    {
      id: 130,
      label: 'parkings',
      title: 'Parking',
      type: 'number',
      disableField: false,
    },
  ],
};

export const textFieldStyle = {
  '& .MuiInputBase-root , .MuiSelect-select': {
    borderRadius: '1px',
  },
};

export const activePropertiesAction = [{ id: 1, label: 'archive' }];

export const propertiesFilterDataTypes = {
  reference: 'string',
  name: 'string',
  title: 'string',
  city: 'string',
  zipCode: 'string',
  adresse: 'string',
  agents: 'number',
  status: 'string',
  category: 'string',
  type: 'string',
  availableFrom: 'string',
  availableTo: 'string',
  priceMin: 'number',
  priceMax: 'number',
  minRooms: 'number',
  maxRooms: 'number',
  minBedrooms: 'number',
  maxBedrooms: 'number',
  minBathrooms: 'number',
  maxBathrooms: 'number',
  minLivingSurface: 'number',
  maxLivingSurface: 'number',
  minLandSurface: 'number',
  maxLandSurface: 'number',
  parkings: 'number',
  departments: 'string',
  service: 'string',
  country: 'string',
  canton: 'string',
  district: 'string',
};

export const initialPropertiesHistoryMainFilter: FilterPropertiesT = {
  history: [
    {
      id: 330,
      label: 'availableFrom',
      title: 'From',
      type: 'date',
      disableField: false,
    },
    {
      id: 331,
      label: 'availableTo',
      title: 'To',
      type: 'date',
      disableField: false,
    },
    {
      id: 332,
      label: 'historicAction',
      title: 'Action type',
      type: 'list',
      disableField: false,
      options: [],
    },

    {
      id: 333,
      label: 'doneBy',
      title: 'Done by',
      type: 'list',
      disableField: false,
      options: [],
    },
  ],
};

export const archivedPropertiesAction = [{ id: 5, label: 'restore' }];
