import { useTranslation } from 'react-i18next';
import TabGridTag from '../../../../features/tags/tabGridTag';
import { Box, Rating, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import useLocale from '../../../../hooks/useLocale';
import { convertDisplayedPrice } from '../../utils';
import FieldValueToDisplay from '../../../../components/common/fieldValueToDisplay';
import { IService } from '../../../../types/service';
import PropertyDetailsItems from './propertyDetailsItems';
import Star2Icon from '../../../../features/icons/star2Icon';

type propertyDescT = {
  title: string;
  description: string;
};

function PropertyDetails({
  property,
  firstImg,
}: {
  property: any;
  firstImg?: string;
}) {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const { translations, department } = property;

  const [currentService, setCurrentService] = useState<string>('');
  useEffect(() => {
    if (property?.department?.service) {
      setCurrentService(property?.department?.service?.keyname);
    }
  }, [property?.department?.service]);

  const setFieldByService = (name: string) => {
    return currentService.toLowerCase().includes('rent')
      ? property?.pricingRent?.[name]
      : property?.pricingBuy?.[name];
  };

  const propertyDescription: propertyDescT[] | any = useMemo(() => {
    const propertyDesc = Object.keys(translations)
      .filter((key) => translations[key].locale === locale)
      .map((key) => translations[key]);
    if (propertyDesc) {
      return propertyDesc;
    } else {
      return { title: '', description: '' };
    }
  }, [translations, locale]);

  const service = department?.service as IService;
  const serviceName = service?.translations[locale]?.name;

  return (
    <Box
      sx={{ color: 'text.primary' }}
      className="w-full bg-[#FFFFFF] rounded border p-8 flex flex-col"
    >
      <Box className="w-full relative">
        {firstImg && (
          <img src={firstImg} alt="d" className="w-full object-cover" />
        )}
        {serviceName && (
          <TabGridTag
            textContent={serviceName}
            className="absolute top-5 left-5 bg-blue border-0 capitalize"
            color="white"
            fontSize="14px"
          />
        )}
      </Box>
      <Box className="grid grid-cols-3 justify-start items-start my-4 w-full">
        <Typography variant="h2" className="col-span-2 text-lg font-medium">
          {`${
            propertyDescription[0]?.title ? propertyDescription[0]?.title : ''
          }`}
        </Typography>
        <Box className="col-span-1 flex flex-col border border-blue rounded md:ml-12 p-2">
          <Box component="span" className="text-blue/70 text-sm">
            {t('propertiesPage:price')}
          </Box>
          <Box component="span" className="text-blue">
            {`CHF ${
              setFieldByService('displayedPrice')
                ? convertDisplayedPrice(
                    setFieldByService('displayedPrice').toString()
                  ) + '.-'
                : '-'
            }`}
          </Box>
        </Box>
        <Box className="flex gap-2">
          <Rating
            name="rating"
            value={property.rating}
            max={3}
            readOnly
            icon={<Star2Icon className="mr-1" color="#FFC839" />}
            emptyIcon={<Star2Icon className="mr-1" color="#E6E8EC" />}
          />
        </Box>
      </Box>
      <Box className="relative w-full flex flex-col">
        <p className="text-base font-normal text-justify my-2 property_details">
          <FieldValueToDisplay
            fieldValue={propertyDescription[0]?.description}
            defaultValue={''}
          />
        </p>

        <PropertyDetailsItems property={property} />
      </Box>
    </Box>
  );
}

export default PropertyDetails;
