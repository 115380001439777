import { Box, Button, Fade, Modal } from '@mui/material';
import React, { useState } from 'react';
import { ClickAwayListener } from '@mui/base';
import classNames from 'classnames';
import UserIcon from '../../../features/icons/userIcon';
import axios from 'axios';
import SpinnerLoading from '../../../features/spinner/spinnerLoading';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import { ContactData } from './utils';

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  handleCloseModal: () => void;
  reference: string;
  contactData: ContactData;
};

const baseUrl = process.env.REACT_APP_API_HOST;

const ContactBrokerPopup = ({
  isModalOpen,
  handleCloseModal,
  reference,
  contactData,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [submitSent, setSubmitSent] = useState(false);
  const { t } = useTranslation('common');
  const onClickAway = () => {
    onClose();
  };

  const onClose = () => {
    handleCloseModal();
  };

  const onSubmit = () => {
    const body = {
      firstname: contactData.firstname,
      lastname: contactData.lastname,
      email: contactData.email,
      phone: contactData.phone,
      reference,
      message: 'i am interested in this property',
    };

    setIsLoading(true);
    axios.post(`${baseUrl}/leads`, body).then(() => {
      setIsLoading(false);
      setSubmitSent(true);
    });
  };

  return (
    <Modal open={isModalOpen} onClose={onClose}>
      <ClickAwayListener onClickAway={onClickAway}>
        <Fade in={isModalOpen}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              maxHeight: '90vh',
              overflowY: 'auto',
              width: {
                xs: '40%',
              },
              borderRadius: 2,
              bgcolor: 'background.paper',
              boxShadow: 24,
            }}
          >
            <Box
              className={classNames(
                'w-full h-full flex flex-col justify-between min-h-[300px] relative'
              )}
            >
              {isLoading ? (
                <div className={'absolute inset-0 z-10'}>
                  <SpinnerLoading />
                </div>
              ) : null}
              <div className="flex-grow w-full h-full p-8 flex-col justify-center items-center gap-8 inline-flex">
                {submitSent ? (
                  <>
                    <CheckIcon
                      sx={{
                        width: '64px',
                        height: '64px',
                        color: '#4CAF50',
                      }}
                    />
                    <div className="self-stretch text-center text-zinc-900 text-2xl font-normal leading-7">
                      {t('Thank_you')}
                      <br /> {t('your_request_has_been_sent_to_the_broker.')}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="relative">
                      <UserIcon
                        width={'64'}
                        height={'64'}
                        color={'#3446B9'}
                        strokeWidth={'0.6px'}
                        className={'w-[70px] h-[70px]'}
                      />
                    </div>
                    <div className="self-stretch text-center text-zinc-900 text-2xl font-normal font-['Inter'] leading-7">
                      {t('Do_you_want_to_contact_a_broker')}
                    </div>
                    <div className="self-stretch justify-center items-center gap-4 inline-flex">
                      <Button
                        onClick={() => handleCloseModal()}
                        variant="outlined"
                        sx={{ height: '48px', width: '89px', color: '#1D1D1F' }}
                      >
                        {t('no')}
                      </Button>
                      <Button
                        onClick={() => onSubmit()}
                        variant="contained"
                        disableElevation
                        sx={{ height: '48px', width: '89px' }}
                      >
                        {t('yes')}
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </Box>
          </Box>
        </Fade>
      </ClickAwayListener>
    </Modal>
  );
};

export default ContactBrokerPopup;
