import { useEffect } from 'react';
import useSWR from 'swr';

import { useAuth } from '../../hooks/useAuth';
import { useTotalNotification } from '../providers/notification';
import { ApiListing } from '../../types/api';
import { IPersonalNotification } from '../../types/notification';

export function useSetupNotifications() {
  const { setNewTotalNotifications } = useTotalNotification();

  const APIkey = '/personal_notifications?isRead=false';
  const isLoggedIn = useAuth().tokens;

  const { data: notificationsList } = useSWR<ApiListing<IPersonalNotification>>(
    isLoggedIn ? APIkey : null
  );

  useEffect(() => {
    //get personal notifications*
    if (notificationsList) {
      setNewTotalNotifications(notificationsList['hydra:totalItems']);
    }
  }, [notificationsList]);
}
