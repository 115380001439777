import TabbedSettings, { SettingsTabProps } from './tabs';
// import GeneralSettingsTab from './tabs/general';
import CustomAttributesSettings from './tabs/customAttributes';

const mapTabNamesToComponents = {
  // General: GeneralSettingsTab,
  Properties: CustomAttributesSettings,
} as Record<string, (props: SettingsTabProps) => JSX.Element>;

function SettingsPage() {
  return <TabbedSettings mapTabNamesToComponents={mapTabNamesToComponents} />;
}

export default SettingsPage;
