import { InputLabel, Switch } from '@mui/material';
import { UseFormRegister } from 'react-hook-form';

type Props = {
  label?: string | React.ReactNode;
  className?: string;
  isRequired?: boolean;
  checked?: boolean;
  disabled?: boolean;
  name: string;
  handleChange?: () => void;
  register: UseFormRegister<any>;
};

const SwitcherFormField = ({
  label,
  isRequired = false,
  checked = false,
  disabled,
  name,
  register,
  handleChange,
  className = 'flex items-center justify-between gap-4',
}: Props) => {
  return (
    <div className={className}>
      {label && (
        <InputLabel className="!text-xs">
          {label}
          {isRequired && <span className="text-red-500">*</span>}
        </InputLabel>
      )}
      <Switch
        defaultChecked={checked}
        color="primary"
        disabled={disabled}
        {...register(name, { onChange: () => handleChange && handleChange() })}
      />
    </div>
  );
};

export default SwitcherFormField;
