import { INetworkListItem } from '../../../../types/networks';
import { Link } from 'react-router-dom';
import useLocale from '../../../../hooks/useLocale';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import NetworkAgencyItem from './networkAgencyItem';
import { replaceEnding } from '../index';
import NetworkItemActionMenu from './actionMenu';

interface Props {
  item: INetworkListItem;
  mutate?: () => void;
}

const statuses = {
  waiting: 1,
  joined: 2,
  multiple: 3,
};

const NetworkCard = (props: Props) => {
  const { item, mutate } = props;
  const { locale } = useLocale();
  const { t } = useTranslation();

  return (
    <div
      className={
        '@container p-2 pt-4 bg-white rounded flex flex-col justify-between'
      }
    >
      <div className={'flex flex-col grow h-full justify-between'}>
        <div className={'mb-4'}>
          <div className="flex items-center gap-2 mb-4">
            <Tooltip title={t('networks:Enabled')} arrow placement="top">
              <div
                className={classNames(
                  'shrink-0 w-[10px] h-[10px] rounded-full',
                  {
                    'bg-green-500': item.enabled,
                    'bg-gray-500': !item.enabled,
                  }
                )}
              />
            </Tooltip>
            <Link to={`/networks/${item.id}`}>
              <div className="cursor-pointer text-zinc-900 text-2xl font-normal leading-tight hover:opacity-80 duration-300 transition-opacity">
                {item?.translations?.[locale]?.name}
              </div>
            </Link>
          </div>
          <div>{item?.translations?.[locale]?.description}</div>
        </div>
        {item.status && !item.userAuthorizedToEdit ? (
          <div className={'flex pt-2 pb-4'}>
            <div
              className={classNames('px-2 py-1 border rounded', {
                'border-[#F50057] text-[#F50057]':
                  item.status === statuses.waiting,
                'border-[#4CAF50] text-[#4CAF50]':
                  item.status === statuses.joined,
              })}
            >
              <div className="text-sm font-normal leading-none first-letter:uppercase">
                {item.status === statuses.waiting
                  ? t('networks:WaitingForYourResponse')
                  : ''}
                {item.status === statuses.joined ? t('networks:Joined') : ''}
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className={'flex flex-col'}>
        <div className={'divide-y flex flex-col border-b border-t'}>
          <div className="basis-0 py-2 justify-start items-center gap-1.5 @xs:gap-2.5 flex opacity-60">
            <PeopleOutlineIcon fontSize={'small'} />
            <div className="text-zinc-900 text-sm leading-none  whitespace-nowrap">
              <span>
                {replaceEnding(
                  t('common:Member'),
                  item.membersCount,
                  t('common:Members')
                )}
              </span>
            </div>
          </div>
        </div>
        <div className={'py-2 flex border-b mb-2'}>
          <NetworkAgencyItem {...item.creator} />
        </div>
        <NetworkItemActionMenu mutate={mutate} item={item} />
      </div>
    </div>
  );
};

export default NetworkCard;
