import PropertyGallery from '../../properties/singleProperty/propertyInfos/propertyGallery';
import { IPromotionApi } from '../../../types/promotion';
import { useMemo } from 'react';

interface Props {
  data: IPromotionApi;
}

function PromotionGallery({ data }: Props) {
  const { images } = data;

  const propertyGalleries = useMemo(() => {
    let propertyGalleriesArray = null;
    propertyGalleriesArray = images;
    if (propertyGalleriesArray) {
      return propertyGalleriesArray.filter(({ type }: any) =>
        type.includes('image')
      );
    } else {
      return [];
    }
  }, [images]);

  const galleryImgs: string[] = propertyGalleries.map(
    ({ url }: any) => url.large
  );

  if (!data || !galleryImgs.length) return null;

  return <PropertyGallery propertyGalleries={galleryImgs} />;
}

export default PromotionGallery;
