import SpinnerLoading from '../../../../features/spinner/spinnerLoading';
import * as React from 'react';
import { ContactTapInnerProps } from './search-criteria-tab';
import Tasks from '../../components/tasks';
import useSWR from 'swr';

const TasksTab = (props: ContactTapInnerProps) => {
  const { data, id } = props;
  const { data: items, mutate } = useSWR(`/contact/${id}/tasks`);

  return data ? (
    <Tasks items={items && items['hydra:member']} id={id} mutate={mutate} />
  ) : (
    <div className={'col-start-1 col-end-4 min-h-[500px]'}>
      <SpinnerLoading />
    </div>
  );
};

export default TasksTab;
