import { FC, FormEventHandler, useState } from 'react';
import useSWR from 'swr';

import {
  IContactOption,
  IContactVisitApi,
  PropertyStepperStep,
} from '../../../../../types/property';
import { stepperFormId } from '../../../../../const';
import StepsFieldset from '../../../../../components/stepper/stepsFieldset';
import { IContact } from '../../../../../types/contacts';
import { ApiListing } from '../../../../../types/api';
import ContactsMultiselect from './contactsMultiselect';
import { t } from 'i18next';

interface Address {
  addresse: string | null;
  city: string;
  country: string;
  zipcode: string;
}

export interface IContactVisit {
  id: number;
  fullname: string;
  email: string;
  phone: string;
  address: {
    de: Address;
    en: Address;
    fr: Address;
  };
}

function appendCountIfAny(str: string, count = 0) {
  if (!count) return str;
  return `${str} (${count})`;
}

function extractContactIds(options?: IContactOption[]) {
  return options?.map((o) => String(o.contact)) || [];
}

function extractVisit(options?: IContactVisitApi | string | null): string[] {
  if (typeof options === 'string') {
    return [options];
  } else if (options) {
    return [options.id.toString()];
  }
  return [];
}

function extractMainContactId(options?: IContactOption[]) {
  return options?.find((o) => o.mainContact)?.contact;
}

function zipContacts(options: string[], selectedId = ''): IContactOption[] {
  return options.map((id) => ({
    contact: id,
    mainContact: selectedId === id,
  }));
}

const PropertyOwnerStep: FC<PropertyStepperStep> = ({
  defaultValues,
  onSubmit,
}) => {
  const { data: ownersOptions } = useSWR<ApiListing<IContact>>(
    '/contacts?type=owner'
  );
  const { data: externalsOptions } = useSWR<IContact[]>(
    '/contacts/external-brokers'
  );
  const { data: externalVisits } = useSWR<IContactVisit[]>(
    '/contacts/external-visits'
  );

  const [owners, setOwners] = useState<string[]>(
    extractContactIds(defaultValues?.owners)
  );
  const [externals, setExternals] = useState<string[]>(
    extractContactIds(defaultValues?.externals)
  );

  const [visits, setVisits] = useState<string[]>(
    extractVisit(defaultValues?.visitContact)
  );

  const [mainOwner, setMainOwner] = useState(
    extractMainContactId(defaultValues?.owners)
  );
  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();

    onSubmit?.({
      owners: zipContacts(owners, mainOwner),
      externals: zipContacts(externals),
      visitContact: visits.length > 0 ? visits[0] : null,
    });
  };

  return (
    <form id={stepperFormId} onSubmit={handleSubmit} className="grid gap-16">
      <StepsFieldset title={appendCountIfAny(t('common:Owners'), 0)}>
        <div className="col-span-2">
          <ContactsMultiselect
            contacts={ownersOptions?.['hydra:member']}
            value={owners}
            onChange={setOwners}
            mainContact={mainOwner}
            onChangeMainContact={setMainOwner}
            label={t('common:Owners')}
            title={'Selected_Owners'}
          />
        </div>
      </StepsFieldset>
      <StepsFieldset title={appendCountIfAny(t('common:External_contacts'), 0)}>
        <div className="col-span-2">
          <ContactsMultiselect
            contacts={externalsOptions}
            value={externals}
            onChange={setExternals}
            label={t('common:External_contacts')}
            title={'Selected_External_contacts'}
          />
        </div>
      </StepsFieldset>
      <StepsFieldset title={appendCountIfAny(t('common:Visit_contact'), 0)}>
        <div className="col-span-2">
          <ContactsMultiselect
            externalVisits={externalVisits}
            value={visits}
            onChange={setVisits}
            label={t('common:Visit_contact')}
            title={'Selected_Visit_contact'}
          />
        </div>
      </StepsFieldset>
    </form>
  );
};

export default PropertyOwnerStep;
