import { Suspense, lazy } from 'react';
import { Skeleton } from '@mui/material';
import { DocumentProps, ThumbnailProps } from 'react-pdf';

const PdfPreview = lazy(() => import('./pdfPreview'));

const LazyPdfPreview = ({
  skeletonHeight,
  documentProps = {},
  thumbnailProps = {},
}: {
  skeletonHeight: number;
  documentProps?: DocumentProps;
  thumbnailProps?: ThumbnailProps;
}) => {
  return (
    <Suspense
      fallback={
        <Skeleton
          height={skeletonHeight}
          variant="rectangular"
          animation="wave"
        />
      }
    >
      <PdfPreview
        documentProps={documentProps}
        thumbnailProps={thumbnailProps}
        skeletonHeight={skeletonHeight}
      />
    </Suspense>
  );
};

export default LazyPdfPreview;
