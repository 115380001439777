import { NavLink, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

import { Collapse, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { LinkT } from './nav';
import { Dispatch, SetStateAction } from 'react';

type Props = {
  item: LinkT;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  isChild?: boolean;
  collapsed?: boolean;
  toggleCollapse?: (name: string) => void;
};

const NavLinkItem = ({
  item,
  isChild,
  collapsed,
  toggleCollapse,
  setIsOpen,
}: Props) => {
  const { path, name, icon, items } = item;
  const location = useLocation();
  const { pathname } = location;
  function onClick(currentName: string) {
    if (Array.isArray(items) && toggleCollapse) {
      toggleCollapse(currentName);
    }
  }

  let expandIcon;
  if (Array.isArray(items) && items.length) {
    expandIcon = collapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />;
  }

  return (
    <Box component="li">
      {!items ? (
        <NavLink
          onClick={() => onClick(name)}
          to={path ? path : ''}
          className={`leading-[100%] font-normal lg:pl-6 w-[95%] mx-auto flex gap-4 2xl:gap-8 2xl:gap-x-2 ${
            !icon ? 'pl-8 text-[8px]' : ''
          } ${
            path === '/'
              ? pathname === '/' && 'bg-white text-blue'
              : pathname === path
              ? 'bg-white text-blue'
              : ''
          } ${isChild ? 'h-9 pt-0 2xl:h-10 2xl:pt-2' : 'h-12 pt-4'}`}
        >
          {icon}
          <Typography
            onClick={() => {
              console.log(name, setIsOpen);
              setIsOpen && setIsOpen(false);
            }}
            className={`2xl:pl-6 ${
              isChild ? '!text-sm xl:!text-base lg:pl-8 2xl:!pl-12' : ''
            }  ${name.replace(' ', '_')}`}
          >
            {name}
          </Typography>{' '}
          {/* with child items*/}
          <span className={!icon ? 'pl-0' : 'pl-6'}>{expandIcon}</span>
        </NavLink>
      ) : (
        <Box
          onClick={() => onClick(name)}
          className={`leading-[100%] w-[95%] mx-auto lg:pl-6  cursor-pointer font-normal flex gap-x-2 2xl:gap-x-6 h-12 pt-2 2xl:pt-4 ${
            !icon ? 'pl-16' : ''
          }`}
        >
          <Box>{icon}</Box>
          <Box className="w-full flex justify-between 2xl:grid 2xl:grid-cols-2 2xl:gap-10">
            <Typography style={{ marginLeft: '9px' }} className={`${name}`}>
              {name}
            </Typography>{' '}
            {/* without child items*/}
            <span>{expandIcon}</span>
          </Box>
        </Box>
      )}

      <Collapse in={collapsed} timeout="auto" unmountOnExit>
        <Box component="ul" className="flex flex-col">
          {items && items.length
            ? items.map((subItem: LinkT, index: number) => (
                <NavLinkItem
                  key={`${subItem.name}${index}`}
                  item={subItem}
                  isChild
                  setIsOpen={setIsOpen}
                />
              ))
            : null}
        </Box>
      </Collapse>
    </Box>
  );
};

export default NavLinkItem;
