import { Skeleton } from '@mui/material';
import { GridColumns, GridValidRowModel } from '@mui/x-data-grid';
import FavoriteIcon from '../../../features/icons/favoriteIcon';
import NotificationMenu from '../../../pages/personalNotifications/components/notificationMenu';
import { http } from '../../../libs/axios';
import FieldValueToDisplay from '../../common/fieldValueToDisplay';
import { EnsureShortLocale } from '../../common/ensureShortLocale';
import { formatTime } from '../../../helpers/utils';

export const handleTypeTag = (type: string, keyname: string) => {
  const color = keyname === 'lead' ? '#F50057' : '#3446B9';
  return (
    <div
      className={`px-2 py-1 text-sm leading-[14px] w-fit text-[${color}] border border-[${color}] capitalize rounded-[4px]`}
    >
      {type}
    </div>
  );
};

const openDetailsModal = (rowId: number, isRead: boolean) => {
  !isRead && http.patch('/personal_notifications/' + rowId, { isRead: true });
  localStorage.setItem('openNotificationModal', 'true');
};
// const isLarge = localStorage.getItem('isLarge');
// const isMacLarge = localStorage.getItem('isMacLarge');
// const isMediumLarge = localStorage.getItem('isMediumLarge');
const isMedium = localStorage.getItem('isMedium');
// const currentLanguage = localStorage.getItem('i18nextLng') || 'en';
export const formatDayAndMonth = (date: any) => {
  const options = {
    day: '2-digit',
    month: 'short',
  };
  const formattedDate = new Intl.DateTimeFormat('us-US', options as any).format(
    new Date(date)
  );

  return formattedDate.split(' ').reverse().join(' ');
};
export const notificationsListingConfig: GridColumns<GridValidRowModel> = [
  {
    field: 'type',
    headerName: 'Type',
    width: isMedium === 'true' ? 120 : 150,
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({ row }) => {
      const currentLanguage = localStorage.getItem('i18nextLng') || 'en';
      const TypeName =
        row?.type?.translations[`${EnsureShortLocale(currentLanguage)}`]
          ?.name || row?.type?.keyname;
      const TypeKeyName = row?.type?.keyname;
      return <p>{handleTypeTag(TypeName, TypeKeyName) || '-'}</p>;
    },
  },
  {
    field: 'date',
    headerName: 'Date',
    width: isMedium === 'true' ? 130 : 150,
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({ row }) => {
      return (
        <p style={{ fontWeight: !row?.isRead ? '600' : '400' }}>
          {formatDayAndMonth(row?.date) || '-'}
          {', '}
          {formatTime(row?.date)}
        </p>
      );
    },
  },
  {
    field: 'notification',
    headerName: 'Notification',
    width: isMedium === 'true' ? 842 : 1160,
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({ row }) => {
      const currentLanguage = localStorage.getItem('i18nextLng') || 'en';
      return (
        <div
          className="cursor-pointer"
          onClick={() => openDetailsModal(row?.id, row?.isRead)}
        >
          <p style={{ fontWeight: !row?.isRead ? '600' : '400' }}>
            <FieldValueToDisplay
              fieldValue={
                row?.translations[`${EnsureShortLocale(currentLanguage)}`]
                  ?.content || '-'
              }
              defaultValue={''}
            />
          </p>{' '}
        </div>
      );
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: isMedium === 'true' ? 97 : 100,
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({ row }) => {
      return (
        <div className="w-full flex items-center justify-between">
          <FavoriteIcon defaultValue={row?.isStarred} rowId={row.id} />
          <NotificationMenu rowId={row.id} />
        </div>
      );
    },
  },
];
export const starredNotificationsListingConfig: GridColumns<GridValidRowModel> =
  [
    {
      field: 'type',
      headerName: 'Type',
      width: isMedium === 'true' ? 100 : 130,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        const currentLanguage = localStorage.getItem('i18nextLng') || 'en';
        const TypeName =
          row?.type?.translations[`${EnsureShortLocale(currentLanguage)}`]
            ?.name || row?.type?.keyname;
        const TypeKeyName = row?.type?.keyname;
        return <p>{handleTypeTag(TypeName, TypeKeyName) || '-'}</p>;
      },
    },
    {
      field: 'date',
      headerName: 'Date',
      width: isMedium === 'true' ? 100 : 130,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <p style={{ fontWeight: !row?.isRead ? '600' : '400' }}>
            {formatDayAndMonth(row?.date) || '-'}
          </p>
        );
      },
    },
    {
      field: 'notification',
      headerName: 'Notification',
      width: isMedium === 'true' ? 842 : 1164,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        const currentLanguage = localStorage.getItem('i18nextLng') || 'en';
        return (
          <div
            className="cursor-pointer"
            onClick={() => openDetailsModal(row?.id, row?.isRead)}
          >
            <p style={{ fontWeight: !row?.isRead ? '600' : '400' }}>
              <FieldValueToDisplay
                fieldValue={
                  row?.translations[`${EnsureShortLocale(currentLanguage)}`]
                    ?.content || '-'
                }
                defaultValue={''}
              />
            </p>{' '}
          </div>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: isMedium === 'true' ? 97 : 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <div className="w-full flex items-center justify-between">
            <FavoriteIcon
              defaultValue={row?.isStarred}
              rowId={row.id}
              as="starred"
            />
            <NotificationMenu rowId={row.id} />
          </div>
        );
      },
    },
  ];
export const DeletedNotificationsListingConfig: GridColumns<GridValidRowModel> =
  [
    {
      field: 'type',
      headerName: 'Type',
      width: isMedium === 'true' ? 100 : 130,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        const currentLanguage = localStorage.getItem('i18nextLng') || 'en';
        const TypeName =
          row?.type?.translations[`${EnsureShortLocale(currentLanguage)}`]
            ?.name || row?.type?.keyname;
        const TypeKeyName = row?.type?.keyname;
        return <p>{handleTypeTag(TypeName, TypeKeyName) || '-'}</p>;
      },
    },
    {
      field: 'date',
      headerName: 'Date',
      width: isMedium === 'true' ? 100 : 130,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <p style={{ fontWeight: !row?.isRead ? '600' : '400' }}>
            {formatDayAndMonth(row?.date) || '-'}
          </p>
        );
      },
    },
    {
      field: 'notification',
      headerName: 'Notification',
      width: isMedium === 'true' ? 939 : 1264,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        const currentLanguage = localStorage.getItem('i18nextLng') || 'en';
        return (
          <div
            className="cursor-pointer"
            onClick={() => openDetailsModal(row?.id, row?.isRead)}
          >
            <p style={{ fontWeight: !row?.isRead ? '600' : '400' }}>
              <FieldValueToDisplay
                fieldValue={
                  row?.translations[`${EnsureShortLocale(currentLanguage)}`]
                    ?.content || '-'
                }
                defaultValue={''}
              />
            </p>{' '}
          </div>
        );
      },
    },
  ];

export const skeletonPropertiesListingConfig: GridColumns<GridValidRowModel> = [
  {
    field: 'type',
    width: isMedium === 'true' ? 80 : 100,
    sortable: false,
    disableColumnMenu: true,
    renderCell: () => {
      return (
        <Skeleton variant="text" width={80} height={20} animation="wave" />
      );
    },
  },
];
