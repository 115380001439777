import { useTranslation } from 'react-i18next';

import { UncontrolledColorField } from '../../../../components/form/colorField';
import InputLayout from '../../../../components/form/components/inputLayout';
import ImageInput from '../imageInput';
import { LayoutPart } from '../../../../types/brochureEditor';

const LayoutPartOptions = ({
  values,
  onChange,
}: {
  values: LayoutPart;
  onChange: (option: keyof LayoutPart, value: any) => unknown;
}) => {
  const { t } = useTranslation('brochureEditor');

  return (
    <div className="pt-6 grid gap-4">
      <UncontrolledColorField
        label={t('border_color')}
        className="bg-white"
        isSmall
        value={values.borderColor}
        onChange={(value) => onChange('borderColor', value)}
      ></UncontrolledColorField>
      <UncontrolledColorField
        label={t('background_color')}
        className="bg-white"
        isSmall
        value={values.bgColor}
        onChange={(value) => onChange('bgColor', value)}
      ></UncontrolledColorField>
      <UncontrolledColorField
        label={t('text_color')}
        className="bg-white"
        isSmall
        value={values.textColor}
        onChange={(value) => onChange('textColor', value)}
      ></UncontrolledColorField>
      <InputLayout label={t('background_image')}>
        <ImageInput
          value={values.bgImage || undefined}
          onChange={(value) => onChange('bgImage', value)}
        />
      </InputLayout>
    </div>
  );
};

export default LayoutPartOptions;
