import { Controller } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';
import React, { useState } from 'react';
import { IExtendedContactSearch } from '../forms/matching-search-form';
import SearchCriteriaModal from './modal';
import useLocale from '../../../../hooks/useLocale';

interface Props {
  item: IExtendedContactSearch;
  control: any;
  setValue: any;
  index: number;
  contactId: number | string;
}

const SearchCriteriaItem = (props: Props) => {
  const { item, control, index, setValue, contactId } = props;
  const [searchPopupIsOpen, setSearchPopupIsOpen] = useState(false);
  const { locale } = useLocale();

  const onViewClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    setSearchPopupIsOpen(true);
  };

  const onViewClose = () => {
    setSearchPopupIsOpen(false);
  };

  return (
    <>
      <div className={'flex justify-between items-center '} key={item.id}>
        <Controller
          key={item.id}
          control={control}
          name={`search.${index}.checked`}
          render={({ field }) => (
            <label className={'cursor-pointer flex items-center'}>
              <Checkbox
                {...field}
                onChange={(e) => {
                  setValue(`search.${index}.id`, item.id);
                  field.onChange(e);
                }}
                defaultChecked={!!field.value}
              />
              <span className={'text-[14px] capitalize'}>
                {item.translations?.[locale]?.title}
              </span>
            </label>
          )}
        />
        <button onClick={(e) => onViewClick(e)}>
          (<span className={'text-blue'}>view</span>)
        </button>
      </div>
      <SearchCriteriaModal
        contactId={contactId}
        isOpen={searchPopupIsOpen}
        item={item}
        onClose={onViewClose}
      />
    </>
  );
};

export default SearchCriteriaItem;
