import React, { useState, useEffect } from 'react';
import InputFormField from '../../../../components/form/inputFormField';
import { IconButton, InputAdornment } from '@mui/material';
import CloseIcon from '../../../../features/icons/closeIcon';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import AiSearch from '../../../../features/icons/iaSearch';
import { http } from '../../../../libs/axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import useLocale from '../../../../hooks/useLocale';
export const AnimatedInput = ({
  passedPlaceholder: passedPlaceholder = '',
  itemsPerPage,
  page,
  setSearch,
  setAiListing,
  aiListing,
  isLoadingAi,
  setIsLoadingAi,
}: any) => {
  const [placeholder, setPlaceholder] = useState('');
  const [lineIndex, setLineIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const { locale } = useLocale();
  useEffect(() => {
    const interval = setInterval(() => {
      const currentLine = passedPlaceholder[lineIndex];
      setPlaceholder(currentLine.slice(0, charIndex));
      if (charIndex >= currentLine.length) {
        setCharIndex(0);
        setLineIndex((prevIndex) => (prevIndex + 1) % passedPlaceholder.length);
      } else {
        setCharIndex((prevIndex) => prevIndex + 1);
      }
    }, 150);

    return () => clearInterval(interval);
  }, [charIndex, lineIndex, passedPlaceholder]);

  useEffect(() => {
    if (page > 0) {
      handleSearch();
    }
  }, [page]);
  const { t } = useTranslation('propertiesPage');
  const handleSearch = async () => {
    if (inputValue) {
      setIsLoadingAi(true);
      try {
        const response = await http.get(
          `/v1/properties/ai-search?page=${page}&itemsPerPage=${itemsPerPage}?lang=${locale}&ai-search=${inputValue}`
        );

        setAiListing(response.data);

        if (Object.keys(response.data.aiDataSearch).length > 0) {
          toast.success(
            t('Your_request_has_been_successfully_processed') as string,
            {
              position: 'bottom-center',
              autoClose: 5000,
              style: { width: '500px' },
            }
          );
        } else {
          toast.warning(
            t(
              'Please_try_using_different_criteria_or_check_the_spelling_of_your_search_terms'
            ) as string,
            {
              position: 'bottom-center',
              autoClose: 5000,
              style: { width: '500px' },
            }
          );
        }
      } catch (error: any) {
        toast.warning(
          t(
            'We_re_sorry_but_something_went_wrong_Please_refine_your_request_and_try_again'
          ) as string,
          {
            position: 'bottom-center',
            autoClose: 5000,
            style: { width: '500px' },
          }
        );
        console.log(error);
      } finally {
        setIsLoadingAi(false);
      }
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div style={{ width: '100%' }} className="gradientRadius">
      <InputFormField
        sx={{
          width: '100%',
          background: 'white',
          borderRadius: '5px',
        }}
        placeholder={placeholder}
        inputVariant={'standard'}
        id="words"
        isSmall
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <AiSearch color="#5B8BBF" className="w-[20px] ml-3" />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <button
                className={`py-1 px-4 rounded-md ${
                  isTyping
                    ? 'bg-blue-600 text-white'
                    : 'bg-gray-200 text-[#9ba2ad]'
                } Inter font-medium opacity-05`}
                disabled={!isTyping}
                onClick={handleSearch}
              >
                {isLoadingAi ? 'Loading...' : 'OK'} <ReplyAllIcon />
              </button>
              <IconButton
                size="small"
                onClick={() => {
                  setSearch(false);
                  setAiListing([]);
                }}
                aria-label={'clear'}
              >
                <CloseIcon className="text-lg" />
              </IconButton>
            </InputAdornment>
          ),
        }}
        onChange={(e) => {
          const value = e.target.value;
          setInputValue(value);
          setIsTyping(value.length > 0);
        }}
        onKeyPress={handleKeyPress}
      />
      {aiListing &&
        aiListing.aiDataSearch &&
        Object.keys(aiListing?.aiDataSearch)?.length > 0 && (
          <div
            className=" !bg-white py-1"
            style={{
              display: 'flex',
              padding: '6px',
              flexWrap: 'wrap',
              borderImage: 'linear-gradient(to right, #5b8bbf, pink) 1',
              borderStyle: 'dashed solid',
              borderTopWidth: '2px',
              borderRadius: '5px',
            }}
          >
            {Object.keys(aiListing?.aiDataSearch).map(
              (item: any, i: number) => {
                if (aiListing?.aiDataSearch[item]) {
                  const isLastValidItem = Object.keys(aiListing?.aiDataSearch)
                    .slice(i + 1)
                    .every((key) => !aiListing?.aiDataSearch[key]);
                  return (
                    <div
                      key={i}
                      className="flex font-['Inter']"
                      style={{ wordWrap: 'break-word' }}
                    >
                      <span className="text-blue capitalize">
                        {item + ' = '}
                      </span>
                      <span className="pl-1">
                        {aiListing?.aiDataSearch[item]}
                      </span>
                      {!isLastValidItem && (
                        <span className="text-[#80a3dc]">
                          &nbsp; {' AND '}&nbsp;
                        </span>
                      )}
                    </div>
                  );
                }
                return null;
              }
            )}
          </div>
        )}
    </div>
  );
};
