import React from 'react';
import { Link } from 'react-router-dom';
import { ILeadItem } from '../../../types/contacts';
import { EnsureShortLocale } from '../../../components/common/ensureShortLocale';
import classNames from 'classnames';
import LeadDateAndStatus from '../../../components/tableGridX/leads/dateAndStatus';
import PermissionIcon from '../../../features/icons/permissionIcon';
import LeadSource from '../../../components/tableGridX/leads/source';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useTranslation } from 'react-i18next';

// Utility function to find images
export const findImagesTab = (item: ILeadItem): string | undefined => {
  if (Array.isArray(item?.property.media)) {
    return undefined;
  }
  const images = item?.property.media.images;
  if (images && images.length > 0) {
    const mainImage = images.find((elem) => elem.isMain);
    return mainImage ? mainImage.url.large : images[0]?.url.large;
  }
  return undefined;
};

// Lead Date and Status Cell
export const DateCell = ({
  item,
  wrapperClassName,
  hideIconOnMobile,
}: {
  item: ILeadItem;
  wrapperClassName?: string;
  hideIconOnMobile?: boolean;
}) => {
  return (
    <div className={classNames('flex items-start', wrapperClassName)}>
      <LeadDateAndStatus
        lead={item}
        withStatus={false}
        hideIconOnMobile={hideIconOnMobile}
      />
    </div>
  );
};

// Deal Type Cell
export const TypeCell = ({
  item,
  locale,
  wrapperClassName,
  isSmall,
}: {
  item: ILeadItem;
  locale: string;
  wrapperClassName?: string;
  isSmall?: boolean;
}) => {
  const lang = `${EnsureShortLocale(locale)}`;
  return (
    <div className={classNames('flex items-start', wrapperClassName)}>
      <div
        className="inline-flex py-1 px-2 rounded-md items-center max-w-full overflow-hidden"
        style={{ border: '1px solid #3446B9 ' }}
      >
        <span
          title={item?.type?.translations?.[lang]?.name}
          className={classNames(
            'whitespace-nowrap text-sm overflow-hidden text-ellipsis font-medium text-blue leading-5 first-letter:uppercase',
            {
              'text-sm': isSmall,
            }
          )}
        >
          {item?.type?.translations?.[lang]?.name}
        </span>
      </div>
    </div>
  );
};

// Status Cell
export const StatusCell = ({
  item,
  wrapperClassName,
}: {
  item: ILeadItem;
  wrapperClassName?: string;
}) => {
  const { t } = useTranslation();
  const inProgress = item?.status.keyname === 'in progress';
  return (
    <div className={classNames('flex items-start', wrapperClassName)}>
      <div
        className={classNames(
          'px-2 py-1 rounded border justify-start items-center gap-1 inline-flex overflow-hidden max-w-full',
          {
            'border-[#4CAF50]': !inProgress,
            'border-[#F50057]': inProgress,
          }
        )}
      >
        <div
          title={t(!inProgress ? 'common:processed' : 'common:not_processed')}
          className={classNames(
            'whitespace-nowrap text-sm overflow-hidden text-ellipsis font-medium',
            {
              'text-[#4CAF50]': !inProgress,
              'text-[#F50057]': inProgress,
            }
          )}
        >
          {t(!inProgress ? 'common:processed' : 'common:not_processed')}
        </div>
      </div>
    </div>
  );
};

// Property Reference Cell
export const ReferenceCell = ({
  item,
  locale,
  wrapperClassName,
  isSmall,
}: {
  item: ILeadItem;
  locale: string;
  wrapperClassName?: string;
  isSmall?: boolean;
}) => {
  const id = item.property ? item.property.id : '';
  return (
    <div
      className={classNames('@container flex gap-2 w-full', wrapperClassName)}
    >
      <Link
        to={`/properties/${id}`}
        id={`img_property_${id}`}
        className={classNames(
          'shrink-0 max-w-[83px] min-w-[83px] h-[100%] bg-gray-400',
          {
            'hidden @[15rem]:flex': isSmall,
          }
        )}
      >
        <img
          src={findImagesTab(item) || '/img/default_image.jpg'}
          alt=""
          className="w-[100%] h-[100%] !object-cover max-w-inherit"
        />
      </Link>
      <div
        className={classNames('pt-1 flex flex-col gap-2 items-start', {
          'w-full @[15rem]:w-3/5': isSmall,
          'w-3/5': !isSmall,
        })}
      >
        {item.property?.translations?.[locale]?.title && (
          <Link
            className="inline-flex max-w-full"
            to={`/properties/${id}`}
            id={`img_property_${id}`}
          >
            <p
              title={item?.property?.translations?.[locale]?.title}
              className={classNames(
                'whitespace-nowrap max-w-full font-medium overflow-ellipsis overflow-hidden',
                {
                  'text-sm': isSmall,
                }
              )}
            >
              {item?.property?.translations?.[locale]?.title}
            </p>
          </Link>
        )}
        {item.property &&
        (item.property.referenceAgency || item.property.reference) ? (
          <div
            className="inline-flex py-1 px-2 rounded-md items-center"
            style={{ border: '1px solid #3446B9 ' }}
          >
            <PermissionIcon
              className="mr-2 col-span-1 hidden @[15rem]:flex"
              color="#3446B9"
            />
            <span
              className={classNames('text-blue leading-5', {
                'text-sm': isSmall,
              })}
            >
              {item.property.referenceAgency || item.property.reference}
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

// Contact Cell
export const ContactCell = ({
  item,
  wrapperClassName,
  isSmall,
}: {
  item: ILeadItem;
  wrapperClassName?: string;
  isSmall?: boolean;
}) => {
  return (
    <div
      className={classNames(
        '@container flex items-start w-full',
        wrapperClassName
      )}
    >
      <Link
        to={`/contacts/${item.contact?.id}/contact-information`}
        id={`img_contact_${item.contact?.id}`}
        className="max-w-full font-medium inline-flex items-center justify-center gap-2 overflow-hidden"
      >
        <PersonOutlineOutlinedIcon
          fontSize="small"
          className={'!hidden @[11rem]:!inline-block'}
        />
        <p
          title={item.contact?.fullname}
          className={classNames(
            'whitespace-nowrap max-w-full overflow-ellipsis overflow-hidden',
            {
              'text-sm': isSmall,
            }
          )}
        >
          {item.contact?.fullname}
        </p>
      </Link>
    </div>
  );
};

// Source Cell
export const SourceCell = ({
  item,
  wrapperClassName,
  customHeight,
  hideIconOnMobile,
}: {
  item: ILeadItem;
  wrapperClassName?: string;
  customHeight?: boolean;
  hideIconOnMobile?: boolean;
}) => {
  return (
    <div
      className={classNames(
        'flex items-start @container w-full',
        wrapperClassName
      )}
    >
      <LeadSource
        lead={item}
        customHeight={customHeight}
        hideIconOnMobile={hideIconOnMobile}
      />
    </div>
  );
};

// Message Cell
export const MessageCell = ({
  item,
  wrapperClassName,
  isSmall,
}: {
  item: ILeadItem;
  wrapperClassName?: string;
  isSmall?: boolean;
}) => {
  return (
    <div className={classNames('flex items-start', wrapperClassName)}>
      <p
        className={classNames('font-medium text-base whitespace-normal', {
          'text-sm': isSmall,
        })}
      >
        {item.message}
      </p>
    </div>
  );
};
