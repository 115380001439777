import { Box, Switch, Menu, MenuItem } from '@mui/material';
import { CSSProperties, MouseEvent, MouseEventHandler, useState } from 'react';

const DropdownWithToggles = ({
  toggles,
}: {
  toggles: {
    title?: string;
    value?: boolean;
    checked?: boolean;
    onClick?: MouseEventHandler;
  }[];
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  return (
    <Box>
      <button
        type="button"
        style={buttonStyle as CSSProperties}
        onClick={handleClick}
      >
        <div className="mb-2">...</div>
      </button>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {toggles.map(({ onClick, title, checked, value }, i) => (
          <MenuItem key={i} onClick={onClick} sx={menuItemStyle}>
            <div className="flex justify-between items-center w-full">
              <p className="">{title}</p>
              <Switch checked={checked} value={value} />
            </div>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default DropdownWithToggles;

const buttonStyle = {
  position: 'absolute',
  top: 8,
  zIndex: 10,
  right: 12,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '24px',
  height: '24px',
  backgroundColor: '#fff',
  borderRadius: '4px',
  cursor: 'pointer',
};

const menuItemStyle = {
  display: 'flex',
  width: '368px',
  justifyContent: 'space-between',
};
