import { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { http } from '../../libs/axios';
import { ApiListing } from '../../types/api';
import { PreviewSubjectTypes, TemplateApi } from '../../types/brochureEditor';
import { useHandlePreviewSubjectChange } from '../../pages/brochureTemplates/lib/hooks/useHandlePreviewSubjectChange';

interface Option {
  label: string;
  value: string;
}

const SelectPdfTemplate = ({
  subjectId,
  subjectType,
}: {
  subjectId?: string;
  subjectType?: PreviewSubjectTypes | null;
}) => {
  const { t } = useTranslation('usersPage');

  const [options, setOptions] = useState<Option[]>([]);
  useEffect(() => {
    http
      .get<ApiListing<TemplateApi>>(`/patterns?itemsPerPage=1000`)
      .then(({ data }) => {
        const templates = data['hydra:member'];
        const newOptions = templates
          .filter((template) => {
            const isExactMatch = template.usedFor === String(subjectType);
            const subjectIsNotSpecifiedInComponent =
              subjectType === null || subjectType === undefined;
            const subjectIsNotSpecifiedInTemplateButComponentIsProperty =
              template.usedFor === null &&
              subjectType === PreviewSubjectTypes.PROPERTY;
            return (
              isExactMatch ||
              subjectIsNotSpecifiedInComponent ||
              subjectIsNotSpecifiedInTemplateButComponentIsProperty
            );
          })
          .map((template) => ({
            label: template.name || `#${template.id}`,
            value: `${template.id}`,
          }));
        setOptions(newOptions);
      });
  }, [subjectType]);

  const createNewTemplateOption = {
    value: 'new',
    label: t('propertiesPage:create_template'),
  };
  const optionsWithNew = [createNewTemplateOption, ...options];

  const handleChange = useHandlePreviewSubjectChange({ subjectType });

  return (
    <Autocomplete
      options={optionsWithNew}
      size="small"
      sx={{ width: 250 }}
      renderInput={(props) => (
        <TextField
          placeholder={t('propertiesPage:generate_brochure')}
          {...props}
        />
      )}
      onChange={(event, newValue) => {
        const templateId = newValue?.value;
        handleChange({ templateId, value: subjectId });
      }}
    />
  );
};

export default SelectPdfTemplate;
