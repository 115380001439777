import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

export default function RestoreIcon({
  color = 'currentcolor',
  className,
}: IconProps) {
  const { mode } = useThemeMode();
  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g stroke={color} strokeLinecap="round" strokeLinejoin="round">
        <path d="M14 3.5V15H2V3.5"></path>
        <path d="M6.563 8.951L4.971 7.36l1.592-1.592"></path>
        <path d="M8.951 13.125A3.378 3.378 0 006.563 7.36H4.971M2 3.5h4m-6 0h6m10 0h-6m-4 0V1h4v2.5m-4 0h4"></path>
      </g>
    </svg>
  );
}
