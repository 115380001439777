import { IconProps } from './locationIcon';

export default function PhoneIcon({ color, className }: IconProps) {
  color = color || '#3446B9';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1.929 7.676C.409 5.354.713 3.322 3.145 1l3.04 3.193c-.507.387-1.277 1.044-1.52 1.741-.304.87 3.951 4.934 4.56 5.224.486.233 2.026-1.257 2.735-2.031.71.58 2.31 2.09 3.04 3.483-2.128 2.612-3.952 2.902-5.776 1.741-1.823-1.16-5.775-4.354-7.295-6.675z"
      ></path>
    </svg>
  );
}
