import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

export default function GridIcon({
  color,
  width = '32',
  height = '32',
}: IconProps) {
  const { mode } = useThemeMode();

  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <rect x="1" y="1" width="2" height="2" stroke={color} />
          <rect x="1" y="5" width="2" height="2" stroke={color} />
          <rect x="1" y="9" width="2" height="2" stroke={color} />
          <rect x="5" y="1" width="2" height="2" stroke={color} />
          <rect x="5" y="5" width="2" height="2" stroke={color} />
          <rect x="5" y="9" width="2" height="2" stroke={color} />
          <rect x="9" y="1" width="2" height="2" stroke={color} />
          <rect x="9" y="5" width="2" height="2" stroke={color} />
          <rect x="9" y="9" width="2" height="2" stroke={color} />
        </g>
      </svg>
    </>
  );
}
