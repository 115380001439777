import { Box, Skeleton } from '@mui/material';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from '@mui/x-data-grid-pro';
import { GridColumns, GridValidRowModel } from '@mui/x-data-grid';
import { JSXElementConstructor, ReactElement } from 'react';
import { checkType } from '../../../pages/contacts/components/forms/contact-type-form';
import { types } from '../../../pages/contacts/components/search-criteria/options';
import { t } from 'i18next';
import { EnsureShortLocale } from '../../common/ensureShortLocale';
import {
  getContactName,
  isCompanyContact,
} from '../../../helpers/utils/contacts';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import CreatedUpdatedHeader from '../../tableGridX/createdUpdatedHeader';
import CreatedUpdated from '../../tableGridX/createdUpdated';
import WarningIcon from '../../../features/icons/warningIcon';
import ContactSource from '../../../pages/contacts/contact-source-cell';
import ContactTypes from '../../tableGridX/leads/contactTypes';
import RelatedAgent from '../../tableGridX/leads/relatedAgent';

export interface GridActionsCellProps {
  key: number;
  icon?: ReactElement<any, string | JSXElementConstructor<any>>;
  type?: string;
  label: string;
  route: string;
  showInMenu: boolean;
  class?: string;
}

export const options: Partial<GridActionsCellProps>[] = [
  {
    route: 'contact-information',
    label: 'Contact details',
    showInMenu: false,
    icon: <RemoveRedEyeOutlinedIcon />,
  },
  {
    route: `contact-type`,
    label: 'contact-type',
    showInMenu: true,
    class: '!hidden lg:!flex',
  },
  {
    route: `additional-information`,
    label: 'additional-information',
    showInMenu: true,
    class: '!hidden lg:!flex',
  },
  {
    route: `matching`,
    label: 'matching',
    showInMenu: true,
    class: '!hidden lg:!flex',
  },
  {
    route: `tasks`,
    label: 'tasks',
    showInMenu: true,
    class: '!hidden lg:!flex',
  },
  {
    route: `contact-history`,
    label: 'contact-history',
    showInMenu: true,
  },
  {
    route: `comments`,
    label: 'comments',
    showInMenu: true,
  },
  {
    route: `leads`,
    label: 'Leads',
    showInMenu: true,
  },
];

const GridActionsCellWithNavigate = (props: GridActionsCellProps) => {
  const { key, icon, label, route, showInMenu = false } = props;
  const navigate = useNavigate();

  const onClick = (route: string) => {
    navigate(route);
  };

  return (
    <GridActionsCellItem
      className="contact_information"
      key={key}
      icon={icon}
      onClick={() => onClick(route)}
      label={label}
      showInMenu={showInMenu as true}
    />
  );
};

const isLarge = localStorage.getItem('isLarge');
// const currentLanguage = localStorage.getItem('i18nextLng') || 'en';
export const contactsListingConfig: GridColDef[] = [
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 220,
    flex: 1,
    sortable: false,
    resizable: false,
    hideable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: ({ row }) => {
      const currentLanguage = localStorage.getItem('i18nextLng') || 'en';
      const CustomWidthTooltip = styled(
        ({ className, ...props }: TooltipProps) => (
          <Tooltip {...props} classes={{ popper: className }} />
        )
      )({
        [`& .${tooltipClasses.tooltip}`]: {
          maxWidth: 220,
        },
      });

      return (
        <div className="w-full">
          <div
            className={`flex py-0.5 px-2 rounded-md items-center border  w-fit mb-1
             ${
               row.status?.keyname === 'rejected'
                 ? 'border-[#F50057] text-[#F50057]'
                 : row.status?.keyname === 'waiting for approval'
                 ? 'border-[#ED6C02] text-[#ED6C02]'
                 : 'border-[#4CAF50] text-[#4CAF50]'
             }`}
          >
            <span className="!text-[12px] 2xl:text-[14px] leading-5 first-letter:uppercase">
              {row.status ? (
                <>
                  {
                    row.status.translations[
                      `${EnsureShortLocale(currentLanguage)}`
                    ]?.value
                  }
                </>
              ) : row.archive ? (
                'Archived'
              ) : (
                'Active'
              )}
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            {row?.warningField?.translations?.[
              EnsureShortLocale(currentLanguage) || ''
            ]?.value && (
              <CustomWidthTooltip
                title={
                  <span>
                    {
                      row?.warningField?.translations?.[
                        EnsureShortLocale(currentLanguage) || ''
                      ]?.value
                    }
                  </span>
                }
                arrow
                placement="top"
              >
                <span>
                  <WarningIcon color="#FFA500" width={'20'} height={'20'} />
                </span>
              </CustomWidthTooltip>
            )}
          </div>
        </div>
      );
    },
  },
  {
    field: 'name',
    headerName: 'Name',
    minWidth: 200,
    flex: 1,
    sortable: false,
    resizable: false,
    hideable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      const { row } = params;
      const name = getContactName(row);
      const newLeadsCount = row.leads
        ? row.leads.filter(
            (el: { status: { keyname: string } }) =>
              el.status.keyname !== 'processed'
          )
        : [];
      const [New_lead] = [t('common:New_lead')];
      return (
        <div className={'flex w-full justify-between items-center'}>
          <div className="flex flex-col gap-1 w-full max-w-full">
            <Link
              className={
                'max-w-full text-[12px] 2xl:text-[16px] overflow-hidden overflow-ellipsis capitalize'
              }
              to={`/contacts/${row.realId}/contact-information`}
            >
              <p
                title={name.toLowerCase()}
                className={
                  'max-w-full overflow-hidden overflow-ellipsis !text-[12px]'
                }
              >
                {name.toLowerCase()}
              </p>
            </Link>
            <div className={'flex w-full justify-between items-center gap-1'}>
              <div
                className={classNames(
                  "!text-[12px] font-normal font-['Inter'] leading-none",
                  {
                    'text-[#3446B9]': !row.unsubscribedMarketing,
                    'text-[#6E6E72]': row.unsubscribedMarketing,
                  }
                )}
              >
                {!row.unsubscribedMarketing ? 'Subscribed' : 'Unsubscribed'}
              </div>
              {newLeadsCount.length ? (
                <div
                  className={classNames(
                    'flex py-0.5 px-1 rounded-md items-center border border-[#ED6C02] text-[#ED6C02]'
                  )}
                >
                  <span className="!text-[12px] leading-5"> {New_lead}</span>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      );
    },
  },
  {
    field: 'agency',
    headerName: 'Agency',
    minWidth: 210,
    flex: 1,
    sortable: false,
    resizable: false,
    hideable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      const { row } = params;
      return row.agencies && row.agencies.length ? (
        <div
          className={classNames('flex gap-1 py-2 pb-2 w-[90%]', {
            'overflow-x-scroll': row.agencies.length > (isLarge ? 1 : 2),
          })}
        >
          {row.agencies.map((item: { nameAgence: string }) => (
            <span
              key={item.nameAgence}
              className={`after:content-[','] last:after:hidden`}
            >
              {item.nameAgence}
            </span>
          ))}
        </div>
      ) : null;
    },
  },
  {
    field: 'agent',
    headerName: 'Related_agent',
    minWidth: 155,
    flex: 1,
    sortable: false,
    resizable: false,
    hideable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: ({ row }) => {
      return <RelatedAgent contact={row} />;
    },
  },
  {
    field: 'createdBy',
    headerName: 'Contact_source',
    minWidth: 150,
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    filterable: false,
    renderCell: ({ row }) => {
      return <ContactSource row={row} />;
    },
  },
  {
    field: 'type',
    headerName: 'Contact_types',
    minWidth: 230,
    flex: 1,
    sortable: false,
    resizable: false,
    hideable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: ({ row }) => {
      return <ContactTypes contact={row} />;
    },
  },
  {
    field: 'createdAt',
    headerName: 'date',
    minWidth: 200,
    flex: 1,
    sortable: false,
    resizable: false,
    hideable: false,
    filterable: false,
    disableColumnMenu: true,
    renderHeader: () => <CreatedUpdatedHeader />,
    renderCell: ({ row }) => (
      <CreatedUpdated created={row.createdAt} updated={row.updatedAt} />
    ),
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: 'actions',
    headerClassName: '!bg-white !border !border-x-0',
    width: 110,
    getActions: (params: GridRowParams) => {
      const { row } = params;

      const clientType = checkType(types, row, 'client');
      let arr = options.map((item, index) => (
        <GridActionsCellWithNavigate
          key={index}
          label={t(`common:${item.label as string}`)}
          route={`/contacts/${row.realId}/${item.route}`}
          icon={item.icon}
          showInMenu={item.showInMenu as boolean}
        />
      ));

      if (isCompanyContact(row)) {
        arr.splice(
          2,
          0,
          <GridActionsCellWithNavigate
            key={options.length + 1}
            label={'Contacts'}
            route={`/contacts/${row.realId}/contacts`}
            showInMenu
          />
        );
      }

      if (clientType) {
        arr.splice(
          4,
          0,
          <GridActionsCellWithNavigate
            key={options.length + 2}
            route={`/contacts/${row.realId}/search-criteria`}
            label={t('common:search-criteria')}
            showInMenu
          />
        );
      }

      arr = arr.filter((item) => {
        return item.key == '0';
      });
      return arr;
    },
  },
];

export const skeletonContactsListingConfig: GridColumns<GridValidRowModel> = [
  {
    field: 'id',
    minWidth: 60,
    maxWidth: 60,
    flex: 1,
    disableColumnMenu: true,
    renderCell: () => {
      return <Skeleton width={60} height={20} animation="wave" />;
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 180,
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    renderCell: () => {
      return (
        <Skeleton variant="text" width={128} height={20} animation="wave" />
      );
    },
  },
  {
    field: 'name',
    headerName: 'Name',
    minWidth: 185,
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    filterable: false,
    renderCell: () => {
      return (
        <Skeleton variant="text" width={317} height={20} animation="wave" />
      );
    },
  },
  {
    field: 'agency',
    headerName: 'Agency',
    minWidth: 210,
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    filterable: false,
    renderCell: () => {
      return (
        <Skeleton variant="text" width={256} height={20} animation="wave" />
      );
    },
  },
  {
    field: 'agent',
    headerName: 'Related_agent',
    minWidth: 155,
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    filterable: false,
    renderCell: () => {
      return (
        <Skeleton variant="text" width={256} height={20} animation="wave" />
      );
    },
  },
  {
    field: 'createdBy',
    headerName: 'Contact_source',
    minWidth: 150,
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    filterable: false,
    renderCell: () => {
      return (
        <Skeleton variant="text" width={256} height={20} animation="wave" />
      );
    },
  },
  {
    field: 'type',
    headerName: 'Contact_types',
    minWidth: 230,
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    filterable: false,
    renderCell: () => {
      return (
        <Skeleton variant="text" width={283} height={20} animation="wave" />
      );
    },
  },
  {
    field: 'createdAt',
    minWidth: 200,
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    filterable: false,
    renderHeader: () => <CreatedUpdatedHeader />,
    renderCell: () => {
      return (
        <Box>
          {' '}
          <Skeleton variant="text" width={230} height={20} animation="wave" />
          <Skeleton variant="text" width={230} height={20} animation="wave" />
        </Box>
      );
    },
  },
  {
    field: 'action',
    headerName: 'actions',
    width: 110,
    disableColumnMenu: true,
    sortable: false,
    headerClassName: '!bg-white !border !border-x-0',
    filterable: false,
    renderCell: () => {
      return (
        <Skeleton variant="text" width={180} height={20} animation="wave" />
      );
    },
  },
];
