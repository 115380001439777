import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

export default function InfoNotificationIcon({ color, className }: IconProps) {
  const { mode } = useThemeMode();

  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_5297_1634)">
        <circle cx="8" cy="8" r="7" stroke="#1D1D1F"></circle>
        <path
          fill={color}
          d="M7.972 5.063a.677.677 0 01-.496-.208.677.677 0 01-.207-.496c0-.192.069-.358.207-.496a.677.677 0 01.496-.207c.192 0 .358.07.496.207a.677.677 0 01.207.496.73.73 0 01-.352.61.67.67 0 01-.351.093z"
        ></path>
        <path fill="#1D1D1F" d="M7.5 6H8.5V12H7.5z"></path>
      </g>
      <defs>
        <clipPath id="clip0_5297_1634">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}
