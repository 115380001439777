import useSWR from 'swr';
import SelectFormField, {
  SelectFormFieldProps,
} from '../../../../../components/form/selectFormField';
import {
  getOptionsByLanguageString,
  optionType,
} from '../../../../../const/propertiesOptions';
import useLocale from '../../../../../hooks/useLocale';

function SelectWithOptionsFromApi({
  endpoint,
  ...rest
}: { endpoint: string } & Omit<SelectFormFieldProps, 'options' | 'ref'>) {
  const { data } = useSWR<optionType[]>(endpoint);
  const { locale } = useLocale();
  const options = getOptionsByLanguageString(data, locale);
  return (
    <>
      <SelectFormField {...rest} options={options} />
    </>
  );
}

export default SelectWithOptionsFromApi;
