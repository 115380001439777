import { useRef, useCallback, CSSProperties, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType } from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Box, Stack } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import classNames from 'classnames';
import LeftArrowIcon from '../../../../features/icons/leftArrowIcon';

interface MainImage {
  large: string;
  mobile: string;
  original: string;
  thumbnail: string;
}

interface Image {
  id?: number;
  url: {
    large: string;
    mobile: string;
    original: string;
    thumbnail: string;
  };
}

type Props = {
  items?: Image[];
  mainImage?: MainImage | null;
  coverImage?: {
    id?: number;
    url: {
      large: string;
      mobile: string;
      original: string;
      thumbnail: string;
    };
  } | null;
};

function CardGallery({ items, mainImage, coverImage }: Props) {
  const [updatedItems, setUpdatedItems] = useState<Image[]>(
    items && items?.length > 0 ? items : []
  );
  const sliderRef = useRef<{ swiper: SwiperType }>(null);

  const handlePrev = useCallback(() => {
    sliderRef.current?.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    sliderRef.current?.swiper.slideNext();
  }, []);

  useEffect(() => {
    if (mainImage && items) {
      const filteredItems = items.filter(
        (item) =>
          item.url.original !== mainImage.original ||
          item.url.large !== mainImage.large ||
          item.url.mobile !== mainImage.mobile ||
          item.url.thumbnail !== mainImage.thumbnail
      );

      setUpdatedItems(filteredItems);
    }
  }, []);

  return (
    <Stack className="h-64 w-full max-w-full select-none flex flex-col relative">
      <Swiper
        // @ts-ignore
        preloadImages={false}
        lazy={true}
        pagination={{
          type: 'bullets',
        }}
        modules={modules}
        className="with-bullets-scale mySwiper"
        style={swiperStyle as CSSProperties}
        ref={sliderRef}
      >
        {mainImage ? (
          <SwiperSlide>
            <LazyLoadImage
              visibleByDefault
              alt={''}
              width={352}
              height={256}
              src={mainImage.mobile}
            />
            <div className="swiper-lazy-preloader"></div>
          </SwiperSlide>
        ) : null}
        {coverImage ? (
          <SwiperSlide>
            <LazyLoadImage
              visibleByDefault
              alt={''}
              width={352}
              height={256}
              src={coverImage.url.mobile}
            />
            <div className="swiper-lazy-preloader"></div>
          </SwiperSlide>
        ) : null}
        {items && items?.length > 0 ? (
          updatedItems.map((item, index) => (
            <SwiperSlide key={item.id || index}>
              <LazyLoadImage
                visibleByDefault={(!mainImage || !coverImage) && index === 0}
                alt={''}
                width={352}
                height={256}
                src={item.url.mobile}
              />
              <div className="swiper-lazy-preloader"></div>
            </SwiperSlide>
          ))
        ) : (
          <SwiperSlide>
            <LazyLoadImage
              alt={''}
              width={352}
              height={256}
              src={'/img/default_image.jpg'}
            />
            <div className="swiper-lazy-preloader"></div>
          </SwiperSlide>
        )}
      </Swiper>

      {items && items.length > 1 ? (
        <>
          <Box
            onClick={handlePrev}
            className={classNames(navigationStyle, 'left-4')}
          >
            <LeftArrowIcon color="currentColor" className="rotate-180" />
          </Box>
          <Box
            onClick={handleNext}
            className={classNames(navigationStyle, 'right-4')}
          >
            <LeftArrowIcon color="currentColor" />
          </Box>
        </>
      ) : null}
    </Stack>
  );
}

export default CardGallery;

const modules = [Navigation, Pagination];

const navigationStyle =
  'swiper-button cursor-pointer absolute bottom-1/2 translate-y-1/2  flex justify-center items-center  w-10  rounded-full  aspect-square text-sm text-white bg-black bg-opacity-40 z-50';

const swiperStyle = {
  '--swiper-pagination-color': '#fff',
  '--swiper-pagination-bullet-inactive-color': '#999999',
  '--swiper-pagination-bullet-inactive-opacity': '0.6',
  '--swiper-pagination-bullet-size': '6px',
  '--swiper-pagination-bullet-active-size': '8px',
  '--swiper-pagination-bullet-horizontal-gap': '4px',
};
