import React, { useEffect, useMemo, useState } from 'react';
import { IContact, IContactSearch } from '../../../../types/contacts';
import useLocale from '../../../../hooks/useLocale';
import { AxiosResponse } from 'axios';
import { http } from '../../../../libs/axios';
import { useNotification } from '../../../../hooks/useNotification';
import { IPropertyApi } from '../../../../types/property';
import { Box, Button } from '@mui/material';
import SpinnerLoading from '../../../../features/spinner/spinnerLoading';
import { createSearchParams, useNavigate } from 'react-router-dom';
import MatchingSearchForm from '../forms/matching-search-form';
import { ApiListing } from '../../../../types/api';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import TableGridXPro from '../../../../components/tableGridPro';
import { propertiesMatchingListingConfig } from '../../../../components/tableGridX/propertiesMatchingListingConfig';
import useTranslationForDataGreedTree from '../../../../hooks/useTranslationForDataGreedTree';
import { GridSelectionModel } from '@mui/x-data-grid';
import { GridRowParams } from '@mui/x-data-grid-pro';
import SendEmailModal from './send-email-modal';
import {
  getContactGreeting,
  getContactName,
} from '../../../../helpers/utils/contacts';
import { useTranslation } from 'react-i18next';
import { useQuery } from '../../../../hooks/useQuery';

interface IContactSearchData {
  checked?: boolean;
  realId?: string;
}

export interface DefaultValues {
  type: number | null;
  types: number | null;
  search?: IContactSearchData[];
}

const getType = (type: number) => {
  let keyname = '';

  switch (type) {
    case 2:
      keyname = 'client';
      break;
    case 1:
      keyname = 'owner';
      break;
    case 3:
      keyname = 'external';
      break;
    default:
      keyname = '';
  }

  return keyname;
};

const getBody = (data: any, locale: string) => {
  const body: any = {
    lang: locale,
  };

  if (data.search) {
    data.search.map((item: IContactSearchData, index: number) => {
      if (item.checked) {
        body[`searchCriteria[${index}]`] = item.realId;
      }
    });
  }
  body[`contactTypeChoices[]`] = data.types;

  return body;
};

const Matching = (props: {
  data: IContact;
  search: IContactSearch[];
  id: string | number;
}) => {
  const { data, search, id } = props;
  const [page, setPage] = useState<number>(1);
  const [isSearched, setIsSearched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emailPopupIsOpen, setEmailPopupIsOpen] = useState(false);
  const [items, setItems] = useState<ApiListing<IPropertyApi> | null>(null);
  const { addNotification } = useNotification();
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridSelectionModel>([]);
  const [selectedItems, setSelectedItems] = useState<IPropertyApi[]>([]);
  const { locale } = useLocale();
  const navigate = useNavigate();
  const query = useQuery();

  const propertiesListingColumns = useTranslationForDataGreedTree(
    propertiesMatchingListingConfig,
    'common'
  );
  const { t } = useTranslation('common');
  const rows = useMemo(() => {
    let test: IPropertyApi[] = [];
    if (items) {
      test = items['hydra:member'];
    }
    return test;
  }, [items]);

  const receiveItems = () => {
    const searchQuery = query.get('search');
    const type = query.get('type');
    const types = query.get('types');

    if (type && types) {
      const data: DefaultValues = {
        type: parseInt(type),
        types: parseInt(types),
      };

      if (searchQuery) {
        data.search = searchQuery.split(',').map((item) => {
          return {
            checked: true,
            realId: item,
          };
        });
      }

      submitDataToServer(data);
    }
  };

  useEffect(() => {
    receiveItems();
  }, [query]);

  const onSubmit = (data: any) => {
    const type = query.get('type');
    const types = query.get('types');

    setIsLoading(true);

    const params = {
      type: data.type,
      types: data.types,
    };

    if (data.search) {
      // @ts-ignore
      params['search'] = data.search
        .filter((item: { checked: boolean }) => item.checked)
        .map((item: { realId: number }) => item.realId)
        .join(',');
    }

    navigate({
      pathname: '',
      search: createSearchParams(params).toString(),
    });

    if (data.type.toString() === type && data.types.toString() === types) {
      submitDataToServer(data);
    }
  };

  const submitDataToServer = async (data: any) => {
    const typeName = getType(data.type);
    const endpoint = `/v1/properties/contacts/${id}/matching/${typeName}?itemsPerPage=100`;
    const body = getBody(data, locale);

    if (typeName === 'client' && !data.search) {
      return false;
    }

    const promise: Promise<AxiosResponse> = http.get(endpoint, {
      params: body,
    });

    setIsLoading(true);
    setIsSearched(true);
    promise
      .finally(() => {
        setIsLoading(false);
      })
      .then(({ data }) => {
        const dataChanged = data;
        dataChanged['hydra:member'] = dataChanged['hydra:member'].map(
          (item: { property: IPropertyApi; alreadySent: boolean }) => {
            if (item.property) {
              return { ...item.property, alreadySent: item.alreadySent };
            } else {
              return item;
            }
          }
        );

        setItems(dataChanged);
      })
      .catch((error) => {
        let message;
        if (error.response) {
          message = 'Server error';
        } else if (error.request) {
          message = 'Failed to connect to server';
        } else {
          message = 'Unknown error';
        }
        addNotification(message, 'error');
      });
  };

  const onEmailPopupIsClose = () => {
    setEmailPopupIsOpen(false);
  };

  const onRowsSelectionHandler = (ids: GridSelectionModel) => {
    // this part is for Typescript :)
    const isProperty = (
      item: IPropertyApi | undefined
    ): item is IPropertyApi => {
      return !!item;
    };

    if (rows) {
      const selectedRowsData = ids
        .map((id) => rows.find((row: IPropertyApi) => row.id === id))
        .filter(isProperty);

      setRowSelectionModel(ids);
      setSelectedItems(selectedRowsData);
    }
  };

  const onEmailSent = () => {
    setSelectedItems([]);
    setRowSelectionModel([]);
  };

  const onClickAway = () => {
    receiveItems();
  };

  return (
    <div className={''}>
      <p className={'mb-8 text-2xl'}> {t('Matching')} </p>
      {data.types && data.types.length ? (
        <>
          <MatchingSearchForm
            onSubmit={onSubmit}
            contactId={id}
            data={data}
            searchCriteria={search}
          />
          {isSearched ? (
            <div className={'pt-16 h-full min-h-[200px]'}>
              {isLoading ? (
                <div className={'flex justify-center items-center'}>
                  <SpinnerLoading />
                </div>
              ) : (
                <>
                  {rows && rows.length && items ? (
                    <>
                      <div className={'mb-8 flex items-center justify-between'}>
                        <p className={'text-2xl'}>
                          {t('Matching_results')} ({items['hydra:totalItems']})
                        </p>
                        <Button
                          disabled={
                            !(rowSelectionModel && rowSelectionModel.length)
                          }
                          variant="outlined"
                          className="flex justify-center items-center"
                          sx={{
                            height: '42px',
                          }}
                          onClick={() => setEmailPopupIsOpen(true)}
                        >
                          <Box
                            className="flex justify-center items-center"
                            gap="16px"
                          >
                            <MailOutlineIcon fontSize={'small'} />
                            <span className="font-medium">
                              {t('Send_selected_properties_via_email')}
                            </span>
                          </Box>
                        </Button>
                      </div>
                      <Box
                        className={'with-border'}
                        sx={{
                          '& .MuiDataGrid-main': {
                            minHeight: '138px',
                          },
                          '& .MuiDataGrid-virtualScroller': {
                            minHeight: '500px',
                          },
                          '& .MuiDataGrid-row.Mui-selected': {
                            backgroundColor: '#F5F5F7',
                          },
                          '& .MuiDataGrid-row.Mui-selected .inner-tag': {
                            backgroundColor: '#FFF',
                          },
                          '& .MuiDataGrid-row:hover .inner-tag': {
                            backgroundColor: '#FFF',
                          },
                        }}
                      >
                        <TableGridXPro
                          rows={rows}
                          columns={propertiesListingColumns}
                          page={page}
                          onPageChange={(page) => setPage(page)}
                          pageSize={20}
                          checkboxSelection
                          onRowSelectionModelChange={(newRowSelectionModel) =>
                            onRowsSelectionHandler(newRowSelectionModel)
                          }
                          rowSelectionModel={rowSelectionModel}
                          isRowSelectable={(params: GridRowParams) =>
                            !params.row.sentToClient
                          }
                        />
                      </Box>
                      <SendEmailModal
                        contactName={getContactName(data)}
                        greeting={getContactGreeting(data)}
                        isOpen={emailPopupIsOpen}
                        contactId={data.id}
                        onClose={onEmailPopupIsClose}
                        items={selectedItems}
                        onEmailSent={onEmailSent}
                        onClickAway={onClickAway}
                      />
                    </>
                  ) : (
                    <div>
                      <p className={'mt-8 text-2xl text-center'}>
                        {t('There_is_no_match_for_this_parameters')}
                      </p>
                    </div>
                  )}
                </>
              )}
            </div>
          ) : null}
        </>
      ) : (
        <div>
          <p>
            {' '}
            {t(
              'You_should_select_some_contact_types_before_using_matching'
            )}{' '}
          </p>
        </div>
      )}
    </div>
  );
};

export default Matching;
