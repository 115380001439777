import { Box, Skeleton, Tooltip } from '@mui/material';
import { GridColumns, GridValidRowModel } from '@mui/x-data-grid';
// import { formatDate } from '../../datePicker/datePicker';
export function convertDateStringToObject(dateString: string) {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month =
    date.getMonth() + 1 < 10
      ? '0' + String(date.getMonth() + 1)
      : date.getMonth() + 1;
  const day =
    date.getDate() < 10 ? '0' + String(date.getDate()) : date.getDate();
  const hours =
    date.getHours() < 10 ? '0' + String(date.getHours()) : date.getHours();
  const minutes =
    date.getMinutes() < 10
      ? '0' + String(date.getMinutes())
      : date.getMinutes();
  const seconds =
    date.getSeconds() < 10
      ? '0' + String(date.getSeconds())
      : date.getSeconds();

  const fullDate = day + '.' + month + '.' + year;
  const fullTime = hours + ':' + minutes + ':' + seconds;
  return {
    fullDate,
    fullTime,
    year,
    month,
    day,
    hours,
    minutes,
    seconds,
  };
}
const isLarge = localStorage.getItem('isLarge');
const isMacLarge = localStorage.getItem('isMacLarge');
const isMediumLarge = localStorage.getItem('isMediumLarge');
// const isMedium = localStorage.getItem('isMedium');
export const propertyHistoryListingConfig: GridColumns<GridValidRowModel> = [
  {
    field: 'date',
    headerClassName: '!bg-white',
    cellClassName: 'pointer-events-none',
    headerName: 'Date',
    width:
      isLarge === 'true'
        ? 200
        : isMacLarge === 'true'
        ? 200
        : isMediumLarge === 'true'
        ? 200
        : 200,
    renderCell: (params) => (
      <div className="flex flex-col">
        <span className="text-sm text-[#1D1D1F]">
          {convertDateStringToObject(params.row.date).fullDate}
        </span>
        <span className="text-sm text-[#1D1D1F]">
          {convertDateStringToObject(params.row.date).fullTime}
        </span>
      </div>
    ),
  },

  {
    field: 'action',
    headerClassName: '!bg-white',
    cellClassName: 'pointer-events-none',
    headerName: 'Action',
    width:
      isLarge === 'true'
        ? 760
        : isMacLarge === 'true'
        ? 750
        : isMediumLarge === 'true'
        ? 720
        : 560,
    renderCell: (params) => (
      <Tooltip title={params.row.action} arrow placement="top">
        <span
          className="text-sm text-[#1D1D1F]"
          dangerouslySetInnerHTML={{
            __html: params.row.action.replace(/\n/g, '<br />'),
          }}
        />
      </Tooltip>
    ),
  },
  {
    field: 'doneBy',
    headerClassName: '!bg-white',
    cellClassName: 'pointer-events-none',
    headerName: 'Done by',
    width:
      isLarge === 'true'
        ? 410
        : isMacLarge === 'true'
        ? 320
        : isMediumLarge === 'true'
        ? 300
        : 300,
    renderCell: (params) => (
      <Tooltip
        title={params.row.doneBy?.firstname + ' ' + params.row.doneBy?.lastname}
        arrow
        placement="top"
      >
        <div className="flex flex-col">
          <span className="text-sm text-blue">
            {params.row.doneBy?.firstname + ' ' + params.row.doneBy?.lastname}
          </span>
          <span className="text-sm text-[#1D1D1F]">
            {params.row.doneBy?.agencyName}
          </span>
        </div>
      </Tooltip>
    ),
  },
];

export const skeletonPropertiesHistoryListingConfig: GridColumns<GridValidRowModel> =
  [
    {
      field: 'date',
      headerName: 'Date',
      cellClassName: 'pointer-events-none',
      width: 200,
      disableColumnMenu: true,
      renderCell: () => {
        return (
          <Box>
            {' '}
            <Skeleton variant="text" width={200} height={20} animation="wave" />
            <Skeleton variant="text" width={200} height={20} animation="wave" />
          </Box>
        );
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      cellClassName: 'pointer-events-none',
      width: 760,
      disableColumnMenu: true,
      renderCell: () => {
        return (
          <Skeleton variant="text" width={760} height={20} animation="wave" />
        );
      },
    },
    {
      field: 'doneby',
      headerName: 'Done by',
      cellClassName: 'pointer-events-none',
      width: 410,
      disableColumnMenu: true,
      renderCell: () => {
        return (
          <Box>
            {' '}
            <Skeleton variant="text" width={410} height={20} animation="wave" />
            <Skeleton variant="text" width={410} height={20} animation="wave" />
          </Box>
        );
      },
    },
  ];
