import React from 'react';
import WidgetWrapper from './wrapper';
import { WidgetProps } from '../index';
import { formatPrice } from '../../../helpers/utils';
import { useTranslation } from 'react-i18next';

const SalesWidget: React.FC<WidgetProps> = (props) => {
  const { sales } = props.data;
  const { t } = useTranslation('common');

  return (
    <WidgetWrapper title={'MySales'} linkText={'ViewMore'} link={null}>
      <div className="w-full flex-col justify-center items-start gap-8 inline-flex">
        <div className="self-stretch flex-col justify-center items-start gap-2 flex">
          <div className="self-stretch pb-2 border-b border-gray-200 justify-start items-start gap-4 inline-flex">
            <div className="grow shrink md:basis-0 text-zinc-900 text-sm font-normal leading-none">
              {t('TotalSoldPrice')}
            </div>
            <div className="grow shrink basis-0 text-right text-zinc-900 text-sm font-medium leading-none">
              {formatPrice(sales['total-sold-price'])}
            </div>
          </div>
          <div className="self-stretch pb-2 border-b border-gray-200 justify-start items-start gap-4 inline-flex">
            <div className="grow shrink md:basis-0 text-zinc-900 text-sm font-normal leading-none">
              {t('AgencyCommission')}
            </div>
            <div className="grow shrink basis-0 text-right text-zinc-900 text-sm font-medium leading-none">
              {formatPrice(sales['agency-commission'])}
            </div>
          </div>
          <div className="self-stretch pb-2 border-b border-gray-200 justify-start items-start gap-4 inline-flex">
            <div className="grow shrink md:basis-0 text-zinc-900 text-sm font-normal leading-none">
              {t('MyCommission')}
            </div>
            <div className="grow shrink md:basis-0 text-right text-zinc-900 text-sm font-medium leading-none">
              {formatPrice(sales['my-commission'])}
            </div>
          </div>
        </div>
      </div>
    </WidgetWrapper>
  );
};

export default SalesWidget;
