import ChoiceButton from './choiceButton';

function Multiselect({
  options,
  value,
  onChange,
}: {
  options: { label: string; value: string }[];
  value: string[];
  onChange: (value: string[]) => void;
}) {
  return (
    <div className="flex gap-4">
      {options.map((option) => {
        const isSelected = value.includes(option.value);
        const handleClick = () => {
          if (isSelected) {
            onChange(value.filter((v) => v !== option.value));
          } else {
            onChange([...value, option.value]);
          }
        };
        return (
          <ChoiceButton
            key={option.value}
            isActive={isSelected}
            onClick={handleClick}
          >
            {option.label}
          </ChoiceButton>
        );
      })}
    </div>
  );
}

export default Multiselect;
