import { IContactVisit } from '..';
import { IContact } from '../../../../../../types/contacts';

const ContactsAutocompleteOption = ({
  option,
  optionVisit,
  visit,
}: {
  option?: IContact;
  optionVisit?: IContactVisit;
  visit?: boolean;
}) => {
  return (
    <div className="w-full flex justify-between items-center hover:bg-gray-100">
      {visit ? (
        <>
          <p className="text-base text-[#1D1D1F]">{optionVisit?.fullname}</p>
          <p className="text-xs text-gray-900">{optionVisit?.email}</p>
        </>
      ) : (
        <>
          {option?.individual && (
            <>
              <p className="text-base text-[#1D1D1F]">
                {option?.individual?.lastname +
                  ' ' +
                  option?.individual?.firstname}
              </p>
              <p className="text-xs text-gray-900">
                {option?.individual?.email}
              </p>
            </>
          )}
          {option?.company && (
            <>
              <p className="text-base text-[#1D1D1F]">
                {option?.company?.legalName}
              </p>
              <p className="text-xs text-gray-900">{option?.company?.email}</p>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ContactsAutocompleteOption;
