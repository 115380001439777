import IndividualInformationFormFields from './individual-form-fields';
import * as React from 'react';
import { ReactNode } from 'react';
import { IAgentInCharge, IContactIndividual } from '../../../types/contacts';
import useLocale from '../../../hooks/useLocale';
import CreateContactWrapper from './wrapper';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import classNames from 'classnames';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  withFormHandler,
  transformDataForFormUsage,
} from '../components/forms/individual-form-handler';
import { useProtectedForm } from '../../../hooks/useProtectedForm';

export const AccordionView = (props: {
  expanded?: boolean;
  title: string;
  titleClassName?: string;
  children: ReactNode;
  defaultExpanded?: boolean;
  disabled?: boolean;
  className?: string;
  onChange?: (event: React.SyntheticEvent, isExpanded: boolean) => void;
  subTitle?: ReactNode;
}) => {
  const {
    expanded,
    onChange,
    title,
    children,
    titleClassName,
    defaultExpanded = false,
    disabled = false,
    className,
    subTitle,
  } = props;
  return (
    <Accordion
      expanded={expanded}
      onChange={onChange}
      key={title}
      className={className}
      defaultExpanded={defaultExpanded}
      disabled={disabled}
      sx={{
        boxShadow: 'none',
        margin: '0',
        '&::before': {
          display: 'none',
        },
        '&.Mui-expanded': {
          margin: '0 0 2rem 0',
        },
        '&.Mui-disabled': {
          backgroundColor: 'inherit',
        },
      }}
    >
      <AccordionSummary
        className={`${title}_contact`}
        expandIcon={<KeyboardArrowDownIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          borderBottom: '1px solid #E6E8EC',
          padding: '0px',
          '&.Mui-expanded': {
            minHeight: '48px',
          },
          '& .MuiAccordionSummary-content': {
            margin: '4px 0',
            alignItems: 'center',
          },
          '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '4px 0',
            alignItems: 'center',
          },
        }}
      >
        <p className={classNames('', titleClassName)}>{title}</p>
        {subTitle}
      </AccordionSummary>
      <AccordionDetails
        style={{
          boxShadow: 'none',
          padding: '1rem 0 0 0',
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

const IndividualForm = (props: {
  isEditMode: boolean;
  id?: string;
  data?: IContactIndividual;
  companyInformation?: number;
  inner?: boolean;
  isLoading: boolean;
  onSubmit: () => void;
  agentInCharge?: IAgentInCharge;
  archived?: boolean;
}) => {
  const {
    id,
    isEditMode,
    data,
    inner,
    onSubmit,
    isLoading,
    agentInCharge,
    archived,
  } = props;
  const { locale } = useLocale();
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
  } = useProtectedForm(
    {
      defaultValues: transformDataForFormUsage(locale, data, agentInCharge),
    },
    data
  );
  const watchNames = watch(['firstname', 'lastname']);

  return (
    <CreateContactWrapper
      id={id}
      // mutate={mutate}
      archived={archived}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isLoading}
      title={'Contact information'}
      isEditMode={isEditMode}
      buttonDisabled={
        !(
          watchNames &&
          watchNames[0] &&
          watchNames[1] &&
          watchNames[0].length &&
          watchNames[1].length
        )
      }
    >
      <IndividualInformationFormFields
        inner={inner}
        register={register}
        watch={watch}
        control={control}
        errors={errors}
        setValue={setValue}
      />
    </CreateContactWrapper>
  );
};

// @ts-ignore
const IndividualView = withFormHandler(IndividualForm);

export default IndividualView;
