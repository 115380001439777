import ConveniencesReusableStep from '../../../../components/reusableSteps/conveniences';
import { FC } from 'react';
import {
  DatafieldsGroupedByTagIdAndStepName,
  FieldValue,
  FieldValuePayload,
} from '../../../../types/property';
import { StepComponentProps } from '../../../../components/stepper';
import { IConvenienceApi } from '../../../../types/conveniences';
import PropertyDistances from './distances';

const ConveniencesStep: FC<
  StepComponentProps<
    {
      conveniences: string[];
      dataFields: DatafieldsGroupedByTagIdAndStepName;
      fieldValues: FieldValuePayload[];
    },
    {
      conveniences: IConvenienceApi[];
      dataFields: DatafieldsGroupedByTagIdAndStepName;
      fieldValues: FieldValue[];
    }
  >
> = ({ onSubmit, defaultValues, serverData, mutate }) => {
  return (
    <div>
      <ConveniencesReusableStep
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        serverData={serverData}
      />
      <PropertyDistances serverData={serverData} mutate={mutate} />
    </div>
  );
};

export default ConveniencesStep;
