import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { GridMoreVertIcon } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useEffect, useState, useRef } from 'react';
import { pickBy, isArray, mapValues } from 'lodash';
import { mutate } from 'swr';

import {
  activePropertiesAction,
  selectFilterOptionT,
} from '../../../../const/propertiesFilter';
import {
  getOptionsByLanguage,
  getPropertyTypeById,
} from '../../../../const/propertiesOptions';
import { countries } from '../../../../const/countries';

import useListingCollection from '../../../../hooks/useListingCollection';
import { useFiltersProperties } from '../../../../hooks/useFiltersProperties';
import { useNotification } from '../../../../hooks/useNotification';
import usePropertyFilterStore from '../../../../hooks/usePropertyFilterStore';
import { useAuth } from '../../../../hooks/useAuth';
import useLocale from '../../../../hooks/useLocale';

import { initialFilterValues } from '../../../../types/filter';
import { PropertyListingItem } from '../../../../types/property';

import Pills from '../../../../components/common/pills';
import FilterLayout from '../../../../components/globalFilter/filterLayout/filterLayout';
import { http } from '../../../../libs/axios';
import {
  convertToClient,
  convertToServerAdress,
  findCountryAlpha2,
} from '../../utils';

import { getCountry } from '../../../agencies/tree/utils';
import { collectUsersFromAgencies } from '../../../promotions/stepper/steps/properties/utils';
import { extratagentsFromDept } from '../../../../helpers/utils';
import AiSearch from '../../../../features/icons/iaSearch';

import PropertyMainFilter from './propertyMainFilter';
import PropertyAdvanceFilter from './propertyAdvanceFilter';
import PropertyMagicSearch from './magicSearch';
import { useMagicSearch } from './useMagicSearch';
import PropertiesListingHeader from '../propertiesListingHeader';
import PropertiesListingTable from '../propertiesListingTable';
import { AnimatedInput } from './animatedPlaceholder';
import { IMercureMatching } from '../../../../types/matching';
import useMercure from '../../../../features/mercure/useMercure';

const archivedPropertiesAction = [{ id: 5, label: 'restore' }];

const ITEM_HEIGHT = 48;

function PropertiesListingPage(props: {
  networks?: boolean;
  isArchived?: boolean;
}) {
  const { t } = useTranslation('propertiesPage');
  const { locale } = useLocale();
  const [activePill, setActivePill] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQueryName, setSearchQueryName] = useState('');
  const [singleSearchQueryId, setSingleSearchQueryId] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [nbproperties, setNbproperties] = useState<number>();
  const [aiListing, setAiListing] = useState([]);
  const [isLoadingAi, setIsLoadingAi] = useState(false);
  const isActiveProperties = !props.isArchived;
  const initialItemsPerPage = localStorage.getItem('itemsPerPage') as string;
  const globalActionOptions = isActiveProperties
    ? activePropertiesAction
    : archivedPropertiesAction;
  const messagesEndRef = useRef(null);
  const { addNotification } = useNotification();
  const {
    itemsToDisplay,
    resetFilter,
    handleItemClick,
    setPage,
    itemsPerPage,
    setItemsPerPage,
    selectedRows,
    setSelectedRows,
    getFiltredProperties,
    anchorEl,
    handleClick,
    handleClose,
    open,
    page,
    loadingItemsToDisplay,
    mutate: mutateProperties,
  } = useListingCollection<PropertyListingItem>({
    basedUrl: `/v1/properties${props.networks ? '/visible_to_networks' : ''}`,
    isActiveProperties,
    currentPage: 'properties',
    isChecked: isChecked,
    initialItemsPerPage: initialItemsPerPage
      ? parseInt(initialItemsPerPage)
      : 10,
  });
  const { data: matchingNotifications } =
    useMercure<IMercureMatching>('matching');

  const [Search, setSearch] = useState<boolean>(false);
  const [magicSearch, setMagicSearch] = useState('');
  const { listing, isSearching } = useMagicSearch({
    searchQuery: magicSearch,
    itemsPerPage,
    page,
  });
  const tableRows = isActiveProperties
    ? listing || itemsToDisplay
    : itemsToDisplay;
  const tableIsLoading = loadingItemsToDisplay || isSearching;

  // const [matchingPopupIsOpen, setMatchingPopupIsOpen] =
  //   useState<boolean>(false);

  const {
    agencyHierarchy,
    agentsPerDept,
    categoriesOptionsApi,
    setAgencyHierarchy,
    setAssistantAgencyHierarchy,
  } = useFiltersProperties();

  const { isAssistant, user } = useAuth();

  const { getInputValue, updateElementOptions, mainFilterState } =
    usePropertyFilterStore({
      basedUrl: `/v1/properties`,
      currentPage: 'properties',
    });

  const { reset, register, setValue, watch, control, handleSubmit, getValues } =
    useForm({
      defaultValues: initialFilterValues,
    });

  // update property type options depending on category value
  useEffect(() => {
    if (watch('category') !== undefined && watch('category') !== '') {
      const categoryTypes = getPropertyTypeById(
        categoriesOptionsApi,
        watch('category')
      );
      let selectedType: selectFilterOptionT[] = [];
      if (
        categoryTypes &&
        categoryTypes[0] &&
        categoryTypes[0]?.propertyTypes
      ) {
        selectedType = getOptionsByLanguage(
          categoryTypes[0].propertyTypes,
          locale
        );
      }

      updateElementOptions('type', selectedType);
    }
  }, [watch('category')]);

  // update agents options
  useEffect(() => {
    let agentsOptions: any = [];
    // if the user is assistant we should display in the agent field only the agents that he belong to
    if (isAssistant) {
      const { users }: any = user;
      agentsOptions =
        users.length > 0
          ? users.map(({ id, firstname, lastname }: any) => {
              return { id, value: `${firstname} ${lastname}` };
            })
          : [];
    } else {
      if (watch('departments').length > 0) {
        setValue('agents', null);
        const agenciesDept = watch('departments');
        agentsOptions = collectUsersFromAgencies(agenciesDept, agencyHierarchy);
      } else {
        if (agentsPerDept) {
          agentsOptions = extratagentsFromDept(agentsPerDept);
        }
      }
    }

    updateElementOptions('agents', agentsOptions);
  }, [watch('departments'), agentsPerDept]);

  // update department depending to selected agent when the user is assistant
  useEffect(() => {
    if (isAssistant && watch('agents')) {
      const brokerId = watch('agents');
      setValue('departments', []);
      setAssistantAgencyHierarchy(brokerId, Number(watch('service')));
    }
  }, [watch('agents')]);

  useEffect(() => {
    if (
      matchingNotifications &&
      // @ts-ignore
      matchingNotifications.user === user.id &&
      // @ts-ignore
      matchingNotifications.status === 'completed'
    ) {
      mutateProperties();
    }
  }, [matchingNotifications]);

  //setValue of canton and district if country is not Switzerland

  useEffect(() => {
    const country = findCountryAlpha2(locale, watch('country'));
    const isSwitzerland = country === 'CH';
    if (!isSwitzerland) {
      setValue('canton', '');
      setValue('district', '');
    }
  }, [watch('country')]);

  // update department depending to selected service and depending to the role of user if assistant or not

  useEffect(() => {
    const serviceId = watch('service');
    setValue('departments', []);
    if (!isAssistant) {
      setAgencyHierarchy(Number(serviceId));
    } else {
      if (isAssistant && watch('agents')) {
        setAssistantAgencyHierarchy(watch('agents'), Number(serviceId));
      }
    }
  }, [watch('service')]);

  useEffect(() => {
    if (watch('address')) {
      const { zipCode, translations }: any = watch('address');
      const { adresse, city, country, canton, district } =
        translations[`${locale}`];
      setValue('zipCode', zipCode);
      setValue('adresse', adresse);
      setValue('city', city);
      setValue('canton', canton);
      setValue('district', district);
      setValue('country', getCountry(countries, country)[locale]);
    }
  }, [watch('address')]);

  // setIsModalOpen(false);
  const handleFilterDataPreparation = () => {
    const dataTosend = pickBy(watch(), (value) => {
      return isArray(value)
        ? value.length > 0
        : value !== undefined && value !== null && value !== '';
    });

    const newdataTosend = mapValues(dataTosend, (value, key) => {
      if (key === 'agents') {
        return [Number(value)];
      }
      if (key === 'country') {
        return findCountryAlpha2(locale, value);
      }
      if (key === 'reference' && Array.isArray(value)) {
        const refrencesToSend: string[] = value?.map(({ id }: any) => id);
        return refrencesToSend;
      }
      if (
        key === 'service' ||
        key === 'status' ||
        key === 'category' ||
        key === 'type'
      ) {
        return Number(value);
      }
      if (typeof value === 'string') {
        return value.trim();
      }
      return value;
    });

    return newdataTosend;
  };

  const submitSavedQuery = async () => {
    let newdataTosend = await handleFilterDataPreparation();
    const translations: any = {};
    translations[locale as string] = {
      name: searchQueryName,
      title: newdataTosend.title ? newdataTosend.title : null,
      locale,
    };
    delete newdataTosend.title;
    delete newdataTosend.name;
    // delete newdataTosend.departments;
    const { adresse, city, zipCode, country } = newdataTosend;
    if (adresse || city || zipCode || country) {
      newdataTosend = convertToServerAdress(newdataTosend, locale);
    }
    setIsLoading(true);
    try {
      if (singleSearchQueryId) {
        const url = `/search_properties/${singleSearchQueryId}`;
        await http.patch(url, {
          translations,
          ...newdataTosend,
        });
        addNotification(
          `${searchQueryName} search has been successfully updated!`
        );
      } else {
        const url = `/search_properties`;
        await http.post(url, {
          translations,
          ...newdataTosend,
        });
        addNotification(
          `${searchQueryName} search has been successfully created!`
        );
      }

      mutate(`/search_properties?lang=${locale}`);
      handleResetFilter();
    } catch (event: unknown) {
      addNotification('error on deleting saved search');
    }
    setIsLoading(false);
  };

  //scroll to specific div
  const smoothScrollToElement = async (elementId: string) => {
    const targetElement = document.getElementById(elementId);

    if (!targetElement) {
      return;
    }
    targetElement.scrollIntoView({ behavior: 'smooth' });
    await new Promise((resolve) => setTimeout(resolve, 1000)); // Adjust the timeout as needed
  };

  // submit to filter list of properties
  const submitSearch = async () => {
    try {
      // Start smooth scrolling and wait for it to complete before proceeding
      await smoothScrollToElement('specificDivId');
      // Proceed with the rest of the logic
      let newdataTosend: any = await handleFilterDataPreparation();
      const { reference } = newdataTosend;

      if (reference) {
        newdataTosend = {
          references: newdataTosend.reference,
          ...newdataTosend,
        };
        delete newdataTosend.reference;
      }

      newdataTosend.archive = isActiveProperties ? false : true;
      newdataTosend.lang = locale;
      delete newdataTosend.id;
      delete newdataTosend.name;
      delete newdataTosend.address;

      await getFiltredProperties(newdataTosend);
    } catch (error) {
      console.log('Error occurred:', error);
    }
  };

  // get single saved search
  const getSingleSavedSearch = async (id: number) => {
    handleResetFilter();
    const url = `/search_properties`;
    const response = await http.get(`${url}/${id}`);
    const { data } = response;

    const defaultValues: any =
      convertToClient(data, locale) || initialFilterValues;

    if (defaultValues) {
      Object.keys(defaultValues).forEach((fieldName: any) => {
        if (
          fieldName === 'service' ||
          fieldName === 'status' ||
          fieldName === 'category' ||
          fieldName === 'type'
        ) {
          setValue(fieldName, defaultValues[fieldName].toString());
        } else {
          setValue(fieldName, defaultValues[fieldName]);
        }
      });
      setSearchQueryName(defaultValues.name);
      setSingleSearchQueryId(defaultValues.id);
    }
  };

  //reset filter
  const handleResetFilter = () => {
    reset();
    resetFilter();
    setSearchQueryName('');
    setSingleSearchQueryId(null);
  };

  const getTitle = () => {
    if (!isActiveProperties) {
      return t('archivedProperties');
    }

    if (props.networks) {
      return t('sharedProperties');
    }

    return t('common:Properties');
  };

  return (
    <section className="">
      <PropertiesListingHeader
        setIsChecked={setIsChecked}
        setIsLoadingAi={setIsLoadingAi}
        type={isActiveProperties && !props.networks ? 'active' : 'archive'}
        title={getTitle()}
        // nbproperties={itemsToDisplay ? itemsToDisplay['hydra:totalItems'] : 0}
        nbproperties={nbproperties}
        hideMyProperties={props.networks}
      >
        {isActiveProperties && (
          <div
            className={`flex ${Search ? 'w-[100%] lg:w-[60%]' : 'max-w-sm'}`}
          >
            {!Search && (
              <div className="gradientRadius">
                <button
                  onClick={() => {
                    setSearch(true);
                  }}
                  className={`flex gap-1 items-center py-2 px-2 rounded-[5px] bg-white h-full`}
                >
                  <AiSearch color="#5B8BBF" className="w-[20px]" />
                  <p className=" text-[#5B8BBF] Inter font-mono font-extralight">
                    AI
                  </p>
                </button>
              </div>
            )}
            {!Search && (
              <PropertyMagicSearch
                magicSearch={magicSearch}
                setMagicSearch={setMagicSearch}
              />
            )}

            {Search && (
              <AnimatedInput
                passedPlaceholder={[
                  t('search_for_a_property_in_Geneva_with_2_bedrooms'),
                  t('Show_me_the_properties_in_Lausanne'),
                  t('Find_the_properties_for_rent'),
                ]}
                setSearch={setSearch}
                page={page}
                itemsPerPage={itemsPerPage}
                setAiListing={setAiListing}
                aiListing={aiListing}
                isLoadingAi={isLoadingAi}
                setIsLoadingAi={setIsLoadingAi}
              />
            )}
          </div>
        )}
      </PropertiesListingHeader>

      <FilterLayout
        withSavedQueries
        page="property"
        submitSavedSearch={submitSavedQuery}
        getSingleSavedSearch={getSingleSavedSearch}
        submitSearch={submitSearch}
        setSearchQueryName={setSearchQueryName}
        searchQueryName={searchQueryName}
        handleResetFilter={handleResetFilter}
        isLoading={isLoading}
      >
        <Pills
          activeItemId={activePill}
          onItemClick={(itemId) => setActivePill(itemId)}
          items={[
            {
              id: 0,
              title: `${t('Basic_search')}`,
              content: (
                <Box>
                  <PropertyMainFilter
                    setValue={setValue}
                    register={register}
                    handleSubmit={handleSubmit}
                    getValues={getValues}
                    watch={watch}
                    control={control}
                    getInputValue={getInputValue}
                    mainFilterState={mainFilterState}
                  />
                </Box>
              ),
            },
            {
              id: 0,
              title: `${t('advanced_search')}`,
              content: (
                <Box>
                  <PropertyAdvanceFilter
                    setValue={setValue}
                    register={register}
                    handleSubmit={handleSubmit}
                    getValues={getValues}
                    watch={watch}
                    control={control}
                    mainFilterState={mainFilterState}
                  />
                </Box>
              ),
            },
          ]}
        />
      </FilterLayout>

      {selectedRows.length > 0 && (
        <Box className="mb-4">
          <IconButton
            sx={{
              background: 'rgb(52, 70, 185, 0.9)',
              color: 'white',
              ':hover': { background: 'rgb(52, 70, 185, 1)' },
            }}
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <GridMoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: '20ch',
              },
            }}
          >
            {globalActionOptions.map(({ id, label }) => (
              <MenuItem
                key={`${label}-${id}`}
                selected={label === 'Pyxis'}
                onClick={() => handleItemClick(label)}
              >
                {t(label)}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      )}

      <div ref={messagesEndRef} id="specificDivId" />

      <PropertiesListingTable
        loadingItemsToDisplay={tableIsLoading}
        itemsPerPage={itemsPerPage}
        data={tableRows}
        aiListing={aiListing}
        handlePage={setPage}
        page={page}
        setSelectedRows={setSelectedRows}
        setItemsPerPage={setItemsPerPage}
        isNetworks={props.networks}
        setNbproperties={setNbproperties}
        isLoadingAi={isLoadingAi}
        isActiveProperties={isActiveProperties}
      />
    </section>
  );
}

export default PropertiesListingPage;
