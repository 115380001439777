import IndividualInformationTable from '../../components/table/individual';
import CompanyInformationTable from '../../components/table/company';
import CompanyView from '../../creator/company-view';
import IndividualView from '../../creator/individual-view';
import { ContactTapInnerProps } from './additional-information-tab';
import { isCompanyContact } from '../../../../helpers/utils/contacts';
import { useQuery } from '../../../../hooks/useQuery';

const ContactInformationTab = (props: ContactTapInnerProps) => {
  const { data, id, mutate } = props;
  const query = useQuery();
  const isEditMode = query.get('mode') === 'edit';

  return (
    <>
      <div className="w-full flex justify-end mb-4"></div>
      {isCompanyContact(data) ? (
        <>
          {isEditMode ? (
            <CompanyView
              isEditMode
              data={data.company}
              id={id}
              mutate={mutate}
              agentInCharge={data.agentInCharge}
              idAgence={data.idAgence}
              nameAgence={data.nameAgence}
              office={data.office}
              archived={data.archive}
            />
          ) : (
            <CompanyInformationTable
              archived={data.archive}
              data={data.company}
              agencies={data.agencies}
            />
          )}
        </>
      ) : (
        <>
          {isEditMode ? (
            <IndividualView
              isEditMode
              data={data.individual}
              id={id}
              mutate={mutate}
              agentInCharge={data.agentInCharge}
              archived={data.archive}
            />
          ) : (
            <IndividualInformationTable
              data={data.individual}
              archived={data.archive}
              agencies={data.agencies}
              agentInCharge={
                data.agentInCharge
                  ? `${data.agentInCharge.firstname} ${data.agentInCharge.lastname}`
                  : ''
              }
            />
          )}
        </>
      )}
    </>
  );
};

export default ContactInformationTab;
