import React, { useEffect, useState, useRef } from 'react';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import { Button, Switch, Tooltip } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import { parseFileName } from '../../../../../helpers/utils/downloadFile';
import { IMedia } from '../../../../../types/api';
import DownloadIcon from '@mui/icons-material/Download';
import classNames from 'classnames';

export const switchStylesBlue = {
  width: 42,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 0,
    transitionDuration: '300ms',
    top: '2px',
    left: '2px',
    '&.Mui-checked': {
      transform: 'translateX(22px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#3446B9',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#3446B9',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: '#fff',
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 16,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
  },
};

interface RotateImageProps {
  image: IMedia;
  onSave: (newImage: File) => void;
  rotation: number;
  setRotation: (rotation: any) => void;
  withWatermark: boolean;
  toggleWithWatermark: (value: boolean) => void;
  withDownloadButton?: boolean;
  disableRotation?: boolean;
  isLoading?: boolean;
}

const RotateImage: React.FC<RotateImageProps> = ({
  rotation,
  setRotation,
  image,
  onSave,
  withWatermark,
  toggleWithWatermark,
  withDownloadButton,
  disableRotation = false,
  isLoading,
}) => {
  const { type, url } = image;
  const { t } = useTranslation('propertiesPage');
  const [rotatedImageURL, setRotatedImageURL] = useState<string | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const processImage = (
    img: HTMLImageElement,
    deg: number,
    isForDisplay: boolean
  ) => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const angle = deg % 360;
    let canvasWidth = img.width;
    let canvasHeight = img.height;

    if (angle === 90 || angle === 270) {
      canvasWidth = img.height;
      canvasHeight = img.width;
    }

    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.translate(canvasWidth / 2, canvasHeight / 2);
    ctx.rotate((angle * Math.PI) / 180);
    ctx.drawImage(img, -img.width / 2, -img.height / 2, img.width, img.height);

    if (isForDisplay) {
      setRotatedImageURL(canvas.toDataURL(type));
    }
  };

  useEffect(() => {
    const largeImg = new Image();
    largeImg.crossOrigin = 'anonymous';
    largeImg.src = url.large;
    largeImg.onload = () => {
      processImage(largeImg, rotation, true);
    };
  }, [rotation, withWatermark]);

  useEffect(() => {
    setRotation(0);
    toggleWithWatermark(false);
  }, [url.original]);

  const handleRotate = () => {
    setRotation((prevRotation: any) => (prevRotation + 90) % 360);
  };

  const handleSave = () => {
    const originalImg = new Image();
    originalImg.crossOrigin = 'anonymous';
    originalImg.src = url.original;
    originalImg.onload = () => {
      processImage(originalImg, rotation, false);

      const canvas = canvasRef.current;
      if (!canvas) return;
      canvas.toBlob((blob) => {
        if (blob) {
          const fileNameWithoutExtension =
            parseFileName(image.url.original)
              ?.split('.')
              .slice(0, -1)
              .join('.') || '';
          const fileExtension = type.split('/')[1];
          const newFileName = `${fileNameWithoutExtension}_rotated.${fileExtension}`;

          const file = new File([blob], newFileName, {
            type: blob.type,
            lastModified: Date.now(),
          });

          onSave(file);
        }
      }, type);
    };
  };

  return (
    <div className={'h-full w-full flex justify-center items-center relative'}>
      <canvas
        ref={canvasRef}
        style={{
          display: 'none',
        }}
      />
      {rotatedImageURL ? (
        <img
          src={rotatedImageURL}
          alt="Rotatable"
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'contain',
          }}
        />
      ) : (
        <img
          src={image.url.large}
          alt="large"
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'contain',
          }}
        />
      )}
      <div
        className={
          'flex flex-col gap-2 absolute left-3 top-1 slider-media-download z-30 bg-white p-2 bg-opacity-50 rounded'
        }
      >
        <div
          className={'grid grid-cols-2 gap-2 min-w-[128px] 2xl:min-w-[143px]'}
        >
          {withDownloadButton && image.url.original ? (
            <Tooltip title={t('download_original')} arrow placement="bottom">
              <a
                href={image.url.original}
                target="_blank"
                download
                rel="noreferrer"
              >
                <Button
                  variant="contained"
                  className={'h-[32px] 2xl:h-[48px]'}
                  sx={{
                    background: '#F5F5F7',
                    color: 'text.primary',
                    display: 'inline-flex',
                    gap: '.75rem',
                    '&:hover': {
                      background: '#dadadc',
                    },
                  }}
                  disableElevation
                >
                  <div className={'flex h-[14px] 2xl:h-[18px]'}>
                    <DownloadIcon
                      sx={{
                        width: '100%',
                        height: '100%',
                      }}
                    />
                  </div>
                </Button>
              </a>
            </Tooltip>
          ) : null}
          {disableRotation ? null : (
            <Button
              variant="contained"
              className={'h-[32px] 2xl:h-[48px]'}
              sx={{
                background: '#F5F5F7',
                color: 'text.primary',
                display: 'inline-flex',
                gap: '.75rem',
                '&:hover': {
                  background: '#dadadc',
                },
              }}
              disableElevation
              onClick={handleRotate}
            >
              <div className={'flex h-[14px] 2xl:h-[18px]'}>
                <RotateRightIcon
                  sx={{
                    width: '100%',
                    height: '100%',
                  }}
                />
              </div>
            </Button>
          )}
        </div>
        <div className={'pt-2 flex items-center gap-1 opacity-70'}>
          <Switch
            sx={switchStylesBlue}
            checked={withWatermark}
            onChange={() => toggleWithWatermark(!withWatermark)}
            color="primary"
          />
          <p
            className={classNames('text-[12px] 2xl:text-[14px] font-medium', {
              'opacity-50': !withWatermark,
            })}
          >
            {t('Watermark')}
          </p>
        </div>
        {rotation > 0 || withWatermark ? (
          <div className={'pr-4'}>
            <Button
              className={'h-[32px] 2xl:h-[48px]'}
              onClick={handleSave}
              disabled={isLoading}
              variant="contained"
              sx={{ gap: '8px' }}
            >
              <div className={'flex h-[14px] 2xl:h-[18px]'}>
                <CheckIcon
                  sx={{
                    width: '100%',
                    height: '100%',
                  }}
                />
              </div>
              <span className={'text-[12px] 2xl:text-[14px]'}>{t('Save')}</span>
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default RotateImage;
