import { IAddress } from '../../../../types/address';
import { IAddressPopup } from '../../../../types/popupSingleProperty';
import { Box } from '@mui/material';
import LocationIcon from '../../../../features/icons/locationIcon';
import { Locale } from '../../../../hooks/useLocale';
import { EnsureShortLocale } from '../../../common/ensureShortLocale';

export const renderShortPropertyAdress = (
  address: IAddress | IAddressPopup | null,
  withTruncate: boolean
) => {
  const locale = ((localStorage.getItem('i18nextLng') as Locale) ||
    'en') as Locale;
  const localAdress = address?.translations?.[`${EnsureShortLocale(locale)}`];
  return (
    <Box className="flex items-center leading-5 w-full">
      {localAdress && (
        <span className="flex gap-2 items-center capitalize h-6 px-2 w-full">
          <LocationIcon className="col-span-1 mr-1" color="#6E6E72" />
          <span className={`w-full  ${withTruncate && 'truncate'}`}>
            {localAdress?.city}
          </span>
        </span>
      )}
    </Box>
  );
};

export default renderShortPropertyAdress;
