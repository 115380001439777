import classNames from 'classnames';

import ControlLayout from '../controlLayout';
import { EditorControlProps } from '../../../../types/brochureEditor';
import { useTranslation } from 'react-i18next';

const ChoiceControl = ({
  value,
  onChange,
  label,
  choices = [],
}: EditorControlProps<string>) => {
  const { t } = useTranslation('brochureEditor');
  return (
    <ControlLayout label={label}>
      <div className="flex gap-2">
        {choices.map(({ label, value: choiceValue }) => (
          <button
            key={choiceValue}
            className={classNames(
              'rounded p-2 text-center border text-xs leading-3 flex-grow block w-full',
              {
                'border-gray-200 bg-white': choiceValue !== value,
                'border-blue bg-blue text-white pointer-events-none':
                  choiceValue === value,
              }
            )}
            onClick={() => onChange?.(choiceValue)}
          >
            {t(label)}
          </button>
        ))}
      </div>
    </ControlLayout>
  );
};

export default ChoiceControl;
