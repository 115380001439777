import ContactTypesForm from '../../components/forms/contact-type-form';
import { IContact } from '../../../../types/contacts';
import useSWR from 'swr';
import SpinnerLoading from '../../../../features/spinner/spinnerLoading';
import * as React from 'react';

interface Props {
  data: IContact;
  id: string;
  mutate?: any;
}

const ContactTypeTab = (props: Props) => {
  const { id, mutate, data } = props;
  const { data: types } = useSWR(`/contact_types`);

  return (
    <>
      {types && data ? (
        <ContactTypesForm id={id} types={types} data={data} mutate={mutate} />
      ) : (
        <div className={'col-start-1 col-end-4 min-h-[500px]'}>
          <SpinnerLoading />
        </div>
      )}
    </>
  );
};

export default ContactTypeTab;
