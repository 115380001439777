import { Link } from 'react-router-dom';
import { abbreviate, fullName } from '../../helpers/utils';
import { createElement } from 'react';

function CircleWithFullName({
  id,
  firstname,
  lastname,
  avatar,
}: {
  id?: string | number;
  firstname?: string | null;
  lastname?: string | null;
  avatar?: string | null;
}) {
  const contents = (
    <>
      <div className="shrink-0 w-6 h-6 bg-neutral-100 rounded-full relative overflow-hidden justify-center items-center gap-2 flex">
        {avatar ? (
          <img
            src={avatar}
            loading="lazy"
            alt="avatar"
            className="w-6 h-6 block object-cover"
          />
        ) : (
          <div className="text-xs font-normal leading-none">
            {abbreviate([firstname, lastname])}
          </div>
        )}
      </div>
      <div className="text-sm font-medium leading-none truncate">
        {fullName(firstname, lastname)}
      </div>
    </>
  );

  return createElement(
    id ? Link : 'div',
    {
      to: id ? `/users/${id}` : '',
      className: 'flex justify-start items-center gap-2.5',
    },
    contents
  );
}

export default CircleWithFullName;
