import { useTranslation } from 'react-i18next';
import CheckedIcon from '../../../../features/icons/checkedIcon';
import { Box, Stack, Typography } from '@mui/material';
import useLocale from '../../../../hooks/useLocale';
import { useMemo } from 'react';

type Props = {
  className?: string;
  featuresStyle?: string;
  data: any;
};

function PromotionFeatures({ className, featuresStyle, data }: Props) {
  const { t } = useTranslation('promotionPage');
  const { conveniences } = data;
  const { locale } = useLocale();

  const promotionFeatures = useMemo(() => {
    const promotionFeaturesArray: any[] = [];

    if (conveniences && conveniences.length) {
      conveniences.map((singleFeature: any) => {
        promotionFeaturesArray.push(singleFeature);
      });
    }

    if (promotionFeaturesArray) {
      if (promotionFeaturesArray && !promotionFeaturesArray.includes(undefined))
        return promotionFeaturesArray.map(({ name }) => name);
      else {
        return [];
      }
    }
  }, [locale, conveniences]) as [];

  if (!conveniences || !promotionFeatures.length) return null;

  return (
    <Stack
      sx={{
        color: 'text.primary',
      }}
      className={
        'bg-[#FFFFFF] w-full rounded border p-4 2xl:p-8 flex flex-col ' +
        className
      }
    >
      <Typography variant="h6" className="mb-8">
        {t('promotion_features')}
      </Typography>
      <Box
        sx={{
          color: 'text.primary',
        }}
        className={
          'bg-[#FFFFFF]  grid grid-cols-4 gap-3 2xl:gap-4 mt-4 ' + featuresStyle
        }
      >
        {promotionFeatures?.map((feature: string, index: number) => (
          <Box
            sx={{
              color: 'text.primary',
            }}
            key={index}
            className=" bg-[#FFFFFF] col-span-1 flex"
          >
            <Box className="w-5 h-5 2xl:w-7 2xl:w-7 rounded border aspect-square p-1 flex justify-center items-center mr-2 max-w-8">
              <CheckedIcon color="green" />
            </Box>
            <Box className={'text-[14px] 2xl:text-[1rem]'} component="span">
              {feature}
            </Box>
          </Box>
        ))}
      </Box>
    </Stack>
  );
}

export default PromotionFeatures;
