import { IconProps } from './locationIcon';

export default function WarningIcon({
  color = 'currentcolor',
  className,
  width = '20',
  height = '20',
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={className}
    >
      <path d="M12 2L1 21h22L12 2zm0 10v6" stroke={color} />
    </svg>
  );
}
