import { IPropertyApi } from '../../../../types/property';
import PropertyLocation from './propertyLocation';
import UserInfoCard from './userInfoCard';

function PropertyContact({ property }: { property: IPropertyApi }) {
  const { mainBroker, address } = property;
  return (
    <div className="w-full flex flex-col gap-8">
      {address ? <PropertyLocation property={property} /> : null}
      {mainBroker && <UserInfoCard mainBroker={mainBroker} />}
    </div>
  );
}

export default PropertyContact;
