import { Controller } from 'react-hook-form';
import { IContactSearch } from '../../../../types/contacts';
import useLocale from '../../../../hooks/useLocale';
import NoEditModeFormWrapper from './wrapper';
import { ReactNode, useState } from 'react';
import { AxiosResponse } from 'axios';
import { http } from '../../../../libs/axios';
import { useNavigate } from 'react-router-dom';
import { useNotification } from '../../../../hooks/useNotification';
import InputFormField from '../../../../components/form/inputFormField';
import { MenuItem, Select, Switch } from '@mui/material';
import { selectOptionStringT } from '../../../../components/form/selectFormField';
import InputLayout from '../../../../components/form/components/inputLayout';
import useSWR from 'swr';
import { optionType } from '../../../../const/propertiesOptions';
import Conveniences from '../search-criteria/conveniances';
import BudgetFields, {
  buyOptions,
  findClosestOption,
} from '../search-criteria/budget-fields';
import AddressesList from '../search-criteria/addresses';
import MinMaxFields from '../search-criteria/min-max-field';
import {
  bathroomsOptions,
  bedroomsOptions,
  buyBudgetOptions,
  floorsOptions,
  minMaxOption,
  preferences,
  rentBudgetOptions,
  roomsOptions,
  surfaceOptions,
} from '../search-criteria/options';
import { v4 as uuid } from 'uuid';
import { Translations } from '../../../../types/translations';
import Checkbox from '@mui/material/Checkbox';
import { switchStyles } from './contact-type-form';
import { updateHistoric } from '../../../../helpers/utils/contacts';
import { useTranslation } from 'react-i18next';
import { useProtectedForm } from '../../../../hooks/useProtectedForm';

interface Values {
  [key: string]: any;
}

const getAddresses = (items: any) => {
  let returnedValue = [];

  if (items.length) {
    returnedValue = items.map(
      (el: {
        zipCode: string;
        polygon: any;
        longitude: number;
        latitude: number;
        translations: Translations;
      }) => {
        const polygons: any[] = [];
        if (el.polygon) {
          el.polygon.forEach((item: any) => {
            if (item.coords) {
              polygons.push(
                item.coords.map((el: { lat: number; lng: number }) => ({
                  lat: el.lat,
                  lon: el.lng,
                }))
              );
            }
          });
        }

        const address: {
          zipCode: string;
          polygon: any;
          longitude?: number | null;
          latitude?: number | null;
          translations?: Translations;
        } = {
          polygon: polygons,
          zipCode: el.zipCode,
          translations: el.translations,
          longitude: el.longitude || null,
          latitude: el.latitude || null,
        };

        return address;
      }
    );
  }

  return returnedValue;
};

const getBudgetData = (
  data: IContactSearch,
  target: number,
  selectBigger: boolean
) => {
  const type = data?.serviceType ? data?.serviceType.id : null;
  let returnedValue = target;

  if (type) {
    const isBuyType = type && buyOptions.includes(type);
    const options = isBuyType ? buyBudgetOptions : rentBudgetOptions;
    const value = findClosestOption(options, target as number, !selectBigger);

    if (value) {
      returnedValue = value.value;
    }
  }

  return returnedValue;
};

const transformDataForFormUsage = (locale: string, data?: IContactSearch) => {
  const returnedData: Values = {
    budgetMin: data?.budgetMin
      ? getBudgetData(data, data?.budgetMin, false)
      : null,
    budgetMax: data?.budgetMax
      ? getBudgetData(data, data?.budgetMax, true)
      : null,
    surfaceMin: data?.surfaceMin || null,
    surfaceMax: data?.surfaceMax || null,
    landSurfaceMin: data?.surfaceMin || null,
    landSurfaceMax: data?.surfaceMax || null,
    roomsMin: data?.roomsMin || null,
    roomsMax: data?.roomsMax || null,
    bedroomsMin: data?.bedroomsMin || null,
    bedroomsMax: data?.bedroomsMax || null,
    bathroomsMin: data?.bathroomsMin || null,
    bathroomsMax: data?.bathroomsMax || null,
    mountainView: data?.mountainView,
    autoMarketing: data?.autoMarketing,
    seaView: data?.seaView,
    lakeView: data?.lakeView,
    balcony: data?.balcony,
    terrace: data?.terrace,
    lift: data?.lift,
    parking: data?.parking,
    garage: data?.garage,
    pool: data?.pool,
    floor: data?.floor || null,
    title: data?.translations?.[locale]?.title || null,
    propertyTypes: data?.propertyTypes
      ? data?.propertyTypes.map((el) => el.id)
      : [],
    serviceType: data?.serviceType ? data?.serviceType.id : null,
    addresses: data?.addresses
      ? data?.addresses.map((item) => ({
          zipCode: item.zipCode,
          polygon: item.polygon.map((item: any, index) => {
            return {
              id: uuid(),
              name: index + 1,
              coords: Array.isArray(item)
                ? item.map((el: { lat: number; lon: number }) => ({
                    lat: el.lat,
                    lng: el.lon,
                  }))
                : {
                    lat: item.lat,
                    lng: item.lon,
                  },
            };
          }),
          translations: {
            [`${locale}`]: {
              locale,
              adresse: item?.translations[locale]?.adresse,
              country: item?.translations[locale]?.country,
              city: item?.translations[locale]?.city,
              quarter: item?.translations[locale]?.quarter,
              canton: item?.translations[locale]?.canton,
              district: item?.translations[locale]?.district,
            },
          },
        }))
      : [],
    conveniences: data?.conveniences
      ? data?.conveniences.map((el) => el.id)
      : [],
    environment: [],
    stores: [],
    transports: [],
    children: [],
    sport: [],
    leisure: [],
    'outside environment': [],
  };
  return returnedData;
};

const RepeatableSearchCriteriaForm = (props: {
  id?: string;
  data?: IContactSearch;
  inner?: boolean;
  additionalButton?: ReactNode;
  mutate?: any;
  isEditMode?: boolean;
  types?: {
    id: number;
    keyname: string;
  }[];
}) => {
  const { data, additionalButton, isEditMode, mutate, id, types } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { addNotification } = useNotification();
  const { locale } = useLocale();
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    control,
    resetField,
    formState: { defaultValues, isDirty },
    setValue,
    setFocus,
  } = useProtectedForm(
    { defaultValues: transformDataForFormUsage(locale, data) },
    data
  );
  const { data: propertyTypes } = useSWR('/types');
  const { data: serviceOptionsApi } = useSWR<optionType[]>('services');

  const typeNames = types?.map((type) => type.keyname) || [];
  const isBuySearcher =
    typeNames.includes('seller') || typeNames.includes('buyer');
  const isRentSearcher =
    typeNames.includes('renter') || typeNames.includes('tenant');

  const serviceOptions =
    serviceOptionsApi
      ?.filter((item) => {
        if (item.keyname.includes('rent')) {
          return isRentSearcher;
        }
        if (item.keyname.includes('buy')) {
          return isBuySearcher;
        }

        // what kind of service are you?!! alright, you can stay for now
        return true;
      })
      .map((option) => ({
        id: String(option.id),
        value:
          option.translations && option.translations[locale]
            ? option.translations[locale]?.name || ''
            : option.keyname,
      })) || [];

  const watchAll = watch();

  const onSubmit = (formData: any) => {
    const body = {
      contact: id,
      propertyTypes: formData.propertyTypes,
      floor: formData.floor,
      serviceType: formData.serviceType ? parseInt(formData.serviceType) : null,
      conveniences: [
        ...formData.environment,
        ...formData.sport,
        ...formData.leisure,
        ...formData.children,
        ...formData.stores,
        ...formData.transports,
        ...formData['outside environment'],
      ],
      budgetMin: formData.budgetMin,
      budgetMax: formData.budgetMax,
      surfaceMin: formData.surfaceMin,
      surfaceMax: formData.surfaceMax,
      landSurfaceMin: formData.landSurfaceMin,
      landSurfaceMax: formData.landSurfaceMax,
      roomsMin: formData.roomsMin,
      roomsMax: formData.roomsMax,
      bedroomsMin: formData.bedroomsMin,
      bedroomsMax: formData.bedroomsMax,
      bathroomsMin: formData.bathroomsMin,
      bathroomsMax: formData.bathroomsMax,
      mountainView: formData.mountainView,
      seaView: formData.seaView,
      lakeView: formData.lakeView,
      balcony: formData.balcony,
      terrace: formData.terrace,
      lift: formData.lift,
      parking: formData.parking,
      garage: formData.garage,
      pool: formData.pool,
      autoMarketing: formData.autoMarketing,
      contactTypes: [],
      addresses: getAddresses(formData.addresses),
      translations: {
        ...data?.translations,
        [`${locale}`]: {
          locale: locale,
          title: formData.title,
        },
      },
    };

    submitDataToServer(body);
  };

  const submitDataToServer = async (body: any) => {
    let promise: Promise<AxiosResponse>;
    if (isEditMode) {
      promise = http.patch(`/search_contacts/${data?.id}`, body);
    } else {
      promise = http.post('/search_contacts', body);
    }

    setIsLoading(true);
    promise
      .finally(() => {
        setIsLoading(false);
      })
      .then(() => {
        if (isEditMode) {
          if (mutate) {
            mutate();
          }
          addNotification(
            `Your search criteria has been successfully updated.`,
            'success'
          );
          updateHistoric(id, 'Search criteria updated');
        } else {
          addNotification(`New search criteria has been successfully created.`);
          updateHistoric(id, 'Search criteria created');
          if (mutate) {
            mutate();
          }
          navigate(0);
        }
      })
      .catch((error) => {
        let message;
        if (error.response) {
          message = 'Server error';
        } else if (error.request) {
          message = 'Failed to connect to server';
        } else {
          message = 'Unknown error';
        }
        addNotification(message, 'error');
      });
  };

  return (
    <NoEditModeFormWrapper
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isLoading}
      additionalButton={additionalButton}
      buttonDisabled={
        !isDirty || !(watchAll['title'] && watchAll['title'].length)
      }
    >
      <div
        className={
          'col-start-1 col-end-4 border-b py-8 grid grid-cols-3 gap-8 2xl:gap-x-16'
        }
      >
        <InputFormField
          type="text"
          label={t('Criteria_title')}
          {...register('title')}
        />
        <InputLayout label={t('Deal_type')}>
          <Controller
            name="serviceType"
            control={control}
            render={({ field }) => (
              <Select className={'h-[56px]'} labelId="level-label" {...field}>
                {serviceOptions.map(
                  (item: selectOptionStringT, index: number) => (
                    <MenuItem
                      key={index}
                      value={item?.id}
                      className="capitalize"
                    >
                      {item.value}
                    </MenuItem>
                  )
                )}
              </Select>
            )}
          />
        </InputLayout>

        <div className="max-w-[368px] flex items-center justify-between py-4 mb-4">
          <p className={'capitalize'}> {t('Marketing_Automation')} </p>
          <Controller
            name={'autoMarketing'}
            control={control}
            render={({ field }) => {
              return (
                <Switch
                  className={`marketing_automation_btn`}
                  sx={switchStyles}
                  value={field.value}
                  defaultChecked={!!field.value}
                  onChange={(event, val) => field.onChange(val)}
                />
              );
            }}
          />
        </div>
      </div>
      <div className={'col-start-1 col-end-4'}>
        <AddressesList
          setFocus={setFocus}
          defaultValues={defaultValues}
          setValue={setValue}
          watch={watch}
          register={register}
          control={control}
        />
      </div>
      <div
        className={
          'col-start-1 col-end-4 border-b border-t py-8 grid grid-cols-3 gap-8 2xl:gap-x-16'
        }
      >
        <div>
          <p className={'mb-8'}>{t('Type')}</p>
          <InputLayout label={t('Property_type')}>
            <Controller
              name="propertyTypes"
              control={control}
              render={({ field }) => (
                <Select
                  multiple
                  className={'h-[56px]'}
                  labelId="level-label"
                  {...field}
                >
                  {propertyTypes &&
                    propertyTypes.map(
                      (
                        item: { id: number; keyname: string },
                        index: number
                      ) => (
                        <MenuItem
                          key={index}
                          value={item?.id}
                          className="capitalize"
                        >
                          {item.keyname}
                        </MenuItem>
                      )
                    )}
                </Select>
              )}
            />
          </InputLayout>
        </div>
        <div>
          <p className={'mb-8'}>{t('Budget')}</p>
          <div className={'grid grid-cols-2 gap-8 2xl:gap-x-16'}>
            <BudgetFields
              resetField={resetField}
              type={watchAll['serviceType']}
              watch={watch}
              control={control}
            />
          </div>
        </div>
        <div>
          <p className={'mb-8'}>{t('Surface')}</p>
          <div className={'grid grid-cols-2 gap-8 2xl:gap-x-16'}>
            <MinMaxFields
              options={surfaceOptions}
              minField={{
                label: 'Min (sq.m)',
                name: 'surfaceMin',
              }}
              maxField={{
                label: 'Max (sq.m)',
                name: 'surfaceMax',
              }}
              control={control}
              watch={watch}
              resetField={resetField}
            />
          </div>
        </div>
        <div>
          <p className={'mb-8'}>{t('Land_Surface')}</p>
          <div className={'grid grid-cols-2 gap-8 2xl:gap-x-16'}>
            <MinMaxFields
              options={surfaceOptions}
              minField={{
                label: 'Min (sq.m)',
                name: 'landSurfaceMin',
              }}
              maxField={{
                label: 'Max (sq.m)',
                name: 'landSurfaceMax',
              }}
              control={control}
              watch={watch}
              resetField={resetField}
            />
          </div>
        </div>
      </div>
      <div
        className={
          'col-start-1 col-end-4 border-b pb-8 grid grid-cols-3 gap-8 2xl:gap-x-16'
        }
      >
        <div>
          <p className={'mb-8'}>{t('Rooms')}</p>
          <div className={'grid grid-cols-2 gap-8 2xl:gap-x-16 mb-8'}>
            <MinMaxFields
              options={roomsOptions}
              minField={{
                label: 'Min',
                name: 'roomsMin',
              }}
              maxField={{
                label: 'Max',
                name: 'roomsMax',
              }}
              control={control}
              watch={watch}
              resetField={resetField}
            />
          </div>
          <InputLayout label={t('Floors')}>
            <Controller
              name="floor"
              control={control}
              render={({ field }) => (
                <Select
                  MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                  className={'h-[56px]'}
                  labelId="level-label"
                  {...field}
                >
                  {floorsOptions.map((item: minMaxOption, index: number) => (
                    <MenuItem
                      key={index}
                      value={item?.value}
                      className="capitalize"
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </InputLayout>
        </div>
        <div>
          <p className={'mb-8'}>{t('Bedrooms')}</p>
          <div className={'grid grid-cols-2 gap-8 2xl:gap-x-16'}>
            <MinMaxFields
              options={bedroomsOptions}
              minField={{
                label: 'Min',
                name: 'bedroomsMin',
              }}
              maxField={{
                label: 'Max',
                name: 'bedroomsMax',
              }}
              control={control}
              watch={watch}
              resetField={resetField}
            />
          </div>
        </div>
        <div>
          <p className={'mb-8'}>{t('Bathrooms')}</p>
          <div className={'grid grid-cols-2 gap-8 2xl:gap-x-16'}>
            <MinMaxFields
              options={bathroomsOptions}
              minField={{
                label: 'Min',
                name: 'bathroomsMin',
              }}
              maxField={{
                label: 'Max',
                name: 'bathroomsMax',
              }}
              control={control}
              watch={watch}
              resetField={resetField}
            />
          </div>
        </div>
      </div>
      <div className={'col-start-1 col-end-4 border-b pb-8'}>
        <p className={'mb-8'}> {t('Additional_preferences')} </p>
        <div className={'grid grid-cols-3 gap-y-2 gap-x-8 2xl:gap-x-16 mb-8'}>
          {preferences.map((el) => (
            <Controller
              key={el.name}
              control={control}
              name={el.name}
              render={({ field }) => (
                <label className={'cursor-pointer flext items-center'}>
                  <Checkbox
                    defaultChecked={defaultValues?.[el.name]}
                    {...field}
                  />
                  <span className={'text-[14px] capitalize'}>
                    {t(el.label.replaceAll(' ', '_'))}
                  </span>
                </label>
              )}
            />
          ))}
        </div>
      </div>
      <div className={'col-start-1 col-end-4'}>
        <p className={'mb-8 text-[24px]'}> {t('Conveniences')} </p>
        <Conveniences
          control={control}
          selection={defaultValues?.conveniences}
          setValue={setValue}
        />
      </div>
    </NoEditModeFormWrapper>
  );
};

export default RepeatableSearchCriteriaForm;
