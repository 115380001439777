import { ReactNode } from 'react';
import './landing-typography.css';

const LandingTitle = ({
  title,
  children,
}: {
  title?: string;
  children?: ReactNode;
}) => {
  return (
    <div className="w-full flex-col justify-start items-center gap-4 inline-flex pb-8">
      <div className="px-4 lg:px-0 max-w-[880px] w-full m-auto text-center">
        <div className="h1">{title}</div>
      </div>
      {children}
    </div>
  );
};

export default LandingTitle;
