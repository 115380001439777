import React, { FC, useEffect, useMemo, useState } from 'react';
import { PropertyStepperStep } from '../../../../../types/property';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';
import SpinnerLoading from '../../../../../features/spinner/spinnerLoading';
import { Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import ManualLead from './manualLead';
import { ApiListing } from '../../../../../types/api';
import { ILeadItem } from '../../../../../types/contacts';
import useColumns from './useColumns';
import TableGridX from '../../../../../components/tableGridX';

const itemsPerPage = 10;

const skeletonItems = Array(itemsPerPage)
  .fill('')
  .map((_, index) => ({ id: index }));

export interface ILeadItemExtended extends ILeadItem {
  parentId: string;
}

const PropertyLeadsStep: FC<PropertyStepperStep> = () => {
  const { id } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [page, setPage] = useState(1);
  const { data: leads, mutate } = useSWR<ApiListing<ILeadItem>>(
    `/v1/properties/${id}/prospects?page=${page}&itemsPerPage=${itemsPerPage}`
  );

  const rows = useMemo(() => {
    if (leads && leads['hydra:member']) {
      return leads['hydra:member'].map((item: ILeadItem) => ({
        ...item,
        parentId: id,
      }));
    }
    return [];
  }, [leads]);

  useEffect(() => {
    mutate();
  }, [updated]);

  const { t } = useTranslation();
  const columns = useColumns({
    isLoading: !leads,
  });

  const totalCount = leads?.['hydra:totalItems'] || 0;
  const showedRows = leads ? rows : skeletonItems;

  return rows ? (
    <div className={'pb-8'}>
      <div className="flex justify-between !items-start w-full">
        <p className={'mb-8 text-2xl'}>
          {t('propertiesPage:leads')}{' '}
          {rows && rows.length ? <span>({rows.length})</span> : null}
        </p>
        <Button
          variant="contained"
          disableElevation
          onClick={() => setOpenModal(true)}
          startIcon={<CheckIcon fontSize="small" />}
        >
          {t('propertiesPage:Add_New_Lead')}
        </Button>
        <ManualLead
          id={id}
          openModal={openModal}
          setOpenModal={setOpenModal}
          setUpdated={setUpdated}
          updated={updated}
        />
      </div>
      <div className={'h-full w-full with-border'}>
        <TableGridX
          key={String(!!rows)}
          rows={showedRows}
          // @ts-ignore
          autoPageSize
          autoHeight
          columns={columns}
          pageSize={itemsPerPage}
          page={page - 1}
          rowCount={totalCount}
          paginationMode={'server'}
          onPageChange={(page: number) => {
            setPage(page + 1);
          }}
          rowHeight={100}
        />
      </div>
    </div>
  ) : (
    <div className={'col-start-1 col-end-4 min-h-[500px]'}>
      <SpinnerLoading />
    </div>
  );
};

export default PropertyLeadsStep;
