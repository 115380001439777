import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';

function ShowMore({
  isExpanded,
  onChange,
}: {
  isExpanded?: boolean;
  onChange?: (isExpanded: boolean) => unknown;
}) {
  const { t } = useTranslation('propertiesPage');

  return (
    <button
      className="bg-[#F5F5F7] flex justify-around items-center rounded-md w-[100px] h-[24px]"
      onClick={() => onChange?.(!isExpanded)}
    >
      <span>
        {isExpanded ? (
          <ExpandLess sx={{ fontSize: '16px' }} />
        ) : (
          <ExpandMore sx={{ fontSize: '16px' }} />
        )}
      </span>
      <span className="font-medium inter text-sm leading-4">
        {isExpanded ? t('view_less') : t('view_more')}
      </span>
    </button>
  );
}

export default ShowMore;
