import React from 'react';
import { GridSelectionModel } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import { IContact } from '../../../../../types/contacts';
import useTranslationForTableGridXConfig from '../../../../../hooks/useTranslationForTableGridX';
import {
  contactsMatchingListingConfig,
  skeletonContactsMatchingListingConfig,
} from '../../../../material-ui/tablesConfig/contactsMatchingListingConfig';
import TableGridX from '../../../../tableGridX';
import SkeletonTableGrid from '../../../../skeletonTableGrid';

type Props = {
  contacts: IContact[];
  onRowsSelectionHandler: (rowSelectionModel: GridSelectionModel) => void;
  rowSelectionModel: GridSelectionModel;
  skeletonItemsCount?: number;
  singleSelectableContact?: boolean;
};

export const ContactsListingTable = ({
  contacts,
  rowSelectionModel,
  onRowsSelectionHandler,
  skeletonItemsCount = 10,
  singleSelectableContact,
}: Props) => {
  const usersListingColumns = useTranslationForTableGridXConfig([
    contactsMatchingListingConfig,
    'propertiesPage',
  ]);

  const getMinHeight = () => {
    const count = contacts.length;
    if (contacts) {
      switch (count) {
        case 1:
          return 150;
          break;
        case 0:
          return 120;
          break;
        default:
          return count < 10 ? 88 * (count + 1.2) : 88 * 11.5;
      }
    } else {
      return 0;
    }
  };

  return (
    <Box className={'w-full with-border min-h-[100px]'}>
      {contacts ? (
        <TableGridX
          rows={contacts}
          columns={usersListingColumns}
          pageSize={10}
          sx={{
            '&': {
              minHeight: `${getMinHeight()}px`,
              marginBottom: '2rem',
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
              display: 'none',
            },
            '& .MuiDataGrid-selectedRowCount': {
              opacity: '0',
            },
          }}
          rowHeight={88}
          rowCount={contacts.length}
          checkboxSelection
          onSelectionModelChange={(ids) => {
            if (singleSelectableContact) {
              const selectedIDs = ids.length ? [ids.pop()] : [];
              // @ts-ignore
              onRowsSelectionHandler(selectedIDs);
            } else {
              onRowsSelectionHandler(ids);
            }
          }}
          selectionModel={rowSelectionModel}
        />
      ) : (
        <SkeletonTableGrid
          className="w-full !min-h-[500px]"
          disableSelectionOnClick={true}
          disableColumnSelector={true}
          sx={{
            '&': {
              minHeight: '500px',
            },
          }}
          rows={Array.from({ length: skeletonItemsCount }).map((el, index) => {
            return {
              id: index,
              status: '',
              name: '',
              agency: '',
              agent: '',
              type: '',
              createdAt: '',
              action: '',
            };
          })}
          columns={skeletonContactsMatchingListingConfig}
          rowCount={0}
        />
      )}
    </Box>
  );
};
