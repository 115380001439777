import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import classNames from 'classnames';
import { FC, Fragment, createElement } from 'react';

const PopupStepperNav = ({
  items,
  step = 0,
}: {
  items: {
    Icon?: FC;
    title?: string;
  }[];
  step?: number;
}) => {
  return (
    <nav className="w-full bg-white border-b border-gray-200 justify-start items-start inline-flex">
      {items.map((item, index) => (
        <Fragment key={index}>
          {index > 0 && (
            <div
              className={classNames(
                'w-11 self-stretch p-4 bg-white border-b justify-center items-center gap-1 flex',
                {
                  'border-blue-800': index <= step,
                  'border-gray-200': index > step,
                }
              )}
            >
              <ArrowForwardIos
                sx={{
                  width: '12px',
                  height: '12px',
                  color: index <= step ? '#3446B9' : '#6E6E72',
                }}
                fontSize={'small'}
              />
            </div>
          )}

          <div
            className={classNames(
              'px-8 py-6 bg-white border-b justify-start items-center gap-4 flex',
              {
                'border-blue-800': index <= step,
              }
            )}
          >
            <div className="w-6 h-6 justify-center items-center flex text-blue-800">
              {item.Icon &&
                createElement(item.Icon, {
                  // @ts-ignore
                  color: index <= step ? '#3446B9' : '#6E6E72',
                  width: '24',
                  height: '24',
                  className: '!w-6 !h-6',
                  sx: {
                    width: '24px',
                    height: '24px',
                    color: index <= step ? '#3446B9' : '#6E6E72',
                  },
                })}
            </div>
            <div
              className={classNames('text-2xl font-normal leading-[28.80px]', {
                'text-blue-800': index <= step,
                'text-neutral-500': index > step,
              })}
            >
              {item.title}
            </div>
          </div>
        </Fragment>
      ))}
    </nav>
  );
};

export default PopupStepperNav;
