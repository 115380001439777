import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

export default function SettingsIcon({ color, className }: IconProps) {
  const { mode } = useThemeMode();

  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        d="M1.572 4.289L8 .577l6.428 3.712v7.422L8 15.423 1.572 11.71V4.29z"
      ></path>
      <circle cx="8" cy="8" r="2.5" stroke={color}></circle>
    </svg>
  );
}
