import { DistanceArrayItem } from './index';
import { useTranslation } from 'react-i18next';
import InputFormField from '../../../../../components/form/inputFormField';
import { useForm } from 'react-hook-form';
import { Button, InputAdornment } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PlaceIcon from '@mui/icons-material/Place';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';
import PropertyDistancesMap from './map';
import { Loader } from '@googlemaps/js-api-loader';
import { AxiosResponse } from 'axios';
import { http } from '../../../../../libs/axios';
import { useNotification } from '../../../../../hooks/useNotification';
import classNames from 'classnames';
import SpinnerLoading from '../../../../../features/spinner/spinnerLoading';

interface Props {
  serverData: any;
  mutate: any;
  item: DistanceArrayItem;
  latitude: number;
  longitude: number;
}

interface Body {
  distance: number | null;
  foots: number | null;
  publicTransport: number | null;
  car: number | null;
  keyname?: string;
  property?: string;
}

interface DistanceInfo {
  keyname: string;
  distance: number | null;
  foots: number | null;
  publicTransport: number | null;
  car: number | null;
  property: string;
}

const initialValues: DistanceInfo = {
  keyname: '',
  distance: null,
  foots: null,
  publicTransport: null,
  car: null,
  property: '',
};

interface TravelTimes {
  distance: number; // distance in meters
  foot: number; // duration in minutes
  car: number; // duration in minutes
  publicTransport: number; // duration in minutes
}

async function getTravelTimes(
  origin: google.maps.LatLngLiteral,
  destination: google.maps.LatLngLiteral
): Promise<TravelTimes> {
  const loader = new Loader({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
    version: 'weekly',
    libraries: ['places'],
  });

  await loader.load();

  const service = new google.maps.DistanceMatrixService();

  const travelModes = [
    google.maps.TravelMode.WALKING,
    google.maps.TravelMode.DRIVING,
    google.maps.TravelMode.TRANSIT,
  ];

  const requests = travelModes.map((mode) => ({
    origins: [origin],
    destinations: [destination],
    travelMode: mode,
    unitSystem: google.maps.UnitSystem.METRIC,
  }));

  const responses = await Promise.all(
    requests.map(
      (request) =>
        new Promise<google.maps.DistanceMatrixResponse>((resolve, reject) =>
          service.getDistanceMatrix(request, (response, status) => {
            if (status === google.maps.DistanceMatrixStatus.OK && response) {
              resolve(response);
            } else {
              reject(status);
            }
          })
        )
    )
  );

  const distance =
    responses?.[0]?.rows?.[0]?.elements?.[0]?.distance?.value || 0; // in meters

  const travelTimes: TravelTimes = {
    distance,
    foot: responses?.[0]?.rows?.[0]?.elements?.[0]?.distance?.value || 60, // in minutes
    car: responses?.[1]?.rows?.[0]?.elements?.[0]?.duration?.value || 60, // in minutes
    publicTransport:
      responses?.[2]?.rows?.[0]?.elements?.[0]?.duration?.value || 60, // in minutes
  };

  return travelTimes;
}

const getDefaultValues = (
  distances: DistanceInfo[],
  keyname: string
): DistanceInfo => {
  const foundItem = distances.find((item) => item.keyname === keyname);
  return foundItem ?? initialValues;
};

const PropertyDistanceItem = (props: Props) => {
  const [mapVisible, setMapVisible] = useState(false);
  const { item, longitude, latitude, mutate } = props;
  const { t } = useTranslation('propertiesPage');
  const [selectedPlace, setSelectedPlace] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { addNotification } = useNotification();
  const editMode = props.serverData.distances.find(
    (el: DistanceInfo) => el.keyname === item.name
  );

  const {
    register,
    setValue,
    reset,
    handleSubmit,
    watch,
    formState: { isDirty, isSubmitted },
  } = useForm({
    defaultValues: getDefaultValues(props.serverData.distances, item.name),
  });

  const onGetPositionsClick = () => {
    setMapVisible(!mapVisible);
  };

  const onPlaceSelected = (item: any) => {
    setSelectedPlace(item);
    if (item) {
      const origin = { lat: latitude, lng: longitude };
      const destination = {
        lat: item.geometry.location.lat(),
        lng: item.geometry.location.lng(),
      };

      getTravelTimes(origin, destination).then((results) => {
        if (results) {
          setValue('distance', results.distance);
          setValue(
            'foots',
            Math.floor(results.foot < 60 ? 1 : results.foot / 60)
          );
          setValue(
            'publicTransport',
            Math.floor(
              results.publicTransport < 60 ? 1 : results.publicTransport / 60
            )
          );
          setValue('car', Math.floor(results.car < 60 ? 1 : results.car / 60));
        }
      });
    } else {
      reset();
    }
  };

  const onFormClean = () => {
    const body: Body = {
      distance: null,
      foots: null,
      publicTransport: null,
      car: null,
    };

    reset(body);
    setSelectedPlace(null);

    if (
      editMode &&
      (editMode.distance ||
        editMode.foots ||
        editMode.car ||
        editMode.publicTransport)
    ) {
      submitDataToServer(body);
    }
  };

  useEffect(() => {
    if (isDirty) {
      setSelectedPlace(null);
    }
  }, [isDirty]);

  const onSubmit = (data: any) => {
    const body: Body = {
      distance: data.distance,
      foots: data.foots,
      publicTransport: data.publicTransport,
      car: data.car,
    };

    if (!editMode) {
      body.keyname = item.keyname;
      body.property = props.serverData.id;
    }

    submitDataToServer(body);
  };

  const submitDataToServer = async (body: any) => {
    let promise: Promise<AxiosResponse>;

    if (editMode) {
      promise = http.patch(`/distances/${editMode.id}`, body);
    } else {
      promise = http.post(`/distances`, body);
    }

    setIsLoading(true);
    promise
      .finally(() => {
        setIsLoading(false);
      })
      .then(() => {
        addNotification(`${t(item.label)} ${t('updated')}.`, 'success');
        if (mutate) {
          mutate();
        }
      })
      .catch((error) => {
        let message;
        if (error.response) {
          message = t('alerts:server_error');
        } else if (error.request) {
          message = t('alerts:failed_server_error');
        } else {
          message = t('alerts:unknown_error');
        }
        addNotification(message, 'error');
      });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classNames('relative w-full flex flex-col gap-4 pt-4', {
        'pointer-events-none opacity-70': isLoading,
      })}
    >
      {isLoading ? (
        <div className={'absolute inset-0 z-10'}>
          <SpinnerLoading />
        </div>
      ) : null}
      <p className={'mb-2 font-medium text-lg'}>{t(item.label)}</p>
      <div className={'w-full grid grid-cols-5 gap-2'}>
        <InputFormField
          label={t('Distance')}
          type="number"
          {...register('distance', {
            valueAsNumber: true,
          })}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <span className={'pr-2'}>m</span>
              </InputAdornment>
            ),
          }}
        />
        <InputFormField
          label={t('By_foot')}
          type="number"
          {...register('foots', {
            valueAsNumber: true,
          })}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <span className={'pr-2'}>min</span>
              </InputAdornment>
            ),
          }}
        />
        <InputFormField
          label={t('By_public_transport')}
          type="number"
          {...register('publicTransport', {
            valueAsNumber: true,
          })}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <span className={'pr-2'}>min</span>
              </InputAdornment>
            ),
          }}
        />
        <InputFormField
          label={t('By_car')}
          type="number"
          {...register('car', {
            valueAsNumber: true,
          })}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <span className={'pr-2'}>min</span>
              </InputAdornment>
            ),
          }}
        />
        <div className="flex justify-end items-end gap-2">
          <div className="grow shrink-0">
            <Button
              onClick={onGetPositionsClick}
              variant={mapVisible ? 'outlined' : 'contained'}
              sx={{
                height: '48px',
                display: 'flex',
                gap: '8px',
                width: '100%',
              }}
            >
              {mapVisible ? (
                <CloseIcon fontSize={'small'} />
              ) : (
                <PlaceIcon fontSize={'small'} />
              )}
              {mapVisible ? t('Close_map') : t('Get_positions')}
            </Button>
          </div>
          <div className="grow shrink-0">
            <Button
              disabled={
                !(
                  selectedPlace ||
                  isDirty ||
                  (editMode &&
                    (watch('distance') ||
                      watch('foots') ||
                      watch('car') ||
                      watch('publicTransport')))
                )
              }
              onClick={onFormClean}
              variant="outlined"
              sx={{ height: '48px', color: '#1D1D1F', gap: '8px' }}
            >
              <DeleteOutlineIcon fontSize={'small'} />
              {t('Clear')}
            </Button>
          </div>
        </div>
      </div>

      {mapVisible || isDirty ? (
        <div className={'w-full'}>
          <PropertyDistancesMap
            type={item.googleType}
            name={item.name}
            radius={item.radius}
            longitude={longitude}
            latitude={latitude}
            onPlaceSelected={onPlaceSelected}
            selectedPlace={selectedPlace}
            mapVisible={mapVisible}
            atLeastOneFieldFilled={
              !!(
                watch('distance') ||
                watch('foots') ||
                watch('car') ||
                watch('publicTransport')
              )
            }
            isDirty={isDirty}
            isSubmitSuccessful={isSubmitted}
          />
        </div>
      ) : null}
    </form>
  );
};

export default PropertyDistanceItem;
