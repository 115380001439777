import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { InputLabel, Switch } from '@mui/material';

import { IProperty } from '../../../../../types/property';
import StepsFieldset from '../../../../../components/stepper/stepsFieldset';
import InputFormField from '../../../../../components/form/inputFormField';
import SwitcherFormField from '../../../../../components/form/switcherFormField';
import { numberInputProps } from './const';

function AdditionalPreferences({
  defaultValues,
}: {
  defaultValues?: Partial<IProperty>;
}) {
  const { register, setValue } = useFormContext<IProperty>();
  const { t } = useTranslation('propertiesPage');

  const [balconyAvailable, setBalconyAvailable] = useState(false);
  const [parkingBoxesAvailable, setParkingBoxesAvailable] = useState(false);
  const [parkingAvailable, setParkingAvailable] = useState(false);
  const [terraceAvailable, setTerraceAvailable] = useState(false);
  const [garage, setGarage] = useState(false);

  useEffect(() => {
    setBalconyAvailable(!!defaultValues?.balcony);
    setTerraceAvailable(!!defaultValues?.terrace);
    setParkingAvailable(!!defaultValues?.parkingAvailable);
    setParkingBoxesAvailable(!!defaultValues?.parkingBoxesAvailable);
    setGarage(!!defaultValues?.garage);
  }, []);

  return (
    <StepsFieldset
      className="grid grid-cols-6 gap-32"
      title={t('Additional_preferences')}
    >
      <div className="col-span-2 grid gap-4">
        <SwitcherFormField
          label={t('propertiesPage:pool')}
          name="pool"
          checked={defaultValues?.pool}
          register={register}
        />
        <SwitcherFormField
          label={t('propertiesPage:lift')}
          name="lift"
          checked={defaultValues?.lift}
          register={register}
        />
        <SwitcherFormField
          label={t('propertiesPage:garden')}
          name="garden"
          checked={defaultValues?.garden}
          register={register}
        />
      </div>
      <div className="col-span-4">
        <div className="flex items-center gap-32">
          <div className="flex items-center gap-8">
            <InputLabel className="!text-xs w-28">
              {t('propertiesPage:parking', { what: t('') })}
            </InputLabel>
            <Switch
              onClick={() => {
                setParkingAvailable(!parkingAvailable);
                setValue('parkingNumbers', parkingAvailable ? 0 : 1);
              }}
              color="primary"
              {...register('parkingAvailable')}
              defaultChecked={defaultValues?.parkingAvailable}
            />
          </div>

          <InputFormField
            style={{ maxWidth: 150 }}
            type="number"
            label={t('propertiesPage:Number_Of', {
              what: t('propertiesPage:parking', { what: t('') }),
            })}
            {...register('parkingNumbers', {
              valueAsNumber: true,
            })}
            disabled={!parkingAvailable}
          />
        </div>
        <div className="flex  items-center gap-32 mt-2">
          <div className="flex items-center gap-8">
            <InputLabel className="!text-xs w-28">
              {t('propertiesPage:parking', {
                what: t('propertiesPage:boxes'),
              })}
            </InputLabel>
            <Switch
              onClick={() => {
                setParkingBoxesAvailable(!parkingBoxesAvailable);
                setValue('parkingBoxesNumbers', parkingBoxesAvailable ? 0 : 1);
              }}
              {...register('parkingBoxesAvailable')}
              color="primary"
              defaultChecked={defaultValues?.parkingBoxesAvailable}
            />
          </div>

          <InputFormField
            style={{ maxWidth: 150 }}
            type="number"
            label={t('propertiesPage:Number_Of', {
              what: t('propertiesPage:parking', {
                what: t('propertiesPage:boxes'),
              }),
            })}
            {...register('parkingBoxesNumbers', {
              valueAsNumber: true,
            })}
            disabled={!parkingBoxesAvailable}
          />
        </div>
        <div className="flex items-center gap-32 mt-2">
          <div className="flex items-center gap-8">
            <InputLabel className="!text-xs w-28">
              {t('propertiesPage:Balcony')}
            </InputLabel>
            <Switch
              onClick={() => {
                setBalconyAvailable(!balconyAvailable);
                setValue('balcony', balconyAvailable ? 0 : 1);
              }}
              color="primary"
              defaultChecked={
                defaultValues?.balcony && defaultValues?.balcony > 0
                  ? true
                  : false
              }
            />
          </div>

          <InputFormField
            style={{ maxWidth: 150 }}
            type="number"
            label={t('propertiesPage:Number_Of', {
              what: t('propertiesPage:balcony'),
            })}
            {...register('balcony', {
              valueAsNumber: true,
            })}
            disabled={!balconyAvailable}
          />
        </div>
        <div className="flex items-center gap-32 mt-2">
          <div className="flex items-center gap-8">
            <InputLabel className="!text-xs w-28">
              {t('propertiesPage:Terrace')}
            </InputLabel>
            <Switch
              onClick={() => {
                setTerraceAvailable(!terraceAvailable);
                setValue('terrace', terraceAvailable ? 0 : 1);
              }}
              color="primary"
              defaultChecked={
                defaultValues?.terrace && defaultValues?.terrace > 0
                  ? true
                  : false
              }
            />
          </div>

          <InputFormField
            style={{ maxWidth: 150 }}
            type="number"
            disabled={!terraceAvailable}
            label={t('propertiesPage:Number_Of', {
              what: t('propertiesPage:terrace'),
            })}
            {...register('terrace', {
              valueAsNumber: true,
            })}
          />
        </div>

        <div className="flex items-center gap-32 mt-2">
          <div className="flex items-center gap-8">
            <InputLabel className="!text-xs w-28">
              {t('propertiesPage:Garage')}
            </InputLabel>
            <Switch
              onClick={() => {
                setGarage(!garage);
                garage ? setValue('nbGarage', 0) : setValue('nbGarage', 1);
              }}
              color="primary"
              {...register('garage')}
              defaultChecked={defaultValues?.garage}
            />
          </div>

          <InputFormField
            style={{ maxWidth: 150 }}
            type="number"
            inputProps={numberInputProps}
            label={t('propertiesPage:Number_Of_garage')}
            {...register('nbGarage', {
              valueAsNumber: true,
            })}
            disabled={!garage}
          />
        </div>
      </div>
    </StepsFieldset>
  );
}

export default AdditionalPreferences;
