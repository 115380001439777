import { Alert, Snackbar } from '@mui/material';
import { useNotification } from '../../hooks/useNotification';

function GlobalSnackbar() {
  const { snackbarIsDisplayed, message, severity, hideSnackbar } =
    useNotification();

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={3000}
      onClose={hideSnackbar}
      open={snackbarIsDisplayed}
    >
      <div>
        <Alert
          className="task_result"
          onClose={hideSnackbar}
          severity={severity}
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </div>
    </Snackbar>
  );
}

export default GlobalSnackbar;
