import { useNavigate } from 'react-router-dom';
import { useThemeMode } from '../../hooks/useThemeMode';

type ActionBtnDataGridT = {
  userId?: number | undefined;
  isOnAction?: boolean;
  color?: string;
};

function ActionBtnDataGrid({
  color,
  userId,
  isOnAction = false,
  ...attributes
}: ActionBtnDataGridT) {
  const navigate = useNavigate();
  const { mode } = useThemeMode();
  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  if (!userId && isOnAction) return null;
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer"
      {...attributes}
      onClick={() => isOnAction && navigate(`${userId}`)}
    >
      <path
        d="M6.76 2.5H1V15H13V8.75"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 1.92765L4.75 9L4 12L7.13333 11.5682L14.0338 4.5M11.5 1.92765L12.121 1.28639C12.8109 0.573888 13.946 0.550025 14.6653 1.2329V1.2329C15.3986 1.9291 15.4187 3.0913 14.7098 3.81235L14.0338 4.5M11.5 1.92765L14.0338 4.5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ActionBtnDataGrid;
