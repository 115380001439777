import { countries } from '../../../const/countries';
import useLocale from '../../../hooks/useLocale';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Select,
  MenuItem,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { Controller } from 'react-hook-form';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import useSWR from 'swr';
import { AutocompleteOption } from '../../../components/form/autoCompleteField';
import InputFormField from '../../../components/form/inputFormField';
import InputLayout from '../../../components/form/components/inputLayout';
import { selectOptionStringT } from '../../../components/form/selectFormField';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { IContactGreeting } from '../../../types/contacts';
import IndividualAgent from './individual-agent';
import { useTranslation } from 'react-i18next';
dayjs.extend(utc);
const languagesOptions = [
  { id: 1, label: 'English' },
  { id: 2, label: 'French' },
];

export const greetingsOptions = [
  { id: 34, value: 'Dear Mr.' },
  { id: 25, value: 'Dear Mrs.' },
];

const IndividualInformationFormFields = (props: {
  inner?: boolean;
  register: any;
  watch: any;
  setValue?: any;
  control: any;
  errors: any;
}) => {
  const { inner = false, register, control, watch, setValue } = props;
  const { locale } = useLocale();
  const watchBirthdate = watch('birthdate');
  const { t } = useTranslation('common');
  useEffect(() => {
    if (watchBirthdate) {
      const today = new Date();
      const birthdate = new Date(watchBirthdate);
      const year = today.getFullYear();
      const birthYear = birthdate.getFullYear();

      if (setValue) {
        setValue('age', String(year - birthYear));
      }
    }
  }, [watchBirthdate]);

  const countryOptions: AutocompleteOption[] = countries.map((country) => ({
    id: String(country.alpha2),
    label: country[locale],
  }));

  const { data: contactGreetingOptionsAPI } =
    useSWR<IContactGreeting[]>(`/contact_greetings`);
  const contactGreetingOptions: selectOptionStringT[] =
    contactGreetingOptionsAPI
      ? contactGreetingOptionsAPI.map((item) => ({
          value: item?.translations[locale]?.name || '',
          id: item.id,
        }))
      : [];

  return (
    <>
      {inner ? (
        <>
          <InputFormField
            type="text"
            label={t('In_the_capacity_of')}
            {...register('capacity')}
          />
          <div />
          <div />
        </>
      ) : null}
      <div className={'grid grid-cols-2 gap-8 2xl:gap-16'}>
        <InputLayout label={t('Title')}>
          <Controller
            control={control}
            name="title"
            render={({ field }) => (
              <RadioGroup
                sx={{
                  flexDirection: 'row',
                }}
                {...field}
              >
                <FormControlLabel
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontSize: '12px',
                    },
                  }}
                  value="Mr"
                  control={<Radio className="contact_gender" />}
                  label={t('Mr')}
                />
                <FormControlLabel
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontSize: '12px',
                    },
                  }}
                  value="Mrs"
                  control={<Radio className="contact_gender" />}
                  label={t('Mrs')}
                />
              </RadioGroup>
            )}
          />
        </InputLayout>
        <InputLayout label={t('Greeting')}>
          <Controller
            name="greeting"
            control={control}
            render={({ field }) => (
              <Select labelId="level-label" {...field}>
                {contactGreetingOptions.map(
                  (item: selectOptionStringT, index: number) => (
                    <MenuItem
                      key={index}
                      value={item?.id}
                      className={`capitalize greeting_${item.id}`}
                    >
                      {item.value}
                    </MenuItem>
                  )
                )}
              </Select>
            )}
          />
        </InputLayout>
      </div>
      <InputFormField
        className="first_name_contact"
        type="text"
        label={t('First_name')}
        isRequired
        {...register('firstname', { required: true })}
      />
      <InputFormField
        className="last_name_contact"
        type="text"
        label={t('Last_name')}
        isRequired
        {...register('lastname', { required: true })}
      />
      <InputFormField
        className="phone_contact"
        type="number"
        label={t('Phone')}
        {...register('phone')}
      />
      <InputFormField
        className="email_contact"
        type="email"
        label={t('Email')}
        {...register('email')}
      />
      <InputLayout label={t('Main_language')}>
        <Controller
          control={control}
          name="language"
          {...register('language')}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              onChange={(event, item) => {
                onChange(item);
              }}
              value={value}
              options={languagesOptions}
              renderInput={(params) => (
                <TextField
                  className="languages_contact"
                  variant="outlined"
                  {...params}
                />
              )}
            />
          )}
        />
      </InputLayout>
      <div className={'flex gap-8 2xl:gap-16 items-center'}>
        <InputLayout label={t('Date_of_birth')}>
          <Controller
            name="birthdate"
            control={control}
            render={({ field: { onChange, ...restField } }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  className="birthdate_contact"
                  onChange={(event) => {
                    onChange(dayjs.utc(event));
                  }}
                  {...restField}
                  format="DD/MM/YYYY"
                />
              </LocalizationProvider>
            )}
          />
        </InputLayout>
        <InputFormField
          className="age_contact"
          type="text"
          label={t('Age')}
          {...register('age')}
        />
      </div>
      <InputFormField
        className="place_of_birth_contact"
        type="text"
        label={t('Place_of_birth')}
        {...register('birthplace')}
      />
      <InputLayout label={t('Nationality')}>
        <Controller
          control={control}
          name="nationality"
          {...register('nationality')}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              onChange={(event, item) => {
                onChange(item);
              }}
              value={value}
              options={countryOptions}
              renderInput={(params) => (
                <TextField
                  className="nationality_contact"
                  variant="outlined"
                  {...params}
                />
              )}
            />
          )}
        />
      </InputLayout>
      <div className={'col-start-1 col-end-3'}>
        <InputFormField
          className="address_contact"
          type="text"
          label={t('Address')}
          {...register('adresse')}
        />
      </div>
      <InputFormField
        className="area_code_contact"
        type="number"
        label={t('Area_code')}
        {...register('zipCode')}
      />
      <InputFormField
        className="city_contact"
        type="text"
        label={t('City')}
        {...register('city')}
      />
      <InputLayout label={t('Country')}>
        <Controller
          control={control}
          name="country"
          {...register('country')}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              onChange={(event, item) => {
                onChange(item);
              }}
              value={value}
              options={countryOptions}
              renderInput={(params) => (
                <TextField
                  className="country_contact"
                  variant="outlined"
                  {...params}
                />
              )}
            />
          )}
        />
      </InputLayout>
      <div />
      {!inner ? <IndividualAgent control={control} /> : null}
    </>
  );
};

export default IndividualInformationFormFields;
