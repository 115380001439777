import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '../../../../../hooks/useQuery';
import LandingFeaturesList from '../../../../../components/landing/featuresList';

type Props = {
  data: any;
};

function PropertyFeaturesItems({ data }: Props) {
  const { conveniences } = data;
  const { t } = useTranslation('propertiesPage');
  const query = useQuery();
  const locale = query.get('lang') || 'en';
  const propertyFeatures: string[] = useMemo(() => {
    const propertyFeaturesArray: any[] = [];

    Object.keys(conveniences).map((key) => {
      conveniences[key].map((singleFeature: any) => {
        propertyFeaturesArray.push(singleFeature);
      });
    });

    if (propertyFeaturesArray) {
      if (propertyFeaturesArray && !propertyFeaturesArray.includes(undefined))
        return propertyFeaturesArray.map(({ name }) => name);
      else {
        return [];
      }
    }
  }, [locale, conveniences]) as [];

  if (!conveniences || !propertyFeatures.length) return null;

  return (
    <LandingFeaturesList
      features={propertyFeatures.map((feature) =>
        locale == 'en'
          ? feature
          : t(feature.replaceAll(' ', '_'), { lng: locale })
      )}
    />
  );
}

export default PropertyFeaturesItems;
