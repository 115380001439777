import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useAuth } from '../../hooks/useAuth';
import Layout from '../../layout/layout';
import { RolesT } from '../../types/user';

interface Props {
  fromLoggedUsers?: boolean;
  withLayout?: boolean;
  notAllowedRoles?: RolesT;
}

const PrivateRoute: FC<Props> = ({
  fromLoggedUsers = false,
  withLayout = true,
  notAllowedRoles,
}) => {
  const {
    user: { roles },
    tokens,
  } = useAuth();

  const isAuthenticated = Boolean(tokens);

  if (!isAuthenticated && !fromLoggedUsers) {
    return <Navigate to="/signin" />;
  } else if (
    notAllowedRoles &&
    notAllowedRoles.some((role) => roles?.includes(role))
  ) {
    return <Navigate to="/" />;
  } else if (isAuthenticated && fromLoggedUsers) {
    return <Navigate to="/" />;
  } else if (!isAuthenticated || !withLayout) {
    return <Outlet />;
  } else {
    return (
      <Layout>
        <Outlet />
      </Layout>
    );
  }
};

export default PrivateRoute;
