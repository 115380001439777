import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

export default function PlusIcon({
  color,
  className,
  width,
  height,
}: IconProps) {
  const { mode } = useThemeMode();

  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width || '16'}
      height={height || '16'}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path stroke={color} strokeLinecap="round" d="M8 1.5v13M1.5 8h13"></path>
    </svg>
  );
}
