import { formatPrice } from '../../../../../helpers/utils';
import { PromotionWidgetPreviewProps } from '../../../../../types/brochureEditor';
import TableWidget from '../../tableWidget';

// TODO: translate

const PromotionChildPropertiesWidget = ({
  promotion,
}: PromotionWidgetPreviewProps) => {
  const lines = promotion.properties.map((property) => {
    return {
      id: `${property.id}`,
      label: `#${property.reference}`,
      value: `Rooms: ${property.rooms}, living area: ${
        property.livingArea
      } m², price: ${formatPrice(property.originalPrice)}`,
    };
  });

  return <TableWidget title="Properties" items={lines} />;
};

export default PromotionChildPropertiesWidget;
