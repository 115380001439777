import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { http } from '../../../../../libs/axios';
import { IMedia, Portal } from '../../../../../types/api';

import DropdownWithToggles from '../../../../../components/form/galleryField/dropdownWithToggles';
import { useImageState } from '../../../../../hooks/useImageState';

function PropertyImageToggles({
  image,
  mutate,
}: {
  image: IMedia;
  mutate?: () => Promise<unknown>;
  isPublished?: any;
}) {
  const { t } = useTranslation();
  const defaultEntries = [
    {
      label: 'isMain',
      name: 'isMain',
      value: image.isMain || false,
    },
    {
      label: 'onWebsite',
      name: 'onWebsite',
      value: image.onWebsite || false,
    },
    {
      label: 'onBrochure',
      name: 'onBrochure',
      value: image.onBrochure || false,
    },
    {
      label: 'onPortal',
      name: 'onPortal',
      value: image.onPortal || false,
    },
  ];
  const { stateEntries, handleSwitchChange } = useImageState({
    image,
    mutate,
    defaultEntries,
  });

  const [apiPortals, setAPIPortals] = useState<Portal[]>(image.portals || []);
  const handlePortalPublication =
    (portalToUpdate: Portal) => async (event: ChangeEvent<any>) => {
      const updatedPortals = apiPortals.map((portal: Portal) =>
        portal.portal.id === portalToUpdate.portal.id
          ? { ...portal, isShown: event.target.checked }
          : portal
      );
      setAPIPortals(updatedPortals);
      const updatedDataToSend = updatedPortals.map((item) => {
        return {
          ...item,
          portal: item.portal.id,
        };
      });

      const dataToSend = {
        portals: [...updatedDataToSend],
      };

      await http.patch(`media/${image.id}`, dataToSend);
      if (mutate) {
        await mutate();
      }
    };

  const toggles = [];

  for (const entry of stateEntries) {
    toggles.push({
      title: t(`usersPage:${entry.name}`),
      value: entry.value,
      checked: entry.value,
      onClick: handleSwitchChange(stateEntries.indexOf(entry)),
    });
  }

  for (const item of apiPortals) {
    toggles.push({
      title: item.portal.name,
      value: item.isShown,
      checked: item.isShown,
      onClick: handlePortalPublication(item),
    });
  }

  if (image.shared) {
    return null;
  }

  return <DropdownWithToggles toggles={toggles} />;
}

export default PropertyImageToggles;
