import { useDebounce } from 'react-use';
import { useCallback, useState } from 'react';
import useLocale from '../../hooks/useLocale';
import { http } from '../../libs/axios';
import { ApiListing } from '../../types/api';
import { IContactListItem } from '../../types/contacts';

export function useContactMagicSearch({
  searchQuery = '',
  page = 1,
  itemsPerPage = 10,
}: {
  searchQuery?: string;
  page?: number;
  itemsPerPage?: number;
}) {
  searchQuery = searchQuery.trim();

  const { locale } = useLocale();
  const [listing, setListing] = useState<ApiListing<IContactListItem>>();
  const [isSearching, setIsSearching] = useState(false);

  const searchProperty = useCallback(async () => {
    setIsSearching(true);
    try {
      if (!searchQuery.length) {
        throw new Error('Empty query');
      }
      const params = new URLSearchParams({
        searchQuery,
        lang: locale,
        page: String(page),
        itemsPerPage: String(itemsPerPage),
      });
      const { data } = await http.get<ApiListing<IContactListItem>>(
        `/contacts/elastic/magic_field?${params.toString()}`
      );
      setListing(data);
    } catch (error) {
      setListing(undefined);
    }
    setIsSearching(false);
  }, [searchQuery, locale, page, itemsPerPage]);

  useDebounce(searchProperty, 200, [searchProperty]);

  return { listing, isSearching };
}
