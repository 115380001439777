import { ChangeEvent, ReactNode } from 'react';
import SwitcherIosFormField from '../../form/switcherIosFormField';

function BooleanOption({
  invert,
  title,
  value,
  onChange,
  extra,
}: {
  invert?: boolean;
  title?: string;
  extra?: ReactNode;
  value: boolean;
  onChange: ({ target: { value } }: { target: { value: boolean } }) => void;
}) {
  if (invert) {
    value = !value;
  }
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let newValue = e.target.checked;
    if (invert) {
      newValue = !newValue;
    }
    onChange({ target: { value: newValue } });
  };
  return (
    <div className="grid gap-8 border-t border-gray-200 py-8">
      <div className="flex justify-between">
        <span>{title}</span>
        <SwitcherIosFormField checked={value} onChange={handleChange} />
      </div>
      {value && extra}
    </div>
  );
}

export default BooleanOption;
