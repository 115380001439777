import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import { Suspense, lazy } from 'react';

import PrivateRoute from '../components/common/privateRoute';
import PageNotFound from '../pages/404/404';

import Dashboard from '../pages/index/index';
import SignIn from '../pages/auth/signIn';
import ForgetPassword from '../pages/auth/forgetPassword';
import ModifyPassword from '../pages/auth/modifyPassword';
import TwoFactorAuthentication from '../pages/auth/twoFactorAuth';

import ProfileSettings from '../pages/profile';
import UsersListingPage from '../pages/users/usersListingPage';
import SingleUserPage from '../pages/users/singleUserPage';

import PropertiesPage from '../pages/properties';
import SingleProperty from '../pages/properties/singleProperty';
import CreateProperty from '../pages/properties/create';
import EditProperty from '../pages/properties/edit';
import PropertyLandingPage from '../pages/properties/landing';
const OwnersReportReport = lazy(
  () => import('../pages/properties/propertiesListing/ownersReport/report')
);

import PromotionsPage from '../pages/promotions';
import SinglePromotion from '../pages/promotions/singlePromotion';
import PromotionLandingPage from '../pages/promotions/landing';

const CreatePromotion = lazy(() => import('../pages/promotions/create'));
const EditPromotion = lazy(() => import('../pages/promotions/edit'));

import Contacts from '../pages/contacts';
import SingleContactPage from '../pages/contacts/singleContactPage';
import CreateContact from '../pages/contacts/create';

import ListBrochureTemplates from '../pages/brochureTemplates';
import BrochureEditor from '../pages/brochureTemplates/edit';
import PrintableBrochureView from '../pages/brochureTemplates/printableView';
import GeneratedBrochures from '../pages/generatedBrochures';

import PortalsPage from '../pages/settingsPage/portals';
import CreatePortal from '../pages/settingsPage/portals/create';
import SinglePortalPage from '../pages/settingsPage/portals/singlePortalPage';
import PersonalNotifications from '../pages/personalNotifications';
import PipelinesListing from '../pages/settingsPage/pipelines';
import EditPipeline from '../pages/settingsPage/pipelines/edit';
import SettingsListPage from '../pages/settingsPage';
import SettingsPage from '../pages/settingsPage/tabbedSettings';
import NetworkStartPage from '../pages/settingsPage/networks';
import CreateUpdateNetworkPage from '../pages/settingsPage/networks/create';
import SingleNetwork from '../pages/settingsPage/networks/singleNetwork';
import AddAgenciesPage from '../pages/settingsPage/networks/singleNetwork/addAgencies';
import SingleAgency from '../pages/settingsPage/networks/agencies/singlePage';
import CustomAttTagsList from '../pages/settingsPage/customAttrTags';
import CustomAttributesTags from '../pages/settingsPage/customAttrTags/customAttributesTags';
import EditCustomTags from '../pages/settingsPage/customAttrTags/editCustomTags';
import EmailTemplatesPage from '../pages/settingsPage/emailTemplates';
import SpaceType from '../pages/settingsPage/spaceTypeSettings';

const Agencies = lazy(() => import('../pages/agencies'));

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<PrivateRoute />}>
        <Route index element={<Dashboard />} />
        <Route path="/profile" element={<ProfileSettings />} />
      </Route>
      {/* USERS PAGE ROUTES  */}
      <Route
        path="users"
        element={<PrivateRoute notAllowedRoles={['ROLE_ASSISTANT']} />}
      >
        <Route index element={<UsersListingPage />} />
        <Route path=":userId" element={<SingleUserPage />} />
      </Route>
      {/* AGENCIES PAGE ROUTES  */}
      <Route
        path="agencies"
        element={
          <PrivateRoute notAllowedRoles={['ROLE_USER', 'ROLE_ASSISTANT']} />
        }
      >
        <Route
          index
          element={
            <Suspense fallback={null}>
              <Agencies />
            </Suspense>
          }
        />
      </Route>
      {/* PROMOTIONS PAGE ROUTES  */}
      <Route path="promotions" element={<PrivateRoute />}>
        <Route index element={<PromotionsPage />} />
        <Route index path={'archived'} element={<PromotionsPage />} />
        <Route
          path="new"
          element={
            <Suspense fallback={null}>
              <CreatePromotion />
            </Suspense>
          }
        />
        <Route path=":id" element={<SinglePromotion />} />
        <Route
          path=":id/edit/*"
          element={
            <Suspense fallback={null}>
              <EditPromotion />
            </Suspense>
          }
        />
        <Route path=":promotionId/brochures" element={<GeneratedBrochures />} />
      </Route>
      {/* PROPERTIES PAGE ROUTES  */}
      <Route path="properties" element={<PrivateRoute />}>
        <Route index element={<PropertiesPage />} />
        <Route
          path="archivedProperties"
          element={<PropertiesPage isArchived />}
        />
        <Route path="networks" element={<PropertiesPage networks />} />
        <Route path="new" element={<CreateProperty />} />
        <Route path=":propertyId" element={<SingleProperty />} />
        <Route path=":id/edit/*" element={<EditProperty />} />
        <Route path=":propertyId/brochures" element={<GeneratedBrochures />} />
      </Route>
      {/* OWNERS REPORT ROUTE */}
      <Route path="ownersReport" element={<PrivateRoute withLayout={false} />}>
        <Route
          path=":propertyId"
          element={
            <Suspense fallback={null}>
              <OwnersReportReport />
            </Suspense>
          }
        />
      </Route>
      {/* WEBSITES PAGE ROUTES  */}
      <Route path="notifications" element={<PrivateRoute />}>
        <Route index element={<PersonalNotifications />} />
      </Route>
      {/* WEBSITES PAGE ROUTES  */}
      <Route path="networks" element={<PrivateRoute />}>
        <Route path="create/*" element={<CreateUpdateNetworkPage />} />
        <Route path=":networkId" element={<SingleNetwork />} />
        <Route path=":id/edit/*" element={<CreateUpdateNetworkPage />} />
        <Route path=":id/edit/add-new" element={<AddAgenciesPage />} />
        <Route path=":id/agencies/:agencyId" element={<SingleAgency />} />
      </Route>
      {/* CONTACTS PAGE ROUTES  */}
      <Route path="contacts" element={<PrivateRoute />}>
        <Route index element={<Contacts />} />
        <Route index path="archived" element={<Contacts />} />
        <Route path="rejected" element={<Contacts />} />
        <Route path="create" element={<CreateContact />} />
        <Route path=":userId" element={<SingleContactPage />} />
        <Route path=":userId/*" element={<SingleContactPage />} />
      </Route>
      {/* BROCHURE EDITOR ROUTES */}
      <Route
        path="/brochure-templates"
        element={
          <PrivateRoute
            notAllowedRoles={['ROLE_USER', 'ROLE_ASSISTANT']}
            withLayout={false}
          />
        }
      >
        <Route index element={<ListBrochureTemplates />} />
        <Route path="new" element={<BrochureEditor />} />
        <Route path=":templateId" element={<BrochureEditor />} />
        <Route
          path=":templateId/property/:propertyId"
          element={<BrochureEditor />}
        />
        <Route
          path=":templateId/promotion/:promotionId"
          element={<BrochureEditor />}
        />
        <Route
          path=":templateId/property/:propertyId/pdf"
          element={<PrintableBrochureView />}
        />
        <Route
          path=":templateId/promotion/:promotionId/pdf"
          element={<PrintableBrochureView />}
        />
      </Route>
      {/* PUBLIC ROUTES  */}
      <Route
        path="/signin"
        element={<PrivateRoute fromLoggedUsers></PrivateRoute>}
      >
        <Route index element={<SignIn />} />
      </Route>
      <Route
        path="/forget-password"
        element={<PrivateRoute fromLoggedUsers></PrivateRoute>}
      >
        <Route index element={<ForgetPassword />} />
      </Route>
      <Route
        path="/set-password/:token"
        element={<PrivateRoute fromLoggedUsers></PrivateRoute>}
      >
        <Route index element={<ModifyPassword type="set" />} />
      </Route>
      <Route
        path="/reset-password/:token"
        element={<PrivateRoute fromLoggedUsers></PrivateRoute>}
      >
        <Route index element={<ModifyPassword type="reset" />} />
      </Route>
      <Route
        path="/2fa/:token"
        element={<PrivateRoute fromLoggedUsers></PrivateRoute>}
      >
        <Route index element={<TwoFactorAuthentication />} />
      </Route>

      <Route path="/property/:reference" element={<PropertyLandingPage />} />
      <Route path="/promotion/:reference" element={<PromotionLandingPage />} />

      {/* Settings PAGE ROUTES  */}
      <Route
        path="settings"
        element={
          <PrivateRoute notAllowedRoles={['ROLE_USER', 'ROLE_ASSISTANT']} />
        }
      >
        <Route index element={<SettingsListPage />} />
        <Route path="/settings/*" element={<SettingsPage />} />
        <Route
          index
          path="/settings/customAttrTags"
          element={<CustomAttTagsList />}
        />
        <Route
          path="/settings/customAttrTags/create"
          element={<CustomAttributesTags />}
        />
        <Route
          path="/settings/customAttrTags/edit/:id"
          element={<EditCustomTags />}
        />
        <Route path="/settings/portals" element={<PortalsPage />} />
        <Route path="/settings/networks" element={<NetworkStartPage />} />
        <Route
          path="/settings/email-templates"
          element={<EmailTemplatesPage />}
        />
        <Route path="/settings/portals/create" element={<CreatePortal />} />
        <Route
          path="/settings/portals/:userId"
          element={<SinglePortalPage />}
        />
        <Route
          path="/settings/portals/:userId/*"
          element={<SinglePortalPage />}
        />
        <Route path="/settings/pipelines" element={<PipelinesListing />} />
        <Route path="/settings/pipelines/new" element={<EditPipeline />} />
        <Route path="/settings/pipelines/:id" element={<EditPipeline />} />
        <Route index path="/settings/space-type" element={<SpaceType />} />
        <Route path="/settings/*" element={<SettingsPage />} />
      </Route>
      {/* 404 ROUTES  */}
      <Route path="*" element={<PageNotFound />} />
    </>
  )
);
