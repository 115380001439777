import { useTranslation } from 'react-i18next';
import TitleHeader from '../../../components/common/titleHeader';
import React, { FC } from 'react';
import TabbedStepper from '../../../components/stepper';
import NetworkInformation from './forms/networkInformation';
import { StepComponentProps } from '../../../components/stepper';
import { INetworkApi, INetwork } from '../../../types/networks';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';

export type NetworkStep = StepComponentProps<INetwork, INetworkApi>;

const mapStepNamesToComponents = {
  'Network Information': NetworkInformation,
} as Record<string, FC<NetworkStep>>;

const CreateUpdateNetworkPage = () => {
  const { id } = useParams();
  const { t } = useTranslation('networks');
  const isAdmin = JSON.parse(localStorage.getItem('isAdmin') as string);
  const { data: serverData, mutate } = useSWR<INetworkApi>(
    id ? `/networks/${id}` : null
  );

  if (!isAdmin.isAdmin) {
    return null;
  }

  return (
    <>
      <TitleHeader
        title={id ? t('Edit_MLS_network') : t('Create_new_MLS_network')}
        goBackLink={id ? `/networks/${id}` : '/settings/networks'}
      >
        <TabbedStepper
          mapStepNamesToComponents={mapStepNamesToComponents}
          serverData={serverData}
          mutate={id ? mutate : undefined}
          singularName="Network"
          apiPath="/networks"
          clientPath="/networks"
          convertToClient={convertToClient}
          redirect={'/networks'}
        />
      </TitleHeader>
    </>
  );
};

export default CreateUpdateNetworkPage;

const convertToClient = (serverData?: INetworkApi): INetwork | undefined => {
  if (!serverData) return undefined;

  const result: INetwork = {
    id: serverData.id,
    translations: serverData.translations,
    status: serverData.status,
    enabled: serverData.enabled,
    creator: serverData.creator.id.toString(),
  };

  return result;
};
