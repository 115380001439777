import * as React from 'react';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { MenuItem, Select } from '@mui/material';
// import { selectOptionStringT } from "../../../../components/form/selectFormField";
import InputLayout from '../../../../components/form/components/inputLayout';
import useSWR from 'swr';
import {
  IConvenienceApi,
  IConvenienceChoiceApi,
} from '../../../../types/conveniences';
import { useTranslation } from 'react-i18next';

interface Props {
  control: any;
  setValue: any;
  selection: number[];
}

const Conveniances = ({ control, setValue, selection }: Props) => {
  const { data: items } = useSWR<IConvenienceApi[]>(`/conveniences`);
  const [environmentOptions, setEnvironmentOptions] = useState<
    IConvenienceChoiceApi[]
  >([]);
  const [transportOptions, setTransportOptions] = useState<
    IConvenienceChoiceApi[]
  >([]);
  const [storesOptions, setStoresOptions] = useState<IConvenienceChoiceApi[]>(
    []
  );
  const [schoolingOptions, setSchoolingOptions] = useState<
    IConvenienceChoiceApi[]
  >([]);
  const [sportsOptions, setSportsOptions] = useState<IConvenienceChoiceApi[]>(
    []
  );
  const [leisureOptions, setLeisureOptions] = useState<IConvenienceChoiceApi[]>(
    []
  );
  const [outsideOptions, setOutsideOptions] = useState<IConvenienceChoiceApi[]>(
    []
  );
  const { t } = useTranslation('common');
  useEffect(() => {
    if (items) {
      const envOptions = items[0]?.choices;
      const transOptions = items[1]?.choices;
      const storeOptions = items[2]?.choices;
      const schoolOptions = items[3]?.choices;
      const sportOptions = items[4]?.choices;
      const leisuresOptions = items[5]?.choices;
      const outsideOptions = items[6]?.choices;

      for (let i = 0; i < items.length; i++) {
        items[i]?.choices.forEach((element: any) => {
          element.value = element.name;
        });
      }

      setEnvironmentOptions(envOptions || []);
      setTransportOptions(transOptions || []);
      setStoresOptions(storeOptions || []);
      setSchoolingOptions(schoolOptions || []);
      setSportsOptions(sportOptions || []);
      setLeisureOptions(leisuresOptions || []);
      setOutsideOptions(outsideOptions || []);
    }
  }, [items]);

  useEffect(() => {
    if (items && selection) {
      for (const option of items) {
        const optionChoiceIds = option.choices.map((c) => String(c.id));
        const selectedOptionChoiceIds = selection.filter((id) =>
          optionChoiceIds.includes(String(id))
        );
        setValue(option.keyname, selectedOptionChoiceIds);
      }
    }
  }, [items, selection]);

  const neighborhoodOptions = [
    {
      name: 'environment',
      label: 'Environment',
      options: environmentOptions,
    },
    {
      name: 'stores',
      label: 'Stores',
      options: storesOptions,
    },
    {
      name: 'transports',
      label: 'Transports',
      options: transportOptions,
    },
    {
      name: 'children',
      label: 'Children and schooling',
      options: schoolingOptions,
    },
    {
      name: 'sport',
      label: 'Sport',
      options: sportsOptions,
    },
    {
      name: 'leisure',
      label: 'Leisure and point of interest',
      options: leisureOptions,
    },
  ];

  return (
    <div>
      <p className={'mb-8'}> {t('Neighborhood')} </p>
      <div className={'grid grid-cols-3 gap-8 2xl:gap-x-16 mb-8'}>
        {neighborhoodOptions.map(({ name, label, options }) => {
          return (
            <InputLayout key={name} label={t(label.replaceAll(' ', '_'))}>
              <Controller
                name={name}
                control={control}
                render={({ field }) => (
                  <Select
                    multiple
                    className={'h-[56px]'}
                    labelId="level-label"
                    {...field}
                  >
                    {options.map((item, index: number) => (
                      <MenuItem
                        key={index}
                        value={item?.id}
                        className="capitalize"
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </InputLayout>
          );
        })}
        <InputLayout label={t('Outside_Conveniences')}>
          <Controller
            name={'outside environment'}
            control={control}
            render={({ field }) => (
              <Select
                multiple
                className={'h-[56px]'}
                labelId="level-label"
                {...field}
              >
                {outsideOptions.map((item, index: number) => (
                  <MenuItem key={index} value={item?.id} className="capitalize">
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </InputLayout>
      </div>
    </div>
  );
};

export default Conveniances;
