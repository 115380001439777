import { ReactElement, useState } from 'react';
import TableIcon from '../../features/icons/tableIcon';
import GridIcon from '../../features/icons/gridIcon';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';

interface Props {
  leftElement?: ReactElement;
  tableElement: ReactElement;
  gridElement: ReactElement;
  isGridView?: boolean;
}

const GridTableView = (props: Props) => {
  const isMobile = useMediaQuery('(min-width:1024px)');
  const { tableElement, gridElement, leftElement, isGridView } = props;

  const [gridView, toggleGridView] = useState(
    isGridView != undefined && isMobile ? false : true
  );

  const { t } = useTranslation('common');
  return (
    <div className={'flex flex-col h-auto flex-grow pb-8'}>
      <div
        className={classNames('w-full inline-flex lg:pb-6', {
          'justify-between items-start': leftElement,
          'justify-end items-start': !leftElement,
        })}
      >
        {leftElement}
        <div className={'flex justify-end items-start gap-2'}>
          <button
            onClick={() => toggleGridView(false)}
            className={classNames(
              'hover:border-blue-800 hover:bg-[#F5F5F7] hidden duration-300 transition-colors px-4 py-3 bg-white rounded border justify-center items-center gap-2 lg:flex',
              {
                'border-blue-800 text-blue-800 pointer-events-none': !gridView,
                'border-gray-200 text-neutral-500': gridView,
              }
            )}
          >
            <div className="w-4 h-4 justify-center items-center flex">
              <TableIcon width={'16'} height={'16'} color={'currentColor'} />
            </div>
            <div className="text-sm font-medium leading-none">{t('Table')}</div>
          </button>
          <button
            onClick={() => toggleGridView(true)}
            className={classNames(
              'hover:border-blue-800 hover:bg-[#F5F5F7] hidden duration-300 transition-colors px-4 py-3 bg-white rounded border justify-center items-center gap-2 lg:flex',
              {
                'border-blue-800 text-blue-800 pointer-events-none': gridView,
                'border-gray-200 text-neutral-500': !gridView,
              }
            )}
          >
            <GridIcon width={'14'} height={'14'} color={'currentColor'} />
            <div className="text-sm font-medium leading-none">{t('Grid')}</div>
          </button>
        </div>
      </div>
      {gridView ? gridElement : tableElement}
    </div>
  );
};

export default GridTableView;
