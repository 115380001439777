import classNames from 'classnames';
import { ReactNode } from 'react';

const GenericDocumentPreview = ({
  icon,
  text,
  isActive = false,
  classes,
}: {
  icon?: ReactNode;
  text?: ReactNode;
  isActive?: boolean;
  classes?: string;
}) => (
  <div
    className={classNames(
      'bg-gray-100 border rounded flex flex-col items-center gap-2.5 text-[1rem] leading-none h-[180px] p-4',
      {
        'border-blue border-2': isActive,
        'border-gray-200': !isActive,
      },
      classes
    )}
  >
    <div className="flex-grow"></div>

    {icon}

    <div className="flex-grow text-[.875rem] leading-[1rem] flex flex-col justify-end text-center font-medium break-all">
      {text}
    </div>
  </div>
);

export default GenericDocumentPreview;
