import { FC } from 'react';
import { FormProvider } from 'react-hook-form';

import { IProperty, PropertyStepperStep } from '../../../../../types/property';
import { stepperFormId } from '../../../../../const';
import { useProtectedForm } from '../../../../../hooks/useProtectedForm';

import CustomAttributesDisplay from '../../customAttributesDisplay';
import MainDetails from './mainDetails';
import BuildingBlocks from './buildingBlocks';
import RoomsBlocks from './roomsBlocks';
import Rooms from './rooms';
import Surfaces from './surfaces';
import Mandate from './mandate';
import AdditionalPreferences from './additionalPreferences';
import SurfacesCalculator from './surfacesCalculator';

const PropertyDetailsStep: FC<PropertyStepperStep> = ({
  onSubmit,
  defaultValues,
  serverData,
}) => {
  const methods = useProtectedForm<IProperty>(
    {
      defaultValues,
    },
    serverData,
    {
      ignoreFields: ['dimensions'],
    }
  );
  const { register, watch, handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <form
        id={stepperFormId}
        onSubmit={handleSubmit(onSubmit)}
        className="grid gap-16"
      >
        <MainDetails />
        <BuildingBlocks property={serverData} />
        {watch('buildingBlocks').length > 0 && (
          <RoomsBlocks property={serverData} />
        )}
        <Rooms />
        <SurfacesCalculator property={serverData} />
        <Surfaces defaultValues={defaultValues} />
        <Mandate />
        <AdditionalPreferences defaultValues={defaultValues} />
        {defaultValues?.dataFields?.property_details && (
          <CustomAttributesDisplay
            groupedDataFields={defaultValues?.dataFields?.property_details}
            fieldValues={defaultValues.fieldValues}
            register={register}
            watch={watch}
          />
        )}
      </form>
    </FormProvider>
  );
};

export default PropertyDetailsStep;
