import { useTranslation } from 'react-i18next';
import { PropertyWidgetPreviewProps } from '../../../../../types/brochureEditor';
import TableWidget from '../../tableWidget';

const PropertySurfacesWidget = ({
  property,
  widgetOptions,
}: PropertyWidgetPreviewProps) => {
  const { t } = useTranslation('propertiesPage');

  return (
    <TableWidget
      title={t('brochureEditor:surfaces')}
      items={[
        {
          id: '10',
          label: t('Living_Area'),
          value: addSuffixIfNotEmpty(property.livingArea, 'm²'),
        },
        {
          id: '11',
          label: t('useful_area'),
          value: addSuffixIfNotEmpty(property.usefulArea, 'm²'),
        },
        {
          id: '12',
          label: t('Land_surface'),
          value: addSuffixIfNotEmpty(property.landSurface, 'm²'),
        },
        {
          id: '13',
          label: t('terrace_surface'),
          value: addSuffixIfNotEmpty(property.terraceSurface, 'm²'),
        },
        {
          id: '14',
          label: t('garden_surface'),
          value: addSuffixIfNotEmpty(property.gardenSurface, 'm²'),
        },
        {
          id: '15',
          label: t('Volume'),
          value: addSuffixIfNotEmpty(property.gardenSurface, 'm³'),
        },
      ]}
      widgetOptions={widgetOptions}
    />
  );
};

export default PropertySurfacesWidget;

export function addSuffixIfNotEmpty(value: number, suffix = '') {
  const withSuffix = suffix ? `${value} ${suffix}` : `${value}`;
  return value ? withSuffix : '';
}
