import { useTranslation } from 'react-i18next';
import TitleHeader from '../../../components/common/titleHeader';
import PortalCreator from './creator';

const CreatePortal = () => {
  const { t } = useTranslation('propertiesPage');
  return (
    <TitleHeader title={t('Create_new_portal')} goBackLink="/settings/portals">
      <PortalCreator />
    </TitleHeader>
  );
};

export default CreatePortal;
