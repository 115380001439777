import { FC, MouseEventHandler, ReactNode, useState } from 'react';
import { Box, Button } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import PopupStepperNav from './nav';

export interface Step {
  id: number;
  Icon?: FC;
  title?: string;
  body?: ReactNode;
  nextButton?: ({ onClick }: { onClick: MouseEventHandler }) => ReactNode;
}

function PopupStepper({
  steps,
  onBack,
  onNext,
  onClose,
  disableNext,
  wrapperClass,
}: {
  steps: Step[];
  onBack?: (to: Step | undefined) => void;
  onNext?: (to: Step | undefined) => void;
  disableNext?: (currentStep: Step | undefined) => boolean;
  onClose?: () => void;
  wrapperClass?: string;
}) {
  const firstStepIndex = 0;
  const [step, setStep] = useState<number>(firstStepIndex);
  const isFirstStep = step === firstStepIndex;
  const activeStep = steps[step];

  const back = () => {
    setStep(Math.max(step - 1, firstStepIndex));
    onBack?.(steps[step]);
  };

  const next = () => {
    setStep(step + 1);
    onNext?.(steps[step + 1]);
  };

  const { t } = useTranslation('common');

  return (
    <Box
      className={classNames(
        'w-full h-full flex flex-col justify-between relative',
        wrapperClass
      )}
    >
      <button
        className={
          'absolute right-4 top-4 p-2 opacity-80 transition-opacity duration-300 hover:opacity-50'
        }
        onClick={onClose}
      >
        <CloseIcon
          sx={{
            width: '22px',
            height: '22px',
          }}
          fontSize={'small'}
        />
      </button>

      <PopupStepperNav step={step} items={steps} />

      <div className="p-16 pb-10 overflow-y-auto max-h-[60vh]">
        {activeStep?.body}
      </div>

      <div className="flex justify-between items-center p-8 bg-gray-50 border-t border-gray-200">
        <Button
          className="cursor-pointer flex justify-between items-center"
          variant="outlined"
          sx={{
            height: '40px',
            color: '#1D1D1F',
          }}
          onClick={() => {
            if (isFirstStep) {
              onClose?.();
            } else {
              back();
            }
          }}
        >
          {t(isFirstStep ? 'cancel' : 'back')}
        </Button>

        {activeStep?.nextButton?.({
          onClick: next,
        }) || (
          <Button
            className="cursor-pointer gap-6"
            variant={'contained'}
            disableElevation
            disabled={disableNext?.(activeStep)}
            sx={{
              height: '40px',
            }}
            onClick={() => next()}
          >
            <span>{t('next')}</span>
            <ArrowForwardIosIcon
              sx={{
                width: '16px',
                height: '16px',
              }}
              fontSize={'small'}
            />
          </Button>
        )}
      </div>
    </Box>
  );
}

export default PopupStepper;
