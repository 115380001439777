import useSWR from 'swr';
import { useEffect, useState } from 'react';
import { ApiListing } from '../../../../types/api';
import { IPersonalNotification } from '../../../../types/notification';
import NotificationsList from '../../notificationsList';
const DeletedNotifications = () => {
  const [page, setPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState(false);
  const [deletedData, setDeletedData] = useState<IPersonalNotification[]>([]);
  const { data: deletedNotificationsList } = useSWR<
    ApiListing<IPersonalNotification>
  >(`/personal_notifications?isDeleted=true&page=${page}&itemsPerPage=${10}`);

  useEffect(() => {
    if (deletedNotificationsList) {
      setDeletedData(deletedNotificationsList['hydra:member']);
    }
  }, [deletedNotificationsList]);

  if (
    !deletedNotificationsList ||
    !deletedNotificationsList['hydra:member'].length
  )
    return null;
  return (
    <div>
      {' '}
      <NotificationsList
        as="deletedList"
        page={page}
        setIsLoading={setIsLoading}
        handlePage={setPage}
        data={deletedData}
        itemsPerPage={10}
        autoHeight={true}
        loadingItemsToDisplay={isLoading}
        notificationsList={deletedNotificationsList}
      />
    </div>
  );
};

export default DeletedNotifications;
