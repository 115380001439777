// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.h1,
.user-editable-html h1 {
  font-size: 2rem;
}@media (min-width: 1280px) {.h1,
.user-editable-html h1 {
    font-size: 64px;
  }
}.h1,
.user-editable-html h1 {
  font-weight: 500;
  line-height: 1;
}

.h2,
.user-editable-html h2 {
  font-size: 32px;
  font-weight: 500;
  line-height: 2.5rem;
}

.h3,
.user-editable-html h3 {
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.03;
}

.user-editable-html a {
  text-decoration-line: underline;
  text-underline-offset: 2px;
  text-decoration-thickness: 1px;
}

.user-editable-html ul li {
  list-style: disc inside;
}

.user-editable-html ol li {
  list-style: decimal inside;
}
`, "",{"version":3,"sources":["webpack://./src/components/landing/landing-typography.css"],"names":[],"mappings":"AAEE;;EAAA;AAAkB,CAClB,4BAAA;;IAAA;EAAqB;AAAA,CACrB;;EAAA,gBAAkB;EAClB;AADkB;;AAMlB;;EAAA,eAAkB;EAClB,gBAAkB;EAClB;AAFkB;;AAOlB;;EAAA,eAAkB;EAClB,iBAAqB;EACrB;AAFkB;;AAMlB;EAAA,+BAAgB;EAChB,0BAAyB;EACzB;AAFgB;;AAKlB;EACE,uBAAuB;AACzB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".h1,\n.user-editable-html h1 {\n  @apply text-[2rem];\n  @apply xl:text-[64px];\n  @apply font-medium;\n  @apply leading-none;\n}\n\n.h2,\n.user-editable-html h2 {\n  @apply text-[32px];\n  @apply font-medium;\n  @apply leading-10;\n}\n\n.h3,\n.user-editable-html h3 {\n  @apply text-[24px];\n  @apply leading-[28px];\n  @apply -tracking-[0.03];\n}\n\n.user-editable-html a {\n  @apply underline;\n  @apply underline-offset-2;\n  @apply decoration-1;\n}\n\n.user-editable-html ul li {\n  list-style: disc inside;\n}\n\n.user-editable-html ol li {\n  list-style: decimal inside;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
