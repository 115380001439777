import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { IContact } from '../../../types/contacts';
import useLocale from '../../../hooks/useLocale';
import { options } from '../../material-ui/tablesConfig/contactsListingConfig';
import { getContactName } from '../../../helpers/utils/contacts';
import Button from '@mui/material/Button';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import React from 'react';
import { Link } from 'react-router-dom';
import RelatedAgent from '../../tableGridX/leads/relatedAgent';
import ContactTypes from '../../tableGridX/leads/contactTypes';
import CreatedUpdated from '../../tableGridX/createdUpdated';
import ContactSource from '../../../pages/contacts/contact-source-cell';
import { ContactAgencyCard } from './contactAgencyCard';
import { Tooltip } from '@mui/material';
type Props = { item: IContact };

export default function ContactCard({ item }: Props) {
  const { t } = useTranslation('common');
  const { locale } = useLocale();

  const newLeadsCount = item.leads
    ? item.leads.filter(
        (el: { status: { keyname: string } }) =>
          el.status.keyname !== 'processed'
      )
    : [];
  const name = getContactName(item);

  const renderButtons = () => {
    return options.map(
      (elem, i) =>
        !elem.showInMenu && (
          <Tooltip key={i} title={t('Explore_more')} arrow placement="top">
            <Link
              className=" block w-full text-[#1D1D1F]"
              to={`/contacts/${item.id}/${elem.route}`}
            >
              <RemoveRedEyeOutlinedIcon sx={{ color: '#1D1D1F' }} />
            </Link>
          </Tooltip>
        )
    );
  };

  const companyAgency = item.agencies.find(
    (agency) => agency.categoryAgence === 'Company'
  );

  const logoAgency =
    companyAgency && companyAgency.logoAgence?.x96
      ? companyAgency.logoAgence.x96
      : '/img/default_image.jpg';

  return (
    <div
      className={
        '@container p-2 pt-4 bg-white rounded flex flex-col justify-between'
      }
    >
      <div className={'flex flex-col grow h-full justify-between'}>
        <div className={'grid gap-3 mb-4'}>
          <div className="flex items-center justify-between flex-wrap gap-2">
            <div className="flex  gap-2 w-[80%]">
              <div className="px-2 py-1 bg-neutral-100 rounded">
                <div
                  className={`${
                    item.status?.keyname === 'rejected'
                      ? ' text-[#F50057]'
                      : item.status?.keyname === 'waiting for approval'
                      ? ' text-[#ED6C02]'
                      : ' text-[#4CAF50]'
                  } text-xs font-normal leading-4 capitalize font-[Inter] w-full `}
                >
                  {item.status ? (
                    <>{item.status.translations[locale]?.value}</>
                  ) : item.archive ? (
                    'Archived'
                  ) : (
                    'Active'
                  )}
                </div>
              </div>
              {newLeadsCount.length ? (
                <div
                  className={classNames(
                    'flex py-0.5 px-1 rounded-md items-center border border-[#ED6C02] text-[#ED6C02]'
                  )}
                >
                  <span className="text-xs font-normal font-[Inter] leading-0 w-max">
                    {' '}
                    {t('New_lead')}
                  </span>
                </div>
              ) : null}
            </div>

            <div className="w-[10%]">
              <div className=" my-2 text-center">
                <Button
                  sx={{
                    '&.MuiButtonBase-root:hover': {
                      bgcolor: 'transparent',
                    },
                  }}
                  className=" !pl-0 !ml-[-14px] w-full"
                >
                  {renderButtons()}
                </Button>
              </div>
            </div>
          </div>

          <Link
            className={
              'max-w-full text-[12px] 2xl:text-[16px] overflow-hidden overflow-ellipsis capitalize'
            }
            to={`/contacts/${item.id}/contact-information`}
          >
            <p
              title={name.toLowerCase()}
              className={'text-2xl font-normal font-[Inter]'}
            >
              {name.toLowerCase()}
            </p>
          </Link>
          <div
            className={classNames(
              "text-sm font-normal font-['Inter'] leading-none",
              {
                'text-[#3446B9]': !item.unsubscribedMarketing,
                'text-[#6E6E72]': item.unsubscribedMarketing,
              }
            )}
          >
            {!item.unsubscribedMarketing ? 'Subscribed' : 'Unsubscribed'}
          </div>
        </div>
      </div>
      <div className={'flex flex-col'}>
        <div
          className={'divide-y flex flex-wrap gap-1 border-b border-t w-full'}
        >
          <div
            className={classNames(
              'grow shrink basis-0 px-2 bg-neutral-100 rounded-[100px] justify-start items-center gap-1 flex my-3 w-[47%]'
            )}
          >
            <div className=" @xs:block shrink-0 w-[43px] h-[43px] rounded-full overflow-hidden">
              <img
                className="w-[42px] h-[40px] object-contain rounded-full"
                src={logoAgency}
                alt="Agency Logo"
              />
            </div>

            <div className="w-full flex-col gap-1 justify-center items-start inline-flex whitespace-nowrap overflow-hidden overflow-ellipsis">
              <div className="text-zinc-900 text-sm font-medium leading-none overflow-ellipsis truncate w-full ">
                <div>
                  <span className="font-[Inter] font-medium text-sm leading-4	text-[#1D1D1F] text-left">
                    {<ContactAgencyCard agencies={item.agencies} />}
                  </span>
                </div>

                <span className="font-normal text-[#6E6E72] font-[Inter] text-sm leading-4 ">
                  {t('Agency')}
                </span>
              </div>

              <div className="text-neutral-500 text-sm font-normal leading-none capitalize"></div>
            </div>
          </div>

          <div
            className={classNames(
              'grow shrink basis-0 px-2 bg-neutral-100 rounded-[100px] justify-start items-center gap-2 flex my-3 w-[47%]'
            )}
          >
            <div className="w-full flex-col gap-1 justify-center items-start inline-flex whitespace-nowrap overflow-hidden overflow-ellipsis">
              <div className="text-zinc-900 text-sm font-medium leading-none grid gap-1 w-full">
                <div className="font-[Inter] font-medium text-sm leading-4 text-[#1D1D1F] text-left mt-1 min-w-[40%]">
                  {<RelatedAgent styleCard contact={item} />}
                </div>

                <span className="font-normal ml-[13%] sm:ml-[9%] md:ml-[19%] lg:ml-[20%] text-[#6E6E72] font-[Inter] text-xs leading-4 min-w-[40%]">
                  {t('Related_agent')}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-2 my-3">
          <span className="font-medium leading-4 text-xs font-[Inter] text-[#6E6E72]">
            {t('Contact_types')}:
          </span>
          <span>{<ContactTypes contact={item} />}</span>
        </div>
        <div className={' border-y'}>
          <div className="my-3">
            <span>
              {
                <CreatedUpdated
                  dateCard
                  created={item.createdAt}
                  updated={item.updatedAt}
                />
              }
            </span>
          </div>
        </div>
        <div>
          <div className="my-3">
            <span className="font-[Inter] font-normal leading-4 text-sm text-[#3446B9]">
              {<ContactSource row={item as any} />}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
