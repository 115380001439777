import { Box } from '@mui/material';

import CheckedIcon from '../../features/icons/checkedIcon';

function LandingFeaturesList({ features }: { features: string[] }) {
  return (
    <div className="grid grid-cols-2 gap-4">
      {features?.map((feature, index) => (
        <Box
          sx={{
            color: 'text.primary',
          }}
          key={index}
          className="col-span-1 flex"
        >
          <Box className="w-5 h-5 2xl:w-7 rounded border aspect-square p-1 flex justify-center items-center mr-2 max-w-8">
            <CheckedIcon color="green" />
          </Box>
          <Box className={'text-[14px] 2xl:text-[1rem]'} component="span">
            {feature}
          </Box>
        </Box>
      ))}
    </div>
  );
}

export default LandingFeaturesList;
