import { useEffect } from 'react';
import PropertiesListingPage from './propertiesListing/propertiesListingPage';
import { useFiltersProperties } from '../../hooks/useFiltersProperties';

export default function PropertiesPage(props: {
  networks?: boolean;
  isArchived?: boolean;
}) {
  const {
    setCategoryOptions,
    setAvailabilityOptions,
    setCategoriesOptionsApi,
    setStatusOptions,
    setAgentOptionsApi,
    setDepartmentService,
    setAgencyHierarchy,
    setActionOptions,
    actionOptions,
    statusOptions,
    availabilityOptions,
    categoriesOptionsApi,
    agentsPerDept,
    departmentService,
    agencyHierarchy,
  } = useFiltersProperties();

  useEffect(() => {
    statusOptions.length === 0 && setStatusOptions();
  }, [setStatusOptions]);

  useEffect(() => {
    departmentService.length === 0 && setDepartmentService();
  }, [departmentService]);

  useEffect(() => {
    categoriesOptionsApi.length === 0 &&
      setCategoriesOptionsApi().then((res: any) => {
        setCategoryOptions(res);
      });
  }, [setCategoriesOptionsApi]);

  useEffect(() => {
    availabilityOptions.length === 0 && setAvailabilityOptions();
  }, [setAvailabilityOptions]);

  useEffect(() => {
    agentsPerDept.length === 0 && setAgentOptionsApi();
  }, [setAgentOptionsApi]);

  useEffect(() => {
    !agencyHierarchy && setAgencyHierarchy();
  }, [setAgencyHierarchy]);

  useEffect(() => {
    actionOptions.length === 0 && setActionOptions();
  }, [setActionOptions]);

  return (
    <PropertiesListingPage
      networks={props.networks}
      isArchived={props.isArchived}
      key={Number(props.isArchived)}
    />
  );
}
