import {
  PreviewSubjectTypes,
  TemplateOptions,
} from '../../../../types/brochureEditor';
import { useEffect } from 'react';
import { useProtectedForm } from '../../../../hooks/useProtectedForm';

export const useOptionsForm = ({ options }: { options?: TemplateOptions }) => {
  const defaultValues = options || {
    name: '',
    usedFor: String(PreviewSubjectTypes.PROPERTY),
    brandColor: '',
    departments: [],
  };

  const methods = useProtectedForm<TemplateOptions>(
    {
      defaultValues,
    },
    options
  );
  const { setValue } = methods;

  // Set default values of form once we get data from server.
  useEffect(() => {
    if (!options) return;
    for (const key in options) {
      // @ts-ignore
      setValue(key, options[key]);
    }
  }, [options, setValue]);

  return methods;
};
