import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

export default function TranslationIcon({ color, className }: IconProps) {
  const { mode } = useThemeMode();

  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill={color}
        d="M9.166 8.554V.804a.814.814 0 00-.23-.568A.78.78 0 008.378 0H.787a.78.78 0 00-.556.236A.813.813 0 000 .804v7.75c0 .213.083.418.231.569a.78.78 0 00.556.235H8.38a.78.78 0 00.556-.235.814.814 0 00.231-.569zm-8.626 0V.804A.25.25 0 01.787.55h7.59c.138 0 .249.113.25.253v7.75c-.001.14-.112.253-.25.254H.789a.25.25 0 01-.248-.254z"
      ></path>
      <path
        fill={color}
        d="M7.103 2.85h-2.25v-.857a.272.272 0 00-.27-.275.272.272 0 00-.27.275v.857h-2.25a.273.273 0 00-.27.275c.001.152.122.276.27.276h3.534c-.014.526-.148 1.743-1.014 2.652a3.475 3.475 0 01-.71-1.148.273.273 0 00-.144-.153.264.264 0 00-.207-.004.272.272 0 00-.149.147.28.28 0 00-.003.212c.185.484.459.928.808 1.306a3.82 3.82 0 01-1.702.68.268.268 0 00-.182.107.28.28 0 00.06.39c.06.043.134.06.206.046a4.25 4.25 0 002.022-.848c.592.455 1.29.748 2.025.848a.265.265 0 00.205-.045.277.277 0 00.06-.39.268.268 0 00-.181-.108 3.823 3.823 0 01-1.705-.681C5.95 5.37 6.111 3.995 6.132 3.4h.971c.149 0 .27-.124.27-.276a.273.273 0 00-.27-.275zM15.212 6.641H9.796a.272.272 0 00-.27.276c0 .152.12.275.27.275h5.416a.25.25 0 01.248.253v7.751a.25.25 0 01-.248.253H7.621a.25.25 0 01-.248-.253v-5.194a.273.273 0 00-.27-.275.273.273 0 00-.27.275v5.194c0 .213.084.418.231.568a.78.78 0 00.557.236h7.592a.78.78 0 00.556-.236.813.813 0 00.23-.568V7.445a.813.813 0 00-.23-.568.78.78 0 00-.556-.236z"
      ></path>
      <path
        fill={color}
        d="M8.83 14.254a.267.267 0 00.358-.132l.836-1.816h2.785l.836 1.816a.265.265 0 00.36.137.274.274 0 00.14-.158.28.28 0 00-.012-.213L11.66 8.517a.272.272 0 00-.244-.155.272.272 0 00-.244.155l-2.471 5.37a.278.278 0 00.129.367zm2.585-4.973l1.14 2.475h-2.278l1.139-2.475z"
      ></path>
    </svg>
  );
}
