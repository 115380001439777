// import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { HistoryT } from '..';
import { ApiListing } from '../../../../../types/api';
import useTranslationForTableGridXConfig from '../../../../../hooks/useTranslationForTableGridX';
import {
  propertyHistoryListingConfig,
  skeletonPropertiesHistoryListingConfig,
} from '../../../../../components/material-ui/tablesConfig/propertyHistoryListingConfig';
import TableGridX from '../../../../../components/tableGridX';
import { HistoryTableGridXStyling } from './style';
import ExportIcon from '../../../../../features/icons/exportIcon';
import SkeletonTableGrid from '../../../../../components/skeletonTableGrid';

type Props = {
  itemsPerPage: number;
  data: ApiListing<HistoryT>;
  handlePage: Dispatch<SetStateAction<number>>;
  page: number;
  loadingItemsToDisplay: boolean;
};
const PropertiesHistoryListingTable = ({
  itemsPerPage,
  data,
  handlePage,
  page,
  loadingItemsToDisplay,
}: Props) => {
  const propertiesHistoryListingColumns = useTranslationForTableGridXConfig([
    propertyHistoryListingConfig,
    'usersPage',
  ]);

  const skeletonPropertiesHistoryListingColumns =
    useTranslationForTableGridXConfig([
      skeletonPropertiesHistoryListingConfig,
      'usersPage',
    ]);

  const rows = useMemo(() => {
    if (data) {
      return data['hydra:member'];
    }
    return null;
  }, [data]);

  return (
    <Box className="w-full min-h-[80%]">
      {loadingItemsToDisplay ? (
        <SkeletonTableGrid
          customTableStyle={HistoryTableGridXStyling}
          style={{
            width: '100%',
            maxHeight: '340px',
            minHeight: '340px',
            height: '100%',
          }}
          disableSelectionOnClick={true}
          disableColumnSelector={true}
          rows={Array.from({ length: itemsPerPage }).map((el, index) => {
            return {
              id: index,
              date: '',
              action: '',
              doneby: '',
            };
          })}
          columns={skeletonPropertiesHistoryListingColumns}
          rowCount={0}
        />
      ) : (
        <TableGridX
          customTableStyle={HistoryTableGridXStyling}
          style={{ width: '100%', minHeight: '340px', height: '100%' }}
          disableSelectionOnClick={true}
          disableColumnSelector={true}
          rows={rows || []}
          columns={propertiesHistoryListingColumns}
          autoPageSize
          pageSize={itemsPerPage}
          page={page - 1}
          paginationMode="server"
          rowCount={(data && data['hydra:totalItems']) || 0}
          onPageChange={(page: number) => {
            handlePage(page + 1);
          }}
        />
      )}
      <Button
        variant="contained"
        disableElevation
        sx={{
          background: 'background.default',
          position: 'absolute',
          bottom: '64px',
          right: '84px',
        }}
        startIcon={<ExportIcon color="#FFF" />}
      >
        {'Export'}
      </Button>
    </Box>
  );
};
export default PropertiesHistoryListingTable;
