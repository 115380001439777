import { IContactComment } from '../../../../types/contacts';
import React, { useState } from 'react';
import { AccordionView } from '../../creator/individual-view';
import TableItem from '../table/item';
import classNames from 'classnames';
import AddIcon from '@mui/icons-material/Add';
import NewCommentForm from '../forms/new-comment-form';
import { formatDate } from '../../../../helpers/utils';
import { useTranslation } from 'react-i18next';

interface Props {
  items: IContactComment[];
  id: string;
  mutate: any;
  api?: string;
  property?: boolean;
}

const Comments = (props: Props) => {
  const { items, id, mutate, api, property } = props;
  const [createMode, toggleCreateMode] = useState(!items.length);
  const [expanded, setExpanded] = useState(!!items.length);
  const { t } = useTranslation('propertiesPage');
  const handleChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  const onAfterSubmit = () => {
    toggleCreateMode(false);
  };

  const onCancelClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    toggleCreateMode(false);
  };

  return (
    <>
      {items && items.length ? (
        <AccordionView
          expanded={expanded}
          onChange={handleChange}
          defaultExpanded={true}
          className={'w-full col-start-1 col-end-4 pb-8'}
          titleClassName={'text-[18px] 2xl:text-[24px]'}
          title={'Comments'}
        >
          <div className={'mt-8 divide-y border'}>
            {items.map((item) => (
              <TableItem
                key={item.id}
                title={`${formatDate(item.commentDate)} by ${
                  item.commentBy.firstname
                } ${item.commentBy.lastname}`}
                value={item.commentBody}
                titleTag={
                  item.isPrivate ? (
                    <div
                      className={
                        'text-[14px] mt-2 inline-flex py-0.5 px-2 rounded-md items-center border border-[#3446B9] text-[#3446B9]'
                      }
                    >
                      {t('Only_you_can_see_this')}
                    </div>
                  ) : null
                }
              />
            ))}
          </div>
        </AccordionView>
      ) : null}
      {createMode ? (
        <div
          className={classNames('pt-8', {
            'pt-8': items && items.length,
            'border-t': expanded,
          })}
        >
          <p className={'text-[24px] mb-8'}>{t('New_comment')}</p>
          <NewCommentForm
            api={api}
            property={property}
            onCancelClick={onCancelClick}
            onAfterSubmit={onAfterSubmit}
            contactId={id}
            mutate={mutate}
          />
        </div>
      ) : (
        <div
          className={classNames('pb-16 mb-8 border-b', {
            'pt-0': expanded,
            'pt-8': !expanded,
          })}
        >
          <button
            onClick={() => toggleCreateMode(true)}
            className={
              'hover:opacity-80 transition-opacity duration-300 rounded flex items-center justify-center gap-2 bg-gray-100 w-full p-3 text-[14px] text-gray-900'
            }
          >
            <AddIcon />
            <span>{t('Add_comment')}</span>
          </button>
        </div>
      )}
    </>
  );
};

export default Comments;
