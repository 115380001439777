import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

function EditCardIcon({ color, className }: IconProps) {
  const { mode } = useThemeMode();

  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        clipPath="url(#clip0_7920_26253)"
      >
        <path d="M6.76 2.5H1V15h12V8.75"></path>
        <path d="M11.5 1.928L4.75 9 4 12l3.133-.432 6.9-7.068M11.5 1.928l.621-.642a1.808 1.808 0 012.544-.053v0c.734.696.754 1.858.045 2.58l-.676.687M11.5 1.928L14.034 4.5"></path>
      </g>
      <defs>
        <clipPath id="clip0_7920_26253">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default EditCardIcon;
