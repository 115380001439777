import { FC } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';

import PageTitleAndPaper from '../../components/stepper/pageTitleAndPaper';
import SpinnerLoading from '../../features/spinner/spinnerLoading';
import PropertyStepper from './stepper';
import { IPropertyApi } from '../../types/property';
import { use404 } from '../../hooks/use404';
import PropertiesListingActionMenu from '../../components/tableGridX/propertiesListingActionMenu';
import { MatchingIndicator } from '../../components/cardsTablesList/cards/elements/matchingIndicator';
import useMercure from '../../hooks/useMercure';
import { IMercureMatchingTime } from '../../types/matching';
import PropertyTags from '../../components/common/propertyTags';

const EditPropertyV2: FC = () => {
  const { id } = useParams();
  const { data, mutate, error } = useSWR<IPropertyApi>(`/v1/properties/${id}`);
  const { data: matchingRemainingTime } = useMercure<IMercureMatchingTime>({
    subscribeUrl: 'matching-remaining-time',
    eventTypes: ['message'],
  });

  use404(error?.response?.status === 404);
  use404(!!data?.isNetworkProperty);
  use404(!!data?.archive);

  const { t } = useTranslation('alerts');

  if (error) {
    return (
      <div className="p-16 bg-white">
        <div className="text-2xl leading-none">{t('an_error_happened')}</div>
      </div>
    );
  }

  if (!data) {
    return <SpinnerLoading />;
  }

  const extraTitle = (
    <div className="flex justify-between">
      <PropertyTags property={data} />

      <div className="flex gap-4">
        <MatchingIndicator
          isStatic
          matchingRemainingTime={matchingRemainingTime}
          item={data}
        />

        <PropertiesListingActionMenu
          isEditProperty
          row={data}
          action="propertyDetails"
        />
      </div>
    </div>
  );

  return (
    <PageTitleAndPaper
      title={t('common:Edit_property')}
      extraTitle={extraTitle}
      goBackLink="/properties"
    >
      <PropertyStepper serverData={data} mutate={mutate} />
    </PageTitleAndPaper>
  );
};

export default EditPropertyV2;
