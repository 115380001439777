import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

export default function ContactIcon({ color, className }: IconProps) {
  const { mode } = useThemeMode();

  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 12c0-4.107-7-3.891-7 0h7z"
      ></path>
      <circle cx="8.5" cy="5.5" r="2" stroke={color}></circle>
      <rect
        width="13"
        height="13"
        x="1.5"
        y="1.5"
        stroke={color}
        rx="0.5"
      ></rect>
      <path stroke={color} d="M0 11.5h3M0 8h3M0 4.5h3"></path>
    </svg>
  );
}
