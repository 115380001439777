import { IContactTask } from '../../../../types/contacts';
import { AccordionView } from '../../creator/individual-view';
import CreateTaskForm from '../forms/create-task-form';
import classNames from 'classnames';
import { getTime } from './calendar/item';
import { formatDate } from '../../../../helpers/utils';

interface Props {
  item: IContactTask;
  completed?: boolean;
  id: string;
  mutate: any;
  onAfterSubmit: () => void;
  taskId: string | number;
}

const TaskItem = (props: Props) => {
  const { item, completed, id, mutate, onAfterSubmit, taskId } = props;

  return (
    <AccordionView
      key={item.id}
      className={'w-full col-start-1 col-end-4'}
      titleClassName={classNames('text-[18px] 2xl:text-[24px] w-[60%]', {
        'line-through': completed,
      })}
      title={item.title}
      subTitle={
        <span className={'text-[14px]'}>
          {item.startDate && item.endDate ? (
            <>
              <span className={'mx-1'}>from</span>
              {formatDate(item.startDate)} {getTime(item.startDate)}
              <span className={'mx-1'}>to</span>
              {formatDate(item.endDate)} {getTime(item.endDate)}
            </>
          ) : null}
        </span>
      }
    >
      <div>
        <CreateTaskForm
          data={item}
          contactId={id}
          onAfterSubmit={onAfterSubmit}
          mutate={mutate}
          taskId={taskId}
          editMode
          verticalMode
        />
      </div>
    </AccordionView>
  );
};

export default TaskItem;
