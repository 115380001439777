import React from 'react';
import WidgetWrapper from './wrapper';
import { WidgetProps } from '../index';
import {
  calculatePercent,
  COLORS,
} from '../../properties/propertiesListing/ownersReport/report/pieChart';
import { PieChart as MinimalPieChart } from 'react-minimal-pie-chart';
import { useTranslation } from 'react-i18next';

const AgencyLeadSourceWidget: React.FC<WidgetProps> = (props) => {
  const { data } = props;
  const items =
    (data['agency-lead-source'] && data['agency-lead-source'].data) || [];
  const { t } = useTranslation('common');

  const series = items
    .map((item, index) => ({
      value: item.count,
      color: COLORS[index % COLORS.length] as string,
      title: `${t(item.source)} (${calculatePercent(
        item.count,
        data['agency-lead-source'].totalItems
      )}%)`,
    }))
    .sort((a, b) => Math.sign(b.value - a.value));

  return (
    <WidgetWrapper
      emptyData={!items.length}
      title={'Agency_Lead_source'}
      linkText={'ViewMore'}
      link={null}
    >
      <div className={'flex flex-col justify-center w-full'}>
        <div className="w-[262px] aspect-square mx-auto mb-5">
          <MinimalPieChart data={series} lineWidth={60} startAngle={-90} />
        </div>
        <div className="justify-start items-center gap-2 inline-flex flex-wrap">
          {series.map((el) => (
            <div
              key={el.value}
              className="px-2 py-1 bg-white rounded-[32px] justify-start items-center gap-2 flex"
            >
              <div
                className={`w-[8px] h-[8px] rounded-full`}
                style={{ backgroundColor: el.color }}
              />
              <div className="text-zinc-900 text-sm font-medium font-['Inter'] md:leading-none">
                {el.title}
              </div>
            </div>
          ))}
        </div>
      </div>
    </WidgetWrapper>
  );
};

export default AgencyLeadSourceWidget;
