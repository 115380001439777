import { useState } from 'react';
import FieldValueToDisplay from '../../components/common/fieldValueToDisplay';
import LeftArrowIcon from '../../features/icons/leftArrowIcon';
import RedDotIcon from '../../features/icons/redDotIcon';
import moment from 'moment';

type Props = {
  title: string;
  index: number;
  notifId: number;
  activeBtn: number;
  date: string;
  description: string;
  read: boolean;
  showHiddenBlock?: () => void;
  refrechData?: () => void;
  setId?: (index: number) => void;
  readOne?: (index: number) => void;
};
export default function Notification({
  title,
  date,
  description,
  read,
  setId,
  index,
  notifId,
  activeBtn,
  showHiddenBlock,
  readOne,
}: Props) {
  const [testRead, setTestRead] = useState(false);
  const handleNotification = () => {
    setTestRead(true);
    !read && readOne && readOne(notifId);
    showHiddenBlock && showHiddenBlock();
    setId && setId(index);
  };

  return (
    <li
      className={`Inter border rounded-lg ${
        activeBtn == 2 && read && 'hidden'
      } border-[#FBFBFD] bg-[#FBFBFD]  text-[#1D1D1F] hover:bg-gray-100 py-3 w-[97%] mx-auto `}
    >
      <a
        href="#"
        className="flex gap-4 max-w-[90%] m-auto items-center justify-between cursor-pointer"
        onClick={() => handleNotification()}
      >
        <div className="flex relative">
          {!read && !testRead && (
            <RedDotIcon
              className=" absolute top-1 -left-2 w-[6px] h-[6px]"
              color="#F50057"
            />
          )}

          <div
            className={`grid gap-2 pl-4 ${testRead && 'opacity-50 '} ${
              read && 'opacity-50 '
            }`}
          >
            <h2 className="text-sm leading-4 font-medium d-flex">{title}</h2>
            <p className="font-normal text-xs leading-[15.6px]">
              {moment(date).fromNow()}
            </p>
            <p className="text-xs leading-[15.6px] font-medium">
              <FieldValueToDisplay fieldValue={description} defaultValue={''} />
            </p>
          </div>
        </div>
        <div>
          <LeftArrowIcon />
        </div>
      </a>
    </li>
  );
}
