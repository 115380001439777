import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

export default function CustomAttributesIcon({
  color,
  width = '32',
  height = '32',
}: IconProps) {
  const { mode } = useThemeMode();

  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 32 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            id="Rectangle 2"
            d="M2 3.5C2 2.94771 2.44772 2.5 3 2.5H29C29.5523 2.5 30 2.94772 30 3.5V29.5C30 30.0523 29.5523 30.5 29 30.5H3C2.44771 30.5 2 30.0523 2 29.5V3.5Z"
            stroke={color}
          />
          <rect
            id="Rectangle 9"
            x="6"
            y="6.5"
            width="20"
            height="8"
            stroke={color}
            strokeLinejoin="round"
          />
          <rect
            id="Rectangle 10"
            x="6"
            y="18.5"
            width="20"
            height="8"
            stroke={color}
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </>
  );
}
