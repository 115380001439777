import { IContact } from '../../../../types/contacts';
import CompanyContacts from '../../components/company-contacts';

interface Props {
  data: IContact;
  id: string;
  mutate: any;
}

const IndividualContactTab = (props: Props) => {
  const { data, id, mutate } = props;

  return (
    <CompanyContacts
      id={id}
      contacts={data.company?.individuals || []}
      companyInformation={data.company?.id}
      mutate={mutate}
    />
  );
};

export default IndividualContactTab;
