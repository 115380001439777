import { CSSProperties, useCallback, useRef } from 'react';
import classNames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType } from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { Box, Stack } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { IMedia } from '../../types/api';

function LandingHeroSlider({ images }: { images: IMedia[] }) {
  const sliderRef = useRef<{ swiper: SwiperType }>(null);

  const handlePrev = useCallback(() => {
    sliderRef.current?.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    sliderRef.current?.swiper.slideNext();
  }, []);

  return (
    <Stack className="w-full max-w-full select-none rounded flex flex-col relative">
      <Swiper
        ref={sliderRef}
        slidesPerView={1.2}
        centeredSlides={true}
        loop={images.length > 1}
        spaceBetween={8}
        className="with-bullets-scale"
        pagination={{
          type: 'bullets',
        }}
        breakpoints={{
          1024: {
            slidesPerView: 1.5,
            spaceBetween: 32,
          },
        }}
        modules={[Navigation, Pagination]}
        style={swiperStyle as CSSProperties}
      >
        {images.map((item, index) => (
          <SwiperSlide key={index}>
            <img className="aspect-video w-full" alt="" src={item.url.large} />
          </SwiperSlide>
        ))}
      </Swiper>

      <Box
        onClick={handlePrev}
        className={classNames(
          'left-4',
          {
            hidden: images.length <= 1,
          },
          navigationStyle
        )}
      >
        <ArrowForwardIosIcon fontSize={'small'} className="rotate-180" />
      </Box>

      <Box
        onClick={handleNext}
        className={classNames(
          'right-4',
          {
            hidden: images.length <= 1,
          },
          navigationStyle
        )}
      >
        <ArrowForwardIosIcon fontSize={'small'} />
      </Box>
    </Stack>
  );
}

export default LandingHeroSlider;

const navigationStyle =
  'hidden xl:inline-flex z-10 w-14 h-14 p-4 bg-white bg-opacity-80 rounded-full shadow justify-center items-center gap-2.5 cursor-pointer absolute bottom-1/2 translate-y-1/2';

const swiperStyle = {
  '--swiper-pagination-color': '#fff',
  '--swiper-pagination-bullet-inactive-color': '#999999',
  '--swiper-pagination-bullet-inactive-opacity': '0.6',
  '--swiper-pagination-bullet-size': '6px',
  '--swiper-pagination-bullet-active-size': '8px',
  '--swiper-pagination-bullet-horizontal-gap': '4px',
};
