import React from 'react';
import WidgetWrapper from './wrapper';
import { WidgetProps } from '../index';
import { IDashboardNetworkRequest } from '../../../types/user';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import useLocale from '../../../hooks/useLocale';

function getStatusColor(status: string): string {
  switch (status.toString()) {
    case 'pending':
      return 'border-[#ED6C02] text-[#ED6C02]'; // Pending - yellow border
    case 'accepted':
      return 'border-[#4CAF50] text-[#4CAF50]'; // Accepted - green border
    case 'declined':
      return 'border-[#F50057] text-[#F50057]'; // Declined - red border
    case 'terminated':
      return 'border-[#F50057] text-[#F50057]'; // Terminated - blue border
    case 'exited':
      return 'border-[#ED6C02] text-[#ED6C02]'; // Exited - orange border
    default:
      return 'border-gray-500 text-gray-500'; // Default color - gray border
  }
}

const Item = (props: { item: IDashboardNetworkRequest }) => {
  const { item } = props;
  const { locale } = useLocale();
  return (
    <div className="pb-2 border-b border-gray-200 grid grid-cols-3 gap-4 w-full">
      <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
        <div className="whitespace-nowrap overflow-hidden overflow-ellipsis grow shrink basis-0 text-zinc-900 text-sm font-medium pb-0.5 leading-none">
          {item.name}
        </div>
      </div>
      <div className="grow shrink basis-0 text-zinc-900 text-sm font-normal font-['Inter'] leading-none">
        {item.city}
      </div>
      <div
        className={classNames(
          'grow shrink basis-0 h-4 justify-start items-center gap-2 flex',
          getStatusColor(item.status.keyname)
        )}
      >
        <div className="w-2 h-2 bg-current rounded-full" />
        <div className="text-sm font-medium leading-none first-letter:uppercase">
          {item.status.translations?.[locale]?.name}
        </div>
      </div>
    </div>
  );
};

const NetworkRequestsWidget: React.FC<WidgetProps> = (props) => {
  const { data } = props;
  const { t } = useTranslation('common');

  return (
    <WidgetWrapper
      emptyData={!data['mls-requests'].length}
      title={'MLSRequests'}
      linkText={'ViewAll'}
      link={'/settings/networks'}
    >
      <div className="w-full flex-col justify-center items-start gap-8 inline-flex">
        <div className="self-stretch flex-col justify-center items-start gap-2 flex">
          <div className="self-stretch pb-2 border-b border-gray-200 justify-start items-start gap-4 inline-flex">
            <div className="grow shrink basis-0 text-zinc-900 text-sm font-medium leading-none">
              {t('Agency')}
            </div>
            <div className="grow shrink basis-0 text-zinc-900 text-sm font-medium leading-none">
              {t('City')}
            </div>
            <div className="grow shrink basis-0 h-4 justify-start items-start gap-2 flex">
              <div className="grow shrink basis-0 text-zinc-900 text-sm font-medium leading-none">
                {t('Status')}
              </div>
            </div>
          </div>
          {data['mls-requests']?.map((item) => (
            <Item key={item.name} item={item} />
          ))}
        </div>
      </div>
    </WidgetWrapper>
  );
};

export default NetworkRequestsWidget;
