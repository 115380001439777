import { ReactNode } from 'react';
import BackLink from './backLink';

const TitleHeader = (props: {
  title?: string;
  goBackLink: string;
  children: ReactNode;
  withTabs?: boolean;
  rightElement?: ReactNode;
  titleElement?: ReactNode;
  wrapperClassName?: string;
}) => {
  const {
    title,
    goBackLink,
    children,
    rightElement,
    titleElement,
    wrapperClassName = '',
  } = props;
  return (
    <div className={wrapperClassName}>
      <BackLink url={goBackLink} />
      <div className={'flex justify-between items-center mb-[40px] w-full'}>
        {titleElement || <h1 className={'text-[2rem]'}>{title}</h1>}
        {rightElement}
      </div>
      <>{children}</>
    </div>
  );
};

export default TitleHeader;
