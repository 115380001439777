import { useCallback, useMemo } from 'react';
import { NameableProperty } from '../types/property';
import useLocale from './useLocale';

const usePropertyName = () => {
  const { locale } = useLocale();

  const getPropertyName = useCallback(
    (property: NameableProperty): string => {
      const mapLocalesToTitles: Record<string, string> = {};

      const { translations } = property;
      if (translations) {
        for (const key of Object.keys(translations)) {
          const title = translations?.[key]?.title;
          if (title) {
            mapLocalesToTitles[key] = title;
          }
        }
      }

      const titleInAnyLanguage = Object.values(mapLocalesToTitles)[0];
      return (
        mapLocalesToTitles[locale] ||
        titleInAnyLanguage ||
        property.reference ||
        `#${property.id}`
      );
    },
    [locale]
  );

  return useMemo(() => ({ getPropertyName }), [getPropertyName]);
};

export default usePropertyName;
