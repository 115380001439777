import { CardMedia, Stack, Typography } from '@mui/material';
import { IPromotionApi } from '../../../../types/promotion';

interface Props {
  data: IPromotionApi;
}

function PromotionPresentation({ data }: Props) {
  return data.videoLinks && data.videoLinks.length ? (
    <Stack
      sx={{
        padding: '32px',
        display: 'flex',
        gap: '16px',
        backgroundColor: 'background.default',
        color: 'text.primary',
      }}
      className="border rounded"
    >
      {data.videoLinks[0] ? (
        <CardMedia
          className="w-[512px] h-[288px]"
          component="iframe"
          image={data.videoLinks[0].url}
        />
      ) : null}
      <Typography variant="h5">Video Presentation</Typography>
    </Stack>
  ) : null;
}

export default PromotionPresentation;
