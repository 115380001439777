import { Box, Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import * as React from 'react';
import { ReactNode, useState } from 'react';
import FormWrapper from '../components/form-wrapper';
import { useNavigate } from 'react-router-dom';
import { http } from '../../../libs/axios';
import { useNotification } from '../../../hooks/useNotification';
import { updateHistoric } from '../../../helpers/utils/contacts';
import { useTranslation } from 'react-i18next';

const CreateContactWrapper = (props: {
  id?: string | number;
  archived?: boolean;
  title: string;
  onSubmit: () => void;
  children: ReactNode;
  isLoading: boolean;
  isEditMode?: boolean;
  buttonDisabled?: boolean;
  mutate?: any;
}) => {
  const {
    id,
    title,
    children,
    onSubmit,
    isLoading,
    isEditMode,
    buttonDisabled = false,
    archived,
  } = props;
  const navigate = useNavigate();
  const { addNotification } = useNotification();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation('common');
  const onDeleteButtonClick = () => {
    if (!isEditMode) {
      navigate('/contacts');
    } else {
      setLoading(true);
      const ids = [id];

      http
        .patch(`/contacts/${archived ? 'unarchive' : 'archive'}`, {
          [archived ? 'toUnarchive' : 'toArchive']: ids,
        })
        .then(() => {
          addNotification(
            `Your contact has been successfully ${
              archived ? 'unarchived' : 'archived'
            }.`,
            'success'
          );
          updateHistoric(
            id,
            `Contact has been ${archived ? 'unarchived' : 'archived'}.`
          );

          setLoading(false);
          navigate(`/contacts${archived ? '' : '/archived'}`);
        })
        .catch((error) => {
          let message;
          if (error.response) {
            message = 'Server error';
          } else if (error.request) {
            message = 'Failed to connect to server';
          } else {
            message = 'Unknown error';
          }
          addNotification(message, 'error');
        });
    }
  };

  const buttonsJsx = (
    <>
      <Button
        onClick={onDeleteButtonClick}
        variant="outlined"
        className="flex justify-center items-center btn_archive_contact"
        sx={{
          height: '42px',
          color: archived ? '#4CAF50' : '#F50057',
        }}
      >
        <Box className="flex justify-center items-center" gap="16px">
          {archived ? (
            <ArrowUpwardIcon fontSize="small" />
          ) : (
            <DeleteOutlineIcon fontSize="small" />
          )}
          <div className="font-medium">
            {archived ? t('Unarchive') : t('Archive')} {t('Contact')}
          </div>
        </Box>
      </Button>
      <Button
        disabled={buttonDisabled}
        type="submit"
        variant="contained"
        disableElevation
        className="flex justify-center items-center btn_save_update"
        sx={{
          background: '#3446B9',
          height: '40px',
        }}
      >
        <Box className="flex justify-center items-center" gap="16px">
          <div className="font-medium">
            {t('Save')} & {isEditMode ? t('Update') : t('publish')}
          </div>
          <CheckIcon
            fontSize="small"
            sx={{
              width: '16px',
              height: '14px',
            }}
          />
        </Box>
      </Button>
    </>
  );

  return (
    <FormWrapper
      title={title}
      onSubmit={onSubmit}
      buttons={buttonsJsx}
      isLoading={isLoading || loading}
      isEditMode={isEditMode}
    >
      {children}
    </FormWrapper>
  );
};

export default CreateContactWrapper;
