import { toast } from 'react-toastify';
import { useCallback } from 'react';
import PublishIcon from '@mui/icons-material/Publish';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTranslation } from 'react-i18next';

import { ActionData } from '../../../types/actionData';
import { Pipeline, PipelineStatus } from '../../../types/pipelines';
import EditIcon from '../../../features/icons/editIcon';
import { useAnchorElement } from '../../../hooks/useAnchorElement';
import { http } from '../../../libs/axios';
import ActionMenu from '../../../components/common/actionMenu';

const PipelinesActionMenu = ({
  row,
  mutate,
}: {
  row: Pipeline;
  mutate?: () => Promise<unknown>;
}) => {
  const { t } = useTranslation('alerts');

  const changePublication = useCallback(
    async (id: number, shouldBePublished = false) => {
      const desiredStatus = shouldBePublished
        ? PipelineStatus.PUBLISHED
        : PipelineStatus.UNPUBLISHED;
      try {
        await http.patch(`/pipelines/${id}`, { status: desiredStatus });
        mutate?.();
        toast.success(
          t('has_been_updated', { what: t('pipeline', { count: 1 }) }) as string
        );
      } catch (e) {
        toast.error(t('alerts:server_error') as string);
      }
    },
    [mutate, t]
  );

  const isPublished = row.status === PipelineStatus.PUBLISHED;
  const menuActionData: ActionData[] = [
    {
      label: 'usersPage:Edit_info',
      icon: EditIcon,
      link: `/settings/pipelines/${row.id}`,
    },
    isPublished
      ? {
          label: 'common:unpublish',
          onClick: () => changePublication(row.id, false),
          icon: CancelIcon,
        }
      : {
          label: 'common:publish',
          onClick: () => changePublication(row.id, true),
          icon: PublishIcon,
        },
  ];

  const anchor = useAnchorElement();

  return (
    <ActionMenu
      {...anchor}
      menuAction={menuActionData}
      transPage={'brochureEditor'}
    />
  );
};

export default PipelinesActionMenu;
