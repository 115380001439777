export const TableGridXStyling = {
  color: 'text.primary',
  maxWidth: '100% !important',
  height: '100%',
  '& .MuiDataGrid-main ,& .MuiDataGrid-root': {
    position: 'relative',
    border: ' none !important',
  },
  '& .MuiDataGrid-columnHeader': {
    paddingLeft: '5px',
    paddingRight: '5px',
    height: '48px !important',
    maxWidth: '100% !important',
    borderRight: '1px solid #E6E8EC',
  },
  '& .MuiDataGrid-columnHeader  .MuiDataGrid-menuIcon ': {
    width: 'auto ',
    visibility: 'visible !important',
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: 'background.paper',
    height: '48px !important',
    minHeight: '48px !important',
    marginBottom: '8px !important',
    border: `1px solid #E6E8EC`,
  },
  '& .MuiDataGrid-virtualScroller': {
    backgroundColor: 'background.paper',
    borderTop: `1px solid #E6E8EC`,
    borderLeft: `1px solid #E6E8EC`,
    borderRight: `1px solid #E6E8EC`,
    // overflowX: 'hidden !important',
    overflowY: 'auto !important',
    minHeight: '180px',
  },
  '& .MuiDataGrid-columnHeader:first-of-type': {
    backgroundColor: 'white',
    // borderRight: 'none',
  },
  '& .MuiDataGrid-columnHeader ': {
    backgroundColor: 'background.paper',
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell:first-of-type': {
    // borderRight: `1px solid #E6E8EC`,
  },
  '& .MuiDataGrid-renderingZone': {
    maxHeight: 'none !important',
    maxWidth: 'none !important',
  },
  '& .MuiDataGrid-cell': {
    paddingLeft: '5px',
    paddingRight: '5px',
    lineHeight: 'unset !important',
    maxHeight: 'none !important',
    whiteSpace: 'normal',
  },
  '.with-border & .MuiDataGrid-cell': {
    borderRight: `1px solid #E6E8EC`,
  },
  '& .MuiDataGrid-row': {
    maxHeight: 'none !important',
  },
  '& .MuiDataGrid-row.super-app-theme--inner': {
    backgroundColor: 'background.default',
    maxHeight: 'fit-content !important',
  },
  '& .MuiDataGrid-toolbarContainer': {
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
    color: 'text.primary',
  },
  // '& .MuiDataGrid-root.only-vertical-scroll .MuiDataGrid-virtualScroller': {
  //   overflowY: 'auto',
  //   overflowX: 'hidden',
  // },
};
