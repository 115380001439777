import IndividualInformationFormFields from '../../creator/individual-form-fields';
import { IContactIndividual } from '../../../../types/contacts';
import useLocale from '../../../../hooks/useLocale';
import {
  withFormHandler,
  transformDataForFormUsage,
} from './individual-form-handler';
import NoEditModeFormWrapper from './wrapper';
import { ReactNode } from 'react';
import { useProtectedForm } from '../../../../hooks/useProtectedForm';

const IndividualForm = (props: {
  id?: string;
  data?: IContactIndividual;
  companyInformation?: number;
  inner?: boolean;
  isLoading: boolean;
  onSubmit: () => void;
  additionalButton?: ReactNode;
}) => {
  const { data, inner, onSubmit, isLoading, additionalButton } = props;
  const { locale } = useLocale();
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isDirty },
    setValue,
  } = useProtectedForm(
    { defaultValues: transformDataForFormUsage(locale, data) },
    data
  );
  const watchNames = watch(['firstname', 'lastname']);

  return (
    <NoEditModeFormWrapper
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isLoading}
      additionalButton={additionalButton}
      buttonDisabled={
        !(
          watchNames &&
          watchNames[0] &&
          watchNames[1] &&
          watchNames[0].length &&
          watchNames[1].length
        ) || !isDirty
      }
    >
      <IndividualInformationFormFields
        inner={inner}
        register={register}
        watch={watch}
        control={control}
        errors={errors}
        setValue={setValue}
      />
    </NoEditModeFormWrapper>
  );
};

// @ts-ignore
const RepeatableIndividualForm = withFormHandler(IndividualForm);

export default RepeatableIndividualForm;
