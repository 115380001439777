import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

function BalconyIcon({ color, className }: IconProps) {
  const { mode } = useThemeMode();
  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        d="M4 10.138H2a1 1 0 00-1 1V14a1 1 0 001 1h2m0-4.862V15m0-4.862h4.063M4 15h4.063m0-4.862V15m0-4.862H12M8.062 15H12m0-4.862h2a1 1 0 011 1V14a1 1 0 01-1 1h-2m0-4.862V15M13 9V2a1 1 0 00-1-1H8M3 9V2a1 1 0 011-1h4m0 0v8M6.5 6v2m3-2v2"
      ></path>
    </svg>
  );
}

export default BalconyIcon;
