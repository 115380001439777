import useLocale from '../../../../../hooks/useLocale';
import PermissionIcon from '../../../../../features/icons/permissionIcon';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { convertDisplayedPrice } from '../../../utils';
import HomeIcon from '../../../../../features/icons/homeIcon';
import BedroomIcon from '../../../../../features/icons/bedroomIcon';
import SquareIcon from '../../../../../features/icons/squareIcon';
import MountainIcon from '../../../../../features/icons/mountainIcon';
import { useForm } from 'react-hook-form';
import { useNotification } from '../../../../../hooks/useNotification';
import { AxiosResponse } from 'axios';
import { http } from '../../../../../libs/axios';
import { GridSelectionModel } from '@mui/x-data-grid';
import useSWR from 'swr';
import { IContact } from '../../../../../types/contacts';
import { IMatching } from '../../../../../types/matching';
import PropertyFirstImage from '../../../../../components/tableGridX/propertyFirstImage';
import { useIsRent } from '../../../../../hooks/useIsRent';
import { ISettingEmails } from '../../../../../types/settings';
import { getMatchingSettingByType } from '../../../../../helpers/utils';
import { getDefaultEmailValues } from '../../../../contacts/components/matching/send-email-modal';
import SendEmailReusableForm from '../../../../../components/sendEmail/form';

type Props = {
  mutate?: any;
  rowSelectionUsers: GridSelectionModel;
  rowSelectionProperty: GridSelectionModel;
  rows: IMatching[];
  onEmailSent: (messageHtml?: string) => void;
  formRef: any;
  downloadAllContacts?: boolean;
  contactsMatched?: number;
  contactsFilter?: number | null;
};

export const FORM_ID = 'email-form';

const formatContactNames = (
  contacts: IContact[]
): { formattedNames: string; allNames: string } => {
  const count = 8;
  const contactNames = contacts.map((contact) =>
    contact.individual
      ? `${contact.individual.firstname} ${contact.individual.lastname}`
      : ''
  );

  if (contactNames.length <= count) {
    return {
      formattedNames: contactNames.join(', '),
      allNames: contactNames.join(', '),
    };
  } else {
    const remainingCount = contactNames.length - count;
    const firstTenNames = contactNames.slice(0, count).join(', ');
    return {
      formattedNames: `${firstTenNames}, +${remainingCount}`,
      allNames: contactNames.join(', '),
    };
  }
};

const SendEmailForm = (props: Props) => {
  const {
    rows,
    mutate,
    rowSelectionProperty,
    rowSelectionUsers,
    onEmailSent,
    formRef,
    downloadAllContacts,
    contactsMatched,
    contactsFilter,
  } = props;
  const { locale } = useLocale();
  const { handleSubmit, register, reset, control, setValue } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { addNotification } = useNotification();
  const { data: contacts } = useSWR(
    `/matchings/${rowSelectionProperty[0]}/contacts${
      downloadAllContacts
        ? `?itemsPerPage=${contactsMatched || 10}${
            contactsFilter !== null ? `&sent=${contactsFilter}` : ''
          }`
        : ''
    }`
  );
  const { data: settings } = useSWR<ISettingEmails[]>('/setting_emails');
  const currentSettings = getMatchingSettingByType('2', settings);
  useEffect(() => {
    if (currentSettings) {
      reset(getDefaultEmailValues(locale, currentSettings));
    }
  }, [currentSettings]);

  console.log('contactsMatched', contactsMatched);

  const items = useMemo(() => {
    const item = rows.filter((el: any) => rowSelectionProperty.includes(el.id));

    return item && item[0] ? [item[0].property] : [];
  }, [rows]);

  const users = useMemo(() => {
    if (contacts && contacts['hydra:member']) {
      const returnedValue = contacts['hydra:member'];
      if (downloadAllContacts) {
        return returnedValue;
      } else {
        return returnedValue.filter((el: any) =>
          rowSelectionUsers.includes(el.id)
        );
      }
    } else {
      return [];
    }
  }, [contacts]);

  const { formattedNames, allNames } = formatContactNames(users);

  const onSubmit = (data: any) => {
    const body = {
      displayGreeting: data.greeting,
      subject: data.subject,
      text1: data.text,
      text2: data.secondText,
      contacts: users.map((el: { id: number }) => el.id),
    };

    submitDataToServer(body);
  };

  const submitDataToServer = async (body: any) => {
    const id = items && items[0] ? items[0].id : null;
    const promise: Promise<AxiosResponse> = http.post(
      `/v1/properties/${id}/send_matching`,
      body
    );

    setIsLoading(true);
    promise
      .finally(() => {
        setIsLoading(false);
      })
      .then(({ data }) => {
        if (mutate) {
          mutate(data);
        }
        reset();
        addNotification(`Email has been sent.`, 'success');

        const successMessage = `Email to
          ${formattedNames}
          <br />
          has been sent`;
        onEmailSent(successMessage);
      })
      .catch((error) => {
        let message;
        if (error.response) {
          message = 'Server error';
        } else if (error.request) {
          message = 'Failed to connect to server';
        } else {
          message = 'Unknown error';
        }
        addNotification(message, 'error');
      });
  };

  const isRent = useIsRent(items[0]);
  const setFieldByService = (name: string): string => {
    return isRent
      ? // @ts-ignore
        items[0]?.pricingRent?.[name]
      : // @ts-ignore
        items[0]?.pricingBuy?.[name];
  };
  return (
    <>
      <SendEmailReusableForm
        control={control}
        register={register}
        formRef={formRef}
        onSubmit={handleSubmit(onSubmit)}
        formId={FORM_ID}
        usersList={formattedNames}
        allNames={allNames}
        isLoading={isLoading}
        setValue={setValue}
      >
        <div>
          {items.map((item) => {
            return (
              <div
                key={item.id}
                className="py-8 self-stretch justify-start items-start inline-flex"
              >
                <div className="relative w-40 shrink-0">
                  <PropertyFirstImage row={item} />
                </div>
                <div className="grow px-4 flex-col justify-start items-start gap-8 inline-flex">
                  <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                    <div
                      className="flex py-1 px-2 rounded-md items-center"
                      style={{ border: '1px solid #3446B9 ' }}
                    >
                      <PermissionIcon className="col-span-1" color="#3446B9" />{' '}
                      <span className="text-blue leading-5">
                        {item.reference}
                      </span>
                    </div>
                    {item.translations?.[locale]?.title ? (
                      <Link
                        className={
                          "text-blue-800 text-2xl font-normal font-['Inter'] leading-7"
                        }
                        to={`/properties/${item.id}`}
                        id={`img_property_${item.id}`}
                      >
                        <p className={'font-medium'}>
                          {item.translations?.[locale]?.title}
                        </p>
                      </Link>
                    ) : null}
                    <div className="text-zinc-900 text-base font-semibold font-['Inter'] leading-tight">
                      {setFieldByService('displayedPrice')
                        ? `CHF ${
                            convertDisplayedPrice(
                              setFieldByService('displayedPrice').toString()
                            ) + '.-'
                          }`
                        : ''}
                    </div>
                  </div>
                  <div className="flex-col justify-center items-start gap-2 flex">
                    <div className="justify-start items-center gap-y-2 gap-x-8 inline-flex">
                      {item.rooms && (
                        <div className="justify-start items-center gap-2.5 flex">
                          <HomeIcon className="col-span-1" color="#6E6E72" />
                          <div className="text-zinc-900 text-sm font-normal font-['Inter'] leading-none">
                            {item.rooms}
                          </div>
                        </div>
                      )}
                      {item.livingArea && (
                        <div className="justify-start items-center gap-2.5 flex">
                          <SquareIcon className="col-span-1" color="#6E6E72" />{' '}
                          <div className="">
                            <span className="text-zinc-900 text-sm font-normal font-['Inter'] leading-none">
                              {item.livingArea} m
                            </span>
                            <sup className="text-zinc-900 text-sm font-normal font-['Inter'] leading-none">
                              2
                            </sup>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="justify-center items-center gap-y-2 gap-x-8 inline-flex">
                      {item.bedrooms && (
                        <div className="justify-start items-center gap-2.5 flex">
                          <BedroomIcon className="col-span-1" color="#6E6E72" />{' '}
                          <div className="text-zinc-900 text-sm font-normal font-['Inter'] leading-none">
                            {item.bedrooms}
                          </div>
                        </div>
                      )}
                      {item.landSurface && (
                        <div className="justify-start items-center gap-2.5 flex">
                          <MountainIcon
                            className="col-span-1"
                            color="#6E6E72"
                          />
                          <div className="">
                            <span className="text-zinc-900 text-sm font-normal font-['Inter'] leading-none">
                              {item.landSurface} m
                            </span>
                            <sup className="text-zinc-900 text-sm font-normal font-['Inter'] leading-none">
                              2
                            </sup>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </SendEmailReusableForm>
    </>
  );
};

export default SendEmailForm;
