import React from 'react';
import { IDashboardOfferItem } from '../../../types/user';
import { Link } from 'react-router-dom';
import TabGridTag from '../../../features/tags/tabGridTag';
import { Skeleton, Tooltip } from '@mui/material';
import PermissionIcon from '../../../features/icons/permissionIcon';
import useLocale from '../../../hooks/useLocale';
import { formatPriceWithCurrency } from '../utils/useOffersListColumns';
import {
  formatDateAndTime,
  getElapsedTimeFromDate,
  sanitizeUrl,
} from '../../../helpers/utils';
import { useTranslation } from 'react-i18next';
import PlusIcon from '../../../features/icons/plusIcon';
import { getInitials } from '../widgets/offers';

import { ILeadItem } from '../../../types/contacts';
import { useIsRent } from '../../../hooks/useIsRent';
import classNames from 'classnames';
import useSWR from 'swr';
import { OfferType } from '../../../types/pipelines';
type Props = {
  offerItem?: IDashboardOfferItem;
  leadsItem?: ILeadItem;
  isOffers?: boolean;
  isLeads?: boolean;
};

export default function OfferCrad({
  offerItem,
  leadsItem,
  isOffers,
  isLeads,
}: Props) {
  const { t } = useTranslation('common');
  const { locale } = useLocale();
  const { data: offerTypes } = useSWR<OfferType[]>('/offer_types');
  const findImages = (): string | undefined => {
    if (Array.isArray(leadsItem?.property.media)) {
      return undefined;
    }

    const images = leadsItem?.property.media.images;

    if (images && images.length > 0) {
      const mainImage = images.find((elem) => elem.isMain);
      return mainImage ? mainImage.url.large : images[0]?.url.large;
    }

    return undefined;
  };

  const isRent = useIsRent(leadsItem?.property);
  const inProgress = leadsItem?.status.keyname === 'in progress';
  const skeletonEl = (
    <Skeleton width={100} height={20} animation="wave" variant="text" />
  );

  return (
    <div
      className={
        '@container p-2 bg-white rounded flex flex-col justify-between'
      }
    >
      <div className={'bg-gray-400 w-full max-h-64 relative'}>
        <div className="h-64 w-full max-w-full">
          {isOffers && (
            <Link
              className="flex w-full h-full"
              to={`/properties/${offerItem?.id}`}
            >
              {offerItem?.dealType && (
                <TabGridTag
                  textContent={offerItem.dealType}
                  textToolTip={offerItem.dealType}
                  className="absolute bg-blue font-medium border-0 font-[Inter] capitalize left-2 top-2"
                  color="white"
                  fontSize="12px"
                />
              )}
              <img
                src={offerItem?.media?.url.large}
                alt=""
                className="w-[100%] !h-[100%] !object-cover max-w-inherit"
              />
            </Link>
          )}
          {isLeads && leadsItem && (
            <Link
              to={`/properties/${leadsItem?.property.id}`}
              id={`img_property_${leadsItem?.property.id}`}
              className="flex w-full h-full"
            >
              {leadsItem?.property.department.service.keyname && (
                <TabGridTag
                  textContent={leadsItem?.property.department.service.keyname}
                  textToolTip={leadsItem?.property.department.service.keyname}
                  className="absolute bg-blue font-medium border-0 font-[Inter] capitalize left-1 md:left-2 top-2"
                  color="white"
                  fontSize="12px"
                />
              )}
              <img
                src={findImages() || '/img/default_image.jpg'}
                alt=""
                className="w-[100%] !h-[100%] !object-cover max-w-inherit"
              />
            </Link>
          )}
          <div className="px-2 py-1 bg-white rounded justify-start items-center gap-1 flex absolute left-2 bottom-2">
            <PermissionIcon className="grid gap-1 col-span-1" color="#3446B9" />{' '}
            <span className=" leading-4 font-[Inter] font-normal text-blue-800 text-xs md:text-sm ">
              {isOffers
                ? offerItem?.reference
                : isLeads &&
                  (leadsItem?.property?.referenceAgency ||
                    leadsItem?.property?.reference)}
            </span>
          </div>

          {isLeads && leadsItem && (
            <div
              className={classNames(
                'px-2 py-1 bg-neutral-100 rounded border justify-start items-center gap-1 inline-flex absolute right-2 bottom-2',
                {
                  'border-[#4CAF50]': !inProgress,
                  'border-[#F50057]': inProgress,
                }
              )}
            >
              <div
                className={classNames(
                  'text-xs md:text-sm overflow-ellipsis font-medium',
                  {
                    'text-[#4CAF50]': !inProgress,
                    'text-[#F50057]': inProgress,
                  }
                )}
              >
                {t(!inProgress ? 'common:processed' : 'common:not_processed')}
              </div>
            </div>
          )}

          {isOffers && (
            <div className="px-2 py-1 bg-neutral-100 rounded absolute right-2 top-2">
              <div className="text-green-500 text-sm font-medium font-[Inter] leading-none normal-case">
                {
                  offerTypes?.find((t) => t.id === Number(offerItem?.offerType))
                    ?.translations?.[locale]?.name
                }
              </div>
            </div>
          )}

          {isLeads && leadsItem?.status.keyname === 'in progress' ? (
            <div className=" bg-neutral-100 rounded absolute right-1 md:right-2 top-2">
              <div className="px-2 py-1 rounded border border-orange-600 justify-start items-center gap-1 inline-flex">
                <div className="text-orange-600 text-xs md:text-sm font-normal leading-none">
                  {t('New_lead')}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <div>
        <div className=" pb-3 pt-4 border-b w-full">
          <div className="grid gap-3">
            {offerItem &&
            offerItem?.title &&
            offerItem?.title[locale] &&
            offerItem?.title?.[locale]?.title ? (
              <Link
                className={
                  'inline-flex w-[30%] sm:w-[70vw] md:w-[36vw] xl:w-[25vw] 2xl:w-[18vw]'
                }
                to={`/properties/${offerItem?.propertyId}`}
                id={`img_property_${offerItem?.propertyId}`}
              >
                <Tooltip
                  title={offerItem?.title?.[locale]?.title}
                  arrow
                  placement="top"
                >
                  <p
                    className={
                      'w-full truncate text-sm md:text-lg font-medium leading-[120%] font-[Inter] overflow-ellipsis overflow-hidden'
                    }
                  >
                    {offerItem?.title?.[locale]?.title}
                  </p>
                </Tooltip>
              </Link>
            ) : null}

            {leadsItem?.property &&
            leadsItem?.property.translations &&
            leadsItem?.property.translations[locale] &&
            leadsItem?.property?.translations?.[locale]?.title ? (
              <Link
                className={
                  'inline-flex w-[30%] sm:w-[70vw] md:w-[36vw] xl:w-[25vw] 2xl:w-[18vw]'
                }
                to={`/properties/${leadsItem?.property.id}`}
                id={`img_property_${leadsItem?.property.id}`}
              >
                <Tooltip
                  title={leadsItem?.property?.translations?.[locale]?.title}
                  arrow
                  placement="top"
                >
                  <p
                    className={
                      'w-full truncate text-sm md:text-lg font-medium leading-[120%] font-[Inter] overflow-ellipsis overflow-hidden'
                    }
                  >
                    {leadsItem?.property?.translations?.[locale]?.title}
                  </p>
                </Tooltip>
              </Link>
            ) : null}

            <div className="font-[Inter] font-medium text-sm leading-[130%]">
              {isOffers
                ? formatPriceWithCurrency(
                    offerItem?.price,
                    offerItem?.currency
                  ) + ' -'
                : isLeads && isRent
                ? formatPriceWithCurrency(
                    leadsItem?.property.pricingRent?.displayedPrice,
                    leadsItem?.property?.pricingRent?.currency?.value
                  ) + ' -'
                : formatPriceWithCurrency(
                    leadsItem?.property.pricingBuy?.displayedPrice,
                    leadsItem?.property?.pricingBuy?.currency?.value
                  ) + ' -'}
            </div>
          </div>
        </div>
        <div className="py-3 border-b w-full">
          {isOffers && (
            <div>
              <div className="flex gap-2 items-center">
                <PlusIcon color="#6E6E72" />
                <span className="font-medium leading-4 text-xs font-[Inter] text-[#6E6E72]">
                  {t('date_created')}:
                </span>
              </div>
              <span className="font-medium leading-4 text-xs font-[Inter] text-[#1D1D1F]">
                {offerItem?.offerDate
                  ? formatDateAndTime(offerItem?.offerDate)
                  : skeletonEl}
              </span>
            </div>
          )}

          {isLeads && (
            <div className="flex gap-1">
              <span className="font-medium leading-4 text-sm font-[Inter] text-[#6E6E72]">
                {t('Date')}:
              </span>
              <div
                className={classNames(
                  'self-stretch justify-start items-center gap-1 inline-flex',
                  {
                    'text-[#3446B9]': leadsItem?.status.keyname === 'processed',
                    'text-orange-600':
                      leadsItem?.status.keyname === 'in progress',
                  }
                )}
              >
                <div className="text-[14px] md:text-[16px] font-[Inter] font-normal leading-none">
                  {leadsItem &&
                    getElapsedTimeFromDate(leadsItem?.date, locale === 'fr')}
                </div>
              </div>
            </div>
          )}
        </div>
        {isOffers && (
          <div className=" pt-3 w-full">
            <div className="flex items-center gap-3">
              <div className=" bg-blue-800 rounded-full w-[40px] h-[40px] flex items-center justify-center ">
                <div className="text-white text-sm font-normal font-['Inter'] leading-none">
                  {offerItem && getInitials(offerItem.name)}
                </div>
              </div>
              <div>
                <p
                  title={offerItem?.name}
                  className="grow shrink basis-0 text-[#1D1D1F] text-sm font-medium leading-[130%] font-['Inter'] whitespace-nowrap overflow-hidden overflow-ellipsis"
                >
                  {offerItem?.name}
                </p>
                <span className="font-medium text-[#6E6E72] font-[Inter] text-sm leading-4 ">
                  {t('Agency')}
                </span>
              </div>
            </div>
          </div>
        )}
        {isLeads && (
          <div className="py-3 border-b w-full">
            <div className="flex gap-1 items-center">
              <span className="font-medium leading-4 text-sm font-[Inter] text-[#6E6E72]">
                {t('Contact')}:
              </span>
              <div>
                <Link
                  to={`/contacts/${leadsItem?.contact?.id}/contact-information`}
                  id={`img_contact_${leadsItem?.contact?.id}`}
                  className={
                    'max-w-full font-[Inter] font-normal overflow-hidden'
                  }
                >
                  <p
                    className={
                      'max-w-full text-[14px] md:text-[16px] overflow-ellipsis overflow-hidden'
                    }
                  >
                    {leadsItem?.contact?.fullname}
                  </p>
                </Link>
              </div>
            </div>
          </div>
        )}

        {isLeads && (
          <div className="py-3 border-b w-full">
            <div className="flex gap-1 items-center">
              <span className="font-medium leading-4 text-sm font-[Inter] text-[#6E6E72]">
                {t('Source')}:
              </span>
              <div className="w-full">
                {leadsItem?.source === 'Preview Page' ? (
                  <Tooltip title={leadsItem?.source} arrow placement="top">
                    <a
                      target={'_blank'}
                      rel={'noreferrer'}
                      href={
                        leadsItem?.source === 'Preview Page' &&
                        leadsItem?.property &&
                        leadsItem?.property.reference
                          ? `/property/${leadsItem?.property.reference}?broker=true`
                          : sanitizeUrl(leadsItem?.source)
                      }
                      className={` inline-flex gap-1 transition-opacity duration-300 hover:opacity-80 overflow-hidden text-ellipsis whitespace-nowrap items-end`}
                    >
                      <span className=" w-[87%] block text-[14px] md:text-[16px] font-[Inter] font-normal truncate">
                        {leadsItem?.source}
                      </span>
                    </a>
                  </Tooltip>
                ) : (
                  <Tooltip title={leadsItem?.source} arrow placement="top">
                    <div className="overflow-hidden text-ellipsis whitespace-nowrap flex items-end">
                      <span className="w-[87%] block text-[14px] md:text-[16px] font-[Inter] font-normal truncate">
                        {t(`${leadsItem?.source}`)}
                      </span>
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        )}

        {isLeads && (
          <div className="pt-3 mb-6 w-full">
            <div className="flex gap-1 items-center">
              <span className="font-medium leading-4 text-sm font-[Inter] text-[#6E6E72]">
                {t('Message')}:
              </span>
              <p className="font-normal text-[14px] md:text-[16px] font-[Inter] truncate w-[73%]">
                {leadsItem?.message}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
