import { PropsWithChildren } from 'react';

import { StepsProvider } from './stepper';
import { NotificationProvider } from './notification';
import { SWRProvider } from './swr';
import { DatepickerLocalizationProvider } from './datepickerLocalization';
import { ThemeProvider } from './theme';

function Providers({ children }: PropsWithChildren) {
  return (
    <DatepickerLocalizationProvider>
      <SWRProvider>
        <ThemeProvider>
          <NotificationProvider>
            <StepsProvider>{children}</StepsProvider>
          </NotificationProvider>
        </ThemeProvider>
      </SWRProvider>
    </DatepickerLocalizationProvider>
  );
}

export default Providers;
