import classNames from 'classnames';
import { Tooltip } from '@mui/material';

import ControlLayout from '../controlLayout';
import { EditorControlProps } from '../../../../types/brochureEditor';

const options = [
  {
    value: 'left',
    path: 'M5 8.5h22M5 13.5h16M5.001 18.5h22M5.001 23.5h16',
  },
  {
    value: 'center',
    path: 'M5 8.5h22M8 13.5h16M5 18.5h22M8 23.5h16',
  },
  {
    value: 'right',
    path: 'M5 8.5h22M11 13.5h16M5.001 18.5h22M11.001 23.5h16',
  },
  {
    value: 'justify',
    path: 'M5 8.5h22M5 13.5h22M5.001 18.5h22M5.001 23.5h22',
  },
];

const TextAlignControl = ({
  value,
  onChange,
  label,
}: EditorControlProps<string>) => {
  return (
    <ControlLayout label={label}>
      <div className="justify-start items-start gap-2 inline-flex">
        {options.map((o) => (
          <Tooltip key={o.value} title={o.value} arrow placement="top">
            <button
              className={classNames(
                'w-12 h-12 p-2 bg-white justify-start items-start gap-2 inline-flex border',
                {
                  'border-transparent': o.value !== value,
                  'text-blue border-blue': o.value === value,
                }
              )}
              onClick={() => onChange?.(o.value)}
              aria-label={o.value}
            >
              <div className="w-8 h-8 rounded-[32px] justify-center items-center flex">
                <svg
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 32 32"
                  className="w-8 h-8"
                >
                  <path
                    d={o.path}
                    stroke="currentcolor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </button>
          </Tooltip>
        ))}
      </div>
    </ControlLayout>
  );
};

export default TextAlignControl;
