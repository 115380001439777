import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { toast } from 'react-toastify';

import { IPropertyApi } from '../../../../types/property';
import { IPromotionApi } from '../../../../types/promotion';
import {
  PreviewSubject,
  PreviewSubjectTypes,
} from '../../../../types/brochureEditor';
import { guaranteeArray } from '../../../../helpers/utils/formatters';

export function usePreviewSubject() {
  const { templateId, propertyId, promotionId } = useParams();
  const navigate = useNavigate();

  const { data: editedProperty, error: propertyError } = useSWR<IPropertyApi>(
    propertyId ? `/v1/properties/${propertyId}` : null,
    null,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  const propertyNotFound = Boolean(
    propertyId && propertyError?.response?.status === 404
  );
  if (propertyNotFound) {
    navigate(`/brochure-templates/${templateId}`);
    toast.error('Property not found');
  }

  const { data: editedPromotion, error: promotionError } =
    useSWR<IPromotionApi>(
      promotionId ? `/v1/promotions/${promotionId}` : null,
      null,
      {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }
    );
  const promotionNotFound = Boolean(
    promotionId && promotionError?.response?.status === 404
  );
  if (promotionNotFound) {
    navigate(`/brochure-templates/${templateId}`);
    toast.error('Promotion not found');
  }

  let previewSubject: PreviewSubject | null = null;
  let originalSubject: IPropertyApi | IPromotionApi | undefined;
  let hasLoaded = true;
  let type: PreviewSubjectTypes | null = null;

  if (propertyId) {
    type = PreviewSubjectTypes.PROPERTY;
    previewSubject = editedProperty
      ? {
          id: editedProperty.id,
          images: Array.isArray(editedProperty.media)
            ? []
            : editedProperty.media.images,
          address: editedProperty.address,
          department: editedProperty.department,
          mainBroker: editedProperty.mainBroker,
          intermediateBroker: editedProperty.intermediateBroker,
          translations: editedProperty.translations,
          reference: editedProperty.reference,
          referenceAgency: editedProperty.referenceAgency,
          type,
        }
      : null;
    hasLoaded = !!editedProperty;
    originalSubject = editedProperty;
  } else if (promotionId) {
    type = PreviewSubjectTypes.PROMOTION;
    previewSubject = editedPromotion
      ? {
          id: editedPromotion.id,
          images: editedPromotion.images,
          address: editedPromotion.address,
          department: guaranteeArray(editedPromotion.departments)[0] || null,
          mainBroker: editedPromotion.mainBroker,
          intermediateBroker: null,
          translations: editedPromotion.translations,
          reference: editedPromotion.reference,
          referenceAgency: null,
          type,
        }
      : null;
    hasLoaded = !!editedPromotion;
    originalSubject = editedPromotion;
  }

  return {
    previewSubject,
    originalSubject,
    hasLoaded,
    type,
  };
}
