import { INetworkAgency } from '../../../../types/networks';
import { Link, useParams } from 'react-router-dom';
import React from 'react';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { replaceEnding } from '../index';
import { ActionData } from '../../../../types/actionData';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useAnchorElement } from '../../../../hooks/useAnchorElement';
import ActionMenu from '../../../../components/common/actionMenu';
import classNames from 'classnames';
import addToNetwork from '../actions/addToNetwork';
import { useTranslation } from 'react-i18next';

interface Props {
  isGrid: boolean;
  item: INetworkAgency;
}

const ItemActionMenu = ({
  item,
  action,
}: {
  item: INetworkAgency;
  action?: string;
}) => {
  const { id } = useParams();

  const menuActionData: ActionData[] = [
    {
      label: 'view',
      link: `/networks/${id}/agencies/${item.id}`,
      icon: VisibilityOutlinedIcon,
    },
    {
      label: 'invite',
      onClick: () => addToNetwork(id as string, item.id.toString()),
      icon: AddOutlinedIcon,
    },
  ];

  const anchor = useAnchorElement();

  return (
    <>
      <ActionMenu
        {...anchor}
        isList={true}
        menuAction={menuActionData}
        transPage={'networks'}
        action={action}
      />
    </>
  );
};

const AgencyTableItem = (props: { item: INetworkAgency }) => {
  const { item } = props;
  const { id } = useParams();
  return (
    <div className={'flex items-center gap-2'}>
      <Link to={`/networks/${id}/agencies/${item.id}`}>
        <div
          className={classNames(
            'w-[48px] h-[48px] rounded-full overflow-hidden flex agencies-center justify-center',
            {
              'bg-[#34B9B9]': !item.logo,
              'bg-[gray-100] border': item.logo,
            }
          )}
        >
          {item.logo?.x96 ? (
            <img
              className={'object-contain'}
              src={item.logo.x96}
              alt={item.name}
            />
          ) : null}
        </div>
      </Link>
      <div className={'flex flex-col gap-1'}>
        <Link to={`/networks/${id}/agencies/${item.id}`}>
          <span>{item.name}</span>
        </Link>
        <span className={'text-[14px]'}>{item.headQuarter}</span>
      </div>
    </div>
  );
};

const AgencyGridItem = (props: { item: INetworkAgency }) => {
  const { item } = props;
  const { id } = useParams();
  const { t } = useTranslation('networks');

  return (
    <div
      className={
        '@container p-2 pt-4 bg-white rounded flex flex-col justify-between'
      }
    >
      <Link to={`/networks/${id}/agencies/${item.id}`}>
        <div
          className={classNames(
            'w-[64px] h-[64px] rounded-full overflow-hidden flex agencies-center justify-center mb-4',
            {
              'bg-[#34B9B9]': !item.logo,
              'bg-[gray-100]': item.logo,
            }
          )}
        >
          {item.logo?.x96 ? (
            <img
              className={'object-contain'}
              src={item.logo.x96}
              alt={item.name}
            />
          ) : null}
        </div>
      </Link>
      <div className="flex items-center gap-2 mb-4">
        <Link to={`/networks/${id}/agencies/${item.id}`}>
          <div className="cursor-pointer text-zinc-700 text-2xl font-normal leading-tight hover:opacity-80 duration-300 transition-opacity">
            {item?.name}
          </div>
        </Link>
      </div>
      <div className={'mb-4 text-[14px]'}>{item?.description}</div>
      <div className={'divide-y flex flex-col border-b border-t mb-2'}>
        <div className="text-zinc-700 basis-0 py-2 justify-start items-center gap-1.5 @xs:gap-2.5 flex opacity-60">
          <PeopleOutlineIcon fontSize={'small'} />
          <div className="text-sm leading-none  whitespace-nowrap">
            <span>
              {replaceEnding(
                t('Employee'),
                item.employees || 0,
                t('Employees')
              )}
            </span>
          </div>
        </div>
        <div className="text-zinc-700 basis-0 py-2 justify-start items-center gap-1.5 @xs:gap-2.5 flex opacity-60">
          <WorkOutlineOutlinedIcon fontSize={'small'} />
          <div className="text-sm leading-none  whitespace-nowrap">
            <span>
              {t('Headquarter')}: {item.headQuarter}
            </span>
          </div>
        </div>
        <div className="text-zinc-700 basis-0 py-2 justify-start items-center gap-1.5 @xs:gap-2.5 flex opacity-60">
          <CalendarTodayOutlinedIcon fontSize={'small'} />
          <div className="text-sm leading-none  whitespace-nowrap">
            <span>
              {t('FoundedIn')} {item.foundedIn}
            </span>
          </div>
        </div>
      </div>
      <ItemActionMenu item={item} />
    </div>
  );
};

const NetworkAgencyCard = (props: Props) => {
  const { isGrid, item } = props;

  return isGrid ? (
    <AgencyGridItem item={item} />
  ) : (
    <AgencyTableItem item={item} />
  );
};

export default NetworkAgencyCard;
