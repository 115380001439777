import classNames from 'classnames';
import { ReactNode } from 'react';

const EditorSidebarSection = ({
  children,
  isLarge,
  className,
}: {
  children: ReactNode;
  isLarge?: boolean;
  className?: string;
}) => (
  <div
    className={classNames('border-b border-gray-200', className, {
      'py-4 px-8': !isLarge,
      'py-8 px-12': isLarge,
    })}
  >
    {children}
  </div>
);

export default EditorSidebarSection;
