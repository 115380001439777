import { RouterProvider } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { router } from './router';

import { useSetupLocalStorageVariables } from './hooks/useSetupLocalStorageVariables';
import { useSetupNotifications } from './hooks/useSetupNotifications';

import ToastContainer from './components/toastContainer';
import GlobalSnackbar from './components/globalSnackbar';

function App() {
  useSetupLocalStorageVariables();
  useSetupNotifications();

  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer />
      <GlobalSnackbar />
    </>
  );
}

export default Sentry.withProfiler(App);
