import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import { ReactNode } from 'react';
import classNames from 'classnames';

interface Props {
  label?: ReactNode;
  children?: ReactNode;
  isRequired?: boolean;
  className?: string;
  capitalize?: boolean;
}

const InputLayout = ({
  label,
  children,
  className,
  isRequired = false,
  capitalize = true,
}: Props) => (
  <Box
    className={className}
    sx={{ display: 'flex', flexDirection: 'column', gap: '8px', flexGrow: '1' }}
  >
    {label && (
      <InputLabel className={classNames('!text-xs', { capitalize })}>
        {label}
        {isRequired && <span className="text-red-500">*</span>}
      </InputLabel>
    )}
    {children}
  </Box>
);

export default InputLayout;
