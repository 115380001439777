import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

function TerraceIcon({ color, className }: IconProps) {
  const { mode } = useThemeMode();
  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        d="M8 16V4m-4.5 6h9m-2 6v-2a1 1 0 011-1H15m0 0v3m0-3V7M1 7v6m0 3v-3m0 0h3.5a1 1 0 011 1v2M2 4l1.804-1.804a1 1 0 01.464-.263l3.49-.872a1 1 0 01.484 0l3.489.872a1 1 0 01.465.263L14 4H2z"
      ></path>
    </svg>
  );
}

export default TerraceIcon;
