import { Nullable } from './api';
import { ILeadItem } from './contacts';
import { IPromotionApi } from './promotion';
import { IPropertyApi } from './property';
import { IService } from './service';
import { Translations } from './translations';
import User from './user';

export enum PipelineProjectTypes {
  PROPERTY = 'property',
  PROMOTION = 'promotion',
}

export enum PipelineStatus {
  PUBLISHED = 'published',
  UNPUBLISHED = 'unpublished',
}

export enum PipelineStepTypes {
  ENTRANCE = 'entrenace',
  REGULAR = 'regular',
  CLOSING = 'closed',
}

export type PipelineStep = {
  id: number;
} & Nullable<{
  name: string;
  sort: number;
  type: string;
  color: string;
  isOfferStep: boolean;
}>;

export type PipelineStepPayload = Omit<PipelineStep, 'id'> & { id?: number };

export type PipelineSteps = Record<PipelineStepTypes, PipelineStep[]>;

export type Pipeline = {
  id: number;
} & Nullable<{
  name: string;
  projectType: PipelineProjectTypes;
  serviceType: IService;
  usedOn: string;
  status: PipelineStatus;
  pipelineSteps: PipelineSteps;
  createdAt: string;
  updatedAt: string;
  createdBy: Pick<
    User,
    'id' | 'email' | 'firstname' | 'lastname' | 'avatar' | 'roles'
  >;
}>;

export type PipelineForm = Omit<
  Pipeline,
  | 'id'
  | 'serviceType'
  | 'createdAt'
  | 'updatedAt'
  | 'createdBy'
  | 'usedOn'
  | 'pipelineSteps'
> & {
  serviceType: number | null;
  createdBy: number | null;
};

export type StepWithLeads = PipelineStep & { leads: ILeadItem[] };

export interface PipelineComment {
  comment: string;
  commentAt: string;
  commentBy: Pick<User, 'id' | 'firstname' | 'lastname'>;
  price?: number;
  offerType?: string;
  rentPrice?: number;
  rentType?: number;
  startDate?: string | null;
  endDate?: string | null;
}

export type LeadHistoryEntry = {
  id: number;
  pipelineComments: PipelineComment[];
} & Nullable<{
  lead: ILeadItem;
  step: PipelineStep;
  action: string;
  doneBy: Pick<User, 'id' | 'firstname' | 'lastname'>;
  doneAt: string;
}>;

export type PipelineValue = {
  id: number;
  historics: LeadHistoryEntry[];
} & Nullable<{
  lead: ILeadItem;
  pipeline: Pick<Pipeline, 'id' | 'name'>;
  property: { id: number };
  promotion: { id: number };
  step: PipelineStep;
}>;

export interface OfferType {
  id: number;
  keyname: string;
  translations: Translations;
}

export type PipelineEntity = {
  promotion?: IPromotionApi;
  property?: IPropertyApi;
};
