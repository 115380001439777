import { Controller } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import {
  IContact,
  IContactType,
  IContactTypeChoice,
} from '../../../../types/contacts';
import NoEditModeFormWrapper from './wrapper';
import { MenuItem, Select, Switch } from '@mui/material';
import useLocale from '../../../../hooks/useLocale';
import { useNotification } from '../../../../hooks/useNotification';
import { http } from '../../../../libs/axios';
import { selectOptionStringT } from '../../../../components/form/selectFormField';
import InputLayout from '../../../../components/form/components/inputLayout';
import classNames from 'classnames';
import useSWR from 'swr';
import { ContactTapInnerProps } from '../../singleContactPage/tabs/additional-information-tab';
import { updateHistoric } from '../../../../helpers/utils/contacts';
import { useTranslation } from 'react-i18next';
import { useProtectedForm } from '../../../../hooks/useProtectedForm';

const choicesToOptions = (choices: IContactTypeChoice[], locale: string) => {
  return choices.map((item) => ({
    id: item.id,
    value: item.translations?.[locale]?.name || '',
  }));
};

export const switchStyles = {
  width: 42,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 0,
    transitionDuration: '300ms',
    top: '2px',
    left: '2px',
    '&.Mui-checked': {
      transform: 'translateX(22px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#2ECA45',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: '#fff',
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 16,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
  },
};

interface InitialValues {
  owner: boolean | null;
  client: boolean | null;
  external: boolean | null;
  'owner-types': number[] | null;
  'client-types': number[] | null;
  'external-types': number[] | null;
  'constructor-types': number | null;
  [key: string]: number | boolean | null | number[];
}

export const checkType = (
  types: IContactType[],
  data: IContact,
  type: string
) => {
  const typesChoices: Record<string, any> | null = types
    ? types.reduce((a, b) => ({ ...a, [b.keyname]: b.choices }), {})
    : null;
  const typesSelectedIds =
    data.types && data.types.length ? data.types.map((el) => el.id) : [];

  return (
    typesChoices &&
    typesChoices[type] &&
    typesChoices[type].some((el: { id: number }) =>
      typesSelectedIds.includes(el.id)
    )
  );
};

const transformDataForFormUsage = (types: IContactType[], data: IContact) => {
  const typesChoices: Record<string, any> | null = types
    ? types.reduce((a, b) => ({ ...a, [b.keyname]: b.choices }), {})
    : null;
  const typesSelectedIds =
    data.types && data.types.length ? data.types.map((el) => el.id) : [];

  const initialValue: InitialValues = {
    owner: checkType(types, data, 'owner') || null,
    client: checkType(types, data, 'client') || null,
    external: checkType(types, data, 'external') || null,
    'owner-types':
      typesChoices && typesChoices.owner
        ? typesChoices.owner
            .filter((el: { id: number }) => typesSelectedIds.includes(el.id))
            .map((item: { id: number }) => item.id)
        : [],
    'client-types':
      typesChoices && typesChoices.client
        ? typesChoices.client
            .filter((el: { id: number }) => typesSelectedIds.includes(el.id))
            .map((item: { id: number }) => item.id)
        : [],
    'external-types':
      typesChoices && typesChoices.external
        ? typesChoices.external
            .filter((el: { id: number }) => typesSelectedIds.includes(el.id))
            .map((item: { id: number }) => item.id)
        : [],
    'constructor-types': data.constructor ? data.constructor.id : null,
  };

  return initialValue;
};

interface Props extends ContactTapInnerProps {
  types: IContactType[];
}

const ContactTypesForm = (props: Props) => {
  const { data, types, id, mutate } = props;
  const { locale } = useLocale();
  const { handleSubmit, control, watch, resetField } = useProtectedForm(
    {
      defaultValues: transformDataForFormUsage(types, data),
    },
    data
  );
  const [isLoading, setIsLoading] = useState(false);
  const { addNotification } = useNotification();
  const { t } = useTranslation('common');
  const watchAll = watch();
  const { data: constructorChoices } = useSWR(
    watchAll['external-types'] &&
      watchAll['external-types'].includes(9 as never)
      ? `/contact_type_choices/9`
      : null
  );

  useEffect(() => {
    if (!watchAll['owner']) {
      resetField('owner-types', {
        defaultValue: [],
      });
    }
    if (!watchAll['client']) {
      resetField('client-types', {
        defaultValue: [],
      });
    }
    if (!watchAll['external']) {
      resetField('external-types', {
        defaultValue: [],
      });
      resetField('constructor-types', {
        defaultValue: null,
      });
    }
  }, [watchAll['owner'], watchAll['client'], watchAll['external']]);

  const onSubmit = (data: any) => {
    const body = {
      types: [
        ...data['client-types'],
        ...data['owner-types'],
        ...data['external-types'],
      ],
      constructor: data['constructor-types'],
    };

    submitDataToServer(body);
  };

  const submitDataToServer = async (body: any) => {
    const promise: Promise<AxiosResponse> = http.patch(`/contacts/${id}`, body);

    setIsLoading(true);
    promise
      .finally(() => {
        setIsLoading(false);
      })
      .then(({ data }) => {
        if (mutate) {
          mutate(data);
        }
        addNotification(
          `Your contact has been successfully updated.`,
          'success'
        );
        updateHistoric(id, 'Contact type has been updated');
      })
      .catch((error) => {
        let message;
        if (error.response) {
          message = 'Server error';
        } else if (error.request) {
          message = 'Failed to connect to server';
        } else {
          message = 'Unknown error';
        }
        addNotification(message, 'error');
      });
  };

  return (
    <NoEditModeFormWrapper
      isLoading={isLoading}
      onSubmit={handleSubmit(onSubmit)}
      buttonDisabled={
        !(
          watchAll['client-types']?.length ||
          watchAll['owner-types']?.length ||
          watchAll['external-types']?.length
        )
      }
    >
      <>
        {types.map((item) => (
          <div key={item.keyname}>
            <div className="flex items-center justify-between border-b py-4 mb-4">
              <p className={'capitalize'}>{item.translations[locale]?.name}</p>
              <Controller
                name={item.keyname}
                key={item.keyname}
                control={control}
                render={({ field }) => {
                  return (
                    <Switch
                      className={`${item.keyname}_btn`}
                      sx={switchStyles}
                      value={field.value}
                      defaultChecked={!!field.value}
                      onChange={(event, val) => field.onChange(val)}
                    />
                  );
                }}
              />
            </div>
            <InputLayout
              // label={`${item.translations[locale]?.name} ${t('types')}`}
              label={
                item.keyname === 'owner'
                  ? t('Owner_Types')
                  : item.keyname === 'client'
                  ? t('Client_Types')
                  : item.keyname === 'external'
                  ? t('External_Types')
                  : null
              }
              capitalize={false}
              className={classNames('', {
                'opacity-50 pointer-events-none': !watchAll[item.keyname],
              })}
            >
              <Controller
                name={`${item.keyname}-types`}
                control={control}
                render={({ field }) => (
                  <Select
                    disabled={!watchAll[item.keyname]}
                    sx={{
                      '& .MuiSelect-select.MuiSelect-multiple': {
                        textTransform: 'capitalize',
                      },
                    }}
                    multiple
                    className={`h-[56px] ${item.keyname}_types`}
                    labelId="level-label"
                    {...field}
                    value={field.value as number[]}
                  >
                    {choicesToOptions(item.choices, locale).map(
                      (item: selectOptionStringT, index: number) =>
                        item?.value != '' && (
                          <MenuItem
                            key={index}
                            value={item?.id}
                            className={`capitalize option_${item.id}`}
                          >
                            {item.value}
                          </MenuItem>
                        )
                    )}
                  </Select>
                )}
              />
            </InputLayout>
            {item.keyname === 'external' &&
            watchAll['external-types'] &&
            watchAll['external-types'].includes(9) ? (
              <InputLayout
                label={t('Building_constructor_types')}
                className={classNames('pt-4')}
              >
                <Controller
                  name={'constructor-types'}
                  control={control}
                  render={({ field }) => (
                    <Select
                      sx={{
                        '& .MuiSelect-select': {
                          textTransform: 'capitalize',
                        },
                      }}
                      className={'h-[56px]'}
                      labelId="level-label"
                      {...field}
                    >
                      {constructorChoices && constructorChoices.constructor
                        ? choicesToOptions(
                            constructorChoices.constructor,
                            locale
                          ).map(
                            (item: selectOptionStringT, index: number) =>
                              item?.value != '' && (
                                <MenuItem
                                  key={index}
                                  value={item?.id}
                                  className="capitalize"
                                >
                                  {item?.value}
                                </MenuItem>
                              )
                          )
                        : null}
                    </Select>
                  )}
                />
              </InputLayout>
            ) : null}
          </div>
        ))}
      </>
    </NoEditModeFormWrapper>
  );
};

export default ContactTypesForm;
