import React from 'react';
import classNames from 'classnames';
import Menu from '@mui/material/Menu';
import { minTwoDigits } from '../../table/individual';
import { IContactTask } from '../../../../../types/contacts';

interface Props {
  dayEvents: IContactTask[];
  day: number;
  onAddClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onOpenTask: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: IContactTask
  ) => void;
  daySize: number;
}

export const getTime = (date: string) => {
  if (date) {
    const formatDate = new Date(date);

    return `${minTwoDigits(formatDate.getHours())}:${minTwoDigits(
      formatDate.getMinutes()
    )}`;
  }
};

const getColorByType = (type: string) => {
  switch (type.toLowerCase()) {
    case 'phone call':
      return '#3446B9';
      break;
    case 'meeting':
      return '#4CAF50';
      break;
    case 'visit':
      return '#ED6C02';
      break;

    default:
      return '#F50057';
  }
};

const EventItem = (props: {
  item: IContactTask;
  daySize: number;
  onOpenTask: any;
}) => {
  const { item, daySize, onOpenTask } = props;
  return (
    <button
      style={{
        background: getColorByType(item.type?.keyname as string),
        maxWidth: `${daySize - 16}px`,
      }}
      title={item.title}
      className={
        'px-2 py-0.5 bg-green-500 rounded-sm text-white text-left overflow-hidden overflow-ellipsis whitespace-nowrap text-[12px] 2xl:text-[16px]'
      }
      key={item.id}
      onClick={(e) => onOpenTask(e, item)}
    >
      {item.title}
    </button>
  );
};

function CalendarItem(props: Props) {
  const { dayEvents, day, onAddClick, daySize, onOpenTask } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const showMore =
    dayEvents.length > 3 ? (
      <button
        className={'w-full text-right text-[12px] 2xl:text-[16px]'}
        onClick={handleClick}
      >
        + {dayEvents.length - 3} more
      </button>
    ) : null;

  return (
    <div>
      <div className={classNames('mb-6')}>{day}</div>
      {dayEvents.length ? (
        <div className={'flex flex-col gap-1 mb-1'}>
          {dayEvents.map((item: any, index: number) => {
            if (index <= 2) {
              return (
                <EventItem
                  daySize={daySize}
                  item={item}
                  key={item.id}
                  onOpenTask={onOpenTask}
                />
              );
            }
          })}
        </div>
      ) : null}
      {showMore || (
        <button
          onClick={(e) => onAddClick(e)}
          className={
            'add-task px-2 py-0.5 bg-gray-100 w-full rounded-sm text-blue text-center text-[1rem]'
          }
        >
          +
        </button>
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <div className={'flex flex-col gap-1 p-2'}>
          {dayEvents.map((item: any, index: number) => {
            if (index >= 3) {
              return (
                <EventItem
                  daySize={daySize}
                  item={item}
                  key={item.id}
                  onOpenTask={onOpenTask}
                />
              );
            }
          })}
          <button
            onClick={(e) => onAddClick(e)}
            className={
              'mt-4 px-2 py-0.5 bg-gray-100 w-full rounded-sm text-blue text-center text-[1rem]'
            }
          >
            +
          </button>
        </div>
      </Menu>
    </div>
  );
}

export default CalendarItem;
