import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EditorControlProps } from '../../../../types/brochureEditor';
import { UncontrolledColorField } from '../../../../components/form/colorField';
import SwitcherIosFormField from '../../../../components/form/switcherIosFormField';
import EditorSwitcherLayout from '../editorSwitcherLayout';

export const BRAND_COLOR_PROP = '--brand-color';
export const BRAND_COLOR = `var(${BRAND_COLOR_PROP})`;

const ColorControl = ({ value, onChange }: EditorControlProps<string>) => {
  const nonBrandColor = value && value !== BRAND_COLOR ? value : '#1D1D1F';

  const isBrand = value === BRAND_COLOR;

  const [prevColor, setPrevColor] = useState(nonBrandColor);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    if (checked) {
      setPrevColor(nonBrandColor);
      onChange?.(BRAND_COLOR);
    } else {
      onChange?.(prevColor);
    }
  };

  const { t } = useTranslation('brochureEditor');

  return (
    <div className="grid gap-4">
      <UncontrolledColorField
        value={value}
        onChange={onChange}
        label={t('text_color')}
        isSmall
        disabled={isBrand}
        className="bg-white"
      />

      <EditorSwitcherLayout title={t('use_brand_color')}>
        <SwitcherIosFormField checked={isBrand} onChange={handleChange} />
      </EditorSwitcherLayout>
    </div>
  );
};

export default ColorControl;
