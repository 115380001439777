import { ReactNode, FunctionComponent, PropsWithChildren } from 'react';
import {
  Modal as MuiModal,
  Box,
  Fade,
  Stack,
  Typography,
  Button,
  IconButton,
  ButtonProps,
  SxProps,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { InjectedModalState } from './widthModal';
import UserIcon from '../../features/icons/userIcon';
import { Theme } from '@mui/material/styles';
// import ClickAwayListener from '@mui/material/ClickAwayListener';

export type ModalProps = PropsWithChildren &
  InjectedModalState & {
    title: ReactNode | JSX.Element;
    withTitleDivider?: boolean;

    closeIconSize?: 'small' | 'large' | 'inherit' | 'medium' | undefined;
    width?: {
      [key in 'xs' | 'md' | 'lg']?: string | number;
    };

    buttonProps: ButtonProps & {
      title: string;
      isMain?: boolean;
    };
    withButton?: boolean;
    CloseIconStyle?: { [key: string]: any };
    boxClassName?: string;
    sx?: SxProps<Theme>;
  };

const Modal: FunctionComponent<ModalProps> = ({
  title,
  isOpen,
  boxClassName,
  withTitleDivider = true,
  closeIconSize = 'medium',
  openModal,
  closeModal,
  buttonProps,
  width,
  CloseIconStyle,
  children,
  withButton = true,
  sx,
}) => {
  const { title: btnTitle, className, isMain, ...rest } = buttonProps;

  // const onClickAway = () => {
  //   isOpen && closeModal();
  // };
  return (
    <>
      {withButton && (
        <Button
          className={btnTitle.replaceAll(' ', '_')}
          onClick={openModal}
          {...rest}
        >
          {isMain && <UserIcon color="#3446B9" />}
          <span className={'ml-2 ' + className}>{btnTitle}</span>
        </Button>
      )}

      <MuiModal sx={sx} open={isOpen} onClose={closeModal}>
        {/* <ClickAwayListener onClickAway={onClickAway}> */}
        <Fade in={isOpen}>
          <Box
            className={boxClassName}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              maxHeight: '95vh',
              overflowY: 'auto',
              width: {
                xs: '90%',
                md: 600,
                ...width,
              },
              borderRadius: 2,
              bgcolor: 'background.paper',
              boxShadow: 24,
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={4}
              sx={{
                py: 1,
                px: 3,
                borderBottom: withTitleDivider ? '1px solid #eee' : '0px',
              }}
            >
              <Box>
                <Typography className="!text-[26px]">{title}</Typography>
              </Box>

              <IconButton onClick={closeModal}>
                <CloseIcon
                  fontSize={closeIconSize}
                  sx={
                    CloseIconStyle
                      ? CloseIconStyle
                      : {
                          cursor: 'pointer',
                        }
                  }
                />
              </IconButton>
            </Stack>

            <Box sx={{ mt: 1, px: 3, py: 2 }}>{children}</Box>
          </Box>
        </Fade>
        {/* </ClickAwayListener> */}
      </MuiModal>
    </>
  );
};

export default Modal;
