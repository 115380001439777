import { Tooltip } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface Props {
  avatar?: string | null;
  firstname?: string | null;
  lastname?: string | null;
  legalName?: string | null;
  role?: string;
}

const PropertyContactItem = (props: Props) => {
  const { t } = useTranslation('propertiesPage');
  const { firstname, lastname, legalName, role, avatar } = props;
  return (
    <div
      className={classNames(
        'grow shrink basis-0 px-3 py-2 bg-neutral-100 rounded-[100px] justify-start items-center gap-2 flex ml-1',
        {
          '@xs:pl-[12px]': !!avatar,
        }
      )}
    >
      {avatar ? (
        <div className="hidden @xs:block shrink-0 w-[24px] h-[24px] rounded-full overflow-hidden">
          <img src={avatar} alt={`${firstname} ${lastname}`} />
        </div>
      ) : null}
      <div className="flex-col gap-1 justify-center items-start inline-flex whitespace-nowrap overflow-hidden overflow-ellipsis">
        <div className="text-zinc-900 text-sm font-medium leading-none overflow-ellipsis truncate w-[100%] ">
          <Tooltip
            title={
              (firstname ?? '') +
              ' ' +
              (lastname ?? '') +
              ' ' +
              (legalName ?? '')
            }
            arrow
            placement="top"
          >
            <span>
              {firstname} {lastname} {legalName}
            </span>
          </Tooltip>
        </div>

        <div className="text-neutral-500 text-sm font-normal leading-none capitalize">
          {t(`${role}`)}
        </div>
      </div>
    </div>
  );
};

export default PropertyContactItem;
