import { useTranslation } from 'react-i18next';
import { Box, Fade, Modal } from '@mui/material';
import React, { useRef, useState } from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { GridSelectionModel } from '@mui/x-data-grid';
import PopupFormSuccess from '../../../propertiesListing/propertiesMatching/steps/success';
import PopupStepper, { Step } from '../../../../../components/popupStepper';
import SendEmailForm from '../../../../generatedBrochures/sendEmail/form';
import SelectFiles from './selectFilesStep';
import { Document } from '../../../../../types/property';
import useSWR from 'swr';
import { IContact } from '../../../../../types/contacts';

interface Props {
  id?: string;
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  contact: IContact | null;
}

enum Steps {
  FILES,
  PREVIEW,
}

const SendFilesPopup = (props: Props) => {
  const { id, openModal, setOpenModal, contact } = props;
  const { t } = useTranslation();
  const [successMessage, setSuccessMessage] = useState('');
  const { data: documents } = useSWR<Document[]>(
    `/v1/properties/${id}/documents`
  );
  const [rowSelectionFiles, setRowSelectionFiles] =
    useState<GridSelectionModel>([]);
  const [selectedItems, setSelectedItems] = useState<(Document | undefined)[]>(
    []
  );

  const onFilesSelectionHandler = (ids: GridSelectionModel) => {
    let selectedRowsData: (Document | undefined)[] = [];

    if (ids.length) {
      selectedRowsData = ids.map((id) =>
        documents?.find((row) => row.id === id)
      );
    }

    setSelectedItems(selectedRowsData);
    setRowSelectionFiles(ids);
  };

  const formRef = useRef<HTMLFormElement | null>(null);

  function onClose() {
    setOpenModal(false);
    setSuccessMessage('');
  }

  const shouldDisableNext = (step: Step | undefined) => {
    switch (step?.id) {
      case Steps.PREVIEW:
        return false;
        break;
      default:
        return false;
    }
  };

  function handleEmailSent(messageHtml?: string) {
    setSuccessMessage(
      messageHtml || t('propertiesPage:Your_files_was_successfully_sent')
    );
  }

  // @ts-ignore
  const content = successMessage ? (
    <PopupFormSuccess onClose={onClose}>
      <div dangerouslySetInnerHTML={{ __html: successMessage }}></div>
    </PopupFormSuccess>
  ) : (
    <PopupStepper
      onClose={onClose}
      disableNext={shouldDisableNext}
      steps={[
        {
          id: Steps.FILES,
          Icon: PeopleOutlineIcon,
          title: `${t('propertiesPage:files')}`,
          body: (
            <SelectFiles
              items={documents}
              rowSelectionModel={rowSelectionFiles}
              onRowsSelectionHandler={onFilesSelectionHandler}
            />
          ),
        },
        {
          id: Steps.PREVIEW,
          Icon: MailOutlineIcon,
          title: `${t('propertiesPage:Email_preview')}`,
          body: (
            <SendEmailForm
              items={selectedItems.filter(Boolean) as Document[]}
              onEmailSent={handleEmailSent}
              formRef={formRef}
              contact={contact}
              propertyId={id}
            />
          ),
          nextButton: () => {
            const onClick = () => {
              if (formRef && formRef.current) {
                formRef.current.requestSubmit();
              }
            };

            return (
              <div
                onClick={onClick}
                className="h-[40px] rounded flex items-center justify-center p-4 gap-y-2 bg-blue cursor-pointer gap-6 text-white text-[14px] transition-colors duration-100 drop-shadow hover:bg-[rgb(36,49,129)]"
              >
                <span>{t('propertiesPage:Send_email')}</span>
                <ArrowForwardIosIcon
                  sx={{
                    width: '16px',
                    height: '16px',
                  }}
                  fontSize={'small'}
                />
              </div>
            );
          },
        },
      ]}
    />
  );

  return (
    <Modal
      open={openModal}
      onClose={onClose}
      classes={{ root: 'grid place-items-center' }}
    >
      <Fade in={openModal}>
        <Box
          sx={{
            maxHeight: '90vh',
            overflowY: 'auto',
            overflowX: 'hidden',
            width: {
              xs: successMessage ? '40%' : '90%',
            },
            borderRadius: 2,
            bgcolor: 'background.paper',
            boxShadow: 24,
          }}
        >
          {content}
        </Box>
      </Fade>
    </Modal>
  );
};

export default SendFilesPopup;
