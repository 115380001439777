import InputLayout from '../../../../components/form/components/inputLayout';
import { Controller } from 'react-hook-form';
import { Box, Button, MenuItem, Select, Switch } from '@mui/material';
import { AccordionView } from '../../../contacts/creator/individual-view';
import InputFormField from '../../../../components/form/inputFormField';
import InputPassword from '../../../../components/form/inputPassword';
import { selectOptionStringT } from '../../../../components/form/selectFormField';
import CheckIcon from '@mui/icons-material/Check';
import SpinnerLoading from '../../../../features/spinner/spinnerLoading';
import * as React from 'react';
import useSWR from 'swr';
import { Department, HierarchyNode } from '../../../../types/hierarchy';
import { useMemo } from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useTranslation } from 'react-i18next';
import LangSwitcher from '../../../../layout/footer/langSwitcher';
import { useQuery } from '../../../../hooks/useQuery';
import classNames from 'classnames';

const sslOptions = [
  { id: 1, value: 'True' },
  { id: 0, value: 'False' },
];

const statusOptions = [
  { id: 1, value: 'Active' },
  { id: 0, value: 'Inactive' },
];

interface Props {
  isWatchMode?: boolean;
  register: any;
  setValue: any;
  notifyBroker?: boolean | null;
  control: any;
  onCancelClick?: () => void;
  onDeleteClick?: () => void;
  isEditMode?: boolean;
  isLoading: boolean;
  isCreateMode?: boolean;
  departmentsIsList?: boolean;
}

export function normalizeDepartments(
  node?: HierarchyNode
): Department[] | undefined {
  if (!node) return undefined;

  const res: Department[] = [];
  processNode(node);

  function processNode(node: HierarchyNode) {
    if (node.categoryEntity === 'Department') {
      res.push(node);
    } else if (node.children) {
      for (const child of node.children) {
        processNode(child);
      }
    }
  }

  return res;
}

const PortalFormFields = (props: Props) => {
  const {
    isWatchMode,
    register,
    setValue,
    notifyBroker,
    control,
    isEditMode,
    isCreateMode,
    isLoading,
    onCancelClick,
    onDeleteClick,
  } = props;
  const { data: portals } = useSWR(`/portals`);
  const { data: node } = useSWR<HierarchyNode>('/users/hierarchy');
  const departments = useMemo(() => normalizeDepartments(node), [node]);
  const [locale, setLocale] = React.useState<string | null>(null);
  const { t } = useTranslation('common');
  const departmentsOptions = useMemo(() => {
    if (departments) {
      return departments.map((item) => ({
        value: item.name,
        id: item.id,
      }));
    }
    return [];
  }, [departments]);

  React.useEffect(() => {
    setValue('language', locale);
  }, [locale]);
  const query = useQuery();

  let queryLang: string | null = query.get('lang');
  if (queryLang == 'null') {
    queryLang = null;
  } else {
    setValue('language', queryLang);
  }
  return (
    <>
      <div className={'!flex !justify-between  gap-8 2xl:gap-16 py-4 2xl:py-8'}>
        <InputLayout label={t('Portal')} isRequired>
          <Controller
            name="portal"
            control={control}
            render={({ field }) => (
              <Select
                inputProps={{
                  readOnly: isWatchMode,
                  required: true,
                }}
                labelId="level-label"
                {...field}
                className={classNames('h-[56px]', {
                  'opacity-60 pointer-events-none':
                    !isEditMode && !isCreateMode,
                })}
              >
                {portals &&
                  portals.length &&
                  portals.map(
                    (item: { id: string; name: string }, index: number) => (
                      <MenuItem key={index} value={item?.id}>
                        {item.name}
                      </MenuItem>
                    )
                  )}
              </Select>
            )}
          />
        </InputLayout>
        <InputLayout label={t('Status')} isRequired>
          <Controller
            name="status"
            control={control}
            render={({ field }) => (
              <Select
                inputProps={{
                  readOnly: isWatchMode,
                  required: true,
                }}
                labelId="level-label"
                {...field}
                className={classNames('h-[56px]', {
                  'opacity-60 pointer-events-none':
                    !isEditMode && !isCreateMode,
                })}
              >
                {statusOptions.map(
                  (item: selectOptionStringT, index: number) => (
                    <MenuItem key={index} value={item?.id}>
                      {item.value}
                    </MenuItem>
                  )
                )}
              </Select>
            )}
          />
        </InputLayout>
        <div className="w-[25%] items-end">
          <InputLayout
            label={t('common:Language')}
            isRequired
            className="float-right"
          >
            <LangSwitcher
              queryLang={queryLang}
              portalLanguage={true}
              setLanguage={setLocale}
              changeAppLocale={false}
              className={classNames('h-[56px] !w-[200px] !ml-0 ', {
                'opacity-60 pointer-events-none': !isEditMode && !isCreateMode,
              })}
            />
          </InputLayout>
        </div>
      </div>
      <AccordionView
        defaultExpanded
        titleClassName={'text-[1.5rem]'}
        title={t('Agency_information')}
      >
        <div
          className={'grid grid-cols-3 gap-8 2xl:gap-16 py-4 2xl:py-8 border-b'}
        >
          <InputLayout label={t('Name')} isRequired>
            <Controller
              name="departments"
              control={control}
              render={({ field }) => (
                <Select
                  inputProps={{
                    readOnly: isWatchMode,
                    required: true,
                  }}
                  labelId="level-label"
                  multiple
                  {...field}
                  className={classNames('h-[56px]', {
                    'opacity-60 pointer-events-none':
                      !isEditMode && !isCreateMode,
                  })}
                >
                  {departmentsOptions &&
                    departmentsOptions.map(
                      (item: selectOptionStringT, index: number) => (
                        <MenuItem
                          key={index}
                          value={item?.id}
                          className="capitalize"
                        >
                          {item.value}
                        </MenuItem>
                      )
                    )}
                </Select>
              )}
            />
          </InputLayout>
          <InputFormField
            type="text"
            label="ID"
            isRequired
            className={classNames('', {
              'opacity-60 pointer-events-none': !isEditMode && !isCreateMode,
            })}
            inputProps={{
              readOnly: isWatchMode,
            }}
            {...register('agencyId')}
          />
          <InputFormField
            type="email"
            label={t('Email')}
            className={classNames('', {
              'opacity-60 pointer-events-none': !isEditMode && !isCreateMode,
            })}
            inputProps={{
              readOnly: isWatchMode,
            }}
            {...register('agencyEmail')}
          />
        </div>
      </AccordionView>

      <AccordionView
        defaultExpanded
        titleClassName={'text-[1.5rem]'}
        title={t('Communication')}
      >
        <div
          className={'grid grid-cols-3 gap-8 2xl:gap-16 py-4 2xl:py-8 border-b'}
        >
          <InputFormField
            type="text"
            label={t('Contact')}
            className={classNames('', {
              'opacity-60 pointer-events-none': !isEditMode && !isCreateMode,
            })}
            inputProps={{
              readOnly: isWatchMode,
            }}
            {...register('contact')}
          />
          <InputFormField
            type="email"
            label={t('Email_Rent')}
            className={classNames('', {
              'opacity-60 pointer-events-none': !isEditMode && !isCreateMode,
            })}
            inputProps={{
              readOnly: isWatchMode,
            }}
            {...register('emailRent')}
          />
          <InputFormField
            type="text"
            label={t('Telephone_Rent')}
            className={classNames('', {
              'opacity-60 pointer-events-none': !isEditMode && !isCreateMode,
            })}
            inputProps={{
              readOnly: isWatchMode,
            }}
            {...register('telRent')}
          />
          <InputFormField
            type="email"
            label={t('Email_Buy')}
            className={classNames('', {
              'opacity-60 pointer-events-none': !isEditMode && !isCreateMode,
            })}
            inputProps={{
              readOnly: isWatchMode,
            }}
            {...register('emailBuy')}
          />
          <InputFormField
            type="text"
            label={t('Telephone_Buy')}
            className={classNames('', {
              'opacity-60 pointer-events-none': !isEditMode && !isCreateMode,
            })}
            inputProps={{
              readOnly: isWatchMode,
            }}
            {...register('telBuy')}
          />

          <div className="flex justify-between items-center mt-4">
            <p
              className={`${
                !isEditMode && !isCreateMode ? 'opacity-60' : ''
              } font-[Inter] font-medium text-sm`}
            >
              {t('propertiesPage:Notify_agent_by_e_mail')}
            </p>
            <Switch
              className={classNames('', {
                'opacity-60 pointer-events-none': !isEditMode && !isCreateMode,
              })}
              defaultChecked={notifyBroker}
              {...register('notifyBroker')}
            />
          </div>
        </div>
      </AccordionView>

      <AccordionView
        defaultExpanded
        titleClassName={'text-[1.5rem]'}
        title={t('FTP')}
      >
        <div
          className={'grid grid-cols-3 gap-8 2xl:gap-16 py-4 2xl:py-8 border-b'}
        >
          <InputFormField
            isRequired
            type="text"
            label={t('Host_FTP')}
            className={classNames('', {
              'opacity-60 pointer-events-none': !isEditMode && !isCreateMode,
            })}
            inputProps={{
              readOnly: isWatchMode,
              required: true,
            }}
            {...register('ftpHost')}
          />
          <InputFormField
            type="text"
            label={t('Login_FTP')}
            className={classNames('', {
              'opacity-60 pointer-events-none': !isEditMode && !isCreateMode,
            })}
            inputProps={{
              readOnly: isWatchMode,
            }}
            {...register('ftpLogin')}
          />
          <InputLayout label={t('Password_FTP')}>
            <InputPassword
              inputProps={{
                readOnly: isWatchMode,
              }}
              disabled={!isEditMode && !isCreateMode}
              className={classNames('', {
                'opacity-60 pointer-events-none': !isEditMode && !isCreateMode,
              })}
              {...register('ftpPassword')}
            />
          </InputLayout>
          <InputFormField
            type="number"
            label={t('Port_FTP')}
            className={classNames('', {
              'opacity-60 pointer-events-none': !isEditMode && !isCreateMode,
            })}
            inputProps={{
              readOnly: isWatchMode,
            }}
            {...register('ftpPort')}
          />
          <InputLayout label={t('Use_SSL')}>
            <Controller
              name="isSSL"
              control={control}
              render={({ field }) => (
                <Select
                  inputProps={{
                    readOnly: isWatchMode,
                  }}
                  labelId="level-label"
                  {...field}
                  className={classNames('h-[56px]', {
                    'opacity-60 pointer-events-none':
                      !isEditMode && !isCreateMode,
                  })}
                >
                  {sslOptions.map(
                    (item: selectOptionStringT, index: number) => (
                      <MenuItem
                        key={index}
                        value={item?.id}
                        className="capitalize"
                      >
                        {item.value}
                      </MenuItem>
                    )
                  )}
                </Select>
              )}
            />
          </InputLayout>
        </div>
      </AccordionView>
      <AccordionView
        defaultExpanded
        titleClassName={'text-[1.5rem]'}
        title={t('IDX_file')}
      >
        <div
          className={'grid grid-cols-3 gap-8 2xl:gap-16 py-4 2xl:py-8 border-b'}
        >
          <InputFormField
            isRequired
            type="text"
            label={t('Name')}
            className={classNames('', {
              'opacity-60 pointer-events-none': !isEditMode && !isCreateMode,
            })}
            inputProps={{
              readOnly: isWatchMode,
              required: true,
            }}
            {...register('idxFile')}
          />
        </div>
      </AccordionView>
      {!isWatchMode ? (
        <div className={'flex justify-end w-full gap-8'}>
          {isEditMode ? (
            <>
              <Button
                onClick={onCancelClick}
                variant="outlined"
                className="flex justify-center items-center"
                sx={{
                  height: '40px',
                }}
              >
                <Box className="flex justify-center items-center" gap="16px">
                  <div className="font-medium"> {t('cancel')} </div>
                </Box>
              </Button>
              <Button
                onClick={onDeleteClick}
                variant="outlined"
                className="flex justify-center items-center"
                sx={{
                  height: '40px',
                  color: '#F50057',
                }}
              >
                <Box className="flex justify-center items-center" gap="16px">
                  <DeleteOutlineIcon fontSize="small" />
                  <div className="font-medium"> {t('Delete')} </div>
                </Box>
              </Button>
            </>
          ) : null}
          <Button
            // disabled={buttonDisabled}
            type="submit"
            variant="contained"
            disableElevation
            className="flex justify-center items-center"
            sx={{
              background: '#3446B9',
              height: '40px',
            }}
          >
            <Box className="flex justify-center items-center" gap="16px">
              <div className="font-medium"> {t('Save')} </div>
              <CheckIcon
                fontSize="small"
                sx={{
                  width: '16px',
                  height: '14px',
                }}
              />
            </Box>
          </Button>
        </div>
      ) : null}
      {isLoading ? (
        <div className={'absolute inset-0 z-10'}>
          <SpinnerLoading />
        </div>
      ) : null}
    </>
  );
};

export default PortalFormFields;
