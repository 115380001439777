import PropertyDetails from './propertyDetails';
import PropertyGallery from './propertyGallery';
import PropertyFeatures from './features';
import { Box } from '@mui/material';
import { useMemo } from 'react';
import { IPropertyApi } from '../../../../types/property';

function PropertyInfos({ property }: { property: IPropertyApi }) {
  const images = Array.isArray(property.media) ? [] : property.media.images;

  const propertyGalleries = useMemo(() => {
    let propertyGalleriesArray = null;
    propertyGalleriesArray = images;
    if (propertyGalleriesArray) {
      return propertyGalleriesArray.filter(({ type }) =>
        type.includes('image')
      );
    } else {
      return [];
    }
  }, [images]);

  const galleryImgs: string[] = propertyGalleries.map(({ url }) => url?.large);
  const DefaultImage = '/img/default_image.jpg';

  const firstImg: string =
    propertyGalleries.filter((img) => img.isMain === true)[0]?.url?.large ||
    DefaultImage;

  return (
    <Box className="w-full aspect-square flex flex-col gap-8">
      <PropertyDetails property={property} firstImg={firstImg} />
      {galleryImgs.length > 0 && (
        <PropertyGallery propertyGalleries={galleryImgs} />
      )}
      {property.conveniences && (
        <PropertyFeatures conveniences={property.conveniences} />
      )}
    </Box>
  );
}

export default PropertyInfos;
