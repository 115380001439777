import { useForm } from 'react-hook-form';
import InputFormField from '../../../../components/form/inputFormField';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import SearchIcon from '../../../../features/icons/searchIcon';
import React from 'react';
import SettingsBarsIcon from '../../../../features/icons/settingsBarsIcon';
import EraseIcon from '../../../../features/icons/eraseIcon';

interface Props {
  onSubmit: (data: any) => void;
  onReset: () => void;
}

const AgenciesFilter = (props: Props) => {
  const { onSubmit, onReset } = props;
  const { handleSubmit, register, reset, watch } = useForm();
  const { t } = useTranslation();

  const onLocalReset = () => {
    reset();
    onReset();
  };

  return (
    <div className={'bg-[#F5F5F7] rounded'}>
      <div className={'flex items-center justify-between py-4 px-8'}>
        <div className={'flex items-center gap-2'}>
          <SettingsBarsIcon width={'16'} height={'16'} color={'currentColor'} />
          <p className={'font-medium'}>{t('networks:SearchFilter')}</p>
        </div>
        {watch('city') ? (
          <button
            onClick={onLocalReset}
            className={
              'px-4 flex items-center cursor-pointer btn_clear text-[#3446B9]'
            }
          >
            <EraseIcon className="mr-2" color="#3446B9" />
            {t('propertiesPage:clear')}
          </button>
        ) : null}
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={'p-8 grid grid-cols-3'}
      >
        <InputFormField
          {...register(`city`)}
          label={t('common:City')}
          variant="outlined"
          inputProps={{
            sx: {
              backgroundColor: '#fff',
            },
          }}
        />
        <div />
        <div className={'flex justify-end items-end'}>
          <Button
            className=""
            variant="outlined"
            sx={{
              height: '40px',
              border: '1px solid #3446B9 !important',
              minWidth: '128px',
            }}
            type={'submit'}
          >
            <SearchIcon color="#3446B9" className="mr-2" />
            <span> {t('common:search')} </span>
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AgenciesFilter;
