import { Suspense, lazy } from 'react';

import ControlLayout from '../controlLayout';
import { EditorControlProps } from '../../../../types/brochureEditor';

const WysiwygEditor = lazy(
  () => import('../../../../components/form/wysiwygEditor')
);

const RichTextControl = ({
  value,
  onChange,
  label,
}: EditorControlProps<string>) => {
  return (
    <ControlLayout label={label}>
      <Suspense fallback={null}>
        <WysiwygEditor value={value} onChange={onChange} />
      </Suspense>
    </ControlLayout>
  );
};

export default RichTextControl;
