import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
// import classNames from 'classnames';

import TabGridTag from '../../features/tags/tabGridTag';
import {
  getSingleOptionByLanguage,
  optionType,
} from '../../const/propertiesOptions';
import { abbreviate } from '../../helpers/utils';

const PropertyAvatar = ({
  row,
  fullScalePicture = false,
  defaultImage = '/img/default_image.jpg',
  width = '95px',
  height = '58px',
}: {
  row: {
    id: number;
    department?: {
      service: optionType;
    } | null;
  };
  fullScalePicture?: boolean;
  defaultImage?: string | null | undefined;
  width?: string;
  height?: string;
}) => {
  if (!row) {
    return null;
  }
  const fullServiceName = row?.department
    ? getSingleOptionByLanguage(row.department.service)
    : '';
  const splitServiceName = fullServiceName?.split(' ');
  const shortServiceName =
    splitServiceName?.length > 1
      ? abbreviate(splitServiceName)
      : fullServiceName;

  return (
    <Box className="flex items-center relative">
      <Link to={`/properties/${row.id}`}>
        {row.department && (
          <TabGridTag
            textContent={shortServiceName}
            textToolTip={fullServiceName}
            className="absolute bg-blue font-bold -top-1 -left-1 border-0 capitalize"
            color="white"
            fontSize="8px"
          />
        )}
        <img
          // className={classNames({
          //   'w-[95px] h-[58px]': !fullScalePicture,
          //   'w-full h-auto': fullScalePicture,
          // })}
          style={{
            width: fullScalePicture ? '100%' : width,
            height: fullScalePicture ? 'auto' : height,
            objectFit: 'cover',
          }}
          src={defaultImage || undefined}
          alt=""
        />
      </Link>
    </Box>
  );
};

export default PropertyAvatar;
