import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import User from '../types/user';

export type Tokens = {
  token: string;
  refresh_token: string;
};

export type UserPayload = Pick<
  User,
  | 'id'
  | 'firstname'
  | 'lastname'
  | 'phone'
  | 'email'
  | 'roles'
  | 'avatar'
  | 'company'
  | 'agency'
  | 'filial'
  | 'department'
  | 'subscription'
  | 'isSuperAdmin'
  | 'users'
  | 'userDashboard'
>;

export interface AuthState {
  user: UserPayload;
  tokens: Tokens | null;
  update: (user: Partial<UserPayload>) => void;
  logout: () => void;
  login: (tokens: Tokens, user: UserPayload) => void;
  isAdmin: boolean;
  isAssistant: boolean;
  updateIsAdmin: (adminStatus: boolean) => void;
  updateIsAssistant: (assistantStatus: boolean) => void;
}

export const initialState = {
  tokens: null,
  user: {} as UserPayload,
  isAdmin: false,
  isAssistant: false,
};

export const useAuth = create(
  persist<AuthState>(
    (set, get) => ({
      ...initialState,
      update: (user) => {
        set({
          user: { ...get().user, ...user },
        });
      },
      login: (tokens, user) => {
        set({ tokens, user });
      },
      logout: () => {
        set(initialState);
      },
      updateIsAdmin: (adminStatus) => {
        set({ isAdmin: adminStatus });
      },
      updateIsAssistant: (assistantStatus) => {
        set({ isAssistant: assistantStatus });
      },
    }),
    {
      name: '@auth',
      version: 0,
    }
  )
);
