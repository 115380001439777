import { Suspense, lazy, useState } from 'react';
import useSWR from 'swr';

import PipelineSelect from './pipelineSelect';
import { PipelineEntity, PipelineValue } from '../../../types/pipelines';

const LeadManagement = lazy(() => import('./leadManagement'));

function PipelinesStep({
  pipelineValuesEndpoint,
  availablePipelinesEndpoint,
  leadsEndpoint,
  createPipelineValuesEndpoint,
  entity,
}: {
  pipelineValuesEndpoint?: string | null;
  availablePipelinesEndpoint?: string | null;
  leadsEndpoint: string;
  createPipelineValuesEndpoint: string;
  entity?: PipelineEntity;
}) {
  const [pipelineId, setSelectedPipelineId] = useState<number>();

  const { data: pipelineValues, mutate } = useSWR<PipelineValue[]>(
    pipelineValuesEndpoint,
    null,
    {
      shouldRetryOnError: false,
    }
  );
  const preselectedPipeline = pipelineValues?.find(
    (value) => value.pipeline
  )?.pipeline;

  return (
    <div className="pb-4 grid gap-8">
      <div className="max-w-[368px]">
        {preselectedPipeline ? (
          <>{preselectedPipeline.name}</>
        ) : (
          <PipelineSelect
            pipelinesEndpoint={availablePipelinesEndpoint}
            handlePipelineChange={(e) =>
              setSelectedPipelineId(e.target.value as number)
            }
          />
        )}
      </div>

      <div className="h-px bg-gray-200"></div>

      <Suspense fallback={null}>
        <LeadManagement
          selectedPipelineId={preselectedPipeline?.id || pipelineId}
          leadsEndpoint={leadsEndpoint}
          pipelineValuesEndpoint={createPipelineValuesEndpoint}
          entity={entity}
          pipelineValues={pipelineValues}
          mutatePipelineValues={mutate}
        />
      </Suspense>
    </div>
  );
}

export default PipelinesStep;
