import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, InputLabel, Typography } from '@mui/material';

import { toast } from 'react-toastify';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { http } from '../../libs/axios';
import InputPassword from '../../components/form/inputPassword';
import CheckedIcon from '../../features/icons/checkedIcon';
import { editPasswordValidation } from '../../libs/yup/formValidation';

function UpdatePassword() {
  const {
    register,
    handleSubmit,
    reset,
    resetField,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(editPasswordValidation),
  });

  const { t } = useTranslation();

  const onSubmit = async (data: FieldValues) => {
    const { password, newPassword } = data;
    if (password) {
      try {
        const { status, data: user } = await http.post(
          '/users/change-password',
          {
            old_password: password,
            new_password: newPassword,
          }
        );
        if (status === 200) {
          reset();
          toast.success(user.message);
        } else if (status === 403) {
          reset();
          toast.warning(user.message);
        } else {
          toast.warning(user.message);
        }
        // FYI : i tried to type the error as AxiosErrors and unknown it doesn't work so i've let it any for now !!!
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        if (err.response.status === 403) {
          resetField('password');
          toast.error(t('alerts:user.error.reset_password_failed') as string);
        }
      }
    } else {
      setError('password', {});
    }
  };

  const { password, newPassword, confirmPassword } = errors;
  return (
    <Box
      component="form"
      className="flex flex-col gap-20"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { sm: 'column', md: 'row' },
            borderBottom: '1px solid #E6E8EC',
            width: '100%',
            gap: 2,
            padding: { sm: '16px', md: '64px' },
          }}
        >
          <Typography variant="h5" className="lg:max-w-[240px] w-full">
            {t('usersPage:changePassword')}:
          </Typography>
          <Grid container gap={2} className="!mt-8 lg:!mt-2">
            <Grid item xs={12} lg={5}>
              <InputLabel className="!text-xs mb-4">
                {t('usersPage:currentPassword')}
              </InputLabel>
              <InputPassword
                error={Boolean(password)}
                helperText={password && 'password is required.'}
                {...register('password')}
              />
            </Grid>
            <Grid item xs={12} lg={5}></Grid>
            <Grid item xs={12} lg={5}>
              <InputLabel className="!text-xs mb-4">
                {t('usersPage:newPassword')}
              </InputLabel>

              <InputPassword
                error={Boolean(newPassword) || Boolean(confirmPassword)}
                helperText={
                  newPassword &&
                  'new password should contain at least 6 characters.'
                }
                {...register('newPassword')}
              />
            </Grid>
            <Grid item xs={12} lg={5}>
              <InputLabel className="!text-xs mb-4">
                {t('usersPage:confirmPassword')}
              </InputLabel>

              <InputPassword
                error={Boolean(confirmPassword)}
                helperText={
                  confirmPassword && 'please make sure your passwords match.'
                }
                {...register('confirmPassword')}
              />
            </Grid>
          </Grid>
        </Box>
        <Button
          sx={{
            color: '#fff',
            fontSize: '12px',
            lineHeight: '16px',
            alignSelf: 'end',
            marginTop: '32px',
            padding: '12px 24px',
            backgroundColor: '#3446B9',
            '&:hover': {
              backgroundColor: '#3446B9',
            },
          }}
          startIcon={<CheckedIcon color="#fff" />}
          type="submit"
        >
          {t('usersPage:updateInfos')}
        </Button>
      </Box>
    </Box>
  );
}

export default UpdatePassword;
