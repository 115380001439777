import { GridColumns } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import { ILeadItem } from '../../../types/contacts';
import {
  DateCell,
  TypeCell,
  StatusCell,
  ReferenceCell,
  ContactCell,
  SourceCell,
  MessageCell,
} from './leadTableCells';
import useLocale from '../../../hooks/useLocale';

export const useLeadsListColumns = ({
  isLoading,
}: {
  isLoading?: boolean;
}): GridColumns<ILeadItem> => {
  const { t } = useTranslation();
  const { locale } = useLocale();

  const commonSettings = {
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
  };

  const columns: GridColumns<ILeadItem> = [
    {
      field: 'date',
      headerName: t('common:Date'),
      maxWidth: 150,
      renderCell: ({ row }) => (
        <DateCell item={row} wrapperClassName={'h-full pt-4'} />
      ),
    },
    {
      maxWidth: 100,
      field: 'type',
      headerName: t('common:Deal_type'),
      renderCell: ({ row }) => (
        <TypeCell
          item={row}
          locale={locale}
          wrapperClassName={'h-full pt-4 pl-[6px]'}
        />
      ),
    },
    {
      maxWidth: 150,
      field: 'status',
      headerName: t('common:Status'),
      renderCell: ({ row }) => (
        <StatusCell item={row} wrapperClassName={'h-full pt-4 pl-[6px]'} />
      ),
    },
    {
      field: 'reference',
      headerName: t('common:Property_reference'),
      renderCell: ({ row }) => (
        <ReferenceCell
          item={row}
          locale={locale}
          wrapperClassName={'max-w-full h-[100px] py-2'}
        />
      ),
    },
    {
      maxWidth: 250,
      field: 'contact',
      headerName: t('common:Contact'),
      renderCell: ({ row }) => (
        <ContactCell item={row} wrapperClassName={'h-full pt-8'} />
      ),
    },
    {
      maxWidth: 250,
      field: 'source',
      headerName: t('common:Source'),
      renderCell: ({ row }) => (
        <SourceCell item={row} wrapperClassName={'h-full pt-4'} />
      ),
    },
    {
      field: 'message',
      headerName: t('common:Message'),
      renderCell: ({ row }) => (
        <MessageCell item={row} wrapperClassName={'h-full pt-8 px-[4px]'} />
      ),
    },
  ];

  return columns.map((column) => ({
    ...commonSettings,
    ...column,
    ...(isLoading
      ? {
          renderCell: () => (
            <Skeleton
              variant="text"
              animation="wave"
              width="100%"
              height={20}
            />
          ),
        }
      : {}),
  }));
};
