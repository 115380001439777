import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

export default function HelpInterrogationIcon({ color, className }: IconProps) {
  const { mode } = useThemeMode();

  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <circle cx="8" cy="8" r="7" stroke={color}></circle>
      <path
        fill={color}
        d="M7.472 9.75v-.047c.005-.497.057-.893.156-1.187.099-.295.24-.533.422-.715.182-.183.4-.35.656-.504.154-.094.292-.205.414-.332a1.477 1.477 0 00.399-1.012c0-.255-.06-.476-.18-.664a1.206 1.206 0 00-.48-.434 1.457 1.457 0 00-.669-.152c-.213 0-.419.044-.617.133a1.195 1.195 0 00-.496.418c-.133.19-.21.439-.23.746h-.985c.021-.443.136-.822.344-1.137a2.07 2.07 0 01.832-.722c.346-.167.73-.25 1.152-.25.459 0 .857.09 1.196.273.34.182.604.432.789.75.187.318.281.68.281 1.086 0 .286-.044.546-.133.777-.086.232-.21.44-.375.621a2.572 2.572 0 01-.586.485c-.229.143-.412.294-.55.453a1.431 1.431 0 00-.301.559 3.143 3.143 0 00-.102.808v.047h-.937zm.5 2.313a.677.677 0 01-.496-.208.677.677 0 01-.207-.496c0-.192.069-.358.207-.496a.677.677 0 01.496-.207c.192 0 .358.07.496.207a.677.677 0 01.207.496.73.73 0 01-.352.61.67.67 0 01-.351.093z"
      ></path>
    </svg>
  );
}
