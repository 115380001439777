import { Box, Typography, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Modal from '../../../components/modal';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import UserForm from '../addNewUserPage/userForm';
import { useAuth } from '../../../hooks/useAuth';
import { useEffect, useState } from 'react';

type Props = {
  nbUsers?: number;
  itemsPerPage: number;
};

function UsersHeader({ itemsPerPage, nbUsers = 0 }: Props) {
  const { t } = useTranslation('usersPage');
  const [isAssistantModalOpen, setOpenAsistantModal] = useState(false);
  const [isUserModalOpen, setOpenUserModal] = useState(false);
  const [showAddBtn, setBtn] = useState(false);
  const {
    user,
    user: { roles, isSuperAdmin },
  } = useAuth();

  useEffect(() => {
    if (isSuperAdmin || roles.join('').includes('ROLE_ADMIN')) {
      setBtn(true);
    } else {
      setBtn(false);
    }
  }, [user]);

  const handleCloseModal = (isAssistant: boolean) => {
    if (isAssistant) {
      setOpenAsistantModal(false);
    } else {
      setOpenUserModal(false);
    }
  };

  const handleOpenModal = (isAssistant: boolean) => {
    if (isAssistant) {
      setOpenAsistantModal(true);
    } else {
      setOpenUserModal(true);
    }
  };

  return (
    <Stack direction="row" justifyContent="space-between">
      <Box>
        <Typography className="!text-3xl">{t('users')}</Typography>

        <Typography variant="subtitle1" className="text-sm text-gray-400">
          {`${nbUsers} ${t('entries_found')}`}
        </Typography>
      </Box>

      {showAddBtn && (
        <Box className="flex items-center justify-between">
          <Box className="mr-2">
            <Modal
              width={{
                md: 500,
              }}
              title={t('Add_new_assistant')}
              buttonProps={{
                title: t('Add_new_assistant'),
                variant: 'contained',
                startIcon: <AddOutlinedIcon />,
              }}
              closeModal={() => handleCloseModal(true)}
              openModal={() => {
                handleOpenModal(true);
              }}
              isOpen={isAssistantModalOpen}
            >
              <UserForm
                type="add"
                role="assistant"
                closeModal={() => handleCloseModal(true)}
                apiKey={'/users/assistants'}
                itemsPerPage={itemsPerPage}
              />
            </Modal>
          </Box>
          <Box>
            <Modal
              width={{
                md: 500,
              }}
              title={t('Add_new_user')}
              buttonProps={{
                title: t('Add_new_user'),
                variant: 'contained',
                startIcon: <AddOutlinedIcon />,
              }}
              closeModal={() => handleCloseModal(false)}
              openModal={() => {
                handleOpenModal(false);
              }}
              isOpen={isUserModalOpen}
            >
              <UserForm
                type="add"
                role="user"
                closeModal={() => handleCloseModal(false)}
                apiKey={'/users'}
                itemsPerPage={itemsPerPage}
              />
            </Modal>
          </Box>
        </Box>
      )}
    </Stack>
  );
}

export default UsersHeader;
