import React, { useEffect, useMemo, useState } from 'react';
import { GridSelectionModel } from '@mui/x-data-grid';
import { Skeleton } from '@mui/lab';
import { Link } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
} from '@mui/material';
import PermissionIcon from '../../../../../../features/icons/permissionIcon';
import useLocale from '../../../../../../hooks/useLocale';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ContactsListingTable from './listing';
import PropertyFirstImage from '../../../../../../components/tableGridX/propertyFirstImage';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import LoopSharpIcon from '@mui/icons-material/LoopSharp';
import { http } from '../../../../../../libs/axios';
import { toast } from 'react-toastify';
import { IMatching, IMercureMatching } from '../../../../../../types/matching';
import classNames from 'classnames';
import useMercure from '../../../../../../hooks/useMercure';
import { useTranslation } from 'react-i18next';
import { ApiListing } from '../../../../../../types/api';
import { IContact } from '../../../../../../types/contacts';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { formatRemainingTime } from '../../matchingStatus';

type Props = {
  mutate: any;
  rowSelectionUsers: GridSelectionModel;
  onRowsSelectionHandler: (rowSelectionModel: GridRowSelectionModel) => void;
  matching?: IMatching | null;
  onRegenerateMatching?: (id?: number, remove?: boolean) => void;
  singleMatching?: boolean;
  onContactsUpdate?: (value: number) => void;
  page: number;
  setPage: (value: number) => void;
  filter: number | null;
  setFilter: (value: number | null) => void;
  contacts: ApiListing<IContact>;
};

export const calculatePercentage = (num1: number, num2: number) => {
  return Math.round((num1 / num2) * 100);
};

const StatisticItem = (props: {
  title: string;
  value: number;
  persentage?: number;
  total?: number;
}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(
      props.total ? calculatePercentage(props.value, props.total) : 100
    );
  }, []);

  return (
    <div className="h-[100px] px-8 py-4 bg-white flex-col justify-start items-start gap-4 inline-flex">
      <div className="self-stretch h-12 flex-col justify-start items-start gap-1 flex">
        <div className="self-stretch text-zinc-900 text-sm font-medium leading-none">
          {props.title}
        </div>
        <div className="self-stretch text-blue-800 text-2xl font-normal leading-7">
          {props.value}
          {props.persentage ? (
            <span className={'ml-1'}>({props.persentage}%)</span>
          ) : null}
        </div>
      </div>
      <div
        style={{ width: `${progress}%`, maxWidth: '100%' }}
        className="self-stretch h-1 bg-blue-800 rounded-[300px]"
      />
    </div>
  );
};

const eventTypes = ['message'];

const UsersStep = ({
  mutate,
  matching,
  rowSelectionUsers,
  onRowsSelectionHandler,
  onRegenerateMatching,
  onContactsUpdate,
  contacts,
  page,
  setPage,
  filter,
  setFilter,
}: Props) => {
  const [requestedUpdate, setRequestedUpdate] = useState(false);
  const [requestedUpdateDone, setRequestedUpdateDone] = useState(false);
  const { t } = useTranslation('propertiesPage');

  const { data } = useMercure<IMercureMatching>({
    subscribeUrl: 'matching',
    eventTypes,
  });

  useEffect(() => {
    if (data && data.matching === info?.id && data.status === 'completed') {
      setRequestedUpdate(false);
      setRequestedUpdateDone(true);
      mutate();

      if (onRegenerateMatching) {
        onRegenerateMatching(info?.id, true);
      }
    }
  }, [data]);

  const info = useMemo(() => {
    if (matching) {
      return matching;
    }
  }, [matching]);

  const { locale } = useLocale();

  const regenerateMatching = () => {
    http
      .get(
        `/v1/properties/${info?.property.id}/contacts/matching?regenerate=true`
      )
      .then(() => {
        toast.success(`${t('Your_matching_is_re_launched')}`, {
          position: 'top-right',
          autoClose: 3000,
        });
        setRequestedUpdate(true);
        if (onRegenerateMatching) {
          onRegenerateMatching(info?.id);
        }
      })
      .catch(() => {
        toast.error(
          `${t(
            'There_is_been_an_server_error_Search_criteria_was_not_generated'
          )}`
        );
      });
  };

  const onFilterChange = (value: number | null) => {
    setFilter(value);
    setPage(1);
  };

  useEffect(() => {
    if (contacts && onContactsUpdate) {
      onContactsUpdate(contacts['hydra:totalItems']);
    }
  }, [contacts]);

  return (
    <div className={'min-h-[400px] flex flex-col gap-8'}>
      <div className={'flex w-full justify-between items-start'}>
        <div className="w-full max-w-[406px] flex-col justify-start items-start gap-4 inline-flex">
          <div className="text-zinc-900 text-base font-normal leading-none">
            {t('Selected_matching')}
          </div>
          <div className="w-full bg-gray-50 rounded border border-blue-800 justify-start items-start gap-2 inline-flex">
            <div className="max-w-[145px] p-2 justify-center items-center gap-2.5 flex shrink-0">
              {info && info.property ? (
                <PropertyFirstImage row={info.property} />
              ) : (
                <Skeleton variant={'rectangular'} height={68} width={95} />
              )}
            </div>
            <div className={'flex flex-col w-full pl-2 pr-4 py-4'}>
              {info && info.property ? (
                <>
                  {info?.property?.translations?.[locale]?.title ? (
                    <Link
                      className={'text-base inline-flex'}
                      to={`/properties/${info.property.id}`}
                      id={`img_property_${info.property.id}`}
                    >
                      <p className={'font-medium whitespace-normal'}>
                        {info?.property?.translations?.[locale]?.title}
                      </p>
                    </Link>
                  ) : null}
                </>
              ) : (
                <Skeleton />
              )}
              <Box className="w-3/5 flex items-center py-2 gap-2">
                {info && info.property ? (
                  <div
                    className="flex py-1 px-2 rounded-md items-center"
                    style={{ border: '1px solid #3446B9 ' }}
                  >
                    <PermissionIcon
                      className="mr-2 col-span-1"
                      color="#3446B9"
                    />{' '}
                    <span className="text-blue leading-5">
                      {info.property.reference}
                    </span>
                  </div>
                ) : (
                  <Skeleton
                    className={'flex-grow'}
                    variant={'rounded'}
                    height={30}
                  />
                )}
              </Box>
              {info && info.spentTime ? (
                <div
                  className={classNames(
                    'pt-2 self-stretch justify-start items-center gap-1 inline-flex'
                  )}
                >
                  <AccessTimeIcon fontSize={'small'} color={'inherit'} />
                  <div className="text-[14px] font-medium leading-none flex flex-wrap gap-1">
                    <span>{formatRemainingTime(info.spentTime, t)}</span>
                    <span className={''}> {t('propertiesPage:spent')}</span>
                  </div>
                </div>
              ) : (
                <Skeleton
                  sx={{
                    maxWidth: '90px',
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div className={'flex flex-col gap-2 items-end'}>
          {requestedUpdate ? (
            <div className="opacity-80 text-blue-800 px-4 py-3 bg-white rounded border border-blue-800 justify-center items-center gap-4 inline-flex">
              <LoopSharpIcon
                sx={{
                  width: '22px',
                  height: '22px',
                }}
                fontSize={'small'}
              />
              <div className="text-sm font-medium leading-2">
                {t('You_launched_an_update_for_this_matching_list')} <br />
                {t('Data_may_change')}
              </div>
            </div>
          ) : null}
          {!requestedUpdate && requestedUpdateDone ? (
            <div className="opacity-80 text-[#4CAF50] px-4 py-3 bg-white rounded border border-[#4CAF50] justify-center items-center gap-4 inline-flex">
              <LoopSharpIcon
                sx={{
                  width: '22px',
                  height: '22px',
                }}
                fontSize={'small'}
              />
              <div className="text-sm font-medium leading-2">
                {t('Update_is_ready')}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="w-full h-px bg-gray-200" />
      <Accordion
        key={'statistics'}
        className={'w-full'}
        defaultExpanded={false}
        sx={{
          boxShadow: 'none',
          margin: '0',
          '&': {
            width: '100%',
            backgroundColor: '#FBFBFD',
          },
          '&::before': {
            display: 'none',
          },
          '&.Mui-expanded': {
            margin: '0',
          },
          '&.Mui-disabled': {
            backgroundColor: 'inherit',
          },
        }}
      >
        <AccordionSummary
          className={`g_contact`}
          expandIcon={<KeyboardArrowDownIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            padding: '1rem',

            '& .MuiAccordionSummary-content': {
              margin: '0',
              alignItems: 'center',
            },
            '& .MuiAccordionSummary-content.Mui-expanded': {
              margin: '0',
              alignItems: 'center',
            },
          }}
        >
          <p className={''}>{t('Statistics')}</p>
        </AccordionSummary>
        <AccordionDetails
          style={{
            boxShadow: 'none',
          }}
        >
          {info && info.statistics ? (
            <div className={'grid grid-cols-6 gap-1'}>
              <StatisticItem
                title={t('Email_sent')}
                value={info.statistics.emailsSent}
              />
              <StatisticItem
                title={t('Opened_emails')}
                value={info.statistics.emailsOpened}
                persentage={info.statistics.percentageOpened}
                total={info.statistics.emailsSent}
              />
              <StatisticItem
                title={t('Delivered_emails')}
                value={info.statistics.emailsDelivered}
                total={info.statistics.emailsSent}
              />
              <StatisticItem
                title={t('Visited_emails')}
                value={info.statistics.emailVisits}
                total={info.statistics.emailsSent}
              />
              <StatisticItem
                title={t('leads')}
                value={info.statistics.leads}
                total={info.statistics.emailsSent}
              />
              <StatisticItem
                title={t('Visits')}
                value={info.statistics.totalVisits}
                total={info.statistics.emailsSent}
              />
            </div>
          ) : null}
        </AccordionDetails>
      </Accordion>
      <div className="w-full h-px bg-gray-200" />
      <>
        <div className="w-full flex-col justify-start items-start gap-4 inline-flex">
          <div className="self-stretch justify-between items-end inline-flex">
            <div className="text-zinc-900 text-2xl font-normal leading-7">
              {t('usersPage:users')}{' '}
              {contacts && contacts['hydra:totalItems']
                ? `(${contacts['hydra:totalItems']})`
                : ''}
            </div>
            <Button
              className="cursor-pointer gap-2"
              type={'submit'}
              variant={'outlined'}
              disabled={requestedUpdate}
              onClick={regenerateMatching}
              sx={{
                height: '50px',
                border: '1px solid #3446B9 !important',
              }}
            >
              <LoopSharpIcon
                sx={{
                  width: '22px',
                  height: '22px',
                }}
                fontSize={'small'}
              />
              <span> {t('Regenerate_matching_list')} </span>
            </Button>
          </div>
          <div className="w-full justify-start items-start gap-2 inline-flex">
            <button
              onClick={() => onFilterChange(null)}
              className={classNames(
                'px-4 py-2 bg-gray-50 rounded justify-center items-center gap-4 flex',
                {
                  'bg-neutral-100': filter === null,
                }
              )}
            >
              <div
                className={classNames('text-sm font-normal leading-none', {
                  'text-blue-800': filter === null,
                })}
              >
                {t('All')}{' '}
              </div>
            </button>
            <button
              onClick={() => onFilterChange(1)}
              className={classNames(
                'px-4 py-2 bg-gray-50 rounded justify-center items-center gap-4 flex',
                {
                  'bg-neutral-100': filter === 1,
                }
              )}
            >
              <div
                className={classNames('text-sm font-normal leading-none', {
                  'text-blue-800': filter === 1,
                })}
              >
                {t('Sent_emails_only')}
              </div>
            </button>
            <button
              onClick={() => onFilterChange(0)}
              className={classNames(
                'px-4 py-2 bg-gray-50 rounded justify-center items-center gap-4 flex',
                {
                  'bg-neutral-100': filter === 0,
                }
              )}
            >
              <div
                className={classNames('text-sm font-normal leading-none', {
                  'text-blue-800': filter === 0,
                })}
              >
                {t('Not_sent_emails_only')}
              </div>
            </button>
          </div>
        </div>
        <ContactsListingTable
          contacts={contacts}
          rowSelectionModel={rowSelectionUsers}
          onRowsSelectionHandler={onRowsSelectionHandler}
          page={page}
          setPage={setPage}
        />
      </>
    </div>
  );
};

export default UsersStep;
