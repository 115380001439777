import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { IconButton } from '@mui/material';

import { http } from '../../../libs/axios';
import ScatterPlot from '@mui/icons-material/ScatterPlot';

function GenerateWithAiButton<T>({
  url,
  onGenerated,
}: {
  url?: string;
  onGenerated?: (data: T) => unknown;
}) {
  const { t } = useTranslation('propertiesPage');

  const [isGenerating, setIsGenerating] = useState(false);

  async function generate() {
    if (!url) return;
    setIsGenerating(true);
    try {
      const { data } = await http.get<T>(url);
      onGenerated?.(data);
    } catch (error) {
      const msg = t('sorry_generation_not_available');
      toast.error(msg);
    }

    setIsGenerating(false);
  }

  return (
    <IconButton size="small" aria-label={t('Generate')} onClick={generate}>
      {isGenerating ? (
        <ScatterPlot className="animate-spin" />
      ) : (
        <AutoAwesomeIcon />
      )}
    </IconButton>
  );
}

export default GenerateWithAiButton;
