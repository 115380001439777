export interface Icountry {
  id: number;
  alpha2: string;
  de: string;
  en: string;
  fr: string;
}

export const countries: Icountry[] = [
  {
    id: 4,
    alpha2: 'AF',
    de: 'Afghanistan',
    en: 'Afghanistan',
    fr: 'Afghanistan',
  },
  {
    id: 8,
    alpha2: 'AL',
    de: 'Albanien',
    en: 'Albania',
    fr: 'Albanie',
  },
  {
    id: 12,
    alpha2: 'DZ',
    de: 'Algerien',
    en: 'Algeria',
    fr: 'Algérie',
  },
  {
    id: 20,
    alpha2: 'AD',
    de: 'Andorra',
    en: 'Andorra',
    fr: 'Andorre',
  },
  {
    id: 24,
    alpha2: 'AO',
    de: 'Angola',
    en: 'Angola',
    fr: 'Angola',
  },
  {
    id: 28,
    alpha2: 'AF',
    de: 'Antigua und Barbuda',
    en: 'Antigua and Barbuda',
    fr: 'Antigua-et-Barbuda',
  },
  {
    id: 32,
    alpha2: 'AR',
    de: 'Argentinien',
    en: 'Argentina',
    fr: 'Argentine',
  },
  {
    id: 51,
    alpha2: 'AM',
    de: 'Armenien',
    en: 'Armenia',
    fr: 'Arménie',
  },
  {
    id: 36,
    alpha2: 'AU',
    de: 'Australien',
    en: 'Australia',
    fr: 'Australie',
  },
  {
    id: 40,
    alpha2: 'AT',
    de: 'Österreich',
    en: 'Austria',
    fr: 'Autriche',
  },
  {
    id: 31,
    alpha2: 'AZ',
    de: 'Aserbaidschan',
    en: 'Azerbaijan',
    fr: 'Azerbaïdjan',
  },
  {
    id: 44,
    alpha2: 'BS',
    de: 'Bahamas',
    en: 'Bahamas',
    fr: 'Bahamas',
  },
  {
    id: 48,
    alpha2: 'BH',
    de: 'Bahrain',
    en: 'Bahrain',
    fr: 'Bahreïn',
  },
  {
    id: 50,
    alpha2: 'BD',
    de: 'Bangladesch',
    en: 'Bangladesh',
    fr: 'Bangladesh',
  },
  {
    id: 52,
    alpha2: 'BB',
    de: 'Barbados',
    en: 'Barbados',
    fr: 'Barbade',
  },
  {
    id: 112,
    alpha2: 'BY',
    de: 'Belarus',
    en: 'Belarus',
    fr: 'Biélorussie',
  },
  {
    id: 56,
    alpha2: 'BE',
    de: 'Belgien',
    en: 'Belgium',
    fr: 'Belgique',
  },
  {
    id: 84,
    alpha2: 'BZ',
    de: 'Belize',
    en: 'Belize',
    fr: 'Belize',
  },
  {
    id: 204,
    alpha2: 'BJ',
    de: 'Benin',
    en: 'Benin',
    fr: 'Bénin',
  },
  {
    id: 64,
    alpha2: 'BT',
    de: 'Bhutan',
    en: 'Bhutan',
    fr: 'Bhoutan',
  },
  {
    id: 68,
    alpha2: 'BO',
    de: 'Bolivien',
    en: 'Bolivia (Plurinational State of)',
    fr: 'Bolivie',
  },
  {
    id: 70,
    alpha2: 'BA',
    de: 'Bosnien und Herzegowina',
    en: 'Bosnia and Herzegovina',
    fr: 'Bosnie-Herzégovine',
  },
  {
    id: 72,
    alpha2: 'BW',
    de: 'Botswana',
    en: 'Botswana',
    fr: 'Botswana',
  },
  {
    id: 76,
    alpha2: 'BR',
    de: 'Brasilien',
    en: 'Brazil',
    fr: 'Brésil',
  },
  {
    id: 96,
    alpha2: 'BN',
    de: 'Brunei',
    en: 'Brunei Darussalam',
    fr: 'Brunei',
  },
  {
    id: 100,
    alpha2: 'BG',
    de: 'Bulgarien',
    en: 'Bulgaria',
    fr: 'Bulgarie',
  },
  {
    id: 854,
    alpha2: 'BF',
    de: 'Burkina Faso',
    en: 'Burkina Faso',
    fr: 'Burkina Faso',
  },
  {
    id: 108,
    alpha2: 'BI',
    de: 'Burundi',
    en: 'Burundi',
    fr: 'Burundi',
  },
  {
    id: 116,
    alpha2: 'KH',
    de: 'Kambodscha',
    en: 'Cambodia',
    fr: 'Cambodge',
  },
  {
    id: 120,
    alpha2: 'CM',
    de: 'Kamerun',
    en: 'Cameroon',
    fr: 'Cameroun',
  },
  {
    id: 124,
    alpha2: 'CA',
    de: 'Kanada',
    en: 'Canada',
    fr: 'Canada',
  },
  {
    id: 132,
    alpha2: 'CV',
    de: 'Kap Verde',
    en: 'Cabo Verde',
    fr: 'Cap-Vert',
  },
  {
    id: 140,
    alpha2: 'CF',
    de: 'Zentralafrikanische Republik',
    en: 'Central African Republic',
    fr: 'République centrafricaine',
  },
  {
    id: 148,
    alpha2: 'TD',
    de: 'Tschad',
    en: 'Chad',
    fr: 'Tchad',
  },
  {
    id: 152,
    alpha2: 'CL',
    de: 'Chile',
    en: 'Chile',
    fr: 'Chili',
  },
  {
    id: 156,
    alpha2: 'CN',
    de: 'Volksrepublik China',
    en: 'China',
    fr: 'Chine',
  },
  {
    id: 170,
    alpha2: 'CO',
    de: 'Kolumbien',
    en: 'Colombia',
    fr: 'Colombie',
  },
  {
    id: 174,
    alpha2: 'KM',
    de: 'Komoren',
    en: 'Comoros',
    fr: 'Comores',
  },
  {
    id: 178,
    alpha2: 'CG',
    de: 'Kongo, Republik',
    en: 'Congo',
    fr: 'République du Congo',
  },
  {
    id: 180,
    alpha2: 'CD',
    de: 'Kongo, Demokratische Republik',
    en: 'Congo, Democratic Republic of the',
    fr: 'République démocratique du Congo',
  },
  {
    id: 188,
    alpha2: 'CR',
    de: 'Costa Rica',
    en: 'Costa Rica',
    fr: 'Costa Rica',
  },
  {
    id: 384,
    alpha2: 'CI',
    de: 'Elfenbeinküste',
    en: "Côte d'Ivoire",
    fr: "Côte d'Ivoire",
  },
  {
    id: 191,
    alpha2: 'HR',
    de: 'Kroatien',
    en: 'Croatia',
    fr: 'Croatie',
  },
  {
    id: 192,
    alpha2: 'CU',
    de: 'Kuba',
    en: 'Cuba',
    fr: 'Cuba',
  },
  {
    id: 196,
    alpha2: 'CY',
    de: 'Zypern',
    en: 'Cyprus',
    fr: 'Chypre',
  },
  {
    id: 203,
    alpha2: 'CZ',
    de: 'Tschechien',
    en: 'Czechia',
    fr: 'Tchéquie',
  },
  {
    id: 208,
    alpha2: 'DK',
    de: 'Dänemark',
    en: 'Denmark',
    fr: 'Danemark',
  },
  {
    id: 262,
    alpha2: 'DJ',
    de: 'Dschibuti',
    en: 'Djibouti',
    fr: 'Djibouti',
  },
  {
    id: 212,
    alpha2: 'DM',
    de: 'Dominica',
    en: 'Dominica',
    fr: 'Dominique',
  },
  {
    id: 214,
    alpha2: 'DO',
    de: 'Dominikanische Republik',
    en: 'Dominican Republic',
    fr: 'République dominicaine',
  },
  {
    id: 218,
    alpha2: 'EC',
    de: 'Ecuador',
    en: 'Ecuador',
    fr: 'Équateur',
  },
  {
    id: 818,
    alpha2: 'EG',
    de: 'Ägypten',
    en: 'Egypt',
    fr: 'Égypte',
  },
  {
    id: 222,
    alpha2: 'SV',
    de: 'El Salvador',
    en: 'El Salvador',
    fr: 'Salvador',
  },
  {
    id: 226,
    alpha2: 'GQ',
    de: 'Äquatorialguinea',
    en: 'Equatorial Guinea',
    fr: 'Guinée équatoriale',
  },
  {
    id: 232,
    alpha2: 'ER',
    de: 'Eritrea',
    en: 'Eritrea',
    fr: 'Érythrée',
  },
  {
    id: 233,
    alpha2: 'EE',
    de: 'Estland',
    en: 'Estonia',
    fr: 'Estonie',
  },
  {
    id: 231,
    alpha2: 'ET',
    de: 'Äthiopien',
    en: 'Ethiopia',
    fr: 'Éthiopie',
  },
  {
    id: 242,
    alpha2: 'FJ',
    de: 'Fidschi',
    en: 'Fiji',
    fr: 'Fidji',
  },
  {
    id: 246,
    alpha2: 'FI',
    de: 'Finnland',
    en: 'Finland',
    fr: 'Finlande',
  },
  {
    id: 250,
    alpha2: 'FR',
    de: 'Frankreich',
    en: 'France',
    fr: 'France',
  },
  {
    id: 266,
    alpha2: 'GA',
    de: 'Gabun',
    en: 'Gabon',
    fr: 'Gabon',
  },
  {
    id: 270,
    alpha2: 'GM',
    de: 'Gambia',
    en: 'Gambia',
    fr: 'Gambie',
  },
  {
    id: 268,
    alpha2: 'GE',
    de: 'Georgien',
    en: 'Georgia',
    fr: 'Géorgie',
  },
  {
    id: 276,
    alpha2: 'DE',
    de: 'Deutschland',
    en: 'Germany',
    fr: 'Allemagne',
  },
  {
    id: 288,
    alpha2: 'GH',
    de: 'Ghana',
    en: 'Ghana',
    fr: 'Ghana',
  },
  {
    id: 300,
    alpha2: 'GR',
    de: 'Griechenland',
    en: 'Greece',
    fr: 'Grèce',
  },
  {
    id: 308,
    alpha2: 'GD',
    de: 'Grenada',
    en: 'Grenada',
    fr: 'Grenade',
  },
  {
    id: 320,
    alpha2: 'GT',
    de: 'Guatemala',
    en: 'Guatemala',
    fr: 'Guatemala',
  },
  {
    id: 324,
    alpha2: 'GN',
    de: 'Guinea',
    en: 'Guinea',
    fr: 'Guinée',
  },
  {
    id: 624,
    alpha2: 'GW',
    de: 'Guinea-Bissau',
    en: 'Guinea-Bissau',
    fr: 'Guinée-Bissau',
  },
  {
    id: 328,
    alpha2: 'GY',
    de: 'Guyana',
    en: 'Guyana',
    fr: 'Guyana',
  },
  {
    id: 332,
    alpha2: 'HT',
    de: 'Haiti',
    en: 'Haiti',
    fr: 'Haïti',
  },
  {
    id: 340,
    alpha2: 'HN',
    de: 'Honduras',
    en: 'Honduras',
    fr: 'Honduras',
  },
  {
    id: 348,
    alpha2: 'HU',
    de: 'Ungarn',
    en: 'Hungary',
    fr: 'Hongrie',
  },
  {
    id: 352,
    alpha2: 'IS',
    de: 'Island',
    en: 'Iceland',
    fr: 'Islande',
  },
  {
    id: 356,
    alpha2: 'IN',
    de: 'Indien',
    en: 'India',
    fr: 'Inde',
  },
  {
    id: 360,
    alpha2: 'ID',
    de: 'Indonesien',
    en: 'Indonesia',
    fr: 'Indonésie',
  },
  {
    id: 364,
    alpha2: 'IR',
    de: 'Iran',
    en: 'Iran (Islamic Republic of)',
    fr: 'Iran',
  },
  {
    id: 368,
    alpha2: 'IQ',
    de: 'Irak',
    en: 'Iraq',
    fr: 'Irak',
  },
  {
    id: 372,
    alpha2: 'IE',
    de: 'Irland',
    en: 'Ireland',
    fr: 'Irlande',
  },
  {
    id: 376,
    alpha2: 'IL',
    de: 'Israel',
    en: 'Israel',
    fr: 'Israël',
  },
  {
    id: 380,
    alpha2: 'IT',
    de: 'Italien',
    en: 'Italy',
    fr: 'Italie',
  },
  {
    id: 388,
    alpha2: 'JM',
    de: 'Jamaika',
    en: 'Jamaica',
    fr: 'Jamaïque',
  },
  {
    id: 392,
    alpha2: 'JP',
    de: 'Japan',
    en: 'Japan',
    fr: 'Japon',
  },
  {
    id: 400,
    alpha2: 'JO',
    de: 'Jordanien',
    en: 'Jordan',
    fr: 'Jordanie',
  },
  {
    id: 398,
    alpha2: 'KZ',
    de: 'Kasachstan',
    en: 'Kazakhstan',
    fr: 'Kazakhstan',
  },
  {
    id: 404,
    alpha2: 'KE',
    de: 'Kenia',
    en: 'Kenya',
    fr: 'Kenya',
  },
  {
    id: 296,
    alpha2: 'KI',
    de: 'Kiribati',
    en: 'Kiribati',
    fr: 'Kiribati',
  },
  {
    id: 408,
    alpha2: 'KP',
    de: 'Korea, Nord (Nordkorea)',
    en: "Korea (Democratic People's Republic of)",
    fr: 'Corée du Nord',
  },
  {
    id: 410,
    alpha2: 'KR',
    de: 'Korea, Süd (Südkorea)',
    en: 'Korea, Republic of',
    fr: 'Corée du Sud',
  },
  {
    id: 414,
    alpha2: 'KW',
    de: 'Kuwait',
    en: 'Kuwait',
    fr: 'Koweït',
  },
  {
    id: 417,
    alpha2: 'KG',
    de: 'Kirgisistan',
    en: 'Kyrgyzstan',
    fr: 'Kirghizistan',
  },
  {
    id: 418,
    alpha2: 'LA',
    de: 'Laos',
    en: "Lao People's Democratic Republic",
    fr: 'Laos',
  },
  {
    id: 428,
    alpha2: 'LV',
    de: 'Lettland',
    en: 'Latvia',
    fr: 'Lettonie',
  },
  {
    id: 422,
    alpha2: 'LB',
    de: 'Libanon',
    en: 'Lebanon',
    fr: 'Liban',
  },
  {
    id: 426,
    alpha2: 'LS',
    de: 'Lesotho',
    en: 'Lesotho',
    fr: 'Lesotho',
  },
  {
    id: 430,
    alpha2: 'LR',
    de: 'Liberia',
    en: 'Liberia',
    fr: 'Liberia',
  },
  {
    id: 434,
    alpha2: 'LY',
    de: 'Libyen',
    en: 'Libya',
    fr: 'Libye',
  },
  {
    id: 438,
    alpha2: 'LI',
    de: 'Liechtenstein',
    en: 'Liechtenstein',
    fr: 'Liechtenstein',
  },
  {
    id: 440,
    alpha2: 'LT',
    de: 'Litauen',
    en: 'Lithuania',
    fr: 'Lituanie',
  },
  {
    id: 442,
    alpha2: 'LU',
    de: 'Luxemburg',
    en: 'Luxembourg',
    fr: 'Luxembourg',
  },
  {
    id: 807,
    alpha2: 'MK',
    de: 'Nordmazedonien',
    en: 'North Macedonia',
    fr: 'Macédoine du Nord',
  },
  {
    id: 450,
    alpha2: 'MG',
    de: 'Madagaskar',
    en: 'Madagascar',
    fr: 'Madagascar',
  },
  {
    id: 454,
    alpha2: 'MW',
    de: 'Malawi',
    en: 'Malawi',
    fr: 'Malawi',
  },
  {
    id: 458,
    alpha2: 'MY',
    de: 'Malaysia',
    en: 'Malaysia',
    fr: 'Malaisie',
  },
  {
    id: 462,
    alpha2: 'MV',
    de: 'Malediven',
    en: 'Maldives',
    fr: 'Maldives',
  },
  {
    id: 466,
    alpha2: 'ML',
    de: 'Mali',
    en: 'Mali',
    fr: 'Mali',
  },
  {
    id: 470,
    alpha2: 'MT',
    de: 'Malta',
    en: 'Malta',
    fr: 'Malte',
  },
  {
    id: 584,
    alpha2: 'MH',
    de: 'Marshallinseln',
    en: 'Marshall Islands',
    fr: 'Îles Marshall',
  },
  {
    id: 478,
    alpha2: 'MR',
    de: 'Mauretanien',
    en: 'Mauritania',
    fr: 'Mauritanie',
  },
  {
    id: 480,
    alpha2: 'MU',
    de: 'Mauritius',
    en: 'Mauritius',
    fr: 'Maurice',
  },
  {
    id: 484,
    alpha2: 'MX',
    de: 'Mexiko',
    en: 'Mexico',
    fr: 'Mexique',
  },
  {
    id: 583,
    alpha2: 'FM',
    de: 'Mikronesien',
    en: 'Micronesia (Federated States of)',
    fr: 'États fédérés de Micronésie',
  },
  {
    id: 504,
    alpha2: 'MA',
    de: 'Marokko',
    en: 'Morocco',
    fr: 'Maroc',
  },
  {
    id: 498,
    alpha2: 'MD',
    de: 'Moldau',
    en: 'Moldova, Republic of',
    fr: 'Moldavie',
  },
  {
    id: 492,
    alpha2: 'MC',
    de: 'Monaco',
    en: 'Monaco',
    fr: 'Monaco',
  },
  {
    id: 496,
    alpha2: 'MN',
    de: 'Mongolei',
    en: 'Mongolia',
    fr: 'Mongolie',
  },
  {
    id: 499,
    alpha2: 'ME',
    de: 'Montenegro',
    en: 'Montenegro',
    fr: 'Monténégro',
  },
  {
    id: 508,
    alpha2: 'MZ',
    de: 'Mosambik',
    en: 'Mozambique',
    fr: 'Mozambique',
  },
  {
    id: 104,
    alpha2: 'MM',
    de: 'Myanmar',
    en: 'Myanmar',
    fr: 'Birmanie',
  },
  {
    id: 516,
    alpha2: 'NA',
    de: 'Namibia',
    en: 'Namibia',
    fr: 'Namibie',
  },
  {
    id: 520,
    alpha2: 'NR',
    de: 'Nauru',
    en: 'Nauru',
    fr: 'Nauru',
  },
  {
    id: 524,
    alpha2: 'NP',
    de: 'Nepal',
    en: 'Nepal',
    fr: 'Népal',
  },
  {
    id: 528,
    alpha2: 'NL',
    de: 'Niederlande',
    en: 'Netherlands',
    fr: 'Pays-Bas',
  },
  {
    id: 554,
    alpha2: 'NZ',
    de: 'Neuseeland',
    en: 'New Zealand',
    fr: 'Nouvelle-Zélande',
  },
  {
    id: 558,
    alpha2: 'NI',
    de: 'Nicaragua',
    en: 'Nicaragua',
    fr: 'Nicaragua',
  },
  {
    id: 562,
    alpha2: 'NE',
    de: 'Niger',
    en: 'Niger',
    fr: 'Niger',
  },
  {
    id: 566,
    alpha2: 'NG',
    de: 'Nigeria',
    en: 'Nigeria',
    fr: 'Nigeria',
  },
  {
    id: 578,
    alpha2: 'NO',
    de: 'Norwegen',
    en: 'Norway',
    fr: 'Norvège',
  },
  {
    id: 512,
    alpha2: 'OM',
    de: 'Oman',
    en: 'Oman',
    fr: 'Oman',
  },
  {
    id: 586,
    alpha2: 'PK',
    de: 'Pakistan',
    en: 'Pakistan',
    fr: 'Pakistan',
  },
  {
    id: 585,
    alpha2: 'PW',
    de: 'Palau',
    en: 'Palau',
    fr: 'Palaos',
  },
  {
    id: 591,
    alpha2: 'PA',
    de: 'Panama',
    en: 'Panama',
    fr: 'Panama',
  },
  {
    id: 598,
    alpha2: 'PG',
    de: 'Papua-Neuguinea',
    en: 'Papua New Guinea',
    fr: 'Papouasie-Nouvelle-Guinée',
  },
  {
    id: 600,
    alpha2: 'PY',
    de: 'Paraguay',
    en: 'Paraguay',
    fr: 'Paraguay',
  },
  {
    id: 604,
    alpha2: 'PE',
    de: 'Peru',
    en: 'Peru',
    fr: 'Pérou',
  },
  {
    id: 608,
    alpha2: 'PH',
    de: 'Philippinen',
    en: 'Philippines',
    fr: 'Philippines',
  },
  {
    id: 616,
    alpha2: 'PL',
    de: 'Polen',
    en: 'Poland',
    fr: 'Pologne',
  },
  {
    id: 620,
    alpha2: 'PT',
    de: 'Portugal',
    en: 'Portugal',
    fr: 'Portugal',
  },
  {
    id: 634,
    alpha2: 'QA',
    de: 'Katar',
    en: 'Qatar',
    fr: 'Qatar',
  },
  {
    id: 642,
    alpha2: 'RO',
    de: 'Rumänien',
    en: 'Romania',
    fr: 'Roumanie',
  },
  {
    id: 643,
    alpha2: 'RU',
    de: 'Russland',
    en: 'Russian Federation',
    fr: 'Russie',
  },
  {
    id: 646,
    alpha2: 'RW',
    de: 'Ruanda',
    en: 'Rwanda',
    fr: 'Rwanda',
  },
  {
    id: 659,
    alpha2: 'KN',
    de: 'St. Kitts und Nevis',
    en: 'Saint Kitts and Nevis',
    fr: 'Saint-Christophe-et-Niévès',
  },
  {
    id: 662,
    alpha2: 'LC',
    de: 'St. Lucia',
    en: 'Saint Lucia',
    fr: 'Sainte-Lucie',
  },
  {
    id: 670,
    alpha2: 'VC',
    de: 'St. Vincent und die Grenadinen',
    en: 'Saint Vincent and the Grenadines',
    fr: 'Saint-Vincent-et-les-Grenadines',
  },
  {
    id: 882,
    alpha2: 'WS',
    de: 'Samoa',
    en: 'Samoa',
    fr: 'Samoa',
  },
  {
    id: 674,
    alpha2: 'SM',
    de: 'San Marino',
    en: 'San Marino',
    fr: 'Saint-Marin',
  },
  {
    id: 678,
    alpha2: 'ST',
    de: 'São Tomé und Príncipe',
    en: 'Sao Tome and Principe',
    fr: 'Sao Tomé-et-Principe',
  },
  {
    id: 682,
    alpha2: 'SA',
    de: 'Saudi-Arabien',
    en: 'Saudi Arabia',
    fr: 'Arabie saoudite',
  },
  {
    id: 686,
    alpha2: 'SN',
    de: 'Senegal',
    en: 'Senegal',
    fr: 'Sénégal',
  },
  {
    id: 688,
    alpha2: 'RS',
    de: 'Serbien',
    en: 'Serbia',
    fr: 'Serbie',
  },
  {
    id: 690,
    alpha2: 'SC',
    de: 'Seychellen',
    en: 'Seychelles',
    fr: 'Seychelles',
  },
  {
    id: 694,
    alpha2: 'SL',
    de: 'Sierra Leone',
    en: 'Sierra Leone',
    fr: 'Sierra Leone',
  },
  {
    id: 702,
    alpha2: 'SG',
    de: 'Singapur',
    en: 'Singapore',
    fr: 'Singapour',
  },
  {
    id: 703,
    alpha2: 'SK',
    de: 'Slowakei',
    en: 'Slovakia',
    fr: 'Slovaquie',
  },
  {
    id: 705,
    alpha2: 'SI',
    de: 'Slowenien',
    en: 'Slovenia',
    fr: 'Slovénie',
  },
  {
    id: 90,
    alpha2: 'SB',
    de: 'Salomonen',
    en: 'Solomon Islands',
    fr: 'Îles Salomon',
  },
  {
    id: 706,
    alpha2: 'SO',
    de: 'Somalia',
    en: 'Somalia',
    fr: 'Somalie',
  },
  {
    id: 710,
    alpha2: 'ZA',
    de: 'Südafrika',
    en: 'South Africa',
    fr: 'Afrique du Sud',
  },
  {
    id: 728,
    alpha2: 'SS',
    de: 'Südsudan',
    en: 'South Sudan',
    fr: 'Soudan du Sud',
  },
  {
    id: 724,
    alpha2: 'ES',
    de: 'Spanien',
    en: 'Spain',
    fr: 'Espagne',
  },
  {
    id: 144,
    alpha2: 'LK',
    de: 'Sri Lanka',
    en: 'Sri Lanka',
    fr: 'Sri Lanka',
  },
  {
    id: 729,
    alpha2: 'SD',
    de: 'Sudan',
    en: 'Sudan',
    fr: 'Soudan',
  },
  {
    id: 740,
    alpha2: 'SR',
    de: 'Suriname',
    en: 'Suriname',
    fr: 'Suriname',
  },
  {
    id: 748,
    alpha2: 'SZ',
    de: 'Eswatini',
    en: 'Eswatini',
    fr: 'Eswatini',
  },
  {
    id: 752,
    alpha2: 'SE',
    de: 'Schweden',
    en: 'Sweden',
    fr: 'Suède',
  },
  {
    id: 756,
    alpha2: 'CH',
    de: 'Schweiz',
    en: 'Switzerland',
    fr: 'Suisse',
  },
  {
    id: 760,
    alpha2: 'SY',
    de: 'Syrien',
    en: 'Syrian Arab Republic',
    fr: 'Syrie',
  },
  {
    id: 762,
    alpha2: 'TJ',
    de: 'Tadschikistan',
    en: 'Tajikistan',
    fr: 'Tadjikistan',
  },
  {
    id: 834,
    alpha2: 'TZ',
    de: 'Tansania',
    en: 'Tanzania',
    fr: 'Tanzanie',
  },
  {
    id: 764,
    alpha2: 'TH',
    de: 'Thailand',
    en: 'Thailand',
    fr: 'Thaïlande',
  },
  {
    id: 626,
    alpha2: 'TL',
    de: 'Osttimor',
    en: 'Timor-Leste',
    fr: 'Timor oriental',
  },
  {
    id: 768,
    alpha2: 'TG',
    de: 'Togo',
    en: 'Togo',
    fr: 'Togo',
  },
  {
    id: 776,
    alpha2: 'TO',
    de: 'Tonga',
    en: 'Tonga',
    fr: 'Tonga',
  },
  {
    id: 780,
    alpha2: 'TT',
    de: 'Trinidad und Tobago',
    en: 'Trinidad and Tobago',
    fr: 'Trinité-et-Tobago',
  },
  {
    id: 788,
    alpha2: 'TN',
    de: 'Tunesien',
    en: 'Tunisia',
    fr: 'Tunisie',
  },
  {
    id: 792,
    alpha2: 'TR',
    de: 'Türkei',
    en: 'Türkiye',
    fr: 'Turquie',
  },
  {
    id: 795,
    alpha2: 'TM',
    de: 'Turkmenistan',
    en: 'Turkmenistan',
    fr: 'Turkménistan',
  },
  {
    id: 798,
    alpha2: 'TV',
    de: 'Tuvalu',
    en: 'Tuvalu',
    fr: 'Tuvalu',
  },
  {
    id: 800,
    alpha2: 'UG',
    de: 'Uganda',
    en: 'Uganda',
    fr: 'Ouganda',
  },
  {
    id: 804,
    alpha2: 'UA',
    de: 'Ukraine',
    en: 'Ukraine',
    fr: 'Ukraine',
  },
  {
    id: 784,
    alpha2: 'AE',
    de: 'Vereinigte Arabische Emirate',
    en: 'United Arab Emirates',
    fr: 'Émirats arabes unis',
  },
  {
    id: 826,
    alpha2: 'GB',
    de: 'Vereinigtes Königreich',
    en: 'United Kingdom',
    fr: 'Royaume-Uni',
  },
  {
    id: 840,
    alpha2: 'US',
    de: 'Vereinigte Staaten',
    en: 'United States of America',
    fr: 'États-Unis',
  },
  {
    id: 858,
    alpha2: 'UY',
    de: 'Uruguay',
    en: 'Uruguay',
    fr: 'Uruguay',
  },
  {
    id: 860,
    alpha2: 'UZ',
    de: 'Usbekistan',
    en: 'Uzbekistan',
    fr: 'Ouzbékistan',
  },
  {
    id: 548,
    alpha2: 'VU',
    de: 'Vanuatu',
    en: 'Vanuatu',
    fr: 'Vanuatu',
  },
  {
    id: 862,
    alpha2: 'VE',
    de: 'Venezuela',
    en: 'Venezuela (Bolivarian Republic of)',
    fr: 'Venezuela',
  },
  {
    id: 704,
    alpha2: 'VN',
    de: 'Vietnam',
    en: 'Viet Nam',
    fr: 'Viêt Nam',
  },
  {
    id: 887,
    alpha2: 'YE',
    de: 'Jemen',
    en: 'Yemen',
    fr: 'Yémen',
  },
  {
    id: 894,
    alpha2: 'ZM',
    de: 'Sambia',
    en: 'Zambia',
    fr: 'Zambie',
  },
  {
    id: 716,
    alpha2: 'ZW',
    de: 'Simbabwe',
    en: 'Zimbabwe',
    fr: 'Zimbabwe',
  },
];
