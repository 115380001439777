import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { http } from '../../../libs/axios';
import { mutate } from 'swr';
import { useTranslation } from 'react-i18next';
interface Props {
  rowId: number;
}
const NotificationMenu = ({ rowId }: Props) => {
  const { t } = useTranslation('notificationsPage');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleReadability = async () => {
    await http
      .patch('/personal_notifications/' + rowId, { isRead: true })
      .then(() => handleClose());
    mutate('/personal_notifications?isDeleted=false', null);
  };
  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        ...
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleReadability}>{t('Mark_as_read')}</MenuItem>
      </Menu>
    </div>
  );
};
export default NotificationMenu;
