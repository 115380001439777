import React, { useMemo, useRef, useState } from 'react';
import { Box, Fade, Modal } from '@mui/material';
import { GridSelectionModel } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

// icons
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// steps
import PopupStepper, { Step } from '../../../../components/popupStepper';
import UsersStep from './steps/users/index';
import SendEmailForm from './steps/email';
import PopupFormSuccess from './steps/success';
import useSWR from 'swr';
import { IMatching } from '../../../../types/matching';

type Props = {
  matchingId: number;
  isModalOpen: boolean;
  handleCloseModal: () => void;
};

enum Steps {
  USERS,
  EMAIL,
}

const SinglePropertyMatchingModal = ({
  matchingId,
  isModalOpen,
  handleCloseModal,
}: Props) => {
  const [successMessage, setSuccessMessage] = useState('');
  const formRef = useRef<HTMLFormElement>();
  const { t } = useTranslation('propertiesPage');
  const [rowSelectionUsers, setRowSelectionUser] = useState<GridSelectionModel>(
    []
  );
  const [downloadAllContacts, setDownloadAllContacts] = useState(false);
  const [contactsFilter, setContactsFilter] = useState<null | number>(null);
  const [contactsPage, setContactsPage] = useState<number>(1);

  const { data: contacts, mutate } = useSWR(
    `/matchings/${matchingId}/contacts?page=${contactsPage}&itemsPerPage=${10}${
      contactsFilter !== null ? `&sent=${contactsFilter}` : ''
    }`
  );

  const { data: matching } = useSWR(`/matchings/${matchingId}`, {
    revalidateOnMount: true,
    refreshWhenHidden: true,
  });

  const rows = useMemo(() => {
    let returnedValue: IMatching[] = [];
    if (matching) {
      returnedValue = [matching];
    }
    return returnedValue;
  }, []);

  const onRowsUsersSelectionHandler = (ids: GridSelectionModel) => {
    setRowSelectionUser(ids);
    setDownloadAllContacts(ids.length === 10 && matching.contactsMatched > 10);
  };

  const shouldDisableNext = (step: Step | undefined) => {
    switch (step?.id) {
      case Steps.USERS:
        return !rowSelectionUsers.length;
        break;
      default:
        return false;
    }
  };

  function handleEmailSent(messageHtml?: string) {
    setSuccessMessage(messageHtml || 'Success');
  }

  function onClose() {
    setRowSelectionUser([]);
    setSuccessMessage('');
    handleCloseModal();
  }

  const content = successMessage ? (
    <PopupFormSuccess onClose={onClose}>
      <div dangerouslySetInnerHTML={{ __html: successMessage }}></div>
    </PopupFormSuccess>
  ) : (
    <PopupStepper
      onClose={onClose}
      disableNext={shouldDisableNext}
      onBack={(step) => {
        if (step?.id === Steps.USERS) {
          setRowSelectionUser([]);
        }
      }}
      steps={[
        {
          id: Steps.USERS,
          Icon: PeopleOutlineIcon,
          title: `${t('usersPage:users')}`,
          body: (
            <UsersStep
              contacts={contacts}
              onRowsSelectionHandler={onRowsUsersSelectionHandler}
              rowSelectionUsers={rowSelectionUsers}
              page={contactsPage}
              setPage={setContactsPage}
              filter={contactsFilter}
              setFilter={setContactsFilter}
              matching={matching}
              singleMatching
              mutate={mutate}
            />
          ),
        },
        {
          id: Steps.EMAIL,
          Icon: MailOutlineIcon,
          title: `${t('Email_preview')}`,
          body: (
            <SendEmailForm
              rows={rows}
              rowSelectionUsers={rowSelectionUsers}
              rowSelectionProperty={[matchingId] as GridSelectionModel}
              onEmailSent={handleEmailSent}
              formRef={formRef}
              downloadAllContacts={downloadAllContacts}
              contactsMatched={contacts ? contacts['hydra:totalItems'] : 10}
              contactsFilter={contactsFilter}
            />
          ),
          nextButton: () => {
            const onClick = () => {
              if (formRef && formRef.current) {
                formRef.current.requestSubmit();
              }
            };

            return (
              <div
                onClick={onClick}
                className="h-[40px] rounded flex items-center justify-center p-4 gap-y-2 bg-blue cursor-pointer gap-6 text-white text-[14px] transition-colors duration-100 drop-shadow hover:bg-[rgb(36,49,129)]"
              >
                <span>{t('Send_email')}</span>
                <ArrowForwardIosIcon
                  sx={{
                    width: '16px',
                    height: '16px',
                  }}
                  fontSize={'small'}
                />
              </div>
            );
          },
        },
      ]}
    />
  );

  return (
    <>
      <Modal
        open={isModalOpen}
        onClose={onClose}
        classes={{ root: 'grid place-items-center' }}
      >
        <Fade in={isModalOpen}>
          <Box
            sx={{
              maxHeight: '90vh',
              overflowY: 'auto',
              overflowX: 'hidden',
              width: {
                xs: successMessage ? '40%' : '90%',
              },
              borderRadius: 2,
              bgcolor: 'background.paper',
              boxShadow: 24,
            }}
          >
            {content}
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default SinglePropertyMatchingModal;
