import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

export default function UpdateIcon({ color, className }: IconProps) {
  const { mode } = useThemeMode();

  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_42_8040)">
        <path
          stroke={color}
          d="M8 15c3.639 0 6.588-3.134 6.588-7 0-1.907-.718-3.636-1.882-4.899M8 1C4.361 1 1.412 4.134 1.412 8c0 2.152.914 4.078 2.353 5.362m0 0V9.5m0 3.862L0 12.5m12.706-9.399L12.236 7m.47-3.899L16 4"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_42_8040">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}
