import useSWR from 'swr';

import { IContact, IContactHobby } from '../../../../types/contacts';

import AdditionalInformationForm from '../../components/forms/additional-information-form';
import SpinnerLoading from '../../../../features/spinner/spinnerLoading';

export interface ContactTapInnerProps {
  data: IContact;
  id: string;
  mutate: any;
}

const AdditionalInformationTab = (props: ContactTapInnerProps) => {
  const { data, id, mutate } = props;

  const { data: selectedHobbiesOnServer, mutate: mutateHobbies } = useSWR<
    IContactHobby[]
  >(`/contact_hobbies/${id}`);
  const selectedHobbyIds = selectedHobbiesOnServer?.map((hobby) => hobby.id);

  return data && selectedHobbyIds ? (
    <AdditionalInformationForm
      id={id}
      data={data}
      mutate={mutate}
      selectedHobbyIds={selectedHobbyIds}
      mutateHobbies={mutateHobbies}
    />
  ) : (
    <div className={'col-start-1 col-end-4 min-h-[500px]'}>
      <SpinnerLoading />
    </div>
  );
};

export default AdditionalInformationTab;
