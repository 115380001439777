import { uniqBy } from 'lodash';

import { IPromotion } from '../../../../../types/promotion';
import {
  IPropertyApi,
  PropertyElasticItem,
  PropertyListingItem,
} from '../../../../../types/property';
import { ICompany } from '../../../../../types/hierarchy/legacy';

import { AgencyWithProperties } from './promotionDesigner/components/propertiesList';
import { getBrokersList } from '../../../../properties/stepper/steps/generalInformation';
import { AutocompleteOption } from '../../../../../components/form/autoCompleteField';
import { findTreeNode } from '../../../../agencies/tree/utils';

export interface Row extends IPropertyApi {
  [key: string]: any;
}

export function collectDepartmentsFromProperties(
  properties: PropertyElasticItem[] | undefined
): Record<string, AgencyWithProperties> | null {
  if (!properties) return null;
  const res: Record<string, AgencyWithProperties> = {};
  for (const property of properties) {
    const { department } = property;
    if (res[department.name]) {
      res[department.name]?.properties.push(property.id);
    } else {
      res[department.name] = {
        id: department.name,
        title: department.name || '',
        subtitle: '',
        countryCode: '',
        properties: [property.id],
      };
    }
  }
  return res;
}

export const collectUsersFromAgencies = (
  agenciesDept: any,
  agencyHierarchy: ICompany
) => {
  const allAgentsCollected: AutocompleteOption[] = [];
  let agentPerDept: AutocompleteOption[] = [];
  if (agencyHierarchy) {
    for (const agency of agenciesDept) {
      const brokerDept = findTreeNode(agencyHierarchy, agency, 'Department');
      const deptUsers: AutocompleteOption[] = getBrokersList(brokerDept);
      allAgentsCollected.push(...deptUsers);
    }
    agentPerDept = uniqBy(allAgentsCollected, (option) => option.id);
  }
  return agentPerDept;
};

export function prepareSearchQuery(
  defaultValues: Partial<IPromotion> | undefined
) {
  const searchParams: Record<string, string> = {
    itemsPerPage: '1000',
  };
  if (defaultValues?.departments?.length) {
    searchParams.departments = `[${defaultValues?.departments?.join(',')}]`;
  }
  return new URLSearchParams(searchParams).toString();
}

export const formatUserOption = ({ id, firstname, lastname }: any) => ({
  id,
  value: `${firstname} ${lastname}`,
});

export const createPayload = (
  row: Row,
  name: string,
  updatedValue?: { id: number }
) => {
  if (name && row[name]) {
    const payload: Partial<PropertyListingItem> = {
      [name]: row[name],
    };

    if (updatedValue && updatedValue.id) {
      // @ts-ignore
      payload[name] = updatedValue.id.toString();
    }

    if (name === 'owners' && updatedValue && Array.isArray(updatedValue)) {
      // @ts-ignore
      payload.owners = updatedValue.map((el: any) => {
        if (el) {
          return {
            contact: el.contact ? el.contact.id.toString() : el.id.toString(),
            mainContact: el.contact ? el.contact.mainContact : false,
          };
        }
      });
    }

    return payload;
  } else if (name === 'pricing') {
    const payload: Partial<PropertyListingItem> = {};

    if (row && row.pricingRent && row.pricingRent?.displayedPrice) {
      payload.pricingRent = {
        displayedPrice: row.pricingRent?.displayedPrice,
      };
    }

    if (row && row.pricingBuy && row.pricingBuy?.displayedPrice) {
      payload.pricingBuy = {
        displayedPrice: row.pricingBuy?.displayedPrice,
      };
    }

    return payload;
  } else if (name === 'pricem2') {
    const payload: Partial<PropertyListingItem> = {};

    if (row && row.pricingRent && row.pricingRent?.pricem2) {
      payload.pricingRent = {
        pricem2: row.pricingRent?.pricem2,
      };
    }

    if (row && row.pricingBuy && row.pricingBuy?.pricem2) {
      payload.pricingBuy = {
        pricem2: row.pricingBuy?.pricem2,
      };
    }

    return payload;
  }

  return {};
};
