import classNames from 'classnames';
import { ReactNode } from 'react';

const formats: Record<string, string> = {
  A4: 'aspect-[210/297]',
};

const Sheet = ({
  format = 'A4',
  children,
}: {
  format?: string;
  children?: ReactNode;
}) => {
  const formatClass = formats[format];

  return (
    <div
      className={classNames(
        'bg-white flex relative overflow-hidden',
        formatClass
      )}
    >
      {children}
    </div>
  );
};

export default Sheet;
