import useLocale from '../../../../hooks/useLocale';
import {
  Page as PageType,
  PageTypes,
  REPEATABLE_AREA,
} from '../../../../types/brochureEditor';
import { usePagesEditor } from '../../lib/hooks/usePagesEditor';
import { usePreviewSubject } from '../../lib/hooks/usePreviewSubject';
import { generateProps as generateDescriptionProps } from '../widgets/universal/multipageDescription';
import { generateProps as generateGalleryProps } from '../widgets/universal/multipageGallery';

import SinglePage from './singlePage';

function Page({ page }: { page?: PageType | null }) {
  const { previewSubject } = usePreviewSubject();
  const { locale } = useLocale();
  const { overrides } = usePagesEditor();
  const isRepeatable = page?.type === PageTypes.REPEATABLE;
  if (isRepeatable) {
    const repeatableWidgetType = page?.widgets?.[REPEATABLE_AREA]?.type;
    const widgetOverrides =
      overrides[previewSubject?.id || '']?.[page?.id || '']?.widgets?.[
        REPEATABLE_AREA
      ]?.options;
    const widgetOptions = {
      ...page?.widgets?.[REPEATABLE_AREA]?.options,
      ...widgetOverrides,
    };

    const gen = pickGenerator(repeatableWidgetType);
    if (gen) {
      const ctx = { page, locale, previewSubject, widgetOptions };
      const pages = gen(ctx);
      if (!pages.length) {
        return <SinglePage page={page} multipageProps={{}} />;
      }
      return (
        <>
          {pages?.map((props, index) => (
            <SinglePage key={index} page={page} multipageProps={props} />
          ))}
        </>
      );
    }
  }

  return <SinglePage page={page} />;
}

export default Page;

function pickGenerator(widgetType?: string) {
  if (widgetType === 'multipageGallery') {
    return generateGalleryProps;
  }
  if (widgetType === 'multipageDescription') {
    return generateDescriptionProps;
  }
  return null;
}
