import { Link } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { ReactNode } from 'react';

function ListingHeader({
  title,
  itemsCountText,
  newLinkUrl,
  newLinkText,
  newLinkClassName,
  children,
}: {
  title?: string;
  itemsCountText?: string;
  newLinkUrl?: string;
  newLinkText?: string;
  newLinkClassName?: string;
  children?: ReactNode;
}) {
  return (
    <Box className="flex gap-4 w-full max-lg:flex-col pb-6 max-lg:pb-4">
      <Box className="flex flex-col mr-auto">
        <Typography variant="h3" className="text-3xl mb-2">
          {title}
        </Typography>

        {itemsCountText ? (
          <Typography variant="subtitle1" className="text-sm text-gray-400">
            {itemsCountText}
          </Typography>
        ) : null}
      </Box>

      {children}

      <Box className="py-4 hidden lg:flex">
        {newLinkUrl && (
          <Link to={newLinkUrl}>
            <Button
              className={newLinkClassName}
              variant="contained"
              sx={{ background: 'background.default' }}
              startIcon={<AddIcon fontSize="medium" />}
              disableElevation
            >
              {newLinkText}
            </Button>
          </Link>
        )}
      </Box>
    </Box>
  );
}

export default ListingHeader;
