import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ListingHeader from '../../components/tableGridX/listingHeader';
import TableGridX from '../../components/tableGridX';
import useColumns from './useColumns';
import { Document, IPropertyApi } from '../../types/property';
import SelectPdfTemplate from '../../components/common/selectPdfTemplate';
import BackLink from '../../components/common/backLink';
import EditPropertyButton from '../properties/singleProperty/editPropertyButton';
import { PreviewSubjectTypes } from '../../types/brochureEditor';
import DownloadBrochureButton from './downloadBrochureButton';
import SendBrochureButton from './sendBrochureButton';
import { useState } from 'react';
import { GridSelectionModel } from '@mui/x-data-grid';
import useSWR from 'swr';
import { generateInternal } from '../brochureTemplates/lib/hooks/useGenerate';
import useLocale from '../../hooks/useLocale';
import PropertyTags from '../../components/common/propertyTags';

const skeletonItems = Array(6)
  .fill('')
  .map((_, index) => ({ id: index }));

const GeneratedBrochures = () => {
  const { t } = useTranslation('propertiesPage');
  const [page, setPage] = useState(0);
  const { propertyId, promotionId } = useParams();
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridSelectionModel>([]);
  const [selectedItems, setSelectedItems] = useState<(Document | undefined)[]>(
    []
  );

  const params = new URLSearchParams();
  if (propertyId) {
    params.append('property', propertyId);
  } else if (promotionId) {
    params.append('promotion', promotionId);
  }
  const { data: property } = useSWR<IPropertyApi>(
    propertyId ? `/v1/properties/${propertyId}` : null
  );
  const { data: items, mutate } = useSWR<Document[]>(
    `/v1/properties/${propertyId}/documents`
  );

  const { locale } = useLocale();
  const [regeneratedId, setRegeneratedId] = useState<number>();
  async function regenerate(templateId: number) {
    setRegeneratedId(templateId);
    try {
      await generateInternal({
        templateId: String(templateId),
        propertyId,
        promotionId,
        locale,
      });
      mutate();
    } catch (e) {
      console.error(e);
    }
    setRegeneratedId(undefined);
  }

  const columns = useColumns({
    isLoading: !items,
    onMainChange: mutate,
    regenerate,
    regeneratedId,
  });

  const totalCount = items?.length;
  const rows = items ? items : skeletonItems;

  let backLink = '';
  let backLabel = '';
  let subjectType = null;
  let subjectId;
  if (propertyId) {
    backLink = `/properties/${propertyId}`;
    backLabel = 'propertiesPage:property_details';
    subjectType = PreviewSubjectTypes.PROPERTY;
    subjectId = propertyId;
  } else if (promotionId) {
    backLink = `/promotions/${promotionId}`;
    backLabel = 'promotionPage:promotionDetails';
    subjectType = PreviewSubjectTypes.PROMOTION;
    subjectId = promotionId;
  }

  const onRowsSelectionHandler = (ids: GridSelectionModel) => {
    let selectedRowsData: (Document | undefined)[] = [];

    if (ids.length) {
      selectedRowsData = ids.map((id) => items?.find((row) => row.id === id));
    }

    setSelectedItems(selectedRowsData);
    setRowSelectionModel(ids);
  };

  return (
    <>
      <div>
        <BackLink url={backLink} label={backLabel} />
      </div>

      <ListingHeader
        title={t('common:brochures_and_files')}
        itemsCountText={
          totalCount ? `${totalCount} ${t('files', { count: totalCount })}` : ''
        }
      >
        <div className="grow flex max-xl:flex-col justify-between gap-4">
          <div className="xl:pt-1">
            {property && <PropertyTags property={property} />}
          </div>

          <div className="flex flex-col gap-4 xl:items-end">
            <div className="flex max-xl:flex-col xl:items-center gap-4">
              <SelectPdfTemplate
                subjectId={subjectId}
                subjectType={subjectType}
              />
              <DownloadBrochureButton
                propertyId={propertyId}
                promotionId={promotionId}
                mutate={mutate}
              />
              {propertyId && <EditPropertyButton propertyId={propertyId} />}{' '}
            </div>
            <SendBrochureButton
              id={propertyId}
              rowSelection={selectedItems}
              onRowsSelectionHandler={onRowsSelectionHandler}
            />
          </div>
        </div>
      </ListingHeader>

      <TableGridX
        key={String(!!items)}
        checkboxSelection
        onSelectionModelChange={(newRowSelectionModel: GridSelectionModel) =>
          onRowsSelectionHandler(newRowSelectionModel)
        }
        selectionModel={rowSelectionModel}
        rows={rows}
        // @ts-ignore
        columns={columns}
        autoPageSize
        pageSize={10}
        page={page - 1}
        rowCount={totalCount}
        onPageChange={(page: number) => {
          setPage(page + 1);
        }}
        rowHeight={64}
      />
    </>
  );
};

export default GeneratedBrochures;
