import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

function UsefulAreaIcon({ color, className }: IconProps) {
  const { mode } = useThemeMode();
  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        d="M4 .5H.5V4M15.5 4V.5H12M12 15.5h3.5V12M.5 12v3.5H4M3.5 3.5l9 9m-9-9v3m0-3h3m6 9H10m2.5 0V10M12.5 3.5l-9 9m9-9v3m0-3h-3m-6 9H6m-2.5 0V10"
      ></path>
    </svg>
  );
}

export default UsefulAreaIcon;
