import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SettingsIcon from '@mui/icons-material/Settings';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';

import { http } from '../../../libs/axios';
import useLocale from '../../../hooks/useLocale';

import HashControlledModal from '../../../components/modal/hashControlledModal';
import PopupStepper from '../../../components/popupStepper';
import Select from '../../../components/popupStepper/controls/select';
import LanguageRadioButtons from '../../../components/popupStepper/controls/languageRadioButtons';
import SpinnerLoading from '../../../features/spinner/spinnerLoading';
import PromotionLandingSelectBroker from './selectBroker';

export enum LandingTemplates {
  SLIDER = 'slider',
  BACKGROUND = 'background',
}

function PromotionLandingPopup({
  hash,
  promotion,
}: {
  hash: string;
  promotion: {
    id: number;
    reference: string;
  };
}) {
  const { t } = useTranslation('common');
  const { locale } = useLocale();

  const [selectedLocale, setSelectedLocale] = useState<string>(locale);
  const [template, setTemplate] = useState<string>(LandingTemplates.SLIDER);
  const [broker, setBroker] = useState<string>('');

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    const params = new URLSearchParams({
      lang: selectedLocale,
      template,
    });
    try {
      if (broker) {
        params.append('broker', await encode(broker));
      }
      window.open(
        `/promotion/${promotion.reference}?${params.toString()}`,
        '_blank'
      );
    } catch (e) {
      toast.error(t('somethingWentWrong') as string);
    }
    setIsLoading(false);
  }, [t, broker, promotion.reference, selectedLocale, template]);

  const steps = useMemo(
    () => [
      {
        id: 1,
        Icon: SettingsIcon,
        title: t('Settings'),
        nextButton: () => (
          <Button
            variant="contained"
            disableElevation
            type="button"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? <SpinnerLoading /> : t('auth:submit')}
          </Button>
        ),
        body: (
          <div className="grid gap-8">
            <div className="grid gap-2">
              <div className="text-[1rem] leading-none">{t('Language')}</div>
              <LanguageRadioButtons
                selectedLocale={selectedLocale}
                setSelectedLocale={setSelectedLocale}
              />
            </div>
            <div className="grid gap-2">
              <div className="text-[1rem] leading-none capitalize">
                {t('brochureEditor:template_one')}
              </div>
              <Select
                value={template}
                onChange={setTemplate}
                options={[
                  { value: LandingTemplates.SLIDER, label: t('Slider') },
                  {
                    value: LandingTemplates.BACKGROUND,
                    label: t('Background'),
                  },
                ]}
              />
            </div>
            <div className="grid gap-2">
              <div className="text-[1rem] leading-none">
                {t('propertiesPage:broker')}
              </div>
              <PromotionLandingSelectBroker
                value={broker}
                onChange={setBroker}
                promotionId={promotion.id}
              />
            </div>
          </div>
        ),
      },
    ],
    [t, selectedLocale, template, broker, promotion.id, handleSubmit, isLoading]
  );

  return (
    <HashControlledModal hash={hash}>
      {({ isOpen, close }) =>
        isOpen ? <PopupStepper onClose={close} steps={steps} /> : null
      }
    </HashControlledModal>
  );
}

export default PromotionLandingPopup;

async function encode(broker: number | string | undefined) {
  const {
    data: { encoded },
  } = await http.get(
    `${process.env.REACT_APP_PDF_SERVICE_URL}/encode?term=${broker}`
  );
  return encoded;
}
