import React from 'react';
import BarChartIcon from '@mui/icons-material/BarChart';
import { useTranslation } from 'react-i18next';

interface Props {
  text?: string;
}

const NoDataWidget: React.FC<Props> = (props) => {
  const { text } = props;
  const { t } = useTranslation('common');

  return (
    <div
      className={
        'w-full h-[200px] bg-[#F5F5F7] rounded flex flex-col justify-center items-center gap-2'
      }
    >
      <BarChartIcon
        sx={{
          color: '#3446B9',
        }}
      />
      <p className={'font-medium text-center'}>
        {text || `${t('ThereIsNoDataForThisWidget')}`}
      </p>
    </div>
  );
};

export default NoDataWidget;
