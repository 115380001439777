import { useEffect } from 'react';

import { usePagesEditor } from './usePagesEditor';
import {
  OverrideApi,
  PageTypes,
  START_PAGE_ID,
  Template,
} from '../../../../types/brochureEditor';
import { initialLayoutState, useLayoutEditor } from './useLayoutEditor';
import { convertPagesToClient, getPagesWithoutLayoutParts } from '../utils';
import { useServerTemplate } from './useServerTemplate';
import { useServerOverride } from './useServerOverride';

export function useEditorInitializer() {
  const { template } = useServerTemplate();
  const { override } = useServerOverride();
  useTemplateInitializer(template);
  useOverrideInitializer(override);
}

export function useTemplateInitializer(template?: Template) {
  const { loadState } = useLayoutEditor();

  // reset layout of previous template
  useEffect(() => {
    loadState(initialLayoutState);
  }, []);

  const { setPages, setEditedPage } = usePagesEditor();
  useEffect(() => {
    if (template?.pages) {
      setPages(template.pages);
      const firstPage = getPagesWithoutLayoutParts(template.pages)[0];
      if (firstPage) {
        setEditedPage(firstPage.id);
      }
    } else {
      // 1 and 2 reserved for header and footer (see PageTypes)
      setPages({
        [`${PageTypes.HEADER}`]: {
          id: PageTypes.HEADER,
          idOnServer: null,
          type: PageTypes.HEADER,
          headerBg: null,
          footerBg: null,
        },
        [`${PageTypes.FOOTER}`]: {
          id: PageTypes.FOOTER,
          idOnServer: null,
          type: PageTypes.FOOTER,
          headerBg: null,
          footerBg: null,
        },
        [`${START_PAGE_ID}`]: {
          id: START_PAGE_ID,
          idOnServer: null,
          type: PageTypes.DEFAULT,
          headerBg: null,
          footerBg: null,
        },
      });
      setEditedPage(START_PAGE_ID);
    }
    if (template?.layout) {
      loadState(template.layout);
    }
  }, [template]);
}

export function useOverrideInitializer(override?: OverrideApi) {
  const { setOverrides } = usePagesEditor();
  useEffect(() => {
    setOverrides({});
    if (override) {
      const { pages, property, promotion } = override;
      let id;
      if (property) {
        id = property.id;
      }
      if (promotion) {
        id = promotion.id;
      }
      if (id) {
        setOverrides({ [id]: convertPagesToClient(pages) });
      }
    }
  }, [override, setOverrides]);
}
