import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { UseFormRegister } from 'react-hook-form';
import { Box, FormControl } from '@mui/material';
import useLocale from '../../hooks/useLocale';
import ControlledSelectedForm from './controlledSelectedForm';
import { useCallback } from 'react';
import { uniqBy } from 'lodash';

type autoCompleteSelectT = {
  name: string;
  value: string;
  label: string;
};
type AutocompleteSelectPropsT = {
  name: string;
  label: string;
  className?: string;
  defaultValue?: { label: string; value: string };
  isStepper?: boolean;
  error?: any;
  width?: string;
  height?: string;
  isRequired?: boolean;
  clearErrors?: any;
  isMultiple?: boolean;
  setValue?: any;
  register?: UseFormRegister<any>;
  options?: any;
  isCountries?: boolean;
  disabled?: boolean;
  value?: autoCompleteSelectT | autoCompleteSelectT[];
  onInputChange?: any;
  updateInfo?: (action: { name: string; value: any }) => void;
};
const convertOptionsData = (options: any) => {
  if (options.length) {
    for (let i = 0; i < options.length; i++) {
      options[i].label = `${
        options[i]?.firstname + ' ' + options[i]?.lastname
      }`;
      options[i].value = options[i]?.id;
    }
  }
  return options;
};
const convertCountriesData = (options: any, locale: string) => {
  if (options.length) {
    for (let i = 0; i < options.length; i++) {
      options[i].label = `${options[i][locale]}`;
      options[i].value = options[i]?.alpha2;
    }
  }
  return options;
};

const defaultOptions = [
  {
    id: 1,
    firstname: '',
    lastname: '',
    label: '',
    value: null,
  },
];
const AutocompleteSelect = ({
  label,
  setValue,
  height,
  width,
  name,
  isMultiple = false,
  disabled = false,
  error,
  updateInfo,
  isStepper,
  defaultValue,
  clearErrors,
  isRequired = false,
  className,
  isCountries = false,
  value = { value: '', name: '', label: '' },
  options = defaultOptions,
  onInputChange,
}: AutocompleteSelectPropsT) => {
  const { locale } = useLocale();

  const optionsList = isCountries
    ? convertCountriesData(options, locale)
    : convertOptionsData(options);

  const clearErrorsCallback = useCallback(
    () => clearErrors(name),
    [clearErrors, name]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <span className="text-xs text-zinc-500">
        {label} {isRequired && <span className="text-red-500">*</span>}
      </span>
      <FormControl error={error} fullWidth>
        <Autocomplete
          disabled={disabled}
          defaultValue={defaultValue}
          multiple={isMultiple}
          disablePortal
          className={className}
          options={optionsList}
          onFocus={isStepper ? clearErrorsCallback : undefined}
          value={value}
          loading
          sx={{
            width: width ? width : '100%',
            height: height ? height : '44px',
            borderRadius: '5px',
            border: error && '0.1px solid red ',
            background: disabled ? '#0001' : 'transparent',
            padding: '0px',
          }}
          onChange={(event, newValue: any) => {
            if (newValue === null) {
              setValue(name, '');
              updateInfo &&
                updateInfo({
                  name,
                  value: '',
                });
            } else {
              const value = isCountries
                ? newValue.alpha2 || newValue.id
                : newValue.id || newValue.label;
              const valueToUpdate = newValue.label;
              isStepper &&
                updateInfo &&
                updateInfo({
                  name,
                  value: String(valueToUpdate),
                });
              setValue(name, value);
              ControlledSelectedForm;
              isMultiple
                ? setValue(
                    name,
                    uniqBy(newValue, function (e: any) {
                      return e.id;
                    })
                  )
                : setValue(name, value);
            }
          }}
          renderInput={(params: any) => (
            <TextField
              onChange={(val) => {
                !isStepper && onInputChange(val.target.value, name);
              }}
              style={{ height: '12px !important' }}
              {...params}
            />
          )}
        />
      </FormControl>
    </Box>
  );
};
export default AutocompleteSelect;
