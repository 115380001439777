import { Button, ButtonProps } from '@mui/material';
import { ReactNode } from 'react';

interface Props extends Omit<ButtonProps, 'color' | 'bgcolor'> {
  bgcolor?: string;
  color?: string;
  children: ReactNode;
}

const ActionButton = ({ bgcolor, color, children, ...rest }: Props) => (
  <Button
    sx={{
      bgcolor,
      color,
      '&:hover': { bgcolor },
    }}
    classes={{
      root: 'flex justify-center items-center gap-4',
    }}
    variant="contained"
    disableElevation
    {...rest}
  >
    {children}
  </Button>
);

export default ActionButton;
