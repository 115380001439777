import LanguageSharpIcon from '@mui/icons-material/LanguageSharp';
import { ILeadItem } from '../../../types/contacts';
import { sanitizeUrl } from '../../../helpers/utils';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import classNames from 'classnames';

function LeadSource({
  lead,
  customHeight,
  hideIconOnMobile,
}: {
  lead?: ILeadItem | null;
  customHeight?: boolean;
  hideIconOnMobile?: boolean;
}) {
  const { t } = useTranslation('common');
  return lead?.source === 'Preview Page' ? (
    <Tooltip
      title={lead?.source}
      arrow
      placement="top"
      componentsProps={{
        tooltip: {
          sx: {
            width: '11vw',
          },
        },
      }}
    >
      <a
        target={'_blank'}
        rel={'noreferrer'}
        href={
          lead?.source === 'Preview Page' &&
          lead?.property &&
          lead?.property.reference
            ? `/property/${lead?.property.reference}?broker=true`
            : sanitizeUrl(lead?.source)
        }
        className={classNames(
          ` inline-flex gap-1 transition-opacity duration-300 hover:opacity-80 overflow-hidden text-ellipsis whitespace-nowrap items-end`,
          {
            'h-[60%]': !customHeight,
          }
        )}
      >
        <LanguageSharpIcon
          className={classNames({
            '!hidden @[11rem]:!inline-block': hideIconOnMobile,
          })}
        />
        <span className="!text-sm font-medium truncate">{lead?.source}</span>
      </a>
    </Tooltip>
  ) : (
    <Tooltip
      title={lead?.source}
      arrow
      placement="top"
      componentsProps={{
        tooltip: {
          sx: {
            width: '11vw',
          },
        },
      }}
    >
      <div
        className={classNames(
          'overflow-hidden text-ellipsis whitespace-nowrap flex items-end',
          {
            'h-[60%]': !customHeight,
          }
        )}
      >
        <LanguageSharpIcon
          className={classNames({
            '!hidden @[11rem]:!inline-block': hideIconOnMobile,
          })}
        />

        <span className=" ml-2 !text-sm font-medium truncate">
          {t(`${lead?.source}`)}
        </span>
      </div>
    </Tooltip>
  );
}
export default LeadSource;
