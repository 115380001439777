import { IPropertyApi } from '../../types/property';
import CircleWithShortName, {
  CircleWithShortNameProps,
} from './circleWithShortName';
import { PopupSingleProperty } from '../../types/popupSingleProperty';

const PropertyContact = ({
  row,
  showIntermediateBroker = false,
}: {
  row: IPropertyApi | PopupSingleProperty;
  showIntermediateBroker?: boolean;
}) => {
  const items: CircleWithShortNameProps[] = [];

  items.push({
    firstname: row.mainBroker?.firstname,
    lastname: row.mainBroker?.lastname,
    role: 'broker',
  });

  if (showIntermediateBroker && row.intermediateBroker) {
    items.push({
      firstname: row.intermediateBroker?.firstname,
      lastname: row.intermediateBroker?.lastname,
      role: 'broker',
    });
  }

  // const mainOwner = row.owners.filter((owner) => owner.mainContact === true)[0];
  // if (mainOwner) {
  //   items.push({
  //     firstname: mainOwner?.contact?.individual ? mainOwner?.contact?.individual?.firstname : mainOwner?.contact?.company.legalName,
  //     lastname: mainOwner?.contact?.individual ? mainOwner?.contact?.individual?.lastname : '',
  //     role: 'owner',
  //   });
  // }

  for (const owner of row.owners) {
    items.push({
      firstname: owner?.contact?.individual
        ? owner?.contact?.individual?.firstname
        : owner?.contact?.company.legalName,
      lastname: owner?.contact?.individual
        ? owner?.contact?.individual?.lastname
        : '',
      role: 'owner',
    });
  }

  return (
    <>
      {items.map((item, index) => (
        <CircleWithShortName key={index} {...item} />
      ))}
    </>
  );
};

export default PropertyContact;
