import * as yup from 'yup';

const profileSettingsValidation = yup.object().shape({
  lastname: yup.string().trim().min(1),
  firstname: yup.string().trim().min(1),
  email: yup
    .string()
    .trim()
    .matches(
      /^[\w-.]+@([\w-]+\.)+[a-z]{2,}$/,
      'Please enter a valid email address'
    ),
});
const editPasswordValidation = yup.object().shape({
  password: yup.string().min(6),
  newPassword: yup.string().min(6),
  confirmPassword: yup.string().when('newPassword', {
    is: (value: string) => value.length,
    then: yup.string().oneOf([yup.ref('newPassword')]),
  }),
});
export { profileSettingsValidation, editPasswordValidation };
