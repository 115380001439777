import * as yup from 'yup';

export const UserValidation = yup.object().shape({
  firstname: yup
    .string()
    .trim()
    .min(3, 'firstname_min_should_be_three')
    .required('firstname_required'),
  lastname: yup
    .string()
    .trim()

    .min(3, 'lastname_min_should_be_three')
    .required('lastname_required'),

  email: yup
    .string()
    .email('enter_valid_email')
    .trim()
    .required('users_must_have_an_email'),
  phone: yup.string().trim().min(8, 'enter_valid_phone').nullable(true),
});
