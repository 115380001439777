import { FC } from 'react';
import classNames from 'classnames';
import EraseIcon from '../../../../features/icons/eraseIcon';
import { useTranslation } from 'react-i18next';

interface AlphabetPickerProps {
  selectedLetter: string | null;
  onLetterClick: (letter: string) => void;
  handleReset: () => void;
}

const AlphabetPicker: FC<AlphabetPickerProps> = ({
  onLetterClick,
  selectedLetter,
  handleReset,
}) => {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  const { t } = useTranslation('');

  return (
    <div className={'flex items-center gap-4'}>
      {alphabet.map((letter) => (
        <button
          className={classNames('text-[24px]', {
            'text-blue': selectedLetter === letter,
          })}
          key={letter}
          onClick={() => onLetterClick(letter)}
        >
          {letter}
        </button>
      ))}
      {selectedLetter ? (
        <button
          onClick={handleReset}
          className={
            'px-4 flex items-center cursor-pointer btn_clear text-[#3446B9]'
          }
        >
          <EraseIcon className="mr-2" color="#3446B9" />
          {t('propertiesPage:clear')}
        </button>
      ) : null}
    </div>
  );
};

export default AlphabetPicker;
