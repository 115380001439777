import { FC } from 'react';
import GooglePlaces from './googlePlaces';
import { IAddress } from '../../../../../types/address';
import useLocale from '../../../../../hooks/useLocale';

const AddressInput: FC<{
  value?: IAddress;
  onChange: (address: IAddress) => unknown;
  isLoaded: boolean;
  register: any;
  watch: any;
  index: number;
  name: string;
}> = ({ onChange, isLoaded, register, watch, name, index }) => {
  const { locale } = useLocale();

  const separator = ', ';
  const defaultAddress = '';
  const [defaultRoute, defaultStreetNumber] = defaultAddress.split(separator);

  const onPlaceChange = (
    placeResult: google.maps.places.PlaceResult,
    placesByLanguage: any
  ) => {
    const location = placeResult.geometry?.location;

    const address: IAddress = {
      latitude: null,
      longitude: null,
      zipCode: '',
      locale,
      translations: {},
    };
    if (location) {
      const { lat, lng } = location.toJSON();
      address.latitude = lat;
      address.longitude = lng;
    }
    // }
    const { address_components } = placeResult;
    if (address_components) {
      let route: string | undefined;
      let streetNumber: string | undefined;

      for (const languageKey of Object.keys(placesByLanguage)) {
        const components = placesByLanguage[languageKey];
        const translationObject = (address.translations[languageKey] = {
          locale: languageKey,
          country: '',
          countryCode: '',
          city: '',
          zone: '',
          adresse: '',
          canton: '',
          district: '',
        });
        for (const component of components) {
          const {
            long_name,
            short_name,
            types: [type],
          } = component;

          switch (type) {
            case 'postal_code':
              address.zipCode = long_name;
              break;
            case 'country':
              translationObject.country = short_name;
              break;
            case 'locality':
            case 'postal_town':
              translationObject.city = long_name;
              break;
            case 'route':
              route = long_name;
              break;
            case 'street_number':
              streetNumber = long_name;
              break;
            case 'administrative_area_level_1':
              translationObject.canton = long_name;
              break;
            case 'administrative_area_level_2':
              translationObject.district = long_name;
              break;
            default:
            // Do nothing.
          }
        }

        // Combine route and street number, if available
        const routeAndStreetNumber = [
          route || defaultRoute,
          streetNumber || defaultStreetNumber,
        ]
          .filter(Boolean)
          .join(separator);

        // Set the 'adresse' property in the translation object
        translationObject.adresse = routeAndStreetNumber;
      }
    }
    onChange(address);
  };

  return (
    <GooglePlaces
      watch={watch}
      register={register}
      name={name}
      index={index}
      onPlaceChange={onPlaceChange}
      isLoaded={isLoaded}
    />
  );
};

export default AddressInput;
