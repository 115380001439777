import classNames from 'classnames';
import { MouseEventHandler, ReactNode } from 'react';

function ChoiceButton({
  isActive,
  children,
  img,
  onClick,
}: {
  isActive?: boolean;
  children?: ReactNode;
  img?: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}) {
  return (
    <button
      onClick={onClick}
      className={classNames(
        'p-4 rounded border border-gray-200 inline-flex items-center gap-4 text-blue',
        {
          'bg-gray-100 border-gray-100 poitner-events-none': isActive,
        }
      )}
    >
      {img && <div className="w-4 h-4 relative flex-shrink-0">{img}</div>}

      <div>{children}</div>
    </button>
  );
}

export default ChoiceButton;
