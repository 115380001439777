import { GridColumns } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';

import CreatedUpdated from '../../../components/tableGridX/createdUpdated';
import { Pipeline, PipelineProjectTypes } from '../../../types/pipelines';
import PipelinesActionMenu from './actionMenu';
import useLocale from '../../../hooks/useLocale';
import CircleWithFullName from '../../../components/tableGridX/circleWithFullName';

export const useColumns = ({
  isLoading,
  mutate,
}: {
  isLoading?: boolean;
  mutate?: () => Promise<unknown>;
}): GridColumns<Pipeline> => {
  const { t } = useTranslation('common');
  const { locale } = useLocale();
  const commonSettings = {
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
  };

  const columns: GridColumns<Pipeline> = [
    { field: '', width: 50, flex: 0 },
    {
      field: 'name',
      headerName: t('Name'),
      renderCell: ({ row }) => {
        return (
          <Link
            to={`/settings/pipelines/${row.id}`}
            className="capitalize font-medium text-base leading-5 truncate"
          >
            {row.name || `${t('unnamed')} #${row.id}`}
          </Link>
        );
      },
    },
    {
      field: 'projectType',
      headerName: t('project_type'),
      renderCell: ({ row }) => {
        let type = '';
        if (row.projectType === PipelineProjectTypes.PROPERTY) {
          type = t('property');
        } else if (row.projectType === PipelineProjectTypes.PROMOTION) {
          type = t('Promotion');
        }
        return <span className="capitalize">{type}</span>;
      },
    },
    {
      field: 'service',
      headerName: t('service_type'),
      renderCell: ({ row }) => {
        return (
          <span className="capitalize">
            {row.serviceType?.translations?.[locale]?.name || ''}
          </span>
        );
      },
    },
    {
      field: 'usedOn',
      headerName: t('used_on'),
    },
    {
      field: 'createdBy',
      headerName: t('brochureEditor:created_by'),
      renderCell: ({ row }) => {
        return row.createdBy && <CircleWithFullName {...row.createdBy} />;
      },
    },
    {
      field: 'createdUpdated',
      renderHeader: () => t('date_created'),
      renderCell: ({ row }) => (
        <CreatedUpdated created={row.createdAt || undefined} updated={null} />
      ),
    },
    {
      field: 'status',
      headerName: t('Status'),
      valueGetter: ({ row }) => (row.status ? t(row.status) : ''),
    },
    {
      field: 'actions',
      flex: 1,
      headerName: t('actions'),
      renderCell: ({ row }) => (
        <PipelinesActionMenu row={row} mutate={mutate} />
      ),
    },
  ];

  return columns.map((column) => ({
    ...commonSettings,
    ...column,
    ...(isLoading
      ? {
          renderCell: () => (
            <Skeleton
              variant="text"
              animation="wave"
              width="100%"
              height={20}
            />
          ),
        }
      : {}),
  }));
};
