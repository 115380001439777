import React, { useEffect, useRef, useState } from 'react';
import { GoogleMap, MarkerF } from '@react-google-maps/api';
import { useGoogleApis } from '../../../../../../hooks/useGoogleApis';

// Custom icon URLs for the markers
const mainMarkerIcon = process.env.PUBLIC_URL + '/img/home-pin.png';
const defaultMarkerIcon = process.env.PUBLIC_URL + '/img/default-pin.png';
const selectedMarkerIcon = process.env.PUBLIC_URL + '/img/selected-pin.png';

interface NearbyPlacesMapProps {
  id: string;
  latitude: number;
  longitude: number;
  type: string;
  radius: number;
  onPlacesLoaded: (places: any) => void;
  setSelectedPlace: (place: any) => void;
  selectedPlace?: any;
  hoveredPlaceId: string | null;
  setHoveredPlaceId: (id: string | null) => void;
}

const NearbyPlacesMap: React.FC<NearbyPlacesMapProps> = ({
  latitude,
  longitude,
  type,
  radius,
  id,
  onPlacesLoaded,
  selectedPlace,
  setSelectedPlace,
  setHoveredPlaceId,
}) => {
  const [places, setPlaces] = useState<any[]>([]);
  const { isLoaded } = useGoogleApis();
  const mapRef = useRef<any | null>(null);

  const onLoad = (map: any): void => {
    if (map) {
      mapRef.current = map;
    }
    nearbySearch();
  };

  const onUnmount = (): void => {
    mapRef.current = null;
  };

  const nearbySearch = async () => {
    if (!mapRef.current) {
      console.error('Map is not loaded yet.');
      return;
    }

    const center = new google.maps.LatLng(latitude, longitude);
    const request = {
      location: center,
      radius,
      type,
    };

    const service = new google.maps.places.PlacesService(mapRef.current);
    service.nearbySearch(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && results) {
        const limitedResults = results.slice(0, 8).map((place) => ({
          ...place,
          marker_url:
            selectedPlace && selectedPlace.place_id === place.place_id
              ? selectedMarkerIcon
              : defaultMarkerIcon,
        }));
        setPlaces(limitedResults);
        onPlacesLoaded(limitedResults);
        fitBoundsToPlaces(limitedResults);
      } else {
        console.error('Nearby search failed:', status);
        fitBoundsToPlaces([]);
      }
    });
  };

  const fitBoundsToPlaces = (places: any[]) => {
    if (!mapRef.current) {
      return;
    }

    const bounds = new google.maps.LatLngBounds();
    bounds.extend(new google.maps.LatLng(latitude, longitude));
    if (places.length) {
      places.forEach((place) => {
        bounds.extend(place.geometry.location);
      });
    }
    mapRef.current.fitBounds(bounds);
  };

  useEffect(() => {
    if (isLoaded) {
      nearbySearch();
    }
  }, [isLoaded, latitude, longitude, type]);

  useEffect(() => {
    if (selectedPlace) {
      setPlaces(
        places.map((place) => ({
          ...place,
          marker_url:
            selectedPlace && selectedPlace.place_id === place.place_id
              ? selectedMarkerIcon
              : defaultMarkerIcon,
        }))
      );
    }
  }, [selectedPlace]);

  if (!isLoaded) {
    return null;
  }

  const markerIcon = (url: string) => ({
    url,
    scaledSize: new window.google.maps.Size(40, 40), // Adjust size as needed
    origin: new window.google.maps.Point(0, 0),
    anchor: new window.google.maps.Point(20, 40),
  });

  const handleMarkerMouseOver = (placeId: string) => {
    setHoveredPlaceId(placeId);
  };

  const handleMarkerMouseOut = () => {
    setHoveredPlaceId(null);
  };

  const onMarkerClick = (place: any) => {
    setSelectedPlace(place);
  };

  return (
    <div>
      <GoogleMap
        key={type}
        id={id}
        mapContainerStyle={{ height: '500px', width: '100%' }}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {latitude && longitude && (
          <MarkerF
            visible={true}
            animation={google.maps.Animation.DROP}
            position={{ lat: latitude, lng: longitude }}
            icon={markerIcon(mainMarkerIcon)}
          />
        )}
        {places.map((place, index) => (
          <MarkerF
            key={place.place_id}
            position={{
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            }}
            title={place.name}
            animation={google.maps.Animation.DROP}
            options={{
              zIndex:
                selectedPlace && selectedPlace.place_id === place.place_id
                  ? 2
                  : 1,
              icon:
                selectedPlace && selectedPlace.place_id === place.place_id
                  ? markerIcon(selectedMarkerIcon)
                  : markerIcon(defaultMarkerIcon),
              label: {
                text:
                  selectedPlace && selectedPlace.place_id === place.place_id
                    ? ' '
                    : `${index + 1}`,
                color: 'white',
              },
            }}
            onClick={() => onMarkerClick(place)}
            onMouseOver={() => handleMarkerMouseOver(place.place_id)}
            onMouseOut={handleMarkerMouseOut}
          />
        ))}
      </GoogleMap>
    </div>
  );
};

export default NearbyPlacesMap;
