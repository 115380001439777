import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

function MountIcon({ color, className }: IconProps) {
  const { mode } = useThemeMode();

  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 32 32"
    >
      <rect width="32" height="32" fill="#34B9B9" rx="16"></rect>
      <path
        fill={color}
        d="M14.938 13.5l2.656-5.313 6.375 11.688H8.03l4.781-8.5 2.126 2.125z"
      ></path>
    </svg>
  );
}

export default MountIcon;
