import { ContactTapInnerProps } from './search-criteria-tab';
import React, { useMemo } from 'react';
// import TableGridX from "../../../../components/tableGridX";
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import TableGridX from '../../../../components/tableGridX';
import { useColumns } from '../utils/useColumns';
import { ILeadItem } from '../../../../types/contacts';
import { ILeadItemExtended } from '../../../properties/stepper/steps/leads';

const skeletonItems = Array(6)
  .fill('')
  .map((_, index) => ({
    createdAt: '',
    createdBy: '',
    date: '',
    id: index,
    message: '',
    property: null,
    source: '',
    updatedAt: '',
    updatedBy: '',
    status: {
      id: 0,
      keyname: '',
      translations: {},
    },
    type: {
      id: 0,
      keyname: '',
      translations: {},
    },
    contact: null,
  }));

const PropertiesTab = (props: ContactTapInnerProps) => {
  const { id } = props;
  const { t } = useTranslation('common');
  const [page, setPage] = React.useState<number>(1);
  const itemsPerPage = 7;
  const { data: items } = useSWR(
    `/contacts/${id}/leads?page=${page}&itemsPerPage=${itemsPerPage}`
  );

  const columns = useColumns({
    isLoading: !items,
  });

  const totalCount = items?.['hydra:totalItems'] || 0;
  const results: ILeadItemExtended[] = useMemo(() => {
    if (items && items['hydra:member']) {
      return items['hydra:member'].map((item: ILeadItem) => ({
        ...item,
        parentId: id,
      }));
    }
    return [];
  }, [items]);
  const rows = !items ? skeletonItems : results;

  return (
    <div>
      <p className={'mb-8 text-2xl'}>
        {t('Leads')} {items && totalCount ? <span>({totalCount})</span> : null}
      </p>
      <Box className={'w-full !min-h-[500px] with-border'}>
        <TableGridX
          sx={{
            '&': {
              minHeight: '500px',
            },
            // '& .MuiDataGrid-virtualScroller': {
            //   overflow: 'hidden',
            // },
          }}
          disableSelectionOnClick={true}
          disableColumnSelector={true}
          rows={rows}
          // @ts-ignore
          columns={columns}
          autoPageSize
          pageSize={itemsPerPage}
          page={page - 1}
          paginationMode="server"
          rowCount={totalCount}
          onPageChange={(page: number) => {
            setPage(page + 1);
          }}
          rowHeight={95}
        />
      </Box>
    </div>
  );
};

export default PropertiesTab;
