import { ReactElement } from 'react';
import { Box } from '@mui/material';

import Aside from './aside';
import Header from './header';
import MobileHeader from './mobile/header';
import useMediaQuery from '../hooks/useMediaQuery';
import { useAuth } from '../hooks/useAuth';

import Notifications from './globalNotifications';
import { useTotalNotification } from '../app/providers/notification';
import RealsErrorBoundary from './errorBoundary';

export default function Layout({
  children: main,
}: {
  children: ReactElement | ReactElement[];
}) {
  const { matches } = useMediaQuery();

  const { tokens } = useAuth();
  const isAuthenticated = Boolean(tokens);

  const { totalNotifications } = useTotalNotification();

  return (
    <Box
      sx={{ bgcolor: 'grey.50', color: 'text.primary' }}
      className={`flex flex-col h-screen max-h-screen overflow-hidden   `}
    >
      <Box className="flex flex-1 overflow-hidden h-full">
        {matches('up', 'md') && (
          <Box
            sx={{ minWidth: '250px' }}
            component="aside"
            className="flex-col border-r w-1/5 min-w-[250px] max-w-[300px]"
          >
            <Aside />
          </Box>
        )}

        <Box className="flex flex-1 flex-col w-4/5">
          <Box className="h-16 px-4 lg:px-10 border-b  overflow-hidden">
            {matches('up', 'md') ? (
              <Header totalNotifications={totalNotifications} />
            ) : (
              <MobileHeader />
            )}
          </Box>
          <Box
            component="main"
            className="flex flex-col flex-1 overflow-y-auto overflow-x-hidden  px-4 pt-4 pb-8 custom-scrollbar"
            sx={{
              '&::-webkit-scrollbar': {
                width: '8px',
              },
            }}
          >
            <RealsErrorBoundary>{main}</RealsErrorBoundary>
          </Box>
        </Box>
      </Box>

      {isAuthenticated && <Notifications />}
    </Box>
  );
}
