import { Box, Stack } from '@mui/material';

function PromotionStatsTable({
  stats,
}: {
  stats: {
    label: string;
    value: string | null;
  }[];
}) {
  return (
    <Stack
      sx={{
        width: '100%',
        backgroundColor: 'background.default',
        color: 'text.primary',
        borderRadius: '8px',
        borderWidth: '1px',
        padding: '12px 32px',
      }}
    >
      {stats
        .filter(({ value }) => !!value)
        .map(({ label, value }, index) => (
          <Box
            className="border-b border-gray-200 last:border-none text-xs"
            key={index}
            sx={{
              display: 'flex',
              gap: '8px',
              justifyContent: 'space-between',
              padding: '8px 0px',
              backgroundColor: 'background.default',
              color: 'text.primary',
            }}
          >
            <label> {label}</label>
            <p className="pr-4">{value}</p>
          </Box>
        ))}
    </Stack>
  );
}

export default PromotionStatsTable;
