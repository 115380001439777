import TextField from '@mui/material/TextField';
import { forwardRef, useRef, useState } from 'react';
import { ColorChangeHandler, SketchPicker } from 'react-color';
import { Control, useController } from 'react-hook-form';
import useClickAway from 'react-use/lib/useClickAway';
import classNames from 'classnames';

import InputLayout from './components/inputLayout';

type Props = {
  className?: string;
  isRequired?: boolean;
  wrapperClassName?: string;
  label?: string;
  isSmall?: boolean;
  disabled?: boolean;
};

// First version of input:  no dependency on react-hook-form. Just value & onChange props
export const UncontrolledColorField = forwardRef<
  SketchPicker,
  Props & {
    value?: string;
    onChange?: (newValue: string) => unknown;
  }
>(
  (
    {
      label,
      className,
      wrapperClassName,
      isRequired = false,
      value,
      onChange,
      isSmall,
      disabled,
    },
    ref
  ) => {
    const [showPicker, setShowPicker] = useState(false);

    const handleColorChange: ColorChangeHandler = ({ hex }) => {
      onChange?.(hex);
    };

    const clickListenerRef = useRef<HTMLDivElement | null>(null);

    useClickAway(clickListenerRef, () => {
      setShowPicker(false);
    });

    return (
      <InputLayout
        label={label}
        className={wrapperClassName}
        isRequired={isRequired}
      >
        <div className="relative" ref={clickListenerRef}>
          <TextField
            value={value}
            className={className}
            variant="outlined"
            // ref={ref}
            required={isRequired}
            classes={{ root: 'w-full' }}
            onFocus={() => setShowPicker(true)}
            InputProps={{
              classes: {
                input: classNames('disabled:opacity-50', {
                  '!py-[13px]': isSmall,
                }),
              },
            }}
            disabled={disabled}
          />

          {showPicker && (
            <div className="absolute top-full left-0 z-10">
              <SketchPicker
                color={value}
                onChange={handleColorChange}
                ref={ref}
              />
            </div>
          )}
          {value && (
            <div
              className="absolute top-1/2 -translate-y-2 right-4 z-10 w-4 h-4 rounded-full pointer-events-none"
              style={{ backgroundColor: value }}
            ></div>
          )}
        </div>
      </InputLayout>
    );
  }
);

UncontrolledColorField.displayName = 'UncontrolledColorField';

// Second version: for usage with react-hook-form. Requires passing `control`
const ColorField = ({
  control,
  name,
  ...rest
}: Props & {
  name: string;
  control: Control<any>;
}) => {
  const {
    field: { value, onChange, ref },
  } = useController({ control, name });

  const handleChange = (value: string) => {
    onChange({ target: { value } });
  };

  return (
    <UncontrolledColorField
      value={value}
      onChange={handleChange}
      ref={ref}
      {...rest}
    />
  );
};

export default ColorField;
