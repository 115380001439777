import { useState } from 'react';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import Star from '@mui/icons-material/Star';
import { http } from '../../libs/axios';
import { mutate } from 'swr';

interface Props {
  defaultValue: boolean;
  rowId: number;
  as?: string;
}
const FavoriteIcon = ({ defaultValue, rowId, as }: Props) => {
  const [isFavorite, setIsFavorite] = useState(defaultValue);

  const handleToggleFavorite = async () => {
    await http
      .patch('/personal_notifications/' + rowId, { isStarred: !isFavorite })
      .then(() => setIsFavorite(!isFavorite));
    if (as === 'starred') {
      mutate('/personal_notifications?isStarred=true');
    }
  };

  return !isFavorite ? (
    <StarOutlineOutlinedIcon
      style={{
        cursor: 'pointer',
        marginRight: '5px',
        color: '#1D1D1F',
        transition: 'color 0.3s ease-in-out',
      }}
      fontSize="medium"
      onClick={handleToggleFavorite}
    />
  ) : (
    <Star
      style={{
        cursor: 'pointer',
        marginRight: '5px',
        color: '#FFB800',
        transition: 'color 0.3s ease-in-out',
      }}
      fontSize="medium"
      onClick={handleToggleFavorite}
    />
  );
};

export default FavoriteIcon;
