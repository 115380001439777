import {
  HTMLAttributes,
  MouseEvent,
  MouseEventHandler,
  ReactNode,
} from 'react';

function LandingCta({
  children,
  onClickPrevented,
  ...props
}: {
  children: ReactNode;
  onClickPrevented?: MouseEventHandler;
} & HTMLAttributes<HTMLAnchorElement>) {
  let clickHandler;
  if (onClickPrevented) {
    clickHandler = (e: MouseEvent) => {
      e.preventDefault();
      onClickPrevented(e);
    };
  }
  return (
    <a
      href="#"
      className="inline-flex align-middle px-8 py-4 rounded-full bg-blue text-white transition-colors hover:bg-blue-900"
      onClick={clickHandler}
      {...props}
    >
      <span className="text-[14px] leading-none uppercase">{children}</span>
    </a>
  );
}

export default LandingCta;
