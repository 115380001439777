import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

import TranslationIcon from '../../../../../features/icons/translationIcon';
import SettingsIcon from '../../../../../features/icons/settingsIcon';
import PDFIcon from '../../../../../features/icons/pdfIcon';
import CheckedIcon from '../../../../../features/icons/checkedIcon';
import UpdateIcon from '../../../../../features/icons/updateIcon';

import usePropertyName from '../../../../../hooks/usePropertyName';
import useLocale from '../../../../../hooks/useLocale';
import { usePrint } from '../../../../../hooks/usePrint';

import { NameableProperty } from '../../../../../types/property';

import PopupStepper, { Step } from '../../../../../components/popupStepper';
import LanguageRadioButtons from '../../../../../components/popupStepper/controls/languageRadioButtons';
import HashControlledModal from '../../../../../components/modal/hashControlledModal';

import OwnersReportSettings, { SettingsFormState } from './settings';

enum Steps {
  LANGUAGE,
  SETTINGS,
  PREVIEW,
}

const defaultSettings = {
  individual_owner: [],
  company_owner: [],
  enable_owners: true,
  agent_in_charge: true,
  pricing: true,
  contact_requests: true,
  all_tasks: true,
  display_tasks_note: true,
};

const OwnersReportPopup = ({
  property,
  hash,
}: {
  property: NameableProperty;
  hash: string;
}) => {
  const { locale } = useLocale();
  const [selectedLocale, setSelectedLocale] = useState<string>(locale);

  const methods = useForm<SettingsFormState>({
    defaultValues: defaultSettings,
  });

  const handleNext = (step: Step | undefined) => {
    if (step?.id === Steps.PREVIEW) {
      generateReport();
    }
  };

  const { print, isPrinting } = usePrint();
  const { getPropertyName } = usePropertyName();

  const generateReport = async () => {
    const formState = methods.getValues();

    const params = new URLSearchParams({
      locale: selectedLocale,
      individual_owner: `[${formState.individual_owner.join(',')}]`,
      company_owner: `[${formState.company_owner.join(',')}]`,
      enable_owners: String(formState.enable_owners),
      agent_in_charge: String(formState.agent_in_charge),
      pricing: String(formState.pricing),
      contact_requests: String(formState.contact_requests),
      all_tasks: String(formState.all_tasks),
      display_tasks_note: String(formState.display_tasks_note),
    });

    await print({
      url: `/generate-owners-report/property/${
        property.id
      }?${params.toString()}`,
      fileName: getPropertyName(property),
    });
  };

  const { t } = useTranslation('propertiesPage');

  return (
    <HashControlledModal hash={hash}>
      {({ close }) => (
        <PopupStepper
          onClose={close}
          onNext={handleNext}
          steps={[
            {
              id: Steps.LANGUAGE,
              Icon: TranslationIcon,
              title: t('language'),
              body: (
                <div className="grid gap-2">
                  <div className="text-[1rem] leading-none">
                    {t('selected_report_language')}
                  </div>
                  <LanguageRadioButtons
                    selectedLocale={selectedLocale}
                    setSelectedLocale={setSelectedLocale}
                  />
                </div>
              ),
            },
            {
              id: Steps.SETTINGS,
              Icon: SettingsIcon,
              title: t('settings'),
              body: (
                <OwnersReportSettings
                  methods={methods}
                  propertyId={property.id}
                />
              ),
              nextButton: ({ onClick }) => (
                <Button
                  className="cursor-pointer gap-6"
                  variant={'contained'}
                  disableElevation
                  sx={{
                    height: '40px',
                  }}
                  onClick={onClick}
                >
                  <span>{t('generate')}</span>
                  <UpdateIcon color="currentcolor" />
                </Button>
              ),
            },
            {
              id: Steps.PREVIEW,
              Icon: PDFIcon,
              title: t('report_preview'),
              body: (
                <div className="flex flex-col justify-center items-center gap-8">
                  {isPrinting ? (
                    <div className="text-blue p-4 rounded-full border-2 border-current animate-spin">
                      <UpdateIcon
                        color="currentcolor"
                        className="w-8 h-8 -scale-x-100"
                      />
                    </div>
                  ) : (
                    <div className="text-[#4CAF50] p-4 rounded-full border-2 border-current">
                      <CheckedIcon color="currentcolor" className="w-8 h-8" />
                    </div>
                  )}
                  <div className="self-stretch text-center text-2xl font-normal font-['Inter'] leading-7">
                    {isPrinting
                      ? t('gathering_info')
                      : t('your_report_has_been_generated')}
                  </div>
                </div>
              ),
              nextButton: () =>
                isPrinting ? (
                  <div></div>
                ) : (
                  <Button
                    className="cursor-pointer gap-6"
                    variant={'contained'}
                    disableElevation
                    sx={{
                      height: '40px',
                    }}
                    onClick={close}
                  >
                    <span>{t('common:close')}</span>
                  </Button>
                ),
            },
          ]}
        />
      )}
    </HashControlledModal>
  );
};

export default OwnersReportPopup;
