import { ReactNode } from 'react';

const BlockWithIndex = ({
  index,
  rightSlot,
  children,
}: {
  index: number | null;
  rightSlot?: ReactNode;
  children?: ReactNode;
}) => {
  const _index = typeof index === 'number' ? index : 0;
  return (
    <div className="p-2 grid gap-2">
      <div className="flex justify-between">
        <div className="text-[11px] leading-[16px] text-gray-900 font-medium">
          {leadZero(_index + 1)}
        </div>

        {rightSlot}
      </div>

      {children}
    </div>
  );
};

export default BlockWithIndex;

function leadZero(number: number): string {
  const str = String(number);
  if (str.length > 1) return str;
  return `0${str}`;
}
