import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

function FloorIcon({ color, className }: IconProps) {
  const { mode } = useThemeMode();
  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        d="M14 7.276V4.717a1 1 0 00-.54-.888l-5-2.59a1 1 0 00-.92 0l-5 2.59a1 1 0 00-.54.888v2.559m12 0H2m12 0v3.862M2 7.276v3.862m12 0V14a1 1 0 01-1 1H3a1 1 0 01-1-1v-2.862m12 0H2"
      ></path>
    </svg>
  );
}

export default FloorIcon;
