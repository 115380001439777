import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { PropertyWidgetPreviewProps } from '../../../../../types/brochureEditor';
import { Dimension, DimensionApi } from '../../../../../types/property';

import useLocale from '../../../../../hooks/useLocale';
import { optionType } from '../../../../../const/propertiesOptions';

const PropertyAreasWidget = ({ property }: PropertyWidgetPreviewProps) => {
  const { t } = useTranslation('propertiesPage');
  const columns: { field: keyof Dimension; headerName: string }[] = [
    {
      field: 'spaceType',
      headerName: t('common:Space_type'),
    },
    {
      field: 'quantity',
      headerName: t('quantity'),
    },
    {
      field: 'isHabitable',
      headerName: t('common:Habitable'),
    },
    {
      field: 'habitable',
      headerName: t('Living_Area'),
    },
    {
      field: 'usable',
      headerName: t('useful_area'),
    },
    {
      field: 'building',
      headerName: t('block'),
    },
    {
      field: 'floor',
      headerName: t('floor'),
    },
    {
      field: 'orientation',
      headerName: t('orientation'),
    },
    {
      field: 'flooring',
      headerName: t('flooring'),
    },
    {
      field: 'celling',
      headerName: t('ceiling'),
    },
  ];

  const { data: dimensions } = useSWR<DimensionApi[]>(
    `/v1/properties/${property?.id}/dimensions`
  );

  const { locale } = useLocale();

  return (
    <div className="flex flex-col gap-4">
      <h2
        className="text-2xl font-semibold leading-7"
        style={{ color: 'var(--brand-color)' }}
      >
        {t('areas')}
      </h2>
      <table>
        <thead>
          <tr>
            {columns.map((col) => (
              <th
                key={col.field}
                className="text-sm leading-none font-medium p-1 text-left"
              >
                {col.headerName}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {dimensions?.map((dimension) => (
            <tr key={dimension.id}>
              {columns.map((col) => {
                const value = dimension[col.field];

                let result;
                if (typeof value === 'object' && value !== null) {
                  const option = value as optionType;
                  result =
                    option.translations?.[locale]?.name || option.keyname || '';
                } else if (typeof value === 'boolean') {
                  result = value ? t('common:yes') : t('common:no');
                } else if (value !== null) {
                  result = String(value);
                }
                return (
                  <td
                    key={col.field}
                    className="text-sm leading-none font-medium p-1 text-left border-t border-gray-200"
                  >
                    {result}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PropertyAreasWidget;
