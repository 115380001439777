import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import {
  Override,
  OverrideApi,
  OverridePayload,
  PagePayload,
} from '../../../../types/brochureEditor';
import { http } from '../../../../libs/axios';
import { convertPageToServer } from '../utils';

export function useServerOverride() {
  const { templateId, propertyId, promotionId } = useParams();
  let url = null;
  if (templateId) {
    if (propertyId) {
      url = `/overrides/patterns/${templateId}/properties/${propertyId}`;
    } else if (promotionId) {
      url = `/overrides/patterns/${templateId}/promotions/${promotionId}`;
    }
  }

  const { data: serverOverride, mutate } = useSWR<OverrideApi>(url, null, {
    onErrorRetry: (error) => {
      // Never retry on 404.
      if (error.status === 404) return;
    },
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const saveOverride = async ({
    templateId,
    propertyId,
    promotionId,
    override,
  }: {
    templateId: string | number;
    propertyId?: string | number;
    promotionId?: string | number;
    override: Override;
  }) => {
    const payload: OverridePayload = {
      pattern: String(templateId),
      pages: Object.values(override).map(
        (page) =>
          convertPageToServer(page, true) as PagePayload & { id: number }
      ),
    };
    if (propertyId) {
      payload.property = String(propertyId);
    }
    if (promotionId) {
      payload.promotion = String(promotionId);
    }
    if (serverOverride) {
      const res = await http.patch(`/overrides/${serverOverride.id}`, payload);
      await mutate();
      return res;
    } else {
      const res = await http.post('/overrides', payload);
      await mutate();
      return res;
    }
  };

  return {
    override: serverOverride,
    saveOverride,
  };
}
