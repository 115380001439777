import LangSwitcher from './langSwitcher';
import SocialMedias from './socialMedias';
import { Box } from '@mui/material';

export default function Footer() {
  return (
    <Box
      sx={{
        color: 'text.primary',
      }}
      component="footer"
      className="flex flex-col lg:flex-row justify-between text-sm mb-3 items-center w-full"
    >
      <Box className="flex items-center gap-x-20">
        <Box>© {new Date().getFullYear()} Reals. All rights reserved.</Box>
        <LangSwitcher />
      </Box>
      <SocialMedias />
      <Box>
        <Box component="ul" className="flex gap-x-6">
          <Box component="li">About us</Box>
          <Box component="li">Privacy Policy</Box>
          <Box component="li">Terms of Service</Box>
          <Box component="li">Contact Us</Box>
          <Box component="li">Help center</Box>
        </Box>
      </Box>
    </Box>
  );
}
