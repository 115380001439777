// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headroom {
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
}

.headroom--unfixed {
  position: relative;
  transform: translateY(0);
}

.headroom--scrolled {
  transition: transform 200ms ease-in-out;
}

.headroom--unpinned {
  position: fixed;
  transform: translateY(-100%);
}

.headroom--pinned {
  position: fixed;
  transform: translateY(0%);
}
`, "",{"version":3,"sources":["webpack://./src/components/landing/headroom.css"],"names":[],"mappings":"AAAA;EACE,MAAM;EACN,OAAO;EACP,QAAQ;EACR,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,wBAAwB;AAC1B;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,eAAe;EACf,4BAA4B;AAC9B;;AAEA;EACE,eAAe;EACf,yBAAyB;AAC3B","sourcesContent":[".headroom {\n  top: 0;\n  left: 0;\n  right: 0;\n  z-index: 20;\n}\n\n.headroom--unfixed {\n  position: relative;\n  transform: translateY(0);\n}\n\n.headroom--scrolled {\n  transition: transform 200ms ease-in-out;\n}\n\n.headroom--unpinned {\n  position: fixed;\n  transform: translateY(-100%);\n}\n\n.headroom--pinned {\n  position: fixed;\n  transform: translateY(0%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
