import InvalidImageException from './InvalidImageException';

export const imageTypes = [
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/png',
] as ImageTypes;

// const units = ['bytes', 'KB', 'MB', 'GB'];

type ImageType = 'image/png' | 'image/jpeg' | 'image/gif' | 'image/jpg';

type ImageTypes = Array<ImageType>;

type ConstructorArgs = {
  file: File;
  unit?: Unit;
  types?: ImageTypes;
};

type Unit = 'MB';

type Validation = {
  maxSize: number;
};

export default class ImageValidator {
  file: File;

  unit: Unit;

  types: ImageTypes;

  private errors: (string | number)[];

  constructor({ file, unit, types }: ConstructorArgs) {
    this.file = file;

    this.unit = unit || 'MB';

    this.types = types || imageTypes;

    this.errors = [];
  }

  setUnit(unit: Unit) {
    this.unit = unit;

    return this;
  }

  getType() {
    return this.file.type as ImageType;
  }

  getName() {
    return this.file.name;
  }

  getSize() {
    return this.file.size;
  }

  getErrors() {
    return this.errors;
  }

  fileSizeIsValid(maxSize: number): boolean {
    const isValid =
      this.unit === 'MB'
        ? this.getSize() / 1024 ** 2 <= maxSize
        : maxSize >= this.getSize();

    if (!isValid) {
      this.errors.push(this.getSize());
    }

    return isValid;
  }

  fileTypeIsValid(): boolean {
    const isValid = this.types.includes(this.getType());

    if (!isValid) {
      this.errors.push(this.file.type);
    }

    return isValid;
  }

  isValid({ maxSize }: Validation): boolean {
    return this.fileSizeIsValid(maxSize) && this.fileTypeIsValid();
  }

  /**
   * @throws {InvalidImageException}
   */

  validate({ maxSize }: Validation): void {
    if (!this.fileTypeIsValid()) {
      throw new InvalidImageException('type');
    }
    if (!this.fileSizeIsValid(maxSize)) {
      throw new InvalidImageException('size');
    }
  }
}
