import React, { useMemo } from 'react';
import { GridSelectionModel } from '@mui/x-data-grid';
import {
  contactsMatchingListingConfig,
  skeletonContactsMatchingListingConfig,
} from '../../../../../../components/material-ui/tablesConfig/contactsMatchingListingConfig';
import { Box } from '@mui/material';
import SkeletonTableGrid from '../../../../../../components/skeletonTableGrid';
import TableGridX from '../../../../../../components/tableGridX';
import useTranslationForTableGridXConfig from '../../../../../../hooks/useTranslationForTableGridX';
import { ApiListing } from '../../../../../../types/api';
import { IContact } from '../../../../../../types/contacts';

type Props = {
  contacts: ApiListing<IContact>;
  onRowsSelectionHandler: (rowSelectionModel: GridSelectionModel) => void;
  rowSelectionModel: GridSelectionModel;
  skeletonItemsCount?: number;
  singleSelectableContact?: boolean;
  pageSize?: number;
  countByPageSize?: boolean;
  setPage: (page: number) => void;
  onPageChange?: (page: number) => void;
  page: number;
};

const ContactsListingTable = ({
  contacts,
  rowSelectionModel,
  onRowsSelectionHandler,
  skeletonItemsCount = 10,
  singleSelectableContact,
  pageSize = 10,
  countByPageSize = false,
  onPageChange,
  page,
  setPage,
}: Props) => {
  const rows = useMemo(() => {
    let returnedValue: IContact[] = [];
    if (contacts) {
      returnedValue = contacts['hydra:member'];
    }
    return returnedValue;
  }, [contacts]);

  const usersListingColumns = useTranslationForTableGridXConfig([
    contactsMatchingListingConfig,
    'propertiesPage',
  ]);

  const getMinHeight = () => {
    const count = countByPageSize ? pageSize : contacts['hydra:totalItems'];
    if (contacts) {
      switch (count) {
        case 1:
          return 150;
          break;
        case 0:
          return 120;
          break;
        default:
          return count < 10
            ? 88 * (count + (countByPageSize ? 1.5 : 1))
            : 88 * 10;
      }
    } else {
      return 0;
    }
  };

  return (
    <Box className={'w-full with-border min-h-[100px]'}>
      {contacts && rows ? (
        <TableGridX
          rows={rows}
          columns={usersListingColumns}
          pageSize={pageSize}
          page={page - 1}
          sx={{
            '&': {
              minHeight: `${getMinHeight()}px`,
              marginBottom: '2rem',
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
              display: 'none',
            },
            '& .MuiDataGrid-selectedRowCount': {
              opacity: '0',
            },
          }}
          rowHeight={88}
          paginationMode="server"
          rowCount={contacts['hydra:totalItems']}
          onPageChange={(page: number) => {
            setPage(page + 1);
            if (onPageChange) {
              onPageChange(page + 1);
            }
          }}
          checkboxSelection
          onSelectionModelChange={(ids) => {
            if (singleSelectableContact) {
              const selectedIDs = ids.length ? [ids.pop()] : [];
              // @ts-ignore
              onRowsSelectionHandler(selectedIDs);
            } else {
              onRowsSelectionHandler(ids);
            }
          }}
          selectionModel={rowSelectionModel}
          hideFooter={contacts['hydra:totalItems'] < 10}
        />
      ) : (
        <SkeletonTableGrid
          className="w-full !min-h-[500px]"
          disableSelectionOnClick={true}
          disableColumnSelector={true}
          sx={{
            '&': {
              minHeight: '500px',
            },
          }}
          rows={Array.from({ length: skeletonItemsCount }).map((el, index) => {
            return {
              id: index,
              status: '',
              name: '',
              agency: '',
              agent: '',
              type: '',
              createdAt: '',
              action: '',
            };
          })}
          columns={skeletonContactsMatchingListingConfig}
          rowCount={0}
        />
      )}
    </Box>
  );
};

export default ContactsListingTable;
