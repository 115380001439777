import { Tooltip } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { Link } from 'react-router-dom';

type Props = {
  length?: number;
  avatarGroup?: any;
  isAssistant?: boolean;
};
export default function GroupAvatars({
  length,
  avatarGroup,
  isAssistant,
}: Props) {
  if (!avatarGroup.length) return null;
  return (
    <AvatarGroup max={length}>
      {avatarGroup.map(({ avatar, id, firstname, lastname }: any) => (
        <Tooltip
          key={avatar + id}
          title={`${firstname} ${lastname}`}
          arrow
          placement="top"
        >
          {isAssistant ? (
            <Link to={`/users/${id}`}>
              <Avatar
                className="cursor-pointer"
                key={avatar + id}
                alt="user"
                src={avatar}
              />
            </Link>
          ) : (
            <Avatar
              className="cursor-default"
              key={avatar + id}
              alt="user"
              src={avatar}
            />
          )}
        </Tooltip>
      ))}
    </AvatarGroup>
  );
}
