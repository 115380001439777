import {
  ListItemText,
  MenuItem,
  Select,
  ListSubheader,
  SelectProps,
  FormHelperText,
  SelectVariants,
} from '@mui/material';
import { forwardRef, ReactNode } from 'react';
import { optionType } from '../../const/propertiesOptions';
import {
  Company,
  Filial,
  HierarchyNode,
  Agency,
  Department,
} from '../../types/hierarchy';
import InputLayout from './components/inputLayout';

type Props = Omit<SelectProps, 'variant'> & {
  label: string;
  value: string;
  onChange: (value: string[]) => unknown;
  selectedServiceOption?: optionType;
  node: HierarchyNode;
  isDisabled?: boolean;
  isRequired?: boolean;
  className?: string;
  variant?: SelectVariants;
};

const ControlledSelectedFormWithHierarchy = forwardRef<
  HTMLSelectElement,
  Props
>(
  (
    {
      label,
      value,
      onChange,
      node,
      selectedServiceOption,
      isDisabled = false,
      isRequired = false,
      className,
      ...rest
    },
    ref
  ) => {
    const children: ReactNode[] = [];
    const mapIdsToDepartmentNames: Record<string, string> = {};

    function processCompany(company: Company) {
      for (const filial of company.children) {
        processFilial(filial);
      }
    }
    function processFilial(filial: Filial) {
      children.push(
        <ListSubheader
          id={`filial_${filial.id}`}
          key={`filial${filial.id}`}
          classes={{ root: '!leading-[1rem] !py-2 !text-black' }}
        >
          <div>{filial.name}</div>
        </ListSubheader>
      );
      for (const agency of filial.children) {
        processAgency(agency);
      }
    }
    function processAgency(agency: Agency) {
      const filteredDepartments = agency.children.filter(
        (d) =>
          !selectedServiceOption ||
          d.service.keyname.toLocaleLowerCase() ===
            selectedServiceOption.keyname.toLowerCase()
      );
      if (filteredDepartments.length) {
        children.push(
          <ListSubheader
            id={`agency_${agency.id}`}
            key={`agency${agency.id}`}
            sx={{
              marginLeft: `15px`,
            }}
          >
            {agency.name}
          </ListSubheader>
        );
        for (const department of filteredDepartments) {
          processDepartment(department);
        }
      }
    }
    function processDepartment(department: Department) {
      mapIdsToDepartmentNames[department.id] = department.name;
      children.push(
        <MenuItem
          sx={{
            marginLeft: `30px`,
          }}
          id={`department_${department.id}`}
          key={`department${department.id}`}
          value={String(department.id)}
        >
          <ListItemText primary={department.name} />
        </MenuItem>
      );
    }

    switch (node?.categoryEntity) {
      case 'Company':
        processCompany(node);
        break;
      case 'Filial':
        processFilial(node);
        break;
      case 'Agency':
        processAgency(node);
        break;
      case 'Department':
        processDepartment(node);
        break;
    }

    return (
      <InputLayout isRequired={isRequired} label={label} className="relative">
        <Select
          className={className}
          value={value}
          disabled={isDisabled}
          onChange={onChange}
          ref={ref}
          MenuProps={{
            elevation: 0,
            classes: {
              root: 'max-h-[calc(100vh-400px)]',
              paper: 'border border-gray-200',
            },
          }}
          renderValue={(selected) => {
            return Array.isArray(selected)
              ? selected.map((id) => mapIdsToDepartmentNames[id]).join(', ')
              : mapIdsToDepartmentNames[value];
          }}
          {...rest}
        >
          {children}
        </Select>
        {isDisabled && (
          <FormHelperText className="!text-[rgb(79,102,235)] absolute -bottom-6">
            * PLease choose broker first
          </FormHelperText>
        )}
      </InputLayout>
    );
  }
);

ControlledSelectedFormWithHierarchy.displayName =
  'ControlledSelectedFormWithHierarchy';

export default ControlledSelectedFormWithHierarchy;
