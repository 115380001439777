import { FC } from 'react';
import TitleHeader from '../../components/common/titleHeader';
import ContactCreator from './creator';
import { useTranslation } from 'react-i18next';

const CreateContact: FC = () => {
  const { t } = useTranslation('common');
  return (
    <TitleHeader title={t('Create_new_contact')} goBackLink="/contacts">
      <ContactCreator />
    </TitleHeader>
  );
};

export default CreateContact;
