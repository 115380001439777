import ActionMenu from '../../common/actionMenu';
import { ActionData } from '../../../types/actionData';
import MatchingIcon from '../../../features/icons/matchingIcon';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';
import { useAnchorElement } from '../../../hooks/useAnchorElement';
import {
  PropertyListingItem,
  SharedPropertyListingItem,
} from '../../../types/property';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import SettingsIcon from '@mui/icons-material/Settings';
import { toast } from 'react-toastify';
import { http } from '../../../libs/axios';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import InvitationModal from '../../../pages/properties/propertiesListing/propertiesNetwork/modal';
import { useState } from 'react';

const NetworksPropertiesListingActionMenu = ({
  row,
  action,
  isList,
  propertiesCards,
  networksCards,
}: {
  row: SharedPropertyListingItem;
  action?: string;
  isList?: boolean;
  propertiesCards?: boolean;
  networksCards?: boolean;
}) => {
  const [openInvitationModal, setOpenInvitationModal] = useState(false);
  const { t } = useTranslation();
  const mainAnchor = useAnchorElement();

  const navigate = useNavigate();
  const [success_message, error_message] = [
    t('common:You_property_is_duplicated'),
    t('common:Your_property_couldnt_be_duplicated'),
  ];
  const duplicateProperty = async () => {
    await http
      .get(`/v1/properties/${row.id}/duplicate`)
      .then((result) => {
        toast.success(success_message, {
          position: 'bottom-left',
          autoClose: 3000,
        });
        navigate(
          `/properties/${result.data.idClonedProperty}/edit/general-information`
        );
      })
      .catch(() => {
        toast.error(error_message, {
          position: 'bottom-left',
          autoClose: 3000,
        });
      });
  };

  const handleMatching = () => {
    http
      .get(`/v1/properties/${row.id}/contacts/matching`)
      .then(() => {
        toast.success(`${t('propertiesPage:Your_matching_is_launched')}`, {
          position: 'top-right',
          autoClose: 3000,
        });
      })
      .catch(() => {
        toast.error(`${t('propertiesPage:There_is_been_an_server_error')}`, {
          position: 'top-right',
          autoClose: 3000,
        });
      });
  };

  const menuActionData: ActionData[] = [];

  if (
    row.invitations &&
    row.invitations.filter((item) => item.status === 1).length
  ) {
    menuActionData.push({
      label: 'networks:manage_invitations',
      icon: SettingsIcon,
      hide: action,
      onClick: () => setOpenInvitationModal(true),
    });
  }

  if (
    row.invitations &&
    row.invitations.filter((item) => item.status === 2).length
  ) {
    menuActionData.push({
      label: 'propertiesPage:property_details',
      icon: VisibilityOutlined,
      hide: action,
      link: `/properties/${row.id}`,
    });
    menuActionData.push({
      label: 'propertiesPage:matching',
      icon: MatchingIcon,
      hide: action,
      onClick: () => handleMatching(),
    });
    if (!row.restrictedShare) {
      menuActionData.push({
        label: 'propertiesPage:duplicate',
        icon: FileCopyIcon,
        hide: action,
        onClick: () => duplicateProperty(),
      });
    }
  }

  return (
    <>
      {row.invitations &&
      row.invitations.filter((item) => item.status !== 3).length ? (
        <>
          <ActionMenu
            {...mainAnchor}
            action={action}
            menuAction={menuActionData}
            transPage={'propertiesPage'}
            isList={isList}
            propertiesCards={propertiesCards}
            networksCards={networksCards}
          />

          <InvitationModal
            openModal={openInvitationModal}
            setOpenModal={setOpenInvitationModal}
            property={row as PropertyListingItem}
          />
        </>
      ) : null}
    </>
  );
};

export default NetworksPropertiesListingActionMenu;
