import {
  ListItemText,
  MenuItem,
  Select,
  ListSubheader,
  SelectProps,
  SelectVariants,
} from '@mui/material';
import { forwardRef, ReactNode } from 'react';
import { optionType } from '../../../../const/propertiesOptions';
import {
  Agency,
  Company,
  Filial,
  HierarchyNode,
} from '../../../../types/hierarchy';
import InputLayout from '../../../../components/form/components/inputLayout';

type Props = Omit<SelectProps, 'variant'> & {
  label: string;
  value: string;
  onChange: (value: string[]) => unknown;
  selectedServiceOption?: optionType;
  node: HierarchyNode;
  isDisabled?: boolean;
  isRequired?: boolean;
  className?: string;
  variant?: SelectVariants;
};

const LevelThreeAgenciesSelect = forwardRef<HTMLSelectElement, Props>(
  (
    {
      label,
      value,
      onChange,
      node,
      selectedServiceOption,
      isDisabled = false,
      isRequired = false,
      className,
      ...rest
    },
    ref
  ) => {
    const children: ReactNode[] = [];
    const mapIdsToDepartmentNames: Record<string, string> = {};

    function processCompany(company: Company) {
      for (const filial of company.children) {
        processFilial(filial);
      }
    }
    function processFilial(filial: Filial) {
      children.push(
        <ListSubheader
          id={`filial_${filial.id}`}
          key={`filial${filial.id}`}
          classes={{ root: '!leading-[1rem] !py-2 !text-black' }}
        >
          <div>{filial.name}</div>
        </ListSubheader>
      );
      for (const agency of filial.children) {
        processAgency(agency);
      }
    }
    function processAgency(agency: Agency) {
      const filteredDepartments = agency.children.filter(
        (d) =>
          !selectedServiceOption ||
          d.service.keyname.toLocaleLowerCase() ===
            selectedServiceOption.keyname.toLowerCase()
      );
      if (filteredDepartments.length) {
        mapIdsToDepartmentNames[agency.id] = agency.name;
        children.push(
          <MenuItem
            sx={{
              marginLeft: `30px`,
            }}
            id={`department_${agency.id}`}
            key={`department${agency.id}`}
            value={String(agency.id)}
          >
            <ListItemText primary={agency.name} />
          </MenuItem>
        );
      }
    }

    switch (node?.categoryEntity) {
      case 'Company':
        processCompany(node);
        break;
      case 'Filial':
        processFilial(node);
        break;
      case 'Agency':
        processAgency(node);
        break;
    }

    return (
      <InputLayout isRequired={isRequired} label={label} className="relative">
        <Select
          className={className}
          value={value}
          disabled={isDisabled}
          onChange={onChange}
          ref={ref}
          MenuProps={{
            elevation: 0,
            classes: {
              root: 'max-h-[calc(100vh-400px)]',
              paper: 'border border-gray-200',
            },
          }}
          renderValue={(selected) => {
            return Array.isArray(selected)
              ? selected.map((id) => mapIdsToDepartmentNames[id]).join(', ')
              : mapIdsToDepartmentNames[value];
          }}
          {...rest}
        >
          {children}
        </Select>
      </InputLayout>
    );
  }
);

LevelThreeAgenciesSelect.displayName = 'LevelThreeAgenciesSelect';

export default LevelThreeAgenciesSelect;
