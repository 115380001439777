import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button } from '@mui/material';
import { useEffect } from 'react';
import useSWR, { mutate } from 'swr';
import CheckedIcon from '../../../features/icons/checkedIcon';
import { useAuth, UserPayload } from '../../../hooks/useAuth';
import { profileSettingsValidation } from '../../../libs/yup/formValidation';
import User from '../../../types/user';
import UpdatePersonalInfos from './updatePersonalInfos';
import UpdateProfilePhoto from './updateProfilePhoto';
import { toast } from 'react-toastify';
import UserContext from '../../../app/providers/user';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import SpinnerLoading from '../../../features/spinner/spinnerLoading';
import { http } from '../../../libs/axios';

export const SubmitBtnStyle = {
  color: '#fff',
  fontSize: '12px',
  lineHeight: '16px',
  alignSelf: 'end',
  marginTop: '32px',
  padding: '12px 24px',
  backgroundColor: '#3446B9',
  '&:hover': {
    backgroundColor: '#3446B9',
  },
};

function UpdateUserInfos() {
  const {
    user: { id },
    update,
  } = useAuth();

  const { t } = useTranslation();

  const key = `/users/${id}`;

  const { data: user } = useSWR<User>(key);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm<Partial<User>>({
    resolver: yupResolver(profileSettingsValidation),
    defaultValues: user,
  });

  useEffect(() => {
    reset(user);
  }, [user, reset]);

  const OnSubmit = async (data: FieldValues) => {
    try {
      delete data.agency;

      if (data && data.phone && !data.phone.includes('+')) {
        data.phone = '+' + data.phone;
      }
      await http.put(`/users/profile/edit`, data);

      mutate<UserPayload>(key).then((user) => {
        user && update(user);
      });
      toast.success(t('alerts:user.success.updated') as string, {
        autoClose: 3000,
      });
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  if (!user) return <SpinnerLoading />;

  return (
    <UserContext.Provider value={{ user, key }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '80px',
          padding: { sm: '16px', md: '32px' },
        }}
      >
        <UpdateProfilePhoto />
        <Box
          component="form"
          className="flex flex-col"
          onSubmit={handleSubmit(OnSubmit)}
        >
          <UpdatePersonalInfos
            editFields={['firstname', 'lastname', 'email', 'phone']}
            register={register}
            setValue={setValue}
            control={control}
            errors={errors}
          />

          <Button
            sx={SubmitBtnStyle}
            startIcon={<CheckedIcon color="#fff" />}
            type="submit"
          >
            {t('usersPage:updateInfos')}
          </Button>
        </Box>
      </Box>
    </UserContext.Provider>
  );
}

export default UpdateUserInfos;
