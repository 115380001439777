import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useSWR from 'swr';
import { useMemo } from 'react';

import { Template, TemplateApi } from '../../../../types/brochureEditor';
import { http } from '../../../../libs/axios';
import { convertTemplateToClient, convertTemplateToServer } from '../utils';
import { use404 } from '../../../../hooks/use404';

export function useServerTemplate() {
  const { templateId } = useParams();

  const {
    data: serverTemplate,
    error,
    mutate,
  } = useSWR(templateId ? `/patterns/${templateId}` : null, null, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  use404(error?.response?.status === 404);

  const template = useMemo(() => {
    return serverTemplate ? convertTemplateToClient(serverTemplate) : undefined;
  }, [serverTemplate]);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const saveTemplate = async (
    template: Omit<Template, 'id'>,
    asNew = false
  ) => {
    const payload = convertTemplateToServer(template);
    let response;
    if (templateId && !asNew) {
      response = await http.patch<TemplateApi>(
        `/patterns/${templateId}`,
        payload
      );
      await mutate();
    } else {
      if (asNew) {
        payload.name = `${payload.name} (copy)`;
      }
      response = await http.post<TemplateApi>('/patterns', payload);
      const { data: updatedServerTemplate } = response;
      const continueTo = searchParams.get('continueTo');
      if (updatedServerTemplate?.id && continueTo) {
        navigate(
          `/brochure-templates/${updatedServerTemplate.id}${continueTo}`,
          {
            state: {
              forceHideConfirmationPopup: true,
            },
          }
        );
      } else if (updatedServerTemplate?.id) {
        navigate(`/brochure-templates/${updatedServerTemplate.id}`, {
          state: {
            forceHideConfirmationPopup: true,
          },
        });
      }
    }
    return response;
  };

  return {
    template,
    saveTemplate,
  };
}
