import React, { useState } from 'react';
import WidgetWrapper from './wrapper';
import { WidgetProps } from '../index';
import { Link } from 'react-router-dom';
import { IDashboardSentEmailsList } from '../../../types/user';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Tooltip } from '@mui/material';
import SentEmailsPopup from '../components/sentEmailsPopup';
import { getEmailContext } from '../../properties/propertiesListing/viewSentEmails';

const Item = (props: { item: IDashboardSentEmailsList }) => {
  const { t } = useTranslation('propertiesPage');
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:900px)');
  const handleTouchStart = () => {
    if (isMobile) {
      setTimeout(() => setOpen(true), 10);
    }
  };

  const handleTouchEnd = () => {
    if (isMobile) {
      setTimeout(() => setOpen(false), 100);
    }
  };
  const { item } = props;

  return (
    <div className="self-stretch pb-2 border-b border-gray-200 justify-start items-center gap-2 md:gap-4 inline-flex">
      <Link
        className={
          'flex w-16 md:w-24 text-blue-800 text-sm font-medium leading-none'
        }
        to={`/properties/${item.propertyId}`}
      >
        <span>{item.propertyReference}</span>
      </Link>
      <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex overflow-y-hidden">
        {isMobile ? (
          <Tooltip
            title={item.contactName}
            arrow
            placement="top"
            onClick={handleTouchStart}
            open={open}
            onOpen={handleTouchStart}
            onClose={handleTouchEnd}
          >
            <Link
              to={`/contacts/${item.contactId}/contact-information`}
              id={`contacts_${item.contactId}`}
              className="block h-full w-[85%]"
            >
              {' '}
              <div className="grow shrink basis-0 text-zinc-900 text-sm font-medium font-['Inter'] pb-0.5 leading-none whitespace-nowrap overflow-hidden overflow-ellipsis">
                {item.contactName}
              </div>
            </Link>
          </Tooltip>
        ) : (
          <Tooltip title={item.contactName} arrow placement="top">
            <Link
              to={`/contacts/${item.contactId}/contact-information`}
              id={`contacts_${item.contactId}`}
              className="block h-full w-[85%]"
            >
              <div className="grow shrink basis-0 text-zinc-900 text-sm font-medium font-['Inter'] pb-0.5 leading-none whitespace-nowrap overflow-hidden overflow-ellipsis">
                {item.contactName}
              </div>
            </Link>
          </Tooltip>
        )}
      </div>
      <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex overflow-y-hidden">
        {isMobile ? (
          <Tooltip
            title={t(getEmailContext(item.emailContext))}
            arrow
            placement="top"
            onClick={handleTouchStart}
            open={open}
            onOpen={handleTouchStart}
            onClose={handleTouchEnd}
          >
            <div className="grow shrink basis-0 text-zinc-900 text-sm font-medium font-['Inter'] pb-0.5 leading-none whitespace-nowrap overflow-hidden overflow-ellipsis">
              {t(getEmailContext(item.emailContext))}
            </div>
          </Tooltip>
        ) : (
          <Tooltip
            title={t(getEmailContext(item.emailContext))}
            arrow
            placement="top"
          >
            <div className="grow shrink basis-0 text-zinc-900 text-sm font-medium font-['Inter'] pb-0.5 leading-none whitespace-nowrap overflow-hidden overflow-ellipsis">
              {t(getEmailContext(item.emailContext))}
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

const SentEmailsWidget: React.FC<WidgetProps> = (props) => {
  const { data } = props;
  const { t } = useTranslation('propertiesPage');
  const [openModal, setOpenModal] = useState(false);

  return (
    <WidgetWrapper
      emptyData={!data['sent-emails']?.length}
      title={'sent_Emails'}
      linkText={'ViewAll'}
      link={null}
      onButtonClick={() => setOpenModal(true)}
    >
      <div className="w-full flex-col justify-center items-start gap-2 inline-flex">
        <div className=" pb-2 w-full grid grid-cols-3">
          <div className="text-zinc-900 text-sm font-medium font-['Inter'] leading-1">
            {t('Reference')}
          </div>
          <div className="text-zinc-900 text-sm font-medium font-['Inter'] leading-1">
            {t('contact_Name')}
          </div>
          <div className="text-zinc-900 text-sm font-medium font-['Inter'] ml-2 leading-1">
            {t('Type')}
          </div>
        </div>
        {data['sent-emails']?.map((item, index) => (
          <Item key={index} item={item} />
        ))}
      </div>

      <SentEmailsPopup
        title={t('View_sent_emails')}
        openModal={openModal}
        onClose={() => setOpenModal(false)}
      />
    </WidgetWrapper>
  );
};

export default SentEmailsWidget;
