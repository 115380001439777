import classNames from 'classnames';
import { matchingStatuses } from './index';
import { useTranslation } from 'react-i18next';
import {
  IMercureMatching,
  IMercureMatchingTime,
} from '../../../../types/matching';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useEffect, useState } from 'react';

export const statuses: {
  [key: string]: string;
} = {
  '1': 'Pending',
  '2': 'Running',
  '3': 'Ready',
  '4': 'Empty',
  '5': 'Error',
};

export const getStatus = (statusString: string) => {
  if (statusString === 'completed') {
    return matchingStatuses.READY;
  }

  return matchingStatuses.RUNNING;
};

export function formatRemainingTime(seconds: number, t: any): string {
  const days = Math.floor(seconds / (24 * 3600));
  seconds %= 24 * 3600;
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  seconds %= 60;

  if (days > 0) {
    return `${Math.round(days)} ${
      days > 1
        ? t('propertiesPage:days_multiple')
        : t('propertiesPage:days_single')
    }`;
  } else if (hours > 0) {
    return `${Math.round(hours)} ${
      hours > 1
        ? t('propertiesPage:hours_multiple')
        : t('propertiesPage:hours_single')
    }`;
  } else if (minutes > 0) {
    return `${Math.round(minutes)} ${
      minutes > 1
        ? t('propertiesPage:minutes_multiple')
        : t('propertiesPage:minutes_single')
    }`;
  } else {
    return `${Math.round(seconds)} ${
      seconds > 1
        ? t('propertiesPage:seconds_multiple')
        : t('propertiesPage:seconds_single')
    }`;
  }
}

const MatchingStatus = ({
  status,
  remainingTime,
  mercureNotifications,
  spentTime,
  id,
}: {
  id: number;
  status: number;
  spentTime: number;
  remainingTime: IMercureMatchingTime | null;
  mercureNotifications: IMercureMatching | null;
}) => {
  const [time, setTime] = useState<number>(0);
  const [updatedStatus, setUpdatedStatus] = useState<number>(status);
  const { t } = useTranslation('matching');

  useEffect(() => {
    if (remainingTime && remainingTime.matching === id) {
      setTime(remainingTime.remaining_time);
    }
  }, [remainingTime]);

  useEffect(() => {
    if (mercureNotifications && mercureNotifications.matching === id) {
      setUpdatedStatus(getStatus(mercureNotifications.status));
    }
  }, [mercureNotifications]);

  return (
    <div className={'flex flex-col gap-2 items-start'}>
      <div
        className={classNames(
          'h-6 px-2 py-1 rounded border justify-start items-center gap-1 inline-flex',
          {
            'border-[#ED6C02]':
              updatedStatus === matchingStatuses.PENDING ||
              updatedStatus === matchingStatuses.RUNNING,
            'border-green-500': updatedStatus === matchingStatuses.READY,
            'border-blue': updatedStatus === matchingStatuses.EMPTY,
            'border-red': updatedStatus === matchingStatuses.ERROR,
          }
        )}
      >
        <div
          className={classNames('text-sm font-normal leading-none', {
            'text-[#ED6C02]':
              updatedStatus === matchingStatuses.PENDING ||
              updatedStatus === matchingStatuses.RUNNING,
            'text-green-500': updatedStatus === matchingStatuses.READY,
            'text-blue': updatedStatus === matchingStatuses.EMPTY,
            'text-red': updatedStatus === matchingStatuses.ERROR,
          })}
        >
          {t(`${statuses[updatedStatus]}`)}
        </div>
      </div>
      {time ? (
        <div
          className={classNames(
            'self-stretch justify-start items-center gap-1 inline-flex',
            {
              'text-[#ED6C02]':
                updatedStatus === matchingStatuses.PENDING ||
                updatedStatus === matchingStatuses.RUNNING,
              'text-green-500': updatedStatus === matchingStatuses.READY,
              'text-blue': updatedStatus === matchingStatuses.EMPTY,
              'text-red': updatedStatus === matchingStatuses.ERROR,
            }
          )}
        >
          <AccessTimeIcon fontSize={'small'} color={'inherit'} />
          <div className="text-[11px] font-medium leading-none flex flex-wrap gap-1">
            <span className={'first-letter:uppercase'}>
              {t('propertiesPage:ready_in')}{' '}
            </span>
            <span>{formatRemainingTime(time, t)}</span>
          </div>
        </div>
      ) : (
        <>
          {spentTime ? (
            <div
              className={classNames(
                'self-stretch justify-start items-center gap-1 inline-flex'
              )}
            >
              <AccessTimeIcon fontSize={'small'} color={'inherit'} />
              <div className="text-[11px] font-medium leading-none flex flex-wrap gap-1">
                <span>{formatRemainingTime(spentTime, t)}</span>
                <span className={''}> {t('propertiesPage:spent')}</span>
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default MatchingStatus;
