import { ActionData } from '../../types/actionData';
import { TemplateApi } from '../../types/brochureEditor';
import EditIcon from '../../features/icons/editIcon';
import ActionMenu from '../../components/common/actionMenu';
import { useAnchorElement } from '../../hooks/useAnchorElement';

const BrochureTemplatesListingActionMenu = ({ row }: { row: TemplateApi }) => {
  const menuActionData: ActionData[] = [
    {
      label: 'usersPage:Edit_info',
      icon: EditIcon,
      link: `/brochure-templates/${row.id}`,
    },
  ];

  const anchor = useAnchorElement();

  return (
    <ActionMenu
      {...anchor}
      menuAction={menuActionData}
      transPage={'brochureEditor'}
    />
  );
};

export default BrochureTemplatesListingActionMenu;
