import { ReactNode } from 'react';

function LandingContainer({ children }: { children: ReactNode }) {
  return (
    <div className="max-w-[1920px] px-4 sm:px-8 xl:px-16 mx-auto">
      {children}
    </div>
  );
}

export default LandingContainer;
