import SpinnerLoading from '../../../features/spinner/spinnerLoading';

const GalleryFieldThumbLoading = () => (
  <div style={{ position: 'relative', maxWidth: '168px' }}>
    <div
      style={{
        border: '1px solid #E6E8EC',
        padding: '4px',
        width: '168px',
        height: '168px',
        objectFit: 'contain',
        display: 'grid',
        placeItems: 'center',
      }}
    >
      <SpinnerLoading />
    </div>
  </div>
);

export default GalleryFieldThumbLoading;
