import { IconProps } from './locationIcon';

export default function CheckedIcon({ color, className }: IconProps) {
  color = color || 'green';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.5 8l3 3 6-6"
      ></path>
    </svg>
  );
}
