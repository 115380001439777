import { useTranslation } from 'react-i18next';
import { PropertyWidgetPreviewProps } from '../../../../../types/brochureEditor';
import TableWidget from '../../tableWidget';

const PropertyRoomsWidget = ({
  property,
  widgetOptions,
}: PropertyWidgetPreviewProps) => {
  const { t } = useTranslation('propertiesPage');

  return (
    <TableWidget
      title={t('rooms')}
      items={[
        {
          id: '7',
          label: t('rooms'),
          value: String(property.rooms),
        },
        {
          id: '8',
          label: t('Bedrooms'),
          value: String(property.bedrooms),
        },
        {
          id: '9',
          label: t('Bathrooms'),
          value: String(property.bathrooms),
        },
      ]}
      widgetOptions={widgetOptions}
    />
  );
};

export default PropertyRoomsWidget;
