import React, { useState, useEffect, useRef } from 'react';
import 'airbnb-browser-shims';
import 'react-dates/initialize';
// @ts-ignore
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import './react_dates_overrides.css';
import CalendarItem from './item';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { IContactTask } from '../../../../../types/contacts';
import { useWindowSize } from 'react-use';

const sortDate = (a: IContactTask, b: IContactTask) => {
  if (!a.startDate) return -1;
  if (!b.startDate) return 0;
  const aDate = new Date(a.startDate);
  const bDate = new Date(b.startDate);
  return aDate.getTime() - bDate.getTime();
};

function Calendar(props: {
  events: IContactTask[];
  onAddNewItemClick: (date: string) => void;
  onDateChange?: (date: any) => void;
  onOpenTask: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: IContactTask
  ) => void;
}) {
  const { events, onAddNewItemClick, onDateChange, onOpenTask } = props;
  const ref = useRef<any | null>(null);
  const [currentDate, setCurrentDate] = useState<string | null>(null);
  const [currentFocused, setCurrentFocused] = useState(true);
  const [daySize, setDaySize] = useState(0);
  const size = useWindowSize();
  const def = 1580;

  const changeDate = (date: any) => {
    setCurrentDate(date);

    if (onDateChange) {
      onDateChange(date);
    }
  };

  useEffect(() => {
    if (ref.current) {
      const width = ref.current.offsetWidth;

      setDaySize(Math.round(width / 7));
    }
  }, [ref.current]);

  function renderDayContents(data: any) {
    const dayStartTime = data._d as Date;
    dayStartTime.setHours(0);
    dayStartTime.setMinutes(0);
    dayStartTime.setSeconds(0);
    const day = dayStartTime.getDate();

    const dayEndTime = new Date(data._d);
    dayEndTime.setHours(23);
    dayEndTime.setMinutes(59);
    dayEndTime.setSeconds(59);
    const dayEvents = events
      ? events
          .filter((item) => item.type)
          .filter((item) => {
            if (!item.startDate || !item.endDate) return false;

            const startDate = new Date(item.startDate);
            const endDate = new Date(item.endDate);

            const hasOverlapOnDayStart =
              startDate < dayStartTime && dayStartTime < endDate;
            const hasOverlapOnDayEnd =
              startDate < dayEndTime && dayEndTime < endDate;
            const includes = dayStartTime < startDate && endDate < dayEndTime;

            return hasOverlapOnDayStart || hasOverlapOnDayEnd || includes;
          })
          .sort(sortDate)
      : [];

    return (
      <CalendarItem
        dayEvents={dayEvents}
        day={day}
        onAddClick={() => onAddNewItemClick(dayStartTime.toString())}
        onOpenTask={onOpenTask}
        daySize={daySize}
      />
    );
  }

  return (
    <div ref={ref} className="calendar-single">
      <SingleDatePicker
        date={currentDate} // momentPropTypes.momentObj or null
        onDateChange={(date: any) => changeDate(date)} // PropTypes.func.isRequired
        focused={currentFocused} // PropTypes.bool
        onFocusChange={() => setCurrentFocused(true)} // PropTypes.func.isRequired
        id="5885" // PropTypes.string.isRequired,
        numberOfMonths={1}
        noBorder={true}
        transitionDuration={0}
        daySize={daySize}
        readOnly
        hideKeyboardShortcutsPanel
        enableOutsideDays
        displayFormat="MMM Y"
        weekDayFormat={size.width > def ? 'dddd' : 'dd'}
        renderDayContents={renderDayContents}
        firstDayOfWeek={1}
        disabled
        keepOpenOnDateSelect
        navPrev={
          <button
            className={
              'w-12 h-12 p-4 bg-gray-100 justify-center items-center flex'
            }
          >
            <ArrowBackIosIcon fontSize={'small'} />
          </button>
        }
        navNext={
          <button
            className={
              'w-12 h-12 p-4 bg-gray-100 justify-center items-center flex'
            }
          >
            <ArrowForwardIosIcon fontSize={'small'} />
          </button>
        }
      />
    </div>
  );
}

export default Calendar;
