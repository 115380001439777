import TextField, {
  TextFieldProps,
  TextFieldVariants,
} from '@mui/material/TextField';
import { forwardRef, useState } from 'react';
import classNames from 'classnames';

import InputLayout from './components/inputLayout';
import { useTranslation } from 'react-i18next';
import { UseFormSetValue } from 'react-hook-form';

/**
 * Imporant note: inputProps and InputProps are diferent proprs of MUI TextField!
 * https://stackoverflow.com/questions/69870930/inputprops-vs-inputprops-in-material-ui-textfield
 *
 * inputProps = HTML attributes of <input /> (`type`, `inputmode`, `step` etc)
 * InputProps = props of MUI Input component (`startAdornment`, `endAdornment` etc)
 */

type Props = TextFieldProps & {
  className?: string;
  type?: 'text' | 'number' | 'email' | 'url';
  isRequired?: boolean;
  width?: string;
  inputVariant?: TextFieldVariants;
  label?: string;
  defaultValue?: string;
  isSmall?: boolean;
  surfaceSpan?: boolean;
  withIcon?: boolean;
  icon?: JSX.Element;
  TextTransform?: boolean;
  btnFromValue?: boolean;
  btnFrom?: string;
  setValue?: UseFormSetValue<any>;
};

const InputFormField = forwardRef<HTMLInputElement, Props>(
  (
    {
      label,
      btnFrom,
      btnFromValue,
      setValue,
      surfaceSpan,
      className,
      width,
      isRequired = false,
      type,
      defaultValue,
      InputProps,
      isSmall = false,
      withIcon = false,
      icon,
      inputVariant,
      TextTransform,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation('propertiesPage');
    const [btnFormdefaultValue, setBtnFormdefaultValue] = useState<
      boolean | undefined
    >(btnFromValue);

    return (
      <InputLayout
        capitalize={TextTransform}
        label={label}
        className={width}
        isRequired={isRequired}
      >
        <div className={`relative location_input ${surfaceSpan && 'flex'}`}>
          {surfaceSpan && (
            <button
              className={`${
                btnFormdefaultValue ? 'bg-blue-600' : 'bg-[#e1e4e5]'
              } w-[130px] mr-5 flex items-center justify-around`}
              style={{
                border: '1px solid #bdbdbf',
                borderRadius: '5px',
              }}
              onClick={(e) => {
                e.preventDefault();

                if (setValue && btnFrom) {
                  if (btnFormdefaultValue) {
                    setValue(btnFrom, !btnFormdefaultValue);
                  } else {
                    setValue(btnFrom, true);
                  }
                }
                setBtnFormdefaultValue(!btnFormdefaultValue);
              }}
            >
              <span
                className={`${
                  btnFormdefaultValue ? 'text-white' : 'text-[#a5a6a7]'
                }`}
              >
                {t('from')}
              </span>
            </button>
          )}
          <TextField
            type={type}
            defaultValue={defaultValue || ''}
            className={className}
            variant={inputVariant == undefined ? 'outlined' : inputVariant}
            ref={ref}
            required={isRequired}
            classes={{ root: 'w-full' }}
            InputProps={{
              ...InputProps,
              classes: {
                input: classNames({
                  'underline underline-offset-2 decoration-2': type === 'url',
                  '!py-[13px] !text-sm lg:!text-base !pl-2 lg:!pl-[15px]':
                    isSmall,
                }),
              },
            }}
            {...props}
          />
          {withIcon && <div className="absolute top-4 right-2"> {icon}</div>}
        </div>
      </InputLayout>
    );
  }
);

InputFormField.displayName = 'InputFormField';

export default InputFormField;
