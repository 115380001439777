import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { stepperFormId } from '../../../../../const';
import { IProperty, PropertyStepperStep } from '../../../../../types/property';
import { IMedia } from '../../../../../types/api';

import InputFormField from '../../../../../components/form/inputFormField';
import StepsFieldset from '../../../../../components/stepper/stepsFieldset';
import GalleryField from '../../../../../components/form/galleryField';
import PropertyImageToggles from './imageToggles';
import { useTranslation } from 'react-i18next';
import DocumentsField from '../../../../../components/form/documentsField';
import { useProtectedForm } from '../../../../../hooks/useProtectedForm';
import { guaranteeArray } from '../../../../../helpers/utils/formatters';

const defaultVal: IMedia[] = [];

const PropertyMediaStep: FC<PropertyStepperStep> = ({
  defaultValues,
  onSubmit,
  mutate,
  serverData,
}) => {
  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useProtectedForm<IProperty>({ defaultValues }, serverData);
  const { id } = useParams();
  const { t } = useTranslation('propertiesPage');
  const [imageList, setImageList] = useState<IMedia[]>([]);
  const [pdfList, setPdfList] = useState<IMedia[]>([]);
  const [isPublished, setIsPublished] = useState<boolean | undefined>(false);
  useEffect(() => {
    if (
      serverData &&
      serverData.media &&
      !Array.isArray(serverData.media) &&
      serverData.media.images
    ) {
      setImageList(serverData.media.images.sort((a, b) => a.sort - b.sort));
    }
  }, [serverData]);

  useEffect(() => {
    if (
      serverData &&
      serverData.media &&
      !Array.isArray(serverData.media) &&
      serverData.media.pdf
    ) {
      setPdfList(serverData.media.pdf);
    }
    if (serverData?.publishGateways) {
      setIsPublished(guaranteeArray(serverData.publishGateways).length > 0);
    }
  }, [serverData]);

  return (
    <form id={stepperFormId} onSubmit={handleSubmit(onSubmit)}>
      <div className="grid gap-16">
        <StepsFieldset title={t('Upload_gallery_images')}>
          <div className="col-span-2">
            <GalleryField
              isSubmitting={isSubmitting}
              fileUploadUrl={`/v1/properties/media/${id}`}
              sortMediaUrl={`/v1/properties/${id}/media/sort`}
              isMultiple
              name="images"
              media={imageList || defaultVal}
              setMedia={setImageList}
              mutate={mutate}
              accept="image/png, image/gif, image/jpeg, image/webp"
              TogglesComponent={PropertyImageToggles}
              isPublished={isPublished}
            />
          </div>
        </StepsFieldset>
        <StepsFieldset title={t('Video_Link')}>
          <div className="col-span-4">
            <InputFormField type="url" {...register('videoLinks.0.url')} />
          </div>
        </StepsFieldset>
        <StepsFieldset title={t('Virtual_3D_Tour')}>
          <div className="col-span-2">
            <InputFormField type="url" {...register('links3d.0.url')} />
          </div>
        </StepsFieldset>
        <StepsFieldset title={t('Documents')}>
          <div className="col-span-2">
            <DocumentsField
              fileUploadUrl={`/v1/properties/media/${id}`}
              isMultiple
              name="documents"
              media={pdfList || defaultVal}
              mutate={mutate}
            />
          </div>
        </StepsFieldset>
      </div>
    </form>
  );
};

export default PropertyMediaStep;
