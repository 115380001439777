import {
  Autocomplete,
  Box,
  MenuItem,
  MenuList,
  TextField,
} from '@mui/material';
import { SyntheticEvent } from 'react';
import classNames from 'classnames';

import InputLayout from '../../../../../../components/form/components/inputLayout';
import { IContact } from '../../../../../../types/contacts';
import { MenuListButton } from '../../../../../../components/form/menuListButton';
import ContactsAutocompleteOption from './autocompleteOption';
import ContactPopup from './contactPopup';
import { useTranslation } from 'react-i18next';
import { IContactVisit } from '..';

const ContactsMultiselect = ({
  contacts = [],
  value = [],
  externalVisits,
  onChange,
  mainContact,
  onChangeMainContact,
  label,
  title,
}: {
  contacts?: IContact[];
  externalVisits?: IContactVisit[];
  value?: string[];
  onChange?: (newVal: string[]) => unknown;
  mainContact?: string;
  onChangeMainContact?: (newVal: string) => unknown;
  label?: string;
  title: string;
}) => {
  const contactsHash: Record<string, IContact> = contacts.reduce(
    (prev, curr) => ({ ...prev, [curr.id]: curr }),
    {}
  );

  const contactsVisitsHash: Record<string, IContactVisit> =
    externalVisits?.reduce(
      (prev, curr) => ({ ...prev, [curr.id]: curr }),
      {}
    ) || {};

  const { t } = useTranslation('common');
  const handleChangeContacts = (e: SyntheticEvent, selection: IContact[]) => {
    onChange?.([...value, ...selection.map((option) => String(option.id))]);
  };

  const handleChangeExternalVisits = (
    e: SyntheticEvent,
    selection: IContactVisit
  ) => {
    onChange?.([selection.id.toString()]);
  };

  const removeFromSelection = (id: string) => {
    const index = value.indexOf(id);
    if (index !== -1) {
      onChange?.([...value.slice(0, index), ...value.slice(index + 1)]);
    }
  };

  return (
    <div className="grid grid-cols-5 gap-16 items-start">
      <div className="col-span-2">
        <InputLayout label={label}>
          {externalVisits ? (
            <Autocomplete
              options={externalVisits.filter(
                (contact) => !value.includes(String(contact.id))
              )}
              value={undefined}
              onChange={handleChangeExternalVisits}
              disableClearable
              getOptionLabel={(option) => {
                return `${option?.fullname}`;
              }}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  <ContactsAutocompleteOption optionVisit={option} visit />
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} margin="normal" />
              )}
              className="child-input-margin-0"
            />
          ) : (
            contacts && (
              <Autocomplete
                options={contacts.filter(
                  (contact) => !value.includes(String(contact.id))
                )}
                value={[]}
                onChange={handleChangeContacts}
                disableClearable
                getOptionLabel={(option) => {
                  return `${
                    option?.individual?.lastname +
                    ' ' +
                    option?.individual?.firstname
                  }  ${option?.individual?.email} `;
                }}
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    <ContactsAutocompleteOption option={option} />
                  </li>
                )}
                multiple
                renderInput={(params) => (
                  <TextField {...params} margin="normal" />
                )}
                className="child-input-margin-0"
              />
            )
          )}
        </InputLayout>
      </div>

      <div className="col-span-3">
        <InputLayout capitalize={false} label={t(title)}>
          <div className="flex flex-wrap items-start gap-4">
            {value.map((contactId) => {
              const isMainContact = contactId === mainContact;
              return (
                <Box
                  className={classNames('hover:cursor-pointer', {
                    '!text-blue !border !border-blue': isMainContact,
                  })}
                  sx={[
                    {
                      position: 'relative',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '12px 16px',
                      height: '40px',
                      background: '#F5F5F7',
                      color: '#6E6E72',
                      border: '1px solid #F5F5F7',
                      borderRadius: '4px',
                    },
                  ]}
                  key={contactId}
                >
                  {externalVisits ? (
                    <ContactPopup
                      visit
                      isMain={isMainContact}
                      option={contactsVisitsHash[contactId]}
                    />
                  ) : (
                    contacts && (
                      <ContactPopup
                        isMain={isMainContact}
                        option={contactsHash[contactId]}
                      />
                    )
                  )}
                  <MenuListButton>
                    <MenuList>
                      {onChangeMainContact && (
                        <MenuItem
                          onClick={() => onChangeMainContact(contactId)}
                        >
                          {t('Set_as_primary_owner')}
                        </MenuItem>
                      )}
                      <MenuItem onClick={() => removeFromSelection(contactId)}>
                        {t('Delete')}
                      </MenuItem>
                    </MenuList>
                  </MenuListButton>
                </Box>
              );
            })}
          </div>
        </InputLayout>
      </div>
    </div>
  );
};

export default ContactsMultiselect;
