import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { IPromotionApi } from '../../../../types/promotion';
import LazyPdfPreview from '../../../../components/common/lazyPdfPreview';

interface Props {
  data: IPromotionApi;
}

function PromotionPlan({ data }: Props) {
  const { t } = useTranslation('promotionPage');
  return data.plans && data.plans.length ? (
    <Stack
      className="bg-white border rounded"
      sx={{
        padding: '32px',
        display: 'flex',
        gap: '16px',
      }}
    >
      <Typography variant="h5">{t('Construction Plan')}</Typography>
      <Box
        className={'flex gap-2 rounded-md flex-col'}
        sx={{
          borderWidth: '1px  ',
          padding: '16px',
        }}
      >
        {data.plans.map((item) => (
          <Box key={item.createdAt} className={'flex overflow-hidden'}>
            <LazyPdfPreview
              documentProps={{ file: item.url.original }}
              thumbnailProps={{ className: 'pointer-events-none' }}
              skeletonHeight={200}
            />
          </Box>
        ))}
      </Box>
    </Stack>
  ) : null;
}

export default PromotionPlan;
