import { useState, useEffect } from 'react';

type MercureHookProps = {
  subscribeUrl: string;
  eventTypes: string[];
};

type MercureData<T> = {
  loading: boolean;
  data: T | null;
};

export const useMercure = <T>({
  subscribeUrl,
  eventTypes,
}: MercureHookProps): MercureData<T> => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<T | null>(null);

  useEffect(() => {
    const eventSource = new EventSource(
      `${process.env.REACT_APP_API_HOST}/hub/.well-known/mercure?topic=${process.env.REACT_APP_API_HOST}/${subscribeUrl}`,
      {
        withCredentials: false,
      }
    );

    eventSource.onmessage = (event) => {
      const eventData = JSON.parse(event.data);

      setData(eventData);
      setLoading(false);
    };

    eventSource.onerror = (event) => {
      console.log('event error', event);
    };

    return () => {
      eventSource.close();
    };
  }, [subscribeUrl, eventTypes]);

  return { loading, data };
};

export default useMercure;
