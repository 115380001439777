import { ReactNode } from 'react';
import { LayoutPart as LayoutPartProps } from '../../../types/brochureEditor';
import { useLayoutPartStyles } from '../lib/hooks/useLayoutPartStyles';
import classNames from 'classnames';

const areas = ['a', 'b', 'c'];
const gridTemplateAreas = `'a b c'`;

const justifyContent = ['start', 'center', 'end'];

const LayoutPart = ({
  options,
  withStyles,
  isHeader,
  renderCell,
}: {
  options: LayoutPartProps;
  withStyles?: boolean;
  isHeader?: boolean;
  renderCell?: ({
    area,
    index,
    isHeader,
    isFooter,
    justifyContent,
  }: {
    area: string;
    index: number;
    isHeader?: boolean;
    isFooter?: boolean;
    justifyContent?: string;
  }) => ReactNode;
}) => {
  const style = useLayoutPartStyles(options);

  return (
    <div
      className={classNames('relative min-h-0', {
        'pb-[10.3%]': !options.bgImage,
      })}
      style={withStyles ? style : undefined}
    >
      {options.bgImage && <img src={options.bgImage.url.original} alt="" />}
      <div
        className="absolute inset-0 grid grid-cols-3 gap-[2%] min-h-0 px-[6.9%]"
        style={{ gridTemplateAreas }}
      >
        {areas.map((area, index) => (
          <div
            key={area}
            style={{ gridArea: area }}
            className="grid relative min-h-0"
          >
            {renderCell?.({
              area,
              index,
              isHeader,
              isFooter: !isHeader,
              justifyContent: justifyContent[index],
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LayoutPart;
