import LanguageIcon from '@mui/icons-material/Language';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import classNames from 'classnames';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { IContactListItem } from '../../types/contacts';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { sanitizeUrl } from '../../helpers/utils';

interface Props {
  row: IContactListItem;
}

const isLarge = localStorage.getItem('isLarge');

const Content = (props: Props) => {
  const { row } = props;
  const { t } = useTranslation('common');

  if (row.lead) {
    if (row.sourceWebsite) {
      return (
        <div className={'flex items-center gap-1'}>
          <LanguageIcon fontSize={'small'} className={'mr-2'} />
          <span>Website</span>
        </div>
      );
    }

    if (row.sourceDirect) {
      return (
        <div className={'flex items-center gap-1'}>
          <HomeOutlinedIcon fontSize={'small'} className={'mr-2'} />
          <span>Direct</span>
        </div>
      );
    }

    if (row.sourcePromotionLP || row.sourcePropertyLP) {
      return (
        <div
          className={'flex items-center gap-1 text-ellipsis overflow-hidden'}
        >
          <LanguageIcon fontSize={'small'} className={'mr-2'} />
          <Tooltip
            title={
              row.sourcePromotionLP
                ? `${t('Promotion_Preview_Page')}`
                : `${t('Property_Preview_Page')}`
            }
            arrow
            placement="top"
          >
            <span className={'text-ellipsis overflow-hidden'}>
              {row.sourcePromotionLP
                ? `${t('Promotion_Preview_Page')}`
                : `${t('Property_Preview_Page')}`}
            </span>
          </Tooltip>
        </div>
      );
    }

    if (row.sourcePortals && row.sourcePortals.length) {
      return (
        <div
          className={classNames('flex gap-1 py-1 pb-2', {
            'overflow-x-scroll': row.sourcePortals.length > (isLarge ? 2 : 1),
          })}
        >
          {row.sourcePortals.map((item: { id: number; name: string }) => {
            return (
              <>
                {item.name === 'immobilier.ch' ? (
                  <a
                    target={'_blank'}
                    rel={'noreferrer'}
                    href={sanitizeUrl(item.name)}
                    key={item.name}
                    className={
                      "transition-color duration-300 hover:text-blue after:content-[','] hover:after:text-[#1D1D1F] last:after:hidden"
                    }
                  >
                    {item.name}
                  </a>
                ) : (
                  <span
                    key={item.name}
                    className={
                      "after:content-[','] hover:after:text-[#1D1D1F] last:after:hidden"
                    }
                  >
                    {item.name}
                  </span>
                )}
              </>
            );
          })}
        </div>
      );
    }
  }

  if (row.sourceBroker) {
    return (
      <div className={'flex items-center gap-1'}>
        <PermIdentityIcon fontSize={'small'} />
        <span>
          {row.sourceBroker.legalName ||
            `${row.sourceBroker.firstname} ${row.sourceBroker.lastname}`}
        </span>
      </div>
    );
  }

  return <></>;
};

const ContactSource = (props: Props) => {
  const { row } = props;

  return <Content row={row} />;
};

export default ContactSource;
