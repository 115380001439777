import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';

import { useQuery } from '../../../hooks/useQuery';

export interface ContactData {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
}

export function useAnalytics() {
  const { reference } = useParams();
  const query = useQuery();
  const contact = query.get('contact');
  const isBroker = query.get('broker');

  useEffect(() => {
    if (!isBroker) {
      const body: {
        reference?: string;
        contact?: string;
      } = {
        reference,
      };

      if (contact) {
        body.contact = contact;
      }

      axios.post(
        `${process.env.REACT_APP_API_HOST}/properties/statistics/${
          contact ? 'contact_' : ''
        }counter`,
        body
      );
    }
  }, []);
}
