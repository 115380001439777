import { minMaxOption, buyBudgetOptions, rentBudgetOptions } from './options';
import InputLayout from '../../../../components/form/components/inputLayout';
import { Controller } from 'react-hook-form';
import { MenuItem, Select } from '@mui/material';
import * as React from 'react';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

interface Props {
  type: string;
  control: any;
  resetField: any;
  watch: any;
}

export const buyOptions = [1, 3];
// const rentOptions = ["1","4"]

function filterItems(items: minMaxOption[], value?: number, bigger = true) {
  if (value) {
    return items.filter((el) =>
      bigger ? el.value >= value : el.value <= value
    );
  }

  return items;
}

export function findClosestOption(
  options: minMaxOption[],
  number: number,
  isSmaller: boolean
): minMaxOption | null {
  let closestOption: minMaxOption | null = null;

  for (const currentOption of options) {
    if (
      (currentOption && isSmaller && currentOption.value < number) ||
      (!isSmaller && currentOption && currentOption.value > number)
    ) {
      if (
        !closestOption ||
        Math.abs(currentOption.value - number) <
          Math.abs(closestOption.value - number)
      ) {
        closestOption = currentOption;
      }
    }
  }

  return closestOption;
}

const BudgetFields = ({ type, control, watch, resetField }: Props) => {
  const [minOptions, setMinOptions] = useState<minMaxOption[]>([]);
  const [maxOptions, setMaxOptions] = useState<minMaxOption[]>([]);
  const isBuyType = type && buyOptions.includes(parseInt(type));
  const watchMin = watch('budgetMin');
  const watchMax = watch('budgetMax');

  useEffect(() => {
    const options = isBuyType ? buyBudgetOptions : rentBudgetOptions;

    resetField('budgetMin');
    resetField('budgetMax');
    setMinOptions(options);
    setMaxOptions(options);
  }, [type]);

  useEffect(() => {
    const options = isBuyType ? buyBudgetOptions : rentBudgetOptions;

    if (watchMin) {
      setMaxOptions(filterItems(options, watchMin));
    }

    if (watchMax) {
      setMinOptions(filterItems(options, watchMax, false));
    }
  }, [watchMin, watchMax]);

  return (
    <>
      <InputLayout
        className={classNames('', {
          'opacity-50 pointer-events-none': !type,
        })}
        label={'Min (CHF)'}
      >
        <Controller
          name="budgetMin"
          control={control}
          render={({ field }) => (
            <Select
              disabled={!type}
              className={'h-[56px]'}
              labelId="level-label"
              {...field}
            >
              {minOptions.map((item, index: number) => (
                <MenuItem
                  key={index}
                  value={item?.value}
                  className="capitalize"
                >
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </InputLayout>
      <InputLayout
        className={classNames('', {
          'opacity-50 pointer-events-none': !type,
        })}
        label={'Max (CHF)'}
      >
        <Controller
          name="budgetMax"
          control={control}
          render={({ field }) => (
            <Select
              disabled={!type}
              className={'h-[56px]'}
              labelId="level-label"
              {...field}
            >
              {maxOptions.map((item, index: number) => (
                <MenuItem
                  key={index}
                  value={item?.value}
                  className="capitalize"
                >
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </InputLayout>
    </>
  );
};

export default BudgetFields;
