const ChartLegend = ({
  legend,
}: {
  legend: { title: string; color: string }[];
}) => {
  return (
    <div className="info">
      {legend.map(({ color, title }, index) => {
        return (
          <div key={index}>
            <span className="dot" style={{ backgroundColor: color }} />
            <span>{title}</span>
          </div>
        );
      })}
    </div>
  );
};

export default ChartLegend;
