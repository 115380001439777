import { CSSProperties, useRef } from 'react';
import { Box } from '@mui/material';
import { GoogleMap, MarkerF } from '@react-google-maps/api';

import { useGoogleApis } from '../../hooks/useGoogleApis';

const GoogleMapContainer = ({
  coordinates,
  containerStyle = { width: '100%', height: '385px' },
  mapOptions = {
    zoomControl: false,
    streetViewControl: false,
    disableDefaultUI: true,
  },
  zoom = 12,
}: {
  coordinates: google.maps.LatLngLiteral;
  containerStyle?: CSSProperties;
  mapOptions?: google.maps.MapOptions;
  zoom?: number;
}) => {
  const { isLoaded } = useGoogleApis();

  const mapRef = useRef<google.maps.Map | null>(null);
  const onLoad = (map: google.maps.Map): void => {
    mapRef.current = map;
  };
  const onUnmount = (): void => {
    mapRef.current = null;
  };

  if (!isLoaded) return <div>Loading ...</div>;

  return (
    <Box>
      <GoogleMap
        id="marker-example"
        mapContainerStyle={containerStyle}
        options={mapOptions}
        center={coordinates}
        zoom={zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <MarkerF
          visible
          position={coordinates}
          animation={google.maps.Animation.DROP}
        />
      </GoogleMap>
    </Box>
  );
};

export default GoogleMapContainer;
