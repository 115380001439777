import { mutate } from 'swr';
import { useLocation } from 'react-router-dom';

import { ActionData } from '../../types/actionData';
import { http } from '../../libs/axios';
import { useAnchorElement } from '../../hooks/useAnchorElement';
import { useNotification } from '../../hooks/useNotification';
import ActionMenu from '../common/actionMenu';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';
import PublicIcon from '@mui/icons-material/Public';
import EditIcon from '../../features/icons/editIcon';
import RestoreIcon from '../../features/icons/restoreIcon';
import RemoveIcon from '../../features/icons/removeIcon';
import PDFIcon from '../../features/icons/pdfIcon';
import PromotionLandingPopup from '../../pages/promotions/landingPopup';
import { useTranslation } from 'react-i18next';

const PromotionsListingActionMenu = ({
  row,
  isList,
  action,
}: {
  row: { id: number; reference: string };
  isList?: boolean;
  action?: string;
}) => {
  const { id } = row;
  const { addNotification } = useNotification();
  const location = useLocation();
  const { pathname } = location;
  const isArchivedPromotion = pathname?.includes('archived');
  const { t } = useTranslation();
  const listOnPageInfos = JSON.parse(
    localStorage.getItem('promotionlistInfos') as string
  );

  async function archivePromotion({
    itemsPerPage,
    page,
  }: {
    itemsPerPage: number;
    page: number;
  }) {
    try {
      await http.patch(`/v1/promotions/archive`, {
        toArchive: [id],
      });
      await mutate(
        `/v1/promotions/archived?page=${page}&itemsPerPage=${itemsPerPage}`
      );
      addNotification(
        `${t('common:promotion_has_been_successfully_archived')}`
      );
      await mutate(`/v1/promotions?page=${page}&itemsPerPage=${itemsPerPage}`);
    } catch (event: unknown) {
      addNotification(`${t('common:error_on_deleting_promotion')}`);
    }
  }

  async function restorePromotion({
    itemsPerPage,
    page,
  }: {
    itemsPerPage: number;
    page: number;
  }) {
    try {
      await http.patch(`/v1/promotions/unarchive`, {
        toArchive: [id],
      });
      await mutate(
        `/v1/promotions/archived?page=${page}&itemsPerPage=${itemsPerPage}`
      );
      addNotification(
        `${t('common:promotion_has_been_successfully_restored')}`
      );
    } catch (event: unknown) {
      addNotification(`${t('common:error_on_restoring_promotion')}`);
    }
  }

  const landingHash = `#landing?id=${row.id}`;
  const menuActionData: ActionData[] = [
    {
      label: 'edit_promotion',
      link: `/promotions/${row?.id}/edit`,
      icon: EditIcon,
      hide: action,
      class: '!hidden md:!block',
    },
    {
      label: 'View_details',
      icon: VisibilityOutlined,
      link: `/promotions/${row.id}`,
    },
    isArchivedPromotion
      ? {
          label: 'propertiesPage:restorePropperties',
          icon: RestoreIcon,
          onClick: () => restorePromotion(listOnPageInfos),
        }
      : {
          label: 'propertiesPage:archive',
          icon: RemoveIcon,
          onClick: () => archivePromotion(listOnPageInfos),
        },
    {
      label: 'common:brochures',
      icon: PDFIcon,
      link: `/promotions/${row.id}/brochures`,
    },
    {
      label: 'propertiesPage:landing_page',
      icon: PublicIcon,
      link: `${location.pathname}${landingHash}`,
    },
  ];

  const anchor = useAnchorElement();

  return (
    <>
      <ActionMenu
        {...anchor}
        isList={isList}
        menuAction={menuActionData}
        transPage={'promotionPage'}
        action={action}
      />
      <PromotionLandingPopup hash={landingHash} promotion={row} />
    </>
  );
};

export default PromotionsListingActionMenu;
