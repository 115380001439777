import { Controller, useForm } from 'react-hook-form';
import { FC, useEffect } from 'react';
import { IProperty, PropertyStepperStep } from '../../../../../types/property';
import { stepperFormId } from '../../../../../const';
import StepsFieldset from '../../../../../components/stepper/stepsFieldset';
import { useTranslation } from 'react-i18next';
import SelectFormField from '../../../../../components/form/selectFormField';
import {
  Box,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
} from '@mui/material';
import InputLayout from '../../../../../components/form/components/inputLayout';
import NetworksSelection from './networksSelection';
import InputFormField from '../../../../../components/form/inputFormField';
import { http } from '../../../../../libs/axios';
import { useNotification } from '../../../../../hooks/useNotification';

export const visibilityOptions = [
  {
    value: 'private',
    id: 1,
  },
  {
    value: 'agency',
    id: 2,
  },
  {
    value: 'network',
    id: 3,
  },
];

const PropertyVisibilityStep: FC<PropertyStepperStep> = ({
  defaultValues,
  serverData,
  mutate,
}) => {
  const { register, handleSubmit, watch, control, setValue, getValues } =
    useForm<IProperty>({
      defaultValues,
    });
  const { t } = useTranslation('propertiesPage');
  const { addNotification } = useNotification();

  const onSubmit = async (data: any) => {
    const body = {
      visibility: data.visibility,
      restrictedShare: data.restrictedShare === 'true',
      commissionRate: data.commissionRate,
      isCommissionRateHt: data.isCommissionRateHt,
      commissionRateShared: data.commissionRateShared,
      isCommissionRateSharedHt: data.isCommissionRateSharedHt,
      networks: data.networks,
    };

    await http
      .patch(`/v1/properties/${serverData?.id}/visibility`, body)
      .then((res) => {
        if (res.status === 200) {
          if (mutate) {
            mutate();
          }

          addNotification(`Property has been successfully updated`);
        }
      });
  };

  useEffect(() => {
    if (watch('visibility') !== 3) {
      setValue('restrictedShare', true);
      setValue('networks', []);
      setValue('commissionRate', 0);
      setValue('commissionRateShared', 0);
      setValue('isCommissionRateHt', false);
      setValue('isCommissionRateSharedHt', false);
    }
  }, [watch('visibility')]);

  return (
    <form
      id={stepperFormId}
      onSubmit={handleSubmit(onSubmit)}
      className="grid gap-16"
    >
      <StepsFieldset className="grid grid-cols-2" title={t('Visibility')}>
        <div>
          <SelectFormField
            label={t('Visibility')}
            isRequired
            defaultValue={getValues('visibility')}
            options={visibilityOptions}
            {...register(`visibility`)}
          />
        </div>
      </StepsFieldset>
      {watch('visibility') === 3 ? (
        <StepsFieldset className="grid grid-cols-2" title={t('MLS_Networks')}>
          <div className={'col-span-2'}>
            <InputLayout
              isRequired={watch('visibility') === 3}
              label={t('Share_type')}
            >
              <Controller
                control={control}
                name="restrictedShare"
                render={({ field }) => (
                  <RadioGroup
                    sx={{
                      flexDirection: 'row',
                    }}
                    {...field}
                  >
                    <FormControlLabel
                      sx={{
                        '& .MuiFormControlLabel-label': {
                          fontSize: '12px',
                        },
                      }}
                      value={true}
                      control={<Radio className="contact_gender" />}
                      label={t('Restricted')}
                    />
                    <FormControlLabel
                      sx={{
                        '& .MuiFormControlLabel-label': {
                          fontSize: '12px',
                        },
                      }}
                      value={false}
                      control={<Radio className="contact_gender" />}
                      label={t('Advanced')}
                    />
                  </RadioGroup>
                )}
              />
              <NetworksSelection
                setValue={setValue}
                defaultValue={getValues('networks')}
              />
            </InputLayout>
            <div className={'grid grid-cols-2 pt-12 gap-4'}>
              <div>
                <InputFormField
                  className="Commission_For_The_Broker_In_Charge_property"
                  type="number"
                  label={t('Commission_Rate')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                  }}
                  isRequired={watch('visibility') === 3}
                  {...register(`commissionRate`, {
                    valueAsNumber: true,
                  })}
                />

                {watch('commissionRate') ? (
                  <Box className="flex items-center gap-4">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(watch('isCommissionRateHt'))}
                          {...register('isCommissionRateHt', {
                            onChange: () =>
                              setValue('isCommissionRateHt', true),
                          })}
                          color="primary"
                        />
                      }
                      label="HT"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(watch('isCommissionRateTTC'))}
                          {...register('isCommissionRateTTC', {
                            onChange: () =>
                              setValue('isCommissionRateHt', false),
                          })}
                          color="primary"
                        />
                      }
                      label="TTC"
                    />
                  </Box>
                ) : null}
              </div>
              <div>
                <InputFormField
                  className="Commission_For_The_Broker_In_Charge_property"
                  type="number"
                  label={t('Commission_Rate_Shared')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                  }}
                  isRequired={watch('visibility') === 3}
                  {...register(`commissionRateShared`, {
                    valueAsNumber: true,
                  })}
                />

                {watch('commissionRate') ? (
                  <Box className="flex items-center gap-4">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(watch('isCommissionRateSharedHt'))}
                          {...register('isCommissionRateSharedHt', {
                            onChange: () =>
                              setValue('isCommissionRateSharedHt', true),
                          })}
                          color="primary"
                        />
                      }
                      label="HT"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(watch('isCommissionRateSharedTTC'))}
                          {...register('isCommissionRateSharedTTC', {
                            onChange: () =>
                              setValue('isCommissionRateSharedHt', false),
                          })}
                          color="primary"
                        />
                      }
                      label="TTC"
                    />
                  </Box>
                ) : null}
              </div>
            </div>
          </div>
        </StepsFieldset>
      ) : null}
    </form>
  );
};

export default PropertyVisibilityStep;
