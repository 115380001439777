import { Autocomplete, TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { NameableProperty } from '../../../../types/property';
import usePropertiesAutocomplete from '../../../../hooks/usePropertiesAutocomplete';
import { PreviewSubjectTypes } from '../../../../types/brochureEditor';
import { useHandlePreviewSubjectChange } from '../../lib/hooks/useHandlePreviewSubjectChange';

const PropertySelectionDropdown = ({
  selectedProperty,
  subjectType,
}: {
  selectedProperty?: NameableProperty | null;
  subjectType: PreviewSubjectTypes | null;
}) => {
  const isPromotion = subjectType === PreviewSubjectTypes.PROMOTION;
  const urlTemplate = isPromotion
    ? '/v1/promotions/elastic?page=1&itemsPerPage=100&title={searchQuery}&archive=false&lang={locale}'
    : undefined;
  const { options, setSearchQuery, defaultValue, searchQuery } =
    usePropertiesAutocomplete({
      selectedProperty,
      urlTemplate,
      preventEmptyQuery: !isPromotion,
    });
  const { templateId } = useParams();
  const { t } = useTranslation('brochureEditor');
  const handleChange = useHandlePreviewSubjectChange({
    subjectType,
  });

  return (
    <Autocomplete
      loadingText={!searchQuery ? t('common:start_typing') : undefined}
      loading={!options}
      options={options || []}
      size="small"
      sx={{ width: 250 }}
      filterOptions={(x) => x}
      renderInput={(props) => (
        <TextField placeholder={t('preview_with')} {...props} />
      )}
      onInputChange={(event, newInputValue) => {
        setSearchQuery(newInputValue);
      }}
      defaultValue={defaultValue}
      onChange={(event, newValue) => {
        const value = newValue?.value;
        handleChange({ templateId, value });
      }}
    />
  );
};

export default PropertySelectionDropdown;
