import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { NavLink } from 'react-router-dom';

interface Item {
  slug: string;
  title: string;
  isActive?: boolean;
}

const titleForm = (title: string) => {
  return title.replace(/\s+/g, '-').toLowerCase();
};

function capitalize(s: string) {
  s = s.replace('-', ' ');
  return s[0]?.toUpperCase() + s.slice(1);
}

export function useTranslateTabTitle() {
  const { t } = useTranslation();
  return (title: string) => capitalize(t(`common:${titleForm(title)}`));
}

const Tabs = ({ items = [] }: { items?: Item[] }) => {
  const translateTabTitle = useTranslateTabTitle();
  return (
    <nav className="flex overflow-y-auto">
      <ul className="w-0 flex-grow flex">
        {items.map((item) => (
          <li key={item.slug}>
            <NavLink
              id={item.slug}
              to={item.slug}
              className={({ isActive }) =>
                classNames(
                  'block p-4 pt-[15px] border-t text-[1rem] leading-[1.2] whitespace-nowrap',
                  {
                    'border-transparent text-gray-900': !isActive,
                    'border-blue bg-white text-blue': isActive,
                  }
                )
              }
            >
              {translateTabTitle(item.title)}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Tabs;
