import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import useLocale, { Locale } from '../../../hooks/useLocale';
import ChoiceButton from './choiceButton';
import '../../../features/icons/flags/flags.css';

function LanguageRadioButtons({
  selectedLocale,
  setSelectedLocale,
}: {
  selectedLocale: string;
  setSelectedLocale: (locale: string) => void;
}) {
  const { langues } = useLocale();
  const { t } = useTranslation('common');

  return (
    <div className="flex gap-4">
      {langues.map((lang) => {
        const [entry] = Object.entries(lang);
        const [code, name] = entry as [Locale, string];
        return (
          <ChoiceButton
            key={code}
            isActive={code === selectedLocale}
            onClick={() => setSelectedLocale(code)}
            img={
              <div
                className={classNames([
                  'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flag',
                  getFlagClass(code),
                ])}
              ></div>
            }
          >
            {t(name)}
          </ChoiceButton>
        );
      })}
    </div>
  );
}

export default LanguageRadioButtons;

function getFlagClass(code: string) {
  if (code === 'en') {
    return 'gb';
  }
  return code;
}
