import { Controller, useFormContext } from 'react-hook-form';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import {
  PreviewSubjectTypes,
  TemplateOptions as TemplateOptionsType,
} from '../../../../types/brochureEditor';
import { FileNode } from '../../../../types/hierarchy/legacy';

import { useLayoutEditor } from '../../lib/hooks/useLayoutEditor';

import ColorField from '../../../../components/form/colorField';
import InputFormField from '../../../../components/form/inputFormField';
import SwitcherIosFormField from '../../../../components/form/switcherIosFormField';
import FilterAgencyMultiselect from '../../../../components/form/filterAgencyMultiselect';
import EditorSwitcherLayout from '../editorSwitcherLayout';
import EditorSidebarSection from '../sidebarSection';
import LayoutPartOptions from './layoutPartOptions';
import ChoiceControl from '../controls/choice';
import InputLayout from '../../../../components/form/components/inputLayout';

const TemplateOptions = () => {
  const { register, control, watch, setValue } =
    useFormContext<TemplateOptionsType>();
  const {
    withHeader,
    withFooter,
    toggleHeader,
    toggleFooter,
    header,
    footer,
    setHeaderProperty,
    setFooterProperty,
  } = useLayoutEditor();

  const { t } = useTranslation('brochureEditor');
  const subjectChoices = useMemo(
    () => [
      {
        value: String(PreviewSubjectTypes.PROPERTY),
        label: t('common:Properties'),
      },
      {
        value: String(PreviewSubjectTypes.PROMOTION),
        label: t('common:Promotions'),
      },
    ],
    [t]
  );

  const { data: hierarchy } = useSWR<FileNode>('/users/hierarchy', null, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return (
    <>
      <EditorSidebarSection>
        <InputFormField
          label={t('name')}
          className="bg-white"
          isSmall
          {...register('name')}
        ></InputFormField>
      </EditorSidebarSection>

      <EditorSidebarSection>
        <InputLayout label={t('used_for')}>
          <Controller
            name="usedFor"
            render={({ field }) => (
              <ChoiceControl choices={subjectChoices} {...field} />
            )}
          />
        </InputLayout>
      </EditorSidebarSection>

      <EditorSidebarSection>
        <ColorField
          label={t('brand_color')}
          className="bg-white"
          isSmall
          name="brandColor"
          control={control}
        ></ColorField>
      </EditorSidebarSection>

      {hierarchy && (
        <EditorSidebarSection>
          <FilterAgencyMultiselect
            isRequired
            label={t('common:Agency')}
            name={'departments'}
            node={hierarchy}
            value={watch('departments') || []}
            setValue={setValue}
            className="h-12 bg-white"
            isMultiple={true}
          />
        </EditorSidebarSection>
      )}

      <EditorSidebarSection>
        <EditorSwitcherLayout title={t('header')}>
          <SwitcherIosFormField
            checked={withHeader}
            onChange={(e, checked) => toggleHeader(checked)}
          />
        </EditorSwitcherLayout>
        {withHeader && (
          <LayoutPartOptions values={header} onChange={setHeaderProperty} />
        )}
      </EditorSidebarSection>

      <EditorSidebarSection>
        <EditorSwitcherLayout title={t('footer')}>
          <SwitcherIosFormField
            checked={withFooter}
            onChange={(e, checked) => toggleFooter(checked)}
          />
        </EditorSwitcherLayout>
        {withFooter && (
          <LayoutPartOptions values={footer} onChange={setFooterProperty} />
        )}
      </EditorSidebarSection>
    </>
  );
};
export default TemplateOptions;
