import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

export default function AgencyIcon({ color, className }: IconProps) {
  const { mode } = useThemeMode();
  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        d="M1 5a1 1 0 011-1h12a1 1 0 011 1v9a1 1 0 01-1 1H2a1 1 0 01-1-1V5z"
      ></path>
      <path
        stroke={color}
        d="M6.75 9.857V9.5a1 1 0 011-1h.5a1 1 0 011 1v.357m-2.5 0v.393a1 1 0 001 1h.5a1 1 0 001-1v-.393m-2.5 0L1 8.5m8.25 1.357L15 8.5M11 4V2a1 1 0 00-1-1H6a1 1 0 00-1 1v2"
      ></path>
    </svg>
  );
}
