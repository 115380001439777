import { useThemeMode } from '../../hooks/useThemeMode';
import { IconProps } from './locationIcon';

export default function SettingsBarsIcon({
  color,
  className,
  width = '16',
  height = '16',
}: IconProps) {
  const { mode } = useThemeMode();

  color = color ? color : mode === 'light' ? '#1D1D1F' : '#FFFFFF';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        d="M1 11h7m7 0h-4M1 5h4m10 0H8M5 3v2m0 2V5m6 4v2m0 2v-2"
      ></path>
    </svg>
  );
}
