import { GridColumns } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { Box, Skeleton, Tooltip } from '@mui/material';
import useSWR from 'swr';
import { Link } from 'react-router-dom';

import { IDashboardOfferItem } from '../../../types/user';
import { getInitials } from '../widgets/offers';
import { formatDateAndTime } from '../../../helpers/utils';
import PriceIcon from '../../../features/icons/priceIcon';
import PermissionIcon from '../../../features/icons/permissionIcon';
import useLocale from '../../../hooks/useLocale';
import TabGridTag from '../../../features/tags/tabGridTag';
import { OfferType } from '../../../types/pipelines';

export function formatPriceWithCurrency(
  number?: number | null,
  currency?: string
) {
  if (!number) {
    return '-';
  }
  return new Intl.NumberFormat('de-CH', {
    style: 'currency',
    currency: currency || 'CHF',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(number);
}

export const useOffersListColumns = ({
  isLoading,
}: {
  isLoading?: boolean;
}): GridColumns<IDashboardOfferItem> => {
  const { t } = useTranslation();
  const { locale } = useLocale();

  const commonSettings = {
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
  };

  const { data: offerTypes } = useSWR<OfferType[]>('/offer_types');

  const columns: GridColumns<IDashboardOfferItem> = [
    {
      field: 'property',
      headerName: t('common:Property'),
      minWidth: 450,
      renderCell: ({ row }) => {
        return (
          <div className={'flex gap-2 max-w-full'}>
            <Link
              to={`/properties/${row.propertyId}`}
              id={`img_property_${row.propertyId}`}
              className={
                'shrink-0 max-w-[94px] min-w-[94px] max-h-[88px] bg-gray-400 w-2/5 '
              }
            >
              <Box className="flex items-center relative">
                <Link to={`/properties/${row.propertyId}`}>
                  {row.dealType && (
                    <TabGridTag
                      textContent={row.dealType}
                      textToolTip={row.dealType}
                      className="absolute bg-blue font-bold top-1 left-1 border-0 capitalize"
                      color="white"
                      fontSize="8px"
                    />
                  )}
                  <img
                    className={'w-[95px] h-[62px]'}
                    src={row.media?.url.thumbnail}
                    alt=""
                  />
                </Link>
              </Box>
            </Link>
            <div className={'w-3/5 pt-1 flex flex-col gap-2 items-start'}>
              {row &&
              row.title &&
              row.title[locale] &&
              row.title?.[locale]?.title ? (
                <Link
                  className={'inline-flex max-w-full'}
                  to={`/properties/${row.propertyId}`}
                  id={`img_property_${row.propertyId}`}
                >
                  <Tooltip
                    title={row.title?.[locale]?.title}
                    arrow
                    placement="top"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          width: '11vw',
                        },
                      },
                    }}
                  >
                    <p
                      className={
                        'max-w-full font-medium overflow-ellipsis overflow-hidden'
                      }
                    >
                      {row.title?.[locale]?.title}
                    </p>
                  </Tooltip>
                </Link>
              ) : null}
              <div
                className="inline-flex py-1 px-2 rounded-md items-center"
                style={{ border: '1px solid #3446B9 ' }}
              >
                <PermissionIcon className="mr-2 col-span-1" color="#3446B9" />{' '}
                <span className="text-blue leading-5">{row.reference}</span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      field: 'name',
      headerName: t('common:Name'),
      renderCell: ({ row }) => {
        return (
          <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex overflow-y-hidden">
            <div className="h-6 p-1 bg-neutral-100 rounded-[100px] justify-center items-center gap-2 flex">
              <div className="text-zinc-900 text-xs font-normal font-['Inter'] leading-none">
                {getInitials(row.name)}
              </div>
            </div>
            <div
              title={row.name}
              className="grow shrink basis-0 text-zinc-900 text-sm font-medium font-['Inter'] pb-0.5 leading-none whitespace-nowrap overflow-hidden overflow-ellipsis"
            >
              {row.name}
            </div>
          </div>
        );
      },
    },
    {
      field: 'type',
      headerName: t('common:Type'),
      renderCell: ({ row }) => {
        return (
          <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex overflow-y-hidden">
            <div className="first-letter:uppercase grow shrink basis-0 text-zinc-900 text-sm font-medium font-['Inter'] pb-0.5 leading-none whitespace-nowrap overflow-hidden overflow-ellipsis">
              {
                offerTypes?.find((t) => t.id === Number(row.offerType))
                  ?.translations?.[locale]?.name
              }
            </div>
          </div>
        );
      },
    },
    {
      field: 'price',
      headerName: t('common:Price'),
      renderCell: ({ row }) => {
        return (
          <div className="grow shrink basis-0 h-4 justify-start items-start gap-2 flex">
            <div className="w-4 h-4 relative opacity-30">
              <PriceIcon />
            </div>
            <div className="grow shrink basis-0 text-zinc-900 text-sm font-normal leading-none">
              {formatPriceWithCurrency(row.price, row.currency)}
            </div>
          </div>
        );
      },
    },
    {
      field: 'date',
      headerName: t('common:date_created'),
      maxWidth: 250,
      renderCell: ({ row }) => {
        return <div className="">{formatDateAndTime(row.offerDate)}</div>;
      },
    },
  ];

  return columns.map((column) => ({
    ...commonSettings,
    ...column,
    ...(isLoading
      ? {
          renderCell: () => (
            <Skeleton
              variant="text"
              animation="wave"
              width="100%"
              height={20}
            />
          ),
        }
      : {}),
  }));
};
