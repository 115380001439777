import { IconProps } from './locationIcon';

export default function RightArrowIcon({ color, className }: IconProps) {
  color = color || '#1D1D1F';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1.5 8h13m0 0L9.482 3M14.5 8l-5.018 5"
      ></path>
    </svg>
  );
}
