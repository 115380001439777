import React from 'react';
// import TableGridXPro from "../../../../../components/tableGridPro";
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import {
  GridColumns,
  GridRowParams,
  GridValidRowModel,
} from '@mui/x-data-grid';
import { GridSelectionModel } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import TableGridX from '../../../../../components/tableGridX';
import { IMatching } from '../../../../../types/matching';
import { useTranslation } from 'react-i18next';
import { ApiListing } from '../../../../../types/api';
import { matchingStatuses } from '../index';

type Props = {
  matchings: ApiListing<IMatching> | null;
  rows: IMatching[];
  page: number;
  pageSize: number;
  setPage: (page: number) => void;
  onRowsSelectionHandler: (rowSelectionModel: GridRowSelectionModel) => void;
  rowSelectionModel: GridSelectionModel;
  usersListingColumns: GridColumns<GridValidRowModel>;
};

const MatchingStep = ({
  matchings,
  rows,
  page,
  setPage,
  pageSize,
  onRowsSelectionHandler,
  rowSelectionModel,
  usersListingColumns,
}: Props) => {
  const { t } = useTranslation('propertiesPage');
  const totalItems = matchings ? matchings['hydra:totalItems'] : 0;

  const getMinHeight = (wrapper?: boolean) => {
    const count =
      rows && rows.length
        ? rows.length < pageSize
          ? rows.length
          : pageSize
        : pageSize;

    if (rows) {
      switch (count) {
        case 1:
          return 230;
          break;
        case 0:
          return 230;
          break;
        default:
          return (count < 10 ? 126 * count : 126 * 10) + (wrapper ? 110 : 50);
      }
    } else {
      return 0;
    }
  };

  return (
    <div className={'flex flex-col'}>
      <div className="text-zinc-900 text-2xl font-normal leading-7 mb-8 flex items-center gap-2">
        {matchings ? (
          `${totalItems} ${t('matching_lists_were_generated')}`
        ) : (
          <>
            <div className="flex gap-0.5 justify-center items-center">
              <div className="h-1 w-1 bg-blue rounded-full animate-bounce [animation-delay:-0.3s]"></div>
              <div className="h-1 w-1 bg-blue rounded-full animate-bounce [animation-delay:-0.15s]"></div>
              <div className="h-1 w-1 bg-blue rounded-full animate-bounce"></div>
            </div>
            <span>{t('matching_lists_are_loading')}</span>
          </>
        )}
      </div>
      <Box className={'flex-grow w-full !min-h-[300px] with-border'}>
        <TableGridX
          sx={{
            '&': {
              minHeight: `${getMinHeight(true)}px`,
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
              display: 'none',
            },
            '& .MuiDataGrid-virtualScroller': {
              height: `${getMinHeight()}px !important`,
              overflowY: 'hidden !important',
            },
          }}
          rows={rows}
          columns={usersListingColumns}
          page={page - 1}
          onPageChange={(page) => setPage(page + 1)}
          pageSize={pageSize}
          rowHeight={126}
          paginationMode="server"
          rowCount={totalItems}
          checkboxSelection
          selectionModel={rowSelectionModel}
          isRowSelectable={(params: GridRowParams) =>
            params.row.status === matchingStatuses.READY ||
            params.row.status === matchingStatuses.EMPTY
          }
          onSelectionModelChange={(ids) => {
            const selectedIDs = ids.length ? [ids.pop()] : [];
            // @ts-ignore
            onRowsSelectionHandler(selectedIDs);
          }}
        />
      </Box>
    </div>
  );
};

export default MatchingStep;
