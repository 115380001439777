import { useState } from 'react';
import { mutate } from 'swr';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';

import StepsFieldset from '../../../../../components/stepper/stepsFieldset';
import InputFormField from '../../../../../components/form/inputFormField';
import RepeatableBlock from '../../../../../components/form/repeateableBlock';

import CloseIcon from '../../../../../features/icons/closeIcon';
import CheckedIcon from '../../../../../features/icons/checkedIcon';

import { IProperty, IPropertyApi } from '../../../../../types/property';
import { http } from '../../../../../libs/axios';
import { useNotification } from '../../../../../hooks/useNotification';
import { numberInputProps } from './const';
import SelectWithOptionsFromApi from './selectWithOptionsFromApi';

function BuildingBlocks({
  property: serverData,
}: {
  property?: Partial<IPropertyApi>;
}) {
  const { register, watch, control, getValues, setValue } =
    useFormContext<IProperty>();
  const { t } = useTranslation('propertiesPage');

  const { addNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);

  const handleBlock = async (id: number, index: number) => {
    setIsLoading(true);
    const dataToSend = {
      surface: +watch(`buildingBlocks.${index}.surface`),
      name: watch(`buildingBlocks.${index}.name`),
      property: String(serverData?.id),
      orientation: watch(`buildingBlocks.${index}.orientation`),
    };
    if (!id) {
      const res = await http.post('/building_blocks', dataToSend);
      setValue(`buildingBlocks.${index}`, res.data);

      addNotification('composition has been successfully created');
    } else {
      await http.patch(`/building_blocks/${id}`, dataToSend);
      addNotification('composition has been successfully updated');
    }
    mutate(`/v1/properties/${serverData?.id}/building_blocks`);
    setIsLoading(false);
  };

  const removeBlock = async (id: number, remove: any) => {
    if (id) {
      try {
        http.delete(`/building_blocks/${id}`);

        remove();
        mutate(`/v1/properties/${serverData?.id}/building_blocks`);
        addNotification('composition has been successfully removed');
      } catch (err: any) {
        addNotification(err.response.data.message, 'error');
      }
    } else {
      remove();
    }
  };

  return (
    <StepsFieldset
      title={`${t('Compositions')} (${watch('buildingBlocks').length})`}
    >
      <div className="col-span-2">
        <RepeatableBlock
          control={control}
          name="buildingBlocks"
          addLabel={t('Add_composition')}
          appendObj={{ name: '', surface: 0, orientation: '1' }}
          renderField={(field, index, remove) => {
            return (
              <div key={field.id}>
                <div className="flex gap-4 justify-end">
                  <Button
                    disabled={isLoading}
                    className="flex justify-center items-center gap-4 "
                    variant="outlined"
                    aria-label="save block"
                    onClick={() =>
                      handleBlock(+watch(`buildingBlocks.${index}.id`), index)
                    }
                  >
                    <CheckedIcon color="#3446B9" />
                    <Typography
                      sx={{
                        fontSize: '14px',
                        color: '#3446B9',
                        fontWeight: 500,
                      }}
                    >
                      {+watch(`buildingBlocks.${index}.id`)
                        ? t('Edit_composition')
                        : t('Save_composition')}
                    </Typography>
                  </Button>
                  <Button
                    className="flex justify-center items-center gap-4 "
                    variant="outlined"
                    aria-label="Remove block"
                    onClick={() => {
                      removeBlock(+watch(`buildingBlocks.${index}.id`), () =>
                        remove(index)
                      );
                    }}
                  >
                    <CloseIcon color="#F50057" />
                    <Typography
                      sx={{
                        fontSize: '14px',
                        color: '#F50057',
                        fontWeight: 500,
                      }}
                    >
                      {t('Delete_composition')}
                    </Typography>
                  </Button>
                </div>
                <div className="flex flex-col gap-4 ">
                  <div style={{ width: '33%' }}>
                    <InputFormField
                      label={t('Block_Name')}
                      type="text"
                      {...register(`buildingBlocks.${index}.name`)}
                      required={index !== 0}
                    />
                  </div>
                  <div className="flex gap-4">
                    <div style={{ width: '33%' }}>
                      <SelectWithOptionsFromApi
                        isRequired
                        label={t('orientation')}
                        endpoint="/orientations"
                        defaultValue={getValues(
                          `buildingBlocks.${index}.orientation`
                        )}
                        {...register(`buildingBlocks.${index}.orientation`)}
                      />
                    </div>
                    <div style={{ width: '33%' }}>
                      <InputFormField
                        label={t('surface')}
                        type="number"
                        inputProps={numberInputProps}
                        {...register(`buildingBlocks.${index}.surface`, {
                          valueAsNumber: true,
                        })}
                        required={index !== 0}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        />
      </div>
    </StepsFieldset>
  );
}

export default BuildingBlocks;
