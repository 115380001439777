import SpinnerLoading from '../../../../../features/spinner/spinnerLoading';
import * as React from 'react';
import { IPortalSetting } from '../../../../../types/portals';
import classNames from 'classnames';
import { formatDateAndTime } from '../../../../../helpers/utils';
import { useTranslation } from 'react-i18next';

interface Props {
  data: IPortalSetting;
}

const HistoryTab = (props: Props) => {
  const { data } = props;
  const { t } = useTranslation('common');
  return data ? (
    <div>
      <p className={'mb-8 text-2xl'}>
        {t('history')}
        {data.settingPortalHistorics.length ? (
          <span>({data.settingPortalHistorics.length})</span>
        ) : null}
      </p>
      {data.settingPortalHistorics.length ? (
        <div className={'border divide-y'}>
          <div className={'grid grid-cols-12 divide-x'}>
            <div className={'col-start-1 col-end-5 p-4'}>
              <span className={'font-medium'}>{t('Action')}</span>
            </div>
            <div className={'col-start-5 col-end-8 p-4'}>
              <span className={'font-medium'}>{t('Date')}</span>
            </div>
            <div className={'col-start-8 col-end-11 p-4'}>
              <span className={'font-medium'}>{t('Author')}</span>
            </div>
            <div className={'p-4'}>
              <span className={'font-medium'}>{t('Status')}</span>
            </div>
          </div>
          {data.settingPortalHistorics.map((item) => (
            <div className={'grid grid-cols-12 divide-x'} key={item.id}>
              <div
                className={
                  'col-start-1 col-end-5 p-4 text-[14px] 2xl:text-base'
                }
              >
                {item.action}
              </div>
              <div className={'col-start-5 col-end-8 p-4'}>
                <span className={'text-[14px] 2xl:text-base'}>
                  {formatDateAndTime(item.date)}
                </span>
              </div>
              <div className={'col-start-8 col-end-11 p-4'}>
                <div
                  className={
                    'flex items-center gap-2 text-[14px] 2xl:text-base'
                  }
                >
                  {item.doneBy ? (
                    <div
                      className={
                        'w-[24px] h-[24px] rounded-full bg-gray-100 overflow-hidden flex items-center justify-center'
                      }
                    >
                      {item.doneBy.avatar ? (
                        <img
                          src={item.doneBy.avatar}
                          alt={`${item.doneBy.firstname} ${item.doneBy.lastname}`}
                        />
                      ) : null}
                    </div>
                  ) : null}
                  <span className={'text-[14px] 2xl:text-[16px] font-medium'}>
                    {item.doneBy
                      ? `${item.doneBy.firstname || ''} ${
                          item.doneBy.lastname || ''
                        }`
                      : ''}
                  </span>
                </div>
              </div>
              <div className={'col-start-11 col-end-13 p-4'}>
                <div
                  className={classNames(
                    'inline-flex py-0.5 px-2 rounded-md items-center border',
                    {
                      'border-[#F50057] text-[#F50057]': !item.isSuccess,
                      'border-[#4CAF50] text-[#4CAF50]': item.isSuccess,
                    }
                  )}
                >
                  <span className="text-[12px] 2xl:text-[16px] leading-5">
                    {item.isSuccess ? t('Success') : t('Fail')}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div
          className={
            'min-h-[200px] text-center opacity-50 flex flex-col items-center justify-center gap-2'
          }
        >
          {t('There_is_no_history_yet_for_this_portal')}
        </div>
      )}
    </div>
  ) : (
    <div className={'col-start-1 col-end-4 min-h-[500px]'}>
      <SpinnerLoading />
    </div>
  );
};

export default HistoryTab;
