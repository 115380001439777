const getUserLevel = (row: any) => {
  const { company, filial, agency, department, roles } = row;
  if (roles.includes('ROLE_ASSISTANT')) {
    return <span></span>;
  }
  if (department) {
    return <span>4</span>;
  } else if (agency && !department) {
    return <span>3</span>;
  } else if (filial && !agency && !department) {
    return <span>2</span>;
  } else if (company && !filial && !agency && !department) {
    return <span>1</span>;
  } else {
    return <span></span>;
  }
};

const getuserAgency = (row: any) => {
  const { company, filial, agency, department, roles } = row;
  if (roles.includes('ROLE_ASSISTANT')) {
    return <span></span>;
  }
  if (department) {
    return <span className="truncate">{department.name}</span>;
  } else if (agency && !department) {
    return <span className="truncate">{agency.name}</span>;
  } else if (filial && !agency && !department) {
    return <span className="truncate">{filial.name}</span>;
  } else if (company && !filial && !agency && !department) {
    return <span className="truncate">{company.name}</span>;
  } else {
    return <span></span>;
  }
};

const getUserRole = (role: string) => {
  switch (role) {
    case 'ROLE_USER':
      return 'Agent';
    case 'ROLE_ADMIN':
    case 'ROLE_ADMIN_ASSISTANT':
    case 'ROLE_ADMIN_AGENT':
    case 'ROLE_ADMIN_OPERATOR':
      return 'Admin';
    case 'ROLE_ASSISTANT':
      return 'Assistant';
  }
  return '';
};

const getUserRoleColor = (role: string) => {
  switch (role) {
    case 'ROLE_USER':
      return '#4CAF50';
    case 'ROLE_ADMIN':
    case 'ROLE_ADMIN_ASSISTANT':
    case 'ROLE_ADMIN_AGENT':
    case 'ROLE_ADMIN_OPERATOR':
      return '#ED6C02';
    case 'ROLE_ASSISTANT':
      return '#8d123b';
  }
  return '';
};

export { getUserLevel, getuserAgency, getUserRoleColor, getUserRole };
