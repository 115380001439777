import { PaletteMode, ThemeOptions } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Theme } from '@mui/material/styles';
import '@mui/styles';

// https://stackoverflow.com/questions/70697666/material-ui-v5-property-spacing-does-not-exist-on-type-defaulttheme-when-u
declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line
  interface DefaultTheme extends Theme {}
}

export const appTheme = (mode: PaletteMode): ThemeOptions => ({
  components: {
    MuiGrid: {
      defaultProps: {
        sx: {
          border: 'none',
        },
      },
    },

    MuiTabs: {
      defaultProps: {
        sx: {
          background:
            mode === 'light' ? '#fff !important' : '#212529 !important',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '0.95rem',
          textTransform: 'none',
        },
      },
    },
  },
  typography: {
    fontFamily: 'Inter',

    h1: {
      fontSize: '2rem',
    },
    h2: {
      fontSize: '2rem',
    },
    h3: {
      fontSize: '2rem',
    },
    button: {
      fontSize: '14px !important',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 1,
    },
    allVariants: {
      fontFamily: 'Inter, sans-serif',
      fontWeight: 400,
      borderColor:
        mode === 'light' ? '#e5e7eb !important' : '#334155 !important',
    },
  },
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          // palette values for light mode
          primary: { main: '#3446B9' },
          divider: '#3446B9',
          background: {
            default: '#F5F5F7',
            paper: '#FFFFFF',
          },
          text: {
            primary: '#1D1D1F',
          },
          grey: {
            ...grey,
            50: '#FBFBFD',
          },
          blue: '#3446B9',
          dark: '#6E6E72',
          white: '#ffffff',
        }
      : {
          // palette values for dark mode
          primary: { main: '#dda9cf' },
          divider: grey[100],
          background: {
            default: '#1a1d21',
            paper: '#212529',
          },
          text: {
            primary: '#fff',
          },
          grey: {
            ...grey,
            50: '#1D1D1F',
          },
          blue: '#3446B9',
          dark: '#6E6E72',
          white: '#ffffff',
        }),
  },
});
