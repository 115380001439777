import { PromotionWidgetPreviewProps } from '../../../../../types/brochureEditor';

const PromotionLogoWidget = ({ promotion }: PromotionWidgetPreviewProps) => {
  return promotion.logo ? (
    <img
      className="block w-full h-[88px] object-contain"
      src={promotion.logo?.url?.large}
      alt=""
    />
  ) : null;
};

export default PromotionLogoWidget;
