import { useTranslation } from 'react-i18next';
import { IconButton, InputAdornment } from '@mui/material';

import InputFormField from '../../../../components/form/inputFormField';
import SearchIcon from '../../../../features/icons/searchIcon';
import CloseIcon from '../../../../features/icons/closeIcon';

function PropertyMagicSearch({
  magicSearch,
  setMagicSearch,
}: {
  magicSearch: string;
  setMagicSearch: (newVal: string) => unknown;
}) {
  const { t } = useTranslation('propertiesPage');
  return (
    <InputFormField
      className="bg-white !ml-2"
      placeholder={t('reference_address_owner_broker')}
      isSmall
      inputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: magicSearch && (
          <InputAdornment position="end">
            <IconButton
              size="small"
              onClick={() => setMagicSearch('')}
              aria-label={t('clear')}
            >
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      value={magicSearch}
      onChange={(e) => setMagicSearch(e.target.value)}
    />
  );
}

export default PropertyMagicSearch;
