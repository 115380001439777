import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import de_common from './locales/de/common.json';
import de_usersPage from './locales/de/usersPage.json';
import de_agencyPage from './locales/de/agencyPage.json';
import de_tags from './locales/de/tags.json';
import de_auth from './locales/de/auth.json';
import de_alerts from './locales/de/alerts.json';
import de_promotionPage from './locales/de/promotionPage.json';
import de_properties from './locales/de/propertiesPage.json';
import de_actions from './locales/de/actions.json';
import de_validation from './locales/de/validation.json';
import de_notificationsPage from './locales/de/notificationsPage.json';
import de_brochureEditor from './locales/de/brochureEditor.json';
import de_matching from './locales/de/matching.json';
import de_networks from './locales/de/networks.json';

import en_common from './locales/en/common.json';
import en_usersPage from './locales/en/usersPage.json';
import en_agencyPage from './locales/en/agencyPage.json';
import en_tags from './locales/en/tags.json';
import en_auth from './locales/en/auth.json';
import en_alerts from './locales/en/alerts.json';
import en_promotionPage from './locales/en/promotionPage.json';
import en_properties from './locales/en/propertiesPage.json';
import en_actions from './locales/en/actions.json';
import en_validation from './locales/en/validation.json';
import en_notificationsPage from './locales/en/notificationsPage.json';
import en_brochureEditor from './locales/en/brochureEditor.json';
import en_matching from './locales/en/matching.json';
import en_networks from './locales/en/networks.json';

import fr_common from './locales/fr/common.json';
import fr_usersPage from './locales/fr/usersPage.json';
import fr_agencyPage from './locales/fr/agencyPage.json';
import fr_tags from './locales/fr/tags.json';
import fr_auth from './locales/fr/auth.json';
import fr_alerts from './locales/fr/alerts.json';
import fr_promotionPage from './locales/fr/promotionPage.json';
import fr_properties from './locales/fr/propertiesPage.json';
import fr_actions from './locales/fr/actions.json';
import fr_validation from './locales/fr/validation.json';
import fr_notificationsPage from './locales/fr/notificationsPage.json';
import fr_brochureEditor from './locales/fr/brochureEditor.json';
import fr_matching from './locales/fr/matching.json';
import fr_networks from './locales/fr/networks.json';

import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  fr: {
    common: fr_common,
    tags: fr_tags,
    usersPage: fr_usersPage,
    agencyPage: fr_agencyPage,
    auth: fr_auth,
    alerts: fr_alerts,
    promotionPage: fr_promotionPage,
    propertiesPage: fr_properties,
    actions: fr_actions,
    matching: fr_matching,
    validation: fr_validation,
    notificationsPage: fr_notificationsPage,
    brochureEditor: fr_brochureEditor,
    networks: fr_networks,
  },

  en: {
    common: en_common,
    tags: en_tags,
    usersPage: en_usersPage,
    agencyPage: en_agencyPage,
    auth: en_auth,
    alerts: en_alerts,
    promotionPage: en_promotionPage,
    propertiesPage: en_properties,
    actions: en_actions,
    matching: en_matching,
    validation: en_validation,
    notificationsPage: en_notificationsPage,
    brochureEditor: en_brochureEditor,
    networks: en_networks,
  },
  de: {
    common: de_common,
    tags: de_tags,
    usersPage: de_usersPage,
    agencyPage: de_agencyPage,
    auth: de_auth,
    alerts: de_alerts,
    promotionPage: de_promotionPage,
    propertiesPage: de_properties,
    actions: de_actions,
    matching: de_matching,
    validation: de_validation,
    notificationsPage: de_notificationsPage,
    brochureEditor: de_brochureEditor,
    networks: de_networks,
  },
};

const detectionOptions = {
  order: [
    'localStorage',
    'navigator',
    'path',
    'cookie',
    'subdomain',
    'queryString',
    'htmlTag',
  ],
  lookupFromPathIndex: 0,
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: detectionOptions,
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    resources,
  });

export default i18n;
