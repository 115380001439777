import classNames from 'classnames';

interface Props {
  // avatar?: string | null;
  logo?: {
    x32?: string | null;
    x64?: string | null;
    x96?: string | null;
    x368?: string | null;
  } | null;
  name: string | null;
  role?: string;
  city?: string;
}

const NetworkAgencyItem = (props: Props) => {
  const { name, logo, city } = props;
  return (
    <div
      className={classNames(
        'py-3 px-3 bg-neutral-100 rounded-[100px] justify-start items-center gap-2 flex'
      )}
    >
      {logo?.x96 ? (
        <div className="flex shrink-0 w-[40px] h-[40px] rounded-full overflow-hidden object-contain">
          <img
            className=" object-contain"
            src={logo.x96}
            alt={name as string}
          />
        </div>
      ) : null}
      <div className="flex-col justify-center items-start inline-flex whitespace-nowrap overflow-hidden overflow-ellipsis">
        <div className="text-zinc-900 text-sm font-medium leading-none overflow-ellipsis mb-1">
          {name}
        </div>
        <div className="text-neutral-500 text-sm font-normal leading-none capitalize">
          {city}
        </div>
      </div>
    </div>
  );
};

export default NetworkAgencyItem;
