import { ClickAwayListener } from '@mui/base';
import { Box, CircularProgress, Fade } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../components/modal';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import { http } from '../../../libs/axios';
import { formatDateAndTime } from '../../../helpers/utils';
import { toast } from 'react-toastify';
import useTranslationForTableGridXConfig from '../../../hooks/useTranslationForTableGridX';

type Props = {
  id: number | null;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export type buttonType = {
  id: number;
  label: string;
  active: boolean;
};

interface Contact {
  fullname: string;
}

interface GeneratedBy {
  firstname: string;
  lastname: string;
  isSuperAdmin: boolean;
  roles: string[];
}

interface DataRow {
  id: number;
  contact: Contact;
  sentAt: string;
  emailContext: number;
  generatedBy: GeneratedBy | null;
}
export const listButton: buttonType[] = [
  { id: 0, label: 'All', active: true },
  { id: 1, label: 'Marketing_Automation', active: false },
  { id: 2, label: 'Manual_Matching', active: false },
  { id: 3, label: 'Sent_file', active: false },
];
export const getEmailContext = (context: number) => {
  switch (context) {
    case 1:
      return 'Marketing_Automation';
      break;
    case 2:
      return 'Manual_Matching';
      break;
    case 3:
      return 'Sent_file';
      break;
    default:
      return 'Marketing_Automation';
  }
};

export default function ViewSentEmails({
  isModalOpen,
  setIsModalOpen,
  id,
}: Props) {
  const { t } = useTranslation('propertiesPage');
  const [rows, setRows] = useState<DataRow[]>([]);
  const [page, setPage] = useState(1);
  const [apiParams, setApiParams] = useState<string>('');
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const res = await http.get(
          `/v1/properties/${id}/sent-emails?${apiParams}&page=${page}&itemsPerPage=${5}`
        );
        const dataWithIds = res.data['hydra:member'].map(
          (row: DataRow, index: number) => ({
            ...row,
            id: index,
          })
        );
        setRows(dataWithIds);
        setTotalRows(res.data['hydra:totalItems']);
        setIsLoading(false);
      } catch (error) {
        toast.error(`${t('something_wrong_try_again_later')}`, {
          position: 'bottom-center',
          autoClose: 3000,
        });
        console.log('Error fetching data:', error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [id, page, apiParams]);

  useEffect(() => {
    if (activeIndex) {
      setApiParams(`emailContext=${activeIndex}`);
    } else {
      setApiParams('');
    }
  }, [activeIndex]);

  const columnsEmails: GridColDef[] = [
    {
      field: 'contact',
      headerName: 'Contact',
      headerClassName: '!pl-5',
      cellClassName: '!pl-6',
      width: 250,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        return <span>{params.row.contact.fullname}</span>;
      },
    },
    {
      field: 'emailSentAt',
      headerName: 'Date',
      width: 250,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        return <span>{formatDateAndTime(params.row.sentAt)}</span>;
      },
    },
    {
      field: 'generatedBy',
      headerName: 'Type',
      width: 250,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        return <div>{t(getEmailContext(params.row.emailContext))}</div>;
      },
    },
  ];

  const Columns = useTranslationForTableGridXConfig([columnsEmails, 'common']);

  const onClickAway = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Modal
        withButton={false}
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        openModal={() => setIsModalOpen(true)}
        sx={{
          maxHeight: '100vh',
          paddingTop: '150px !important',
        }}
        width={{
          md: '60%',
        }}
        boxClassName="!pt-3 border rounded-0 pb-[24px]"
        title={
          <div style={{ padding: '14px' }}>
            {t('propertiesPage:View_sent_emails')}
          </div>
        }
        buttonProps={{
          title: 'Choose role',
        }}
      >
        <ClickAwayListener onClickAway={onClickAway}>
          <Fade in={isModalOpen}>
            <Box>
              <div className="mb-8 flex gap-3">
                {listButton.map(({ label, id }) => (
                  <div
                    onClick={() => {
                      setActiveIndex(id);
                    }}
                    className={`p-2 ${
                      activeIndex === id
                        ? 'bg-[#3446B9] text-white'
                        : 'bg-gray-200'
                    } ${
                      id === 0 && 'w-[60px]'
                    } cursor-pointer  flex justify-center items-center h-[35px]  border border-#E6E8EC rounded-[4px] text-sm font-medium`}
                    key={id}
                  >
                    <p>{t(label)}</p>
                  </div>
                ))}
              </div>
              <div>
                {isLoading ? (
                  <div className="min-h-[300px]">
                    <span className="flex justify-center items-center h-[250px]">
                      <CircularProgress disableShrink />
                    </span>
                  </div>
                ) : (
                  <DataGrid
                    sx={{
                      '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                        display: 'none',
                      },
                      minHeight: '300px',
                    }}
                    rows={rows}
                    columns={Columns}
                    autoHeight={true}
                    loading={!rows}
                    disableColumnMenu
                    disableSelectionOnClick
                    autoPageSize
                    page={page - 1}
                    paginationMode="server"
                    rowCount={totalRows || 0}
                    onPageChange={(page: number) => {
                      setPage(page + 1);
                    }}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                  />
                )}
              </div>
            </Box>
          </Fade>
        </ClickAwayListener>
      </Modal>
    </div>
  );
}
