import { WidgetPreviewProps } from '../../../../../types/brochureEditor';
import { useAlignmentStyles } from '../../../lib/hooks';
import FormattedPhone from '../../formattedPhone';

const BrokerBlock = ({ previewSubject, widgetOptions }: WidgetPreviewProps) => {
  const withAvatar = widgetOptions?.withAvatar?.value;
  const withEmail = widgetOptions?.withEmail?.value;
  const withPhone = widgetOptions?.withPhone?.value;

  const source = widgetOptions?.source?.value || 'main';

  let broker;
  if (source === 'intermediate') {
    broker = previewSubject.intermediateBroker;
  } else {
    broker = previewSubject.mainBroker;
  }

  const wrapperGridStyle = useAlignmentStyles(widgetOptions);

  return (
    <div className="grid gap-4" style={wrapperGridStyle}>
      {withAvatar && (
        <div>
          {broker?.avatar ? (
            <img
              className="block w-[88px] h-[88px] rounded-full object-cover"
              src={broker.avatar}
              alt=""
            />
          ) : (
            <span className="w-[88px] h-[88px] rounded-full bg-gray-100"></span>
          )}
        </div>
      )}

      <div className="grid gap-1" style={wrapperGridStyle}>
        <div className="text-2xl leading-7">
          {broker?.firstname} {broker?.lastname}
        </div>
      </div>

      {(withEmail || withPhone) && (
        <div className="grid gap-1" style={wrapperGridStyle}>
          {withEmail && (
            <a
              href={`mailto:${broker?.email}`}
              className="text-sm leading-none"
            >
              {broker?.email}
            </a>
          )}

          {withPhone && (
            <a
              href={`tel:${broker?.phone}`}
              className="inline-flex text-sm leading-none"
            >
              <FormattedPhone
                phone={broker?.phone}
                horizontalAlignment={
                  wrapperGridStyle.justifyItems as 'start' | 'center' | 'end'
                }
              />
            </a>
          )}
        </div>
      )}
    </div>
  );
};

export default BrokerBlock;
