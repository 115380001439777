import { PropertyListingItem } from '../../types/property';
import { Tooltip } from '@mui/material';
import { useIsRent } from '../../hooks/useIsRent';
import { formatPriceWithCurrency } from '../../pages/index/utils/useOffersListColumns';

const PropertyPrice = ({ row }: { row: PropertyListingItem }) => {
  const isRent = useIsRent(row);
  const formattedPrice = isRent
    ? formatPriceWithCurrency(
        row.pricingRent?.displayedPrice,
        row.pricingRent?.currency?.value
      )
    : formatPriceWithCurrency(
        row.pricingBuy?.displayedPrice,
        row.pricingBuy?.currency?.value
      );

  return (
    <>
      <Tooltip className="pl-4" title={formattedPrice} arrow placement="top">
        <span>{formattedPrice}</span>
      </Tooltip>
    </>
  );
};

export default PropertyPrice;
