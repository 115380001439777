import { Button, ButtonProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

import CheckedIcon from '../../features/icons/checkedIcon';
import SpinnerLoading from '../../features/spinner/spinnerLoading';

function SaveButton({
  isLoading,
  text = 'usersPage:save',
  ...props
}: ButtonProps & { isLoading?: boolean; text?: string }) {
  const { t } = useTranslation('usersPage');
  return (
    <Button
      className="Save_btn"
      type="submit"
      variant="contained"
      disableElevation
      classes={classes}
      disabled={isLoading}
      {...props}
    >
      {isLoading ? <SpinnerLoading /> : t(text)}
      {!isLoading && <CheckedIcon color="currentcolor" />}
    </Button>
  );
}

export default SaveButton;

const classes = {
  root: 'flex justify-center items-center gap-4 bg-blue py-4 px-8',
};
