import React, { useState } from 'react';
import { AccordionView } from '../creator/individual-view';
import useLocale from '../../../hooks/useLocale';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import classNames from 'classnames';
import RepeatableSearchCriteriaForm from './forms/repeatable-search-criteria-form';
import { IContactSearch } from '../../../types/contacts';
import { http } from '../../../libs/axios';
import { useNotification } from '../../../hooks/useNotification';
import { updateHistoric } from '../../../helpers/utils/contacts';
import { useTranslation } from 'react-i18next';

const SearchCriteria = (props: {
  id?: string;
  mutate?: any;
  items: IContactSearch[];
  types?: {
    id: number;
    keyname: string;
  }[];
}) => {
  const { mutate, items, id, types } = props;
  const { locale } = useLocale();
  const [createMode, toggleCreateMode] = useState(!items.length);
  const { addNotification } = useNotification();
  const { t } = useTranslation('common');
  const deleteSearchCriteria = (searchCriteriaId: string) => {
    http
      .delete(`/search_contacts/${searchCriteriaId}`)
      .then(() => {
        addNotification(
          `Search criteria has been successfully deleted.`,
          'success'
        );
        if (mutate) {
          mutate();
        }
        updateHistoric(id, 'Search criteria deleted');
      })
      .catch((error) => {
        let message;
        if (error.response) {
          message = 'Server error';
        } else if (error.request) {
          message = 'Failed to connect to server';
        } else {
          message = 'Unknown error';
        }
        addNotification(message, 'error');
      });
  };

  return (
    <div>
      <div className={''}>
        {items &&
          items.map((item) => {
            const title = `${
              item.translations?.[locale]?.title
                ? `${item.translations?.[locale]?.title}`
                : ''
            }`;

            return (
              <AccordionView
                key={item.id}
                className={'w-full col-start-1 col-end-4'}
                titleClassName={'text-[24px]'}
                title={title}
              >
                <RepeatableSearchCriteriaForm
                  mutate={mutate}
                  isEditMode={true}
                  inner
                  data={item}
                  id={id}
                  types={types}
                  additionalButton={
                    <Button
                      onClick={() => deleteSearchCriteria(item.id as string)}
                      variant="outlined"
                      className="flex justify-center items-center"
                      sx={{
                        height: '42px',
                        color: '#F50057',
                      }}
                    >
                      <Box
                        className="flex justify-center items-center"
                        gap="16px"
                      >
                        <DeleteOutlineIcon fontSize="small" />
                        <div className="font-medium">
                          {t('Delete_search_criteria')}
                        </div>
                      </Box>
                    </Button>
                  }
                />
              </AccordionView>
            );
          })}
      </div>
      {createMode ? (
        <div
          className={classNames('', {
            'pt-8': createMode,
          })}
        >
          <p className={'text-[24px] mb-2'}>{t('Add_new_search_criteria')}</p>
          <RepeatableSearchCriteriaForm
            id={props.id}
            isEditMode={false}
            inner
            mutate={mutate}
            types={types}
            additionalButton={
              <Button
                onClick={() => toggleCreateMode(false)}
                variant="outlined"
                className="flex justify-center items-center w-[140px] cancel_btn"
                sx={{
                  height: '42px',
                  color: '#F50057',
                }}
              >
                <Box className="flex justify-center items-center" gap="16px">
                  <DeleteOutlineIcon fontSize="small" />
                  <div className="font-medium"> {t('Cancel')} </div>
                </Box>
              </Button>
            }
          />
        </div>
      ) : (
        <div className={'py-16 mb-8 border-b'}>
          <button
            onClick={() => toggleCreateMode(true)}
            className={
              'hover:opacity-80 transition-opacity duration-300 rounded flex items-center justify-center gap-2 bg-gray-100 w-full p-3 text-[14px] text-gray-900'
            }
          >
            <AddIcon />
            <span> {t('Add_new_search_criteria')} </span>
          </button>
        </div>
      )}
    </div>
  );
};

export default SearchCriteria;
