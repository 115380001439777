import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { useAnchorElement } from '../../../hooks/useAnchorElement';

interface Props {
  open: boolean;
  reference: string;
}

export default function PreviewLanguage({
  open,
  handleClose,
  anchorEl,
  reference,
}: Props & ReturnType<typeof useAnchorElement>) {
  const { t } = useTranslation('common');
  const preview = (locale: string) => {
    window.open(`/property/${reference}?broker=true&lang=${locale}`, '_blank');
    handleClose?.();
  };
  const isMdScreen = window.innerWidth >= 768;
  return (
    <Menu
      className="translate-y-[6%] md:translate-y-[0%]"
      id="demo-positioned-menu"
      aria-labelledby="demo-positioned-button"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: isMdScreen ? 'left' : 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{
        style: {
          transform: 'translateX(-103%)',
        },
      }}
    >
      <MenuItem
        onClick={() => {
          preview('en');
        }}
      >
        {t('English')}
      </MenuItem>
      <MenuItem
        onClick={() => {
          preview('fr');
        }}
      >
        {t('French')}
      </MenuItem>
      <MenuItem
        onClick={() => {
          preview('de');
        }}
      >
        {t('German')}
      </MenuItem>
    </Menu>
  );
}
