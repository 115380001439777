import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { IProperty } from '../../../../../types/property';
import StepsFieldset from '../../../../../components/stepper/stepsFieldset';
import SelectFormField from '../../../../../components/form/selectFormField';
import ReusableDatePicker from '../../../../../components/datePicker/reusableDatePicker';
import {
  getOptionsByLanguageString,
  optionType,
} from '../../../../../const/propertiesOptions';
import useLocale from '../../../../../hooks/useLocale';

function Mandate() {
  const { register, watch, control, getValues } = useFormContext<IProperty>();
  const { locale } = useLocale();
  const { t } = useTranslation('propertiesPage');

  const { data: mandateOptionsApi } = useSWR<optionType[]>('mandate_types');
  const mandateOptions = getOptionsByLanguageString(mandateOptionsApi, locale);

  return (
    <StepsFieldset
      className="property_details grid grid-cols-3"
      title={t('Mandate')}
    >
      <SelectFormField
        label={t('Type_of_mandate')}
        options={mandateOptions}
        defaultValue={getValues('mandateType')}
        {...register('mandateType')}
      />

      <ReusableDatePicker
        label={t('Mandate_start_date')}
        control={control}
        name="mandateStartDate"
        watch={watch}
      />
      <ReusableDatePicker
        label={t('Mandate_end_date')}
        control={control}
        name="mandateEndDate"
        watch={watch}
      />

      <ReusableDatePicker
        label={t('Renewal_of_mandate')}
        control={control}
        name="mandateRenewalDate"
        watch={watch}
      />
    </StepsFieldset>
  );
}

export default Mandate;
