import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { ReactNode, useEffect, useRef, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type Props = {
  title?: string;
  titleNode?: ReactNode;
  defaultExpanded?: boolean;
  children?: ReactNode | ReactNode[];
  className?: string;
  detailsTopMargin?: number;
};

const StepsFieldset = ({
  title,
  titleNode,
  defaultExpanded = true,
  children,
  className = 'grid grid-cols-2',
  detailsTopMargin = 40,
}: Props) => {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const element = useRef<HTMLDivElement>();

  useEffect(() => {
    const elements = Array.from(
      element.current?.querySelectorAll('input, select') || []
    );
    const handler = () => setExpanded(true);
    for (const element of elements) {
      element.addEventListener('invalid', handler);
    }
    return () => {
      for (const element of elements) {
        element.removeEventListener('invalid', handler);
      }
    };
  }, []);

  return (
    <Box display="flex" flexDirection="row" gap="80px" ref={element}>
      <Accordion
        expanded={expanded}
        onChange={(evt, newExpanded) => setExpanded(newExpanded)}
        square
        sx={{ width: '100%', border: 'none', boxShadow: 'none' }}
      >
        <AccordionSummary
          className={`show_form_btn_${title}`}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ borderBottom: '1px solid #E6E8EC' }}
        >
          {titleNode || <span className="text-2xl leading-7">{title}</span>}
        </AccordionSummary>
        <AccordionDetails>
          <Box
            marginTop={`${detailsTopMargin}px`}
            width="100%"
            display="flex"
            flexDirection="column"
          >
            <Box width="100%" className={className} gap="48px">
              {children}
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default StepsFieldset;
