import { useTranslation } from 'react-i18next';
import { PropertyWidgetPreviewProps } from '../../../../../types/brochureEditor';
import { IConvenienceChoiceApi } from '../../../../../types/conveniences';
import TableWidget from '../../tableWidget';
import useLocale from '../../../../../hooks/useLocale';

const PropertyConveniencesWidget = ({
  property,
  widgetOptions,
}: PropertyWidgetPreviewProps) => {
  const { t } = useTranslation('propertiesPage');
  const { locale } = useLocale();

  function prepareConveniences(list: IConvenienceChoiceApi[] = []) {
    return list
      .map((item) => item.translations?.[locale]?.name || item.name)
      .join(', ');
  }

  const lines = [];
  const { conveniences } = property;
  if (conveniences) {
    const conveniencesLines = [
      {
        id: '21',
        label: t('Environment'),
        value: prepareConveniences(conveniences.environment),
      },
      {
        id: '22',
        label: t('Transports'),
        value: prepareConveniences(conveniences.transports),
      },
      {
        id: '23',
        label: t('Stores'),
        value: prepareConveniences(conveniences.stores),
      },
      {
        id: '24',
        label: t('Children_and_schooling'),
        value: prepareConveniences(conveniences.children),
      },
      {
        id: '25',
        label: t('Sport'),
        value: prepareConveniences(conveniences.sport),
      },
      {
        id: '26',
        label: t('Leisure_and_point_of_interest'),
        value: prepareConveniences(conveniences.leisure),
      },
      {
        id: '261',
        label: t('rooms'),
        value: prepareConveniences(conveniences.rooms),
      },
      {
        id: '27',
        label: t('Outside_conveniences'),
        value: prepareConveniences(conveniences['outside environment']),
      },
    ];
    lines.push(...conveniencesLines);
  }
  return (
    <TableWidget
      title={t('common:Conveniences')}
      items={lines}
      widgetOptions={widgetOptions}
    />
  );
};

export default PropertyConveniencesWidget;
