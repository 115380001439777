import { Avatar } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const ContactPopupHeader = ({
  className,
  title,
  src,
  isMain,
}: {
  className?: string;
  title: string;
  src: string;
  isMain: boolean;
}) => {
  const { t } = useTranslation('propertiesPage');
  return (
    <div
      className={classNames(
        'flex justify-center items-center gap-4',
        className
      )}
    >
      <Avatar sx={{ width: 48, height: 48 }} alt="Remy Sharp" src={src} />
      <div className="flex flex-col">
        <p className="text-[#1D1D1F] text-2xl leading-7">{title}</p>
        {isMain && (
          <p className="text-sm leading-4 text-blue"> {t('Primary_owner')} </p>
        )}
      </div>
    </div>
  );
};

export default ContactPopupHeader;
