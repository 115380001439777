import useLocale from '../../../hooks/useLocale';
import { IContact } from '../../../types/contacts';

function ContactTypes({ contact }: { contact?: IContact }) {
  const { locale } = useLocale();
  return (
    <div className="flex flex-wrap gap-1 py-1">
      {contact?.types.length ? (
        <>
          {contact.types.map((item) => (
            <div
              key={item.id}
              className="h-6 px-2 py-1 bg-neutral-100 rounded justify-start items-center gap-1 inline-flex"
            >
              <div className="text-neutral-500 text-[12px] 2xl:text-[14px] font-normal leading-none capitalize">
                {item.translations[locale]?.name}
              </div>
            </div>
          ))}
        </>
      ) : null}
    </div>
  );
}

export default ContactTypes;
