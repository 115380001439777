import { ReactNode } from 'react';
import { create } from 'zustand';

type Severity = 'error' | 'warning' | 'info' | 'success';

interface NotificationState {
  message: string | ReactNode;
  severity: Severity;
  snackbarIsDisplayed: boolean;
  addNotification: (
    message: string | ReactNode,
    severity?: Severity
  ) => unknown;
  hideSnackbar: () => unknown;
}

export const useNotification = create<NotificationState>((set) => ({
  message: '',
  severity: 'info',
  snackbarIsDisplayed: false,
  addNotification: (message, severity = 'info') =>
    set({ message, severity, snackbarIsDisplayed: true }),
  hideSnackbar: () => set({ snackbarIsDisplayed: false }),
}));
