import { useEffect } from 'react';
import PromotionsListingPage from './promotionsListing';
import { useFiltersProperties } from '../../hooks/useFiltersProperties';

function PromotionsPage() {
  const {
    setStatusOptions,
    setAgentOptionsApi,
    setAgencyHierarchy,
    setDepartmentService,
    departmentService,
    agentsPerDept,
    statusOptions,
    agencyHierarchy,
  } = useFiltersProperties();

  useEffect(() => {
    statusOptions.length === 0 && setStatusOptions();
  }, [setStatusOptions]);

  useEffect(() => {
    departmentService.length === 0 && setDepartmentService();
  }, [departmentService]);

  useEffect(() => {
    agentsPerDept.length === 0 && setAgentOptionsApi();
  }, [setAgentOptionsApi]);

  useEffect(() => {
    !agencyHierarchy && setAgencyHierarchy();
  }, [setAgencyHierarchy]);

  return (
    <>
      <PromotionsListingPage />
    </>
  );
}

export default PromotionsPage;
