import { UseFormHandleSubmit } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { usePagesEditor } from './usePagesEditor';
import { useLayoutEditorComputables } from './useLayoutEditor';
import { useServerTemplate } from './useServerTemplate';

import { TemplateOptions } from '../../../../types/brochureEditor';
import { useNotification } from '../../../../hooks/useNotification';

export function useSave(handler: UseFormHandleSubmit<TemplateOptions>) {
  const { addNotification } = useNotification();
  const data = useDataForSaving();
  const { t } = useTranslation('common');
  const { saveTemplate } = useServerTemplate();

  return handler(async (options) => {
    try {
      await saveTemplate({
        options,
        ...data,
      });
      const msg = t('alerts:has_been_saved', { what: t('template') });
      addNotification(msg, 'success');
    } catch (e) {
      const msg = t('alerts:error_while_saving', { what: t('template') });
      addNotification(msg, 'error');
    }
  });
}

export function useDataForSaving() {
  const { template } = useServerTemplate();
  const { pages, overrides } = usePagesEditor();
  const { layout } = useLayoutEditorComputables();

  const propertyIds = template?.properties || [];
  const promotionIds = template?.promotions || [];

  const { propertyId, promotionId } = useParams();
  if (propertyId) {
    propertyIds.push(Number(propertyId));
  } else if (promotionId) {
    promotionIds.push(Number(promotionId));
  }

  return {
    pages,
    overrides,
    layout,
    properties: unique<number>(propertyIds),
    promotions: unique<number>(promotionIds),
  };
}

function unique<T>(arr: T[]): T[] {
  return Array.from(new Set(arr));
}
