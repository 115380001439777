import useSWR from 'swr';
import { HttpError } from '../../../../libs/axios';
import { Link, useParams } from 'react-router-dom';
import TitleHeader from '../../../../components/common/titleHeader';
import { Button, Skeleton, Typography } from '@mui/material';
import classNames from 'classnames';
import React, { useState } from 'react';
import { addEnding } from '../index';
import { useTranslation } from 'react-i18next';
import { INetworkApi, INetworkMemberItem } from '../../../../types/networks';
import useLocale from '../../../../hooks/useLocale';
import TableGridX from '../../../../components/tableGridX';
import useListingCollection from '../../../../hooks/useListingCollection';
import { useMembersListColumns } from '../hooks/useMembersListColumns';

export const statuses = {
  pending: '1',
  accepted: '2',
  declined: '3',
  terminated: '4',
  exited: '5',
};

const filters = [
  {
    nameKey: 'All',
    filterKey: null,
  },
  {
    nameKey: 'Accepted',
    filterKey: statuses.accepted,
  },
  {
    nameKey: 'declined',
    filterKey: statuses.declined,
  },
  {
    nameKey: 'pending',
    filterKey: statuses.pending,
  },
  {
    nameKey: 'Terminated',
    filterKey: statuses.terminated,
  },
  {
    nameKey: 'Exited',
    filterKey: statuses.exited,
  },
];

const skeletonItems: INetworkMemberItem[] = Array(6)
  .fill('')
  .map((_, index) => ({
    id: index,
    agency: {
      name: '',
      id: index,
      logo: null,
      userAgencyOwner: false,
    },
    dateAcceptance: '',
    dateDeclination: '',
    dateExit: '',
    dateInvitation: '',
    dateTermination: '',
    status: {
      id: 0,
      keyname: '',
      translations: {
        en: {
          locale: 'en',
        },
      },
    },
  }));

const SingleNetwork = () => {
  const { t } = useTranslation('networks');
  const { networkId } = useParams();
  const { locale } = useLocale();
  const [filter, setFilter] = useState<null | string>(null);
  const { data: networkItem } = useSWR<INetworkApi, HttpError>(
    `/networks/${networkId}`
  );

  const {
    itemsToDisplay,
    setPage,
    itemsPerPage,
    page,
    loadingItemsToDisplay,
    mutate,
  } = useListingCollection<INetworkMemberItem>({
    basedUrl: `/networks/${networkId}/network_members`,
    isActiveProperties: true,
    currentPage: 'networks',
    status: filter,
  });

  const columns = useMembersListColumns({
    isLoading: loadingItemsToDisplay,
    userAuthorisedToEdit: networkItem?.userAuthorizedToEdit,
    mutate,
  });

  const totalCount = itemsToDisplay?.['hydra:totalItems'] || 0;
  const results = itemsToDisplay?.['hydra:member'] || [];
  const rows = loadingItemsToDisplay ? skeletonItems : results;

  const findCountByStatus = (status: string, ending: string) => {
    const items = results.filter((el) => el.status.id === +status);
    return items ? `${items.length} ${ending}` : '';
  };

  const getMinHeight = () => {
    const count = results.length;
    if (results) {
      switch (count) {
        case 1 as number:
          return 150;
          break;
        case 0 as number:
          return 120;
          break;
        default:
          return count < 10 ? 48 * (count + 1) : 48 * 10;
      }
    } else {
      return 0;
    }
  };

  return (
    <>
      <TitleHeader
        title={t('mls-networks')}
        titleElement={
          <div className={'w-full'}>
            {networkItem ? (
              <Typography className="!text-3xl">
                {networkItem?.translations?.[locale]?.name}
              </Typography>
            ) : (
              <Skeleton
                sx={{
                  height: '40px',
                  marginBottom: '.5rem',
                  maxWidth: '500px',
                }}
                variant={'rectangular'}
              />
            )}
            {totalCount ? (
              <Typography variant="subtitle1" className="text-sm text-gray-600">
                {addEnding(t('member'), totalCount)}
                {', '}
                {findCountByStatus(statuses.pending, t('pending'))}
                {', '}
                {findCountByStatus(statuses.declined, t('declined'))}
              </Typography>
            ) : (
              <Skeleton
                sx={{
                  maxWidth: '300px',
                }}
                variant={'text'}
              />
            )}
          </div>
        }
        goBackLink={'/settings/networks'}
        rightElement={
          networkItem && networkItem.userAuthorizedToEdit ? (
            <div className="flex gap-2 shrink-0">
              <Link className="shrink-0" to={`/networks/${networkId}/edit`}>
                <Button
                  className="add_contact shrink-0"
                  variant="outlined"
                  disableElevation
                  sx={{ background: 'background.default', height: '40px' }}
                >
                  {t('Edit')}
                </Button>
              </Link>
              <Link
                className="shrink-0"
                to={`/networks/${networkId}/edit/add-new`}
              >
                <Button
                  className="add_contact shrink-0"
                  variant="contained"
                  disableElevation
                  sx={{ background: 'background.default', height: '40px' }}
                >
                  {t('Add_new_member')}
                </Button>
              </Link>
            </div>
          ) : null
        }
      >
        <section className="w-full h-full flex flex-col">
          <div className="w-full justify-start items-start gap-2 inline-flex">
            {filters.map((el) => (
              <button
                key={el.nameKey}
                onClick={() => setFilter(el.filterKey)}
                className={classNames(
                  'px-4 py-2 rounded justify-center items-center gap-4 flex border',
                  {
                    'bg-blue border-blue': filter === el.filterKey,
                    'bg-gray-50': !(filter === el.filterKey),
                  }
                )}
              >
                <div
                  className={classNames(
                    'first-letter:uppercase text-sm font-normal leading-none',
                    {
                      'text-white': filter === el.filterKey,
                    }
                  )}
                >
                  {t(el.nameKey)}{' '}
                </div>
              </button>
            ))}
          </div>
          <div className={'pt-8 flex-grow'}>
            <div className="h-full w-full with-border">
              <TableGridX
                key={String(loadingItemsToDisplay)}
                disableSelectionOnClick={true}
                disableColumnSelector={true}
                rows={rows}
                // @ts-ignore
                columns={columns}
                autoPageSize
                pageSize={itemsPerPage}
                page={page - 1}
                paginationMode="server"
                rowCount={totalCount}
                onPageChange={(page: number) => {
                  setPage(page + 1);
                }}
                rowHeight={48}
                sx={{
                  '&': {
                    minHeight: `${getMinHeight()}px`,
                  },
                  '& .MuiDataGrid-virtualScroller': {
                    overflow: 'hidden',
                  },
                }}
              />
            </div>
          </div>
        </section>
      </TitleHeader>
    </>
  );
};

export default SingleNetwork;
