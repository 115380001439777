import React, { useState } from 'react';
import useSWR from 'swr';
import { getOptionsByLanguage } from '../../../const/propertiesOptions';
import { MenuItem, Select } from '@mui/material';
import { AxiosResponse } from 'axios';
import { http } from '../../../libs/axios';
import { useNotification } from '../../../hooks/useNotification';
import SpinnerLoading from '../../../features/spinner/spinnerLoading';
import { updateHistoric } from '../../../helpers/utils/contacts';
import useLocale from '../../../hooks/useLocale';

const getColor = (status: number | null) => {
  switch (status) {
    case 1:
      return '#ED6C02';
    case 2:
      return '#4CAF50';
    case 3:
      return '#F50057';
    default:
      return 'black';
  }
};

const StatusChanger = (props: {
  status: number | null;
  id: number;
  mutate: any;
}) => {
  const { locale } = useLocale();
  const [status, setStatus] = useState(props.status);
  const [isLoading, setIsLoading] = useState(false);
  const { data: statuses } = useSWR(`/contact_statuses`);
  const { addNotification } = useNotification();
  const statusesOptions = getOptionsByLanguage(
    statuses ? statuses : [],
    locale
  );

  const onStatusChange = (e: any) => {
    const promise: Promise<AxiosResponse> = http.patch(
      `/contacts/${props.id}`,
      {
        status: e.target.value,
      }
    );

    setIsLoading(true);
    promise
      .finally(() => {
        setIsLoading(false);
      })
      .then(({ data }) => {
        addNotification(
          `Your contact status has been successfully updated.`,
          'success'
        );
        updateHistoric(props.id, 'Contact status has been updated');
        if (props.mutate) {
          props.mutate(data);
        }
        setStatus(e.target.value);
      })
      .catch((error) => {
        let message;
        if (error.response) {
          message =
            error.response.data && error.response.data.message
              ? error.response.data.message
              : 'Server error';
        } else if (error.request) {
          message = 'Failed to connect to server';
        } else {
          message = 'Unknown error';
        }
        addNotification(message, 'error');
      });
  };

  return (
    <div className={'relative'}>
      {isLoading ? (
        <div className={'absolute inset-0 z-10 bg-gray-400 bg-opacity-40'}>
          <SpinnerLoading />
        </div>
      ) : null}
      <Select
        className={'h-[56px] pr-8'}
        labelId="level-label"
        value={status}
        onChange={onStatusChange}
        sx={{
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: `${getColor(status)} !important`,
          },
          '& .MuiSelect-select:first-letter': {
            textTransform: 'uppercase',
          },
          '& .MuiSelect-select, & .MuiSvgIcon-root': {
            color: getColor(status),
          },
        }}
      >
        {statusesOptions &&
          statusesOptions.map(
            (item: { id: number; value: string }, index: number) => (
              <MenuItem
                key={index}
                value={item?.id}
                className={`select_status_${item.id}`}
              >
                <span className={'first-letter:uppercase'}>{item.value}</span>
              </MenuItem>
            )
          )}
      </Select>
    </div>
  );
};

export default StatusChanger;
