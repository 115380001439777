import { CSSProperties } from 'react';

import usePropertyName from '../../../../../hooks/usePropertyName';
import { WidgetPreviewProps } from '../../../../../types/brochureEditor';
import useLocale from '../../../../../hooks/useLocale';
import FontSizeCalibrator from '../../fontSizeCalibrator';
import { useTranslation } from 'react-i18next';

const TitleBlock = ({ previewSubject, widgetOptions }: WidgetPreviewProps) => {
  const { getPropertyName } = usePropertyName();

  const withCity = widgetOptions?.withCity?.value;
  const withReference = widgetOptions?.withReference?.value;

  const textAlign = String(widgetOptions?.textAlign?.value);
  const color = String(widgetOptions?.color?.value);
  const textStyle = { textAlign, color } as CSSProperties;

  const { locale } = useLocale();

  let gridTemplateRows = '1fr';
  if (withCity) {
    gridTemplateRows = 'auto ' + gridTemplateRows;
  }
  if (withReference) {
    gridTemplateRows = gridTemplateRows + ' auto';
  }

  const { t } = useTranslation('propertiesPage');

  return (
    <div className="grid" style={textStyle}>
      <div className="grid gap-1" style={{ gridTemplateRows }}>
        {withCity && (
          <p className="text-[2.625rem] font-bold leading-[1.2] w-full uppercase">
            {previewSubject.address?.translations?.[locale]?.city}
          </p>
        )}

        <h2 className="text-[2.625rem] font-bold leading-[1.2] w-full relative">
          <FontSizeCalibrator html={getPropertyName(previewSubject)} center />
        </h2>

        {withReference && (
          <p className="text-[1.25rem] font-bold leading-[1.2] w-full">
            {t('Ref')}:{' '}
            {previewSubject.referenceAgency || previewSubject.reference}
          </p>
        )}
      </div>
    </div>
  );
};

export default TitleBlock;
