import { useTranslation } from 'react-i18next';
import useLocale from '../../../../../hooks/useLocale';
import { WidgetPreviewProps } from '../../../../../types/brochureEditor';
import FontSizeCalibrator from '../../fontSizeCalibrator';

import './multipageDescription.css';

const DescriptionBlock = ({
  previewSubject,
  widgetOptions,
}: WidgetPreviewProps) => {
  const { locale } = useLocale();
  const textAlign = widgetOptions?.textAlign?.value;
  const color = widgetOptions?.color?.value;
  const { t } = useTranslation('common');

  const source = widgetOptions?.source?.value;
  let html = '';
  if (source === 'main') {
    html = previewSubject.translations?.[locale]?.description || '';
  } else if (source === 'second') {
    html = previewSubject.translations?.[locale]?.secondDescription || '';
  }

  return (
    <div className="flex flex-col gap-4">
      <h2
        className="text-2xl font-semibold leading-7"
        style={{ color: 'var(--brand-color)' }}
      >
        {t('Description')}
      </h2>
      <div
        className="text-2xl leading-[calc(28/24)] grow relative"
        // @ts-ignore
        style={{ textAlign, color }}
      >
        <FontSizeCalibrator html={html} />
      </div>
    </div>
  );
};

export default DescriptionBlock;
