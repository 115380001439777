import { MobileDatePicker } from '@mui/x-date-pickers-pro';
import * as locales from '@mui/material/locale';
import { createTheme, ThemeProvider, Box } from '@mui/material';
import { useMemo, useState } from 'react';

import useLocale from '../../hooks/useLocale';
import { appTheme } from '../material-ui/theme';
import { useThemeMode } from '../../hooks/useThemeMode';

export type DatePickerT = {
  label?: string;
  name?: string;
  className?: string;
  setValue?: any;
  isRequired?: boolean;
  value?: string;
  onChange?: (date: Date) => unknown;
};

export function formatDate(date: any): string {
  // Do not use "any". Please
  if (typeof date === 'string') {
    date = new Date(date);
  }

  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };
  return new Intl.DateTimeFormat('en-US', options as any).format(date);
}

export default function DatePicker({
  label,
  name,
  className,
  isRequired = false,
  setValue,
  value,
  onChange,
}: DatePickerT) {
  const [currentDate, setCurrentDate] = useState<any>(
    value ? new Date(value) : null
  );
  const { locale } = useLocale();
  const coreLang =
    locale == 'fr'
      ? locales['frFR']
      : locale == 'de'
      ? locales['deDE']
      : locales['enUS'];

  const { mode } = useThemeMode();

  const theme = useMemo(() => createTheme(appTheme(mode)), [mode]);
  const tableGridTheme = createTheme(theme, coreLang);

  return (
    <ThemeProvider theme={tableGridTheme}>
      <Box className="flex flex-col gap-1 ">
        <span className="text-xs text-zinc-500">
          {label} {isRequired && <span className="text-red-500">*</span>}
        </span>

        <MobileDatePicker
          className={className}
          value={value || currentDate}
          format="DD/MM/YYYY"
          onChange={
            onChange ||
            ((newValue) => {
              setCurrentDate(newValue);
              const formattedDate = formatDate(newValue);

              name === 'from' || name === 'to'
                ? setValue(name, newValue.format())
                : setValue(name, formattedDate);
            })
          }
          // this is not supported in "@mui/x-date-pickers-pro": "^6.5.0"
          // renderInput={(params) => (
          //   <TextField
          //     className="!rounded"
          //     {...params}
          //     size="small"
          //     sx={textFieldStyle}
          //   />
          // )}
        />
      </Box>
    </ThemeProvider>
  );
}
