import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document } from '../../../../../types/property';
import TableGridX from '../../../../../components/tableGridX';
import { GridSelectionModel } from '@mui/x-data-grid';
import useColumns from '../../../../generatedBrochures/useColumns';
import { Box } from '@mui/material';

type Props = {
  items?: Document[];
  rowSelectionModel: GridSelectionModel;
  onRowsSelectionHandler: (value: GridSelectionModel) => void;
};

const skeletonItems = Array(6)
  .fill('')
  .map((_, index) => ({ id: index }));

const pageSize = 10;

const SelectFiles = ({
  items,
  rowSelectionModel,
  onRowsSelectionHandler,
}: Props) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);

  const columns = useColumns({
    isLoading: !items,
    isPopup: true,
  });

  const totalCount = items?.length;
  const rows = items ? items : skeletonItems;

  const getMinHeight = (wrapper?: boolean) => {
    const count =
      rows && rows.length
        ? rows.length < pageSize
          ? rows.length
          : pageSize
        : pageSize;

    if (rows) {
      switch (count) {
        case 1:
          return 230;
          break;
        case 0:
          return 230;
          break;
        default:
          return (count < 10 ? 185 * count : 185 * 10) + (wrapper ? 50 : 50);
      }
    } else {
      return 0;
    }
  };

  return (
    <div className={'flex flex-col'}>
      <p className={'text-xl mb-8'}>{t('propertiesPage:Select_files')}</p>
      <div className={'h-full min-h-[500px] w-full grow flex'}>
        <Box
          className={'with-border h-full w-full'}
          sx={{
            '& .MuiDataGrid-main': {
              minHeight: `${getMinHeight(true)}px`,
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
              display: 'none',
            },
            '& .MuiDataGrid-virtualScroller': {
              height: `${getMinHeight()}px !important`,
              overflowY: 'hidden !important',
            },
          }}
        >
          <TableGridX
            key={String(!!items)}
            checkboxSelection
            onSelectionModelChange={(
              newRowSelectionModel: GridSelectionModel
            ) => onRowsSelectionHandler(newRowSelectionModel)}
            selectionModel={rowSelectionModel}
            rows={rows}
            // @ts-ignore
            columns={columns}
            pageSize={pageSize}
            page={page - 1}
            rowCount={totalCount}
            onPageChange={(page: number) => {
              setPage(page + 1);
            }}
            rowHeight={64}
          />
        </Box>
      </div>
    </div>
  );
};

export default SelectFiles;
