import { createElement } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Paper } from '@mui/material';
import slugify from 'slugify';
import { useTranslation } from 'react-i18next';
import TitleHeader from '../../../../components/common/titleHeader';
import Tabs from '../../../../components/common/tabs';

export interface SettingsTabProps {
  mapTabNamesToComponents: any;
}

const TabbedSettings = (props: SettingsTabProps) => {
  const { mapTabNamesToComponents } = props;
  const { t } = useTranslation('common');
  const stepNames = Object.keys(mapTabNamesToComponents);
  const stepsData = stepNames.map((name) => {
    return {
      slug: slugify(name.toLowerCase()),
      title: name,
      component: mapTabNamesToComponents[name],
    };
  });

  return (
    <div>
      <TitleHeader title={t('Settings')} goBackLink="/settings">
        <>
          <Tabs items={stepsData} />
          <Paper square elevation={0}>
            <div className="">
              <div className="pt-16 pb-12 px-16">
                <Routes>
                  {stepsData.map(({ slug, component }) => (
                    <Route
                      key={slug}
                      path={slug}
                      element={component && createElement(component)}
                    />
                  ))}
                </Routes>
              </div>
            </div>
          </Paper>
        </>
      </TitleHeader>
    </div>
  );
};

export default TabbedSettings;
