import { useNavigate } from 'react-router-dom';
import { PreviewSubjectTypes } from '../../../../types/brochureEditor';

export function useHandlePreviewSubjectChange({
  subjectType,
}: {
  subjectType?: PreviewSubjectTypes | null;
}) {
  const navigate = useNavigate();
  return ({ templateId, value }: { templateId?: string; value?: string }) => {
    if (templateId && value && subjectType === PreviewSubjectTypes.PROPERTY) {
      navigate(prepareUrl({ templateId, slug: `/property/${value}` }));
    } else if (
      templateId &&
      value &&
      subjectType === PreviewSubjectTypes.PROMOTION
    ) {
      navigate(prepareUrl({ templateId, slug: `/promotion/${value}` }));
    } else if (templateId) {
      navigate(`/brochure-templates/${templateId}`);
    }
  };
}

function prepareUrl({
  templateId,
  slug,
}: {
  templateId?: string;
  slug: string;
}) {
  return templateId === 'new'
    ? `/brochure-templates/new?continueTo=${slug}`
    : `/brochure-templates/${templateId}${slug}`;
}
