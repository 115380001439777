import {
  Control,
  UseFormGetValues,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form';
import { IProperty } from '../../../../../../types/property';
import StepsFieldset from '../../../../../../components/stepper/stepsFieldset';
import InputFormField from '../../../../../../components/form/inputFormField';
import SelectFormField, {
  selectOptionStringT,
} from '../../../../../../components/form/selectFormField';
import useSWR from 'swr';
import {
  getOptionsByLanguageString,
  optionType,
} from '../../../../../../const/propertiesOptions';
import SwitcherFormField from '../../../../../../components/form/switcherFormField';
import { useEffect, useState } from 'react';
import { Button, InputAdornment, Typography } from '@mui/material';
import PricingPublication, {
  IPublicationListItem,
} from './utils/pricingPublication';
import { useTranslation } from 'react-i18next';
import useLocale from '../../../../../../hooks/useLocale';
import RepeatableBlock from '../../../../../../components/form/repeateableBlock';
import CloseIcon from '../../../../../../features/icons/closeIcon';

type Props = {
  register: UseFormRegister<IProperty>;
  getValues: UseFormGetValues<IProperty>;
  watch: UseFormWatch<IProperty>;
  control: Control<IProperty>;
};
const PricesList: { id: number; label: string; name: any }[] = [
  {
    id: 0,
    label: 'Displayed Price',
    name: 'pricingBuy.displayedPrice',
  },
  {
    id: 1,
    label: 'Original Price',
    name: 'pricingBuy.originalPrice',
  },
  {
    id: 2,
    label: 'Price/m2',
    name: 'pricingBuy.pricem2',
  },
  {
    id: 3,
    label: 'Estimated Price',
    name: 'pricingBuy.estimatedPrice',
  },
  {
    id: 4,
    label: 'Recommended price',
    name: 'pricingBuy.recommendedPrice',
  },
];

const BuyPricing = ({ register, getValues, control, watch }: Props) => {
  const [defaultCurrencyValue, setDefaultCurrencyValue] =
    useState<selectOptionStringT>();
  const { locale } = useLocale();
  const { t } = useTranslation('propertiesPage');
  // fetching options lists
  const { data: parkingIncludesOptionsApi } =
    useSWR<optionType[]>('parking_includeds');
  const { data: parkingMandatoryOptionsApi } = useSWR<optionType[]>(
    'parking_mandatories'
  );
  const { data: chargesIncludedOptionsApi } =
    useSWR<optionType[]>('charges_includeds');
  const { data: heatingAnnualChargesIncludedOptionsApi } = useSWR<optionType[]>(
    'heating_annual_charges_includeds'
  );
  const { data: currenciesOptionsApi } =
    useSWR<selectOptionStringT[]>('currencies');
  const { data: regimesOptionsApi } = useSWR<optionType[]>('regimes');
  const { data: parkingTypesApi } = useSWR<optionType[]>('parking_types');
  // transform option list by language
  const parkingIncludesOptions = getOptionsByLanguageString(
    parkingIncludesOptionsApi,
    locale
  );
  const parkingMandatoryOptions = getOptionsByLanguageString(
    parkingMandatoryOptionsApi,
    locale
  );
  const chargesIncludedOptions = getOptionsByLanguageString(
    chargesIncludedOptionsApi,
    locale
  );
  const heatingAnnualChargesIncludedOptions = getOptionsByLanguageString(
    heatingAnnualChargesIncludedOptionsApi,
    locale
  );

  const regimesOptions = getOptionsByLanguageString(regimesOptionsApi, locale);
  const parkingTypes = getOptionsByLanguageString(parkingTypesApi, locale);
  useEffect(() => {
    if (currenciesOptionsApi && currenciesOptionsApi[0]) {
      setDefaultCurrencyValue(currenciesOptionsApi[0]);
    }
  }, [currenciesOptionsApi]);

  if (!defaultCurrencyValue) return null;

  const currencyChoice = getCurrency(
    currenciesOptionsApi,
    Number(watch('pricingBuy.currency')),
    defaultCurrencyValue
  );
  const publicationList: IPublicationListItem[] = [
    { name: 'pricingBuy.hidePriceOnWebsite', label: 'Hide price on website' },
    { name: 'pricingBuy.hidePriceOnPortal', label: 'Hide price on portal' },
    { name: 'pricingBuy.hidePriceOnBrochure', label: 'Hide price on brochure' },
  ];
  return (
    <>
      <StepsFieldset className="grid grid-cols-2" title={t('Prices')}>
        <SelectFormField
          className="Status_property"
          label={t('Currency')}
          options={currenciesOptionsApi!}
          defaultValue={String(currencyChoice?.id)}
          {...register('pricingBuy.currency')}
        />
        {PricesList.map(({ id, label, name }) => (
          <InputFormField
            key={id}
            type="number"
            label={t(label.replaceAll(' ', '_'))}
            InputProps={{
              endAdornment: (
                <InputAdornment className="uppercase" position="start">
                  {currencyChoice?.value}
                </InputAdornment>
              ),
            }}
            {...register(name, {
              valueAsNumber: true,
            })}
          />
        ))}
      </StepsFieldset>
      <StepsFieldset className="grid grid-cols-1" title={t('Parkings')}>
        <RepeatableBlock
          control={control}
          name={'pricingBuy.parkings'}
          addLabel={t('Add_parking_price')}
          appendObj={{
            parkingPrice: '',
            parkingIncluded: '',
            parkingMandatory: '',
            parkingType: '',
          }}
          renderField={(field, index, remove) => (
            <div className="flex flex-wrap justify-between mb-8" key={field.id}>
              <div className="w-[75%] flex flex-col gap-6">
                <div className={'grid grid-cols-2 gap-8'}>
                  <InputFormField
                    type="number"
                    label={t('Parking_price')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment className="uppercase" position="start">
                          {currencyChoice?.value}
                        </InputAdornment>
                      ),
                    }}
                    {...register(`pricingBuy.parkings.${index}.parkingPrice`, {
                      valueAsNumber: true,
                      setValueAs: (v) =>
                        v === '' || v === undefined ? null : v,
                    })}
                  />
                  <SelectFormField
                    label={t('Parking_type')}
                    options={parkingTypes}
                    defaultValue={getValues(
                      `pricingBuy.parkings.${index}.parkingType`
                    )}
                    {...register(`pricingBuy.parkings.${index}.parkingType`, {
                      setValueAs: (v) =>
                        v === '' || v === undefined ? null : v,
                    })}
                  />
                  <SelectFormField
                    className="Status_property"
                    label={t('Parking_included')}
                    options={parkingIncludesOptions}
                    defaultValue={getValues(
                      `pricingBuy.parkings.${index}.parkingIncluded`
                    )}
                    {...register(
                      `pricingBuy.parkings.${index}.parkingIncluded`,
                      {
                        setValueAs: (v) =>
                          v === '' || v === undefined ? null : v,
                      }
                    )}
                  />
                  <SelectFormField
                    className="Status_property"
                    label={t('Parking_mandatory')}
                    options={parkingMandatoryOptions}
                    defaultValue={getValues(
                      `pricingBuy.parkings.${index}.parkingMandatory`
                    )}
                    {...register(
                      `pricingBuy.parkings.${index}.parkingMandatory`,
                      {
                        setValueAs: (v) =>
                          v === '' || v === undefined ? null : v,
                      }
                    )}
                  />
                </div>
              </div>
              <div className="flex justify-end items-start w-[25%] mt-6">
                <Button
                  className="flex !px-0 items-center gap-3 w-[48%]"
                  variant="outlined"
                  aria-label="Delete"
                  onClick={() => remove(index)}
                >
                  <CloseIcon color="#F50057" />
                  <Typography
                    sx={{
                      fontSize: '14px',
                      color: '#F50057',
                      fontWeight: 500,
                    }}
                  >
                    {t('Delete')}
                  </Typography>
                </Button>
              </div>
            </div>
          )}
        />
      </StepsFieldset>
      <StepsFieldset className="grid grid-cols-2" title={t('Charges')}>
        <InputFormField
          type="number"
          label={t('Annual_charges')}
          InputProps={{
            endAdornment: (
              <InputAdornment className="uppercase" position="start">
                {currencyChoice?.value}
              </InputAdornment>
            ),
          }}
          {...register('pricingBuy.annualCharges', {
            valueAsNumber: true,
          })}
        />{' '}
        <SelectFormField
          label={t('Charges_included')}
          options={chargesIncludedOptions}
          defaultValue={getValues('pricingBuy.chargesIncluded')}
          {...register('pricingBuy.chargesIncluded')}
        />{' '}
        <SelectFormField
          label={t('Heating_Annual_charges_included')}
          options={heatingAnnualChargesIncludedOptions}
          defaultValue={getValues('pricingBuy.heatingAnnualChargesIncluded')}
          {...register('pricingBuy.heatingAnnualChargesIncluded')}
        />
        <InputFormField
          type="number"
          label={t('Monthly_Heating_charges')}
          InputProps={{
            endAdornment: (
              <InputAdornment className="uppercase" position="start">
                {currencyChoice?.value}
              </InputAdornment>
            ),
          }}
          {...register('pricingBuy.monthlyHeatingCharges', {
            valueAsNumber: true,
          })}
        />{' '}
        <InputFormField
          type="number"
          label={t('Monthly_PPE_charges')}
          InputProps={{
            endAdornment: (
              <InputAdornment className="uppercase" position="start">
                {currencyChoice?.value}
              </InputAdornment>
            ),
          }}
          {...register('pricingBuy.monthlyPPECharges', {
            valueAsNumber: true,
          })}
        />
        <InputFormField
          type="number"
          label={t('Charges/m2/Year')}
          InputProps={{
            endAdornment: (
              <InputAdornment className="uppercase" position="start">
                {currencyChoice?.value}
              </InputAdornment>
            ),
          }}
          {...register('pricingBuy.chargesm2Year', {
            valueAsNumber: true,
          })}
        />{' '}
      </StepsFieldset>
      <StepsFieldset className="grid grid-cols-2" title={t('General_infos')}>
        <InputFormField
          type="number"
          label={t('Income')}
          InputProps={{
            endAdornment: (
              <InputAdornment className="uppercase" position="start">
                {currencyChoice?.value}
              </InputAdornment>
            ),
          }}
          {...register('pricingBuy.income', {
            valueAsNumber: true,
          })}
        />
        <SwitcherFormField
          label={t('Property_liable_to_VAT')}
          name="pricingBuy.propertyLiableToVAT"
          checked={Boolean(getValues('pricingBuy.propertyLiableToVAT'))}
          register={register}
        />{' '}
        <SelectFormField
          className="Status_property"
          label={t('Regime')}
          options={regimesOptions}
          defaultValue={getValues('pricingBuy.regime')}
          {...register('pricingBuy.regime')}
        />
        <SwitcherFormField
          label={t('Parking_liable_to_VAT')}
          name="pricingBuy.parkingLiableToVAT"
          checked={Boolean(getValues('pricingBuy.parkingLiableToVAT'))}
          register={register}
        />{' '}
        <InputFormField
          type="number"
          label={t('Business_asset_(fond_de_commerce)')}
          inputProps={numberInputProps}
          InputProps={{
            endAdornment: (
              <InputAdornment className="uppercase" position="start">
                {currencyChoice?.value}
              </InputAdornment>
            ),
          }}
          {...register('pricingBuy.businessAsset', {
            valueAsNumber: true,
          })}
        />
        <InputFormField
          type="number"
          label={t('Revenue')}
          inputProps={numberInputProps}
          InputProps={{
            endAdornment: (
              <InputAdornment className="uppercase" position="start">
                %
              </InputAdornment>
            ),
          }}
          {...register('pricingBuy.revenue', {
            valueAsNumber: true,
          })}
        />{' '}
        <InputFormField
          type="number"
          label={t('Taxes')}
          inputProps={numberInputProps}
          InputProps={{
            endAdornment: (
              <InputAdornment className="uppercase" position="start">
                {currencyChoice?.value}
              </InputAdornment>
            ),
          }}
          {...register('pricingBuy.taxes', {
            valueAsNumber: true,
          })}
        />{' '}
        <InputFormField
          type="number"
          label={t('Income_tax_rates')}
          inputProps={numberInputProps}
          InputProps={{
            endAdornment: (
              <InputAdornment className="uppercase" position="start">
                %
              </InputAdornment>
            ),
          }}
          {...register('pricingBuy.incomeTaxRates', {
            valueAsNumber: true,
          })}
        />{' '}
        <InputFormField
          type="number"
          label={t('Renovation_fund')}
          InputProps={{
            endAdornment: (
              <InputAdornment className="uppercase" position="start">
                {currencyChoice?.value}
              </InputAdornment>
            ),
          }}
          {...register('pricingBuy.renovationFund', {
            valueAsNumber: true,
          })}
        />{' '}
      </StepsFieldset>
      <PricingPublication
        list={publicationList}
        getValues={getValues}
        register={register}
      />
    </>
  );
};

export default BuyPricing;

export function getCurrency(
  currencyOptions: selectOptionStringT[] | undefined,
  id: number,
  defaultValue: selectOptionStringT
) {
  const result = currencyOptions?.filter((currency) => +currency.id === id);
  return result ? result[0] : defaultValue;
}

const numberInputProps = {
  step: '0.1',
};
