import React from 'react';
import { GoogleMap, PolygonF } from '@react-google-maps/api';
import AddIcon from '@mui/icons-material/Add';
import classNames from 'classnames';
import CloseIcon from '@mui/icons-material/Close';
import { Polygon } from '../../forms/address-form';
import { styles } from './styles';
import { useTranslation } from 'react-i18next';

const containerStyle = {
  width: '100%',
  height: '100%',
};

interface Props {
  isLoaded: boolean;
  paths: Polygon[];
  onAddPolygon: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  onEditPolygon: (event: any, id: string) => void;
  onLoadPolygon: (polygon: any, id: string) => void;
  onRemovePolygon: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string
  ) => void;
  center: {
    lat: number;
    lng: number;
  };
}

function AddressMap(props: Props) {
  const {
    isLoaded,
    paths,
    onRemovePolygon,
    onAddPolygon,
    onLoadPolygon,
    onEditPolygon,
  } = props;
  const { t } = useTranslation('common');
  const [map, setMap] = React.useState<google.maps.Map | null>(null);

  const onLoad = React.useCallback(function callback(map: google.maps.Map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback() {
    setMap(null);
  }, []);

  return isLoaded ? (
    <>
      <div
        className={classNames('h-[640px]', {
          'pointer-events-none': !paths.length,
        })}
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={props.center}
          zoom={13}
          onLoad={onLoad}
          onUnmount={onUnmount}
          options={{
            styles: !paths.length ? styles : null,
          }}
        >
          <>
            {map ? (
              <>
                {paths.map((item) => (
                  <PolygonF
                    key={item.id}
                    // Make the Polygon editable / draggable
                    editable
                    draggable
                    path={item.coords}
                    options={{
                      strokeColor: '#3446B9',
                      strokeOpacity: 1.0,
                      strokeWeight: 1.0,
                      fillColor: '#3446B9',
                      fillOpacity: 0.4,
                    }}
                    onLoad={(polygon) => onLoadPolygon(polygon, item.id)}
                    onMouseUp={(event) => onEditPolygon(event, item.id)}
                    onDragEnd={(event) => onEditPolygon(event, item.id)}
                  />
                ))}
              </>
            ) : null}
          </>
        </GoogleMap>
      </div>
      <div className="grid grid-cols-2 gap-4 pt-4">
        <button
          onClick={(event) => onAddPolygon(event)}
          className={
            'relative min-w-[200px] hover:opacity-80 transition-opacity duration-300 rounded flex items-center justify-center gap-2 bg-gray-100 p-3 text-[14px] text-gray-900'
          }
        >
          <span
            className={
              'flex items-center justify-center absolute inset-y-0 left-2'
            }
          >
            <AddIcon />
          </span>
          <span> {t('Add_polygon')} </span>
        </button>
        {paths.map((item) => (
          <button
            key={item.id}
            onClick={(event) => onRemovePolygon(event, item.id)}
            className={
              'relative min-w-[200px] hover:opacity-80 transition-opacity duration-300 rounded flex items-center justify-center gap-2 bg-gray-100 p-3 text-[14px] text-gray-900'
            }
          >
            <span>
              {' '}
              {t('Delete_polygon')} #{item.name}
            </span>
            <span
              className={
                'opacity-80 text-[#F50057] flex items-center justify-center absolute inset-y-0 right-2'
              }
            >
              <CloseIcon />
            </span>
          </button>
        ))}
      </div>
    </>
  ) : (
    <></>
  );
}

export default React.memo(AddressMap);
