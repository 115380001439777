import { useEffect } from 'react';

import { usePagesEditor, usePagesEditorComputables } from './usePagesEditor';
import { PreviewSubject, Widget } from '../../../../types/brochureEditor';

export function useDefaultImages(previewSubject?: PreviewSubject | null) {
  const { setWidgetOption } = usePagesEditor();
  const { pages } = usePagesEditorComputables();
  useEffect(() => {
    if (previewSubject) {
      const { images } = previewSubject;

      let currentImageIndex = 0;
      if (images.length > 0) {
        for (const page of pages.filter((page) => page.widgets)) {
          const widgetEntries = Object.entries(
            page.widgets as Record<string, Widget>
          );
          const imageWidgets = widgetEntries.filter(
            ([, { type }]) => type === 'image' || type === 'imageWithPrice'
          );
          for (const [areaId, widget] of imageWidgets) {
            if (!widget.options?.image?.value) {
              const image = images[currentImageIndex];
              if (image) {
                setWidgetOption(
                  page.id,
                  areaId,
                  'image',
                  String(image.id),
                  previewSubject.id
                );
              }
              currentImageIndex = (currentImageIndex + 1) % images.length;
            }
          }
        }
      }
    }
  }, [previewSubject]);
}
