import { FC, useRef } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { Autocomplete } from '@react-google-maps/api';
import useUpdate from 'react-use/lib/useUpdate';

import InputFormField from '../inputFormField';
import { useGoogleApis } from '../../../hooks/useGoogleApis';
import useLocale from '../../../hooks/useLocale';
import { useTranslation } from 'react-i18next';

const options = {
  disabledDefaultUI: true,
  zoomControl: true,
  gestureHandling: 'cooperative',
};

type RequestGetDetails = {
  placeId: string;
  fields: string[];
  language: string;
};

interface Props {
  center: {
    lat: number | null;
    lng: number | null;
  };
  onPlaceChange: (
    placeResult: google.maps.places.PlaceResult,
    placeByLang: google.maps.GeocoderAddressComponent
  ) => unknown;
  zoom?: number;
  defaultCenter?: google.maps.LatLngLiteral;
  withoutMaps?: boolean;
  replaceClassNames?: string;
}

const GooglePlacesWithMap: FC<Props> = ({
  center,
  onPlaceChange,
  zoom = 12,
  defaultCenter = {
    lat: 46.5,
    lng: 7.5,
  },
  withoutMaps = false,
  replaceClassNames,
}) => {
  const { langues } = useLocale();
  const laguageArray = langues.map((langue) => Object.keys(langue).join());
  const update = useUpdate();
  const { isLoaded } = useGoogleApis();
  const { t } = useTranslation('propertiesPage');
  const mapRef = useRef<google.maps.Map | null>(null);
  const onLoad = (map: google.maps.Map) => {
    mapRef.current = map;
    // re-render the component - otherwise marker sometimes not displayed
    setTimeout(update, 0);
  };
  const onUnmount = (): void => {
    mapRef.current = null;
  };

  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const onLoadAutocomplete = (
    autocomplete: google.maps.places.Autocomplete
  ) => {
    autocompleteRef.current = autocomplete;
  };
  const onUnmountAutocomplete = () => {
    autocompleteRef.current = null;
  };
  const onPlaceChanged = async () => {
    if (autocompleteRef.current) {
      const placeResult = autocompleteRef.current.getPlace();
      if (placeResult) {
        const placesByLanguage: any = {};

        const fetchPlaceForLanguage = async (language: string) => {
          const request: RequestGetDetails = {
            placeId: placeResult.place_id || '', // Use the place_id from the autocomplete result
            fields: ['address_components'],
            language, // Set the desired language code, e.g., 'fr' for French
          };

          const service = new google.maps.places.PlacesService(
            document.createElement('div')
          );

          return new Promise<void>((resolve) => {
            service.getDetails(
              request as RequestGetDetails,
              (placeDetails, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                  // Extract address components from placeDetails
                  const addressComponents = placeDetails?.address_components;

                  // Store address components in placesByLanguage
                  placesByLanguage[language] = addressComponents;
                } else {
                  placesByLanguage[language] = null;
                }
                resolve();
              }
            );
          });
        };

        const fetchPlacePromises = laguageArray?.map(fetchPlaceForLanguage);
        await Promise.all(fetchPlacePromises);
        // Process and save the placesByLanguage object in the database
        onPlaceChange(placeResult, placesByLanguage);
      }
    }
  };

  let finalCenter = center as google.maps.LatLngLiteral;
  if (
    center.lat === null ||
    center.lng === null ||
    Number.isNaN(center.lat) ||
    Number.isNaN(center.lng)
  ) {
    finalCenter = defaultCenter;
  }

  if (!isLoaded) {
    return null;
  }

  return (
    <div className={replaceClassNames || 'grid gap-8'}>
      <Autocomplete
        onLoad={onLoadAutocomplete}
        onUnmount={onUnmountAutocomplete}
        onPlaceChanged={onPlaceChanged}
      >
        <InputFormField label="" placeholder={t('Enter_a_location')} />
      </Autocomplete>

      {!withoutMaps && (
        <div className="flex-grow relative pb-[calc(385/880*100%)] overflow-hidden">
          <GoogleMap
            mapContainerStyle={{ position: 'absolute', inset: '0' }}
            options={options}
            zoom={zoom}
            center={finalCenter}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            <div>
              {mapRef.current && center.lat !== null && center.lng !== null && (
                <Marker
                  visible
                  animation={google.maps.Animation.DROP}
                  position={center as google.maps.LatLngLiteral}
                />
              )}
            </div>
          </GoogleMap>
        </div>
      )}
    </div>
  );
};

export default GooglePlacesWithMap;
