import * as React from 'react';
import { useAuth } from '../../../../hooks/useAuth';
import { useEffect, useState } from 'react';
import { IPortalSetting } from '../../../../types/portals';
import classNames from 'classnames';
import PortalFormFields from './fields';
import { Department } from '../../../../types/hierarchy';

interface Values {
  portal: string;
  ftpHost: string;
  ftpLogin: string;
  ftpPassword: string;
  ftpPort: number | null;
  isSSL: number | null;
  status: number | null;
  departments: Department[] | null;
  idxFile: string;
  agencyId: string;
  agencyName: string;
  agencyEmail: string;
  contact: string;
  emailRent: string;
  telRent: string;
  emailBuy: string;
  telBuy: string;
  language: string | null;
  notifyBroker: boolean | null;
}

const initialValues: Values = {
  portal: '',
  ftpHost: '',
  ftpLogin: '',
  ftpPassword: '',
  ftpPort: null,
  isSSL: null,
  departments: [],
  idxFile: '',
  agencyId: '',
  agencyName: '',
  agencyEmail: '',
  status: 1,
  contact: '',
  emailRent: '',
  telRent: '',
  emailBuy: '',
  telBuy: '',
  language: null,
  notifyBroker: null,
};

interface Props {
  isEditMode?: boolean;
  isCreateMode?: boolean;
  isWatchMode?: boolean;
  id?: string;
  mutate?: any;
  data?: IPortalSetting;
  register: any;
  handleSubmit: any;
  control: any;
  setValue: any;
  onSubmit: any;
  isLoading: boolean;
  onCancelClick?: () => void;
  onDeleteClick?: () => void;
}

export const transformDataFromServer = (data?: IPortalSetting) => {
  if (data) {
    const departments = Array.isArray(data.departments) ? data.departments : [];
    return {
      portal: data.portal.id,
      ftpHost: data.ftpHost,
      ftpLogin: data.ftpLogin,
      ftpPassword: data.ftpPassword,
      ftpPort: data.ftpPort,
      isSSL: data.isSSL ? 1 : 0,
      status: data.isActive ? 1 : 0,
      idxFile: data.idxFile,
      departments: departments.map((elem: any) => elem.id),
      agencyId: data.agencyId,
      agencyEmail: data.agencyEmail,
      contact: data.contact,
      emailRent: data.emailRent,
      telRent: data.telRent,
      emailBuy: data.emailBuy,
      telBuy: data.telBuy,
      language: data.language,
      notifyBroker: data.notifyBroker,
    };
  } else {
    return initialValues;
  }
};

const PortalForm = (props: Props) => {
  const {
    isEditMode,
    isCreateMode,
    isWatchMode,
    register,
    handleSubmit,
    control,
    data,
    setValue,
    onSubmit,
    isLoading,
    onCancelClick,
    onDeleteClick,
  } = props;
  const { user } = useAuth();
  const [departmentsIsList, setDepartmentsIsList] = useState(false);

  useEffect(() => {
    if (isCreateMode) {
      if (user && user.department) {
        setValue('agencyName', user.department.name);
        setValue('department', user.department.id);
        setDepartmentsIsList(false);
      } else {
        setDepartmentsIsList(true);
      }
    }
  }, [user]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classNames('flex flex-col gap-8', {
        'pb-16': isWatchMode,
      })}
    >
      <PortalFormFields
        control={control}
        register={register}
        setValue={setValue}
        notifyBroker={data?.notifyBroker}
        isEditMode={isEditMode}
        isWatchMode={isWatchMode}
        isCreateMode={isCreateMode}
        isLoading={isLoading}
        onCancelClick={onCancelClick}
        onDeleteClick={onDeleteClick}
        departmentsIsList={departmentsIsList}
      />
    </form>
  );
};

export default PortalForm;
