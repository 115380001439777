import { FC } from 'react';
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
  useController,
} from 'react-hook-form';
import Rating from '@mui/material/Rating';
import StarIcon from '../../../../features/icons/starIcon';
import { stepperFormId } from '../../../../const';
import { Box, Divider, Typography } from '@mui/material';
import AutocompleteSelect from '../../../../components/form/autoCompleteSelect';
import { useFiltersProperties } from '../../../../hooks/useFiltersProperties';
import ControlledSelectedForm from '../../../../components/form/controlledSelectedForm';
import AddressInput from '../../../../components/form/addressInput';
import InputLayout from '../../../../components/form/components/inputLayout';
import { findCountryAlpha2 } from '../../utils';
import useLocale from '../../../../hooks/useLocale';
import InputFormField from '../../../../components/form/inputFormField';
import FilterAgencyMultiselect from '../../../../components/form/filterAgencyMultiselect';
import { useAuth } from '../../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { visibilityOptions } from '../../stepper/steps/visibility';

type Props = {
  mainFilterState: any;
  getValues?: UseFormGetValues<any>;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  getInputValue: (value: string, label: string) => void;
  watch: UseFormWatch<any>;
  control: any;
  handleSubmit: any;
};

interface RawDataItem {
  id: number;
  name: string;
}

const PropertyMainFilter: FC<Props> = ({
  register,
  watch,
  control,
  setValue,
  getInputValue,
  mainFilterState,
}) => {
  const { isAssistant } = useAuth();
  const { agencyHierarchy: hierarchy, assistantAgencyHierarchy } =
    useFiltersProperties(); // address
  const { field: addressField } = useController({ control, name: 'address' });

  const { locale } = useLocale();
  const { t } = useTranslation('propertiesPage');
  const country = findCountryAlpha2(locale, watch('country'));
  const isSwitzerland = country === 'CH';

  const { data: portals } = useSWR('/setting_portals/active-portals-by-agence');
  const portalsList =
    portals &&
    portals.map((item: RawDataItem) => ({
      id: item.id,
      value: item.name,
    }));

  return (
    <form id={stepperFormId}>
      <Box className="search_property">
        <Typography fontSize="1.3rem" marginBottom="20px">
          {' '}
          {t('Property')}{' '}
        </Typography>
        <Box className="grid grid-cols-3 gap-8 mb-4">
          <AutocompleteSelect
            value={watch('reference')}
            register={register}
            className="w-full search_reference"
            setValue={setValue}
            isMultiple
            onInputChange={(value: string, label: string) =>
              getInputValue(value, label)
            }
            options={
              mainFilterState['property']?.find(
                (elm: any) => elm.label === 'reference'
              )?.options || []
            }
            label={t('Reference')}
            name={'reference'}
          />
          <AutocompleteSelect
            value={watch('title')}
            register={register}
            className="w-full search_title"
            setValue={setValue}
            onInputChange={(value: string, label: string) =>
              getInputValue(value, label)
            }
            options={
              mainFilterState['property']?.find(
                (elm: any) => elm.label === 'title'
              )?.options || []
            }
            label={t('Title')}
            name={'title'}
          />
          <FilterAgencyMultiselect
            label={t('Agencies')}
            node={isAssistant ? assistantAgencyHierarchy : hierarchy}
            name="departments"
            value={watch('departments')}
            setValue={setValue}
            className="w-full !h-14 search_agencies"
            isDisabled={isAssistant && !watch('agents')}
          />
          <ControlledSelectedForm
            className="search_service"
            options={
              mainFilterState['property']?.find(
                (elm: any) => elm.label === 'service'
              )?.options || []
            }
            setValue={setValue}
            value={watch('service')}
            label={t('Service')}
            name={'service'}
            register={register}
          />
          <ControlledSelectedForm
            className="search_status"
            options={
              mainFilterState['property']?.find(
                (elm: any) => elm.label === 'status'
              )?.options || []
            }
            //isDisable={disableField}
            value={watch('status')}
            label={t('Status')}
            name={'status'}
            register={register}
          />
          <ControlledSelectedForm
            className="search_agents"
            label={t('Agents')}
            name={'agents'}
            options={
              mainFilterState['property']?.find(
                (elm: any) => elm.label === 'agents'
              )?.options || []
            }
            value={watch('agents')}
            register={register}
          />
          <ControlledSelectedForm
            className="search_category"
            options={
              mainFilterState['property']?.find(
                (elm: any) => elm.label === 'category'
              )?.options || []
            }
            //isDisable={disableField}
            value={watch('category')}
            label={t('Category')}
            name={'category'}
            register={register}
          />
          <ControlledSelectedForm
            className="search_property_type"
            options={
              mainFilterState['property']?.find(
                (elm: any) => elm.label === 'type'
              )?.options || []
            }
            isDisable={!watch('category') && watch('category') === ''}
            value={watch('type')}
            label={t('Property_type')}
            name={'type'}
            register={register}
          />

          <ControlledSelectedForm
            options={visibilityOptions}
            value={watch('visibility')}
            label={t('Visibility')}
            name={'visibility'}
            register={register}
          />
          <ControlledSelectedForm
            options={portalsList}
            value={watch('publishedOn')}
            label={t('published_on')}
            name={'publishedOn'}
            register={register}
          />

          <div className="flex justify-between items-center ">
            <span className="text-[#8e8f8e] Inter font-normal text-sm">
              {t('common:Rating')}
            </span>
            <Rating
              sx={{
                '& .MuiRating-iconFilled': {
                  color: '#3446B9',
                },
              }}
              max={3}
              icon={<StarIcon color={'#3446B9'} />}
              emptyIcon={<StarIcon color={'#F5F5F7'} />}
              size="large"
              onClick={(event: any) =>
                event.target.value && setValue('rating', event.target.value)
              }
            />
          </div>
        </Box>

        <Divider sx={{ margin: '20px 0', border: '1px solid #E6E8EC' }} />
        <Typography fontSize="1.3rem" marginBottom="20px">
          {' '}
          {t('Location')}{' '}
        </Typography>
        <Box className="grid grid-cols-3 gap-8 mb-4">
          <InputLayout label={t('Location')} className="search_Location">
            <AddressInput
              withoutMaps
              value={addressField.value}
              onChange={addressField.onChange}
            />
          </InputLayout>
          <AutocompleteSelect
            label={t('Country')}
            register={register}
            name={'country'}
            setValue={setValue}
            className="w-full search_country"
            onInputChange={(value: string, label: string) =>
              getInputValue(value, label)
            }
            options={
              mainFilterState['location']?.find(
                (elm: any) => elm.label === 'country'
              )?.options || []
            }
            value={watch('country')}
          />
          <AutocompleteSelect
            value={watch('city')}
            register={register}
            className="w-full search_city"
            setValue={setValue}
            onInputChange={(value: string, label: string) =>
              getInputValue(value, label)
            }
            options={
              mainFilterState['location']?.find(
                (elm: any) => elm.label === 'city'
              )?.options || []
            }
            label={t('City')}
            name={'city'}
          />
          <AutocompleteSelect
            value={watch('zipCode')}
            register={register}
            className="w-full search_zipCode"
            setValue={setValue}
            onInputChange={(value: string, label: string) =>
              getInputValue(value, label)
            }
            options={
              mainFilterState['location']?.find(
                (elm: any) => elm.label === 'zipCode'
              )?.options || []
            }
            label={t('Zip_code')}
            name="zipCode"
          />
          <AutocompleteSelect
            value={watch('adresse')}
            register={register}
            className="w-full search_adresse"
            setValue={setValue}
            onInputChange={(value: string, label: string) =>
              getInputValue(value, label)
            }
            options={
              mainFilterState['location']?.find(
                (elm: any) => elm.label === 'adresse'
              )?.options || []
            }
            label={t('Address')}
            name="adresse"
          />
          {isSwitzerland && (
            <InputFormField
              type="text"
              label={t('Canton')}
              {...register('canton')}
            />
          )}
          {isSwitzerland && (
            <InputFormField
              type="text"
              label={t('District')}
              {...register('district')}
            />
          )}
        </Box>
      </Box>
    </form>
  );
};

export default PropertyMainFilter;
