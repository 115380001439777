import { ReactNode } from 'react';

const EditorSwitcherLayout = ({
  title,
  children,
}: {
  title?: ReactNode;
  children?: ReactNode;
}) => (
  <div className="flex items-center justify-between">
    <div className="text-[.875rem] leading-[1rem]">{title}</div>
    <div>{children}</div>
  </div>
);

export default EditorSwitcherLayout;
