import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction } from 'react';
import { Box, Typography } from '@mui/material';
import SettingsBarsIcon from '../../../features/icons/settingsBarsIcon';
import EraseIcon from '../../../features/icons/eraseIcon';

type Props = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  handleResetFilter: () => void;
};

function PropertiesFilterHeader({ setIsOpen, handleResetFilter }: Props) {
  const { t } = useTranslation('propertiesPage');

  const handelMenuOpen = () => setIsOpen((prev) => !prev);
  return (
    <Box
      className="lg:flex h-12 justify-between px-4 rounded hidden"
      sx={{ bgcolor: '#E6E8EC', color: 'text.primary' }}
    >
      {' '}
      <Typography
        className="h-full flex items-center cursor-pointer search_filter"
        sx={{ color: '#6E6E72', fontFamily: 'Inter' }}
        onClick={handelMenuOpen}
      >
        <SettingsBarsIcon className="mr-4" color="#6E6E72" />
        {t('search_filter').toUpperCase()}
      </Typography>
      <Box className="pt-2 pb-2 text-blue">
        <Typography
          variant="subtitle1"
          className="h-full bg-white px-4 flex items-center cursor-pointer btn_clear"
          onClick={handleResetFilter}
        >
          <EraseIcon className="mr-2" color="#3446B9" />
          {t('clear')}
        </Typography>
      </Box>
    </Box>
  );
}

export default PropertiesFilterHeader;
