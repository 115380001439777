import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Button, Typography } from '@mui/material';

import { IProperty, IPropertyApi } from '../../../../../types/property';

import StepsFieldset from '../../../../../components/stepper/stepsFieldset';
import InputFormField from '../../../../../components/form/inputFormField';
import RepeatableBlock from '../../../../../components/form/repeateableBlock';

import CloseIcon from '../../../../../features/icons/closeIcon';

import { numberInputProps } from './const';
import SelectWithOptionsFromApi from './selectWithOptionsFromApi';
import useSWR from 'swr';
import SelectFormField, {
  selectOptionStringT,
} from '../../../../../components/form/selectFormField';

function RoomsBlocks({ property }: { property?: Partial<IPropertyApi> }) {
  const { register, watch, getValues, control } = useFormContext<IProperty>();
  const { t } = useTranslation('propertiesPage');

  const { data: blocks } = useSWR<{ name: string; id: number }[]>(
    `/v1/properties/${property?.id}/building_blocks`
  );
  const blocksOptions = blocks?.map((block) => ({
    id: block.id,
    value: block.name,
  })) as selectOptionStringT[];

  return (
    <StepsFieldset
      title={`${t('Rooms_Blocks')} (${watch('roomBlocks').length})`}
    >
      <div className="col-span-2">
        <RepeatableBlock
          control={control}
          name="roomBlocks"
          addLabel={t('Add_room')}
          appendObj={{ name: '', surface: 0, type: '', block: '' }}
          renderField={(field, index, remove) => (
            <div key={field.id}>
              <div className="flex justify-end">
                <Button
                  className="flex justify-center items-center gap-4 "
                  variant="outlined"
                  aria-label="Remove room"
                  onClick={() => remove(index)}
                >
                  <CloseIcon color="#F50057" />
                  <Typography
                    sx={{
                      fontSize: '14px',
                      color: '#F50057',
                      fontWeight: 500,
                    }}
                  >
                    {t('Delete_room')}
                  </Typography>
                </Button>
              </div>
              <div className="flex flex-col gap-4 ">
                <div style={{ width: '32%' }}>
                  <InputFormField
                    label={
                      watch(`roomBlocks.${index}.name`).trim() || t('room_name')
                    }
                    type="text"
                    {...register(`roomBlocks.${index}.name`)}
                    required={index !== 0}
                  />
                </div>
                <div className="grid grid-cols-3 gap-4">
                  <SelectWithOptionsFromApi
                    label={t('type')}
                    endpoint="/room_types"
                    {...register(`roomBlocks.${index}.type`)}
                    defaultValue={getValues(`roomBlocks.${index}.type`)}
                    isRequired
                  />

                  <InputFormField
                    label={t('surface')}
                    type="number"
                    inputProps={numberInputProps}
                    {...register(`roomBlocks.${index}.surface`, {
                      valueAsNumber: true,
                    })}
                    required={index !== 0}
                  />
                  <SelectFormField
                    label={t('block')}
                    options={blocksOptions}
                    {...register(`roomBlocks.${index}.buildingBlock`)}
                    defaultValue={getValues(
                      `roomBlocks.${index}.buildingBlock`
                    )}
                  />
                </div>
              </div>
            </div>
          )}
        />
      </div>
    </StepsFieldset>
  );
}

export default RoomsBlocks;
