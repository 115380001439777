import { ActionData } from '../../../../types/actionData';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useAnchorElement } from '../../../../hooks/useAnchorElement';
import ActionMenu from '../../../../components/common/actionMenu';
import { INetworkListItem } from '../../../../types/networks';
import deleteNetwork from '../actions/delete';

const NetworkItemActionMenu = ({
  item,
  action,
  mutate,
}: {
  item: INetworkListItem;
  isList?: boolean;
  action?: string;
  mutate?: () => void;
}) => {
  const menuActionData: ActionData[] = [
    {
      label: 'view',
      link: `/networks/${item?.id}`,
      icon: VisibilityOutlinedIcon,
      hide: action,
    },
  ];

  if (item.userAuthorizedToEdit) {
    menuActionData.push(
      {
        label: 'Edit',
        link: `/networks/${item?.id}/edit`,
        icon: EditIcon,
        hide: action,
      },
      {
        label: 'Delete',
        onClick: () => deleteNetwork(item?.id, mutate),
        icon: DeleteOutlineOutlinedIcon,
        hide: action,
      },
      {
        label: 'Add_member',
        link: `/networks/${item?.id}/edit/add-new`,
        icon: AddOutlinedIcon,
        hide: action,
      }
    );
  }

  const anchor = useAnchorElement();

  return (
    <>
      <ActionMenu
        {...anchor}
        isList={true}
        menuAction={menuActionData}
        transPage={'networks'}
        action={action}
      />
    </>
  );
};

export default NetworkItemActionMenu;
