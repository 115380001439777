import {
  DataGrid,
  DataGridProps,
  deDE,
  enUS,
  frFR,
  GridColumns,
  GridOverlay,
  GridValidRowModel,
} from '@mui/x-data-grid';

import {
  deDE as coredeDE,
  enUS as coreenEN,
  frFR as corefrFR,
} from '@mui/material/locale';
import { Box, createTheme, Skeleton, ThemeProvider } from '@mui/material';
import useLocale from '../../hooks/useLocale';
import { appTheme } from '../material-ui/theme';
import { CSSProperties, useMemo } from 'react';
import { useThemeMode } from '../../hooks/useThemeMode';
import { TableGridXStyling } from '../tableGridX/tableGridXStyling';

interface tableGridPropsT extends DataGridProps {
  rows: Array<GridValidRowModel>;
  columns: GridColumns<GridValidRowModel>;
  disableColumnMenu?: boolean;
  density?: 'standard' | 'compact' | 'comfortable';
  rowsPerPageOptions?: Array<number>;
  loading?: boolean;
  customTableStyle?: { [key: string]: any };
  withCheckBox?: boolean;
  style?: CSSProperties;
  sx?: any;
}

function SkeletonTableGrid({
  rows,
  style,
  columns,
  withCheckBox = false,
  customTableStyle,
  sx,
}: tableGridPropsT) {
  const { locale } = useLocale();
  const [lang, coreLang] =
    locale == 'fr'
      ? [frFR, corefrFR]
      : locale == 'de'
      ? [deDE, coredeDE]
      : [enUS, coreenEN];

  const { mode } = useThemeMode();
  const theme = useMemo(() => createTheme(appTheme(mode)), [mode]);

  const tableGridTheme = createTheme(theme, lang, coreLang);

  return (
    <ThemeProvider theme={tableGridTheme}>
      <Box sx={customTableStyle ? customTableStyle : TableGridXStyling}>
        <DataGrid
          style={style}
          rows={rows}
          columns={columns}
          rowCount={0}
          checkboxSelection={withCheckBox}
          sx={sx}
          rowsPerPageOptions={[]}
          components={{
            LoadingOverlay: () => (
              <GridOverlay>
                {Array.from({ length: 6 }).map((_, rowIndex) => (
                  <div key={rowIndex}>
                    {columns?.map((col, colIndex) => (
                      <Skeleton
                        key={`cell-${rowIndex}-${colIndex}`}
                        variant="rectangular"
                        width={50}
                        height={20}
                        animation="wave"
                        sx={{ mx: 1, my: 1 }}
                      />
                    ))}
                  </div>
                ))}
              </GridOverlay>
            ),
          }}
        />
      </Box>
    </ThemeProvider>
  );
}

export default SkeletonTableGrid;
