import {
  // Box, Grid,
  Stack,
  Typography,
} from '@mui/material';
import { IPromotionApi } from '../../../../types/promotion';
import PDFIcon from '../../../../features/icons/pdfIcon';
import { useTranslation } from 'react-i18next';

interface Props {
  data: IPromotionApi;
}

function PromotionDocuments({ data }: Props) {
  const { t } = useTranslation('promotionPage');

  return data.documents.length ? (
    <Stack
      className="bg-white border border-gray-200 rounded"
      sx={{
        backgroundColor: 'background.white',
        color: 'text.primary',
        padding: '32px',
        display: 'flex',
        gap: '16px',
      }}
    >
      <Typography variant="h5">{t('Documents')}</Typography>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: '1rem',
          padding: '12px',
        }}
      >
        {data.documents.map((item) => (
          <a
            key={item.createdAt}
            href={item.url.original}
            rel={'nofollow noopener noreferrer'}
            target={'_blank'}
            className={
              'flex items-center overflow-hidden justify-items-start overflow-ellipsis transition-opacity duration-300 hover:opacity-80'
            }
          >
            <span className={'shrink-0 mr-2'}>
              <PDFIcon />
            </span>
            <span
              className={
                'whitespace-nowrap overflow-ellipsis overflow-hidden text-blue'
              }
            >
              {item.url.original}
            </span>
          </a>
        ))}
      </Stack>
    </Stack>
  ) : null;
}

export default PromotionDocuments;
