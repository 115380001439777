import { createPortal } from 'react-dom';
import { FormProvider } from 'react-hook-form';
import { NavLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  usePagesEditor,
  usePagesEditorComputables,
} from './lib/hooks/usePagesEditor';
import { useEditorInitializer } from './lib/hooks/useEditorInitializer';
import { useServerTemplate } from './lib/hooks/useServerTemplate';
import { useOptionsForm } from './lib/hooks/useOptionsForm';
import { useDefaultImages } from './lib/hooks/useDefaultImages';
import { useGenerate } from './lib/hooks/useGenerate';
import { useSave } from './lib/hooks/useSave';
import { usePreviewSubject } from './lib/hooks/usePreviewSubject';

import SpinnerLoading from '../../features/spinner/spinnerLoading';
import CheckedIcon from '../../features/icons/checkedIcon';
import LeftArrowIcon from '../../features/icons/leftArrowIcon';
import PDFIcon from '../../features/icons/pdfIcon';
import HistoryIcon from '../../features/icons/historyIcon';

import TemplateOptions from './components/editorLayout/templateOptions';
import WidgetOptions from './components/editorLayout/widgetOptions';
import WidgetSelection from './components/editorLayout/widgetSelection';
import MockupSelection from './components/editorLayout/mockupSelection';
import ThumbnailsList from './components/editorLayout/thumbnailsList';
import ActionButton from './components/editorLayout/actionButton';
import BlockWithIndex from './components/blockWithIndex';
import PropertySelectionDropdown from './components/editorLayout/propertySelectionDropdown';
import PageOptions from './components/editorLayout/pageOptions';
import Page from './components/editorLayout/page';

import { REPEATABLE_AREA } from '../../types/brochureEditor';

const BrochureEditor = () => {
  useEditorInitializer();

  const { editedAreaId, setEditedArea } = usePagesEditor();
  const { editedPageIndex, editedPage, editedPageIsRepeatable } =
    usePagesEditorComputables();

  const { templateId, propertyId, promotionId } = useParams();
  const { template } = useServerTemplate();
  const methods = useOptionsForm({ options: template?.options });

  const templateSubjectType = Number(methods.watch('usedFor'));
  const { previewSubject, hasLoaded: previewSubjectHasLoaded } =
    usePreviewSubject();

  useDefaultImages(previewSubject);

  const canEditLayout = !previewSubject;
  const canPreview = templateId && previewSubjectHasLoaded;

  const save = useSave(methods.handleSubmit);

  const { generate, isGenerating, canGenerate } = useGenerate({
    templateId,
    propertyId,
    promotionId,
    filename: template?.options.name,
  });

  const { t } = useTranslation('brochureEditor');

  let brochuresLink = '';
  if (propertyId) {
    brochuresLink = `/properties/${propertyId}/brochures`;
  } else if (promotionId) {
    brochuresLink = `/promotions/${promotionId}/brochures`;
  }

  const canSelectMockup = !(
    editedPageIsRepeatable &&
    editedPage?.widgets?.[REPEATABLE_AREA]?.type === 'multipageDescription'
  );

  return (
    <FormProvider {...methods}>
      {isGenerating &&
        createPortal(
          <div className="fixed z-30 top-0 left-0 w-screen h-screen bg-black opacity-20 grid place-items-center">
            <SpinnerLoading />
          </div>,
          document.body
        )}
      <div>
        <div className="fixed top-0 left-0 right-0 h-[72px] bg-gray-50 z-10 border-b border-gray-200">
          <div className="flex p-4 gap-4">
            <div className="mr-auto">
              <NavLink to="/brochure-templates">
                <ActionButton bgcolor="background.default" color="dark">
                  <div className="-scale-x-100">
                    <LeftArrowIcon color="currentcolor" />
                  </div>
                  <span>{t('brochure_templates')}</span>
                </ActionButton>
              </NavLink>
            </div>

            {brochuresLink && (
              <NavLink to={brochuresLink} className="flex">
                <ActionButton
                  type="button"
                  bgcolor="background.default"
                  color="dark"
                >
                  <span>{t('propertiesPage:generated_brochures')}</span>{' '}
                  <HistoryIcon color="currentcolor" />
                </ActionButton>
              </NavLink>
            )}

            {canPreview && (
              <PropertySelectionDropdown
                selectedProperty={previewSubject}
                subjectType={templateSubjectType}
              />
            )}

            {canEditLayout && (
              <ActionButton bgcolor="blue" color="white" onClick={save}>
                <span>{t('save')}</span> <CheckedIcon color="currentcolor" />
              </ActionButton>
            )}

            {canGenerate && (
              <ActionButton bgcolor="blue" color="white" onClick={generate}>
                <span>{t('propertiesPage:generate')}</span>{' '}
                <PDFIcon color="white" />
              </ActionButton>
            )}
          </div>
        </div>

        <aside className="fixed top-[72px] bottom-0 left-0 w-[224px] bg-gray-50 z-10 border-r border-gray-200 flex">
          <ThumbnailsList addNew={canEditLayout} />
        </aside>

        <div
          className="h-screen grid place-items-center overflow-auto ml-[224px] mr-[464px] p-6 pt-[96px]"
          onClick={() => setEditedArea(null)}
        >
          <BlockWithIndex index={editedPageIndex}>
            <Page page={editedPage} />
          </BlockWithIndex>
        </div>

        <aside className="fixed top-[72px] bottom-0 right-0 w-[464px] bg-gray-50 z-10 border-l border-gray-200 flex-shrink-0 overflow-y-auto">
          {!editedAreaId && canEditLayout && <TemplateOptions />}
          {canEditLayout && canSelectMockup && <MockupSelection />}
          {canEditLayout && <PageOptions />}
          {canEditLayout && (
            <WidgetSelection subjectType={templateSubjectType} />
          )}
          <WidgetOptions previewSubject={previewSubject} />
        </aside>
      </div>
    </FormProvider>
  );
};

export default BrochureEditor;
