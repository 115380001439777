import './index.css';

import { FC, useCallback } from 'react';
import { KeyedMutator } from 'swr';

import { createElement, useMemo, useState } from 'react';
import {
  Route,
  Routes,
  useNavigate,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { Paper } from '@mui/material';
import slugify from 'slugify';
import { useTranslation } from 'react-i18next';

import { http } from '../../libs/axios';
import { useNotification } from '../../hooks/useNotification';
import Tabs, { useTranslateTabTitle } from '../common/tabs';
import { stepperFormId } from '../../const';
import SaveButton from './saveButton';
import {
  SilentAbortion,
  calculateDiff,
  sanitizePayload,
  useCurrentTab,
} from './utils';

const TabbedStepper = <ClientType, ServerType extends { id: number }>(
  props: StepperProps<ClientType, ServerType>
) => {
  const {
    mapStepNamesToComponents,
    serverData,
    mutate,
    apiPath,
    clientPath,
    singularName,
    convertToClient,
    redirect,
    // allows showing confirmation popups if user attempts to change some field and either abort request to API or automatically modify the request payload
    processDiff,
    onSuccess: onSuccessExternal,
  } = props;

  const translateTabTitle = useTranslateTabTitle();
  const tabs = Object.keys(mapStepNamesToComponents).map((name) => ({
    slug: slugify(name.toLowerCase()),
    title: translateTabTitle(name),
    component: mapStepNamesToComponents[name],
  }));
  const firstTab = tabs[0];
  const currentTab = useCurrentTab(tabs);

  const location = useLocation();
  const withoutFooter =
    location.pathname.includes('leads') ||
    location.pathname.includes('comments') ||
    location.pathname.includes('pipelines');

  const defaultValues = useMemo(
    () => convertToClient(serverData),
    [serverData, convertToClient]
  );

  const preparePayload = useCallback(
    async (body: Partial<ClientType>, defaultValues?: ClientType) => {
      let payload;

      if (defaultValues) {
        const diff = calculateDiff(defaultValues, body) as Partial<ClientType>;

        if (processDiff) {
          const { abort, diff: processedDiff } = await processDiff({
            diff,
            defaultValues,
          });
          if (abort) {
            throw new SilentAbortion();
          } else {
            payload = processedDiff;
          }
        } else {
          payload = diff;
        }
      } else {
        payload = body;
      }

      return sanitizePayload<ClientType>(payload);
    },
    [processDiff]
  );

  const { t } = useTranslation('alerts');
  const navigate = useNavigate();
  const { addNotification } = useNotification();
  const onSuccess = useCallback(
    (data: ServerType) => {
      if (mutate) {
        mutate(data);
        addNotification(t('has_been_updated', { what: singularName }));
      } else {
        addNotification(t('has_been_saved', { what: singularName }));

        if (redirect) {
          navigate(`${redirect}/${data.id}`, {
            state: {
              forceHideConfirmationPopup: true,
            },
          });
        } else if (firstTab) {
          navigate(`${clientPath}/${data.id}/edit/${firstTab.slug}`, {
            state: {
              forceHideConfirmationPopup: true,
            },
          });
        }
      }

      onSuccessExternal?.({ tabTitle: currentTab?.title });
    },
    [
      onSuccessExternal,
      addNotification,
      navigate,
      mutate,
      firstTab,
      singularName,
      redirect,
      clientPath,
      currentTab?.title,
      t,
    ]
  );

  const onError = useCallback(
    (error: unknown) => {
      let message;
      if (error instanceof AxiosError) {
        if (error?.response?.status === 481) {
          message = t('propertiesPage:The_reference_agency_already_exists');
        } else if (error?.response?.data.message) {
          message = error?.response?.data.message;
        } else if (error.code === 'ERR_NETWORK') {
          message = t('alerts:failed_server_error');
        } else {
          message = error?.response?.data.message;
        }
      } else {
        message = t('alerts:an_error_happened');
      }
      console.error(error);
      addNotification(message, 'error');
    },
    [t, addNotification]
  );
  const saveOrUpdate = useCallback(
    (body: Partial<ClientType>) => {
      let promise: Promise<AxiosResponse<ServerType>>;

      if (serverData) {
        //Check if save button submit publication form
        if (
          'publishWebsite' in body ||
          'publishGateways' in body ||
          'publishLanding' in body ||
          'publishLandingPromotion' in body
        ) {
          http.patch<ServerType>(
            `${apiPath}/${serverData.id}/publish_website_gateway`,
            body
          );
          promise = http.get(`${apiPath}/${serverData.id}`);
        } else {
          promise = http.patch<ServerType>(`${apiPath}/${serverData.id}`, body);
        }
      } else {
        promise = http.post<ServerType>(apiPath, body);
      }
      return promise;
    },
    [serverData, apiPath]
  );

  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = useCallback(
    async (body: Partial<ClientType>) => {
      setIsLoading(true);

      try {
        const payload = await preparePayload(body, defaultValues);
        const { data } = await saveOrUpdate(payload);
        onSuccess(data);
      } catch (error) {
        if (error instanceof SilentAbortion) {
          // do nothing
        } else {
          onError(error);
        }
      }

      setIsLoading(false);
    },
    [defaultValues, saveOrUpdate, preparePayload, onError, onSuccess]
  );

  const isEditMode = !!mutate;

  return (
    <div id="stepper_page">
      {isEditMode && <Tabs items={tabs} />}

      <Paper square elevation={0}>
        <div className="">
          <div className="pt-16 pb-12 px-16">
            {isEditMode ? (
              <Routes>
                {tabs.map(({ slug, component }) => (
                  <Route
                    key={slug}
                    path={slug}
                    element={
                      component &&
                      createElement(component, {
                        onSubmit,
                        defaultValues,
                        serverData,
                        mutate,
                        isLoading,
                      })
                    }
                  />
                ))}
                {firstTab && (
                  <Route
                    path="*"
                    element={<Navigate to={firstTab.slug} replace />}
                  />
                )}
              </Routes>
            ) : firstTab?.component ? (
              createElement(firstTab.component, {
                onSubmit,
                defaultValues,
                serverData,
                mutate,
                isLoading,
              })
            ) : null}
          </div>

          {!withoutFooter ? (
            <>
              <div className="h-px bg-gray-200"></div>

              <div className="pt-7 pb-10 px-16">
                <div className="grid grid-flow-col justify-end gap-6">
                  <SaveButton isLoading={isLoading} form={stepperFormId} />
                </div>
              </div>
            </>
          ) : null}
        </div>
      </Paper>
    </div>
  );
};

export default TabbedStepper;

export interface StepComponentProps<ClientType, ServerType> {
  defaultValues?: Partial<ClientType>;
  onSubmit: (body: Partial<ClientType>) => unknown;
  serverData?: Partial<ServerType>;
  mutate?: KeyedMutator<ServerType>;
  isLoading?: boolean;
}

export interface StepperProps<ClientType, ServerType> {
  mapStepNamesToComponents: Record<
    string,
    FC<StepComponentProps<ClientType, ServerType>>
  >;
  serverData?: ServerType;
  mutate?: KeyedMutator<ServerType>;
  apiPath: string;
  clientPath: string;
  singularName: string;
  convertToClient: (serverData?: ServerType) => ClientType | undefined;
  redirect?: string;
  processDiff?: ({
    diff,
    defaultValues,
  }: {
    diff: Partial<ClientType>;
    defaultValues?: ClientType;
  }) => Promise<{ diff: Partial<ClientType>; abort?: boolean }>;
  onSuccess?: ({ tabTitle }: { tabTitle?: string }) => unknown;
}

export interface CustomErrorData {
  message: string;
}
