import { IContact } from '../../../../types/contacts';
import SearchCriteria from '../../components/search-criteria';
import SpinnerLoading from '../../../../features/spinner/spinnerLoading';
import * as React from 'react';
import useSWR from 'swr';

export interface ContactTapInnerProps {
  data: IContact;
  id: string;
  mutate: any;
}

const SearchCriteriaTab = (props: ContactTapInnerProps) => {
  const { data, id } = props;
  const { data: search, mutate } = useSWR(`/search_contacts/contacts/${id}`);

  return data && search ? (
    <SearchCriteria id={id} mutate={mutate} items={search} types={data.types} />
  ) : (
    <div className={'col-start-1 col-end-4 min-h-[500px]'}>
      <SpinnerLoading />
    </div>
  );
};

export default SearchCriteriaTab;
