import { ChangeEvent, useState } from 'react';
import SwitcherIosFormField from '../../components/form/switcherIosFormField';

function ServerSideControlledSwitch({
  checked,
  onChange,
}: {
  checked: boolean;
  onChange?: (newVal: boolean) => unknown;
}) {
  const [prevChecked, setPrevChecked] = useState(checked);
  const [internalChecked, setInternalChecked] = useState(checked);

  if (checked !== prevChecked) {
    setPrevChecked(checked);
    setInternalChecked(checked);
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInternalChecked(e.target.checked);
    if (checked !== e.target.checked) {
      onChange?.(e.target.checked);
    }
  };

  return (
    <SwitcherIosFormField checked={internalChecked} onChange={handleChange} />
  );
}

export default ServerSideControlledSwitch;
