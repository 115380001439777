import { minMaxOption } from './options';
import InputLayout from '../../../../components/form/components/inputLayout';
import { Controller } from 'react-hook-form';
import { MenuItem, Select } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';

interface Props {
  minField: {
    label: string;
    name: string;
  };
  maxField: {
    label: string;
    name: string;
  };
  options: minMaxOption[];
  control: any;
  resetField: any;
  watch: any;
}

function filterItems(items: minMaxOption[], value?: number, bigger = true) {
  if (value) {
    return items.filter((el) =>
      bigger ? el.value >= value : el.value <= value
    );
  }

  return items;
}

const MinMaxFields = ({
  minField,
  maxField,
  options,
  control,
  watch,
}: Props) => {
  const [minOptions, setMinOptions] = useState<minMaxOption[]>(options);
  const [maxOptions, setMaxOptions] = useState<minMaxOption[]>(options);

  const watchMin = watch(minField.name);
  const watchMax = watch(maxField.name);

  useEffect(() => {
    if (watchMin) {
      setMaxOptions(filterItems(options, watchMin));
    }

    if (watchMax) {
      setMinOptions(filterItems(options, watchMax, false));
    }
  }, [watchMin, watchMax]);

  return (
    <>
      <InputLayout label={minField.label}>
        <Controller
          name={minField.name}
          control={control}
          render={({ field }) => (
            <Select className={'h-[56px]'} labelId="level-label" {...field}>
              {minOptions.map((item, index: number) => (
                <MenuItem
                  key={index}
                  value={item?.value}
                  className="capitalize"
                >
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </InputLayout>
      <InputLayout label={maxField.label}>
        <Controller
          name={maxField.name}
          control={control}
          render={({ field }) => (
            <Select className={'h-[56px]'} labelId="level-label" {...field}>
              {maxOptions.map((item, index: number) => (
                <MenuItem
                  key={index}
                  value={item?.value}
                  className="capitalize"
                >
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </InputLayout>
    </>
  );
};

export default MinMaxFields;
