import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useServerOverride } from './useServerOverride';

import { useNotification } from '../../../../hooks/useNotification';
import useLocale from '../../../../hooks/useLocale';

import { http } from '../../../../libs/axios';
import { Override } from '../../../../types/brochureEditor';
import { usePagesEditor } from './usePagesEditor';

export function useGenerate({
  templateId,
  propertyId,
  promotionId,
  filename,
}: {
  templateId?: string;
  propertyId?: string;
  promotionId?: string;
  filename?: string;
}) {
  const subjectId = propertyId || promotionId;
  const canGenerate = templateId && subjectId;
  const { addNotification } = useNotification();

  const { t } = useTranslation('brochureEditor');

  const [isGenerating, setIsGenerating] = useState(false);
  const { saveOverride } = useServerOverride();
  const { overrides } = usePagesEditor();
  const { locale } = useLocale();

  async function generate() {
    if (!canGenerate) return;

    setIsGenerating(true);
    try {
      await saveOverride({
        templateId,
        propertyId,
        promotionId,
        override: (overrides[subjectId] || {}) as Override,
      });

      await generateInternal({
        templateId,
        propertyId,
        promotionId,
        filename,
        locale,
      });

      let listPageUrl = '';
      if (propertyId) {
        listPageUrl = `/properties/${propertyId}/brochures`;
      } else if (promotionId) {
        listPageUrl = `/promotions/${promotionId}/brochures`;
      }

      const msg = t('brochure_successfully_generated');
      const msg2 = t('click_here_to_view_generated_brochures');
      addNotification(
        <>
          {msg}.{' '}
          <a
            href={listPageUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="underline"
          >
            {msg2}
          </a>
        </>,
        'success'
      );
    } catch (e) {
      const msg = t('error_while_generating_brochure');
      addNotification(msg, 'error');
      console.error(e);
    }
    setIsGenerating(false);
  }

  return { isGenerating, generate, canGenerate };
}

export async function generateInternal({
  templateId,
  propertyId,
  promotionId,
  filename,
  locale,
}: {
  templateId?: string;
  propertyId?: string;
  promotionId?: string;
  filename?: string;
  locale: string;
}) {
  const params = new URLSearchParams({
    locale,
    filename: `${filename || 'Unnamed template'}.pdf`,
  });

  if (templateId) {
    params.append('templateId', templateId);
  }
  if (propertyId) {
    params.append('propertyId', propertyId);
  } else if (promotionId) {
    params.append('promotionId', promotionId);
  }

  await http.get(
    `${
      process.env.REACT_APP_PDF_SERVICE_URL
    }/generate-pdf?${params.toString()}`,
    {
      responseType: 'blob',
    }
  );
}
