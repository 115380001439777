import { useCallback } from 'react';
import { useNotification } from './useNotification';
import { useTranslation } from 'react-i18next';

export function useValidateFile({ maxSizeMb = 10 }) {
  const { addNotification } = useNotification();
  const { t } = useTranslation('common');
  return useCallback(
    (file: File) => {
      if (file.size > maxSizeMb * 1024 ** 2) {
        addNotification(
          `${t('File')} ${file.name} ${t(
            'is_too_big_Max_allowed_size_is'
          )} ${maxSizeMb} MB`,
          'warning'
        );
        return false;
      }
      return true;
    },
    [maxSizeMb, addNotification]
  );
}
