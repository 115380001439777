import React, { useEffect, useState } from 'react';
import TitleHeader from '../../../components/common/titleHeader';
import { Box, Skeleton, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { http } from '../../../libs/axios';
import { Translations } from '../../../types/translations';
import EditIcon from '../../../features/icons/editIcon';
import useLocale from '../../../hooks/useLocale';
import PostAddIcon from '@mui/icons-material/PostAdd';
import SpaceTypeModal from './spaceTypeModal';
import CheckIcon from '@mui/icons-material/Check';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
interface SpaceType {
  id: number;
  keyname: string;
  translations: Translations;
}

interface Item {
  id: number;
  isHabitable: boolean;
  isSetted: boolean;
  spaceType: SpaceType;
  weighting: string;
}

const SpaceType = () => {
  const { t } = useTranslation('common');
  const { locale } = useLocale();
  const [rows, setRows] = useState<Item[]>([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isSetted, setIsSetted] = useState<boolean>();
  const [id, setId] = useState<number>();
  const [spaceTypeId, setSpaceTypeId] = useState<number>();
  const [weighting, setWeighting] = useState<string>('0.1');
  const [isHabitable, setIsHabitable] = useState<boolean>();
  const fetchData = async () => {
    try {
      const res = await http.get('space_types_settings');
      setRows(res.data);
      setLoading(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const columns: GridColDef<Item>[] = [
    {
      field: 'spaceType',
      headerClassName: '!pl-5',
      cellClassName: '!pl-6 !pt-2',
      width: 350,
      sortable: false,
      renderHeader: () => (
        <Box className="leading-5 flex gap-2 Inter font-medium text-sm pl-2 py-5 text-[#030814]">
          <span>{t('Space_type')}</span>
        </Box>
      ),
      renderCell: ({ row }) => {
        return row?.spaceType?.translations[locale]?.name;
      },
    },
    {
      field: 'isHabitable',
      headerClassName: '!pl-5',
      cellClassName: '!pl-6 !pt-1',
      width: 250,
      sortable: false,
      renderHeader: () => (
        <Box className="leading-5 flex gap-2 Inter font-medium text-sm pl-2 py-5 text-[#030814]">
          <span>{t('Habitable')}</span>
        </Box>
      ),
      renderCell: ({ row }) => {
        if (row.isHabitable === true) {
          return (
            <span className="pl-2">
              <CheckIcon sx={{ color: '#4CAF50' }} />
            </span>
          );
        } else if (row.isHabitable === false) {
          return (
            <span className="pl-2">
              <UnpublishedIcon sx={{ color: 'red' }} />
            </span>
          );
        } else {
          return '-';
        }
      },
    },
    {
      field: 'weighting',
      headerClassName: '!pl-5',
      cellClassName: '!pl-6 !pt-2',
      width: 250,
      sortable: false,
      renderHeader: () => (
        <Box className="leading-5 flex gap-2 Inter font-medium text-sm pl-2 py-5 text-[#030814]">
          <span>{t('weighting')}</span>
        </Box>
      ),
      renderCell: ({ row }) => {
        return (
          <span className="pl-2">{row.weighting ? row.weighting : '-'}</span>
        );
      },
    },
    {
      field: 'edit',
      headerClassName: ' hideRightSeparator !pl-5',
      cellClassName: '!pl-10 !pt-2',
      width: 250,
      sortable: false,
      renderHeader: () => (
        <Box className="leading-5 flex gap-2 Inter font-medium text-sm pl-2 py-5 text-[#030814]">
          <span>{t('Actions')}</span>
        </Box>
      ),
      renderCell: ({ row }) => {
        return row.isSetted ? (
          <Tooltip title={t('Edit_space_type')} arrow placement="top">
            <span
              className="block w-fit cursor-pointer"
              onClick={() => {
                setWeighting(row.weighting);
                setIsHabitable(row.isHabitable);
                setOpenModal(true);
                setIsSetted(row.isSetted);
                setId(row.id);
                setSpaceTypeId(row.spaceType.id);
              }}
            >
              <EditIcon width="19" height="19" color="#4CAF50" />
            </span>
          </Tooltip>
        ) : (
          <Tooltip title={t('Add_space_type')} arrow placement="top">
            <span
              className="cursor-pointer"
              onClick={() => {
                setWeighting('0.1');
                setIsHabitable(false);
                setOpenModal(true);
                setIsSetted(row.isSetted);
                setSpaceTypeId(row.spaceType.id);
              }}
            >
              <PostAddIcon sx={{ color: '#FFA500' }} />
            </span>
          </Tooltip>
        );
      },
    },
  ];
  const skeletonRows = Array.from(new Array(5)).map((_, index) => (
    <div
      key={index}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 0',
      }}
    >
      <Skeleton
        sx={{ mx: 1, my: 1 }}
        animation="wave"
        variant="text"
        width={200}
      />
      <Skeleton
        sx={{ mx: 1, my: 1 }}
        animation="wave"
        variant="text"
        width={90}
        height={20}
      />
      <Skeleton
        sx={{ mx: 1, my: 1 }}
        animation="wave"
        variant="text"
        width={90}
      />
      <Skeleton
        sx={{ mx: 1, my: 1 }}
        animation="wave"
        variant="text"
        width={100}
        height={20}
      />
    </div>
  ));

  return (
    <>
      <TitleHeader
        titleElement={
          <div>
            <Typography className="!text-3xl">{t('Space_types')}</Typography>
          </div>
        }
        goBackLink={'/settings'}
      >
        <div>
          {loading ? (
            <div>
              <DataGridPro
                sx={{
                  '& .MuiDataGrid-virtualScroller': {
                    overflowY: 'scroll',
                  },
                  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                    display: 'block',
                  },
                  '& .MuiDataGrid-columnSeparator': {
                    visibility: 'visible',
                  },
                  '& .hideRightSeparator > .MuiDataGrid-columnSeparator': {
                    display: 'none',
                  },
                  '& .MuiDataGrid-footerContainer': {
                    minHeight: '0px',
                  },
                }}
                rows={rows}
                columns={columns}
                disableColumnMenu
                disableRowSelectionOnClick={true}
                disableColumnSelector={true}
                hideFooterPagination
                hideFooterSelectedRowCount
                hideFooter
                getRowId={(row) =>
                  row.id
                    ? row.spaceType.keyname + row.id
                    : row.spaceType.keyname + row.spaceType.id
                }
                autoHeight={false}
                style={{ height: '60vh' }}
              />
              <SpaceTypeModal
                setLoading={setLoading}
                id={id}
                spaceTypeId={spaceTypeId}
                weighting={weighting ? weighting : '0'}
                isSetted={isSetted}
                openModal={openModal}
                setOpenModal={setOpenModal}
                setWeighting={setWeighting}
                setIsHabitable={setIsHabitable}
                isHabitable={isHabitable}
                fetchData={fetchData}
              />
            </div>
          ) : (
            <div>{skeletonRows}</div>
          )}
        </div>
      </TitleHeader>
    </>
  );
};

export default SpaceType;
