import { useTranslation } from 'react-i18next';
import {
  MultipagePropsGenerator,
  WidgetPreviewProps,
} from '../../../../../types/brochureEditor';

import './multipageDescription.css';

const MultipageDescriptionWidget = ({
  widgetOptions,
  multipageProps,
}: WidgetPreviewProps) => {
  const textAlign = widgetOptions?.textAlign?.value;
  const color = widgetOptions?.color?.value;
  const props = multipageProps as { description?: string };
  const { t } = useTranslation('common');

  return (
    <div className="flex flex-col gap-4">
      <h2
        className="text-2xl font-semibold leading-7"
        style={{ color: 'var(--brand-color)' }}
      >
        {t('Description')}
      </h2>
      <div
        className="text-xl leading-[calc(28/24)] grow relative style-lists"
        // @ts-ignore
        style={{ textAlign, color }}
        dangerouslySetInnerHTML={{ __html: props.description || '' }}
      ></div>
    </div>
  );
};

export default MultipageDescriptionWidget;

export const generateProps: MultipagePropsGenerator = ({
  locale,
  previewSubject,
}) => {
  if (!previewSubject) {
    return [{ description: '' }];
  }
  const description = previewSubject?.translations?.[locale]?.description || '';
  const elements = createHtmlElementsFromString(description);
  const eachPageProps = [];

  const div = createTestDiv();
  for (const element of elements) {
    // @ts-ignore
    div.innerHTML += element.outerHTML || element.textContent;

    if (div.clientHeight > 1170) {
      eachPageProps.push({
        description: div.innerHTML,
      });
      div.innerHTML = '';
    }
  }
  if (div.clientHeight > 0) {
    eachPageProps.push({
      description: div.innerHTML,
    });
    div.innerHTML = '';
  }

  return eachPageProps;
};

function createHtmlElementsFromString(string = '') {
  const div = document.createElement('div');
  div.innerHTML = string;
  return Array.from(div.childNodes);
}

let testDiv: HTMLDivElement;
function createTestDiv() {
  if (!testDiv) {
    testDiv = document.createElement('div');
    testDiv.className = 'text-2xl leading-[calc(28/24)]';
    testDiv.style.width = '795px';
    testDiv.style.visibility = 'hidden';
    document.body.appendChild(testDiv);
  }
  return testDiv;
}
