import { GridColumns } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { Box, Skeleton, Tooltip } from '@mui/material';
import useLocale from '../../../../hooks/useLocale';
import PropertyMainImage from '../../../../components/tableGridX/propertyMainImage';
import { EnsureShortLocale } from '../../../../components/common/ensureShortLocale';
import PermissionIcon from '../../../../features/icons/permissionIcon';
import renderShortPropertyAdress from '../../../../components/cardsTablesList/cards/elements/shortAddress';
import { formatPrice } from '../../../../helpers/utils';
import NetworksPropertiesListingActionMenu from '../../../../components/tableGridX/propertiesListingActionMenu/networks';

export default function useNetworkPropertiesColumns({
  isLoading,
}: {
  isLoading: boolean;
}) {
  const { t } = useTranslation('propertiesPage');
  const { locale } = useLocale();

  const columns: GridColumns = [
    {
      field: 'media',
      width: 100,
      renderCell: ({ row }) => <PropertyMainImage row={row} />,
    },
    {
      field: 'reference',
      minWidth: 300,
      flex: 1,
      renderHeader: () => (
        <Box className="leading-5 flex flex-col Inter font-medium text-sm pl-2 py-5 opacity-80 text-[#030814]">
          <span>{t('common:Property_title')}</span>
          <div>
            <span>{t('common:Reference')}</span>
          </div>
        </Box>
      ),
      renderCell: ({ row }) => (
        <div className={'flex flex-col items-start gap-2 w-[220px]'}>
          <div className="truncate overflow-hidden overflow-ellipsis cursor-pointer text-zinc-900 text-base font-normal leading-tight hover:opacity-80 duration-300 transition-opacity">
            {row?.translations?.[`${EnsureShortLocale(locale)}`]?.title}
          </div>
          <div className="border border-[#3446B9] cursor-pointer px-2 py-1 bg-white rounded justify-start items-center gap-1 flex">
            <PermissionIcon color="#3446B9" />
            <div className="text-blue-800 text-sm font-normal leading-none">
              {row.reference}
            </div>
          </div>
        </div>
      ),
    },
    {
      field: 'category',
      minWidth: 200,
      flex: 1,
      renderHeader: () => (
        <span className="leading-5 flex flex-col Inter font-medium pl-3 text-sm opacity-80 text-[#030814]">
          <span>{t('common:Category')}</span>
          <span>{t('common:Location')}</span>
        </span>
      ),
      renderCell: ({ row }) => (
        <Box className="flex flex-col gap-2 w-full pl-3">
          <div>
            {row.type && (
              <span className="leading-5 capitalize bg-gray-100 rounded py-1 px-2">
                {row.type?.translations[locale]?.value}
              </span>
            )}
          </div>
          <Tooltip
            title={
              row.address?.translations?.[`${EnsureShortLocale(locale)}`]?.city
            }
            arrow
            placement="top"
          >
            {renderShortPropertyAdress(row.address, true)}
          </Tooltip>
        </Box>
      ),
    },
    {
      field: 'displayedPrice',
      headerName: t('common:Price'),
      width: 130,
      headerClassName: '!pl-4',
      renderCell: ({ row }) => <div>{formatPrice(row.pricing)}</div>,
    },
    {
      field: 'sharedType',
      headerName: t('common:Share_Type'),
      width: 130,
      headerClassName: '!pl-4',
      renderCell: ({ row }) => (
        <div>
          {row.restrictedShare ? t('common:Restricted') : t('common:Advanced')}
        </div>
      ),
    },
    {
      field: 'commissionRate',
      headerName: t('common:Commission_Rate'),
      width: 200,
      headerClassName: '!pl-4',
      renderCell: ({ row }) => (
        <div className="font-medium opacity-70 text-zinc-900 text-sm leading-none whitespace-nowrap">
          {row.commissionRate} %
          {row.isCommissionRateHt ? t('common:(HT)') : t('common:(TTC)')}
        </div>
      ),
    },
    {
      field: 'commissionRateShared',
      headerName: t('common:Commission_Rate_to_Share'),
      width: 200,
      headerClassName: '!pl-4',
      renderCell: ({ row }) => (
        <div className="font-medium opacity-70 text-zinc-900 text-sm leading-none whitespace-nowrap">
          {row.commissionRateShared} %{' '}
          {row.isCommissionRateSharedHt ? t('common:(HT)') : t('common:(TTC)')}
        </div>
      ),
    },
    {
      field: '',
      headerName: t('common:Actions'),
      width: 100,
      headerClassName: '!bg-white !border !border-x-0',
      renderCell: ({ row }) => (
        <NetworksPropertiesListingActionMenu row={row} />
      ),
    },
  ];

  const commonSettings = {
    sortable: false,
    disableColumnMenu: true,
  };

  return columns.map((column) => ({
    ...commonSettings,
    ...column,
    ...(isLoading
      ? {
          renderCell: () => (
            <Skeleton
              variant="text"
              animation="wave"
              width="100%"
              height={20}
            />
          ),
        }
      : {}),
  }));
}
