import WithModal from '../../../../components/modal/widthModal';
import CreateTaskForm from '../forms/create-task-form';
import { IContactTask } from '../../../../types/contacts';
import { Box, Fade, Modal as MuiModal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

type Props = {
  data: IContactTask | null;
  isOpen: boolean;
  onClose: () => void;
  onAfterSubmit: () => void;
  contactId: string;
  mutate: any;
  isEditMode: boolean;
  defaultDate?: string | null;
};

const CreateTaskModal = ({
  isOpen,
  onClose,
  data,
  contactId,
  onAfterSubmit,
  mutate,
  isEditMode,
}: Props) => {
  const { t } = useTranslation('common');
  if (!isOpen) return null;

  return (
    <MuiModal
      open={isOpen}
      onClose={onClose}
      classes={{ root: 'grid place-items-center' }}
    >
      <Fade in={isOpen}>
        <Box
          className={''}
          sx={{
            maxHeight: '95vh',
            overflowY: 'auto',
            width: {
              xs: '90%',
              md: '40%',
            },
            borderRadius: 2,
            bgcolor: 'background.paper',
            boxShadow: 24,
          }}
        >
          <div className={'p-16'}>
            <div className="flex items-center justify-between gap-4 mb-8">
              <p className="text-2xl">
                {isEditMode ? t('Update') : t('Create')} {t('task')}
              </p>
              <button onClick={onClose}>
                <CloseIcon />
              </button>
            </div>
            <CreateTaskForm
              contactId={contactId}
              data={data}
              onDeleteItem={onClose}
              onAfterSubmit={onAfterSubmit}
              mutate={mutate}
              editMode={isEditMode}
              createMode={!isEditMode}
              taskId={data ? data.id : undefined}
            />
          </div>
        </Box>
      </Fade>
    </MuiModal>
  );
};

export default WithModal(CreateTaskModal);
