import AuthLayout from '../../layout/authLayout';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import MountTransition from '../../layout/mountTransition';
import { http, isHttpError } from '../../libs/axios';
import { useForm } from 'react-hook-form';
import { useThemeMode } from '../../hooks/useThemeMode';

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
});

export default function ForgetPassword() {
  const { t } = useTranslation('auth');

  const {
    handleSubmit,
    register,
    setError,
    formState: { isSubmitting, errors, isSubmitSuccessful },
  } = useForm<{ email: string }>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async ({ email }: { email: string }) => {
    try {
      await http.post('/users/password/forgot-password', { email });
    } catch (e: unknown) {
      if (isHttpError(e) && e?.response?.status === 404) {
        setError('email', {
          type: 'not_found',
        });
      }
    }
  };
  const { mode } = useThemeMode();

  return (
    <AuthLayout>
      <MountTransition
        className="transition-transform duration-700"
        transitionStyles={{
          exited: { transform: 'translateX(-100%)' },
          entered: { transform: 'translateX(0%)' },
        }}
      >
        <Box
          sx={{
            backgroundColor: mode === 'dark' ? 'background.default' : '#fff',
            color: 'text.primary',
          }}
          className="  px-8 py-10 shadow-sm"
        >
          <Box className="text-center mb-8">
            <Typography className="!mb-2" variant="h5">
              {t('forget_your_password')}
            </Typography>
            <Typography variant="body1">
              {t('forget_password_description')}
            </Typography>
          </Box>

          {isSubmitSuccessful ? (
            <Alert severity="success">
              <AlertTitle> {t('email_has_been_sent')}</AlertTitle>

              <Typography variant="body1">
                {t('email_has_been_sent_description')}
              </Typography>
            </Alert>
          ) : (
            <Box
              component="form"
              className="flex flex-col gap-y-6 w-full"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
            >
              <TextField
                type="email"
                id="email"
                label="Email"
                variant="outlined"
                helperText={
                  errors.email &&
                  errors.email.type === 'not_found' &&
                  t('acount_not_found')
                }
                error={Boolean(errors.email)}
                {...register('email')}
              />
              <Button
                variant="contained"
                disableElevation
                type="submit"
                disabled={isSubmitting}
                sx={{ textTransform: 'uppercase' }}
              >
                <Box component="span" className="mr-3">
                  {' '}
                  {t('submit')}
                </Box>
                <TrendingFlatIcon />
              </Button>
            </Box>
          )}
        </Box>
      </MountTransition>

      <Box className="mt-3 text-right">
        <Link className="text-primary" to="/signin">
          {t('login')}
        </Link>
      </Box>
    </AuthLayout>
  );
}
