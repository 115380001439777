import classNames from 'classnames';
import './landing-typography.css';

function LandingUserEditableHtml({
  html,
  className,
}: {
  html: string;
  className?: string;
}) {
  return (
    <div
      className={classNames(
        'user-editable-html text-[20px] leading-[1.2] font-medium grid gap-4',
        className
      )}
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    ></div>
  );
}

export default LandingUserEditableHtml;
