import { http } from '../../../../libs/axios';
import { toast } from 'react-toastify';
import i18n from '../../../../i18n';

const exit = (id: string | number, mutate: any) => {
  const { t } = i18n;
  http
    .patch(`/network_members/${id}`, {
      action: 'exit',
    })
    .then(() => {
      toast.success(`${t('alerts:networks:youExitedThisNetwork')}`, {
        position: 'top-right',
        autoClose: 3000,
      });
      if (mutate) {
        mutate();
      }
    })
    .catch(() => {
      toast.error(`${t('alerts:server_error')}`);
    });
};

export default exit;
