import AlphabetPicker from '../elements/alphabetPicker';
import { Skeleton, Typography } from '@mui/material';
import { replaceEnding } from '../index';
import TitleHeader from '../../../../components/common/titleHeader';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { INetworkAgency } from '../../../../types/networks';
import useListingCollection from '../../../../hooks/useListingCollection';
import GridTableView from '../../../../components/cardsTablesList';
import AddAgenciesList from './agenciesList';
import { useParams } from 'react-router-dom';
import AgenciesFilter from './filter';

const AddAgenciesPage = () => {
  const { id } = useParams();
  const { t } = useTranslation('networks');
  const [letterFilter, setLetterFilter] = useState<null | string>(null);
  const [cityFilter, setCityFilter] = useState<null | string>(null);

  const {
    itemsToDisplay,
    page,
    setPage,
    loadingItemsToDisplay,
    itemsPerPage,
    setItemsPerPage,
  } = useListingCollection<INetworkAgency>({
    initialItemsPerPage: 20,
    basedUrl: `/agencies/networks/${id}/visible_to_network`,
    isActiveProperties: true,
    currentPage: 'networks-agencies',
    status: letterFilter,
    filter: cityFilter,
  });

  const results = itemsToDisplay?.['hydra:member'] || [];
  const totalCount = itemsToDisplay?.['hydra:totalItems'] || 0;

  const onLetterSelect = (letter: string) => {
    setLetterFilter(letter);
  };

  const onFilterSubmit = (data: any) => {
    setCityFilter(data.city);
  };

  const onReset = () => {
    setCityFilter(null);
  };

  return (
    <>
      <TitleHeader
        title={t('mls-networks')}
        titleElement={
          <div className={'w-full'}>
            <Typography className="!text-3xl">{t('Add_new_member')}</Typography>
            {totalCount ? (
              <Typography variant="subtitle1" className="text-sm text-gray-600">
                {replaceEnding(t('agency'), totalCount, t('agencies'))}
              </Typography>
            ) : (
              <Skeleton
                sx={{
                  maxWidth: '100px',
                }}
                variant={'rounded'}
                height={28}
              />
            )}
          </div>
        }
        goBackLink={`/networks/${id}`}
      >
        <section className="w-full h-full flex flex-col">
          <div className={'pb-8'}>
            <AgenciesFilter onSubmit={onFilterSubmit} onReset={onReset} />
          </div>
          <GridTableView
            leftElement={
              <AlphabetPicker
                handleReset={() => setLetterFilter(null)}
                selectedLetter={letterFilter}
                onLetterClick={onLetterSelect}
              />
            }
            gridElement={
              <AddAgenciesList
                isGrid
                letterFilter={letterFilter}
                agencies={results}
                isLoading={loadingItemsToDisplay}
                page={page - 1}
                rowCount={totalCount}
                handleChangePage={(
                  event: React.MouseEvent | null,
                  page: number
                ) => {
                  setPage(page + 1);
                }}
                rowsPerPage={itemsPerPage}
                handleChangeRowsPerPage={(event: {
                  target: { value: number };
                }) => {
                  setItemsPerPage(event.target.value);
                  if (page > 1) {
                    setPage(1);
                  }
                }}
              />
            }
            tableElement={
              <AddAgenciesList
                isGrid={false}
                letterFilter={letterFilter}
                agencies={results}
                isLoading={loadingItemsToDisplay}
                page={page - 1}
                rowCount={totalCount}
                handleChangePage={(
                  event: React.MouseEvent | null,
                  page: number
                ) => {
                  setPage(page + 1);
                }}
                rowsPerPage={itemsPerPage}
                handleChangeRowsPerPage={(event: {
                  target: { value: number };
                }) => {
                  setItemsPerPage(event.target.value);
                  if (page > 1) {
                    setPage(1);
                  }
                }}
              />
            }
          />
        </section>
      </TitleHeader>
    </>
  );
};

export default AddAgenciesPage;
